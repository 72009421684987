import apiCall from "../utils/apiCall";

export default class ContactPersonService {
  static listAllRoles = async () => {
    const response = await apiCall(`/api/contactpersonrole/get`);
    return response?.Data;
  };

  static postContactPerson = async (payload) => {
    return await apiCall(`/api/contactperson/post`, "POST", payload);
  };
  static postEditedContactPerson = async (id, payload) => {
    return await apiCall(`/api/ContactPerson/Put/${id}`, "PUT", payload);
  };
}
