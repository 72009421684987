import React, { useEffect, useState, PureComponent } from "react";
import "../../styles/dataTable.scss";
import {
	useNavigate,
	useLocation,
	Link,
	Navigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import moment from "moment";
import ms from "ms";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import {
	colorArray,
	cycleColor,
	dateToYYYY_MM_DD,
	randomColor,
	randomNumber,
	toReadableDate,
} from "../../utils/utilityFunctions";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import {
	generateQueryString,
	checkUserPermission,
	formatValue,
} from "../../utils/utilityFunctions";
import VesselService from "../../services/vesselService";
import styled from "styled-components";
import { useCallback } from "react";
import CustomModal from "../../utils/customModal";
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";

export default function VesselUtilizationDashboard() {
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [payload, setPayload] = useState({});
	const [pages, setPages] = useState([10, 20, 50, 100, 150]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [totalRecords, setTotalRecords] = useState(10);
	const [utilizationList, setUtilizationList] = useState([]);
	const [exportData, setExportData] = useState([]);
	// const [monthTo, setMonthTo] = useState("");
	// const [vesselSpecId, setVesselSpecificationId] = useState("");
	// const [activityGroup, setActivityGroup] = useState("");
	// const [groupActivities, setGroupActivities] = useState(true);
	// const [activityId, setActivityId] = useState("");
	const [maxMonthHeader, setMaxMonthHeader] = useState("");
	const [secondMaxMonthHeader, setSecondMaxMonthHeader] = useState("");
	const [thirdMaxMonthHeader, setThirdMaxMonthHeader] = useState("");
	// const [monthFrom, setMonthFrom] = useState("");
	const [allocationCost, setAllocationCost] = useState([]);
	// const { search: queryParams } = useLocation();
	// const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [filterObject, setFilterObject] = useState(
		[]
		// useCallback(Object.fromEntries([...searchParams]), [])
		// Object.fromEntries([...searchParams])
	);
	const [vesselList, setVesselList] = useState([]);
	const [displayFilter, setDisplayFilter] = useState(false);
	const [unHideTable, setUnHideTable] = useState(false);

	const monthArray = [
		{ label: "January", value: 1 },
		{ label: "February", value: 2 },
		{ label: "March", value: 3 },
		{ label: "April", value: 4 },
		{ label: "May", value: 5 },
		{ label: "June", value: 6 },
		{ label: "July", value: 7 },
		{ label: "August", value: 8 },
		{ label: "September", value: 9 },
		{ label: "October", value: 10 },
		{ label: "November", value: 11 },
		{ label: "December", value: 12 },
	];

  const activityGroupOptions = [
	{label: "Yes", value: true},
	{label: "No", value: false}
  ];

	const activityGroupList = [
		{
			label: "Anywhere",
			value: "Anywhere",
		},
		{
			label: "At sea",
			value: "At sea",
		},
		{
			label: "Offshore installation",
			value: "Offshore installation",
		},
		{ label: "Port", value: "Port" },
	];

	useEffect(async () => {
		await init();
	}, []);

	const init = async () => {
		setLoading(true);
		await loadData();
		let listOfVessels = await VesselService.listAllVessels();
		listOfVessels = listOfVessels?.map((list) => ({
			vesselSpecificationId: list?.VesselSpecificationId,
			vesselName: list?.VesselName,
		}));
		setVesselList(listOfVessels);
		setLoading(false);
	};

	let queryStr = generateQueryString(filterObject);

	const clearFilters = async () => {
		setFilterObject({
			monthFrom: "",
			monthTo: "",
			year: "",
			vesselSpecificationId: "",
			activityGroup: "",
			groupActivities: "",
		});
		queryStr = "";
		await loadData(1, null, queryStr);
	};
	const filterData = async () => {
		await loadData(1, null, queryStr);
	};

	const loadData = async (_page, _pageSize, query = queryStr) => {
		setLoading(true);
		_page = _page || page;
		_pageSize = _pageSize || pageSize;
		const utilizationDetails = await VesselService.utilizationDashboard(
			_page,
			_pageSize,
			query
		);
			setTotalRecords(utilizationDetails?.TotalCount || 0);
		if (!utilizationDetails) {
			toastr("error", "there are installations within the selected to");
			setLoading(false);
			return;
		}
		const allocationMonths = utilizationDetails?.Data?.map(
			(list) => list.Month
		);
		let maxMonth = Math.max.apply(null, allocationMonths);
		allocationMonths.splice(allocationMonths.indexOf(maxMonth), 1);
		let secondMax = maxMonth - 1;
		let thirdMax = secondMax - 1;
		let maxMonthHeader = monthArray?.find(
			(mon) => mon.value === maxMonth
		)?.label;
		setMaxMonthHeader(maxMonthHeader);
		let secondMonthHeader = monthArray?.find(
			(mon) => mon.value === secondMax
		)?.label;
		setSecondMaxMonthHeader(secondMonthHeader);
		let thirdMaxHeader = monthArray?.find(
			(mon) => mon.value === thirdMax
		)?.label;
		setThirdMaxMonthHeader(thirdMaxHeader);

		let modifiedDataList = utilizationDetails?.Data || [];

		modifiedDataList = modifiedDataList.map((list) => ({
			...list,
			monthName: monthArray.find((numMonth) => numMonth.value === list.Month)
				.label,

			ThirdMax: (
				<div>
					<p>
						{formatValue(
							modifiedDataList?.find(
								(item) =>
									list?.Month === thirdMax && item.Activity === list.Activity
							)?.PercentUtilization
						)?.toFixed(3) || "0.000"}
					</p>
				</div>
			),
			SecondMax: (
				<div>
					<p>
						{formatValue(
							modifiedDataList?.find(
								(item) =>
									list?.Month === secondMax && item.Activity === list.Activity
							)?.PercentUtilization
						)?.toFixed(3) || "0.000"}
					</p>
				</div>
			),
			MaxMonth: (
				<div>
					<p>
						{modifiedDataList
							?.find(
								(item) =>
									list?.Month === maxMonth && item.Activity === list.Activity
							)
							?.PercentUtilization?.toFixed(3) || "0.000"}
					</p>
				</div>
			),
		}));
		setUtilizationList(modifiedDataList);

		page != _page && setPage(_page);
		pageSize != _pageSize && setPageSize(_pageSize);
		setLoading(false);
	};

	//The function below sums up the Percentage Utilization  for objects with
	//the same keys(name)
	const summKeyValue = (arrayOfObjects) => {
		let newArrayOfObjects = Array.from(
			arrayOfObjects.reduce(
				(list, { Activity, PercentUtilization }) =>
					list.set(Activity, (list.get(Activity) || 0) + PercentUtilization),
				new Map()
			),
			([Activity, PercentUtilization]) => ({ Activity, PercentUtilization })
		);

		return newArrayOfObjects;
	};
	// Accepts the array and key - then it groups the array by specified key
	const groupObjectByKey = (array, key) => {
		return array.reduce((result, currentValue) => {
			(result[currentValue[key]] = result[currentValue[key]] || []).push(
				currentValue
			);
			return result;
		}, {});
	};
	let groupedArray = groupObjectByKey(utilizationList, "VesselName");
	//converting the key-value pair in the object to the chart library accepted response.
	const keys = Object.keys(groupedArray);
	let values = Object.values(groupedArray);

	let sampleData = [];
	keys.forEach((v, i) => {
		const obj = {};
		obj.VesselName = v;
		obj.Activities = values[i];
		sampleData.push(obj);
	});

	sampleData = sampleData?.map((list, index) => ({
		VesselName: list?.VesselName,
		Activities: summKeyValue(list?.Activities),
	}));

	const headerList = [
		{
			Header: "Activity Group",
			accessor: "Activity",
		},
		// {
		// 	Header: "Vessel Name",
		// 	accessor: "VesselName",
		// },
		{
			Header: thirdMaxMonthHeader,
			id: "thirdMonthId",
			accessor: "ThirdMax",
		},
		{
			Header: secondMaxMonthHeader,
			id: "secondMonthId",
			accessor: "SecondMax",
		},
		{
			Header: maxMonthHeader,
			id: "maxMonthId",
			accessor: "MaxMonth",
		},
	];
	const pageLimiter = Math.ceil(totalRecords / pageSize);

	const prevLoaded = async () => {
		if (pageLimiter > 1 && page < pageLimiter) {
			await loadData(page - 1, null, queryStr);
		} else if (pageLimiter == page) {
			await loadData(page - 1, null, queryStr);
		}
	};

	const nextLoaded = async () => {
		if (pageLimiter !== 1 && page < pageLimiter) {
			await loadData(page + 1, null, queryStr);
		}
	};

	const goToPage = async (page) => {
		await loadData(page, null, queryStr);
	};

	const colors = [
		"#003f5c",
		"#2f4b7c",
		"#665191",
		"#a05195",
		"#d45087",
		"#f95d6a",
		"#ff7c43",
		"#ffa600",
	];

	const generateKeyValuePair = (sortedArray) => {
		const result = sortedArray.map((dataObj) => ({
			[dataObj.Activity]: dataObj.PercentUtilization?.toFixed(1),
		}));
		return result;
	};

	const convertToObject = (obj) => {
		const converted = Object.assign({}, ...obj);
		return converted;
	};

	let sampleData2 = sampleData?.map((list, index) => ({
		VesselName: list?.VesselName,
		Activities: generateKeyValuePair(list.Activities),
	}));
	let modifiedeData = sampleData2.map((list) => ({
		VesselName: list.VesselName,
		...convertToObject(list.Activities),
	}));

	const displayBarChart = () => {
		const result = modifiedeData.map((dataObject) =>
			Object.keys(dataObject)
				.filter((vesselDetails) => {
					return vesselDetails != "VesselName";
				})
				.map((activityNames, index) => {
					return (
						<Bar
							dataKey={activityNames}
							stackId="a"
							fill={colors[index % colors.length]}
						/>
					);
				})
		);
		return result[0];
	};

	return (
		<>
			{loading ? (
				<div className="w-100 text-center center my-5 py-5">
					<Spinner />
				</div>
			) : (
				<>
					<div className="card-inner-group">
						<h5 className="text-center pt-3 pb-0 pl-1">
							Vessel Utilization (% of time spent)
						</h5>
						{utilizationList.length < 0 ? null : (
							// The filter and pagination component

							<div className="card-inner position-relative card-tools-toggle">
								{
									<div className="card-title-group">
										<div className="card-tools ml-n1">
											<ul className="btn-toolbar gx-1">
												<li>
													<div className="dropdown">
														<a
															href="#"
															className="btn btn-trigger btn-icon dropdown-toggle "
															data-toggle="dropdown">
															<em className="icon ni ni-filter-alt" />
														</a>
														<div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1 filterPosition">
															<form style={{ zoom: "75%" }} className="">
																<div className="dropdown-head">
																	<span className="sub-title dropdown-title">
																		Filter List
																	</span>
																	<div className="dropdown">
																		<a
																			className="btn btn-sm btn-icon"
																			data-toggle="dropdown">
																			<em
																				className="icon ni ni-cross"
																				title="close"
																			/>
																		</a>
																	</div>
																</div>
																<div className="dropdown-body dropdown-body-rg">
																	<div className="row gx-6 gy-3">
																		<div className="col-6">
																			<div
																				className="form-group"
																				onClick={(e) => e.stopPropagation()}>
																				<label className="overline-title overline-title-alt">
																					Vessel Name
																				</label>
																				<Select
																					id="test"
																					className="border-transparent"
																					placeholder=""
																					maxMenuHeight={160}
																					value={vesselList
																						?.map((e) => ({
																							...e,
																							label: e.vesselName,
																							value: e.vesselSpecificationId,
																						}))
																						.find(
																							(a) =>
																								a.value ===
																								filterObject.vesselSpecificationId
																						)}
																					options={vesselList?.map((e) => ({
																						...e,
																						label: e.vesselName,
																						value: e.vesselSpecificationId,
																					}))}
																					onChange={(option) =>
																						setFilterObject((prev) => ({
																							...prev,
																							vesselSpecificationId:
																								option.value,
																						}))
																					}
																				/>
																			</div>
																		</div>

																		<div className="col-6">
																			<div
																				className="form-group"
																				style={{ zIndex: "18" }}
																				onClick={(e) => e.stopPropagation()}>
																				<label className="overline-title overline-title-alt">
																					Activity Group
																				</label>
																				<Select
																					className="border-transparent"
																					placeholder=""
																					maxMenuHeight={160}
																					value={activityGroupList
																						.map((e) => ({
																							...e,
																							label: e.label,
																							value: e.value,
																						}))
																						.find(
																							(a) =>
																								a.value ===
																								filterObject.activityGroup
																						)}
																					options={activityGroupList.map(
																						(e) => ({
																							...e,
																							label: e.label,
																							value: e.value,
																						})
																					)}
																					onChange={(option) =>
																						setFilterObject((prev) => ({
																							...prev,
																							activityGroup: option.value,
																						}))
																					}
																				/>
																			</div>
																		</div>

																		<div className="col-6">
																			<div
																				className="form-group h-100"
																				onClick={(e) => e.stopPropagation()}
																				>
																				<label
																					className="overline-title overline-title-alt"
																					htmlFor="fw-last-name">
																					From
																				</label>
																				<div className="form-control-wrap ">
																					<Select
																						maxMenuHeight={160}
																						options={monthArray}
																						value={monthArray
																							.map((e) => ({
																								...e,
																								label: e.label,
																								value: e.value,
																							}))
																							.find(
																								(a) =>
																									a.value ===
																									filterObject.monthFrom
																							)}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								monthFrom: option.value,
																							}))
																						}
																					/>
																				</div>
																			</div>
																		</div>

																		<div className="col-6">
																			<div
																				className="form-group"
																				style={{ zIndex: "15" }}
																				onClick={(e) => e.stopPropagation()}>
																				<label
																					className="overline-title overline-title-alt"
																					htmlFor="fw-last-name">
																					To
																				</label>
																				<div className="form-control-wrap filterSpacing">
																					<Select
																						maxMenuHeight={160}
																						options={monthArray}
																						value={monthArray
																							.map((e) => ({
																								...e,
																								label: e.label,
																								value: e.value,
																							}))
																							.find(
																								(a) =>
																									a.value ===
																									filterObject.monthTo
																							)}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								monthTo: option.value,
																							}))
																						}
																					/>
																				</div>
																			</div>
																		</div>
																		<div className="col-6">
																			<div
																				className="form-group"

																				onClick={(e) => e.stopPropagation()}>
																				<label
																					className="overline-title overline-title-alt"
																					htmlFor="to-date-filter">
																					Year
																				</label>
																				<input
																					type="number"
																					className="form-control "
																					placeholder=""
																					id="to-date-filter"
																					value={filterObject.year}
																					onChange={(e) =>
																						setFilterObject((prev) => ({
																							...prev,
																							year: e.target.value,
																						}))
																					}
																				/>
																			</div>
																		</div>
																			
																		<div className="col-6">
																			<div
																				className="form-group position-relative"
																				onClick={(e) => e.stopPropagation()}>
																				<label
																					className="overline-title overline-title-alt"
																					htmlFor="fw-last-name">
																					By Activity Group?
																				</label>
																				<div className="form-control-wrap ">
																					<Select
																						// maxMenuHeight={160}
																						options={activityGroupOptions}
																						value={activityGroupOptions
																							.map((e) => ({
																								...e,
																								label: e.label,
																								value: e.value,
																							}))
																							.find(
																								(a) =>
																									a.value ===
																									filterObject.groupActivities
																							)}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								groupActivities: option.value,
																							}))
																						}
																					/>
																				</div>
																			</div>
																		</div>

																		<div className="gap gap-10px" />

																		<div className="col-12">
																			<div className="form-group">
																				<button
																					type="button"
																					onClick={filterData}
																					className="btn btn-secondary">
																					<span>Filter</span>
																				</button>
																				<a
																					className="clickable ml-2"
																					onClick={() => clearFilters()}>
																					Reset Filter
																				</a>
																			</div>
																		</div>
																		<div className="gap gap-20px" />
																	</div>
																</div>
															</form>
														</div>
													</div>
												</li>
											</ul>
										</div>
										{pageLimiter > 1 && unHideTable && (
											<div className="card-tools mr-n1">
												<ul className="btn-toolbar gx-1">
													<li>
														<button
															className="btn btn-trigger btn-icon "
															onClick={page !== 1 ? prevLoaded : null}
															disabled={page == 1 ? true : false}
															title="Previous voyages">
															<em className="icon ni ni-arrow-left" />
														</button>
													</li>
													<li className="btn-toolbar-sep" />
													<li>
														<button
															className="btn btn-trigger btn-icon "
															onClick={page >= 1 ? nextLoaded : null}
															disabled={page >= pageLimiter ? true : false}
															title="Next voyages">
															<em className="icon ni ni-arrow-right" />
														</button>
													</li>
													<li className="btn-toolbar-sep" />
													<li>
														<div className="g" data-select2-id={6}>
															<div
																className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3"
																data-select2-id={5}>
																<div>
																	<input
																		type="number"
																		className="form-control border search-input text-align-center"
																		placeholder=""
																		onChange={(e) => goToPage(e.target.value)}
																		value={page}
																		style={{ width: "50px" }}
																	/>
																</div>
																<div>of {`${pageLimiter}`}</div>
															</div>
														</div>
													</li>
												</ul>
											</div>
										)}
									</div>
								}
							</div>
						)}
						<>
							{unHideTable && (
								<div className="" style={{ width: "100%" }}>
									<div className="text-center">
										<button
											type="button"
											className="btn btn-sm btn-link m-1"
											onClick={() => setUnHideTable(false)}>
											View as a chart
										</button>
									</div>
									<BookingTable
										columns={headerList}
										data={utilizationList}
										cssArray={["font-weight-500", "", "", "text-left", "", ""]}
										enableSearch={false}
										emptyMessage="No Vessel Utilization record to show"
										disablePageSize={true}
									/>
								</div>
							)}
						</>
					</div>
				</>
			)}
			{!unHideTable && (
				<div>
					<div className="text-center">
						<button
							type="button"
							className="btn btn-sm btn-link m-1"
							onClick={() => setUnHideTable(true)}>
							View as a table
						</button>
					</div>
					<div className="text-center  align-center" style={{ width: "100%" }}>
						<BarChart
							width={500}
							height={180}
							data={modifiedeData}
							margin={{
								top: 20,
								right: 30,
								left: 20,
								bottom: 5,
							}}>
							<CartesianGrid strokeDasharray="3 3" />

							<XAxis dataKey="VesselName" type="category" />
							<YAxis type="number" />
							<Tooltip />
							{displayBarChart()}
						</BarChart>
					</div>
				</div>
			)}
		</>
	);
}
