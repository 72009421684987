import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { toReadableDate, camelizeKeys } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import VesselService from "../../services/vesselService";
import BookingTable from "../bookingTable";

export default function VoyageCostDashboardComponent() {
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const [totalRecords, setTotalRecords] = useState(null);
	const [vessels, setVessels] = useState([]);
	const [timeFrom, setTimeFrom] = useState("");
	const [timeTo, setTimeTo] = useState("");
	const [voyageId, setVoyageId] = useState("");
	const [vesselSpecificationId, setVesselSpecificationId] = useState("");
	const [voyageRef, setVoyageRef] = useState("");
	const [voyagesWithExtraCost, setVoyagesWithExtraCost] = useState([]);

	useEffect(async () => {
		setLoading(true);
		let _vessels = await VesselService.listAllVessels();
		_vessels = _vessels?.map((c) => ({
			...c,
			label: c.VesselName,
			value: c.VesselSpecificationId,
		}));
		setVessels(_vessels || []);

		await init();
		setLoading(false);
	}, []);

	const init = async (
		_page,
		_pageSize,
		_vesselSpecificationId = "",
		_timeFrom = "",
		_timeTo = "",
		_voyageId = "",
		_voyageRef = ""
	) => {
		setLoading(true);
		_page = _page || page;
		_pageSize = _pageSize || pageSize;

		let _voyages = await VoyageService.getExtraVoyageCostsDashboardList(
			_page,
			_pageSize,
			_vesselSpecificationId,
			_timeFrom,
			_timeTo,
			_voyageId,
			_voyageRef
		);
		// console.log(_voyages);
		setTotalRecords(_voyages?.TotalCount);
		const _processedVoyageDetails = _voyages?.Data.map((voyage) => ({
			...voyage?.VoyagePlan,
			VoyageEndDate: toReadableDate(voyage?.VoyageEndDate),
			IsAdditionalCostsAdded:
				voyage?.IsAdditionalCostsAdded == true
					? "Costs added"
					: "No costs added",
		}));

		const _voyageDetails = _processedVoyageDetails.map((voyage) => ({
			...voyage,
			AddCostLinkButton: addCostLink(voyage),
		}));
		// console.log(_voyageDetails);
		setVoyagesWithExtraCost(_voyageDetails || []);

		page != _page && setPage(_page);
		pageSize != _pageSize && setPageSize(_pageSize);
		setTimeFrom(_timeFrom || "");
		setTimeTo(_timeTo || "");
		setVoyageId(_voyageId || "");
		setVesselSpecificationId(_vesselSpecificationId || "");
		setVoyageRef(_voyageRef || "");

		setLoading(false);
	};

	const addCostLink = (voyage) => {
		if (voyage) {
			return (
				<div>
					<Link
						to={`/add-extra-voyage-costs/${voyage.VoyagePlanningId}`}
						className="btn btn-xs text-primary"
						title="Add or update additional voyage costs">
						<span>Update Cost</span>
					</Link>
				</div>
			);
		}
	};

	// Endpoint vessel data filter

	const pageLimiter = Math.ceil(totalRecords / pageSize);

	const prevLoaded = async () => {
		if (pageLimiter > 1 && page <= pageLimiter) {
			await init(
				page - 1,
				null,
				vesselSpecificationId,
				timeFrom,
				timeTo,
				voyageId,
				voyageRef
			);
		}
	};

	const nextLoaded = async () => {
		if (pageLimiter !== 1 && page < pageLimiter) {
			await init(
				page + 1,
				null,
				vesselSpecificationId,
				timeFrom,
				timeTo,
				voyageId,
				voyageRef
			);
		}
	};

	const goToPage = async (page) => {
		await init(
			page,
			null,
			vesselSpecificationId,
			timeFrom,
			timeTo,
			voyageId,
			voyageRef
		);
	};

	const filterByDashboardProperty = async () => {
		await init(
			1,
			null,
			vesselSpecificationId,
			timeFrom,
			timeTo,
			voyageId,
			voyageRef
		);
	};

	const clearFilter = async () => {
		setTimeFrom("");
		setTimeTo("");
		setVoyageId("");
		setVesselSpecificationId("");
		setVoyageRef("");

		await init(1, null);
	};

	const headerList = [
		{
			Header: "Voyage Code",
			accessor: "VoyageCode",
		},
		{
			Header: "Voyage Status",
			accessor: "VoyagePlanningStatus",
		},
		{
			Header: "Voyage End Date",
			accessor: "VoyageEndDate",
		},
		{
			Header: "Additional Costs",
			accessor: "IsAdditionalCostsAdded",
		},
		{
			Header: "",
			accessor: "AddCostLinkButton",
		},
	];

	// const pageActions = (
	//   <div className="toggle-wrap nk-block-tools-toggle">
	//     <a
	//       href="#"
	//       className="btn btn-icon btn-trigger toggle-expand mr-n1"
	//       data-target="pageMenu"
	//     >
	//       <em className="icon ni ni-menu-alt-r" />
	//     </a>
	//     <div className="toggle-expand-content" data-content="pageMenu">
	//       <ul className="nk-block-tools g-3">
	//         <li>
	//           <button
	//             onClick={() => window.history.back()}
	//             className="btn btn-white btn-outline-light"
	//           >
	//             <em className="icon ni ni-arrow-left" />
	//             <span>Return to Previous Page</span>
	//           </button>
	//         </li>
	//       </ul>
	//     </div>
	//   </div>
	// );

	return (
		<>
			{loading ? (
				<div className="w-100 text-center center my-5 py-5">
					<Spinner />
				</div>
			) : (
				<>
					<div className="card-inner-group">
						<h5 className="text-center pt-2">Voyages (Uninvoiced)</h5>
						{voyagesWithExtraCost.length == 0 ? null : (
							<div className="card-inner position-relative card-tools-toggle">
								{
									<div className="card-title-group">
										<div className="card-tools ml-n1">
											<ul className="btn-toolbar gx-1">
												<li>
													<div className="dropdown" style={{ zoom: "90%" }}>
														<a
															href="#"
															className="btn btn-trigger btn-icon dropdown-toggle "
															data-toggle="dropdown">
															<em className="icon ni ni-filter-alt" />
														</a>
														<div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
															<form>
																<div className="dropdown-head">
																	<span className="sub-title dropdown-title">
																		Filter List
																	</span>
																	<div className="dropdown">
																		<a
																			className="btn btn-sm btn-icon"
																			data-toggle="dropdown">
																			<em
																				className="icon ni ni-cross"
																				title="close"
																			/>
																		</a>
																	</div>
																</div>
																<div className="dropdown-body dropdown-body-rg">
																	<div className="row gx-6 gy-3">
																		<div className="col-12">
																			<div
																				className="form-group"
																				onClick={(e) => e.stopPropagation()}>
																				<label className="overline-title overline-title-alt">
																					Vessel
																				</label>
																				<Select
																					className="border-transparent"
																					placeholder="Filter by Vessel"
																					value={vessels.find(
																						(vessel) =>
																							vessel.value ===
																							vesselSpecificationId
																					)}
																					options={vessels}
																					onChange={(option) =>
																						setVesselSpecificationId(
																							option.value
																						)
																					}
																				/>
																			</div>
																		</div>

																		<div className="col-12">
																			<div className="form-group">
																				<label className="overline-title overline-title-alt">
																					Voyage Code
																				</label>
																				<input
																					type="text"
																					className="form-control "
																					placeholder="Enter Voyage Code"
																					id="voyage-ref-filter"
																					value={voyageRef}
																					onChange={(e) =>
																						setVoyageRef(e.target.value)
																					}
																				/>
																			</div>
																		</div>

																		<div className="col-6">
																			<div className="form-group">
																				<label
																					className="overline-title overline-title-alt"
																					htmlFor="from-date-filter">
																					Start Time
																				</label>
																				<input
																					type="date"
																					className="form-control "
																					placeholder=""
																					id="from-date-filter"
																					value={timeFrom}
																					onChange={(e) =>
																						setTimeFrom(e.target.value)
																					}
																				/>
																			</div>
																		</div>

																		<div className="col-6">
																			<div className="form-group">
																				<label
																					className="overline-title overline-title-alt"
																					htmlFor="to-date-filter">
																					End Time
																				</label>
																				<input
																					type="date"
																					className="form-control "
																					placeholder=""
																					id="to-date-filter"
																					value={timeTo}
																					onChange={(e) =>
																						setTimeTo(e.target.value)
																					}
																				/>
																			</div>
																		</div>

																		<div className="gap gap-10px" />

																		<div className="col-12">
																			<div className="form-group">
																				<button
																					type="button"
																					onClick={filterByDashboardProperty}
																					className="btn btn-secondary"
																					style={{
																						backgroundColor: "midnightblue",
																					}}>
																					<span>Filter</span>
																				</button>
																				<a
																					className="clickable ml-2"
																					onClick={() => clearFilter()}>
																					Reset Filter
																				</a>
																			</div>
																		</div>
																		<div className="gap gap-20px" />
																	</div>
																</div>
															</form>
														</div>
													</div>
												</li>
											</ul>
										</div>
										{(pageLimiter > 1) && (
											<div className="card-tools mr-n1">
												<ul className="btn-toolbar gx-1">
													<li>
														<button
															className="btn btn-trigger btn-icon "
															onClick={page !== 1 ? prevLoaded : null}
															disabled={page == 1 ? true : false}
															title="Previous voyages">
															<em className="icon ni ni-arrow-left" />
														</button>
													</li>
													<li className="btn-toolbar-sep" />
													<li>
														<button
															className="btn btn-trigger btn-icon "
															onClick={page >= 1 ? nextLoaded : null}
															disabled={page >= pageLimiter ? true : false}
															title="Next voyages">
															<em className="icon ni ni-arrow-right" />
														</button>
													</li>
													<li className="btn-toolbar-sep" />
													<li>
														<div className="g" data-select2-id={6}>
															<div
																className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3"
																data-select2-id={5}>
																<div>
																	<input
																		type="number"
																		className="form-control border search-input text-align-center"
																		placeholder=""
																		onChange={(e) => goToPage(e.target.value)}
																		value={page}
																		style={{ width: "50px" }}
																	/>
																</div>
																<div>of {`${pageLimiter}`}</div>
															</div>
														</div>
													</li>
												</ul>
											</div>
										)}
									</div>
								}
							</div>
						)}
						<>
							<div
								className=""
								style={{
									width: "100%",
									maxHeight: "400px",
									overflowY: "auto",
								}}>
								<BookingTable
									columns={headerList}
									data={voyagesWithExtraCost}
									cssArray={["font-weight-500", "", "", "text-left", "", ""]}
									enableSearch={false}
									emptyMessage="No voyage record to show"
									disablePageSize={true}
								/>
							</div>
						</>
					</div>
				</>
			)}
		</>
	);
}
