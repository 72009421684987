import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class DocumentService {
  static getListOfDocumentsById = async (ownerId) => {
    ownerId = ownerId || null;
    return await apiCall(
      `/api/Documents/listForOwner/${ownerId}`,
      "GET"
    );
  };
  static uploadDeckCargoPlan = async (payload)=>{
    return await apiCall(`/api/Documents`, "POST", payload);
}
  static postDocument = async (payload) => {
    return await apiCall(`/api/Documents`, "POST", payload);
  };

  static deleteDocument = async ( documentId ) => {
    return await apiCall(`/api/Documents/delete?documentId=${documentId}`, "POST");
  };

  static updateDocument = async () => {
    return await apiCall(`/api/Documents/update`, "POST");
  };
}
