import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import TableComponent from "../../components/tableComponent";
import VesselService from "../../services/vesselService";
import _ from "lodash";
import { exportToCSV } from "../../utils/exportCSV";
import { Link, Navigate } from "react-router-dom";
import VesselListComponent from "../../components/vessel/vesselListComponent";
import { camelizeKeys, toReadableDate } from "../../utils/utilityFunctions";
import { checkUserPermission } from "../../utils/utilityFunctions";
import CustomModal from "../../utils/customModal";
import AddVesselToPool from "../vesselPools/addVesselToFleet";
import FleetService from "../../services/fleetService";
import PoolService from "../../services/vesselPoolsService";
import VoyageActivityService from "../../services/voyageActivityService";
import DPService from "../../services/dynamicPositioning";

export default function VesselList() {
  const [exportData, setExportData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [imoOrMmsi, setImoOrMmsi] = useState(null);
  const [loading, setLoading] = useState(true);
  const [apiResponse, setApiResponse] = useState({});
  const [showPoolModal, setShowPoolModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [poolsList, setPoolsList] = useState([]);
  const [PoolWithVesselInfo, setPoolWithVesselInfo] = useState([]);
  const [vesselDetailsList, setVesselDetailsList] = useState([]);
  const [dpValue, setDp] = useState([{}]);

  useEffect(async () => {
    await fetchList();
  }, []);

  let fetchList = async (_page, _pageSize, _search, _imoOrMmsi) => {
    setLoading(true);
    getPools();
    retrieveDp();
    poolList();
    retrieveVessels();
    try {
      let _apiResponse = await VesselService.extendedListing(
        _page || page,
        _pageSize || pageSize,
        _search || "",
        _imoOrMmsi
      );
      let _apiResponseWithLastVoyage = _apiResponse?.Data;
      _apiResponseWithLastVoyage = _apiResponseWithLastVoyage.map((vessel) => ({
        ...vessel,
        vesselSpecificationId: vessel?.specification?.VesselSpecificationId,
      }));
      _page && setPage(_page);
      _pageSize && setPageSize(_pageSize);
      setSearch(_search || "");
      setImoOrMmsi(_imoOrMmsi);

      setApiResponse(_apiResponse || {});

      if (_apiResponse?.Data && _apiResponse.Data.length > 0) {
        const export_data = [
          ..._apiResponse.Data.map((e) => {
            let data = { ...e.specification, ...e };

            delete data.DateDeleted;
            delete data.DateCreated; //this might be returned
            delete data.CreatedBy;
            delete data.LastModifiedDate; //this has been removed as well
            delete data.LastModifiedBy;
            delete data.VesselSpecificationId;
            delete data.CountryId;
            delete data.VesselTypeId;
            delete data.specification;
            data.ShipId = data?.ShipId || "n/a";
            data.lastActivityTime = toReadableDate(data.lastActivityTime);
            data.DynamicPositioning = dpValue.find(
              (item) => item?.DynamicPositioningId === data.DynamicPositioningId
            ).DynamicPositioning;
            return data;
          }),
        ];

        setExportData(export_data);
      } else {
        setExportData(null);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const retrieveDp = async () => {
    const dyp_List = await DPService.dpList();
    setDp(dyp_List || []);
  };
  const getPools = async () => {
    let _poolsList = await FleetService.listFleet();
    setPoolsList(camelizeKeys(_poolsList) || []);
  };
  const poolList = async () => {
    const poolOfVessels = await PoolService.fetchPool();
    setPoolWithVesselInfo(poolOfVessels);
  };
  const retrieveVessels = async () => {
    const vesselsList = await VesselService.listAllVessels();
    const vesselsListAbb = vesselsList?.map((vessel) => ({
      VesselName: vessel?.VesselName,
      VesselSpecificationId: vessel?.VesselSpecificationId,
    }));
    setVesselDetailsList(vesselsListAbb);
  };
  const pageActions = (
    <>
      {
        <div className="toggle-wrap nk-block-tools-toggle">
          <a
            href="#"
            className="btn btn-icon btn-trigger toggle-expand mr-n1"
            data-target="pageMenu"
          >
            <em className="icon ni ni-menu-alt-r" />
          </a>
          <div className="toggle-expand-content" data-content="pageMenu">
            <ul className="nk-block-tools g-3">
              {checkUserPermission("vessel_download_vessels") && (
                <li>
                  <a
                    href={
                      loading
                        ? "javascript:void(0)"
                        : `${process.env.REACT_APP_API}/api/VesselSpecifications/ExportToExcel?pageCount=1&authorization=${localStorage.token}`
                    }
                    target={loading ? "" : "_blank"}
                    className={`btn btn-dim btn-secondary ${
                      loading ? "disabled" : ""
                    }`}
                  >
                    <em className="icon ni ni-download" />
                    <span>Download Vessels</span>
                  </a>
                </li>
              )}
              {/* {exportData && checkUserPermission("vessel_export_page") && (
                <li>
                  <button
                    type="button"
                    onClick={() => exportToCSV(exportData, "list_of_vessels")}
                    className="btn btn-white btn-outline-light"
                    disabled={loading}
                  >
                    <em className="icon ni ni-download-cloud" />
                    <span>Export page</span>
                  </button>
                </li>
              )} */}
              {checkUserPermission("vessel_add_vessel") && (
                <li className="nk-block-tools-opt">
                  <div className="drodown">
                    <a
                      href="#"
                      className="dropdown-toggle btn btn-icon btn-primary"
                      data-toggle="dropdown"
                    >
                      <em className="icon ni ni-plus" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <ul className="link-list-opt no-bdr">
                        <li>
                          <Link to="/add-vessel">
                            <span>Add Vessel</span>
                          </Link>
                        </li>
                        {checkUserPermission("vessel_add_vessel_to_fleet") && (
                          <li onClick={() => setShowPoolModal(true)}>
                            <a>
                              <span>Add Vessel to Pool</span>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      }
    </>
  );

  let searchVesselList = async (value, _page, _pageSize) => {
    _page = 1; //when searching start from page 1
    setSearch(value);

    if (!value) {
      await fetchList(_page, _pageSize, "", null);
    } else if (value != "" && !isNaN(parseInt(value))) {
      //search value passed is a number, its likely imo or mmsi
      setImoOrMmsi(value);
      await fetchList(_page, _pageSize, null, value);
    } else {
      await fetchList(_page, _pageSize, value, null);
    }
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title={<>Vessels </>}
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <div className="col">
        <div className="">
          <VesselListComponent
            _apiResponse={apiResponse}
            search={search}
            onSearchChange={searchVesselList}
            reload={(_search, _page, _pageSize) =>
              fetchList(_page, _pageSize, _search)
            }
            setLoading={setLoading}
            page={page}
            pageSize={pageSize}
          />
        </div>
      </div>
      {showPoolModal && checkUserPermission("pool_add_vessel_pool") && (
        <CustomModal
          title={
            <p>
              <em class="icon ni ni-property-add"></em> Add Vessel to Pool
            </p>
          }
          content={
            <div>
              <AddVesselToPool
                entirePool={poolsList}
                vesselDetails={vesselDetailsList}
                poolWithVesselDetails={PoolWithVesselInfo}
              />
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-sm btn-link m-1"
                  onClick={() => setShowPoolModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          }
          onClose={() => setShowPoolModal(false)}
          // isClosed={false}
          // noContentPadding={true}
        />
      )}
      {/* .nk-block */}
    </MainLayout>
  );
}
