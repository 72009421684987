import React, { useEffect, useState, useRef } from "react";
import MainLayout from "../../layouts/mainLayout";
import { Link, Navigate } from "react-router-dom";
import Select from "react-select";
import LocationService from "../../services/locationService";
import FleetService from "../../services/fleetService";
import VesselService from "../../services/vesselService";
import ZoneService from "../../services/zoneService";
import PoolService from "../../services/vesselPoolsService";
import { dateToYYYY_MM_DD, toReadableDate } from "../../utils/utilityFunctions";
import payloadUpdater from "../../utils/payloadUpdater";
import { Validator } from "../../components/validator";
import toastr from "../../utils/toastr";

export default function AddVesselToPool(
  entirePool,
  vesselDetails,
  poolWithVesselDetails,
  // ClickedVal = () => null
) {
  let entirePoolItems = [];
  entirePoolItems.push(entirePool);
  const [fleetPayload, setFleetPayload] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState(null);

  const vesselToFleetPayload = new payloadUpdater({
    payload: fleetPayload,
    setPayload: setFleetPayload,
  }).update;
  const validationGroup = "addVesselToPool";
  const selectFieldRef = useRef();
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    setLoading(false);
  };

  let today = new Date();
  let dateForDbPost = today.toISOString();
  fleetPayload.DateCreated = dateForDbPost;

  const onFleetChange = (e) => {
    vesselToFleetPayload("FleetId", e.value);
  };


  const saveVesselToFleet = async () => {
    setLoading(true);
    if (!fleetPayload.VesselSpecificationId || !fleetPayload.FleetId) {
      toastr("error", "Please fill the empty fields");
      setLoading(false);
      return;
    }
    const vesselCheck = entirePoolItems[0].poolWithVesselDetails.find(
      (pool) =>
        pool?.VesselSpecificationId === fleetPayload?.VesselSpecificationId
    );
    if (vesselCheck) {
      toastr(
        "error",
        "Vessel is currently assigned to a Pool, redirecting you to the Switch Vessel page"
      );
      setLoading(false);
      setRedirectUrl(`/switch-vessel-pool/${vesselCheck.FleetId}`);
      // switchVesselPool();
      return;
    }

    const request = await PoolService.postPool(fleetPayload);
    setLoading(true);
    if (request) {
      toastr("success", "Vessel has been added to Pool!");
      // setRedirectUrl("/booking-requests");
      setLoading(false);
      setFleetPayload([]);
    } else {
      toastr("error", "Vessel could not be added to Pool");
      setLoading(false);
      return;
    }
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <div title="Add Vessel to Fleet" loading={loading} showFullLoader={loading}>
      <div className="card-inne p-2">
        <form>
          {/* <div className="row align-items-center"> */}
          <div className="row gy-4 p-3">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Pool Name</label>
                <div className="form-control-wrap">
                  <Select
                    ref={selectFieldRef}
                    options={entirePoolItems[0].entirePool?.map((list) => ({
                      ...list,
                      label: list.fleetName,
                      value: list.fleetId,
                    }))}
                    onChange={(option) => onFleetChange(option)}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group ">
                <label className="form-label" htmlFor="fw-last-name">
                  Name of Vessel
                </label>
                <div className="form-control-wrap">
                  <Select
                    ref={selectFieldRef}
                    options={entirePool.vesselDetails?.map((list) => ({
                      ...list,
                      label: list.VesselName,
                      value: list.VesselSpecificationId,
                    }))}
                    onChange={(option) => {
                      vesselToFleetPayload(
                        "VesselSpecificationId",
                        option.value
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          <div className="nk-tb-col">
            <span>&nbsp;</span>
          </div>
          <div className="text-center">
            <button
              id="toggleBtn"
              type="button"
              className="btn btn-md btn-dark btn-wide px-5 mr-3"
              onClick={() => saveVesselToFleet()}
            >
              Add Vessel
            </button>
            {/* <button
              type="button"
              className="btn btn-sm btn-link m-1"
              onClick={() => closeModalFunction()}
            >
              Cancel
            </button> */}
          </div>
        </form>
      </div>
    </div>
  );
}
