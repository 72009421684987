import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DocumentService from "../../services/documentService";
import BookingService from "../../services/bookingRequestService";

export default function UploadDeckPlan() {
    const params = useParams();
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deckPayload, setDeckPayload] = useState({});
    const [bulkPayload, setBulkPayload] = useState({});
    const [saving, setSaving] = useState(false);
    const [voyage, setVoyage] = useState({});
    const [bulkCargo, setBulkCargo] = useState([]);
    const [deckCargo, setDeckCargo] = useState([]);
    const [bulkCargoId, setBulkCargoId] = useState([]);
    const [deckCargoId, setDeckCargoId] = useState([]);
    const [bulkSaving, setBulkSaving] = useState(false);
    const [deckPlanSaving, setDeckPlanSaving] = useState(false);
    const [recordId, setRecordId] = useState(params.voyagePlanningId);

    const addDeckPlanPayload = new PayloadUpdater({ payload: deckPayload, setPayload: setDeckPayload }).update;
    const addBulkPlanPayload = new PayloadUpdater({ payload: bulkPayload, setPayload: setBulkPayload }).update;
    const validationGroup = "uploadEvidenceOfLoadedCargo";

    deckPayload.recordId = recordId;
    bulkPayload.recordId = recordId;
    useEffect(async () => {
        await retrieveConfigurations();
    }, []);

    const retrieveConfigurations = async () => {
        setLoading(true);

        let configurationDetails = await BookingService.dgClassificationList();//DeckCargoPlan"
        const retrieveDeckCargoId = configurationDetails?.find(a => a?.CorrespondingData.toLowerCase() === "deckcargoplan")?.ConfigurationId;
        const retrieveBulkCargoId = configurationDetails?.find(a => a?.CorrespondingData.toLowerCase() === "bulkcargoplan")?.ConfigurationId;
        bulkPayload.configurationId = retrieveBulkCargoId;
        deckPayload.configurationId = retrieveDeckCargoId;
        setLoading(false);
    };



    const uploadDeckPlan = async () => {
        const finalPayload = { ...deckPayload };
        if (!finalPayload.Base64File) {
            toastr("error", "Select a file to upload");
            return;
        }
        setDeckPlanSaving(true);
        delete finalPayload.fileName;
        const request = await DocumentService.uploadDeckCargoPlan(finalPayload);
        if (request) {
            setDeckPayload({});
            toastr("success", "Deck cargo plan saved!");
            setTimeout(() => window.location.reload(false), 5000);
        } else {
            toastr("error", "Deck cargo plan not uploaded!");
            setLoading(false);
            return;
        }
        setDeckPlanSaving(false);
    };
    const uploadBulkProof = async () => {
        const finalPayload = { ...bulkPayload };
        if (!finalPayload.Base64File) {
            toastr("error", "Select a file to upload");
            return;
        }
        setBulkSaving(true);

        delete finalPayload.fileName;
        const request = await DocumentService.uploadDeckCargoPlan(finalPayload);
        if (request) {
            setBulkPayload({});
            toastr("success", "Bulk cargo evidence uploaded");
            setTimeout(() => window.location.reload(false), 5000);
        } else {
            toastr("error", "Bulk cargo evidence not uploaded");
            setLoading(false);
            return;
        }
        setBulkSaving(false);
    };

    const pageActions =
        <div className="toggle-wrap nk-block-tools-toggle">
            <Link to="#" className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r" /></Link>
            <div className="toggle-expand-content" data-content="pageMenu">
                <ul className="nk-block-tools g-3">
                    <li>
                        <button
                            onClick={() => window.history.back()} className="btn btn-white btn-outline-light"><span>{"<< "}Return to Previous Page</span></button>
                    </li>

                </ul>
            </div>
        </div>
    return redirectUrl ? (
        <Navigate to={redirectUrl} replace={true} />
    ) : (
        <MainLayout title="Upload Deck Cargo Plan" loading={loading} pageActions={pageActions}>
            <form>
                <div className="card-inner">
                    <div className="row">
                        <div className="col-12">
                            <p>
                                <span className="preview-title-lg overline-title">
                                    Proof of Loaded Bulk Cargo / Bulk cargo Plan
                                </span>{" "}
                            </p>
                        </div>
                        {/* <div className="col-md-4">
                            <div className="form-group" style={{ zIndex: "50" }}>
                                <label className="form-label small">Bulk Cargo(s)</label>
                                <div className="form-control-wrap">
                                    <Select
                                        onChange={(e) => {
                                            addPayload("cargoDetailId", e.value);
                                            // handleBulkTypeChange(option);
                                        }}
                                        // isMulti={true}
                                        value={bulkCargo?.map((c) => ({
                                            ...c,
                                            label: c.BookingReference + " - " + c.ProductName,
                                            value: c.BulkCargoDetailId,
                                        }))?.find(a => a.value == payload?.BulkCargoDetailId)}
                                        options={bulkCargo?.map((c) => ({
                                            ...c,
                                            label: c.BookingReference + " - " + c.ProductName,
                                            value: c.BulkCargoDetailId,
                                        }))}
                                    />
                                </div>
                            </div>
                        </div> */}

                        <div className="col-md-4">
                            <div className="form-group" style={{ zIndex: "10" }}>
                                <label className="form-label small">Upload Evidence</label>
                                <div className="form-control-wrap">
                                    <FileInput
                                        onChange={(file) => {
                                            addBulkPlanPayload(
                                                ["Base64File"
                                                    // , "fileName"
                                                ],
                                                [file.base64
                                                    // , `${file.name}`
                                                ]
                                            );
                                        }}
                                        fileValue={{
                                            // name: bulkPayload.fileName,
                                            // size: bulkPayload.Base64File?.length || 0,
                                            fileContent: bulkPayload.Base64File,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {bulkSaving &&
                            <button type="button" className="btn btn-md btn-link mt-4" ><Spinner size="1.5" /></button>}

                        {!bulkSaving &&
                            <button type="button"
                                className="btn btn-md btn-dim btn-secondary btn-dark mt-4"
                                onClick={uploadBulkProof}
                            >Upload Evidence</button>}
                    </div>
                </div>


                <div className="card-inner">
                    <div className="row">
                        <div className="col-12">
                            <p>
                                <span className="preview-title-lg overline-title">
                                    Proof of Loaded Deck Cargo / Deck Cargo Plan
                                </span>{" "}
                            </p>
                        </div>

                        {/*  <div className="col-md-4">
                            <div className="form-group" style={{ zIndex: "20" }}>
                                <label className="form-label small">Deck Cargo(s)</label>
                                <div className="form-control-wrap">
                                    <Select
                                        onChange={(e) => {
                                            addPayload("cargoDetailId", e.value);
                                            // handleDeckTypeChange(option); setDeckPayload   deckPayload
                                        }}
                                        value={deckCargo?.map((c) => ({ ...c, label: c.BookingReference + " - " + c.DescriptionOfGoods, }))?.find(a =>
                                            a.value == payload?.DeckCargoDetailId)}
                                        options={deckCargo?.map((c) => ({
                                            ...c,
                                            label: c.BookingReference + " - " + c.DescriptionOfGoods,
                                            value: c.DeckCargoDetailId,
                                        }))}
                                    />
                                </div>
                            </div>
                        </div> */}

                        <div className="col-md-4">
                            <div className="form-group" style={{ zIndex: "10" }}>
                                <label className="form-label small">Upload Evidence</label>
                                <div className="form-control-wrap">
                                    <FileInput
                                        onChange={(file) => {
                                            addDeckPlanPayload(["Base64File"
                                                // , "fileName"
                                            ], [file.base64
                                                // , `${file.name}`
                                            ]
                                            );
                                        }}
                                        fileValue={{
                                            // name: deckPayload.fileName,
                                            // size: deckPayload.Base64File?.length || 0,
                                            fileContent: deckPayload.Base64File,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {deckPlanSaving &&
                            <button type="button" className="btn btn-md btn-link mt-4" ><Spinner size="1.5" /></button>}

                        {!deckPlanSaving &&
                            <button type="button"
                                className="btn btn-md btn-dim btn-secondary btn-dark mt-4"
                                onClick={uploadDeckPlan}
                            >Upload Evidence</button>}
                    </div>
                </div>
            </form>

            <hr className="preview-hr" style={{ visibility: "hidden" }} />
        </MainLayout>
    );
}