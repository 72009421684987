import React, { useEffect, useState, useCallback } from "react";
import MainLayout from '../../layouts/mainLayout';
import { Navigate, useParams } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import BookingService from "../../services/bookingRequestService";
import PartyService from "../../services/partyService";
import BookingEquipmentSheet from "../../utils/spreadTableEquipment";
import DeckCargoSheet from "../../utils/spreadSheetDeckCargo"
import SailingService from "../../services/sailingService";
import CustomModal from '../../utils/customModal';
import PayloadUpdater from '../../utils/payloadUpdater';
import LocationService from "../../services/locationService";
import EquipmentService from "../../services/equipmentService";
import { Validator, ValidateGroup } from "../../components/validator";
import CloseButton from 'react-bootstrap/CloseButton';
import { camelizeKeys } from "../../utils/utilityFunctions";
import { Overlay, Tooltip, Button, Row, Popover, OverlayTrigger } from "react-bootstrap";
import { cycleColor, dateToYYYY_MM_DD, randomColor, randomNumber, toReadableDate } from "../../utils/utilityFunctions";

export default function EditBookingRequest() {
    const [hoveredScheduleVisits, sethoveredScheduleVisits] = useState([]);
    const [cachedScheduleVisits, setCachedScheduleVisits] = useState([]);
    const [loadinghoveredScheduleVisits, setLoadinghoveredScheduleVisits] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [allTubularTypeId, setAllTubularTypeId] = useState("");

    const params = useParams();
    const [myEventsList, setMyEventsList] = useState(null);
    const localizer = momentLocalizer(moment);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(new Date().toLocaleDateString());
    const [endDate, setEndDate] = useState(new Date().toLocaleDateString());
    const [deckButtonClicked, setDeckButtonClicked] = useState(false);
    const [bookingButtonClicked, setBookingButtonClicked] = useState(true);
    const [schedules, setSchedules] = useState([]);
    const [sailingsAndDetails, setSailingAndDetails] = useState([]);
    const [bookingRequestId, setBookingRequestId] = useState(params.bookingRequestId);
    const [retrievedBooking, setRetrievedBooking] = useState(null);
    const [sailingName, setSailingName] = useState();
    const [buttonClicked, setButtonClicked] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [selectedSailingId, setSelectedSailingId] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [payload, setPayload] = useState({});
    const [bookingDirection, setBookingDirection] = useState()
    const [locationsList, setLocationsList] = useState([]);
    const [portOptions, setPortOptions] = useState([]);
    const [installationOptionList, setInstallationOptionList] = useState([]);
    const [operatorsList, setOperatorsList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [cargoDataDetails, setCargoDataDetails] = useState([]);
    const [customStatus, setCustomStatus] = useState();
    const [cargoEquipmentType, setCargoEquipmentType] = useState();
    const [cargoEquipment, setCargoEquipment] = useState({});
    const [preferredCargoEquipment, setPreferredCargoEquipment] = useState([]);
    const [measurementUnitsBulkItem, setMeasurementUnitsBulkItem] = useState([]);
    const [measurementUnit, setMeasurementUnit] = useState({});
    const [deckCargoData, setDeckCargoData] = useState([
        ["", "", "", "", "", "", "", "", ""]
    ]);
    const [bookingEquipmentContent, setBookingEquipmentContent] = useState([
        ["", "", "", "", "", "", "", "", ""]
    ])
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [uneditedBookingData, setUneditedBookingData] = useState([]);
    const [ownersList, setOwnersList] = useState([]);
    const [measurementsPrime, setMeasurementsPrime] = useState([]);
    const [measurements, setmeasurements] = useState([]);

    const [dgList, setDgList] = useState([]);
    const [unCodeList, setUnCodeList] = useState([]);
    const [pgList, setPgList] = useState([]);
    const [hazardClassList, setHazardClassList] = useState([]);
    const [allCargoCarryingEquipments, setAllCargoCarryingEquipments] = useState([]);
    const [chosenOption, setChosenOption] = useState("");
    const [toggleCheckBox, setToggleCheckBox] = useState(false)
    const [bulkItemsList, setBulkItemsList] = useState([]);
    const [selectedSailingInfo, setSelectedSailingInfo] = useState([]);


    const validationGroup = "editBookingRequest";
    const bookingDetailsPayload = new PayloadUpdater({ payload: payload, setPayload: setPayload }).update;
    const bulkCargoPayload = new PayloadUpdater({ payload: cargoDataDetails, setPayload: setCargoDataDetails }).update;
    const directionOptions = [
        { label: "Outbound", value: "Outbound" },
        { label: "Interfield", value: "Interfield" },
        { label: "Inbound", value: "Inbound" }
    ];
    let customsStatus = [
        { label: "Cleared", value: "cleared" },
        { label: "Awaiting clearance", value: "awaiting clearance", }]
    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setLoading(true);
        await retrieveSchedules();
        await retrieveMeasurementUnit();
        const requestDetails = await fetchBookingRequestDetails();
        await userPreferredCargoEquipment();
        // await locations();
        await locationTypes();
        await operators();
        await listOfProducts();
        await partyOption();
        await retrieveDgClasses();
        await retrieveSailing(requestDetails)
        await setCustomStatus(requestDetails?.cargos[0]?.CustomStatus);
        await setBookingDirection(requestDetails?.Direction);
        retrieveSelectedSailingInfo(requestDetails?.SailingId);
        setLoading(false);
    }
    const calenderPopOver = useCallback((schedule) => {
        const popover =

            (
                <Popover id="calender-popover" show={false}>
                    <Popover.Header as="h3">{schedule.event.title}
                        <CloseButton className="icon ni ni-cross btn-danger float-sm-right"
                            onClick={() => setShowOverlay(false)} />
                    </Popover.Header>
                    <Popover.Body>
                        {loadinghoveredScheduleVisits ? (
                            <div className="text-center p-3">
                                <Spinner />
                            </div>
                        ) : (
                            <div
                                style={{ maxHeight: "140px", overflowY: "auto" }}
                                className="small-scrollbar pr-2"
                            >
                                <small className="d-block mt-2">
                                    Start: {moment(schedule.event.start).format("llll")}
                                </small>
                                <small className="d-block my-1">
                                    End: {moment(schedule.event.end).format("llll")}
                                </small>

                                <div>
                                    <h6 className="mt-2">Locations</h6>
                                    {hoveredScheduleVisits.map((visit, index) => (
                                        <small className="d-block my-1" key={index}>
                                            {visit.LocationName}: {visit.DayOnLocationFormatted}
                                        </small>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Popover.Body>
                </Popover>
            );

        const overlay =
            <OverlayTrigger
                placement="auto"
                overlay={popover}
                id="calender-overlay"
                show={false}
            >
                <div className="d-flex align-center" >
                <b>{schedule.event.title}</b>
          <span className="small ml-1">
            {schedule.event.SailingRoute && `(${schedule.event.SailingRoute})`}
          </span>
                </div>
            </OverlayTrigger>

        return overlay;
    }, [showOverlay]);

    const randomiseCalenderEntryColor = useCallback((event) => {
        return { style: event.style };
    }, []);
    const closeAndClearModal = () => {
        setToggleCheckBox(false)
        setChosenOption("");
    }
    const modalCheckBox = () => {
        return (
            <div >

                <CustomModal
                    title={<span><em class="icon ni ni-info-i"></em> Confirm Save</span>}
                    content={<div className="text-center">

                        <span><p><h6>Kindly click 'confirm' to save changes to booking request.</h6></p></span>
                        <br />
                        <div>
                            <input type="button" className="btn btn-md btn-dark btn-wide" value="Confirm" name="chosenOption" onClick={() => toggleChosenOptionYes()} />
                            <input type="button" className="btn btn-sm btn-link m-1" value="Cancel" name="chosenOption" onClick={() => toggleChosenOptionNo()} />
                        </div>
                    </div>}
                    onClose={() => closeAndClearModal()}
                />
            </div>
        )
    }
    const toggleChosenOptionYes = () => {
        saveBookingChanges();
        closeAndClearModal();
    }
    const toggleChosenOptionNo = () => {
        closeAndClearModal();
    }
    const fetchBookingRequestDetails = async () => {
        const retrievedBookingRequest = await BookingService.getBookingRequest(bookingRequestId);
        setRetrievedBooking(retrievedBookingRequest);
        setBulkItemsList(retrievedBookingRequest?.cargos)
        return retrievedBookingRequest;
    }
    const retrieveDgClasses = async () => {
        const dgFullList = await BookingService.dgClassificationList();
        // Retrieve ID for DG class category
        const retrievedDgId = dgFullList?.find(a => a?.CorrespondingData.toLowerCase() === "ibc ")?.ConfigCategoryId;
        const completeDgItems = dgFullList?.filter(item => item?.ConfigCategoryId === retrievedDgId)?.map(obj =>
        ({
            id: obj.ConfigurationId,
            name: obj?.CorrespondingData
        })
        ) || "";
        setDgList(completeDgItems)
        const retrieveUnId = dgFullList?.find(a => a?.CorrespondingData.toLowerCase() === "2011")?.ConfigCategoryId;
        const completeUnCodes = dgFullList?.filter(item => item?.ConfigCategoryId === retrieveUnId)?.map(obj =>
        ({
            id: obj.ConfigurationId,
            name: obj?.CorrespondingData
        })
        ) || "";
        setUnCodeList(completeUnCodes);
        const retrievePgGrpId = dgFullList?.find(a => a?.CorrespondingData.toLowerCase() === "packing group (pg) i")?.ConfigCategoryId;
        const completePGList = dgFullList?.filter(item => item?.ConfigCategoryId === retrievePgGrpId)?.map(obj =>
        ({
            id: obj.ConfigurationId,
            name: obj?.CorrespondingData
        })
        ) || "";
        setPgList(completePGList);
        const retrievedhazardClassId = dgFullList?.find(a => a.CorrespondingData.toLowerCase() === "class 1 ")?.ConfigCategoryId;
        const completeHazardClassList = dgFullList?.filter(item => item?.ConfigCategoryId === retrievedhazardClassId)?.map(obj =>
        ({
            id: obj.ConfigurationId,
            name: obj.CorrespondingData
        })
        ) || "";
        setHazardClassList(completeHazardClassList)

    }
    const partyOption = async () => {
        let listOfParties = await PartyService.listParty();

        // list of Operators
        let listOfOperatorsOnly = listOfParties.filter((party) => party.PartyType?.toLowerCase() === "operator")
        setOperatorsList(listOfOperatorsOnly);

        let editedList = listOfParties.filter((party) => party.PartyType?.toLowerCase() === "ccu owner")
        editedList = editedList?.map((obj) => ({
            id: obj.PartyId,
            name: obj.PartyName,
        }));
        setOwnersList(editedList);
    }
    const handleSelect = (e) => {
        setSelectedSailingId(e.SailingId);
        setSelectedSchedule(e);
        setModalIsOpen(true);
        sailing(e.SailingId);
        retrieveSelectedSailingInfo(e.SailingId);
        setButtonClicked(false);
    };
    const sailing = async () => {
        const specificSailing = await SailingService.getUnlockedSailingDetails();
        setSailingAndDetails(specificSailing);//SailingId
    }
    const listOfProducts = async () => {
        const listProducts = await BookingService.products();
        setProductList(listProducts?.Data);
    }
    const measuementItemsToFilter = ["hour", "kelvin", "second", "knots", "inch",
    "minute", "watt", "horsepower", "meter per second", "kilometer per ho", "kilowatt",
    "meter", "square meter", "centimeter", "cubic meter per hour", "square centimeter"]
   
        const tableMeasurementFilter = ["hour", "kelvin", "second", "knots", "inch",
        "cubic centimeter", "cubic meter per ", "cubic meter", "liters", "milliliter", 
        "minute", "watt", "horsepower", "meter per second", "kilometer per ho", "kilowatt",
        "meter", "square meter", "centimeter", "cubic meter per hour", "square centimeter"]
        const retrieveMeasurementUnit = async () => {
        const unitsList = await EquipmentService.listMeasurementUnits();
        setMeasurementUnit(unitsList);
        let editedUnits = unitsList?.map(obj =>
        ({
            name: obj.MeasurementUnit,
            id: obj.MeasurementUnitId,
        })
        );
        //removing the non-weight measurement units from the list.
        editedUnits = editedUnits?.filter((units) => !measuementItemsToFilter.find(list => (list === units.name)))
        setMeasurementUnitsBulkItem(editedUnits);
        let editedUnitedForSheets = editedUnits?.filter((units) => !tableMeasurementFilter.find(list => (list === units.name)))
        setMeasurementsPrime(editedUnitedForSheets);
        setmeasurements(unitsList);
    }
    const retrieveSchedules = async () => {
        const schedulesInfo = await SailingService.getUnlockedSailingDetails();
        const _schedules = schedulesInfo?.Data;
        if (!_schedules || _schedules == null) setSchedules([]);
        else {
            setSchedules(_schedules);
            setMyEventsList(
                _schedules.map((schedule, index) => ({
                    ...schedule,
                    title: "Sailing " + schedule.SailingNo,
                    start: schedule.DayOfSailing,
                    end: schedule.DayOfReturnInPort,
                    style: {
                        backgroundColor: `${cycleColor(index)}`,
                    },
                }))
            );
        }
    };
    const MyCalendar = useCallback((props) => (
        <>
            {myEventsList && (
                <div>
                    <Calendar
                        localizer={localizer}
                        events={myEventsList || []}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 420 }}
                        onSelectEvent={(event) => handleSelect(event)}
                        onSelectSlot={(e) => handleSelect()}
                        // onNavigate={(date) => calendarNavigated(date)}
                        eventPropGetter={randomiseCalenderEntryColor}
                        components={{
                            event: calenderPopOver,
                        }}
                        views={[Views.MONTH]}
                    />
                </div>
            )}
        </>
    ), [myEventsList])

    const displayCalendar = () => {
        // myEventsList && <MyCalendar></MyCalendar>
        return (<div>
            {myEventsList && <MyCalendar></MyCalendar>}
        </div>)
    }
    const displayToggle = (prop) => {
        return (
            <>
                {myEventsList && (
                    <div>
                        <CustomModal
                            title={"Select preferred Sailing"}
                            content={<MyCalendar></MyCalendar>}
                            // content={displayCalendar()}
                            onClose={() => setButtonClicked(false)}
                        />
                    </div>
                )}
            </>
        );
    };
    const handleClick = () => {
        if (!buttonClicked) {
        }
        // setButtonClicked(true);
        setButtonClicked(true);
    }
    const receiveBookingEquipmentDetails = (equipment) => {
        setBookingEquipmentContent(equipment); // This would be set to session storage (or state object if it won't result in re-render)
    }
    const userPreferredCargoEquipment = async () => {
        const preferredEquipment = await BookingService.cargoEquipment();
        const tubularId = preferredEquipment?.find((item) => item?.equipmentType.toLowerCase() === "tubular/casing (drill collar)")?.equipmentTypeId
        setAllTubularTypeId(tubularId)
        setPreferredCargoEquipment(preferredEquipment)
        setCargoEquipmentType(preferredEquipment);
        const equipDescription = preferredEquipment?.map((obj) => ({
            id: obj.tubularCategoryId || obj.equipmentTypeId,
            name: obj.equipmentType,
        }));
        setCargoEquipment(equipDescription);
        setAllCargoCarryingEquipments(equipDescription);

    }
    let selectedSailing = myEventsList?.find(sortedSailing => sortedSailing.SailingId === selectedSailingId)?.title;
    let selectedSailingDetails = (sailingsAndDetails?.Data?.find((list) => list?.SailingId === selectedSailingId))
        || (myEventsList?.find((list) => list?.SailingId === retrievedBooking?.SailingId))



    let locationForSailing = [];
    let operatorForSailing = [];
    let offshoreInstallationOptions = [];
    if (selectedSailingDetails) {
        let operatorForSailingList = selectedSailingDetails?.SailingRoutes?.map((list) => ({
            IsBulkRequirement: list?.IsBulkRequirement,
            LocationId: list?.LocationId,
            LocationName: list?.LocationName,
            PartyName: list?.PartyName,
            PartyId: list?.PartyId
        }))
        // operatorForSailingList = operatorForSailingList.map((obj) =>
        //   Object.fromEntries(Object.entries(obj).filter(([key, value]) => !!value)));//removes objects with null values
        operatorForSailingList.forEach((item, index, object) => {
            if (!item.PartyId || !item.PartyName) {
                object.splice(index, 1);
            }
        })
        locationForSailing?.splice(0, locationForSailing?.length, ...operatorForSailingList)

        const uniqueOperatorsList = [...new Map(locationForSailing.map(item => [item.PartyName, item])).values()]
        operatorForSailing?.splice(0, operatorForSailing?.length, ...uniqueOperatorsList)

        let offshoreInstallationList = operatorForSailingList.filter((item) => item?.PartyId === (payload?.PartyId || retrievedBooking?.PartyId))
        const uniqueList = [...new Map(offshoreInstallationList.map(item => [item.LocationName, item])).values()]

        offshoreInstallationOptions?.splice(0, offshoreInstallationOptions?.length, ...uniqueList)

    }

    const retrieveSailing = async (requestDetails) => {
        const entireSailingList = await SailingService.getSailingSchedule();
        const specificSailing = entireSailingList?.Data?.find(obj => obj.SailingId === requestDetails?.SailingId)?.SailingNo;
        setSailingName("Sailing " + specificSailing);
    }
    const receiveDeckCargoDetails = (list) => {
        setDeckCargoData(list);
    }
    const onDirectionChange = (options) => {
        setBookingDirection(options.value);
        bookingDetailsPayload("direction", options.value)
    }
    const onCustomStatusChange = (options) => {
        setCustomStatus(options.value);
        bulkCargoPayload("customStatus", options.value)
    }
    const locationTypes = async () => {
        const locationsTypes = await LocationService.list();
        const retrievePortFilter = locationsTypes?.find(p => p.LocationTypeName?.toLowerCase() === 'port')
        let retrievedPort = retrievePortFilter?.LocationTypeId;
        const ports = locationsTypes?.filter(p => p?.LocationTypeId === retrievedPort)
        const offshoreFilters = locationsTypes?.find(
            (p) => p.LocationTypeName?.toLowerCase() === "offshore installation"
        );
        let retrievedInstallation = offshoreFilters?.LocationTypeId;
        const offshoreInstallationsList = locationsTypes?.filter(
            (p) => p?.LocationTypeId === retrievedInstallation
        );
        setInstallationOptionList(offshoreInstallationsList)
        setLocationsList(locationsTypes);
        setPortOptions(ports);
    }
    const operators = async () => {
        let listOfOperators = await PartyService.listParty();
        setOperatorsList(listOfOperators);
    }
    const toggleDeckCargo = () => {
        setBookingButtonClicked(false)
        setDeckButtonClicked(true)
    }
    const toggleBookingEquipmentTable = () => {
        setDeckButtonClicked(false)
        setBookingButtonClicked(true)
    }
    let entireBookingData = bookingEquipmentContent.map((obj) => ({
        id: obj[0],
        name: preferredCargoEquipment?.find(equip => equip?.equipmentTypeId === obj[0])?.equipmentType ||
            preferredCargoEquipment?.find(equip => equip?.tubularCategoryId === obj[0])?.equipmentType,
        ccuNO: obj[6],
        dimension: obj[7]
    }))

    //Index was used as ID because 
    let newEntireBookingData = entireBookingData.map((newlist, index) => ({
        id: index + 1,
        dbId: newlist.id,
        name: (newlist.name !== "Offshore Container") ? `${newlist.name} - ${newlist.dimension}` :
            `${newlist.name} - ${newlist.ccuNO}`
    }))
    let cargoMeasurementUnitId = retrievedBooking?.cargos[0]?.MeasurementUnitId;
    let bookingEquip = retrievedBooking?.bookingEquipment;
    //The gymnastics in the few lines below is to return a "null" value in place of uuid for a "||" operator ...=>
    //that was made in spreadTableEquipment inside this function => 'userPreferredCargoEquipment'
    bookingEquip = bookingEquip?.map(bookingInfo => ({
        ...bookingInfo,
        TubularCategoryId: bookingInfo.TubularCategoryId === "00000000-0000-0000-0000-000000000000" ? null : bookingInfo.TubularCategoryId,

        specificCargoEquipmentId: (bookingInfo.TubularCategoryId === "00000000-0000-0000-0000-000000000000" ? null : bookingInfo.TubularCategoryId) || bookingInfo.CargoCarryingEquipmentTypeId,
    }))

    let deckCargo = retrievedBooking?.deckCargos;
    //The gymnastics in the few lines below is to return a "null" value in place of uuid for a "||" operator
    //this also ensures we use same specificCargoEquipmentId for both tubular and cargoCarryingEquipmentId
    deckCargo = deckCargo?.map(bookingInfo => ({
        ...bookingInfo,
        TubularCategoryId: bookingInfo.TubularCategoryId === "00000000-0000-0000-0000-000000000000" ? null : bookingInfo.TubularCategoryId,

        specificCargoEquipmentId: (bookingInfo.TubularCategoryId === "00000000-0000-0000-0000-000000000000" ? null : bookingInfo.TubularCategoryId) || bookingInfo.CargoCarryingEquipmentTypeId,
        newDg: bookingInfo.IsDG === true ? "Yes" : "No"
    }))

    let updatedDeckCargo = deckCargo?.map((list) => ({
        ...list,
        name: newEntireBookingData?.find((item) => item.dbId === list?.specificCargoEquipmentId)?.id
    }))


    let bulkCargo = retrievedBooking?.cargos[0];

    let sailingWithBulkItems = [];
    if (selectedSailingDetails) {

        let bulkItemCheck = selectedSailingDetails?.SailingRoutes?.filter((list) => list?.IsBulkRequirement === true)
        sailingWithBulkItems.push(bulkItemCheck)

    }

    // ||
    //     sailingWithBulkItems[0]?.find((party) => party?.PartyId === retrievedBooking?.PartyId)?.IsBulkRequirement;

    const selectBulkCargo = (value, index) => {
        let items = bulkItemsList;
        items[index]["productId"] = value.value;
        setBulkItemsList(items);
    };
    const bulkItemQuantity = (value, index) => {
        let items = bulkItemsList;
        items[index]["quantity"] = value.value;
        setBulkItemsList(items);
    };
    const quantityUnit = (value, index) => {
        let items = bulkItemsList;
        items[index]["measurementUnitId"] = value.value;
        setBulkItemsList(items);
    };
    const customsStatusOfItem = (value, index) => {
        let items = bulkItemsList;
        items[index]["customStatus"] = value.value;
        setBulkItemsList(items);
    };
    const specificGravityOfItem = (value, index) => {
        let items = bulkItemsList;
        items[index]["specificGravity"] = value.value;
        setBulkItemsList(items);
    };
    const removeBulkItem = (index) => {
        let items = [...bulkItemsList];
        items.splice(index, 1);
        setBulkItemsList(items);
    };


    let bookingPayload =
    {
        bookingRequestId: retrievedBooking?.BookingRequestId,
        sailingId: selectedSailingId || retrievedBooking?.SailingId,
        bookingReferenceNumber: retrievedBooking?.BookingReferenceNumber,
        bookingStatus: retrievedBooking?.BookingStatus,
        direction: payload?.direction || retrievedBooking?.Direction,
        portOfLoading: payload?.portOfLoading || retrievedBooking?.PortOfLoading,
        portOfDischarge: payload?.portOfDischarge || retrievedBooking?.PortOfDischarge,
        offshoreInstallation: payload?.offshoreInstallation || retrievedBooking?.OffshoreInstallation,
        expectedHandlingTimeOnLocation: retrievedBooking?.ExpectedHandlingTimeOnLocation,
        partyId: payload?.PartyId || retrievedBooking?.PartyId,
        manifestNumber: payload?.manifestNumber || retrievedBooking?.ManifestNumber,
        secondOffshoreInstallation: payload?.secondOffshoreInstallation || retrievedBooking?.secondOffshoreInstallation,
    };

    const changeOffshoreInstallation = (option) => {
        bookingDetailsPayload("offshoreInstallation", option.value)
        retrieveSelectedSailingInfo(bookingPayload?.sailingId)
    }
    const retrieveSelectedSailingInfo = async (retrievedId) => {
        const sailingDetailedInfo = await SailingService.getSailingLocationBySailing(retrievedId)
        setSelectedSailingInfo(sailingDetailedInfo);
    }
    const selectSailingLocation = selectedSailingInfo?.find((item) => item?.LocationId === bookingPayload?.offshoreInstallation)
    let formattedDayOnLocation = toReadableDate(selectSailingLocation?.DayOnLocation);
    let timeOnLocation = selectSailingLocation?.ReqTimeOnLocationInHrs;



    //removing payload items that are not relevent to specified direction.
    if (bookingPayload?.direction === "Outbound") {
        delete bookingPayload?.secondOffshoreInstallation
        delete bookingPayload?.portOfDischarge
    }
    else if (bookingPayload?.direction === "Inbound") {
        delete bookingPayload?.secondOffshoreInstallation
        delete bookingPayload?.portOfLoading
    }
    else if (bookingPayload?.direction === "Interfield") {
        delete bookingPayload?.portOfLoading
        delete bookingPayload?.portOfDischarge
    }
    let finalBulkCargoPayload = camelizeKeys(bulkItemsList)
    finalBulkCargoPayload = finalBulkCargoPayload?.map((newlist) => ({
        bulkCargoDetailId: newlist?.bulkCargoDetailId || "00000000-0000-0000-0000-000000000000",
        bookingRequestId: newlist?.bookingRequestId || retrievedBooking?.BookingRequestId,
        productId: newlist?.productId,
        quantity: newlist?.quantity,
        measurementUnitId: newlist?.measurementUnitId,
        specificGravity: newlist?.specificGravity,
        customStatus: newlist?.customStatus,
        // voyagePlanningId: newlist?.voyagePlanningId ,
        // shipSideEquipmentId: newlist?.shipSideEquipmentId || "00000000-0000-0000-0000-000000000000",
        // loadedCargoEvidence: newlist?.loadedCargoEvidence
    }))

    const bookingEquipmentHash = {};
    let bookingEquipmentPayload = bookingEquipmentContent?.map((bookingContent, index) => {
        let uniqueObj = `${bookingContent[0]} - ${bookingContent[7]} - ${bookingContent[6]}`
        if (!bookingEquipmentHash[uniqueObj]) {
            bookingEquipmentHash[uniqueObj] = index;
        }
        return {
            cargoCarryingEquipmentTypeId: cargoEquipmentType?.find(equipmentId => equipmentId?.tubularCategoryId === bookingContent[0])?.equipmentTypeId || bookingContent[0],
            PartyId: bookingContent[1] || "00000000-0000-0000-0000-000000000000",
            QuantityOfEquipment: bookingContent[2],
            SealNo: bookingContent[3],
            EstimatedGrossWeight: bookingContent[4],
            MeasurementUnitId: bookingContent[5] || "00000000-0000-0000-0000-000000000000",
            CCUNumber: bookingContent[6],
            Description: bookingContent[7],
            FullEmptyIndication: bookingContent[8],
            bookingRequestId: bookingRequestId || bookingContent[9],
            tubularCategoryId: cargoEquipmentType?.find(equipmentId => equipmentId?.tubularCategoryId === bookingContent[0])?.tubularCategoryId || bookingContent[0],
            bookingEquipmentDetailId: bookingContent[10] || "00000000-0000-0000-0000-000000000000",
            // cargoCarryingEquipmentId: null,
        }
    })

    //adding key to deckCargoEquipment
    let deckCargoPayload = deckCargoData.map((deckData) => ({
        equipmentIndex: parseInt(deckData[0]) - 1,//ensuring the index starts from 0
        descriptionOfGoods: deckData[9],
        serialNumber: deckData[1],
        measurementValue: deckData[6],
        measurementValueUnitId: deckData[7] || "00000000-0000-0000-0000-000000000000",
        hsCode: deckData[10],
        customsStatus: deckData[8],
        grossWeightValue: deckData[2],
        grossWeightUnitId: deckData[3] || "00000000-0000-0000-0000-000000000000",
        netWeightValue: deckData[4],
        netWeightUnitId: deckData[5] || "00000000-0000-0000-0000-000000000000",
        numberOfPackages: deckData[11],
        typeOfPackage: deckData[12],
        isDG: (deckData[13] === "Yes" ? true : "" || deckData[13] === "No" ? false : "") || (deckData[13] === "Yes" ? "true" : "" || deckData[13] === "No" ? "false" : ""),
        dgClassificationConfigId: deckData[14] || "00000000-0000-0000-0000-000000000000",
        imoHazardClassConfigId: deckData[18] || "00000000-0000-0000-0000-000000000000",
        unNumberConfigId: deckData[15] || "00000000-0000-0000-0000-000000000000",
        packingGroupConfigId: deckData[19] || "00000000-0000-0000-0000-000000000000",
        subsidiaryRisk: deckData[16],
        flashpoint: deckData[20],
        marpolPollutionCode: deckData[17],
        emS: deckData[21],
        deckCargoDetailId: deckData[22] || "00000000-0000-0000-0000-000000000000",
        bookingRequestId: bookingRequestId || deckData[23],
        bookingEquipmentDetailId: newEntireBookingData?.find((item) => (item?.id).toString() === deckData[0])?.dbId || "00000000-0000-0000-0000-000000000000",
    }))

    let bookingRequest = bookingPayload;
    let cargosPayload = finalBulkCargoPayload;
    let bookingEquipment = camelizeKeys(retrievedBooking?.bookingEquipment);
    let deckCargos = retrievedBooking?.deckCargos;


    let operatorHasBulk = (sailingWithBulkItems[0]?.find((party) => party?.PartyId === bookingRequest?.partyId)?.IsBulkRequirement)

    //When there is a change to the sheet bookingEquipment is loaded with updated data from the excel sheet.
    //the function to retrieve Input has been tied with an onChange function in the excel sheet
    if (bookingEquipmentContent?.length > 0) {
        bookingEquipment = bookingEquipmentPayload;
    }
    if (deckCargoData.length > 0) {
        deckCargos = deckCargoPayload;
    }
    let cargos = [];
    if (cargosPayload) {
        cargos?.splice(0, cargos.length, ...cargosPayload)
    }
    let editedBookingData = bookingEquipment

    let bigBagId = allCargoCarryingEquipments?.find((item) => item?.name.toLowerCase() === "bigbag")?.id
    let containerId = allCargoCarryingEquipments?.find((item) => item?.name.toLowerCase() === "offshore container")?.id

    let conditionalBookingItems = ["cargoCarryingEquipmentTypeId", "partyId", "ccuNumber",
        "quantityOfEquipment", "description"];

    let conditionalDeckCargoItems = ["dgClassificationConfigId", "imoHazardClassConfigId", "unNumberConfigId", "packingGroupConfigId"]

    const newValidation = () => {
        const validateBookingItemsFailed = bookingEquipment.find((request) => {
            return conditionalBookingItems.find((item) => {
                return (
                    ((request.cargoCarryingEquipmentTypeId === containerId) && (request.PartyId === "00000000-0000-0000-0000-000000000000")) ||
                    ((request.cargoCarryingEquipmentTypeId === containerId) && (request.CCUNumber === "")) ||
                    ((request.cargoCarryingEquipmentTypeId === containerId) && (request.QuantityOfEquipment > 1)) ||
                    ((request.cargoCarryingEquipmentTypeId === containerId) && (request.QuantityOfEquipment === "")) ||
                    ((request.cargoCarryingEquipmentTypeId === allTubularTypeId || (request.cargoCarryingEquipmentTypeId === bigBagId)) && (request.PartyId != "00000000-0000-0000-0000-000000000000")) ||
                    ((request.cargoCarryingEquipmentTypeId === allTubularTypeId || (request.cargoCarryingEquipmentTypeId === bigBagId)) && (request.Description === "")) ||
                    ((request.cargoCarryingEquipmentTypeId === allTubularTypeId || (request.cargoCarryingEquipmentTypeId === bigBagId)) && (request.CCUNumber != ""))
                );
            });
        });
        if (!validateBookingItemsFailed) {
            setLoading(false);
            return !deckCargos.find((request) => {
                return (
                    conditionalDeckCargoItems.find((item) => {
                        return (
                            ((request.isDG === true) && (request[item] === "00000000-0000-0000-0000-000000000000"))
                        )
                    })
                )
            })
        }
        else {
            return false;
        }
    }

    //#region For tracking the spreadtable contents
    //bookingEquipmentContentReplica(a copy of bookingEquipmentContent) array tracks input and changes to the BookingEquipment table.
    //it has been flattened and converted to a Set object which returns the length to 1.
    //If any value different from ''(empty string) is added, the length increases accordingly.
    let bookingEquipmentContentReplica = bookingEquipmentContent.flat();//it was copied to enable us convert it to a Set
    bookingEquipmentContentReplica = [...new Set(bookingEquipmentContentReplica)]

    let deckCargoReplica = deckCargoData.flat();
    deckCargoReplica = [...new Set(deckCargoReplica)]


    let nonDgKeys = ["dgClassificationConfigId", "imoHazardClassConfigId", "unNumberConfigId", "packingGroupConfigId"]
    const validatePayload = () => {
        const requiredBookingRequestKeys = ["cargoCarryingEquipmentTypeId",
            "QuantityOfEquipment",
            "EstimatedGrossWeight",
            "MeasurementUnitId"];
        const requiredDeckCargoKeys = ["equipmentIndex", "numberOfPackages", "descriptionOfGoods", "measurementValue",
            "measurementValueUnitId", "grossWeightValue", "grossWeightUnitId", "netWeightValue", "netWeightUnitId",
            "numberOfPackages", "isDG"];
        const validateBookingRequestFailed = bookingEquipment.find(request => {
            return requiredBookingRequestKeys.find(item => {
                return (request[item] === "") || (request[item] === "00000000-0000-0000-0000-000000000000")
            })
        })
        if (!validateBookingRequestFailed) {
            return !deckCargos.find(request => {
                return requiredDeckCargoKeys.find(item => {
                    return (request[item] === "") || (request[item] === "00000000-0000-0000-0000-000000000000")
                }) || request.equipmentIndex === undefined
            })
        }
        else {
            return false;
        }
    }

    //#endregion end of spreadsheet validation logic........
    const saveBookingChanges = async () => {
        let bookingInfo = {
            bookingRequest,
            cargos,
            deckCargos,
            bookingEquipment,
        };
        const validatorStatus = ValidateGroup(validationGroup);
        if (
            !validatorStatus
        ) {
            toastr("error", "Incomplete booking request information, please fill the empty fields in the first/second section.");
            return;
        }
        if ((!validatePayload()) && (bookingEquipmentContentReplica?.length > 1)) return toastr("error", "Some required fields are empty, check booking equipment and deck cargo information!");

        if (!newValidation() && (bookingEquipmentContentReplica?.length > 1))
            return toastr("error", "Please check booking equipment and deck cargo information sheet, required fields are missing!");

        if ((deckCargoReplica?.length > 1) && (bookingEquipmentContentReplica?.length <= 1)) return toastr("error", "Deck cargo records cannot be saved without Booking equipment!");
        if ((bookingInfo.cargos.length === 0) && (bookingEquipmentContentReplica?.length <= 1)) return toastr("error", "You must saved a Bulk cargo or Booking equipment!");
        if (bookingEquipmentContentReplica?.length <= 1) {
            bookingInfo = {
                bookingRequest,
                cargos,
            };
        }
        setLoading(true);
        const request = await BookingService.updateBookingDetails(bookingInfo);
        setLoading(true);
        if (request) {
            toastr("success", "Booking request added");
            setRedirectUrl("/booking-requests");
            setLoading(false);
        } else {
            toastr("error", "Booking request could not be added");
            setLoading(false);
            return;
        }
    }

    return (
        redirectUrl ? <Navigate to={redirectUrl} replace={true} /> :
            < MainLayout title="Edit Booking Request" loading={loading}
                showFullLoader={loading} >
                <div className="card-inner p-5">
                    <form>
                        <span className="preview-title-lg overline-title">Booking Details </span>

                        <div className="row gy-4 p-3">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="fw-last-name">Booking Ref Number</label> <div className="form-control-wrap">
                                        <input type="text" data-msg="Required" className="form-control required" id="fw-last-name" name="fw-last-name"
                                            value={retrievedBooking?.BookingReferenceNumber}
                                            disabled
                                            onChange={async (e) => bookingDetailsPayload("bookingReferenceNumber", e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="fw-last-name">Status</label>
                                    <div className="form-control-wrap">
                                        <input type="text" value={retrievedBooking?.BookingStatus} data-msg="Required" className="form-control required" id="fw-last-name" name="fw-last-name" disabled />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="fw-last-name">Day on location</label>
                                    <div className="form-control-wrap">
                                        <input type="text" data-msg="Required" className="form-control required"
                                            id="fw-last-name" name="fw-last-name" disabled
                                            value={formattedDayOnLocation}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="fw-last-name">Expected handling time on location</label>
                                    <div className="form-control-wrap">
                                        <input type="text" data-msg="Required" className="form-control required" disabled
                                            value={timeOnLocation}
                                        // value={retrievedBooking?.ExpectedHandlingTimeOnLocation}
                                        />
                                    </div>
                                </div>

                                <div className="form-group" style={{ zIndex: "60" }}>
                                    <label className="form-label" htmlFor="fw-last-name">Offshore installation</label>
                                    <div className="form-control-wrap">
                                        <Select
                                            options={offshoreInstallationOptions?.map(a => ({ ...a, label: a.LocationName, value: a.LocationId }))}
                                            value={(offshoreInstallationOptions?.map(a => ({ ...a, label: a.LocationName, value: a.LocationId })).find(a => a.value === payload?.offshoreInstallation)) ||
                                                (offshoreInstallationOptions?.map(a => ({ ...a, label: a.LocationName, value: a.LocationId })).find(a => a.value === retrievedBooking?.OffshoreInstallation))}
                                            onChange={(option) => { changeOffshoreInstallation(option) }}
                                        // onChange={(option) => {  bookingDetailsPayload("offshoreInstallation", option.value) }}
                                        />
                                        <Validator
                                            data={bookingRequest.offshoreInstallation}
                                            errorMessage="Select an Offshore installation"
                                            groupName={validationGroup}
                                            dataKey={"offshoreInstallation"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="fw-last-name">Sailing</label>
                                    <div className="form-control-wrap">
                                        <div className="form-icon form-icon-right">
                                            <em className="icon ni ni-calendar-alt"
                                                onClick={handleClick}
                                            />
                                        </div>
                                        <input type="button"
                                            onClick={handleClick}
                                            value={selectedSailing || sailingName}
                                            className="form-control" />
                                    </div>
                                    <Validator
                                        data={bookingRequest.sailingId}
                                        errorMessage="Select a Sailing"
                                        groupName={validationGroup}
                                        dataKey={"sailingId"}
                                    />
                                    {buttonClicked ? displayToggle() : ""}
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="fw-last-name">Manifest Number</label>
                                    <div className="form-control-wrap">
                                        <input type="number" data-msg="Required" className="form-control required" id="fw-last-name" name="fw-last-name"
                                            onChange={(e) => bookingDetailsPayload("manifestNumber", e.target.value)}
                                            defaultValue={(payload?.manifestNumber) || (retrievedBooking?.ManifestNumber)}
                                        />
                                        <Validator
                                            data={bookingRequest.manifestNumber}
                                            errorMessage="Enter Manifest number"
                                            groupName={validationGroup}
                                            dataKey={"manifestNumber"}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="fw-last-name">Direction</label>
                                    <div className="form-control-wrap">
                                        <Select
                                            onChange={(option) => onDirectionChange(option)}
                                            options={directionOptions.map((a) => ({ ...a, label: a.label, value: a.value }))}
                                            value={{ label: bookingDirection, value: bookingDirection }}
                                        />
                                        <Validator
                                            data={bookingRequest.direction}
                                            errorMessage="Select a direction"
                                            groupName={validationGroup}
                                            dataKey={"direction"}
                                        />
                                    </div>
                                </div>
                                {(bookingDirection === "Inbound") && (
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fw-last-name">Port Of Discharge</label>
                                        <div className="form-control-wrap">
                                            <Select
                                                maxMenuHeight={160}
                                                options={portOptions?.map(a => ({ ...a, label: a.LocationName, value: a.LocationId }))}
                                                onChange={(option) => bookingDetailsPayload("portOfDischarge", option.value)}
                                                value={(locationsList?.map(a => ({ ...a, label: a.LocationName, value: a.LocationId })).find(a => a.value === payload?.portOfDischarge)) ||
                                                    (locationsList?.map(a => ({ ...a, label: a.LocationName, value: a.LocationId })).find(a => a.value === retrievedBooking?.PortOfDischarge))}
                                            />
                                            <Validator
                                                data={bookingRequest.portOfDischarge}
                                                errorMessage="Select a Port"
                                                groupName={validationGroup}
                                                dataKey={"portOfDischarge"}
                                            />
                                        </div>
                                    </div>
                                )}

                                {(bookingDirection === "Interfield") && (
                                    <div className="form-group"
                                    //  style={{ zIndex: "8" }}
                                    >
                                        <label className="form-label" htmlFor="fw-last-name">
                                            Offshore installation (Departure)
                                        </label>
                                        <div className="form-control-wrap">
                                            <Select
                                                maxMenuHeight={200}
                                                options={installationOptionList?.map((a) => ({
                                                    ...a,
                                                    label: a.LocationName,
                                                    value: a.LocationId,
                                                }))}
                                                value={installationOptionList?.map((a) => ({
                                                    ...a,
                                                    label: a.LocationName,
                                                    value: a.LocationId,
                                                })).find((a) => a.value === bookingRequest.secondOffshoreInstallation)}
                                                onChange={(option) => {
                                                    bookingDetailsPayload(
                                                        "secondOffshoreInstallation",
                                                        option.value
                                                    );
                                                }}
                                            />
                                            <Validator
                                                data={bookingRequest.secondOffshoreInstallation}
                                                errorMessage="Select an Offshore installation"
                                                groupName={validationGroup}
                                                dataKey={"offshoreInstallation"}
                                            />
                                        </div>
                                    </div>)}

                                <div className="form-group">
                                    <label className="form-label" htmlFor="fw-last-name">Operator Name</label> <div className="form-control-wrap">
                                        <Select
                                            options={operatorForSailing?.map(a => ({ ...a, label: a.PartyName, value: a.PartyId }))}
                                            onChange={(option) => { bookingDetailsPayload("PartyId", option.value) }}
                                            value={operatorForSailing?.map(a => ({ ...a, label: a.PartyName, value: a.PartyId })).find(a => a.value === payload?.PartyId) ||
                                                (operatorForSailing?.map(a => ({ ...a, label: a.PartyName, value: a.PartyId })).find(a => a.value === retrievedBooking?.PartyId))}
                                        />
                                        <Validator
                                            data={bookingRequest.partyId}
                                            errorMessage="Please select Operator"
                                            groupName={validationGroup}
                                            dataKey={"partyId"}
                                        />
                                    </div>
                                </div>

                                {(bookingDirection === "Outbound") && (
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fw-last-name">Port Of Loading</label>
                                        <div className="form-control-wrap">
                                            <Select
                                                maxMenuHeight={160}
                                                options={portOptions?.map(a => ({ ...a, label: a.LocationName, value: a.LocationId }))}
                                                onChange={(option) => bookingDetailsPayload("portOfLoading", option.value)}
                                                value={((locationsList?.map(a => ({ ...a, label: a.LocationName, value: a.LocationId })).find(a => a.value === payload?.portOfLoading))) ||
                                                    (locationsList?.map(a => ({ ...a, label: a.LocationName, value: a.LocationId })).find(a => a.value === retrievedBooking?.PortOfLoading))}
                                            />
                                            <Validator
                                                data={bookingRequest.portOfLoading}
                                                errorMessage="Select an Port Of Loading"
                                                groupName={validationGroup}
                                                dataKey={"portOfLoading"}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {operatorHasBulk ? (
                            <div>
                                <hr className="preview-hr" />
                                <span className="preview-title-lg overline-title">
                                    Bulk Cargo Details{" "}
                                </span>

                                {bulkItemsList?.map((list, index) =>
                                (
                                    <div className="row gy-4 p-3" key={index} >
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="fw-last-name">
                                                    Bulk Cargo
                                                </label>{" "}
                                                <div className="form-control-wrap">
                                                    <div className="form-control-wrap">
                                                        <Select
                                                            //productList
                                                            options={productList?.map((a) => ({
                                                                ...a,
                                                                label: a.ProductName,
                                                                value: a.ProductId,
                                                            }))}
                                                            value={productList?.map((a, index) => ({
                                                                ...a,
                                                                label: a.ProductName,
                                                                value: a.ProductId,
                                                            })).find(
                                                                (a) => a.value === list?.ProductId
                                                            )}
                                                            onChange={(item) =>
                                                                selectBulkCargo(item, index)
                                                            }
                                                            placeholder="Select product"
                                                            maxMenuHeight={160}
                                                        />
                                                        <Validator
                                                            data={list?.ProductId || list?.productId}
                                                            errorMessage="Please select bulk product"
                                                            groupName={validationGroup}
                                                            dataKey={"ProductId" || "productId"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group" >
                                                <label className="form-label" htmlFor="fw-last-name">
                                                    Quantity Unit{" "}
                                                </label>
                                                <div className="form-control-wrap">
                                                    <Select
                                                        options={measurementUnitsBulkItem?.map((a) => ({
                                                            ...a,
                                                            label: a.name,
                                                            value: a.id,
                                                        }))}
                                                        value={measurementUnitsBulkItem.map((a) => ({
                                                            ...a,
                                                            label: a.name,
                                                            value: a.id,
                                                        })).find(
                                                            (a) => a.value ===
                                                                list?.MeasurementUnitId
                                                        )}
                                                        onChange={(item) =>
                                                            quantityUnit(item, index)
                                                        }
                                                        maxMenuHeight={160}
                                                    />
                                                    <Validator
                                                        data={list?.MeasurementUnitId || list?.measurementUnitId}
                                                        errorMessage="Please select a unit"
                                                        groupName={validationGroup}
                                                        dataKey={"MeasurementUnitId" || "measurementUnitId"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="fw-last-name">
                                                    Quantity
                                                </label>{" "}
                                                <div className="form-control-wrap">
                                                    <input
                                                        defaultValue={list?.Quantity}
                                                        type="text"
                                                        data-msg="Required"
                                                        className="form-control required"
                                                        id="fw-last-name"
                                                        name="fw-last-name"
                                                        onChange={async (e) =>
                                                            bulkItemQuantity(e.target, index)}
                                                    />
                                                    <Validator
                                                        data={list?.quantity || list?.Quantity}
                                                        errorMessage="Please fill quantity"
                                                        groupName={validationGroup}
                                                        dataKey={"Quantity" || "quantity"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="fw-last-name">
                                                    Specific Gravity
                                                </label>
                                                <input
                                                    defaultValue={list?.specificGravity || list?.SpecificGravity}
                                                    type="text"
                                                    data-msg="Required"
                                                    className="form-control required"
                                                    id="fw-last-name"
                                                    name="fw-last-name"
                                                    onChange={async (e) =>
                                                        specificGravityOfItem(e.target, index)
                                                    }
                                                />
                                                <Validator
                                                    data={list?.SpecificGravity || list?.specificGravity}
                                                    errorMessage="Please fill Specific Gravity"
                                                    groupName={validationGroup}
                                                    dataKey={"SpecificGravity" || "specificGravity"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="fw-last-name">
                                                    Customs Status
                                                </label>
                                                <div className="form-control-wrap">
                                                    <div className="form-control-wrap">
                                                        <Select
                                                            options={customsStatus.map((a) => ({
                                                                ...a,
                                                                label: a.label,
                                                                value: a.value,
                                                            }))}
                                                            value={customsStatus.map((a) => ({
                                                                ...a,
                                                                label: a.label,
                                                                value: a.value,
                                                            })).find(
                                                                (a) => a.value ===
                                                                    list?.CustomStatus
                                                            )}
                                                            onChange={(item) => {
                                                                customsStatusOfItem(item, index);
                                                            }}
                                                        />
                                                        <Validator
                                                            data={list?.customStatus || list?.CustomStatus}
                                                            errorMessage="Please select custom status"
                                                            groupName={validationGroup}
                                                            dataKey={"CustomStatus" || "customStatus"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="gap" />
                                                <em
                                                    class="icon ni ni-trash-alt text-danger fs-16px pointer mr-2 p-1"
                                                    title="Discard"
                                                    onClick={() => removeBulkItem(index)}
                                                > delete entry</em>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    title="click to add bulk cargo"
                                    className="btn btn-xs text-primary m-2 p-2"
                                    onClick={() =>
                                        setBulkItemsList([...bulkItemsList, {}])
                                    }
                                >
                                    <em className="icon ni ni-plus">  </em> Add bulk cargo
                                </button>
                            </div>

                        ) : ""}
                        {/* )} */}

                        {bookingEquip && (
                            <div>
                                <hr className="preview-hr" />
                                <span className="preview-title-lg overline-title"> Booking Equipment Details </span>
                                <div className="card" id="VS1">

                                    {bookingButtonClicked ?
                                        <BookingEquipmentSheet
                                            allCargoCarryingEquipments={allCargoCarryingEquipments}
                                            bookingDataToEdit={bookingEquip}
                                            callback={(equipment) => receiveBookingEquipmentDetails(equipment)}
                                            setBookingDataFirstColumn={setUneditedBookingData}
                                            editedBookingData={editedBookingData}
                                            bookingEquipmentContent={bookingEquipmentContent}
                                            measurementUnitArray={measurementsPrime} ownersList={ownersList}
                                            cargoEquipmentForBooking={cargoEquipment}
                                        />
                                        :
                                        (
                                            <div>
                                                <button id="deckButton" type="button"
                                                    onClick={() => toggleBookingEquipmentTable()}
                                                    className="btn btn-md btn-dark btn-wide px-5 mr-3" >Click to edit Booking Equipment</button>
                                            </div>
                                        )}
                                </div>
                            </div>)}
                        <hr className="preview-hr" />
                        <span className="preview-title-lg overline-title">Deck Cargo Details </span>
                        <div className="card" id="VS1">

                            {deckButtonClicked ?

                                <DeckCargoSheet
                                    dataToEdit={updatedDeckCargo}
                                    // dataToEdit={deckCargo} 
                                    preferredEquipment={preferredCargoEquipment}
                                    deckCallBack={(list) => receiveDeckCargoDetails(list)}
                                    bookingData={uneditedBookingData} hazardClassList={hazardClassList}
                                    // editedDeckCargoData={deckCargoPayload} 
                                    editedDeckCargoData={deckCargoData}
                                    // editedDeckCargoData={updatedDeckCargo}
                                    measurementUnitArray={measurementsPrime}
                                    dgList={dgList} unCodeList={unCodeList} pgList={pgList} newNewEntry={newEntireBookingData}
                                />
                                :
                                (
                                    <div>
                                        <button id="deckButton" type="button"
                                            onClick={() => toggleDeckCargo()}
                                            className="btn btn-md btn-dark btn-wide px-5 mr-3" >Click to fill Deck Cargo</button>
                                    </div>
                                )
                            }
                            <hr className="preview-hr" id="HR1" />
                        </div>
                        {toggleCheckBox ? modalCheckBox() : !toggleCheckBox}

                        {<div className="form-group py-2 my-3">

                            <button id="toggleBtn" type="button" onClick={() => setToggleCheckBox(true)} className="btn btn-md btn-dark btn-wide px-5 mr-3" >Save Changes</button>
                            <button type="button" className="btn btn-sm btn-link m-1" onClick={() => window.history.back()}>Cancel</button>
                        </div>}

                    </form>
                    {/* <hr className="preview-hr" /> */}
                </div>
            </MainLayout>
    )
}