export default [
    {
        label: "Vessels",
        icon:"icon ni ni-downward-alt-fill",
        route: "/vessels",
        roles:["all"]
    },
    {
        label: "Locations",
        icon:"icon ni ni-map-pin-fill",
        route: "/locations",
        roles:["all"]
    },{
        label: "Parties",
        icon:"icon ni ni-briefcase",
        route: "/parties",
        roles:["all"]
    }
]