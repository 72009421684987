import React, { Component, useEffect, useState } from "react";
import ReactModal from "react-modal";
import apiCall from "../../utils/apiCall";
import Spinner from "../../utils/spinner";
import { BrowserRouter } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import CountryService from "../../services/countriesService";
import ProvinceService from "../../services/provinceService";
import PartyService from "../../services/partyService";
import payloadUpdater from "../../utils/payloadUpdater";
import { Validator, ValidateGroup } from "../../components/validator";
import toastr from "../../utils/toastr";
import { defaultModalStyle } from "../../utils/customStyles";
import Select from "react-select";
import ContactPersonService from "../../services/contactPersonService";
import { Navigate } from "react-router-dom";

export default function AddParty() {
  const [showModal, setShowModal] = useState(false);
  const [countryList, setCountry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partyTypeList, setPartyType] = useState([{}]);
  const [countryParty, setCountryParty] = useState();
  const [provinces, setProvinces] = useState({});
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [postPartyData, setPostPartyData] = useState({
    partyName: "",
    // emailAddress: "",
    // phoneNumber: "",
    addressLine1: "",
    addressLine2: "address",
    regionId: "",
    countryId: "",
  });
  const [postContactPerson, setPostContactPerson] = useState({});

  const updatePayload = new payloadUpdater({
    payload: postPartyData,
    setPayload: setPostPartyData,
  }).update;
  const validationGroup = "partyModal";
  const [contactPersonRoles, setContactPersonRoles] = useState([]);
  // console.log("postPartyData", postPartyData);
  useEffect(async () => {
    setLoading(true);
    const party_Types = await PartyService.listPartyTypes();
    setPartyType(party_Types || []);

    const _countryList = await CountryService.listCountry();
    setCountry(_countryList || []);

    const _contactPersonRoles = await ContactPersonService.listAllRoles();
    if (_contactPersonRoles)
      setContactPersonRoles(
        _contactPersonRoles.map((e) => ({
          ...e,
          id: "RoleId",
          label: e.ContactPersonRole,
          value: e.ContactPersonRoleId,
        }))
      );
    else setContactPersonRoles([]);
    setLoading(false);
  }, []);

  const savePartyForm = async () => {
    if (loading) return;
    // const validatorStatus = ValidateGroup(validationGroup);

    if (!postPartyData.partyName) {
      toastr("error", "Enter party name");
      return;
    }

    if (!postPartyData.partyTypeId) {
      toastr("error", "Select party type");
      return;
    }

    if (
      // !validatorStatus ||
      !postPartyData.countryId ||
      !postPartyData.regionId
    ) {
      toastr("error", "Select Country");
      return;
    }

    if (!postPartyData.City) {
      toastr("error", "Enter city");
      return;
    }

    if (!postContactPerson.FirstName) {
      toastr("error", "Enter contact's firstname");
      return;
    }

    if (!postContactPerson.LastName) {
      toastr("error", "Enter contact's lastname");
      return;
    }

    if (!postContactPerson.RoleId) {
      toastr("error", "Select contact's role");
      return;
    }

    if (!postContactPerson.EmailAddress) {
      toastr("error", "Enter contact's email");
      return;
    }

    if (!postContactPerson.PhoneNumber) {
      toastr("error", "Enter contact's phone number");
      return;
    }

    const selectedCountry = countryList.find(
      (a) => a.CountryId == postPartyData.countryId
    );
    const selectedState = provinces[postPartyData.countryId].find(
      (a) => a.RegionId == postPartyData.regionId
    );

    postPartyData.addressLine2 = `${selectedState?.RegionName} ${selectedCountry?.CountryName}`;

    setLoading(true);
    const response = await PartyService.postParty(postPartyData);
    if (response) {
      let _cpPayload = { ...postContactPerson, partyId: response.PartyId };
      const contactPersonSaved = await ContactPersonService.postContactPerson(
        _cpPayload
      );
      if (contactPersonSaved) {
        setPostPartyData({});
        toastr("success", "New party record added");
        setRedirectUrl("/party-list");
      }
    }
    setLoading(false);
  };

  const retrieveProvince = async (countryId) => {
    if (!countryId) return;
    if (provinces[countryId]) return; // we have fetched provinces for this country before

    const _provinces = await ProvinceService.listProvincesByCountry(countryId);
    const __provinces = { ...provinces };
    __provinces[countryId] = _provinces;

    console.log({ __provinces });
    setProvinces(__provinces);
  };

  const handleChange = (e) => {
    setPostContactPerson({
      ...postContactPerson,
      [e.id]: e.value,
    });
  };

  const effectInput = (id, value) => {
    setPostContactPerson({
      ...postContactPerson,
      [id]: value,
    });
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setPostPartyData({});
    setShowModal(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      <MainLayout
        preTitle={
          <span className="ml-2">
            <h4 className="ml-2">Add a party</h4>
          </span>
        }
        showFullLoader={loading}
      >
        <div className="card  col">
          <form className=" nk-wizard-simple is-alter p-3">
            <div className="nk-wizard-head">
              {/* There should be something here */}
            </div>
            <div className="nk-wizard-content">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="partyName">
                      Party Name
                    </label>
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        onChange={(e) =>
                          updatePayload("partyName", e.target.value)
                        }
                        className="form-control required"
                        id="partyName"
                      />
                      <Validator
                        data={postPartyData.partyName}
                        errorMessage="Enter party name"
                        groupName={validationGroup}
                        dataKey={"partyName"}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="col-sm-6">
                  <div className="form-group" style={{ zIndex: "11" }}>
                    <label className="form-label" htmlFor="partyType">
                      Party Type
                    </label>
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <Select
                        options={partyTypeList.map((e) => ({
                          ...e,
                          label: e.PartyType,
                          value: e.PartyTypeId,
                        }))}
                        value={partyTypeList
                          .map((e) => ({
                            ...e,
                            label: e.PartyType,
                            value: e.PartyTypeId,
                          }))
                          .find((a) => a.value == postPartyData.partyTypeId)}
                        onChange={(option) =>
                          updatePayload("partyTypeId", option.value)
                        }
                      />
                      {/* <Validator data={postPartyData.partyType} errorMessage="select party type" groupName={validationGroup} dataKey={"partyType"} /> */}
                    </div>
                  </div>
                </div>
                <div className="gap"></div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Address Line 1</label>
                    <div className="form-control-wrap">
                      <textarea
                        onChange={(e) =>
                          updatePayload("addressLine1", e.target.value)
                        }
                        placeholder="Fill in address"
                        className="form-control no-resize"
                        id="addressLine1"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Address Line 2</label>
                    <div className="form-control-wrap">
                      <textarea
                        onChange={(e) =>
                          updatePayload("addressLine2", e.target.value)
                        }
                        placeholder="Fill in address"
                        className="form-control no-resize"
                        id="addressLine2"
                      />
                    </div>
                  </div>
                </div>
                <div className="gap" />

                <div className="col-sm-6">
                  <div className="form-group" style={{ zIndex: "10" }}>
                    <label className="form-label" htmlFor="country">
                      Country
                    </label>
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <Select
                        options={countryList.map((e) => ({
                          ...e,
                          label: e.CountryName,
                          value: e.CountryId,
                        }))}
                        value={countryList
                          .map((e) => ({
                            ...e,
                            label: e.CountryName,
                            value: e.CountryId,
                          }))
                          .find((a) => a.value == postPartyData.countryId)}
                        onChange={(option) => {
                          updatePayload("countryId", option.value);
                          retrieveProvince(option.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group" style={{ zIndex: "9" }}>
                    <label className="form-label">State/Province</label>
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <Select
                        isDisabled={!postPartyData.countryId}
                        options={
                          postPartyData.countryId &&
                          provinces[postPartyData.countryId] &&
                          provinces[postPartyData.countryId].map((e) => ({
                            ...e,
                            label: e.RegionName,
                            value: e.RegionId,
                          }))
                        }
                        value={(
                          (postPartyData.countryId &&
                            provinces[postPartyData.countryId] &&
                            provinces[postPartyData.countryId].map((e) => ({
                              ...e,
                              label: e.RegionName,
                              value: e.RegionId,
                            }))) ||
                          []
                        ).find((a) => a.value == postPartyData.regionId)}
                        onChange={(option) => {
                          updatePayload("regionId", option.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="gap"></div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="phoneNumber">
                      City
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        onChange={(e) => updatePayload("City", e.target.value)}
                        className="form-control"
                        id="City"
                        name="City"
                      />
                      <Validator
                        data={postPartyData.City}
                        dataType="text"
                        errorMessage="Fill in City"
                        groupName={validationGroup}
                        dataKey={"City"}
                      />
                    </div>
                  </div>
                </div>

                <div className="gap gap-20px" />
                <div className="gap gap-20px" />
                <span className="preview-title-lg overline-title">
                  Contact Person Information{" "}
                </span>
                <div className="gap gap-20px" />
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      First Name{" "}
                    </label>
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <input
                        onChange={(e) => {
                          effectInput("FirstName", e.target.value);
                        }}
                        type="text"
                        data-msg="Required"
                        className="form-control required"
                        id="CFirstName"
                        name="CFirstName"
                        required
                      />
                      <Validator
                        data={postContactPerson.FirstName}
                        errorMessage="Please enter a valid name"
                        groupName={validationGroup}
                        dataKey={"CFirstName"}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="CLastName">
                      Last Name{" "}
                    </label>
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <input
                        onChange={(e) => {
                          effectInput("LastName", e.target.value);
                        }}
                        type="text"
                        data-msg="Required"
                        className="form-control required"
                        id="CLastName"
                        name="CLastName"
                        required
                      />
                      <Validator
                        data={postContactPerson.LastName}
                        errorMessage="Please enter a valid name"
                        groupName={validationGroup}
                        dataKey={"CLastName"}
                      />
                    </div>
                  </div>
                </div>
                <div className="gap" />
                <div className="col-sm-6">
                  <div className="form-group" style={{ zIndex: "8" }}>
                    <label className="form-label">Contact Person Role</label>
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <Select
                        options={contactPersonRoles}
                        value={contactPersonRoles.find(
                          (a) =>
                            a.value == postContactPerson.ContactPersonRoleId
                        )}
                        onChange={(item) => {
                          handleChange(item);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      Email Address
                    </label>
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <input
                        onChange={(e) => {
                          effectInput("EmailAddress", e.target.value);
                        }}
                        type="email"
                        data-msg="Required"
                        className="form-control required"
                        id="CEmailAddress"
                        name="CEmailAddress"
                        required
                      />
                      <Validator
                        data={postContactPerson.EmailAddress}
                        dataType="email"
                        errorMessage="Please enter a valid email"
                        groupName={validationGroup}
                        dataKey={"CEmailAddress"}
                      />
                    </div>
                  </div>
                </div>
                <div className="gap" />
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      Phone number
                    </label>
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <input
                        onChange={(e) => {
                          effectInput("PhoneNumber", e.target.value);
                        }}
                        type="number"
                        data-msg="Required"
                        className="form-control required"
                        id="CPhoneNumber"
                        name="CPhoneNumber"
                        required
                      />
                      <Validator
                        data={postContactPerson.PhoneNumber}
                        dataType="number"
                        errorMessage="Please fill phone number"
                        groupName={validationGroup}
                        dataKey={"CPhoneNumber"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gap"></div>
            <div className="mt-5">
              <button
                className="btn btn-md btn-light btn-dim"
                onClick={() => handleCloseModal()}
              >
                {" "}
                <em className="icon ni ni-arrow-left pointer mr-2" /> Go back
              </button>
              {loading ? (
                <span className="float-right">
                  <Spinner size="2" />
                </span>
              ) : (
                <button
                  type="button"
                  onClick={() => savePartyForm()}
                  className="btn btn-md btn-dark float-right"
                >
                  Add new party
                </button>
              )}
            </div>
          </form>
        </div>
      </MainLayout>
    </>
  );
}
