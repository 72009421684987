import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import MyTable from "../../components/dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import PartyService from "../../services/partyService";
import Spinner from "../../utils/spinner";
import { Link } from "react-router-dom";
import Select from "react-select";
import { checkUserPermission } from "../../utils/utilityFunctions";
import PersonWithInitial from "../../components/personWithInitial";
import BookingTable from "../../components/bookingTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";

export default function PartList() {
  const [tableData, setTableData] = useState(null);
  const [parties, setParties] = useState([]);
  const [partyTypeList, setPartyType] = useState([{}]);
  const [selectedPartyType, setSelectedPartyType] = useState([{}]);
  const [partiesCount, setPartiesCount] = useState(0);
  const [initializing, setInit] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contactSearchParam, setContactSearchParam] = useState("");
  const [contactRole, setContactRole] = useState("");
  const [country, setCountry] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState([10, 20, 50, 100, 150]);
  const [sortingOrder, setSortingOrder] = useState(true);
  const [totalRecords, setTotalRecords] = useState(10);
  const [partiesForExport, setPartiesForExport] = useState([]);

  const orderList = [
    { value: true, label: "A-Z" },
    { value: false, label: "Z-A" },
  ];
  const exportPage = () => {
    if (!parties.length) return;
    const exportData = parties.map((party) => {
      const exportObject = {
        "Party Name ": party.Name,
        "Party Type ": party.Type,
        "Address Line 1": party.AddressLine1,
        "Address Line 2": party.AddressLine2,
        "City": party.City,
        "Region": party.Region,
        "Country": party.Country,
        "Contact Name": party.CName,
        "Contact Role": party.CRole,
        "Phone No.": party.Phone,
        "Email ": party.Email,
      };
      return exportObject;
    });
    exportToCSV(exportData, "list-of-parties");
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {checkUserPermission("parties_view_party_details") &&
            checkUserPermission("parties_edit_party") && (
              <li>
                <a
                  href={
                    initializing
                      ? "javascript:void(0)"
                      : `${process.env.REACT_APP_API}/api/Parties/DownloadPartiesCSV?authorization=${localStorage.token}`
                  }
                  target={initializing ? "" : "_blank"}
                  className={`btn btn-dim btn-secondary ${initializing ? "disabled" : ""
                    }`}
                >
                  <em className="icon ni ni-download" />
                  <span>Download Parties</span>
                </a>
              </li>
            )}
          {/* {checkUserPermission("parties_view_party_details") &&
            checkUserPermission("parties_edit_party") &&
            parties && (
              <li>
                <button
                  type="button"
                  onClick={exportPage}
                  className="btn btn-white btn-outline-light"
                >
                  <em className="icon ni ni-download-cloud" />
                  <span>Export page</span>
                </button>
              </li>
            )} */}
          <li className="nk-block-tools-opt">
            <div className="drodown">
              <a
                href="#"
                className="dropdown-toggle btn btn-icon btn-primary"
                data-toggle="dropdown"
              >
                <em className="icon ni ni-plus" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <ul className="link-list-opt no-bdr">
                  {checkUserPermission("parties_add_party") && (
                    <li>
                      <Link to="/add-party">
                        <span>Add Party</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );

  const overflowMenu = (party) => {
    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                {checkUserPermission("parties_view_party_details") && (
                  <li>
                    <Link to={`/view-party/${party.Party.PartyId}`}>
                      <em className="icon ni ni-eye" />
                      <span>View Details</span>
                    </Link>
                  </li>
                )}
                {checkUserPermission("parties_edit_party") && (
                  <li>
                    <Link to={`/edit-party/${party.Party.PartyId}`}>
                      <em className="icon ni ni-file-docs" />
                      <span>Edit Party Details</span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  // const rowActions = [
  //   {
  //     action: "View Details",
  //     link: "/view-party/",
  //     property1: "Id",
  //     property2: null,
  //     icon: "icon ni ni-eye",
  //     show: checkUserPermission("parties_view_party_details"),
  //   },
  //   {
  //     action: "Edit Party Details",
  //     link: "/edit-party/",
  //     property1: "Id",
  //     property2: null,
  //     icon: "icon ni ni-file-docs",
  //     show: checkUserPermission("parties_edit_party"),
  //   },
  // ];

  const headerList = [
    {
      Header: "Name",
      accessor: "PartyComponent",
      link: "/view-party/",
      linkId: "Id",
    },
    // {
    //   Header: "Type",
    //   accessor: "Type",
    // },
    {
      Header: "Address",
      accessor: "Address",
    },
    {
      Header: "Country",
      accessor: "Country",
    },
    // {
    //   Header: "Phone",
    //   accessor: "Phone",
    // },
    // {
    //   Header: "Email",
    //   accessor: "Email",
    // },
    {
      Header: "C.Name	",
      accessor: "ContactNameComponent",
    },
    {
      Header: "C.Role",
      accessor: "CRole",
    },
    {
      Header: "C.Phone",
      accessor: "ContactPhone",
    },
    {
      Header: "C.Email",
      accessor: "ContactEmail",
    },
    {
      Header: "",
      accessor: "ActionsComponent",
    },
  ];

  useEffect(async () => {
    const party_Types = await PartyService.listPartyTypes();
    if (party_Types) {
      setPartyType(party_Types);
    }
    await retrieveParties();
    await getTotalPartyCount();
  }, []);

  const retrieveParties = async (
    _currentPage,
    _pageSize,
    _query = "",
    _contactQuery = "",
    _contactRole = "",
    _country = "",
    _orderByAsc = true
  ) => {
    setInit(true);
    _currentPage = _currentPage || currentPage;
    _pageSize = _pageSize || pageSize;
    _orderByAsc = _orderByAsc;
    setSearchParam(_query);
    setContactSearchParam(_contactRole);
    setContactRole(_contactQuery);
    setCountry(_country);
    const partyList = await PartyService.listPartyPaginated(
      _currentPage,
      _pageSize,
      _query,
      _contactQuery,
      _country,
      _contactRole,
      _orderByAsc
    );
    setTotalRecords(partyList?.TotalCount || 0);
    let v = partyList.response
      ? partyList.response?.map((e) => ({
        Name: e?.Party.PartyName,
        Type: e?.PartyType,
        Id: e?.Party.PartyId,
        Email: e?.Party.EmailAddress,
        Phone: e?.Party.PhoneNumber,
        Country: e?.Country.CountryName,
        Address: e?.Party.AddressLine1,
        AddressLine1: e?.Party.AddressLine1,
        AddressLine2: e?.Party.AddressLine2,
        City: e?.Party.City,
        Region: e?.Region,
        CName: e.ContactPersons != null ? e.ContactPersons[0]?.FirstName : "",
        ContactPhone:
          e.ContactPersons != null ? e.ContactPersons[0]?.PhoneNumber : "",
        ContactEmail:
          e.ContactPersons != null ? e.ContactPersons[0]?.EmailAddress : "",
        CRole:
          e.ContactPersons != null
            ? e.ContactPersonRole?.ContactPersonRole
            : "",
        ActionsComponent: overflowMenu(e),
        PartyComponent: <PersonWithInitial name={e.Party.PartyName} />,
        ContactNameComponent:
          e.ContactPersons != null ? (
            <PersonWithInitial
              name={
                e.ContactPersons[0] != null
                  ? e.ContactPersons[0]?.FirstName +
                  " " +
                  e.ContactPersons[0]?.LastName
                  : null
              }
            />
          ) : (
            ""
          ),
      }))
      : [];

    currentPage != _currentPage && setCurrentPage(_currentPage);
    pageSize != _pageSize && setPageSize(_pageSize);

    setParties(v);
    setTableData(v);
    setInit(false);
  };

  const getTotalPartyCount = async () => {
    const count = await PartyService.CountParties();
    if (count != null) {
      setPartiesCount(count);
    }
  };

  const deleteAction = () => {
    console.log("click worked");
  };

  const filterByPartyProperty = async () => {
    await retrieveParties(
      1,
      null,
      searchParam,
      contactSearchParam,
      contactRole,
      country,
      sortingOrder
    );
  };

  const clearFilter = async () => {
    setSearchParam("");
    setContactSearchParam("");
    setContactRole("");
    setCountry("");
    setSortingOrder(true);

    await retrieveParties(1, null);
  };


  return (
    <MainLayout
      title="Parties"
      loading={initializing}
      showFullLoader={initializing}
      pageActions={pageActions}
    >
      <div className="table-component">
        <div className="card-inner-group">
          {
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="dropdown">
                    <a
                      href="#"
                      className="btn btn-trigger btn-icon dropdown-toggle "
                      data-toggle="dropdown"
                    >
                      <em className="icon ni ni-search" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
                      <form>
                        <div className="dropdown-head">
                          <span className="sub-title dropdown-title">
                            Filter Parties
                          </span>
                          <div className="dropdown">
                            <a
                              className="btn btn-sm btn-icon"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-cross" title="close" />
                            </a>
                          </div>
                        </div>
                        <div className="dropdown-body dropdown-body-rg">
                          <div className="row gx-6 gy-3">
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="from-date-filter"
                                >
                                  Party
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  placeholder="Enter name or address."
                                  value={searchParam}
                                  onChange={(e) =>
                                    setSearchParam(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="from-date-filter"
                                >
                                  Country
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  placeholder="Enter party's country"
                                  value={country}
                                  onChange={(e) => setCountry(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="from-date-filter"
                                >
                                  Contact Person
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  placeholder="Enter name or phone or email."
                                  value={contactSearchParam}
                                  onChange={(e) =>
                                    setContactSearchParam(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="from-date-filter"
                                >
                                  Contact Person Role
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  placeholder="Enter contact role"
                                  value={contactRole}
                                  onChange={(e) =>
                                    setContactRole(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            {/* <div className="col-12">
                              <div
                                className="form-group"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <label className="overline-title overline-title-alt">
                                  Sort Alphabetically
                                </label>
                                <Select
                                  className="select-dropdown-sm"
                                  placeholder=""
                                  value={orderList.find(
                                    (order) => order.value === sortingOrder
                                  )}
                                  options={orderList}
                                  onChange={(e) => setSortingOrder(e.value)}
                                />
                              </div>
                            </div> */}

                            <div className="gap gap-10px" />

                            <div className="col-12">
                              <div className="form-group">
                                <button
                                  type="button"
                                  onClick={filterByPartyProperty}
                                  className="btn btn-secondary"
                                >
                                  <span>Filter</span>
                                </button>
                                <a
                                  className="clickable ml-2"
                                  onClick={() => clearFilter()}
                                >
                                  Reset Filter
                                </a>
                              </div>
                            </div>
                            <div className="gap gap-50px" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* .form-inline */}
                </div>

                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li className="btn-toolbar-sep" />
                    <li>
                      <div className="toggle-wrap">
                        <a
                          href="#"
                          className="btn btn-icon btn-trigger toggle"
                          data-target="cardTools"
                        >
                          <em className="icon ni ni-menu-right" />
                        </a>
                        <div
                          className="toggle-content"
                          data-content="cardTools"
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <a
                                href="#"
                                className="btn btn-icon btn-trigger toggle"
                                data-target="cardTools"
                              >
                                <em className="icon ni ni-arrow-left" />
                              </a>
                            </li>

                            <li>
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  <em className="icon ni ni-setting" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    {pages.map((item, index) => (
                                      <li
                                        onClick={() =>
                                          retrieveParties(
                                            null,
                                            item,
                                            searchParam,
                                            contactSearchParam,
                                            contactRole,
                                            country,
                                            sortingOrder
                                          )
                                        }
                                        key={index}
                                        className={`${currentPage == item && "active"
                                          }`}
                                      >
                                        <Link to={"#"}>{item}</Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      <div>
        <>
          <BookingTable
            columns={headerList}
            cssArray={["font-weight-500", "", "", "text-left", "", ""]}
            data={tableData}
            enableSearch={false}
            emptyMessage="Party List is Empty"
            disablePageSize={true}
          />
        </>
        <br />
        <div className="">
          <Paginate
            onChange={(p) =>
              retrieveParties(
                p,
                null,
                searchParam,
                contactSearchParam,
                contactRole,
                country,
                sortingOrder
              )
            }
            currentPage={currentPage}
            pageSize={pageSize}
            totalRecordsCount={totalRecords}
          />
        </div>
      </div>
    </MainLayout>
  );
}
