import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import EquipmentService from "../../services/equipmentService";
import Spinner from "../../utils/spinner";

export default function VesselEquipmentListComponent({
  dataList,
  reload,
  deleteAction,
  viewAction,
  vesselSpecId = "",
}) {
  const [tableData, setTableData] = useState(dataList || []);
  const [loading, setLoading] = useState(true);

  const headerList = [
    {
      Header: "Equipment Name",
      accessor: "EquipmentName",
    },
    {
      Header: "Product",
      accessor: "Product",
    },
    {
      Header: "Capacity Measure",
      accessor: "CapacityMeasure",
    },
    {
      Header: "Measurement Unit",
      accessor: "MeasurementUnit",
    },
    {
      Header: " ",
      accessor: "action",
    },
  ];

  useEffect(async () => {
    setLoading(true);
    let data = vesselSpecId ? await retrieveEquipmentList() : dataList || [];
    if (viewAction) {
      data = data.map((item) => {
        item.action = (
          <em
            class="icon ni ni-edit text-primary fs-20px pointer mx-2"
            title="Edit"
            onClick={() => {
              viewAction(item);
            }}
          ></em>
        );
        return item;
      });
    }

    setTableData(data || []);

    setLoading(false);
  }, [dataList]);

  const retrieveEquipmentList = async () => {
    setLoading(true);
    const types = await EquipmentService.listVesselEquipmentV2(vesselSpecId);
    return types || [];
    // setTableData(types || []);
  };
  return (
    <div style={{ zoom: "0.85" }}>
      {loading ? (
        <Spinner />
      ) : (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", "", "", ""]}
          data={tableData}
          reloadData={(page, pageSize, query) =>
            reload && reload(page, pageSize, query)
          }
          enableSearch={false}
          deleteAction={deleteAction}
          emptyMessage="You have not added any equipment"
        />
      )}
    </div>
  );
}
