import React, { useEffect, useState, ReactDOM, useRef } from "react";
import Select from "react-select";
import toastr from "../../utils/toastr";
import MainLayout from "../../layouts/mainLayout";
import EquipmentService from "../../services/equipmentService";
import PartyService from "../../services/partyService";
import CertificateService from "../../services/certificateService";
import { Navigate, useParams } from "react-router-dom";
import PartyModal from "../Modals/partyModal";

export default function EditEquipment() {
  const params = useParams();
  const [equipmentId, setEquipmentId] = useState(params.equipmentId);
  const [equipmentTypes, setEquipmentTypes] = useState(null);
  const [partyList, setParty] = useState([{}]);
  const [certificates, setCertificates] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState(null);
  const [categories, setCategories] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [newEquipmentData, setNewEquipmentData] = useState({
    CargoCarryingEquipmentTypeId: "",
    PartyId: "",
    EquipmentName: "",
    EquipmentDescription: "",
    ContainerSizeId: "",
    TubularCategoryId: null,
    Ccunumber: null,
    EquipmentName: "",
  });
  const [equipmentMeasurements, setEquipmentMeasurements] = useState(null);
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [finalSaveloading, setFinalSaveLoading] = useState(false);
  const [initializing, setInit] = useState(true);
  const [volumeMeasurementUnits, setVolumeMeasurementUnits] = useState([]);
  const [lengthMeasurementUnits, setLengthMeasurementUnits] = useState([]);
  const [massMeasurementUnits, setMassMeasurementUnits] = useState([]);
  const [areaMeasurementUnits, setAreaMeasurementUnits] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [measurementUnitTypes, setMeasurementUnitTypes] = useState([]);
  const [equipmentSizes, setEquipmentSizes] = useState([]);
  const diameterRef = useRef(null);
  const lengthRef = useRef(null);
  const widthRef = useRef(null);
  const heightRef = useRef(null);
  const payloadRef = useRef(null);
  const tareMassRef = useRef(null);
  const maxGrossMaxRef = useRef(null);
  const volumeCapacityRef = useRef(null);

  useEffect(async () => {
    setInit(true);
    await retrieveEquipmentTypes();
    await retrieveParty();
    await retrieveCertificates();
    await retrieveMeasurementUnits();
    await retrieveMeasurementTypes();
    await retrieveCategories();
    await retrieveEquipment();
    await retrieveEquipmentSizes();
    setInit(false);
    return () => {};
  }, []);

  const retrieveEquipment = async () => {
    const EquipmentTypes = await EquipmentService.listCargoEquipmentTypes();
    if (EquipmentTypes == null) setEquipmentTypes([]);
    else
      setEquipmentTypes(
        EquipmentTypes.map((e) => ({
          ...e,
          label: e.EquipmentType,
          value: e.EquipmentTypeId,
          id: "CargoCarryingEquipmentTypeId",
        }))
      );

    const equipmentDetails =
      await EquipmentService.getCargoCarryingEquipmentById(equipmentId);
    if (equipmentDetails) {
      setNewEquipmentData(equipmentDetails.CargoCarryingEquipment);
      setEquipmentMeasurements(equipmentDetails.Measurements);
    }
  };

  const retrieveCategories = async () => {
    const categories = await EquipmentService.getEquipmentCategories();
    if (categories == null) setCategories({});
    else
      setCategories(
        categories.map((e) => ({
          ...e,
          label: e.TubularCategory,
          value: e.TubularCategoryId,
          id: "TubularCategoryId",
        }))
      );

    let _parentCategories = categories
      ?.map((e) => ({
        ...e,
        label: e.TubularCategory,
        value: e.TubularCategoryId,
        id: "TubularCategoryId",
      }))
      .filter(
        (category) =>
          category.ParentTubularCategoryId ==
          "00000000-0000-0000-0000-000000000000"
      );

    let _filteredChildrenCategories = categories?.filter(
      (category) =>
        category.ParentTubularCategoryId !==
        "00000000-0000-0000-0000-000000000000"
    );

    let _parentTubularForChildCategories = _filteredChildrenCategories?.map(
      (e) => ({
        ...e,
        parentTubularCategory: _parentCategories
          .map((p) => ({
            TubularCategory: p.TubularCategory,
            TubularCategoryId: p.TubularCategoryId,
          }))
          .find((x) => x.TubularCategoryId == e.ParentTubularCategoryId),
      })
    );

    let _childCategories = _parentTubularForChildCategories?.map((e) => ({
      ...e,
      label:
        e.parentTubularCategory.TubularCategory + " - " + e.TubularCategory,
      value: e.TubularCategoryId,
      id: "TubularSubCategoryId",
    }));
    setParentCategories(_parentCategories);
    setSubCategories(_childCategories);
  };
  //The next few lines extracted the selected tubular category to filter results for sub-category
  let subCat = subCategories.filter(
    (list) =>
      list?.ParentTubularCategoryId === newEquipmentData?.TubularCategoryId
  );
  let subCategoryOptions = [];
  if (subCat.length === 0) {
    subCategoryOptions.push(subCategories);
    subCategoryOptions.flat();
  } else if (subCat.length > 0) {
    subCategoryOptions.push(subCat);
    subCategoryOptions.flat();
  }
  subCategoryOptions = subCategoryOptions.flat();

  const retrieveEquipmentTypes = async () => {
    const EquipmentTypes = await EquipmentService.listCargoEquipmentTypes();
    if (EquipmentTypes == null) setEquipmentTypes([]);
    else
      setEquipmentTypes(
        EquipmentTypes.map((e) => ({
          ...e,
          label: e.EquipmentType,
          value: e.EquipmentTypeId,
          id: "CargoCarryingEquipmentTypeId",
        }))
      );
  };
  const retrieveMeasurementUnits = async () => {
    const _measurementUnits = await EquipmentService.listMeasurementUnits();
    const _measurementUnitTypes =
      await EquipmentService.listMeasurementUnitTypes();

    if (_measurementUnitTypes == null) setMeasurementUnitTypes({});
    else setMeasurementUnitTypes(_measurementUnitTypes);

    if (_measurementUnits == null) setMeasurementUnits({});
    else
      setMeasurementUnits(
        _measurementUnits.map((e) => ({
          ...e,
          label: e.MeasurementUnitSymbol,
          value: e.MeasurementUnitId,
          id: "MeasurementUnitId",
        }))
      );

    let massUnitType = _measurementUnitTypes?.find(
      (unitType) => unitType.MeasurementUnitType == "Mass/Weight"
    );

    let lengthUnitType = _measurementUnitTypes?.find(
      (unitType) => unitType.MeasurementUnitType == "Length/Diameter"
    );

    let volumeUnitType = _measurementUnitTypes?.find(
      (unitType) => unitType.MeasurementUnitType == "Volume"
    );

    let areaUnitType = _measurementUnitTypes?.find(
      (unitType) => unitType.MeasurementUnitType == "Area"
    );

    let massUnits = _measurementUnits
      ?.map((e) => ({
        ...e,
        label: e.MeasurementUnitSymbol,
        value: e.MeasurementUnitId,
        id: "MeasurementUnitId",
      }))
      .filter(
        (unit) =>
          unit.MeasurementUnitTypeId == massUnitType.MeasurementUnitTypeId
      );

    let lengthUnits = _measurementUnits
      ?.map((e) => ({
        ...e,
        label: e.MeasurementUnitSymbol,
        value: e.MeasurementUnitId,
        id: "MeasurementUnitId",
      }))
      .filter(
        (unit) =>
          unit.MeasurementUnitTypeId == lengthUnitType.MeasurementUnitTypeId
      );

    let volumeUnits = _measurementUnits
      ?.map((e) => ({
        ...e,
        label: e.MeasurementUnitSymbol,
        value: e.MeasurementUnitId,
        id: "MeasurementUnitId",
      }))
      .filter(
        (unit) =>
          unit.MeasurementUnitTypeId == volumeUnitType.MeasurementUnitTypeId
      );

    let areaUnits = _measurementUnits
      ?.map((e) => ({
        ...e,
        label: e.MeasurementUnitSymbol,
        value: e.MeasurementUnitId,
        id: "MeasurementUnitId",
      }))
      .filter(
        (unit) =>
          unit.MeasurementUnitTypeId == areaUnitType.MeasurementUnitTypeId
      );

    setMassMeasurementUnits(massUnits || []);
    setLengthMeasurementUnits(lengthUnits || []);
    setVolumeMeasurementUnits(volumeUnits || []);
    setAreaMeasurementUnits(areaUnits || []);
  };

  const retrieveMeasurementTypes = async () => {
    const types = await EquipmentService.listMeasurementTypes();
    if (types == null) setEquipmentTypes({});
    else setMeasurementTypes(types);
  };

  const retrieveEquipmentSizes = async () => {
    const _equipmentSizes = await EquipmentService.getEquipmentSizes();
    if (_equipmentSizes == null) setEquipmentSizes({});
    else
      setEquipmentSizes(
        _equipmentSizes.map((e) => ({
          ...e,
          label: e.Size,
          value: e.ContainerSizeId,
          id: "ContainerSizeId",
        }))
      );
  };

  const retrieveParty = async () => {
    const party_List = await PartyService.listParty();
    if (party_List == null) setParty({});
    else
      setParty(
        party_List
          .map((e) => ({
            ...e,
            label: e.PartyName,
            value: e.PartyId,
            id: "PartyId",
          }))
          .filter((party) => party.PartyType == "CCU Owner")
      );
  };

  const addPartyModalCallback = async (partyAndContactPerson) => {
    if (partyAndContactPerson?.party) {
      const _partyWithContactList =
        await PartyService.getPartyWithContactPerson(
          partyAndContactPerson.party.partyId ||
            partyAndContactPerson.party.PartyId
        );
      const _selectedContactPerson = _partyWithContactList &&
        _partyWithContactList.ContactPersons && {
          ..._partyWithContactList.ContactPersons[0],
        };
    }
    await retrieveParty();
  };

  const retrieveCertificates = async () => {
    const _certificates = await CertificateService.listAll();
    if (_certificates == null) setCertificates({});
    else
      setCertificates(
        _certificates.map((e) => ({
          ...e,
          label: e.CertificateName,
          value: e.CertificateNameId,
          id: "CertificateId",
        }))
      );
  };

  const describeEquipment = (id) => {
    if (id == "") return;
    const ChosenEquip = equipmentTypes.find(
      (a) => a.EquipmentTypeId == id
    ).EquipmentType;

    if (ChosenEquip == "Offshore Container") {
      return `${
        equipmentSizes?.find(
          (size) => size.ContainerSizeId == newEquipmentData.ContainerSizeId
        )?.label
      } - (${(
        equipmentSizes?.find(
          (size) => size.ContainerSizeId == newEquipmentData.ContainerSizeId
        )?.LengthInmm / 1000
      ).toFixed(2)}m by ${(
        equipmentSizes?.find(
          (size) => size.ContainerSizeId == newEquipmentData.ContainerSizeId
        )?.WidthInmm / 1000
      ).toFixed(2)}m)`;
    } else if (ChosenEquip == "Tubular") {
      return `${
        categories?.find(
          (category) =>
            category.TubularCategoryId == newEquipmentData.TubularCategoryId
        )?.label
      } - ${
        subCategories?.find(
          (subCategory) =>
            subCategory.TubularCategoryId ==
            newEquipmentData.TubularSubCategoryId
        )?.TubularCategory
      } - ${getMeasurementValue("Diameter")}${
        diameterRef.current?.state?.value?.label || " "
      } - ${getMeasurementValue("Length")}${
        lengthRef.current?.state?.value?.label || " "
      }`;
    } else if (ChosenEquip == "BigBag") {
      return `BigBag - ${getMeasurementValue("VolumeCapacity")}${
        volumeCapacityRef.current?.state?.value[0]?.label || " "
      }`;
    }
  };

  const updateEquipmentData = async () => {
    newEquipmentData.EquipmentDescription = describeEquipment(
      newEquipmentData.CargoCarryingEquipmentTypeId
    );
    // console.log("EquipmentDescription", newEquipmentData.EquipmentDescription);
    // console.log("newEquipmentData", newEquipmentData);
    // console.log("volumeCapacityRef", volumeCapacityRef);
    // return;
    var hasFieldErrs = false;

    if (hasFieldErrs) return;
    setFinalSaveLoading(true);
    const vesselAdded = await EquipmentService.updateCargoEquipment(
      newEquipmentData
    );
    if (!vesselAdded) {
      toastr("error", "Equipment details could not be updated");
      setFinalSaveLoading(false);
      return;
    } else {
      toastr("success", "Equipment details successfully updated");
      setFinalSaveLoading(false);
      var id = vesselAdded.CargoCarryingEquipmentId;
      await postEquipmentMeasurements(id);
    }
  };
  const postEquipmentMeasurements = async (id) => {
    var hasFieldErrs = false;

    if (hasFieldErrs) return;
    setFinalSaveLoading(true);
    const measurementsAdded = await EquipmentService.addEquipmentMeasurements(
      equipmentMeasurements
    );
    if (!measurementsAdded) {
      toastr("error", "Equipment measurement details could not be updated");
      setFinalSaveLoading(false);
      return;
    } else {
      setRedirectUrl("/equipment-list");
    }
  };

  const getMeasurementValue = (type, toReturn = "value") => {
    const measurementType = measurementTypes.find(
      (a) => a.MeasurementType == type
    );
    if (!measurementType) return "";
    if (equipmentMeasurements != null)
      HideThreeDivs(newEquipmentData.CargoCarryingEquipmentTypeId);

    const measurement = equipmentMeasurements.find(
      (m) => m.MeasurementTypeId == measurementType.MeasurementTypeId
    );
    if (measurement) {
      if (toReturn == "unitId") return measurement.MeasurementUnitId;
      return measurement.MeasurementValue;
    }
  };

  const handleChange = (e, type) => {
    if (e.id == "MeasurementUnitId") {
      let measurements = equipmentMeasurements;
      var typeId = null;
      var currentMeasurement = null;
      var value = 0;
      const measurementType = measurementTypes.find(
        (a) => a.MeasurementType == type
      );
      if (measurementType) {
        typeId = measurementType.MeasurementTypeId;
        value = newEquipmentData[type];
        measurements = measurements.filter(
          (m) => m.MeasurementTypeId != typeId
        );
        currentMeasurement = equipmentMeasurements.find(
          (m) => m.MeasurementTypeId == typeId
        );
      } else return;

      if (currentMeasurement) {
        currentMeasurement.MeasurementUnitId = e.value;
        measurements.push(currentMeasurement);
      } else {
        measurements.push({
          [e.id]: e.value,
          CargoCarryingEquipmentId:
            newEquipmentData.CargoCarryingEquipmentId || null,
          MeasurementTypeId: typeId,
          MeasurementValue: value || null,
        });
      }
      setEquipmentMeasurements(measurements);
      // console.log(equipmentMeasurements);
      return;
    }
    setNewEquipmentData({
      ...newEquipmentData,
      [e.id]: e.value,
    });
    if (e.id == "CargoCarryingEquipmentTypeId") HideThreeDivs(e.value);
  };

  const effectInput = (id, value, type = null) => {
    if (type != null) {
      let measurements = equipmentMeasurements;
      var typeId = null;
      var currentMeasurement = null;
      const measurementType = measurementTypes.find(
        (a) => a.MeasurementType == type
      );
      if (measurementType) {
        typeId = measurementType.MeasurementTypeId;
        measurements = measurements.filter(
          (m) => m.MeasurementTypeId != typeId
        );
        currentMeasurement = equipmentMeasurements.find(
          (m) => m.MeasurementTypeId == typeId
        );
      } else return;
      if (currentMeasurement) {
        currentMeasurement.MeasurementValue = value;
        measurements.push(currentMeasurement);
      } else {
        measurements.push({
          EquipmentMeasurementId: "00000000-0000-0000-0000-000000000000",
          MeasurementUnitId: currentMeasurement?.MeasurementUnitId || null,
          CargoCarryingEquipmentId: newEquipmentData.CargoCarryingEquipmentId,
          MeasurementTypeId: typeId,
          MeasurementValue: value,
        });
      }
      setEquipmentMeasurements(measurements);
      // console.log(equipmentMeasurements);
    }
    setNewEquipmentData({
      ...newEquipmentData,
      [id]: value,
    });
  };

  const HideThreeDivs = (id, equipmentTypes2 = null) => {
    if (document.getElementById("Tog1") == null) {
      setTimeout(() => {
        HideThreeDivs(id);
      }, 1000);
      return;
    }
    let ChosenEquip = "";
    if (id == "" || (equipmentTypes == null && equipmentTypes2 == null))
      return null;
    if (equipmentTypes2 != null)
      ChosenEquip = equipmentTypes2.find(
        (a) => a.EquipmentTypeId == id
      ).EquipmentType;
    else
      ChosenEquip = equipmentTypes.find(
        (a) => a.EquipmentTypeId == id
      ).EquipmentType;

    if (ChosenEquip == "Offshore Container") {
      document.getElementById("Tog1").style.display = "block";
      document.getElementById("Tog2").style.display = "block";
      document.getElementById("Tog3").style.display = "block";
      document.getElementById("Tog5").style.display = "block";
      document.getElementById("Tog5a").style.display = "block";
      document.getElementById("Tog6").style.display = "block";
      document.getElementById("Tog6a").style.display = "block";
      document.getElementById("Tog7").style.display = "block";
      document.getElementById("Tog7a").style.display = "block";
      document.getElementById("Tog8").style.display = "block";
      document.getElementById("Tog8a").style.display = "block";
      document.getElementById("Tog9").style.display = "none";
      document.getElementById("Tog9a").style.display = "none";
      document.getElementById("Tog11").style.display = "none";
      document.getElementById("Tog11a").style.display = "none";
      document.getElementById("Tog12").style.display = "none";
      document.getElementById("Tog12a").style.display = "none";
      document.getElementById("Tog13").style.display = "none";
      document.getElementById("Tog13a").style.display = "none";
      document
        .getElementById("Tog14")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("Tog15")
        .style.setProperty("display", "none", "important");
      document.getElementById("DivOwner").style.display = "block";
      document
        .getElementById("DivAddOwner")
        .style.setProperty("display", "block", "important");
      document.getElementById("DivToHide1").style.display = "none";
      document.getElementById("DivToHide1a").style.display = "none";
      document.getElementById("DivToHide2").style.display = "none";
      document.getElementById("DivToHide2a").style.display = "none";
      document.getElementById("DivToHide3").style.display = "none";
      document.getElementById("DivToHide3a").style.display = "none";
      document.getElementById("DivToHide4").style.display = "block";
    } else if (ChosenEquip == "BigBag") {
      document.getElementById("DivOwner").style.display = "none";
      document
        .getElementById("DivAddOwner")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("DivToHide2")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("DivToHide2a")
        .style.setProperty("display", "none", "important");
      document.getElementById("DivToHide3").style.display = "none";
      document.getElementById("DivToHide3a").style.display = "none";
      document
        .getElementById("DivToHide4")
        .style.setProperty("display", "none", "important");
      document.getElementById("Tog1").style.display = "none";
      document.getElementById("Tog2").style.display = "none";
      document.getElementById("Tog3").style.display = "none";
      document.getElementById("Tog5").style.display = "none";
      document.getElementById("Tog5a").style.display = "none";
      document.getElementById("Tog6").style.display = "none";
      document.getElementById("Tog6a").style.display = "none";
      document.getElementById("Tog7").style.display = "none";
      document.getElementById("Tog7a").style.display = "none";
      document.getElementById("Tog8").style.display = "none";
      document.getElementById("Tog8a").style.display = "none";
      document
        .getElementById("Tog9")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("Tog9a")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("Tog11")
        .style.setProperty("display", "none", "important");
      document
        .getElementById("Tog11a")
        .style.setProperty("display", "none", "important");
      document.getElementById("Tog12").style.display = "block";
      document.getElementById("Tog12a").style.display = "block";
      document.getElementById("Tog13").style.display = "block";
      document.getElementById("Tog13a").style.display = "block";
      document.getElementById("Tog14").style.display = "block";
      document
        .getElementById("Tog15")
        .style.setProperty("display", "none", "important");
      document.getElementById("DivToHide1").style.display = "block";
      document.getElementById("DivToHide1a").style.display = "block";
    } else if (ChosenEquip == "Tubular") {
      document.getElementById("DivOwner").style.display = "none";
      document
        .getElementById("DivAddOwner")
        .style.setProperty("display", "none", "important");
      document.getElementById("DivToHide1").style.display = "none";
      document.getElementById("DivToHide1a").style.display = "none";
      document.getElementById("DivToHide2").style.display = "none";
      document.getElementById("DivToHide2a").style.display = "none";
      document.getElementById("DivToHide3").style.display = "none";
      document.getElementById("DivToHide3a").style.display = "none";
      document.getElementById("DivToHide4").style.display = "none";
      document.getElementById("Tog1").style.display = "none";
      document.getElementById("Tog2").style.display = "none";
      document.getElementById("Tog3").style.display = "none";
      document.getElementById("Tog5").style.display = "none";
      document.getElementById("Tog5a").style.display = "none";
      document.getElementById("Tog6").style.display = "none";
      document.getElementById("Tog6a").style.display = "none";
      document.getElementById("Tog7").style.display = "none";
      document.getElementById("Tog7a").style.display = "none";
      document.getElementById("Tog8").style.display = "none";
      document.getElementById("Tog8a").style.display = "none";
      document.getElementById("Tog9").style.display = "block";
      document.getElementById("Tog9a").style.display = "block";
      document.getElementById("Tog11").style.display = "block";
      document.getElementById("Tog11a").style.display = "block";
      document.getElementById("Tog12").style.display = "block";
      document.getElementById("Tog12a").style.display = "block";
      document.getElementById("Tog13").style.display = "none";
      document.getElementById("Tog13a").style.display = "none";
      document
        .getElementById("Tog14")
        .style.setProperty("display", "none", "important");
      document.getElementById("Tog15").style.display = "block";
    } else {
      document.getElementById("DivOwner").style.display = "block";
      document.getElementById("DivToHide1").style.display = "block";
      document.getElementById("DivToHide1a").style.display = "block";
      document.getElementById("DivToHide2").style.display = "block";
      document.getElementById("DivToHide2a").style.display = "block";
      document.getElementById("DivToHide3").style.display = "block";
      document.getElementById("DivToHide3a").style.display = "block";
      document.getElementById("DivToHide4").style.display = "block";
      document.getElementById("Tog1").style.display = "none";
      document.getElementById("Tog2").style.display = "none";
      document.getElementById("Tog3").style.display = "none";
      document.getElementById("Tog5").style.display = "none";
      document.getElementById("Tog5a").style.display = "none";
      document.getElementById("Tog6").style.display = "none";
      document.getElementById("Tog6a").style.display = "none";
      document.getElementById("Tog7").style.display = "none";
      document.getElementById("Tog7a").style.display = "none";
      document.getElementById("Tog8").style.display = "none";
      document.getElementById("Tog8a").style.display = "none";
    }
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      <MainLayout
        title="Edit Equipment Details"
        loading={initializing}
        showFullLoader={initializing}
      >
        {" "}
        {equipmentTypes != null && equipmentMeasurements != null && (
          <div className="card-inner p-5">
            <form>
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Equipment Type</label>
                    <div className="form-control-wrap">
                      <Select
                        id="CargoCarryingEquipmentTypeId"
                        name="CargoCarryingEquipmentTypeId"
                        onChange={(item) => {
                          handleChange(item);
                        }}
                        value={equipmentTypes?.filter(function (option) {
                          return (
                            option.value ===
                            newEquipmentData.CargoCarryingEquipmentTypeId
                          );
                        })}
                        options={equipmentTypes}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6" id="DivToHide4">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      CCU Number
                    </label>
                    <div className="form-control-wrap">
                      <input
                        onChange={(e) => {
                          effectInput("Ccunumber", e.target.value);
                        }}
                        value={newEquipmentData.Ccunumber || ""}
                        type="text"
                        data-msg="Required"
                        className="form-control required"
                        id="Ccunumber"
                        name="Ccunumber"
                      />
                    </div>
                  </div>
                </div>
                <div className="gap" />
              </div>
              <div className="nk-wizard-content">
                <div className="row gy-3">
                  <div className="col-sm-4" id="DivToHide1">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Volume capacity
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput(
                              "VolumeCapacity",
                              e.target.value,
                              "VolumeCapacity"
                            );
                          }}
                          value={getMeasurementValue("VolumeCapacity")}
                          type="text"
                          data-msg="Required"
                          className="form-control required"
                          id="VolumeCapacity"
                          name="VolumeCapacity"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2" id="DivToHide1a">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Unit
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="VolumeCapacityMeasurementUnit"
                          name="VolumeCapacityMeasurementUnit"
                          value={measurementUnits?.filter(function (option) {
                            return (
                              option.value ===
                              getMeasurementValue("VolumeCapacity", "unitId")
                            );
                          })}
                          onChange={(item) => {
                            handleChange(item, "VolumeCapacity");
                          }}
                          options={volumeMeasurementUnits}
                          ref={volumeCapacityRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" id="DivToHide2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Load-carrying capacity
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput(
                              "LoadCarryingCapacity",
                              e.target.value,
                              "LoadCarryingCapacity"
                            );
                          }}
                          value={getMeasurementValue("LoadCarryingCapacity")}
                          type="text"
                          data-msg="Required"
                          className="form-control required"
                          id="LoadCarryingCapacity"
                          name="LoadCarryingCapacity"
                          required
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2" id="DivToHide2a">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Unit
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="LoadCarryingCapacityMeasurementUnit"
                          name="LoadCarryingCapacityMeasurementUnit"
                          onChange={(item) => {
                            handleChange(item, "LoadCarryingCapacity");
                          }}
                          value={measurementUnits?.filter(function (option) {
                            return (
                              option.value ===
                              getMeasurementValue(
                                "LoadCarryingCapacity",
                                "unitId"
                              )
                            );
                          })}
                          options={massMeasurementUnits}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" id="DivToHide3">
                    <div className="form-group ">
                      <label className="form-label" htmlFor="fw-last-name">
                        Space-Area Capacity
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput("SpaceAreaCapacity", e.target.value);
                          }}
                          value={getMeasurementValue("SpaceAreaCapacity")}
                          type="text"
                          className="form-control "
                          id="SpaceAreaCapacity"
                          name="SpaceAreaCapacity"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2" id="DivToHide3a">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Unit
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="SpaceAreaCapacityMeasurementUnit"
                          name="SpaceAreaCapacityMeasurementUnit"
                          value={measurementUnits?.filter(function (option) {
                            return (
                              option.value ===
                              getMeasurementValue("SpaceAreaCapacity", "unitId")
                            );
                          })}
                          onChange={(item) => {
                            handleChange(item, "SpaceAreaCapacity");
                          }}
                          options={areaMeasurementUnits}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" id="DivOwner">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Owner
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="PartyId"
                          name="PartyId"
                          onChange={(item) => {
                            handleChange(item);
                          }}
                          options={partyList}
                          value={partyList?.filter(function (option) {
                            return option.value === newEquipmentData.PartyId;
                          })}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-2 mt-1 align-center" id="DivAddOwner">
                    <div className="form-control-wrap">
                      <PartyModal
                        addNewLabel="+ Add Owner"
                        callback={async (_party_contact) =>
                          await addPartyModalCallback(_party_contact)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6" id="Tog1">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Certification
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="CertificateId"
                          name="CertificateId"
                          onChange={(item) => {
                            handleChange(item);
                          }}
                          value={certificates?.filter(function (option) {
                            return (
                              option.value === newEquipmentData.CertificateId
                            );
                          })}
                          options={certificates}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6" id="Tog3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Type
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="ContainerSizeId"
                          name="ContainerSizeId"
                          onChange={(item) => {
                            handleChange(item);
                          }}
                          options={equipmentSizes}
                          value={equipmentSizes.find(
                            (equipment) =>
                              equipment.ContainerSizeId ==
                              newEquipmentData.ContainerSizeId
                          )}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Size
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          data-msg="Required"
                          className="form-control required"
                          id="fw-last-name"
                          name="fw-last-name"
                          disabled
                          value={
                            equipmentSizes?.find(
                              (size) =>
                                size.ContainerSizeId ==
                                newEquipmentData.ContainerSizeId
                            )?.label == undefined
                              ? null
                              : `(${(
                                  equipmentSizes?.find(
                                    (size) =>
                                      size.ContainerSizeId ==
                                      newEquipmentData.ContainerSizeId
                                  )?.LengthInmm / 1000
                                ).toFixed(2)}m by ${(
                                  equipmentSizes?.find(
                                    (size) =>
                                      size.ContainerSizeId ==
                                      newEquipmentData.ContainerSizeId
                                  )?.WidthInmm / 1000
                                ).toFixed(2)}m)`
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6" id="Tog2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Container Description
                      </label>
                      <div className="form-control-wrap">
                        <textarea
                          onChange={(e) => {
                            effectInput("EquipmentDescription", e.target.value);
                          }}
                          placeholder="Fill in description"
                          className="form-control no-resize"
                          id="EquipmentDescription"
                          disabled
                          value={
                            equipmentSizes?.find(
                              (size) =>
                                size.ContainerSizeId ==
                                newEquipmentData.ContainerSizeId
                            )?.label == undefined
                              ? null
                              : `${
                                  equipmentSizes?.find(
                                    (size) =>
                                      size.ContainerSizeId ==
                                      newEquipmentData.ContainerSizeId
                                  )?.label
                                } - (${(
                                  equipmentSizes?.find(
                                    (size) =>
                                      size.ContainerSizeId ==
                                      newEquipmentData.ContainerSizeId
                                  )?.LengthInmm / 1000
                                ).toFixed(2)}m by ${(
                                  equipmentSizes?.find(
                                    (size) =>
                                      size.ContainerSizeId ==
                                      newEquipmentData.ContainerSizeId
                                  )?.WidthInmm / 1000
                                ).toFixed(2)}m)`
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" id="Tog7">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Max. Gross Mass
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput(
                              "MaxGrossMass",
                              e.target.value,
                              "MaxGrossMass"
                            );
                          }}
                          value={getMeasurementValue("MaxGrossMass")}
                          type="number"
                          data-msg="Required"
                          className="form-control required"
                          id="MaxGrossMass"
                          name="MaxGrossMass"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2" id="Tog7a">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Unit
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="MaxGrossMassMeasurementUnit"
                          name="MaxGrossMassMeasurementUnit"
                          onChange={(item) => {
                            handleChange(item, "MaxGrossMass");
                          }}
                          value={measurementUnits?.filter(function (option) {
                            return (
                              option.value ===
                              getMeasurementValue("MaxGrossMass", "unitId")
                            );
                          })}
                          options={massMeasurementUnits}
                          ref={maxGrossMaxRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" id="Tog5">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        TareMass
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput("TareMass", e.target.value, "TareMass");
                          }}
                          type="number"
                          data-msg="Required"
                          className="form-control required"
                          value={getMeasurementValue("TareMass")}
                          id="TareMass"
                          name="TareMass"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2" id="Tog5a">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Unit
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="TareMassMeasurementUnit"
                          name="TareMassMeasurementUnit"
                          value={measurementUnits?.filter(function (option) {
                            return (
                              option.value ===
                              getMeasurementValue("TareMass", "unitId")
                            );
                          })}
                          onChange={(item) => {
                            handleChange(item, "TareMass");
                          }}
                          options={massMeasurementUnits}
                          ref={tareMassRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" id="Tog6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Payload
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput("Payload", e.target.value, "Payload");
                          }}
                          value={getMeasurementValue("Payload")}
                          type="number"
                          data-msg="Required"
                          className="form-control required"
                          id="Payload"
                          name="Payload"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2" id="Tog6a">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Unit
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="PayloadMeasurementUnit"
                          name="PayloadMeasurementUnit"
                          value={measurementUnits?.filter(function (option) {
                            return (
                              option.value ===
                              getMeasurementValue("Payload", "unitId")
                            );
                          })}
                          onChange={(item) => {
                            handleChange(item, "Payload");
                          }}
                          options={massMeasurementUnits}
                          ref={payloadRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" id="Tog8">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Height
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput("Height", e.target.value, "Height");
                          }}
                          value={getMeasurementValue("Height")}
                          type="number"
                          data-msg="Required"
                          className="form-control required"
                          id="Height"
                          name="Height"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2" id="Tog8a">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Unit
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="HeightMeasurementUnit"
                          name="HeightMeasurementUnit"
                          value={measurementUnits?.filter(function (option) {
                            return (
                              option.value ===
                              getMeasurementValue("Height", "unitId")
                            );
                          })}
                          onChange={(item) => {
                            handleChange(item, "Height");
                          }}
                          options={lengthMeasurementUnits}
                          ref={heightRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6" id="Tog9">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Category
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="TubularCategoryId"
                          name="TubularCategoryId"
                          onChange={(item) => {
                            setSelectedCategory(item);
                            handleChange(item, "TubularCategoryId");
                          }}
                          value={categories?.filter(function (option) {
                            return (
                              option.value ===
                              newEquipmentData.TubularCategoryId
                            );
                          })}
                          options={parentCategories}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6" id="Tog9a">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        SubCategory
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="TubularSubCategoryId"
                          name="TubularSubCategoryId"
                          onChange={(item) => {
                            handleChange(item, "TubularSubCategoryId");
                          }}
                          value={categories?.filter(function (option) {
                            return (
                              option.value ===
                              newEquipmentData.TubularSubCategoryId
                            );
                          })}
                          options={subCategoryOptions}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" id="Tog11">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Diameter
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput("Diameter", e.target.value, "Diameter");
                          }}
                          value={getMeasurementValue("Diameter")}
                          type="number"
                          data-msg="Required"
                          className="form-control required"
                          id="Diameter"
                          name="Diameter"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2" id="Tog11a">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Unit
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="DiameterMeasurementUnit"
                          name="DiameterMeasurementUnit"
                          value={measurementUnits?.filter(function (option) {
                            return (
                              option.value ===
                              getMeasurementValue("Diameter", "unitId")
                            );
                          })}
                          onChange={(item) => {
                            handleChange(item, "Diameter");
                          }}
                          options={lengthMeasurementUnits}
                          ref={diameterRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" id="Tog12">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Length
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput("Length", e.target.value, "Length");
                          }}
                          value={getMeasurementValue("Length")}
                          type="number"
                          data-msg="Required"
                          className="form-control required"
                          id="Length"
                          name="Length"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2" id="Tog12a">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Unit
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="LengthUnits"
                          name="LengthUnits"
                          value={measurementUnits?.filter(function (option) {
                            return (
                              option.value ===
                              getMeasurementValue("Length", "unitId")
                            );
                          })}
                          onChange={(item) => {
                            handleChange(item, "Length");
                          }}
                          options={lengthMeasurementUnits}
                          ref={lengthRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4" id="Tog13">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Width
                      </label>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) => {
                            effectInput("Width", e.target.value, "Width");
                          }}
                          value={getMeasurementValue("Width")}
                          type="number"
                          data-msg="Required"
                          className="form-control required"
                          id="Width"
                          name="Width"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2" id="Tog13a">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-first-name">
                        Unit
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          id="WidthUnits"
                          name="WidthUnits"
                          value={measurementUnits?.filter(function (option) {
                            return (
                              option.value ===
                              getMeasurementValue("Width", "unitId")
                            );
                          })}
                          onChange={(item) => {
                            handleChange(item, "Width");
                          }}
                          options={lengthMeasurementUnits}
                          ref={widthRef}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6" id="Tog14">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Bigbag Description
                      </label>
                      <div className="form-control-wrap">
                        <input
                          placeholder="Fill in description"
                          className="form-control no-resize"
                          id="EquipmentDescription"
                          disabled
                          value={`BigBag - ${getMeasurementValue(
                            "VolumeCapacity"
                          )}${
                            volumeCapacityRef.current?.state?.value[0]?.label ||
                            " "
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6" id="Tog15">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Tubular Description
                      </label>
                      <div className="form-control-wrap">
                        <input
                          placeholder="Fill in description"
                          className="form-control no-resize"
                          id="EquipmentDescription"
                          disabled
                          value={`${
                            categories?.find(
                              (category) =>
                                category.TubularCategoryId ==
                                newEquipmentData.TubularCategoryId
                            )?.label
                          } - ${
                            subCategories?.find(
                              (category) =>
                                category.TubularCategoryId ==
                                newEquipmentData.TubularSubCategoryId
                            )?.TubularCategory
                          } - ${getMeasurementValue("Diameter")}${
                            diameterRef.current?.state?.value?.label || " "
                          } - ${getMeasurementValue("Length")}${
                            lengthRef.current?.state?.value?.label || " "
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-tb-col">
                <span>&nbsp;</span>
              </div>
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => updateEquipmentData()}
                >
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-danger m-1"
                  onClick={() => window.history.back()}
                >
                  Cancel
                </button>
              </div>
            </form>
            <hr className="preview-hr" />
          </div>
        )}{" "}
      </MainLayout>
    </>
  );
}
