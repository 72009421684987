import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import VesselService from "../../services/vesselService";
import VoyageService from "../../services/voyageService";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import { toReadableDate } from "../../utils/utilityFunctions";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import MapView from "../../components/mapView";
import CustomModal from "../../utils/customModal";
import _ from "lodash";
import { checkUserPermission } from "../../utils/utilityFunctions";

export default function VoyageListPage() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [pages, setPages] = useState([10, 20, 50, 100, 150]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [totalRecords, setTotalRecords] = useState(10);
  const [filter, setFilter] = useState(null);
  const [vesselFilter, setVesselFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [vessels, setVessels] = useState([]);
  const [voyages, setVoyages] = useState([]);
  const [mapObject, setMapObject] = useState(null);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [bookingRef, setBookingRef] = useState("");
  const [vesselId, setVesselId] = useState("");
  const [voyageStatus, setVoyageStatus] = useState("");
  const [confirmLock, setConfirmLock] = useState(null);
  const [saving, setSaving] = useState(false);

  const statusList = [
    { value: "Planned", label: "Planned" },
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
    { value: "Reviewed", label: "Reviewed" },
    { value: "Invoiced", label: "Invoiced" },
    { value: "Finalized", label: "Finalized" },
  ];

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);
    let _vessels = await VesselService.listAllVessels();
    _vessels = _vessels?.map((c) => ({
      ...c,
      label: c.VesselName,
      value: c.VesselSpecificationId,
    }));
    setVessels(_vessels || []);

    await loadData();

    setLoading(false);
  };

  const loadData = async (
    _page,
    _pageSize,
    _dateFrom = "",
    _dateTo = "",
    _bookingRef = "",
    _vesselId = "",
    _status = ""
  ) => {
    setLoading(true);
    _page = _page || page;
    _pageSize = _pageSize || pageSize;
    _dateFrom = _dateFrom;
    _dateTo = _dateTo;
    _bookingRef = _bookingRef;
    _vesselId = _vesselId;
    _status = _status;

    const voyagesDetails = await VoyageService.getVoyageList(
      _page,
      _pageSize,
      _dateFrom,
      _dateTo,
      _bookingRef,
      _vesselId,
      _status
    );
    let modifiedDataList = voyagesDetails?.Data || [];
      setTotalRecords(voyagesDetails?.TotalCount || 0);
    modifiedDataList = modifiedDataList.map((l) => ({
      ...l,
      DayOfSailing: toReadableDate(l.SailingDetails.DayOfSailing),
      actionsComponent: overflowMenu(l),
      changeLockComponent: lockIcon(l),
    }));
    setVoyages(modifiedDataList);

    page != _page && setPage(_page);
    pageSize != _pageSize && setPageSize(_pageSize);
    dateFrom != _dateFrom && setDateFrom(_dateFrom);
    dateTo != _dateTo && setDateTo(_dateTo || "");
    bookingRef != _bookingRef && setBookingRef(_bookingRef || "");
    vesselId != _vesselId && setVesselId(_vesselId || "");
    voyageStatus != _status && setVoyageStatus(_status || "");

    setLoading(false);
  };

  const lockIcon = (voyage) => {
    if (voyage.IsLocked == false) {
      return (
        <>
          <a
            style={{ color: "lightgreen" }}
            href="#"
            className="btn btn-sm btn-icon btn-trigger"
            title="Voyage open"
          >
            <em className="icon ni ni-unlock" />
          </a>
        </>
      );
    } else {
      return (
        <>
          <a
            style={{ color: "red" }}
            href="#"
            className="btn btn-sm btn-icon btn-trigger"
            title="Voyage locked"
          >
            <em className="icon ni ni-lock-alt" />
          </a>
        </>
      );
    }
  };

  const lockVoyagePlanAction = async (voyage) => {
    setSaving(true);
    if (voyage) {
      const response = await VoyageService.lockVoyage(voyage.VoyagePlanningId);

      if (response) {
        setConfirmLock(null);
        await init();
        toastr("success", "Voyage locked");
      } else {
        toastr("error", "Voyage could not be locked");
        setSaving(false);
      }
      setSaving(false);
    }
  };

  const unlockVoyagePlanAction = async (voyage) => {
    setSaving(true);
    if (voyage) {
      const response = await VoyageService.unlockVoyage(
        voyage.VoyagePlanningId
      );

      if (response) {
        setConfirmLock(null);
        await init();
        toastr("success", "Voyage unlocked");
      } else {
        toastr("error", "Voyage could not be unlocked");
        setSaving(false);
      }
      setSaving(false);
    }
  };

  const overflowMenu = (voyage) => {
    // const finalizeVoyage = async () => {
    //   if (voyage) {
    //     let statusFinalize = await VoyageService.updateVoyageStatus(
    //       voyage.VoyagePlanningId,
    //       3
    //     );
    //     if (statusFinalize) {
    //       setPayload({});
    //       await init();
    //       toastr("success", "Voyage finalized");
    //     }
    //   }
    //   setLoading(false);
    // };

    // const lockVoyagePlanAction = async () => {
    //   if (voyage) {
    //     const response = await VoyageService.lockVoyage(
    //       voyage.VoyagePlanningId
    //     );

    //     if (response) {
    //       await init();
    //       toastr("success", "Voyage locked");
    //     } else {
    //       toastr("error", "Voyage could not be locked");
    //     }
    //   }
    // };

    // const unlockVoyagePlanAction = async () => {
    //   if (voyage) {
    //     const response = await VoyageService.unlockVoyage(
    //       voyage.VoyagePlanningId
    //     );

    //     if (response) {
    //       await init();
    //       toastr("success", "Voyage unlocked");
    //     } else {
    //       toastr("error", "Voyage could not be unlocked");
    //     }
    //   }
    // };

    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                {checkUserPermission("voyage-planning_view_details") && (
                  <li>
                    <Link to={`/voyage/${voyage.VoyagePlanningId}`}>
                      <em className="icon ni ni-eye" />
                      <span>View Details</span>
                    </Link>
                  </li>
                )}

                {checkUserPermission("voyage-planning_add_cargo") &&
                  checkUserPermission("voyage-planning_remove_cargos") && (
                    <>
                      <li>
                        <a role="button" onClick={() => setConfirmLock(voyage)}>
                          <em className="icon ni ni-lock-alt" />
                          {voyage.IsLocked == false ? (
                            <span>Lock Voyage</span>
                          ) : (
                            <span>Unlock Voyage</span>
                          )}
                        </a>
                      </li>
                      {/* {voyage.IsLocked == false ? (
                        <li>
                          <a role="button" onClick={lockVoyagePlanAction}>
                            <em className="icon ni ni-lock-alt" />
                            <span>Lock Voyage</span>
                          </a>
                        </li>
                      ) : (
                        <li>
                          <a role="button" onClick={unlockVoyagePlanAction}>
                            <em className="icon ni ni-unlock" />
                            <span>Open Voyage</span>
                          </a>
                        </li>
                      )} */}
                    </>
                  )}

                {checkUserPermission("voyage-planning_add_cargo") && (
                  <>
                    {voyage.IsLocked == true ? null : (
                      <li>
                        <Link to={`/add-cargo/${voyage.VoyagePlanningId}`}>
                          <em className="icon ni ni-plus" />
                          <span>Add Cargo(s)</span>
                        </Link>
                      </li>
                    )}
                  </>
                )}
                {checkUserPermission("voyage-planning_assign_tank") && (
                  <li>
                    <Link to={`/assign-tanks/${voyage.VoyagePlanningId}`}>
                      <em className="icon ni ni-check-c"></em>
                      <span>Assign tank(s)</span>
                    </Link>
                  </li>
                )}
                <li className="divider" />
                {checkUserPermission("voyage-planning_remove_cargos") && (
                  <>
                    {voyage.IsLocked == true ? null : (
                      <li>
                        <Link to={`/remove-cargo/${voyage.VoyagePlanningId}`}>
                          <em className="icon ni ni-trash" />
                          <span>Remove Cargo(s)</span>
                        </Link>
                      </li>
                    )}
                  </>
                )}
                {checkUserPermission("voyage-planning_cargo_evidence") && (
                  <li>
                    <Link
                      to={`/upload-cargo-evidence/${voyage.VoyagePlanningId}`}
                    >
                      <em className="icon ni ni-files" />
                      <span>Cargo Evidence</span>
                    </Link>
                  </li>
                )}
                {checkUserPermission(
                  "voyage-planning_create_deck_and_cargo_plan"
                ) && (
                  <li>
                    <Link
                      to={`/deckCargoPlan-Upload/${voyage.VoyagePlanningId}`}
                    >
                      <em className="icon ni ni-property-add"></em>
                      <span>Deck & Cargo Plans</span>
                    </Link>
                  </li>
                )}
                <li>
                  <Link to={`/fuel-usage-log/${voyage.VoyagePlanningId}`}>
                    <em className="icon ni ni-property-add"></em>
                    <span>Fuel Usage Log</span>
                  </Link>
                </li>
                <li className="divider" />
                <li>
                  <Link to={`/water-usage-log/${voyage.VoyagePlanningId}`}>
                    <em className="icon ni ni-property-add"></em>
                    <span>Water Usage Log</span>
                  </Link>
                </li>

                {checkUserPermission("voyage-planning_show_on_map") && (
                  <li
                    className="clickable"
                    onClick={() =>
                      retrieveVesselId(voyage?.VesselSpecificationId)
                    }
                  >
                    <a>
                      <em className="icon ni ni-location" />
                      <span>Show on map</span>
                    </a>
                  </li>
                )}

                {checkUserPermission("voyage-planning_add_activity") &&
                  (voyage.VoyagePlanningStatus === "Finalized" ||
                  voyage.VoyagePlanningStatus === "Invoiced" ? null : (
                    <li>
                      <Link to={`/add-activity/${voyage.VoyagePlanningId}`}>
                        <em className="icon ni ni-files" />
                        <span>Add Activity</span>
                      </Link>
                    </li>
                  ))}

                {voyage.VoyagePlanningStatus == null ? null : (
                  <li>
                    <Link to={`/voyage-activities/${voyage.VoyagePlanningId}`}>
                      <em className="icon ni ni-list" />
                      <span>View activities</span>
                    </Link>
                  </li>
                )}

                {checkUserPermission("voyage-planning_review_voyage") &&
                  (voyage.VoyagePlanningStatus == "Completed" ||
                  voyage.VoyagePlanningStatus == "Reviewed" ? (
                    <li>
                      <Link to={`/review-voyage/${voyage.VoyagePlanningId}`}>
                        <em className="icon ni ni-check" />
                        <span>Review Voyage</span>
                      </Link>
                    </li>
                  ) : null)}

                {checkUserPermission("voyage-planning_finalize_voyage") &&
                voyage.VoyagePlanningStatus !== "Reviewed" ? null : (
                  <li>
                    <Link to={`/finalize-voyage/${voyage.VoyagePlanningId}`}>
                      <em className="icon ni ni-check" />
                      <span>Finalize Voyage</span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };
  const retrieveVesselId = async (voyage) => {
    let vesselPositionDetails = await VesselService.singleVesselPosition(
      voyage
    );
    console.log(vesselPositionDetails);
    let longitude = vesselPositionDetails?.Data?.Lon;
    let latiitude = vesselPositionDetails?.Data?.Lat;
    let destination = vesselPositionDetails?.Data?.Destination;

    if (!longitude || !latiitude) {
      toastr("error", "Map coordinates were not defined for this location");
      return;
    }

    setMapObject({
      long: longitude,
      lat: latiitude,
      location: "Destination:  " + destination,
      width: 520,
      height: 350,
    });
  };
  const headerList = [
    {
      Header: "Voyage",
      accessor: "VoyageCode",
    },
    {
      Header: "Vessel",
      accessor: "VesselName",
    },
    {
      Header: "Day of Sailing",
      accessor: "DayOfSailing",
    },
    {
      Header: "Zone",
      accessor: "ZoneName",
    },
    {
      Header: "Duration",
      accessor: "SailingDuration",
    },
    {
      Header: "Status",
      accessor: "SailingStatus",
    },
    {
      Header: "Voyage Status",
      accessor: "VoyagePlanningStatus",
    },
    {
      Header: "",
      accessor: "changeLockComponent",
    },
    {
      Header: "",
      accessor: "actionsComponent",
    },
  ];

  const filterByVoyageProperty = async () => {
    await loadData(
      1,
      null,
      dateFrom,
      dateTo,
      bookingRef,
      vesselId,
      voyageStatus
    );
  };

  const clearFilter = async () => {
    setDateFrom("");
    setDateTo("");
    setBookingRef("");
    setVesselId("");
    setVoyageStatus("");

    await loadData(1, null);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {checkUserPermission("voyage-planning_view_all_active_vessels") && (
            <li>
              <Link to={`/active-vessels`}>
                <em className="icon ni ni-eye" />
                <span> View All Active Vessels</span>
              </Link>
            </li>
          )}
          {checkUserPermission("voyage-planning_download_voyages") && (
            <li>
              <a
                href={`${process.env.REACT_APP_API}/api/Voyage/search?page=${page}&pageSize=${pageSize}`}
                target="_blank"
                className="btn btn-dim btn-secondary disabled"
              >
                <em className="icon ni ni-download" />
                <span>Download Voyages</span>
              </a>
            </li>
          )}

          {/* {voyages && checkUserPermission("voyage-planning_export_page") && (
            <li>
              <button
                type="button"
                onClick={() => exportToCSV(voyages, "list_of_voyages")}
                className="btn btn-white btn-outline-light"
              >
                <em className="icon ni ni-download-cloud" />
                <span>Export page</span>
              </button>
            </li>
          )} */}
          {checkUserPermission("voyage-planning_add_voyage") && (
            <li className="nk-block-tools-opt">
              <div className="drodown">
                <a
                  href="#"
                  className="dropdown-toggle btn btn-icon btn-primary"
                  data-toggle="dropdown"
                >
                  <em className="icon ni ni-plus" />
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <Link to="/create-voyage">
                        <span>Add Voyage</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Voyages"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <div className="table-component">
        <div className="card-inner-group">
          {
            <div className="card-inner position-relative card-tools-toggle">
              {checkUserPermission("voyage-planning_search") && (
                <div className="card-title-group">
                  <div className="card-tools">
                    <div className="dropdown">
                      <a
                        href="#"
                        className="btn btn-trigger btn-icon dropdown-toggle "
                        data-toggle="dropdown"
                      >
                        <em className="icon ni ni-search" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
                        <form>
                          <div className="dropdown-head">
                            <span className="sub-title dropdown-title">
                              Filter Voyages
                            </span>
                            <div className="dropdown">
                              <a
                                className="btn btn-sm btn-icon"
                                data-toggle="dropdown"
                              >
                                <em
                                  className="icon ni ni-cross"
                                  title="close"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="dropdown-body dropdown-body-rg">
                            <div className="row gx-6 gy-3">
                              <div className="col-12">
                                <div
                                  className="form-group"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <label className="overline-title overline-title-alt">
                                    Vessel
                                  </label>
                                  <Select
                                    className="border-transparent"
                                    placeholder="Filter by Vessel"
                                    value={vessels.find(
                                      (vessel) => vessel.value === vesselId
                                    )}
                                    options={vessels}
                                    onChange={(option) =>
                                      setVesselId(option.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div
                                  className="form-group"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <label className="overline-title overline-title-alt">
                                    Status
                                  </label>
                                  <Select
                                    // className="border-transparent"
                                    className="select-dropdown-sm"
                                    placeholder="Filter by Status"
                                    value={statusList.find(
                                      (status) => status.value === voyageStatus
                                    )}
                                    options={statusList}
                                    onChange={(e) => setVoyageStatus(e.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="form-group">
                                  <label
                                    className="overline-title overline-title-alt"
                                    htmlFor="from-date-filter"
                                  >
                                    From
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control "
                                    placeholder=""
                                    id="from-date-filter"
                                    value={dateFrom}
                                    onChange={(e) =>
                                      setDateFrom(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="form-group">
                                  <label
                                    className="overline-title overline-title-alt"
                                    htmlFor="to-date-filter"
                                  >
                                    To
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control "
                                    placeholder=""
                                    id="to-date-filter"
                                    value={dateTo}
                                    onChange={(e) => setDateTo(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="form-group">
                                  <label
                                    className="overline-title overline-title-alt"
                                    htmlFor="from-date-filter"
                                  >
                                    Booking Reference
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control "
                                    placeholder=""
                                    value={bookingRef}
                                    onChange={(e) =>
                                      setBookingRef(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="gap gap-10px" />

                              <div className="col-12">
                                <div className="form-group">
                                  <button
                                    type="button"
                                    onClick={filterByVoyageProperty}
                                    className="btn btn-secondary"
                                  >
                                    <span>Filter</span>
                                  </button>
                                  <a
                                    className="clickable ml-2"
                                    onClick={() => clearFilter()}
                                  >
                                    Reset Filter
                                  </a>
                                </div>
                              </div>
                              <div className="gap gap-50px" />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* .form-inline */}
                  </div>

                  <div className="card-tools mr-n1">
                    <ul className="btn-toolbar gx-1">
                      <li className="btn-toolbar-sep" />
                      {/* li */}
                      <li>
                        <div className="toggle-wrap">
                          <a
                            href="#"
                            className="btn btn-icon btn-trigger toggle"
                            data-target="cardTools"
                          >
                            <em className="icon ni ni-menu-right" />
                          </a>
                          <div
                            className="toggle-content"
                            data-content="cardTools"
                          >
                            <ul className="btn-toolbar gx-1">
                              <li className="toggle-close">
                                <a
                                  href="#"
                                  className="btn btn-icon btn-trigger toggle"
                                  data-target="cardTools"
                                >
                                  <em className="icon ni ni-arrow-left" />
                                </a>
                              </li>
                              {/* li */}

                              <li>
                                <div className="dropdown">
                                  <a
                                    href="#"
                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-setting" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                    <ul className="link-check">
                                      <li>
                                        <span>Show</span>
                                      </li>
                                      {pages.map((item, index) => (
                                        <li
                                          onClick={() => loadData(null, item)}
                                          key={index}
                                          className={`${
                                            page == item && "active"
                                          }`}
                                        >
                                          <Link to="#">{item}</Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                                {/* .dropdown */}
                              </li>
                              {/* li */}
                            </ul>
                            {/* .btn-toolbar */}
                          </div>
                          {/* .toggle-content */}
                        </div>
                        {/* .toggle-wrap */}
                      </li>
                      {/* li */}
                    </ul>
                    {/* .btn-toolbar */}
                  </div>
                  {/* .card-tools */}
                </div>
              )}
            </div>
          }
          {/* .card-inner */}
        </div>
        {/* .card-inner-group */}
      </div>

      {loading || (
        <>
          <BookingTable
            columns={headerList}
            data={voyages}
            cssArray={["font-weight-500", "", "", "text-left", "", ""]}
            enableSearch={false}
            emptyMessage="No voyage record to show on page"
            disablePageSize={true}
          />
          <br />
          
            <div className="">
              <Paginate
                onChange={(p) => loadData(p)}
                currentPage={page}
                pageSize={pageSize}
                totalRecordsCount={totalRecords}
              />
            </div>
        </>
      )}

      {mapObject && (
        <CustomModal
          title={mapObject.location}
          content={<MapView {...mapObject} />}
          onClose={() => setMapObject(null)}
          isClosed={false}
          noContentPadding={true}
        />
      )}

      {confirmLock && (
        <CustomModal
          title={
            <span>
              <em class="icon ni ni-info-i"></em>{" "}
              {confirmLock.IsLocked == true ? "Unlock Voyage" : "Lock Voyage"}
            </span>
          }
          content={
            <>
              <div className="">
                <div className="text-center mt-3">
                  <h5>
                    {confirmLock.IsLocked == true
                      ? "Would you like to unlock this voyage?"
                      : "Would you like to lock this voyage?"}
                  </h5>
                </div>
                <br />

                {saving ? (
                  <>
                    <div className="text-center">
                      <button type="button" className="btn btn-md btn-link m-1">
                        <Spinner size="1.5" />
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="row my-1 p-1">
                    <div className="col-12 text-center">
                      <a
                        role="button"
                        className="btn btn-lg btn-dark active"
                        onClick={
                          confirmLock.IsLocked == true
                            ? () => unlockVoyagePlanAction(confirmLock)
                            : () => lockVoyagePlanAction(confirmLock)
                        }
                      >
                        Confirm
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </>
          }
          onClose={() => setConfirmLock(null)}
          isClosed={false}
          noContentPadding={true}
        />
      )}
    </MainLayout>
  );
}
