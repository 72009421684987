import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import VoyageService from "../../services/voyageService";
import VoyageDetailsComponent from "../../components/voyage/voyageDetailsComponent";
import VoyageDocumentsComponent from "../../components/voyage/voyageDocumentsComponent";
import Tabs from "../../utils/tabs";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import RemoveBulkListComponent from "../../components/voyage/removeBulkList";
import RemoveDeckListComponent from "../../components/voyage/removeDeckList";
import DeckListComponent from "../../components/voyage/deckList";
import { forEach } from "lodash";

export default function VoyageDetailsPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [bulkCargo, setBulkCargo] = useState([]);
  const [deckCargo, setDeckCargo] = useState([]);
  const [voyage, setVoyage] = useState([]);

  useEffect(async () => {
    await init();
  }, [params.voyagePlanningId]);

  const init = async () => {
    setLoading(true);
    const _voyage = await VoyageService.get(params.voyagePlanningId);
    setVoyage(_voyage || []);
    setLoading(false);
  };
  console.log(voyage);

  const tabHeaders = [
    { icon: "icon ni ni-view-grid-wd", text: "Details" },
    { icon: "icon ni ni-files", text: "Voyage Documents" },
  ];

  const tabContent = [
    <VoyageDetailsComponent
      voyagePlanningId={params.voyagePlanningId}
      voyage={voyage}
    />,
    <VoyageDocumentsComponent voyagePlanningId={params.voyagePlanningId} />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {voyage.length == 0 || voyage.IsLocked == true ? null : (
            <>
              <li>
                <Link
                  to={`/add-cargo/${params.voyagePlanningId}`}
                  className="btn btn-white btn-outline-light"
                >
                  <em className="icon ni ni-plus" />
                  <span>Add Cargo(s)</span>
                </Link>
              </li>
              <li>
                <Link
                  to={`/remove-cargo/${params.voyagePlanningId}`}
                  className="btn btn-white btn-outline-light"
                >
                  <em className="icon ni ni-trash" />
                  <span>Remove Cargo(s)</span>
                </Link>
              </li>
            </>
          )}
          <li>
            <Link
              to={`/upload-cargo-evidence/${params.voyagePlanningId}`}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-files" />
              <span>Upload Cargo Evidence</span>
            </Link>
          </li>

          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <span>{"<< "}Back to Voyages</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Voyage Details"
      loading={loading}
      pageActions={pageActions}
    >
      <div className="">{_tabView}</div>
    </MainLayout>
  );
}
