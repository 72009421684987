import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CustomModal from "../../utils/customModal";
import {
  toReadableDate,
  dateToYYYY_MM_DD,
  camelizeKeys,
} from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import RoleService from "../../services/roleService";
import UserService from "../../services/userService";
import VesselService from "../../services/vesselService";
import LocationService from "../../services/locationService";

export default function AddNewRolePage() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [roles, setRoles] = useState([]);
  const [locations, setLocations] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [saving, setSaving] = useState(false);
  const localizer = momentLocalizer(moment);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const [reloading, setReloading] = useState(false);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "addnewuser";
  console.log(payload);

  useEffect(async () => {
    setLoading(true);
    setLoading(false);

    return () => {
      ClearValidator(validationGroup);
    };
  }, []);

  const init = async () => {
    let _roles = await RoleService.getAllRolesPaginated(1, 1000);
    _roles = camelizeKeys(_roles?.Data || [])?.map((role) => ({
      ...role,
      label: role.name,
      value: role.roleId,
    }));
    setRoles(_roles || []);
  };

  const submitForm = async () => {
    const _payload = { ...payload };

    if (!_payload.name) {
      toastr("error", "Enter role name");
      return;
    }

    if (!_payload.isSystemRole) {
      _payload.isSystemRole = false;
    }

    setSaving(true);

    const response = await RoleService.addNewRole(_payload);
    if (response) {
      setPayload({});
      toastr("success", "Role added successfully");
      setRedirectUrl(`/grant-permissions/${response.RoleId}`);
    } else {
      toastr("error", "Role could not be added");
      setSaving(false);
    }
    setSaving(false);
  };

  // const reloadPage = () => {
  //   setReloading(true);
  //   setTimeout(() => {
  //     setReloading(false);
  //   }, 200);
  // };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout title="Add New Role" loading={loading} showFullLoader={loading}>
      <form>
        {
          <div className="card-inner">
            <div>
              <div className="row mt-4 align-items-center">
                <div className="col-md-3">
                  <div className="form-group ">
                    <label className="form-label small">Role Name</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control required"
                        required
                        onChange={(e) => addPayload("name", e.target.value)}
                      />
                      <Validator
                        data={payload.name}
                        errorMessage="Enter role name"
                        groupName={validationGroup}
                        dataKey={"name"}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <div className="form-group row align-items-center">
                    <label className="form-label col-auto">
                      Is system role?
                    </label>
                    <div className="form-control-wrap col">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitchActive"
                          checked={!!payload.isSystemRole}
                          onChange={() =>
                            addPayload(
                              "isSystemRole",
                              payload.isSystemRole ? false : true
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customSwitchActive"
                        >
                          {payload.isSystemRole ? "Yes" : "No"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        }

        {saving && (
          <div className="form-group p-2 m-3">
            <button type="button" className="btn btn-md btn-link m-1">
              <Spinner size="1.5" />
            </button>
          </div>
        )}
        {!saving && (
          <div className="form-group p-2 m-3">
            <button
              type="button"
              className="btn btn-md btn-dark btn-wide px-5 mr-3"
              onClick={() => submitForm()}
            >
              Add Role
            </button>
            <button
              type="button"
              className="btn btn-sm btn-link m-1"
              onClick={() => window.history.back()}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
      <hr className="preview-hr" style={{ visibility: "hidden" }} />
    </MainLayout>
  );
}
