import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import BulkListComponent from "./bulkList";
import DeckListComponent from "../../components/voyage/deckList";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";

export default function VoyageDetailsComponent({ voyagePlanningId }) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [bulkCargo, setBulkCargo] = useState([]);
  const [deckCargo, setDeckCargo] = useState([]);
  const [voyage, setVoyage] = useState([]);
  const [confirmLock, setConfirmLock] = useState(null);

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setInit(true);
    const _voyage = await VoyageService.get(voyagePlanningId);
    setVoyage(_voyage || []);

    if (_voyage?.BulkCargos) {
      let _bCargo = _voyage.BulkCargos?.map((bulkCargo) => ({
        ...bulkCargo,
        BulkEvidence: bulkCargoEvidence(bulkCargo),
      }));
      setBulkCargo(_bCargo || {});
    }

    if (_voyage?.DeckCargos) {
      let _dCargo = _voyage.DeckCargos?.map((deckCargo) => ({
        ...deckCargo,
        DeckEvidence: deckCargoEvidence(deckCargo),
      }));
      setDeckCargo(_dCargo || {});
    }

    setInit(false);
  };

  const bulkCargoEvidence = (bulkCargo) => {
    if (bulkCargo && bulkCargo.LoadedCargoEvidence !== null) {
      return (
        <div>
          <a
            href={`${process.env.REACT_APP_API}/api/files/loadfile/${bulkCargo.LoadedCargoEvidence}`}
            target="_blank"
          >
            View Evidence
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  const deckCargoEvidence = (deckCargo) => {
    if (deckCargo.LoadedCargoEvidence !== null) {
      return (
        <div>
          <a
            href={`${process.env.REACT_APP_API}/api/files/loadfile/${deckCargo.LoadedCargoEvidence}`}
            target="_blank"
          >
            View Evidence
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  const lockVoyagePlanToggle = async () => {
    setSaving(true);
    const response = await VoyageService.lockVoyage(voyagePlanningId);

    if (response) {
      setConfirmLock(null);
      await init();
      toastr("success", "Voyage locked");
    } else {
      toastr("error", "Voyage could not be locked");
      setSaving(false);
    }
    setSaving(false);
  };

  const unlockVoyagePlanToggle = async () => {
    setSaving(true);
    const response = await VoyageService.unlockVoyage(voyagePlanningId);

    if (response) {
      setConfirmLock(null);
      await init();
      toastr("success", "Voyage unlocked");
    } else {
      toastr("error", "Voyage could not be unlocked");
      setSaving(false);
    }
    setSaving(false);
  };

  const dataItem = (label, value) => {
    if (value == "null") value = "";
    return (
      <li className="col-6 col-lg-3">
        <p>
          <span className="text-soft small">{label}</span> {value || "-"}
        </p>
      </li>
    );
  };

  return (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="card sp-plan">
            <div className="row no-gutters">
              <div className="col-md-8">
                <div className="sp-plan-info card-inner">
                  <div className="row gx-0 gy-3">
                    <div className="col-xl-9 col-sm-8">
                      <div className="sp-plan-name">
                        <h6 className="title">
                          <Link to={"#"}>
                            {voyage.VoyageCode}
                            {voyage.SailingStatus && (
                              <span className="badge badge-light badge-pill ml-3">
                                {voyage.SailingStatus}
                              </span>
                            )}
                          </Link>

                          <>
                            <span
                              className={`badge  badge-pill ml-3 ${
                                voyage?.IsLocked == true
                                  ? "badge-danger"
                                  : "badge-success"
                              } `}
                            >
                              {voyage?.IsLocked == true ? "locked" : "unlocked"}
                            </span>
                            <span onClick={() => setConfirmLock(voyage)}>
                              <em
                                className={`icon ni ml-1 pointer align-middle fs-22px  ${
                                  voyage?.IsLocked == true
                                    ? "ni-toggle-off text-danger"
                                    : "ni-toggle-on text-success"
                                }`}
                                title={
                                  voyage?.IsLocked == true
                                    ? "Unlock voyage"
                                    : "Lock voyage"
                                }
                              />
                            </span>
                          </>
                        </h6>
                        <p style={{ visibility: "hidden" }}>
                          '': <span className="text-base">{"-"}</span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-4"></div>
                  </div>
                </div>
                <div className="sp-plan-desc card-inner">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Voyage
                    </span>{" "}
                  </p>
                  <ul className="row gx-1">
                    {dataItem("Voyage Code", voyage.VoyageCode)}
                    {dataItem("Vessel Name", voyage.VesselName)}
                    {dataItem("Sailing Status", voyage.SailingStatus)}
                    {dataItem("Sailing Duration", voyage.SailingDuration)}
                    {dataItem("Arrival Port", voyage.ArrivalPortName || "-")}
                    {dataItem(
                      "Expected Date of Arrival",
                      toReadableDate(voyage.ExpectedDateOfArrival) || "-"
                    )}
                    {dataItem("Departure Port", voyage.DeparturePortName)}
                    {dataItem(
                      "Expected Date of Departure",
                      toReadableDate(voyage.ExpectedDateOfDeparture) || "-"
                    )}
                    {dataItem("Zone Name", voyage.ZoneName)}
                  </ul>

                  {/* <button
                    // onClick={() => setModalIsClosed(false)}
                    type="button"
                    className="mt-4 btn btn-dark btn-dim btn-sm "
                >
                    <span>Edit Voyage Details</span>
                </button> */}
                </div>
              </div>
            </div>
          </div>

          <div className="card-inner p-5">
            <p>
              <span className="preview-title-lg overline-title">
                Bulk Cargo
              </span>{" "}
            </p>
            <BulkListComponent dataList={bulkCargo} />

            <div className="gap" />
            <p>
              <span className="preview-title-lg overline-title">
                Deck Cargo
              </span>{" "}
            </p>
            <DeckListComponent dataList={deckCargo} />
          </div>
        </>
      )}

      {confirmLock && (
        <CustomModal
          title={
            <span>
              <em class="icon ni ni-info-i"></em>{" "}
              {confirmLock.IsLocked == true ? "Unlock Voyage" : "Lock Voyage"}
            </span>
          }
          content={
            <>
              <div className="">
                <div className="text-center mt-3">
                  <h5>
                    {confirmLock.IsLocked == true
                      ? "Would you like to unlock this voyage?"
                      : "Would you like to lock this voyage?"}
                  </h5>
                </div>
                <br />

                {saving ? (
                  <>
                    <div className="text-center">
                      <button type="button" className="btn btn-md btn-link m-1">
                        <Spinner size="1.5" />
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="row my-1 p-1">
                    <div className="col-12 text-center">
                      <a
                        role="button"
                        className="btn btn-lg btn-dark active"
                        onClick={
                          confirmLock.IsLocked == true
                            ? () => unlockVoyagePlanToggle(confirmLock)
                            : () => lockVoyagePlanToggle(confirmLock)
                        }
                      >
                        Confirm
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </>
          }
          onClose={() => setConfirmLock(null)}
          isClosed={false}
          noContentPadding={true}
        />
      )}
      {/* {!modalIsClosed && <CustomModal title="Edit Booking Request" isClosed={modalIsClosed}
                content={<EditVesselDetailsComponent bookingRequestId={bookingRequestId} callback={() => onModalClose()} />} onClose={() => onModalClose()} />} */}
    </>
  );
}
