import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import EquipmentService from "../../services/equipmentService";
import Spinner from "../../utils/spinner";
import CommercialService from "../../services/commercialService";

export default function OffHirePeriodsComponent({
  dataList,
  reload,
  deleteAction,
  vesselSpecId = "",
  commercial,
}) {
  const [tableData, setTableData] = useState(dataList || []);
  const [loading, setLoading] = useState(true);

  const headerList = [
    {
      Header: "From",
      accessor: "OffHireStartDate",
    },
    {
      Header: "To",
      accessor: "OffHireEndDate",
    },
  ];

  useEffect(async () => {
    setLoading(true);
    await retrieveOffhirePeriods();

    setLoading(false);
  }, [dataList]);

  const retrieveOffhirePeriods = async () => {
    setLoading(true);
    const _res = await CommercialService.getOffhirePeriods(
      commercial.VesselSpecificationId
    );

    const offhirePeriods = _res?.offHireDetails?.map((period) => ({
      ...period,
      OffHireStartDate: toReadableDate(period.OffHireStartDate),
      OffHireEndDate: toReadableDate(period.OffHireEndDate),
    }));

    setTableData(offhirePeriods || []);
    setLoading(false);
  };
  return (
    <div style={{ zoom: "0.85" }}>
      {loading ? (
        <div className="center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", "", "", "text-right"]}
          data={tableData}
          reloadData={(page, pageSize, query) =>
            reload && reload(page, pageSize, query)
          }
          enableSearch={false}
          deleteAction={deleteAction}
          emptyMessage="No Off-hire periods"
          paginationStyle="2"
        />
      )}
    </div>
  );
}
