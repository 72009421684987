import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";

export default function Profile() {
  const [loading, setLoading] = useState(true);
  const user = JSON.parse(localStorage.resu)?.user;
  const roles = JSON.parse(localStorage.resu)?.roles;

  useEffect(async () => {
    setLoading(true);

    setLoading(false);
  }, []);

  const init = async () => {};

  return (
    <MainLayout title="Profile" loading={loading} showFullLoader={loading}>
      <div className="card-inner">
        <div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small">Profile Picture</label>
                <div className="form-control-wrap">
                  {user.ImageUrl ? (
                    <img src={user.ImageUrl} alt="Profile Picture" />
                  ) : (
                    <p> No profile picture uploaded</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="gap gap-12px" />

          <div className="row">
            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small">First Name</label>
                <div className="form-control-wrap">
                  <p>{user.FirstName}</p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small">Last Name</label>
                <div className="form-control-wrap">
                  <p>{user.LastName}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="gap gap-12px" />

          <div className="row">
            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small required">
                  Username / E-mail
                </label>
                <div className="form-control-wrap">
                  <p>{user.Email}</p>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small">Phone Number</label>
                <div className="form-control-wrap">
                  <p>{user.PhoneNumber || "N/A"}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="gap gap-12px" />

          <div className="row">
            <div className="col-md-3">
              <div className="form-group ">
                <label className="form-label small required">Role(s)</label>
                <div className="form-control-wrap">
                  {roles.map((role) => (
                    <p className="mb-1">{role.Name}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
