import React, { useEffect, useState, useCallback } from "react";
import "../../styles/dataTable.scss";
import {
	useNavigate,
	useLocation,
	Link,
	Navigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import moment from "moment";
import ms from "ms";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import { toReadableDate } from "../../utils/utilityFunctions";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import {
	generateQueryString,
	checkUserPermission,
	formatValue,
} from "../../utils/utilityFunctions";
import InvoiceService from "../../services/invoiceService";
import RoleService from "../../services/roleService";
import styled from "styled-components";
import CustomModal from "../../utils/customModal";
import {
	PieChart,
	Pie,
	Sector,
	ResponsiveContainer,
	Cell,
	Tooltip,
	Legend,
} from "recharts";

export default function OustandingInvoices() {
	const [loading, setLoading] = useState(false);
	const [payload, setPayload] = useState({});
	const [pages, setPages] = useState([10, 20, 50, 100, 150]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalRecords, setTotalRecords] = useState(10);
	const [utilizationList, setUtilizationList] = useState([]);
	const navigate = useNavigate();
	const [filterObject, setFilterObject] = useState([]);
	const [vesselList, setVesselList] = useState([]);
	const [unHideTable, setUnHideTable] = useState(false);
	const [labelValue, setLabelValue] = useState([]);


	let retrievedRole = localStorage.getItem("resu");
	retrievedRole = JSON.parse(retrievedRole);
	let retrievedPoolId = retrievedRole?.poolId;
	let operatorPartyId = retrievedRole?.operatorPartyId;

	useEffect(async () => {
		await init();
	}, []);

	const init = async () => {
		setLoading(true);
		await loadData();
		const _roles = await RoleService.getAllRolesPaginated(1, 1000);

		setLoading(false);
	};
	let queryStr = generateQueryString(filterObject);
	if (retrievedPoolId) {
		queryStr = `poolId=${retrievedPoolId}`;
	} else if (operatorPartyId) {
		queryStr = `partyId=${operatorPartyId}`;
	} else if (retrievedPoolId != null && operatorPartyId != null) {
		queryStr = "";
	}

	const viewInvoicePage = (view) => {
		return (
			<div>
				<Link
					to={`/invoices/${view.InvoiceId}`}
					className="btn btn-xs text-primary"
					title="Invoice details">
					<span>Details</span>
				</Link>
			</div>
		);
	};

	const loadData = async (_page, _pageSize, query = queryStr) => {
		setLoading(true);
		_page = _page || page;
		_pageSize = _pageSize || pageSize;
		const utilizationDetails = await InvoiceService.oustandingInvoicesDashboard(
			_page,
			_pageSize,
			query
		);
		if (utilizationDetails?.TotalCount) {
			setTotalRecords(utilizationDetails.TotalCount);
		}
		if (!utilizationDetails)
			return toastr("error", "there are no records to display");

		let modifiedDataList = utilizationDetails || [];
		modifiedDataList = modifiedDataList.map((list) => ({
			...list,
			newNetAmount: `${list?.Currency} ${(list?.NetAmount).toLocaleString(
				
			)}`,
			newGrossAmount: `${list?.Currency} ${(list?.GrossAmount).toLocaleString(
				
			)}`,
			formatedDate: toReadableDate(list?.DueDate),
			viewDetailsButton: viewInvoicePage(list),
			name: list?.PartyName,
		}));

		setUtilizationList(modifiedDataList);

		page != _page && setPage(_page);
		pageSize != _pageSize && setPageSize(_pageSize);
		setLoading(false);
	};

	//The function below sums up the NetAmount for objects with
	//the same keys(name)
	const newAggregateObject = {};
	utilizationList.map((item) => {
		if(newAggregateObject[item.name] ){
			newAggregateObject[item.name] = newAggregateObject[item.name] + item.NetAmount
		}
		else	newAggregateObject[item.name] = item.NetAmount
	})
	let utilizationKeys  = Object.keys(newAggregateObject)
    const newUtilization = utilizationKeys.map((item) => ({
		name: item,
		NetAmount: newAggregateObject[item]
	}) ) 



	let unpaidInvoice = utilizationList.find(
		(item) => item.InvoiceStatus.toLowerCase() === "unpaid"
	);

	const headerList = [
		{
			Header: "Provider",
			accessor: "PartyName",
		},
		{
			Header: "Amount",
			accessor: "newGrossAmount",
		},
		{
			Header: "Amount Due",
			accessor: "newNetAmount",
		},
		{
			Header: "Date Due",
			accessor: "formatedDate",
		},
		{
			Header: "",
			accessor: "viewDetailsButton",
		},
	];

	const pageLimiter = Math.ceil(totalRecords / pageSize);

	const prevLoaded = async () => {
		if (pageLimiter > 1 && page < pageLimiter) {
			await loadData(page - 1, null, queryStr);
		} else if (pageLimiter == page) {
			await loadData(page - 1, null, queryStr);
		}
	};

	const nextLoaded = async () => {
		if (pageLimiter !== 1 && page < pageLimiter) {
			await loadData(page + 1, null, queryStr);
		}
	};

	const goToPage = async (page) => {
		await loadData(page, null, queryStr);
	};
	const colors = [
		"#003f5c",
		"#2f4b7c",
		"#665191",
		"#a05195",
		"#d45087",
		"#f95d6a",
		"#ff7c43",
		"#ffa600",
	];

	const pieCustomLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		NetAmount,
		index,
	}) => {
		const RADIAN = Math.PI / 180;
		// eslint-disable-next-line
		const radius = 25 + innerRadius + (outerRadius - innerRadius);
		// eslint-disable-next-line
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		// eslint-disable-next-line
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text
				x={x}
				y={y}
				fill="8884d8"
				textAnchor={x > cx ? "start" : "end"}
				dominantBaseline="central">
				{utilizationList[index].PartyName}-{NetAmount.toLocaleString(undefined)}
			</text>
		);
	};

	return (
		<>
			{loading ? (
				<div className="w-100 text-center center my-5 py-5">
					<Spinner />
				</div>
			) : (
				<>
					<div className="card-inner-group">
						<h5 className="text-center pt-3 pb-0">
							Invoice & Payments (Draft Invoices)
						</h5>
						{utilizationList.length == 0 ? null : (
							// The filter and pagination component

							<div className="card-inner position-relative card-tools-toggle pt-0">
								{
									<div className="card-title-group">
										<div className="card-tools ml-n1">
											<ul className="btn-toolbar gx-1">
												<li>
													<div className="dropdown">
														{/* <button
															className="btn btn-trigger btn-icon dropdown-toggle "
															data-toggle="dropdown"
															onClick={(e) => e.stopPropagation()}>
															
														</button> */}
														<div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1"></div>
													</div>
												</li>
											</ul>
										</div>
										{pageLimiter > 1 && unHideTable && (
											<div className="card-tools mr-n1">
												<ul className="btn-toolbar gx-1">
													<li>
														<button
															className="btn btn-trigger btn-icon "
															onClick={page !== 1 ? prevLoaded : null}
															disabled={page == 1 ? true : false}
															title="Previous voyages">
															<em className="icon ni ni-arrow-left" />
														</button>
													</li>
													<li className="btn-toolbar-sep" />
													<li>
														<button
															className="btn btn-trigger btn-icon "
															onClick={page >= 1 ? nextLoaded : null}
															disabled={page >= pageLimiter ? true : false}
															title="Next voyages">
															<em className="icon ni ni-arrow-right" />
														</button>
													</li>
													<li className="btn-toolbar-sep" />
													<li>
														<div className="g" data-select2-id={6}>
															<div
																className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3"
																data-select2-id={5}>
																<div>
																	<input
																		type="number"
																		className="form-control border search-input text-align-center"
																		placeholder=""
																		onChange={(e) => goToPage(e.target.value)}
																		value={page}
																		style={{ width: "50px" }}
																	/>
																</div>
																<div>of {`${pageLimiter}`}</div>
															</div>
														</div>
													</li>
												</ul>
											</div>
										)}
									</div>
								}
							</div>
						)}
						<>
							{unHideTable && (
								<div className="" style={{ width: "100%", maxHeight: "300px" }}>
									<div className="text-center">
										<button
											type="button"
											className="btn btn-sm btn-link m-1"
											onClick={() => setUnHideTable(false)}>
											Chart view
										</button>
									</div>
									<BookingTable
										columns={headerList}
										data={utilizationList}
										cssArray={["font-weight-500", "", "", "text-left", "", ""]}
										enableSearch={false}
										emptyMessage="No invoice record to show"
										disablePageSize={true}
									/>
								</div>
							)}
						</>
					</div>
				</>
			)}
			{!unHideTable && (
				<div
					className="text-center"
					style={{ width: "100%", maxHeight: "300px" }}>
					<div className="text-center">
						<button
							type="button"
							className="btn btn-sm btn-link m-1"
							onClick={() => setUnHideTable(true)}>
							View as a table
						</button>
					</div>
					<PieChart
						width={520}
						height={235}
						className="mx-auto"
						onMouseEnter={this?.onPieEnter}>
						{/* <Legend /> */}

						<Pie
							data={newUtilization}
							isAnimationActive={true}
							cx="50%"
							cy="50%"
							innerRadius={40}
							outerRadius={80}
							fill="#8884d8"
							paddingAngle={4}
							dataKey="NetAmount"
							label={pieCustomLabel}>
							{newUtilization.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={colors[index % colors.length]}
								/>
							))}
						</Pie>
						<Tooltip />
					</PieChart>
				</div>
			)}
		</>
	);
}
