import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class VoyageService {
  static get = async (voyagePlanningId) => {
    let response = await apiCall(`/api/Voyage/get/${voyagePlanningId}`, "GET");
    return response;
  };
  static postVoyage = async (payload) => {
    return await apiCall(
      `/api/Voyage/createvoyageplan`,
      "POST",
      payload,
      null,
      null,
      false,
      true
    );
  };

  static getVoyageList = async (
    page,
    pageSize,
    dateFrom,
    dateTo,
    bookingRef,
    vesselId,
    status
  ) => {
    page = page || 1;
    pageSize = pageSize || 50;
    dateFrom = dateFrom || "";
    dateTo = dateTo || "";
    bookingRef = bookingRef || "";
    vesselId = vesselId || "";
    status = status || "";
    let response = await apiCall(
      `/api/Voyage/search?page=${page}&pageSize=${pageSize}&dateFrom=${dateFrom}&dateTo=${dateTo}&bookingRef=${bookingRef}&vesselId=${vesselId}&status=${status}`,
      "GET"
    );
    return response;
  };
  static allActiveVessels = async () => {
    let response = await apiCall(
      `/api/VesselPosition/GetActiveVesselPositions`,
      "GET"
    );
    return response?.Data;
  };

  static updateBulkCargo = async (voyagePlanningId, payload) => {
    return await apiCall(
      `/api/Voyage/Update/BulkCargo/${voyagePlanningId}`,
      "POST",
      payload
    );
  };

  static updateDeckCargo = async (voyagePlanningId, payload) => {
    return await apiCall(
      `/api/Voyage/Update/DeckCargo/${voyagePlanningId}`,
      "POST",
      payload
    );
  };

  static removeBulkCargo = async (bulkCargoDetailId) => {
    return await apiCall(
      `/api/Voyage/Remove/BulkCargo/${bulkCargoDetailId}`,
      "DELETE"
    );
  };

  static removeDeckCargo = async (deckCargoDetailId) => {
    return await apiCall(
      `/api/Voyage/Remove/DeckCargo/${deckCargoDetailId}`,
      "DELETE"
    );
  };

  static uploadBulkCargoEvidence = async (payload) => {
    return await apiCall(
      `/api/Voyage/bulkCargo/upload-evidence`,
      "POST",
      payload
    );
  };

  static uploadDeckCargoEvidence = async (payload) => {
    return await apiCall(
      `/api/Voyage/deckCargo/upload-evidence`,
      "POST",
      payload
    );
  };

  static allocateTank = async (payload) => {
    return await apiCall(`api/Voyage/allocate-tank`, "POST", payload);
  };

  static updateVoyageStatus = async (voyagePlanningId, voyageStatusCode) => {
    return await apiCall(
      `/api/Voyage/Update/VoyagePlanningStatus/${voyagePlanningId}/${voyageStatusCode}`,
      "POST"
    );
  };

  static search = async (page, pageSize, query = "") => {
    page = page || 1;
    pageSize = pageSize || 50;
    let response = await apiCall(
      `/api/Voyage/search?pageCount=${page}&noPerPage=${pageSize}&${query}`
    );
    return response?.Data;
  };

  static getAllVoyageLocations = async (voyagePlanningId) => {
    let response = await apiCall(
      `/api/Voyage/location-costs?voyagePlanningId=${voyagePlanningId}`,
      "GET"
    );
    return response;
  };

  static allocateLocationCost = async (voyagePlanningId, payload) => {
    return await apiCall(
      `/api/Voyage/add-location-costs/${voyagePlanningId}`,
      "POST",
      payload
    );
  };

  static finalizeVoyage = async (voyagePlanningId) => {
    return await apiCall(
      `/api/Voyage/FinalizeVoyage/${voyagePlanningId}`,
      "PUT"
    );
  };

  static allocateVesselCost = async (
    voyagePlanningId,
    currencyConfigurationId,
    amount
  ) => {
    return await apiCall(
      `/api/Voyage/update-vessel-cost/${voyagePlanningId}?currencyConfigurationId=${currencyConfigurationId}&amount=${amount}`,
      "POST"
    );
  };

  static lockVoyage = async (voyagePlanningId) => {
    return await apiCall(
      `/api/Voyage/Update/LockVoyagePlan/${voyagePlanningId}`,
      "POST"
    );
  };

  static unlockVoyage = async (voyagePlanningId) => {
    return await apiCall(
      `/api/Voyage/Update/UnlockVoyagePlan/${voyagePlanningId}`,
      "POST"
    );
  };

  static getVoyageDashboardProperties = async (
    pageCount,
    noPerPage,
    vesselSpecificationId,
    timeFrom,
    timeTo,
    voyageId,
    voyageRef,
    poolId
  ) => {
    pageCount = pageCount || 1;
    noPerPage = noPerPage || 20;
    timeFrom = timeFrom || "";
    timeTo = timeTo || "";
    voyageId = voyageId || "";
    vesselSpecificationId = vesselSpecificationId || "";
    voyageRef = voyageRef || "";
    poolId = poolId || "";
    let response = await apiCall(
      `/api/Vessel/Get/voyage-dashboard?pageCount=${pageCount}&noPerPage=${noPerPage}&timeFrom=${timeFrom}&timeTo=${timeTo}&vesselSpecificationId=${vesselSpecificationId}&voyageId=${voyageId}&voyageRef=${voyageRef}&poolId=${poolId}`,
      "GET"
    );
    return response;
  };

  static getExtraVoyageCostsDashboardList = async (
    pageCount,
    noPerPage,
    vesselSpecificationId,
    timeFrom,
    timeTo,
    voyageId,
    voyageRef
  ) => {
    pageCount = pageCount || 1;
    noPerPage = noPerPage || 20;
    timeFrom = timeFrom || "";
    timeTo = timeTo || "";
    voyageId = voyageId || "";
    vesselSpecificationId = vesselSpecificationId || "";
    voyageRef = voyageRef || "";
    let response = await apiCall(
      `/api/Voyage/additional-voyage-cost-dashboard?pageCount=${pageCount}&noPerPage=${noPerPage}&timeFrom=${timeFrom}&timeTo=${timeTo}&vesselSpecificationId=${vesselSpecificationId}&voyageId=${voyageId}&voyageRef=${voyageRef}`,
      "GET"
    );
    return response;
  };

  static getVoyagesForFinalizationList = async (
    pageCount,
    noPerPage,
    vesselSpecificationId,
    timeFrom,
    timeTo,
    voyageId,
    voyageRef
  ) => {
    pageCount = pageCount || 1;
    noPerPage = noPerPage || 20;
    timeFrom = timeFrom || "";
    timeTo = timeTo || "";
    voyageId = voyageId || "";
    vesselSpecificationId = vesselSpecificationId || "";
    voyageRef = voyageRef || "";
    let response = await apiCall(
      `/api/Voyage/dashboard-finalized-voyages?pageCount=${pageCount}&noPerPage=${noPerPage}&timeFrom=${timeFrom}&timeTo=${timeTo}&vesselSpecificationId=${vesselSpecificationId}&voyageId=${voyageId}&voyageRef=${voyageRef}`,
      "GET"
    );
    return response;
  };
}
