import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class CargoHandlingService {
  static getLoadingCargos = async (voyagePlanningId, locationId) => {
    locationId = locationId || null;
    voyagePlanningId = voyagePlanningId || null;
    return await apiCall(
      `/api/Cargos/loading-cargos/by-location?voyagePlanningId=${voyagePlanningId}&locationId=${locationId}`,
      "GET"
    );
  };

  static getDischargingCargos = async (voyagePlanningId, locationId) => {
    locationId = locationId || null;
    voyagePlanningId = voyagePlanningId || null;
    return await apiCall(
      `/api/Cargos/discharging-cargos/by-location?voyagePlanningId=${voyagePlanningId}&locationId=${locationId}`,
      "GET"
    );
  };

  static getOffshoreCargos = async (voyagePlanningId, locationId) => {
    locationId = locationId || null;
    voyagePlanningId = voyagePlanningId || null;
    return await apiCall(
      `/api/Cargos/offshore-installation-cargos/by-location?voyagePlanningId=${voyagePlanningId}&locationId=${locationId}`,
      "GET"
    );
  };
}
