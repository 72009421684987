import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import { validationLatitudeLongitude } from "validation-latitude-longitude";
import VoyageActivityService from "../../services/voyageActivityService";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import { camelizeKeys, dynamicSort } from "../../utils/utilityFunctions";
import { forEach } from "lodash";

export default function WeatherReportingPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [payload, setPayload] = useState({});
  const [weatherDetails, setWeatherDetails] = useState([]);
  const [position, setPostion] = useState([]);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "view_weatherinfo";

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);

    setLoading(false);
  };

  const getWeatherInfo = async () => {
    const _payload = { ...payload };

    if (!_payload.lon) {
      toastr("error", "Enter longitude");
      return;
    }

    if (!_payload.lat) {
      toastr("error", "Enter latitude");
      return;
    }

    if (!validationLatitudeLongitude.latLong(_payload.lat, _payload.lon)) {
      toastr("error", "Invalid location coordinates");
      return;
    }

    _payload.part = null;

    setSaving(true);
    const response = await VoyageActivityService.getWeatherInfo(
      _payload.lon,
      _payload.lat,
      _payload.part
    );
    if (response) {
      setPostion(response?.Data);
      setWeatherDetails(response?.Data?.current);
    }
    setSaving(false);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to Voyage Activities</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  const dataItem = (label, value) => {
    if (value == "null") value = "";
    return (
      <li className="col-6 col-lg-3">
        <p>
          <span className="text-soft small">{label}</span> {value || "-"}
        </p>
      </li>
    );
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Weather on Route"
      loading={loading}
      pageActions={pageActions}
      showFullLoader={loading}
    >
      <div className="card card-bordered shadow-lg">
        <div className="card-inner p-5">
          <form>
            <div className="row">
              <div className="col-12">
                <p>
                  <span className="preview-title-lg overline-title">
                    Weather Info Requirements
                  </span>{" "}
                </p>
              </div>
            </div>
            <div className="gap gap-10px" />
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label small" htmlFor="fw-last-name">
                    Longitude
                  </label>
                  <div className="form-control-wrap">
                    <ReactTooltip id="longitudeTip">
                      The Correct Longitude format should be <br />a maximum of
                      three whole number digits
                      <br />
                      (between -180 and 180) and not more than six (6)
                      <br />
                      decimal places, i.e -179.999999, 179.999999.
                    </ReactTooltip>
                    <input
                      type="number"
                      data-tip
                      data-for="longitudeTip"
                      className="form-control required"
                      required
                      value={payload.lon}
                      onChange={(e) => {
                        addPayload("lon", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label className="form-label small" htmlFor="fw-last-name">
                    Latitude
                  </label>
                  <div className="form-control-wrap">
                    <ReactTooltip id="latitudeTip">
                      The Correct Latitude format should be <br />a maximum of
                      two whole number digits
                      <br />
                      (between -90 and 90) and not more than six (6)
                      <br />
                      decimal places, i.e -89.999999, 89.999999.
                    </ReactTooltip>
                    <input
                      type="number"
                      data-tip
                      data-for="latitudeTip"
                      className="form-control required"
                      required
                      value={payload.lat}
                      onChange={(e) => {
                        addPayload("lat", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="gap gap-40px" />

          {saving ? (
            <Spinner />
          ) : (
            <div className="form-group">
              <button
                type="button"
                onClick={getWeatherInfo}
                className="btn btn-md btn-dark btn-wide px-5 mr-3"
              >
                View Weather Info
              </button>
            </div>
          )}

          <div className="gap gap-20px" />

          {weatherDetails.length == 0 ? null : (
            <div className="card sp-plan">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="sp-plan-info card-inner">
                    <div className="row gx-0 gy-3">
                      <div className="col-xl-3 col-sm-4"></div>
                    </div>
                  </div>
                  <div className="sp-plan-desc card-inner">
                    <p>
                      <span className="preview-title-lg overline-title">
                        Current Weather Information
                      </span>{" "}
                    </p>
                    <ul className="row gx-1">
                      {dataItem("Longitude", position.lon)}
                      {dataItem("Latitude", position.lat)}
                      {dataItem("Timezone", position.timezone)}
                      {dataItem("Sunrise", weatherDetails.sunrise)}
                      {dataItem("Sunset", weatherDetails.sunset)}
                      {dataItem("Temperature", weatherDetails.temp)}
                      {dataItem("Pressure", weatherDetails.pressure)}
                      {dataItem("Humidity", weatherDetails.humidity)}
                      {dataItem("Dewpoint", weatherDetails.dew_point)}
                      {dataItem("UVI", weatherDetails.uvi)}
                      {dataItem("Clouds", weatherDetails.clouds)}
                      {dataItem("Visibility", weatherDetails.visibility)}
                      {dataItem("Wind Speed", weatherDetails.wind_speed)}
                      {dataItem("Wind Deg.", weatherDetails.wind_deg)}
                    </ul>

                    <hr style={{ opacity: "0.25" }} />
                  </div>
                </div>
                <br />
              </div>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}
