import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import CertificateService from '../../services/certificateService';
import payloadUpdater from "../../utils/payloadUpdater";
import ComplianceService from '../../services/complianceService';
import VesselService from '../../services/vesselService';
import Select from "react-select";
import CountryService from '../../services/countriesService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function EditCompliance() {
    const [certificates, setCertificates] = useState([]);
    const [countryList, setCountry] = useState([{}]);
    const [certValidityPeriod, setCertValidtyPeriod] = useState([]);
    const [issuingAuthority, setIssuingAuthority] = useState([]);
    const [certificatePayload, setCertificatePayload] = useState({});
    const [vesselList, setVesselList] = useState([]);
    const [selectCertInterface, setCertInterface] = useState([]);
    const [savedVesselCertificates, setSavedCertificates] = useState([]);
    const [selectedVesselSpecId, setSelectedVesselSpecId] = useState("");
    const [certStatus, setCertStatus] = useState([]);
    const [entryId, setEntryId] = useState("");

    const [selectedCertificate, setSelectedCertificate] = useState();
    const [selectedIssuer, setSelectedIssuer] = useState();
    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedValidtyPeriod, setSelectedValidityPeriod] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [selectedExpiryDate, setSelectedExpiryDate] = useState(new Date());
    const [selectedIssueDate, setSelectedIssueDate] = useState(new Date());
    const [postEditCompliance, setPostEditCompliance] = useState({


    })


    const editCertificatePayload = new payloadUpdater({ payload: certificatePayload, setPayload: setCertificatePayload }).update;

    // console.log(certStatus);

    useEffect(() => {
        fetchData();

    }, []);


    const fetchData = async () => {

        await Promise.all(
            [
                await CertificateService.listAll(),
                await ComplianceService.fetchValidityPeriod(),
                await ComplianceService.certificateIssuer(),
                await VesselService.listAllVessels(),
                await CountryService.listCountry(),
                await ComplianceService.certificateValidityList(),
                await ComplianceService.certificateStatus(),

            ]
        ).then(response => {
            setCertificates(response[0])
            setCertValidtyPeriod(response[1]);
            setIssuingAuthority(response[2]);
            setVesselList(response[3]);
            setCountry(response[4]);
            setSavedCertificates(response[5]);
            const certStatusList = response[6].filter(stat => stat.ParentStatusId === "0e35c9ba-e5d1-41f7-a4b9-78c3a9ceef44");
            setCertStatus(certStatusList);
            console.log(savedVesselCertificates);
            console.log("status", certStatusList);
            console.log(certStatus);

        }).catch((err) => {
            console.log(err);
        });

    }


    let selectedVessel = null;

    const onVesselNameChange = (vessel) => {
        const certificate = savedVesselCertificates.find(cert => cert?.VesselSpecificationId === vessel.VesselSpecificationId);
        certificate["CertificateName"] = certificates.find(certName => certName?.CertificateNameId === certificate?.CertificateNameId).CertificateName;
        setSelectedCertificate(certificate);
        console.log(certificate);

        let complianceEntryId = certificate.EntryId;
        setEntryId(complianceEntryId);
        console.log(complianceEntryId);
        console.log()

        certificate["IssuerName"] = issuingAuthority.find(issueBody => issueBody.IssuerId === certificate.IssuerId).IssuerName;
        setSelectedIssuer(certificate);

        let countryExtract = countryList.find(country => country.CountryId === certificate.PlaceOfIssue);
        certificate["PlaceOfIssue"] = countryExtract.CountryId;
        setSelectedCountry({ CountryId: countryExtract.CountryId, CountryName: countryExtract.CountryName });


        let exactValidityPeriod = certValidityPeriod.find(certValidty => certValidty.ValidityPeriodId === certificate.ValidityPeriodId);
        certificate["ValidityPeriodId"] = exactValidityPeriod.ValidityPeriodId;
        setSelectedValidityPeriod({ ValidityPeriod: exactValidityPeriod.ValidityPeriod, ValidityPeriodId: exactValidityPeriod.ValidityPeriodId });


        let extractedStatus = certificate.StatusId !== undefined && certStatus?.find(certValidity => certValidity?.StatusId === certificate?.StatusId);
        certificate["Status"] = extractedStatus?.StatusId;
        setSelectedStatus({ StatusId: extractedStatus?.StatusId, Status: extractedStatus?.Status });

        // console.log("error" , extractedStatus);

        // let preExpiryDate = certificate.ExpiryDate;
        let d = new Date(certificate.ExpiryDate).toISOString();
        let b = d.slice(0, d.indexOf("T"));
        setSelectedExpiryDate(b);

        let dd = new Date(certificate.IssueDate).toISOString();
        let bb = dd.slice(0, dd.indexOf("T"));
        setSelectedIssueDate(bb);
        setCertificatePayload({

            vesselSpecificationId: vessel.VesselSpecificationId,
            issuerId: certificate.IssuerId,
            placeOfIssue: countryExtract.CountryId,
            certificateNameId: certificate.CertificateNameId,
            issueDate: certificate.IssueDate,
            expiryDate: certificate.ExpiryDate,
            statusId: extractedStatus?.StatusId,
            validityPeriodId: exactValidityPeriod.ValidityPeriodId,
            entryId: complianceEntryId,

        })
    }

    const onCertificateChange = (certificate) => {
        // const certificate = savedVesselCertificates.find(cert => cert?.VesselSpecificationId === vessel.VesselSpecificationId);
        certificate["CertificateName"] = certificates.find(certName => certName.CertificateNameId === certificate.CertificateNameId).CertificateName;
        setSelectedCertificate(certificate);
        editCertificatePayload("certificateNameId", certificate.CertificateNameId);
    }

    const onIssuerChange = (certificate) => {
        certificate["IssuerId"] = issuingAuthority.find(issueBody => issueBody.IssuerId === certificate.IssuerId).IssuerId;
        // console.log(certificate)
        setSelectedIssuer(certificate);
        editCertificatePayload("issuerId", certificate.IssuerId);

    }
    const onPlaceChange = (option) => {
        let exactCountry = countryList.find(country => country.CountryId === option.value)
        // certificate["PlaceOfIssue"] = exactCountry.CountryId;
        setSelectedCountry({ CountryId: exactCountry.CountryId, CountryName: exactCountry.CountryName });
        editCertificatePayload("placeOfIssue", exactCountry.CountryId);
    }

    const validityChange = (option) => {
        let exactValidityPeriod = certValidityPeriod.find(certValidty => certValidty.ValidityPeriodId === option.value)
        // certificate["ValidtyPeriodId"] = ;
        setSelectedValidityPeriod({ ValidityPeriod: exactValidityPeriod.ValidityPeriod, ValidityPeriodId: exactValidityPeriod.ValidityPeriodId });
        editCertificatePayload("validtyPeriodId", exactValidityPeriod.ValidityPeriodId);
    }

    const onStatusChange = (stat) => {
        setSelectedStatus(stat)
        console.log("user selected:", stat.Status);
        editCertificatePayload("statusId", stat?.StatusId);
    }
    const onIssueDateChange = (e) => {
        let dateObject = e.target.value
        let convertedIssueDate = new Date(dateObject).toISOString();
        let newDateObject = convertedIssueDate.slice(0, convertedIssueDate.indexOf("T"));
        setSelectedIssueDate(newDateObject);
        editCertificatePayload("issueDate", e.target.value);
    }

    const onExpiryDateChange = (e) => {
        let dateObject2 = e.target.value
        let convertedIssueDate2 = new Date(dateObject2).toISOString();
        let newDateObject2 = convertedIssueDate2.slice(0, convertedIssueDate2.indexOf("T"));
        setSelectedExpiryDate(newDateObject2);
        editCertificatePayload("expiryDate", e.target.value);
    }

    const submit = async (e) => {
        e.preventDefault()

        const payload = {
            ...certificatePayload
        }
        console.log(payload);
        try {
            const saveEditedCompliance = await ComplianceService?.postEditVessel(entryId, certificatePayload);
        }
        catch (e) {
            console.error(e)
        }
    }


    return (
        <MainLayout title="Edit Compliance">
            <div className="card card-bordered shadow-lg">
                <div className="card-inner p-5">
                    <form onSubmit={submit}>
                        <div className="row align-items-center">
                            <div className="row gy-4 p-3">
                                <div className="col-sm-6">
                                    <div className="form-group ">
                                        <label className="form-label" htmlFor="fw-last-name">Vessel Name</label>
                                        <div className="form-control-wrap">
                                            <Select
                                                options={vesselList.map(a => ({ ...a, label: a.VesselName, value: a.VesselSpecificationId }))}
                                                value={vesselList.map(a => ({ ...a, label: a.VesselName, value: a.VesselSpecificationId })).find(a => a.value === certificatePayload.VesselSpecificationId)}
                                                onChange={e => onVesselNameChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="form-label">Certificate </label>
                                        <div className="form-control-wrap">
                                            <Select options={certificates.map(a => ({ ...a, label: a.CertificateName, value: a.CertificateNameId }))}
                                                value={{ label: selectedCertificate?.CertificateName, value: selectedCertificate?.CertificateNameId }}
                                                onChange={(e) => onCertificateChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group ">
                                        <label className="form-label">Issuing Authrority</label>
                                        <div className="form-control-wrap">
                                            <Select options={issuingAuthority.map(e => ({ ...e, label: e.IssuerName, value: e.IssuerId }))}
                                                value={{ label: selectedIssuer?.IssuerName, value: selectedIssuer?.IssuerId }}
                                                onChange={(option) => onIssuerChange(option)}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group ">
                                        <label className="form-label" htmlFor="fw-last-name">Place of Issue </label>
                                        <div className="form-control-wrap">
                                            <Select options={countryList.map(e => ({ ...e, label: e.CountryName, value: e.CountryId }))}
                                                value={{ label: selectedCountry?.CountryName, value: selectedCountry?.CountryId }}
                                                // value={{ label: selectedCountry?.CountryName, value: selectedCountry?.CountryId }}
                                                onChange={(option) => onPlaceChange(option)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fw-last-name">Validity period</label>
                                        <Select
                                            options={certValidityPeriod.map(e => ({ ...e, label: e.ValidityPeriod, value: e.ValidityPeriodId }))}
                                            value={{ label: selectedValidtyPeriod?.ValidityPeriod, value: selectedValidtyPeriod?.ValidityPeriodId }}
                                            // value={certValidityPeriod.map(e => ({ ...e, label: e.ValidityPeriod, value: e.ValidityPeriodId })).find(a => a.value === certificatePayload.validtyPeriodId)}
                                            onChange={(option) => validityChange(option)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fw-last-name">Status</label>
                                        <Select
                                            options={certStatus.map(e => ({ ...e, label: e?.Status, value: e?.StatusId }))}
                                            value={{ label: selectedStatus?.Status, value: selectedStatus?.StatusId }}
                                            onChange={(stat) => onStatusChange(stat)}
                                        />

                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fw-last-name">Commencement date</label>
                                        <div className="form-control-wrap">
                                            <input type="date" className="form-control required"
                                                value={selectedIssueDate}
                                                onChange={(e) => onIssueDateChange(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="fw-last-name">Expiry date</label>
                                        <div className="form-control-wrap">
                                            <input type="date" className="form-control required"
                                                value={selectedExpiryDate}
                                                onChange={(e) => onExpiryDateChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nk-tb-col"><span>&nbsp;</span>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-sm btn-primary">Save</button>
                            <button type="button" className="btn btn-sm btn-danger m-1" onclick="history.back()">Cancel</button>
                        </div>
                    </form>
                    <hr className="preview-hr" />
                </div>
            </div>
        </MainLayout>
    );
}