import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import StartEndVoyageActivityListComponent from "./startEndVoyageActivityList";
import VoyageActivityService from "../../services/voyageActivityService";
import VoyageService from "../../services/voyageService";
import BulkListComponent from "../voyage/bulkList";
import DeckListComponent from "../voyage/deckList";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import { camelizeKeys, dynamicSort } from "../../utils/utilityFunctions";
import { forEach } from "lodash";

import ReactModal from "react-modal";
import { defaultModalStyle } from "../../utils/customStyles";
import ActivityService from "../../services/activityService";
import CargoHandlingService from "../../services/cargoHandlingService";

export default function UpdateCargoLogsComponent({
  callback,
  cargo,
  activityName,
  voyageActivityId,
  selectedBulkCargoDetailId,
  selectedDeckCargoDetailId,
}) {
  const [showModal, setShowModal] = useState(false);
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [voyageActivity, setVoyageActivity] = useState([]);
  const [bookingRequests, setBookingRequests] = useState([]);
  const [bulkCargos, setBulkCargos] = useState([]);
  const [deckCargos, setDeckCargos] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [payload, setPayload] = useState({
    numberOfLifts: cargo?.ActivityEndLog?.NumberOfLifts,
    weightOfLoadedItem: cargo?.ActivityEndLog?.WeightOfLoadedItem,
    measurementUnitId: cargo?.ActivityEndLog?.MeasurementUnitId,
    rob: cargo?.ActivityEndLog?.Rob,
    robUnitId: cargo?.ActivityEndLog?.RobUnitId,
  });
  const [postUpdateDetails, setPostUpdateDetails] = useState({
    voyageActivityId: "",
    deckCargoLogs: [{}] || null,
    bulkCargoLogs: [{}] || null,
  });

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "endactivity";

  useEffect(async () => {
    await retrieveMeasurentUnits();
  }, []);

  const retrieveMeasurentUnits = async () => {
    setLoading(true);
    const _measurementUnits = await EquipmentService.listMeasurementUnits();

    let _filterMeasurementUnits = _measurementUnits?.filter(
      (measurementUnit) =>
        measurementUnit.UnitType == "mass" ||
        measurementUnit.UnitType == "volume"
    );
    setMeasurementUnits(_filterMeasurementUnits || []);

    setLoading(false);
  };

  postUpdateDetails.voyageActivityId = voyageActivityId;
  postUpdateDetails.deckCargoLogs[0].voyageActivityId = voyageActivityId;
  postUpdateDetails.deckCargoLogs[0].deckCargoDetailId =
    selectedDeckCargoDetailId;
  postUpdateDetails.deckCargoLogs[0].measurementUnitId =
    payload.measurementUnitId || "00000000-0000-0000-0000-000000000000";
  postUpdateDetails.deckCargoLogs[0].numberOfLifts = payload.numberOfLifts || 0;
  postUpdateDetails.deckCargoLogs[0].weightOfLoadedItem =
    payload.weightOfLoadedItem || 0.0;
  postUpdateDetails.bulkCargoLogs[0].voyageActivityId = voyageActivityId;
  postUpdateDetails.bulkCargoLogs[0].bulkCargoDetailId =
    selectedBulkCargoDetailId;
  postUpdateDetails.bulkCargoLogs[0].robUnitId =
    payload.robUnitId || "00000000-0000-0000-0000-000000000000";
  postUpdateDetails.bulkCargoLogs[0].rob = payload.rob || 0.0;

  const updateCargoLogValues = async () => {
    const _payload = { ...postUpdateDetails };

    if (
      !_payload.bulkCargoLogs[0].bulkCargoDetailId &&
      !_payload.deckCargoLogs[0].deckCargoDetailId
    ) {
      toastr("error", "Select cargo(s)");
      return;
    }

    if (
      !_payload.deckCargoLogs[0].weightOfLoadedItem &&
      !_payload.deckCargoLogs[0].numberOfLifts &&
      !_payload.bulkCargoLogs[0].rob
    ) {
      toastr("error", "Input bulk/deck type values as necessary");
      return;
    }

    if (
      !_payload.deckCargoLogs[0].measurementUnitId &&
      !_payload.bulkCargoLogs[0].robUnitId
    ) {
      toastr("error", "Select bulk/deck type unit as necessary");
      return;
    }

    if (_payload.deckCargoLogs[0].deckCargoDetailId == null) {
      delete _payload.deckCargoLogs;
    }

    if (_payload.bulkCargoLogs[0].bulkCargoDetailId == null) {
      delete _payload.bulkCargoLogs;
    }

    // if (!_payload.numberOfLifts) {
    //   toastr("error", "Input number of lifts");
    //   return;
    // }

    // if (!_payload.rob) {
    //   toastr("error", "Input remainder on board");
    //   return;
    // }

    // if (!_payload.robUnitId) {
    //   toastr("error", "Select ROB unit");
    //   return;
    // }

    setSaving(true);
    const response = await ActivityService.updateCargoLogs(_payload);
    if (response) {
      setPayload({});
      toastr("success", "Cargo logs have been updated");
      callback && callback();
    }
    setSaving(false);
  };

  return (
    <>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="card-inner p-2">
            <form>
              {activityName !== "Port Loading Deck cargo" &&
              activityName !== "Port Discharging Deck cargo" &&
              activityName !== "Offshore Handling Deck cargo" ? null : (
                <>
                  <div className="row">
                    <div className="col-12">
                      <p>
                        <span className="preview-title-lg overline-title">
                          Cargo Weight &amp; Number of Lifts
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label small"
                          htmlFor="fw-last-name"
                        >
                          Number of Lifts
                        </label>
                        <div className="form-control-wrap">
                          <input
                            defaultValue={cargo?.ActivityEndLog?.NumberOfLifts}
                            type="number"
                            className="form-control required"
                            onChange={(e) => {
                              addPayload("numberOfLifts", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gap gap-10px" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label small"
                          htmlFor="fw-last-name"
                        >
                          Weight of Cargo
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            defaultValue={
                              cargo?.ActivityEndLog?.WeightOfLoadedItem
                            }
                            className="form-control required"
                            onChange={(e) => {
                              addPayload("weightOfLoadedItem", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gap gap-10px" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label small"
                          htmlFor="fw-last-name"
                        >
                          Cargo Weight Unit
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(e) => {
                              addPayload("measurementUnitId", e.value);
                            }}
                            defaultValue={measurementUnits
                              ?.map((c) => ({
                                ...c,
                                label:
                                  c.MeasurementUnit +
                                  " (" +
                                  c.MeasurementUnitSymbol +
                                  ")",
                                value: c.MeasurementUnitId,
                              }))
                              .find(
                                (a) =>
                                  a.value ==
                                  cargo?.ActivityEndLog?.MeasurementUnitId
                              )}
                            options={measurementUnits?.map((c) => ({
                              ...c,
                              label:
                                c.MeasurementUnit +
                                " (" +
                                c.MeasurementUnitSymbol +
                                ")",
                              value: c.MeasurementUnitId,
                            }))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activityName !== "Port Loading Bulk cargo" &&
              activityName !== "Port Discharging Bulk cargo" &&
              activityName !== "Offshore Handling Bulk cargo" ? null : (
                <>
                  <div className="row">
                    <div className="col-12">
                      <p>
                        <span className="preview-title-lg overline-title">
                          Remainder On board
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label small"
                          htmlFor="fw-last-name"
                        >
                          Remainder On board
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="number"
                            defaultValue={cargo?.ActivityEndLog?.Rob}
                            className="form-control required"
                            onChange={(e) => {
                              addPayload("rob", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gap gap-10px" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label small"
                          htmlFor="fw-last-name"
                        >
                          ROB Unit
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(e) => {
                              addPayload("robUnitId", e.value);
                            }}
                            defaultValue={measurementUnits
                              ?.map((c) => ({
                                ...c,
                                label:
                                  c.MeasurementUnit +
                                  " (" +
                                  c.MeasurementUnitSymbol +
                                  ")",
                                value: c.MeasurementUnitId,
                              }))
                              .find(
                                (a) =>
                                  a.value == cargo?.ActivityEndLog?.RobUnitId
                              )}
                            options={measurementUnits?.map((c) => ({
                              ...c,
                              label:
                                c.MeasurementUnit +
                                " (" +
                                c.MeasurementUnitSymbol +
                                ")",
                              value: c.MeasurementUnitId,
                            }))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
            <div className="gap gap-40px" />
            {saving ? (
              <div className="form-group p-2 m-3">
                <Spinner size="1.5" />
              </div>
            ) : (
              <div className="form-group">
                <button
                  type="button"
                  onClick={() => updateCargoLogValues()}
                  className="btn btn-md btn-dark btn-wide px-5 mr-3"
                >
                  Save
                </button>
              </div>
            )}
            <div className="gap gap-10px" />
          </div>
        </>
      )}
      {/* {!modalIsClosed && <CustomModal title="Edit Booking Request" isClosed={modalIsClosed}
                content={<EditVesselDetailsComponent bookingRequestId={bookingRequestId} callback={() => onModalClose()} />} onClose={() => onModalClose()} />} */}
    </>
  );
}
