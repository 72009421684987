import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import {
  currencyFormat,
  generateQueryString,
  toReadableDate,
} from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";
import { Link } from "react-router-dom";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from "recharts";
import DateFilter from "../general/DateFilter";
import VesselService from "../../services/vesselService";
import EmptyRecords from "../general/EmptyRecords";

export default function VesselHireHistory({
  dataList,
  reload,
  deleteAction,
  vesselSpecId = "",
}) {
  const [tableData, setTableData] = useState(dataList || []);
  const [loading, setLoading] = useState(true);

  const headerList = [
    {
      Header: "Departure Port",
      accessor: "DeparturePortName",
    },
    {
      Header: "Departure Date",
      accessor: "ExpectedDateOfDeparture",
    },
    {
      Header: "Arrival Port",
      accessor: "ArrivalPortName",
    },
    {
      Header: "Arrival Date",
      accessor: "ExpectedDateOfArrival",
    },

    {
      Header: "Hire Cost",
      accessor: "HireCost",
    },

    {
      Header: "",
      accessor: "route",
    },
  ];

  // methods
  const getHireData = async (dates) => {
    setLoading(true);
    let payload = { vesselSpecId: vesselSpecId };
    if (dates) payload = { ...payload, ...dates };
    payload = generateQueryString(payload);
    const _res = await VesselService.getHireHistoryData(payload);
    if (!_res) {
      setLoading(false);
      return;
    }
    const data = _res?.map((voyage) => ({
      ...voyage,
      ExpectedDateOfDeparture: toReadableDate(voyage.ExpectedDateOfDeparture),
      ExpectedDateOfArrival: toReadableDate(voyage.ExpectedDateOfArrival),
      HireCost: currencyFormat(voyage.HireCost ? voyage.HireCost : 0),
      Costs: voyage.HireCost ? voyage.HireCost : 0,
      route: <Link to={`/voyage/${voyage.VoyagePlanningId}`}>View</Link>,
    }));

    setTableData(data || []);
    setLoading(false);
  };

  useEffect(async () => {
    setLoading(true);
    await getHireData();

    setLoading(false);
  }, [dataList]);

  return (
    <div style={{ zoom: "0.85" }}>
      <div className="d-flex justify-between mb-2">
        <h4>Vessel Hire History</h4>

        <DateFilter callback={getHireData} />
      </div>
      {loading ? (
        <div className="center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <div>
          {/* bar-chart */}
          {!!tableData.length ? (
            <div className="d-flex justify-between">
              <div
                className={`${!!tableData.length ? "w-40" : "w-100"}  max-h-75`}
              >
                <MyTable
                  columns={headerList}
                  cssArray={["font-weight-500", "", "", ""]}
                  data={tableData}
                  reloadData={(page, pageSize, query) =>
                    reload && reload(page, pageSize, query)
                  }
                  enableSearch={false}
                  deleteAction={deleteAction}
                  emptyMessage="No Voyage For This Vessel"
                  paginationStyle="2"
                />
              </div>
              <div className={!!tableData.length ? "w-50" : "d-none"}>
                {!!tableData.length && (
                  <ResponsiveContainer
                    width="100%"
                    height="100%"
                    minHeight={500}
                    maxHeight={650}
                  >
                    <BarChart
                      width={500}
                      height={500}
                      maxBarSize={80}
                      data={tableData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      barGap={0}
                      barCategoryGap={0}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="ArrivalPortName" />
                      <YAxis dataKey="Costs" />
                      {/* <Tooltip active={true} /> */}
                      <Legend />
                      <Bar
                        dataKey="Costs"
                        fill="#8884d8"
                        key="ArrivalPortName"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>
          ) : (
            <EmptyRecords message="No data for time frame" />
          )}
        </div>
      )}
    </div>
  );
}
