import React, { useEffect, useState, useCallback } from "react";
import "../../styles/dataTable.scss";
import { Link, Navigate, useParams } from "react-router-dom";
import moment from "moment";
import ms from "ms";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import { toReadableDate } from "../../utils/utilityFunctions";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import { checkUserPermission, formatValue } from "../../utils/utilityFunctions";
import LocationService from "../../services/locationService";

export default function OffshoreInstallationDashboard() {
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [payload, setPayload] = useState({});
	const [pages, setPages] = useState([10, 20, 50, 100, 150]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [totalRecords, setTotalRecords] = useState(10);
	const [allocationCostList, setAllocationCostList] = useState([]);
	const [exportData, setExportData] = useState([]);
	const [dateTo, setDateTo] = useState("");
	const [locationId, setLocationId] = useState("");
	const [maxMonthHeader, setMaxMonthHeader] = useState("");
	const [secondMaxMonthHeader, setSecondMaxMonthHeader] = useState("");
	const [thirdMaxMonthHeader, setThirdMaxMonthHeader] = useState("");
	const [dateFrom, setDateFrom] = useState("");
	const [allocationCost, setAllocationCost] = useState([]);
	const [maxDate, setMaxDate] = useState(null);
	const [minDate, setMinDate] = useState(null);
	const [installationList, setOffshoreInstallationList] = useState([]);

	const monthArray = [
		{ label: "January", value: 1 },
		{ label: "February", value: 2 },
		{ label: "March", value: 3 },
		{ label: "April", value: 4 },
		{ label: "May", value: 5 },
		{ label: "June", value: 6 },
		{ label: "July", value: 7 },
		{ label: "August", value: 8 },
		{ label: "September", value: 9 },
		{ label: "October", value: 10 },
		{ label: "November", value: 11 },
		{ label: "December", value: 12 },
	];
	useEffect(async () => {
		await init();
	}, []);

	//this it is to retrive date and avoid re-renders.
	useCallback(() => {
		const minToSeconds = ms("60d");
		const minimumDate = new Date(+new Date(dateFrom));
		const maximumDate = new Date(+new Date(dateFrom) + minToSeconds);
		setMinDate(moment(minimumDate).format("YYYY-MM-DD"));
		setMaxDate(moment(maximumDate).format("YYYY-MM-DD"));
	}, [dateFrom]);

	const init = async () => {
		setLoading(true);
		await loadData();
		const locationsTypes = await LocationService.list();
		const filteredLocation = locationsTypes?.filter(
			(list) =>
				list?.LocationTypeName?.toLowerCase() === "offshore installation"
		);
		// && 		(list?.LocationTypeName?.toLowerCase()=== "port terminal"))
		setOffshoreInstallationList(filteredLocation);
		setLoading(false);
	};
	
	const loadData = async (
		_page,
		_pageSize,
		_dateFrom = "",
		_dateTo = "",
		_locationId = ""
	) => {
		setLoading(true);
		_page = _page || page;
		_pageSize = _pageSize || pageSize;
		setDateFrom(_dateFrom);
		setDateTo(_dateTo);
		setLocationId(_locationId);

		const allocationList = await LocationService.dashboardAllocation(
			_page,
			_pageSize,
			_dateFrom,
			_dateTo,
			_locationId
		);
		if (allocationList?.TotalCount) {
			setTotalRecords(allocationList.TotalCount);
		}
		if (!allocationList){
			toastr(
				"error",
				"there are no installations within the selected dates"
			);
			
			return    setLoading(false);
		}
		const allocationMonths = allocationList?.Data?.map((list) => list.Month);
		let maxMonth = Math.max.apply(null, allocationMonths);
		allocationMonths.splice(allocationMonths.indexOf(maxMonth), 1);
		let secondMax = maxMonth - 1;
		let thirdMax = secondMax - 1;
		let maxMonthHeader = monthArray?.find(
			(mon) => mon.value === maxMonth
		)?.label;
		setMaxMonthHeader(maxMonthHeader);
		let secondMonthHeader = monthArray?.find(
			(mon) => mon.value === secondMax
		)?.label;
		setSecondMaxMonthHeader(secondMonthHeader);
		let thirdMaxHeader = monthArray?.find(
			(mon) => mon.value === thirdMax
		)?.label;
		setThirdMaxMonthHeader(thirdMaxHeader);

		let modifiedDataList = allocationList?.Data || [];

		modifiedDataList = modifiedDataList?.map((list) => ({
			...list,
			LocationName: (
				<div>
					<p>{list.LocationName}</p>
				</div>
			),
			ThirdMax: (
				<div>
					<p>
						{(
							modifiedDataList?.find(
								(item) =>
									list?.Month === thirdMax &&
									item.LocationId === list.LocationId
							)?.TotalCostAllocated
						) || "0"}{" "}
						/{" "}
						{modifiedDataList?.find(
							(item) =>
								list?.Month === thirdMax && item.LocationId === list.LocationId
						)?.NumberOfVisits || "0"}
					</p>
				</div>
			),
			SecondMax: (
				<div>
					<p>
						{(
							modifiedDataList?.find(
								(item) =>
									list?.Month === secondMax &&
									item.LocationId === list.LocationId
							)?.TotalCostAllocated
						) || "0"}{" "}
						/
						{modifiedDataList?.find(
							(item) =>
								list?.Month === secondMax && item.LocationId === list.LocationId
						)?.NumberOfVisits || "0"}
					</p>
				</div>
			),
			MaxMonth: (
				<div>
					<p>
						{(
							modifiedDataList?.find(
								(item) =>
									list?.Month === maxMonth &&
									item.LocationId === list.LocationId
							)?.TotalCostAllocated
						) || "0"}
						/
						{modifiedDataList?.find(
							(item) =>
								list?.Month === maxMonth && item.LocationId === list.LocationId
						)?.NumberOfVisits || "0"}
					</p>
				</div>
			),
		}));

		setAllocationCostList(modifiedDataList);

		page != _page && setPage(_page);
		pageSize != _pageSize && setPageSize(_pageSize);
		setLoading(false);
	};

	const headerList = [
		{
			Header: "OffShore Installations",
			accessor: "LocationName",
		},
		{
			Header: thirdMaxMonthHeader,
			id: "thirdMonthId",
			accessor: "ThirdMax",
		},
		{
			Header: secondMaxMonthHeader,
			id: "secondMonthId",
			accessor: "SecondMax",
		},
		{
			Header: maxMonthHeader,
			id: "maxMonthId",
			accessor: "MaxMonth",
		},
	];

	const filterallocationCost = async () => {
		await loadData(1, null, dateFrom, dateTo, locationId);
	};

	const clearFilter = async () => {
		setDateFrom("");
		setDateTo("");
		setLocationId("");
		setAllocationCost([]);
		await loadData(1, null);
	};

	const pageLimiter = Math.ceil(totalRecords / pageSize);

	const prevLoaded = async () => {
		if (pageLimiter > 1 && page < pageLimiter) {
			await loadData(page - 1, null, dateFrom, dateTo, locationId);
		}
		else if(pageLimiter == page){
			await loadData(page - 1, null, dateFrom, dateTo, locationId);
		}
	};

	const nextLoaded = async () => {
		if (pageLimiter !== 1 && page < pageLimiter) {
			await loadData(page + 1, null,  dateFrom, dateTo, locationId);
		}
	};

	const goToPage = async (page) => {
		await loadData(page, null,  dateFrom, dateTo, locationId);
	};

	return (
		<>
		{loading ? (
		  <div className="w-100 text-center center my-5 py-5">
			<Spinner />
		  </div>
		) : (
		  <>
			<div className="card-inner-group">
			  <h5 className="text-center pt-3 pb-0 pl-1">Offshore Installation (Cost / Visits)</h5>
			  {allocationCostList.length < 0 ? null : (
				// The filter and pagination component
  
				<div className="card-inner position-relative card-tools-toggle">
				  {
					<div className="card-title-group">
					  <div className="card-tools ml-n1">
						<ul className="btn-toolbar gx-1">
						  <li>
							<div className="dropdown">
							  <a
								href="#"
								className="btn btn-trigger btn-icon dropdown-toggle "
								data-toggle="dropdown"
							  >
								<em className="icon ni ni-filter-alt" />
							  </a>
							  <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
								<form style={{zoom: "85%"}}>
								  <div className="dropdown-head">
									<span className="sub-title dropdown-title">
									  Filter List
									</span>
									<div className="dropdown">
									  <a
										className="btn btn-sm btn-icon"
										data-toggle="dropdown"
									  >
										<em
										  className="icon ni ni-cross"
										  title="close"
										/>
									  </a>
									</div>
								  </div>
								  <div className="dropdown-body dropdown-body-rg">
									<div className="row gx-6 gy-3">
									<div className="col-12">
									<div
										className="form-group"
										onClick={(e) => e.stopPropagation()}>
										<label className="overline-title overline-title-alt">
											Location
										</label>
										<Select
											maxMenuHeight={160}
											className="border-transparent"
											placeholder=""
											value={installationList
												?.map((e) => ({
													...e,
													label: e.LocationName,
													value: e.LocationId,
												}))
												.find(
													(a) => a.value === locationId
												)}
											options={installationList.map(
												(e) => ({
													...e,
													label: e.LocationName,
													value: e.LocationId,
												})
											)}
											onChange={(option) =>
												setLocationId(option.value)
											}
										/>
									</div>
								</div>
  
								<div className="col-6">
									<div
										className="form-group"
										onClick={(e) => e.stopPropagation()}>
										<label
											className="overline-title overline-title-alt"
											htmlFor="from-date-filter">
											From
										</label>
										<input
											type="date"
											className="form-control "
											placeholder=""
											id="from-date-filter"
											value={dateFrom}
											onChange={(e) =>
												setDateFrom(e.target.value)
											}
										/>
									</div>
								</div>
  
							<div className="col-6">
							<div
								className="form-group"
								onClick={(e) => e.stopPropagation()}>
								<label
									className="overline-title overline-title-alt"
									htmlFor="to-date-filter">
									To
								</label>
								<input
									type="date"
									className="form-control "
									placeholder=""
									id="to-date-filter"
									value={dateTo}
									onChange={(e) =>
										setDateTo(e.target.value)
									}
									min={minDate}
									max={maxDate}
								/>
							</div>
						</div>
  
						<div className="gap gap-10px" />

						<div className="col-12">
						<div className="form-group">
							<button
							type="button"
							onClick={filterallocationCost}
							className="btn btn-secondary"
							>
							<span>Filter</span>
							</button>
							<a
							className="clickable ml-2"
							onClick={() => clearFilter()}
							>
							Reset Filter
							</a>
						</div>
						</div>
						<div className="gap gap-20px" />
					</div>
					</div>
				</form>
							  </div>
							</div>
						  </li>
						</ul>
					  </div>
					 {(pageLimiter > 1) && ( 
					 <div className="card-tools mr-n1">
						<ul className="btn-toolbar gx-1">
						  <li>
							<button
							  className="btn btn-trigger btn-icon "
							  onClick={page !== 1 ? prevLoaded : null}
							  disabled={page == 1 ? true : false}
							  title="Previous voyages"
							>
							  <em className="icon ni ni-arrow-left" />
							</button>
						  </li>
						  <li className="btn-toolbar-sep" />
						  <li>
							<button
							  className="btn btn-trigger btn-icon "
							  onClick={page >= 1 ? nextLoaded : null}
							  disabled={page >= pageLimiter ? true : false}
							  title="Next voyages"
							>
							  <em className="icon ni ni-arrow-right" />
							</button>
						  </li>
						  <li className="btn-toolbar-sep" />
						  <li>
							<div className="g" data-select2-id={6}>
							  <div
								className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3"
								data-select2-id={5}
							  >
								<div>
								  <input
									type="number"
									className="form-control border search-input text-align-center"
									placeholder=""
									onChange={(e) => goToPage(e.target.value)}
									value={page}
									style={{ width: "50px" }}
								  />
								</div>
								<div>of {`${pageLimiter}`}</div>
							  </div>
							</div>
						  </li>
						</ul>
					  </div>)}
					</div>
				  }
				</div>
			  )}
			  <>
				<div
				  className=""
				  style={{ width: "100%",}}
				>
				  <BookingTable
					columns={headerList}
					data={allocationCostList}
					cssArray={["font-weight-500", "", "", "text-left", "", ""]}
					enableSearch={false}
					emptyMessage="No voyage record to show"
					disablePageSize={true}
				  />
				</div>
			  </>
			</div>
		  </>
		)}
	  </>
	);
}
