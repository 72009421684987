import React, { useCallback, useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import payloadUpdater from "../../utils/payloadUpdater";
import { Validator, ValidateGroup } from "../validator";
import Select from "react-select";
import toastr from "../../utils/toastr";
import SailingService from "../../services/sailingService";
import Spinner from "../../utils/spinner";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "../../styles/table.scss";
import ReactModal from "react-modal";
import { defaultModalStyle } from "../../utils/customStyles";
import EquipmentService from "../../services/equipmentService";
import CountryService from "../../services/countriesService";
import { Link } from "react-router-dom";
import MyTable from "../dataTable";
import VisitList from "../../pages/sailingSchedule/listSailingVisits";
import {
  Overlay,
  Tooltip,
  Button,
  Row,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import {
  cycleColor,
  dateToYYYY_MM_DD,
  randomColor,
  randomNumber,
  toReadableDate,
} from "../../utils/utilityFunctions";
import { checkUserPermission } from "../../utils/utilityFunctions";

export default function SailingScheduleCalendarComponent() {
  const [schedules, setSchedules] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedSailingId, setSelectedSailingId] = useState("");
  const localizer = momentLocalizer(moment);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [countryList, setCountry] = useState([]);
  const [visits, setVisits] = useState([]);
  const [rerendering, setRerendering] = useState(false);
  const [hoveredScheduleVisits, sethoveredScheduleVisits] = useState([]);
  const [cachedScheduleVisits, setCachedScheduleVisits] = useState([]);
  const [loadinghoveredScheduleVisits, setLoadinghoveredScheduleVisits] =
    useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const [myEventsList, setMyEventsList] = useState(null);

  const [postVesselToFleetData, setPostVesselToFleetData] = useState({
    VesselSpecificationId: "",
    FleetId: "",
    IsActive: true,
  });
  const [finalSaveloading, setFinalSaveLoading] = useState(false);

  const rerenderCalendar = async () => {
    setRerendering(true);
    await retrieveSchedules();
    setRerendering(false);
  };

  useEffect(async () => {
    calendarNavigated(new Date());
  }, []);

  const retrieveMeasurementUnits = async () => {
    const units = await EquipmentService.listMeasurementUnits();
    if (units == null) setMeasurementUnits([]);
    else
      setMeasurementUnits(
        units.map((e) => ({
          ...e,
          label: e.MeasurementUnitSymbol,
          value: e.MeasurementUnitId,
          id: "MeasurementUnitId",
        }))
      );
  };
  const retrieveSchedules = async (_startDate, _endDate) => {
    if (!_startDate) {
      _startDate = dateToYYYY_MM_DD(new Date());
    }

    if (!_endDate) {
      const dateNow = new Date();
      const _currentMonth = dateNow.getMonth() + 1;
      const _currentYear = dateNow.getFullYear();
      _endDate = dateToYYYY_MM_DD(new Date(_currentYear, _currentMonth, 0));
    }

    const _schedules = await SailingService.getSchedule2(_startDate, _endDate);
    if (!_schedules || _schedules == null) {
      setSchedules([]);
    } else {
      setSchedules(_schedules);
      setMyEventsList(
        _schedules.map((schedule, index) => ({
          ...schedule,
          title: "Sailing " + schedule.SailingNo,
          start: schedule.DayOfSailing,
          end: schedule.DayOfReturnInPort,
          style: {
            backgroundColor: `${cycleColor(index)}`,
          },
        }))
      );
    }
  };

  const effectInput = (id, value) => {
    setSelectedSchedule({
      ...selectedSchedule,
      [id]: value,
    });
  };

  const handleSelect = (e) => {
    // console.log("selected");
    setSelectedSailingId(e.SailingId);
    setSelectedSchedule(e);
    setModalIsOpen(true);
  };

  const formatDate = (date) => {
    return date.split("T")[0];
  };
  const updateSailingSchedule = async () => {
    var hasFieldErrs = false;

    if (hasFieldErrs) return;
    setFinalSaveLoading(true);
    const vesselAdded = await SailingService.updateSailing(selectedSchedule);
    if (!vesselAdded) {
      toastr("error", "Sailing Schedule could not be updated");
      setFinalSaveLoading(false);
      return;
    } else {
      toastr("success", "Sailing Schedule successfully updated");
      await rerenderCalendar();
      await calendarNavigated(new Date());
      setFinalSaveLoading(false);
    }
    setModalIsOpen(false);
  };

  const firstDayOfMonth = (date) => {
    let _currentMonth = date.getMonth() + 1;
    const _currentYear = date.getFullYear();

    if (_currentMonth.toString().length < 2)
      _currentMonth = "0" + _currentMonth.toString();
    return `${_currentYear}-${_currentMonth}-01`;
  };

  const calendarNavigated = (a, b, c, d) => {
    const _currentMonth = a.getMonth() + 1;
    const _currentYear = a.getFullYear();

    // if ((_currentMonth != currentMonth) || (_currentYear != currentYear)) {
    //update events list
    setCurrentMonth(_currentMonth);
    setCurrentYear(_currentYear);

    let _lastDayOfMonth = dateToYYYY_MM_DD(
      new Date(_currentYear, _currentMonth, 0)
    );
    let _firstDayOfMonth = firstDayOfMonth(a);

    retrieveSchedules(_firstDayOfMonth, _lastDayOfMonth);
    // }
    // console.log(`navigated a: `, a);
    // console.log(`month: `, currentMonth);
    // console.log(`year: `, currentYear);
  };

  const gethoveredScheduleVisits = async (hoveredSchedule) => {
    setLoadinghoveredScheduleVisits(true);
    let visitList = [];
    // check if the user has already hovered on this visit before
    const cachedVisit = cachedScheduleVisits.find(
      (visit) => visit.SailingId === hoveredSchedule?.SailingId
    );
    if (cachedVisit) {
      visitList = cachedVisit.visitList;
    } else {
      visitList = await SailingService.getSailingLocationBySailing(
        hoveredSchedule?.SailingId
      );
      visitList = (visitList || []).map((e) => ({
        ...e,
        IsBulkRequirement: e.IsBulkRequirement ? "Yes" : "No",
        DayOnLocationFormatted: toReadableDate(e.DayOnLocation),
      }));

      setCachedScheduleVisits((prev) => [
        ...prev,
        { ...hoveredSchedule, visitList },
      ]);
    }
    sethoveredScheduleVisits([...visitList] || []);
    setLoadinghoveredScheduleVisits(false);
  };

  const calenderPopOver = (schedule) => {
    const popover = (
      <Popover id="calender-popover" show={true}>
        <Popover.Header as="h3">{schedule.event.title}</Popover.Header>
        <Popover.Body>
          {loadinghoveredScheduleVisits ? (
            <div className="text-center p-3">
              <Spinner />
            </div>
          ) : (
            <div
              style={{ maxHeight: "160px", overflowY: "auto" }}
              className="small-scrollbar pr-2"
            >
              <small className="d-block mt-2">
                Start: {moment(schedule.event.start).format("llll")}
              </small>
              <small className="d-block my-1">
                End: {moment(schedule.event.end).format("llll")}
              </small>

              <div>
                <h6 className="mt-2">Locations</h6>
                {hoveredScheduleVisits.map((visit, index) => (
                  <small className="d-block my-1" key={index}>
                    {visit.LocationName}: {visit.DayOnLocationFormatted}
                  </small>
                ))}
              </div>
            </div>
          )}
        </Popover.Body>
      </Popover>
    );

    const overlay = (
      <OverlayTrigger
        placement="top"
        overlay={popover}
        id="calender-overlay"
        // rootClose={true}
        show={showOverlay}
      >
        <div
          onMouseEnter={() => {
            gethoveredScheduleVisits(schedule.event);
            setShowOverlay(true);
          }}
          onMouseLeave={() => {
            // setShowOverlay(false);
          }}
          className="d-flex align-center"
        >
          <b>{schedule.event.title}</b>
          <span className="small ml-1">
            {schedule.event.SailingRoute && `(${schedule.event.SailingRoute})`}
          </span>
        </div>
      </OverlayTrigger>
    );

    return overlay;
  };

  const randomiseCalenderEntryColor = useCallback((event) => {
    return { style: event.style };
  }, []);

  const renderModal = () => {
    return (
      <ReactModal
        isOpen={modalIsOpen}
        contentLabel="Minimal Modal Example"
        style={defaultModalStyle}
      >
        {selectedSchedule && (
          <MainLayout
            preTitle={
              <span className="ml-2">
                <em
                  className="icon ni ni-arrow-left pointer"
                  onClick={() => setModalIsOpen(false)}
                />
                <span className="pointer" onClick={() => setModalIsOpen(false)}>
                  Return
                </span>
                <h4 className="ml-2">Edit Sailing Schedule</h4>
              </span>
            }
            loading={finalSaveloading}
            showFullLoader={finalSaveloading}
          >
            <div className="card  col">
              <form
                action="#"
                id="editEventForm"
                className="form-validate is-alter"
              >
                <div className="row gx-4 gy-3">
                  <div className="col-sm-12 col-12">
                    <div className="form-group">
                      <label className="form-label">Sailing Status</label>
                      <div className="form-control-wrap badge badge-info ml-2">
                        {selectedSchedule.SailingStatus}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label className="form-label">Day Of Sailing</label>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-left">
                          <em className="icon ni ni-calendar" />
                        </div>
                        <input
                          onChange={(e) => {
                            effectInput("DayOfSailing", e.target.value);
                          }}
                          value={
                            formatDate(selectedSchedule.DayOfSailing) || ""
                          }
                          type="date"
                          id="DayOfSailing"
                          className="form-control date-picker"
                          data-date-format="yyyy-mm-dd"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label className="form-label">
                        Day of Return In Port
                      </label>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-left">
                          <em className="icon ni ni-calendar" />
                        </div>
                        <input
                          onChange={(e) => {
                            effectInput("DayOfReturnInPort", e.target.value);
                          }}
                          value={
                            formatDate(selectedSchedule.DayOfReturnInPort) || ""
                          }
                          type="date"
                          id="DayOfReturnInPort"
                          className="form-control date-picker"
                          data-date-format="yyyy-mm-dd"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label className="form-label">Submission Deadline</label>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-left">
                          <em className="icon ni ni-calendar" />
                        </div>
                        <input
                          onChange={(e) => {
                            effectInput("SubmissionDeadline", e.target.value);
                          }}
                          value={
                            formatDate(selectedSchedule.SubmissionDeadline) ||
                            ""
                          }
                          type="date"
                          id="SubmissionDeadline"
                          className="form-control date-picker"
                          data-date-format="yyyy-mm-dd"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <ul className="d-flex justify-content-between gx-4 mt-1">
                      <li>
                        <button
                          onClick={() => updateSailingSchedule()}
                          id="updateEvent"
                          type="button"
                          className="btn btn-primary"
                          disabled={finalSaveloading}
                        >
                          Update Schedule
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </form>

              {selectedSchedule && (
                <VisitList
                  sailingId={selectedSailingId}
                  selectedSailing={selectedSchedule}
                ></VisitList>
              )}
            </div>
          </MainLayout>
        )}
      </ReactModal>
    );
  };

  const MyCalendar = (props) => <div></div>;
  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <div className="toggle-expand-content" data-content="pageMenu">
        {checkUserPermission(
          "sailing-schedule_upload_sailing_schedule_page"
        ) && (
          <Link
            to="/addsailingschedule"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#addEventPopup"
          >
            <em className="icon ni ni-plus" />
            <span>Add Schedule</span>
          </Link>
        )}
      </div>
    </div>
  );

  return (
    <>
      {renderModal()}
      {!modalIsOpen && (
        <>
          <div className="">
            {rerendering && (
              <div className="card-inner">
                {" "}
                <Spinner />{" "}
              </div>
            )}
            {checkUserPermission("sailing-schedule_view_sailing_calendar") && (
              <div className="card-inner">
                <h5 className="text-center">Sailing Schedule</h5>
                <div className="text-right">
                  <Link
                    to="/addsailingschedule"
                    className="d-inline btn btn-sm text-primary ml-3"
                    data-toggle="modal"
                    data-target="#addEventPopup"
                  >
                    <em className="icon ni ni-plus" />
                    <span>Add Schedule</span>
                  </Link>
                </div>
                <div className="gap" />
                <Calendar
                  localizer={localizer}
                  events={myEventsList || []}
                  showAllEvents={true}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: "80vh" }}
                  onSelectEvent={(event) => handleSelect(event)}
                  onSelectSlot={(e) => handleSelect()}
                  onNavigate={(date) => calendarNavigated(date)}
                  eventPropGetter={randomiseCalenderEntryColor}
                  components={{
                    event: calenderPopOver,
                  }}
                  views={[Views.MONTH]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
