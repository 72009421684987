import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import VoyageService from "../../services/voyageService";
import FinalVoyageActivitiesComponent from "../../components/voyageReporting/finalVoyageActivitiesComponent";
import VoyageDetailsActivityComponent from "../../components/voyageReporting/voyageDetailsActivityComponent";
import FinalLocationCostComponent from "../../components/voyage/finalLocationCostComponent";
import FinalVesselCostComponent from "../../components/voyage/finalVesselCostComponent";
import Tabs from "../../utils/tabs";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { toReadableDate, camelizeKeys } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import ConfigurationService from "../../services/configurationService";

export default function FinalizeVoyagePage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState([]);
  const [saving, setSaving] = useState(false);
  const [voyage, setVoyage] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currency, setCurrency] = useState([]);

  useEffect(async () => {
    setLoading(true);
    await retrieveVoyage();
    await voyageLocations();
    await configurations();
    setLoading(false);
  }, [params.voyagePlanningId]);

  const retrieveVoyage = async () => {
    const _voyage = await VoyageService.get(params.voyagePlanningId);
    setVoyage(_voyage || []);
  };

  const configurations = async () => {
    let _configList = await ConfigurationService.getConfigList();

    if (_configList) {
      let _currency = _configList?.filter(
        (l) =>
          l.CorrespondingData.toLowerCase() == "dollars" ||
          l.CorrespondingData.toLowerCase() == "naira" ||
          l.CorrespondingData.toLowerCase() == "pounds"
      );
      setCurrency(_currency);
    }
  };

  const voyageLocations = async () => {
    setLoading(true);
    let _voyageLocations = await VoyageService.getAllVoyageLocations(
      params.voyagePlanningId
    );

    let modifiedDataList = camelizeKeys(_voyageLocations || []);

    setPayload(modifiedDataList || []);

    setLoading(false);
  };

  const finalizeVoyage = async () => {
    let _finalizeVoyage = await VoyageService.updateVoyageStatus(
      params.voyagePlanningId,
      "Finalized"
    );
    if (_finalizeVoyage) {
      toastr("success", "Voyage finalized");
      setSelectedRecord(null);
      await retrieveVoyage();
    }

    setLoading(false);
  };

  const tabHeaders = [
    { icon: "icon ni ni-files", text: "Voyage Details" },
    { icon: "icon ni ni-view-grid-wd", text: "Voyage Activities" },
    { icon: "icon ni ni-money", text: "Location Cost" },
    { icon: "icon ni ni-money", text: "Vessel Cost" },
  ];

  const tabContent = [
    <VoyageDetailsActivityComponent voyage={voyage} />,
    <FinalVoyageActivitiesComponent
      voyagePlanningId={params.voyagePlanningId}
      voyage={voyage}
    />,
    <FinalLocationCostComponent
      voyagePlanningId={params.voyagePlanningId}
      payload={payload}
      currency={currency}
    />,
    <FinalVesselCostComponent
      voyagePlanningId={params.voyagePlanningId}
      voyage={voyage}
      currency={currency}
    />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {voyage.VoyagePlanningStatus !== "Reviewed" ? null : (
            <>
              <li>
                <a
                  role="button"
                  className="btn btn-white btn-outline-light"
                  onClick={() => setSelectedRecord(voyage)}
                >
                  <em className="icon ni ni-check" />
                  <span>Finalize Voyage</span>
                </a>
              </li>
            </>
          )}

          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to Voyages</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Finalize Voyage"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <div className="">{_tabView}</div>

      {selectedRecord && voyage && (
        <CustomModal
          title={voyage.VoyageCode}
          content={
            <>
              <div className="">
                <div className="text-center">
                  <h5>Are you sure you want to finalize voyage?</h5>
                  <br />
                  <p>1. Ensure all voyage activities are completed.</p>
                  <p>
                    2. Ensure all required costs have been correctly entered.
                  </p>
                </div>
                <br />
                <div className="row">
                  <div className="col-6 text-center">
                    <a
                      role="button"
                      className="btn btn-lg btn-success active"
                      onClick={finalizeVoyage}
                    >
                      Yes
                    </a>
                  </div>

                  <div className="col-6 text-center">
                    <a
                      role="button"
                      className="btn btn-lg btn-danger active"
                      onClick={() => setSelectedRecord(null)}
                    >
                      Hold on
                    </a>
                  </div>
                </div>
              </div>
            </>
          }
          onClose={() => setSelectedRecord(null)}
          isClosed={false}
        />
      )}
    </MainLayout>
  );
}
