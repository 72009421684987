import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  checkUserPermission,
  formatAmount,
  toReadableDate,
} from "../../utils/utilityFunctions";
import logo from "../../images/internautical_darklogo.png";
import CustomModal from "../../utils/customModal";
import PostPaymentComponent from "./PostPaymentComponent";
import Spinner from "../../utils/spinner";
import InvoiceService from "../../services/invoiceService";
import toastr from "../../utils/toastr";
import { pick } from "lodash";

function InvoiceDetailsComponent({
  details,
  currencies,
  organizationAccounts,
  callback,
}) {
  const [paymentModalIsClosed, setPaymentModalIsClosed] = useState(true);
  const [_details, setDetails] = useState(details);

  const [editIndex, setEditIndex] = useState(null);
  const [editItem, setEditItem] = useState({});
  const [showDiscountInput, setShowDiscountInput] = useState(false);
  const [tempDiscount, setTempDiscount] = useState("");
  // once an invoice has been moved from draft to pending the frontend needs to create an edit feel to still allow users edit the invoice
  const [editDraftMode, setEditDraftMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const onModalClose = () => {
    setPaymentModalIsClosed(true);
  };

  const onPostSuccess = async () => {
    callback && callback();
    setPaymentModalIsClosed(true);
  };

  const cancelEdit = () => {
    setEditItem({});
    setEditIndex(null);
    setShowDiscountInput(false);
  };

  const addEntry = () => {
    cancelEdit();
    // this is to add a new entry on the table
    setDetails((prev) => ({
      ...prev,
      Detail: prev.Detail ? [...prev.Detail, {}] : [],
    }));

    // this works due to the asynchronous nature of useState. At this point the array length hasn't been increased
    setEditIndex(_details.Detail.length);
  };

  const selectEntryToEdit = (item, index) => {
    // this will clear any new entry that has not been saved
    setDetails((prev) => ({
      ...prev,
      Detail: prev.Detail.filter(
        (item) => !!item.Description && !!item.UnitAmount
      ),
    }));
    setEditItem(item);
    setEditIndex(index);
  };

  const removeEntry = (index) => {
    cancelEdit();

    // this will clear any new entry that has not been saved
    setDetails((prev) => ({
      ...prev,
      Detail: prev.Detail.filter(
        (item, filterIndex) =>
          filterIndex !== index && !!item.Description && !!item.UnitAmount
      ),
    }));
  };

  const calculateDiscount = (prev) =>
    prev.NetAmount - (Number(prev.Discount) / 100) * prev.NetAmount + prev.VAT;

  const validateEntryFailed = () => {
    if (
      !editItem.Description ||
      !editItem.UnitAmount ||
      !Number(editItem.UnitAmount)
    ) {
      return true;
    }
  };

  const saveEntry = (index) => {
    if (validateEntryFailed())
      return toastr("error", "please provide a description and price");

    const _editItem = editItem;

    if (_editItem.Quantity) {
      _editItem.TotalAmount = _editItem.Quantity * _editItem.UnitAmount;
    } else {
      _editItem.TotalAmount = _editItem.UnitAmount;
    }

    _editItem.Rate = _editItem.TotalAmount / _editItem.Quantity;

    setDetails((prev) => {
      prev.Detail.splice(index, 1, _editItem);

      prev.NetAmount = prev.Detail.reduce(
        (acc, current) => acc + current.TotalAmount,
        0
      );
      prev.GrossAmount = calculateDiscount(prev);
      return prev;
    });
    cancelEdit();
  };

  const saveDraft = async () => {
    setLoading(true);
    let _payload;
    let res = null;
    if (_details.InvoiceStatus === "Draft") {
      _payload = pick(_details, ["InvoiceId", "PersonaId", "Discount"]);
      _payload.Detail = _details.Detail.filter(
        (item) => !item.IsSystemGenerated && !item.InvoiceDetailID
      );
      res = await InvoiceService.saveDraft(_payload);
    } else {
      _payload = {
        ..._details,
        Detail: _details.Detail.filter((item) => !item.IsSystemGenerated),
      };
      res = await InvoiceService.updateInvoice(_payload);
    }
    if (res) {
      toastr("success", `Invoice Updated successfully`);
      callback && callback();
    }
    setEditDraftMode(false);
    setLoading(false);
  };

  const enableDeleteIcons = () => {
    return (
      _details.Detail.length > 1 && editIndex === null && !showDiscountInput
    );
  };

  return (
    <>
      {loading ? (
        <div className="center py-5 my-5">
          <Spinner />
        </div>
      ) : (
        <div>
          <div className="invoice">
            <div className="invoice-action">
              <Link
                to="/invoices"
                title="Back to invoices"
                className="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-back-arrow"></em>
              </Link>

              {_details?.fileUrl &&
                checkUserPermission("invoice_download_invoice") && (
                  <a
                    className="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary ml-2"
                    href={`${_details.fileUrl}?authorization=${localStorage.token}`}
                    target="_blank"
                  >
                    <em className="icon ni ni-printer-fill"></em>
                  </a>
                )}
            </div>
            <div className="invoice-wrap">
              <div className="invoice-brand text-center">
                <img src={logo} srcset="./images/logo-dark2x.png 2x" alt="" />
              </div>
              <div className="invoice-head row">
                <div className="invoice-contact  col-lg-4 mb-2">
                  <span className="overline-title">Invoice To</span>
                  <div className="invoice-contact-info">
                    <h4 className="title">{_details.PartyName}</h4>
                    <ul className="list-plain">
                      <li>
                        <em className="icon ni ni-map-pin-fill"></em>
                        <span>{_details.PartyAddress}</span>
                      </li>
                      <li>
                        <Link to={`mailto:${_details.PartyEmail}`}>
                          <em className="icon ni ni-emails-fill"></em>
                          {_details.PartyEmail}
                        </Link>
                      </li>
                      <li>
                        <Link to={`tel:${_details.PartyPhone}`}>
                          <em className="icon ni ni-call-fill"></em>
                          {_details.PartyPhone}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="invoice-contact col-lg-4 mb-2">
                  <span className="overline-title">Payment Account</span>
                  <div className="invoice-contact-info">
                    <h4 className="title">
                      Internautical Offshore Services Limited
                    </h4>
                    <ul className="list-plain">
                      <li className="invoice-id">
                        <span>Bank </span>:<span>Access Bank </span>
                      </li>
                      <li className="invoice-date">
                        <span>Account No </span>:<span>0796510242</span>
                      </li>
                      <li className="invoice-date">
                        <span>Sort Code </span>:<span>044151106</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="invoice-desc  col-lg-4 mb-2">
                  <h3 className="title">Invoice</h3>
                  <ul className="list-plain">
                    <li className="invoice-id">
                      <span>Invoice Ref</span>:
                      <span>{_details.InvoiceRefNo}</span>
                    </li>
                    <li className="invoice-date">
                      <span>Due Date</span>:
                      <span>{toReadableDate(_details?.DueDate)}</span>
                    </li>
                    <li className="invoice-status">
                      <span>Status</span>:<span>{_details?.InvoiceStatus}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="invoice-bills">
                <div className="table-responsive">
                  {!!_details?.Detail?.length && (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="w-60">Description</th>
                          <th>Price</th>
                          <th>Qty</th>
                          <th>Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {_details?.Detail?.map((item, index) =>
                          editIndex === index ? (
                            <>
                              <tr key={index}>
                                <td>
                                  <div className="form-control-wrap d-flex align-center w-80">
                                    <input
                                      type="text"
                                      className="form-control required"
                                      required
                                      value={editItem.Description}
                                      onChange={(e) =>
                                        setEditItem((prev) => ({
                                          ...prev,
                                          Description: e.target.value,
                                        }))
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-control-wrap d-flex align-center w-80">
                                    <input
                                      type="number"
                                      className="form-control required"
                                      required
                                      value={editItem.UnitAmount}
                                      onChange={(e) =>
                                        setEditItem((prev) => ({
                                          ...prev,
                                          UnitAmount: e.target.value,
                                        }))
                                      }
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="form-control-wrap d-flex align-center w-80">
                                    <input
                                      type="number"
                                      className="form-control required"
                                      required
                                      value={editItem.Quantity}
                                      onChange={(e) =>
                                        setEditItem((prev) => ({
                                          ...prev,
                                          Quantity: e.target.value,
                                        }))
                                      }
                                    />
                                  </div>
                                </td>
                                <td className="align-middle text-center">
                                  {" "}
                                  {editItem.TotalAmount
                                    ? formatAmount(
                                        editItem.TotalAmount,
                                        _details.Currency
                                      )
                                    : " - "}{" "}
                                </td>
                                <td className="align-middle">
                                  <div className="d-flex">
                                    <em
                                      className="icon ni ni-check text-primary fs-22px pointer"
                                      title="Save"
                                      onClick={() => saveEntry(index)}
                                    ></em>
                                    <em
                                      className="icon ni ni-trash text-danger fs-22px pointer mx-3"
                                      title="Discard"
                                      onClick={
                                        item.Description && item.UnitAmount
                                          ? cancelEdit
                                          : () => removeEntry(index)
                                      }
                                    ></em>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr key={index + "static"}>
                                <td>{item.Description}</td>
                                <td>
                                  {formatAmount(
                                    item.UnitAmount,
                                    _details.Currency
                                  )}
                                </td>
                                <td>{item.Quantity || "-"}</td>
                                <td>
                                  {item.TotalAmount
                                    ? formatAmount(
                                        item.TotalAmount,
                                        _details.Currency
                                      )
                                    : " - "}
                                </td>
                                <td>
                                  {!item.IsSystemGenerated &&
                                    (checkUserPermission(
                                      "invoice_review_upward_draft_invoices"
                                    ) ||
                                      checkUserPermission(
                                        "invoice_review_downward_draft_invoices"
                                      )) &&
                                    (_details?.InvoiceStatus?.toLowerCase() ==
                                      "draft" ||
                                      editDraftMode) && (
                                      <div className="d-flex">
                                        <em
                                          className="icon ni ni-edit text-primary fs-22px pointer"
                                          title="Edit"
                                          onClick={() => {
                                            selectEntryToEdit(item, index);
                                          }}
                                        ></em>

                                        <em
                                          className={`icon ni ni-cross text-danger fs-22px pointer mx-3 ${
                                            enableDeleteIcons()
                                              ? "text-danger"
                                              : "text-muted"
                                          }`}
                                          title="Delete"
                                          onClick={() =>
                                            enableDeleteIcons() &&
                                            removeEntry(index)
                                          }
                                        ></em>
                                      </div>
                                    )}

                                  {item.IsSystemGenerated &&
                                    (_details?.InvoiceStatus?.toLowerCase() ==
                                      "draft" ||
                                      editDraftMode) && (
                                      <div className="d-flex">
                                        <em
                                          className="icon ni ni-lock text-muted fs-22px pointer"
                                          title="System generated"
                                        ></em>
                                      </div>
                                    )}
                                </td>
                              </tr>
                            </>
                          )
                        )}
                      </tbody>

                      <tfoot>
                        <tr>
                          <td colspan="1">
                            {(checkUserPermission(
                              "invoice_review_upward_draft_invoices"
                            ) ||
                              checkUserPermission(
                                "invoice_review_downward_draft_invoices"
                              )) &&
                              (_details?.InvoiceStatus?.toLowerCase() ==
                                "draft" ||
                                editDraftMode) && (
                                <button
                                  onClick={addEntry}
                                  type="button"
                                  className="btn btn-dark btn-sm mt-2 "
                                  disabled={editIndex !== null}
                                >
                                  <span>Add Entry</span>
                                </button>
                              )}
                          </td>
                          <td colspan="1"></td>
                          <td colspan="2">Subtotal</td>
                          <td>
                            {formatAmount(
                              _details?.NetAmount,
                              _details.Currency
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2"></td>
                          <td colspan="2">VAT</td>
                          <td>
                            {formatAmount(_details?.VAT, _details.Currency)}
                          </td>
                        </tr>
                        {(!!_details?.Discount ||
                          _details?.InvoiceStatus?.toLowerCase() == "draft" ||
                          editDraftMode) && (
                          <tr>
                            <td colspan="2"></td>
                            <td colspan="1">Discount</td>
                            <td colspan="2">
                              {showDiscountInput ? (
                                <>
                                  <div className="d-flex align-center">
                                    <div className="form-control-wrap d-flex align-center ml-auto">
                                      <input
                                        type="number"
                                        className="form-control required"
                                        required
                                        defaultValue={_details.Discount}
                                        max="100"
                                        onChange={(e) =>
                                          Number(e.target.value) <= 100
                                            ? setTempDiscount(e.target.value)
                                            : (e.target.value = 100)
                                        }
                                      />
                                    </div>

                                    <em
                                      className="icon ni ni-check text-primary fs-22px pointer ml-1"
                                      title="Save"
                                      onClick={() => {
                                        setDetails((prev) => {
                                          prev.Discount = tempDiscount;
                                          prev.NetAmount = prev.Detail.reduce(
                                            (acc, current) =>
                                              acc + current.TotalAmount,
                                            0
                                          );
                                          prev.GrossAmount =
                                            calculateDiscount(prev);

                                          return prev;
                                        });

                                        setShowDiscountInput(false);
                                      }}
                                    ></em>
                                    <em
                                      className="icon ni ni-trash text-danger fs-22px pointer ml-1"
                                      title="Discard"
                                      onClick={() =>
                                        setShowDiscountInput(false)
                                      }
                                    ></em>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {_details?.Discount}%
                                  {checkUserPermission(
                                    "invoice_apply_discount"
                                  ) &&
                                    (_details?.InvoiceStatus?.toLowerCase() ==
                                      "draft" ||
                                      editDraftMode) && (
                                      <em
                                        className="icon ni ni-edit text-primary ml-1 pointer"
                                        title="Edit"
                                        onClick={() => {
                                          setShowDiscountInput(true);
                                        }}
                                      ></em>
                                    )}{" "}
                                </>
                              )}
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td colspan="2"></td>
                          <td colspan="2">Grand Total</td>
                          <td>
                            {formatAmount(
                              _details?.GrossAmount,
                              _details.Currency
                            )}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  )}
                  <div className="nk-notes ff-italic fs-12px text-soft">
                    {" "}
                    Invoice was created on a computer and is valid without the
                    signature and seal.{" "}
                  </div>

                  <div className="col-12 pl-0 mb-2">
                    {checkUserPermission("invoice_acknowledge_payment") &&
                      ["partial", "unpaid"].includes(
                        _details?.InvoiceStatus?.toLowerCase()
                      ) && (
                        <button
                          onClick={() => setPaymentModalIsClosed(false)}
                          type="button"
                          className="mt-4 btn btn-dark btn-sm mr-3 "
                        >
                          <span>Post Payment</span>
                        </button>
                      )}

                    {checkUserPermission("invoice_confirm_invoice") &&
                      (_details?.InvoiceStatus?.toLowerCase() == "draft" ||
                        (_details?.InvoiceStatus?.toLowerCase() == "pending" &&
                          editDraftMode)) && (
                        <button
                          onClick={saveDraft}
                          type="button"
                          className="mt-4 btn btn-dark btn-sm mr-3 "
                        >
                          <span>Confirm Invoice</span>
                        </button>
                      )}

                    {(checkUserPermission(
                      "invoice_review_upward_draft_invoices"
                    ) ||
                      checkUserPermission(
                        "invoice_review_downward_draft_invoices"
                      )) &&
                      _details?.InvoiceStatus?.toLowerCase() == "pending" &&
                      !editDraftMode && (
                        <button
                          onClick={() => setEditDraftMode(true)}
                          type="button"
                          className="mt-4 btn btn-dark btn-sm mr-3 "
                        >
                          <span>Edit Invoice</span>
                        </button>
                      )}

                    {checkUserPermission("payments_view_module") &&
                      ["partial", "completed"].includes(
                        _details?.InvoiceStatus?.toLowerCase()
                      ) && (
                        <Link to={`/payments?InvoiceId=${_details?.InvoiceId}`}>
                          <button
                            type="button"
                            className="mt-4 btn btn-dark btn-dim btn-sm "
                          >
                            <span>Payment History</span>
                          </button>
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!paymentModalIsClosed && (
        <CustomModal
          title={`Post Payment`}
          isClosed={paymentModalIsClosed}
          content={
            <PostPaymentComponent
              invoice={_details}
              currencies={currencies}
              organizationAccounts={organizationAccounts}
              callback={onPostSuccess}
            />
          }
          onClose={() => onModalClose()}
        />
      )}
    </>
  );
}

export default InvoiceDetailsComponent;
