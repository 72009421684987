import React, { Component, useEffect, useState } from "react";
import ReactModal from "react-modal";
import apiCall from "../../utils/apiCall";
import Spinner from "../../utils/spinner";
import { BrowserRouter } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import CountryService from "../../services/countriesService";
import ProvinceService from "../../services/provinceService";
import PartyService from "../../services/partyService";
import payloadUpdater from "../../utils/payloadUpdater";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import { defaultModalStyle } from "../../utils/customStyles";
import Select from "react-select";
import ContactPersonService from "../../services/contactPersonService";
import { numberOnly } from "../../components/validator";
import { Navigate, useParams, Link } from "react-router-dom";

export default function EditParty() {
  const params = useParams();
  const [partyId, setPartyId] = useState(params.partId);
  const [showModal, setShowModal] = useState(false);
  const [countryList, setCountry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partyTypeList, setPartyType] = useState([{}]);
  const [countryParty, setCountryParty] = useState();
  const [provinces, setProvinces] = useState({});
  const [partyTypeId, setPartyTypeId] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [postPartyData, setPostPartyData] = useState({
    PartyName: "",
    EmailAddress: "",
    PhoneNumber: "",
    AddressLine1: "",
    AddressLine2: "address",
    RegionId: "",
    CountryId: "",
    City: "",
  });
  const [postContactPerson, setPostContactPerson] = useState({
    ContactPersonId: "",
    CreatedBy: "",
    DateCreated: "",
    EmailAddress: "",
    FirstName: "",
    LastModifiedBy: "",
    LastModifiedDate: "",
    LastName: "",
    PartyId: "",
    PhoneNumber: "",
    RoleId: "",
  });
  const [contactPersonPayload, setContactPersonPayload] = useState({});
  const [contactPersonRoles, setContactPersonRoles] = useState([]);
  const [selectedContactPersonRole, setSelectedContactPersonRole] = useState(
    {}
  );

  const updatePayload = new payloadUpdater({
    payload: postPartyData,
    setPayload: setPostPartyData,
  }).update;
  const addContactPersonPayload = new payloadUpdater({
    payload: contactPersonPayload,
    setPayload: setContactPersonPayload,
  }).update;

  const validationGroup = "editpartyModal";

  useEffect(async () => {
    setLoading(true);
    const party_Types = await PartyService.listPartyTypes();
    if (party_Types) {
      setPartyType(party_Types);
    }
    await retrieveParty();

    const _contactPersonRoles = await ContactPersonService.listAllRoles();
    setContactPersonRoles(_contactPersonRoles);

    const _countryList = await CountryService.listCountry();
    setCountry(_countryList);

    if (partyTypeId) {
      updatePayload("partyTypeId", partyTypeId);
    }
    setLoading(false);

    return () => {
      ClearValidator(validationGroup);
    };
  }, []);

  const retrieveParty = async () => {
    const partDetails = await PartyService.getPartyWithContactPerson(partyId);
    if (partDetails) {
      await retrieveProvince(partDetails.Party.CountryId);
      setPostPartyData(partDetails.Party);
      setContactPersonPayload(partDetails.ContactPersons[0]);
    }
  };

  const savePartyForm = async () => {
    if (loading) return;
    // const validatorStatus = ValidateGroup(validationGroup);
    // console.log(validatorStatus);
    if (
      // !validatorStatus ||
      !postPartyData.CountryId ||
      !postPartyData.RegionId
    ) {
      toastr("error", "Please fill the form correctly");
      return;
    }

    const selectedCountry = countryList.find(
      (a) => a.CountryId == postPartyData.CountryId
    );
    const selectedState = provinces[postPartyData.CountryId].find(
      (a) => a.RegionId == postPartyData.RegionId
    );

    postPartyData.AddressLine2 = `${selectedState?.RegionName} ${selectedCountry?.CountryName}`;
    if (partyTypeId) {
      postPartyData.partyTypeId = partyTypeId;
    }

    setLoading(true);
    const response = await PartyService.postEditedParty(
      postPartyData.PartyId,
      postPartyData
    );
    if (response) {
      if (!contactPersonPayload.ContactPersonId) {
        let _cpPayload = {
          ...contactPersonPayload,
          PartyId: postPartyData.PartyId,
        };
        const contactPersonSaved = await ContactPersonService.postContactPerson(
          _cpPayload
        );

        const _partyData = {
          party: { ...response },
          contactPerson: {
            ...contactPersonSaved,
          },
        };
        ClearValidator(validationGroup);
        setPostPartyData({});
        setContactPersonPayload({});

        toastr("success", "Party details updated successfully");
        setRedirectUrl("/party-list");
      } else {
        let _cpPayload = {
          ...contactPersonPayload,
          partyId: response.PartyId || response.partyId,
        };
        const contactPersonSaved =
          await ContactPersonService.postEditedContactPerson(
            _cpPayload.ContactPersonId,
            _cpPayload
          );

        const _partyData = {
          party: { ...response },
          contactPerson: {
            ...contactPersonSaved,
          },
        };
        ClearValidator(validationGroup);
        setPostPartyData({});
        setContactPersonPayload({});

        toastr("success", "Party details updated successfully");
        setRedirectUrl("/party-list");
      }
    }
    setLoading(false);
  };

  const retrieveProvince = async (CountryId) => {
    if (!CountryId) return;
    if (provinces[CountryId]) return; // we have fetched provinces for this country before

    const _provinces = await ProvinceService.listProvincesByCountry(CountryId);
    const __provinces = { ...provinces };
    __provinces[CountryId] = _provinces;

    console.log({ __provinces });
    setProvinces(__provinces);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setPostPartyData({});
    setRedirectUrl("party-list");
    setShowModal(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      preTitle={
        <span className="ml-2">
          <h4 className="ml-2">Edit party</h4>
        </span>
      }
      showFullLoader={loading}
    >
      <div className="card  col">
        <form className=" nk-wizard-simple is-alter p-3">
          <div className="nk-wizard-head">
            {/* There should be something here */}
          </div>
          <div className="nk-wizard-content">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="partyName">
                    Party Name
                  </label>
                  <div className="form-control-wrap">
                    <input
                      tabIndex={0}
                      type="text"
                      value={postPartyData.PartyName || ""}
                      onChange={(e) =>
                        updatePayload("PartyName", e.target.value)
                      }
                      className="form-control required"
                      id="PartyName"
                    />
                    <Validator
                      data={postPartyData.PartyName}
                      errorMessage="Enter party name"
                      groupName={validationGroup}
                      dataKey={"PartyName"}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div className="col-sm-6">
                <div className="form-group" style={{ zIndex: "11" }}>
                  <label className="form-label" htmlFor="partyType">
                    Party Type
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      options={partyTypeList.map((e) => ({
                        ...e,
                        label: e.PartyType,
                        value: e.PartyTypeId,
                      }))}
                      value={partyTypeList
                        .map((e) => ({
                          ...e,
                          label: e.PartyType,
                          value: e.PartyTypeId,
                        }))
                        .find((a) => a.value == postPartyData.PartyTypeId)}
                      onChange={(option) =>
                        updatePayload("partyTypeId", option.value)
                      }
                    />
                    {/* <Validator data={postPartyData.partyType} errorMessage="select party type" groupName={validationGroup} dataKey={"partyType"} /> */}
                  </div>
                </div>
              </div>
              <div className="gap"></div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Address Line 1</label>
                  <div className="form-control-wrap">
                    <textarea
                      defaultValue={postPartyData.AddressLine1 || ""}
                      onChange={(e) =>
                        updatePayload("addressLine1", e.target.value)
                      }
                      placeholder="Fill in address"
                      className="form-control no-resize"
                      id="addressLine1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Address Line 2</label>
                  <div className="form-control-wrap">
                    <textarea
                      defaultValue={postPartyData.AddressLine2 || ""}
                      onChange={(e) =>
                        updatePayload("addressLine2", e.target.value)
                      }
                      placeholder="Fill in address"
                      className="form-control no-resize"
                      id="addressLine2"
                    />
                  </div>
                </div>
              </div>
              <div className="gap" />

              <div className="col-sm-6">
                <div className="form-group" style={{ zIndex: "10" }}>
                  <label className="form-label" htmlFor="country">
                    Country
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      options={countryList.map((e) => ({
                        ...e,
                        label: e.CountryName,
                        value: e.CountryId,
                      }))}
                      value={countryList
                        .map((e) => ({
                          ...e,
                          label: e.CountryName,
                          value: e.CountryId,
                        }))
                        .find((a) => a.value == postPartyData.CountryId)}
                      onChange={(option) => {
                        updatePayload("CountryId", option.value);
                        retrieveProvince(option.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group" style={{ zIndex: "9" }}>
                  <label className="form-label">State/Province</label>
                  <div className="form-control-wrap">
                    <Select
                      isDisabled={!postPartyData.CountryId}
                      options={
                        postPartyData.CountryId &&
                        provinces[postPartyData.CountryId] &&
                        provinces[postPartyData.CountryId].map((e) => ({
                          ...e,
                          label: e.RegionName,
                          value: e.RegionId,
                        }))
                      }
                      value={(
                        (postPartyData.CountryId &&
                          provinces[postPartyData.CountryId] &&
                          provinces[postPartyData.CountryId].map((e) => ({
                            ...e,
                            label: e.RegionName,
                            value: e.RegionId,
                          }))) ||
                        []
                      ).find((a) => a.value == postPartyData.RegionId)}
                      onChange={(option) => {
                        updatePayload("RegionId", option.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="gap"></div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="phoneNumber">
                    City
                  </label>
                  <div className="form-control-wrap">
                    <input
                      tabIndex={0}
                      type="text"
                      value={postPartyData.City || ""}
                      onChange={(e) => updatePayload("City", e.target.value)}
                      className="form-control required"
                      id="City"
                    />
                    <Validator
                      data={postPartyData.City}
                      dataType="text"
                      errorMessage="Fill in City"
                      groupName={validationGroup}
                      dataKey={"City"}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="phoneNumber">
                    Phone number
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="number"
                      value={postPartyData.PhoneNumber || ""}
                      onChange={(e) =>
                        updatePayload("PhoneNumber", e.target.value)
                      }
                      min="99999"
                      max="9999999999"
                      className="form-control"
                      id="PhoneNumber"
                      name="fw-last-name"
                    />
                    <Validator
                      data={postPartyData.PhoneNumber}
                      dataType="number"
                      errorMessage="Please fill phone number"
                      groupName={validationGroup}
                      dataKey={"PhoneNumber"}
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="gap" /> */}
              {/* <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="eemailAddressmail">
                    Email address
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="email"
                      value={postPartyData.EmailAddress || ""}
                      onChange={(e) =>
                        updatePayload(
                          "EmailAddress",
                          e.target.value?.toLowerCase()
                        )
                      }
                      className="form-control"
                      id="EmailAddress"
                      name="EmailAddress"
                    />
                    <Validator
                      data={postPartyData.EmailAddress}
                      dataType="email"
                      groupName={validationGroup}
                      dataKey={"EmailAddress"}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="gap gap-20px" />
          <div className="gap gap-20px" />
          <div className="row">
            <span className="col preview-title-lg overline-title">
              Contact Person Information{" "}
            </span>
            <div className="gap gap-20px" />
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">First name</label>{" "}
                <small className="text-danger ml-1">*</small>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    value={contactPersonPayload?.FirstName || ""}
                    className="form-control required"
                    defaultValue={contactPersonPayload?.FirstName}
                    onChange={(e) =>
                      addContactPersonPayload("FirstName", e.target.value)
                    }
                    required
                  />
                  <Validator
                    data={contactPersonPayload?.FirstName}
                    errorMessage="Enter contact first name"
                    groupName={validationGroup}
                    dataKey={"FirstName"}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Last name</label>{" "}
                <small className="text-danger ml-1">*</small>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    value={contactPersonPayload?.LastName || ""}
                    className="form-control required"
                    defaultValue={contactPersonPayload?.LastName}
                    onChange={(e) =>
                      addContactPersonPayload("LastName", e.target.value)
                    }
                    required
                  />
                  <Validator
                    data={contactPersonPayload?.LastName}
                    errorMessage="Enter contact last name"
                    groupName={validationGroup}
                    dataKey={"LastName"}
                  />
                </div>
              </div>
            </div>
            <div className="gap" />
            <div className="col-sm-6">
              <div className="form-group" style={{ zIndex: "8" }}>
                <label className="form-label">Contact Person Role</label>{" "}
                <small className="text-danger ml-1">*</small>
                <div className="form-control-wrap">
                  <>
                    {contactPersonRoles && (
                      <Select
                        options={contactPersonRoles.map((e) => ({
                          ...e,
                          label: e.ContactPersonRole,
                          value: e.ContactPersonRoleId,
                        }))}
                        value={contactPersonRoles
                          .map((e) => ({
                            ...e,
                            label: e.ContactPersonRole,
                            value: e.ContactPersonRoleId,
                          }))
                          .find((a) => a.value == contactPersonPayload?.RoleId)}
                        onChange={(option) => {
                          setSelectedContactPersonRole(option);
                          addContactPersonPayload(
                            "RoleId",
                            option.ContactPersonRoleId
                          );
                        }}
                      />
                    )}
                  </>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Email address</label>{" "}
                <small className="text-danger ml-1">*</small>
                <div className="form-control-wrap">
                  <input
                    type="email"
                    name="EmailAddress2"
                    id="EmailAddress2"
                    value={contactPersonPayload?.EmailAddress || ""}
                    className="form-control required"
                    defaultValue={contactPersonPayload?.EmailAddress}
                    onChange={(e) =>
                      addContactPersonPayload(
                        "EmailAddress",
                        e.target.value?.toLowerCase()
                      )
                    }
                    required
                  />
                  <Validator
                    data={contactPersonPayload?.EmailAddress}
                    dataType="email"
                    errorMessage="Enter contact email"
                    groupName={validationGroup}
                    dataKey={"emailAddress2"}
                  />
                </div>
              </div>
            </div>
            <div className="gap" />
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Phone number</label>{" "}
                <small className="text-danger ml-1">*</small>
                <div className="form-control-wrap">
                  <input
                    onKeyUp={(e) => numberOnly(e)}
                    type="tel"
                    maxLength={13}
                    className="form-control required"
                    onChange={(e) =>
                      addContactPersonPayload("PhoneNumber", e.target.value)
                    }
                    required
                    value={contactPersonPayload?.PhoneNumber}
                  />
                  <Validator
                    dataType="number"
                    data={contactPersonPayload?.PhoneNumber}
                    errorMessage="Enter contact phone"
                    groupName={validationGroup}
                    dataKey={"phoneNumber2"}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="mt-5">
          <Link to={"/party-list"}>
            <button className="btn btn-md btn-light btn-dim">
              <em className="icon ni ni-arrow-left pointer mr-2" /> Go back
            </button>
          </Link>
          {loading ? (
            <span className="float-right">
              <Spinner size="2" />
            </span>
          ) : (
            <button
              type="button"
              onClick={() => savePartyForm()}
              className="btn btn-md btn-dark float-right"
            >
              Update party
            </button>
          )}
        </div>
      </div>
    </MainLayout>
  );
}
