import React, { useEffect, useState } from 'react';

export default function MapView({ long, lat, height, width }) {

    useEffect(() => {
       

    }, [height, width]);



    return (
        <><iframe width={width || 300} height={height || 170} 
        frameBorder={0} scrolling="no" 
        marginHeight={0} marginWidth={0} 
        src={`https://maps.google.com/maps?q=${lat},${long}&hl=en&z=7&output=embed`}>
        </iframe>
       </>
    );
}


