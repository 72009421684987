import React, { useEffect, useState } from "react";
import MainLayout from '../../layouts/mainLayout';
import { Link } from 'react-router-dom';
import ActivityTable from "../../components/dataTable";
import ActivityService from "../../services/activityService";
import Paginate from "../../components/paginate";
import Tabs from "../../utils/tabs";
import ActivityListComponent from "../../components/activities/activitiesListComponent";
import ActivityTimeSpent from "../../components/activities/activityTimeSpent";
import { checkUserPermission } from "../../utils/utilityFunctions";

export default function ActivityList() {
    const [initializing, setInit] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [pageSize, setPageSize] = useState(20);
    const [pages, setPages] = useState([10, 20, 30, 50]);
    const [tableData, setTableData] = useState([]);
    const [activitiesCount, setActivitiesCount] = useState();
    useEffect(
        async () => {
            await loadData();
        }
        , []);

    const loadData = async (_page, _pageSize, _search) => {
        setInit(true);
        _page = _page || currentPage;
        _pageSize = _pageSize || pageSize;

        if (_search) {
            _page = 1;
        }
        const _activities = await ActivityService.activitiesPaginatedList(_page, _pageSize, _search);
        let modifiedDataList = _activities?.Data || [];
        if (_activities?.TotalCount) {
            setActivitiesCount(tableData?.TotalCount);
        }
        modifiedDataList = modifiedDataList.map(e => ({
            Id: e.ActivityId,
            Longname: e.ActivityName,
            Shortname: e.ActivityShortName,
            Description: e.ActivityDescription,
            AllowParallel: e.AllowsParallel ? "Yes" : "No",
            actionsComponent: overflowMenu(e)

        }));
        setTableData(modifiedDataList);
        (currentPage !== _page) && setCurrentPage(_page);
        (pageSize !== _pageSize) && setPageSize(_pageSize);
        setInit(false);

    }
    const pageActions =
        <div className="toggle-wrap nk-block-tools-toggle">
            <Link to={"#"} className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r" /></Link>
            <div className="toggle-expand-content" data-content="pageMenu">
                <ul className="nk-block-tools g-3">
                    {checkUserPermission("activities_create_activity") && (
                        <li className="nk-block-tools-opt">
                            <div className="drodown">
                                <Link to={"#"} className="dropdown-toggle btn btn-icon btn-primary" data-toggle="dropdown"><em className="icon ni ni-plus" /></Link>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="link-list-opt no-bdr">
                                        <li>
                                            <Link to="/add-activity">
                                                <span>Create Activity</span></Link></li>


                                    </ul>
                                </div>
                            </div>
                        </li>)}
                </ul>
            </div>
        </div>

    const overflowMenu = (activity) => {
        return <ul className="nk-tb-actions gx-2">
            {null && <li className="nk-tb-action-hidden">
                <Link to={"#"} className="btn btn-sm btn-icon btn-trigger" data-toggle="tooltip" data-placement="top" title="Suspend">
                    <em className="icon ni ni-user-cross-fill" />
                </Link>
            </li>}
            <li>
                <div className="drodown">
                    <Link to={"#"} className="btn btn-sm btn-icon btn-trigger dropdown-toggle" data-toggle="dropdown"><em className="icon ni ni-more-h" /></Link>
                    <div className="dropdown-menu dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                            {/* <li className="clickable" onClick={() => setSelectedRecord(location)}><a><em className="icon ni ni-eye" /><span>View Details</span></a></li> */}
                            {checkUserPermission("activities_view_details") && (
                                <li>
                                    <Link to={`/view-activity/${activity.ActivityId}`}><em className="icon ni ni-eye" /><span>View Activity details</span></Link>
                                </li>
                            )}

                        </ul>
                    </div>
                </div>
            </li>
        </ul>

    }

    const tabHeaders = [
        { icon: "icon ni ni-view-grid-wd", text: "Activity List" },
        { icon: "icon ni ni-clock", text: "Reports - Total Time Spent" },
    ];
    const tabContent = [
        <ActivityListComponent />,
        <ActivityTimeSpent />,
    ];

    const tabView = (
        <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
    );

    return (
        <MainLayout title="Activities" pageActions={pageActions} loading={initializing}
            showFullLoader={initializing}>
            <div className="">{tabView}</div>

        </MainLayout>
    )
}