import SamplePage from "../pages/sample";
import AddVessel from "../pages/vessel/addVessel";
import AddVesselToFleet from "../pages/vessel/addVesselToFleet";
import EditVessel from "../pages/vessel/editVessel";
import VesselList from "../pages/vessel/vesselList";
import EditCompliance from "../pages/vessel/editCompliance";
import VoyageHistory from "../pages/vessel/voyageHistory";
import ComplianceEditPage from "../pages/vessel/complianceEdit";
import VesselDetailsPage from "../pages/vessel/vesselDetailsPage";
import AddParty from "../pages/party/addParty";
import PartyList from "../pages/party/partyList";
import EditParty from "../pages/party/editParty";
import ViewParty from "../pages/party/viewParty";
import LocationsListPage from "../pages/location/locationsList";
import AddOrEditLocationPage from "../pages/location/addOrEditLocation";
// import EditLocationPage from "../pages/location/addOrEditLocation";
// import EditLocationPage from "../pages/location/editLocation";
import FullLocationViewPage from "../pages/location/fullLocationView";
import CreateEquipment from "../pages/equipment/createEquipment";
import EditEquipment from "../pages/equipment/editEquipment";
import CargoCarryingEquipmentList from "../pages/equipment/equipmentList";
import AddSailingSchedule from "../pages/sailingSchedule/addSailingSchedule";
import SailingScheduleCalendarView from "../pages/sailingSchedule/sailingScheduleCalendarView";
import ActivityList from "../pages/activities/activityList";
import ViewActivity from "../pages/activities/viewActivity";
import BookingRequestListPage from "../pages/bookingRequest/bookingRequestList";
import BookingRequestDetailsPage from "../pages/bookingRequest/bookingRequestDetailsPage";
import CreateVoyagePage from "../pages/voyage/addVoyage";
import ProductRequestPage from "../pages/voyage/productRequestPage";
// import ProductUsageReportPage from "../pages/voyage/productUsageReportPage";
import AddActivity from "../pages/activities/addActivity";
import AddBookingRequest from "../pages/bookingRequest/addBookingRequest";
import EditBookingRequest from "../pages/bookingRequest/editBookingRequest";
//import CreateVoyagePage from "../pages/voyage/addVoyage";

import AddNewCargo from "../pages/voyage/addNewCargo";
import RemoveCargo from "../pages/voyage/removeCargo";
import VoyageListPage from "../pages/voyage/voyageList";
import UploadCargoEvidence from "../pages/voyage/uploadCargoEvidence";
import VoyageDetailsPage from "../pages/voyage/voyageDetails";
import UploadDeckPlan from "../pages/voyage/uploadDeckPlan";
import AssignCargoTanks from "../pages/voyage/assignCargoTanks";
import ActiveVesselMapView from "../pages/voyage/activeVesselMapView";
import AddLocationCostPage from "../pages/voyage/addLocationCostPage";

import CreateCommercialPage from "../pages/commercial/addCommercial";
import CommercialDetailsPage from "../pages/commercial/commercialDetails";
import CommercialListPage from "../pages/commercial/commercialList";
import EditCommercialPage from "../pages/commercial/editCommercial";
import VoyageActivitiesPage from "../pages/voyageReporting/voyageActivities";
import StartVoyageActivityPage from "../pages/voyageReporting/startVoyageActivity";
import StartSailingVoyageActivityPage from "../pages/voyageReporting/startSailingVoyageActivity";
import EndVoyageActivityPage from "../pages/voyageReporting/endVoyageActivity";
import VoyageActivityDetailsPage from "../pages/voyageReporting/voyageActivityDetails";
import FuelUsageReportPage from "../pages/voyage/fuelUsageReportPage";
import WaterUsageReportPage from "../pages/voyage/waterUsageReportPage";
import AddVoyageActivity from "../pages/activities/addVoyageActivity";
import VoyageActivityListPage from "../pages/activities/voyageActivityListPage";
import WeatherReportingPage from "../pages/voyageReporting/weatherReportingPage";
import TerminalActivitiesPage from "../pages/voyageReporting/terminalActivities";
import UpdateVoyageActivityPage from "../pages/voyageReporting/updateVoyageActivity";
import UpdateSystemSettingsPage from "../pages/systemSettings/updateSystemSettings";
import FinalizeVoyagePage from "../pages/voyage/finalizeVoyage";
import PoolListPage from "../pages/vesselPools/poolList";
import ReviewVoyagePage from "../pages/voyage/reviewVoyage";
// import SwitchVesselPoolPage from "../pages/vesselPools/switchVesselPool";

// Costs module
import Costs from "../pages/costs/Costs.jsx";
import InvoiceList from "../pages/invoice/InvoiceList";
import InvoiceDetail from "../pages/invoice/InvoiceDetail";
import PaymentsList from "../pages/payments/PaymentsList";
import PaymentDetails from "../pages/payments/PaymentDetails";

//Pools
import AddVesselToPool from "../pages/vesselPools/addVesselToFleet";
// import PoolListPage from "../pages/vesselPools/poolList";
import PoolMapView from "../pages/vesselPools/poolMapView";
import ViewPool from "../pages/vesselPools/viewPool";
import SwitchVesselPoolPage from "../pages/vesselPools/switchVesselPool";
// Users
import AddNewUserPage from "../pages/users/addNewUser";
import EditUserDetailsPage from "../pages/users/editUserDetails";
import UserListPage from "../pages/users/userList";

// Roles
import AddNewRolePage from "../pages/roles/addNewRole";
import RolesListPage from "../pages/roles/rolesList";
import GrantRolePermissionsPage from "../pages/roles/grantRolePermissions";
import Profile from "../pages/profile/Profile";

//Dashboard
import VesselUtilizationDashboard from "../components/dashboard/VesselUtilization";
import OffshoreInstallationDashboard from "../components/dashboard/OffShoreAllocationCost";
import DashboardSample from "../pages/dashboard/dashboardSample";
import OffshoreInstallationEfficiency from "../components/dashboard/OffshoreInstallationEfficiency";
import OustandingInvoices from "../components/dashboard/OutstandingPayments";
import DraftInvoices from "../components/dashboard/DraftInvoices";
import BookingRequestDashboard from "../components/dashboard/BookingRequestDashboard";
const CommonRoutes = [
  { route: "/", component: DashboardSample },

  {
    route: "/dash",
    component: VesselUtilizationDashboard,
  },
  {
    route: "/dashboardSample",
    component: DashboardSample,
  },
  {
    route: "/dash2",
    component: OffshoreInstallationDashboard,
  },
  {
    route: "/dash3",
    component: OffshoreInstallationEfficiency,
  },
  {
    route: "/dash4",
    component: OustandingInvoices,
  },
  {
    route: "/dash5",
    component: DraftInvoices,
  },
  {
    route: "/dash6",
    component: BookingRequestDashboard,
  },
  // vessel module
  {
    route: "/add-vessel",
    component: AddVessel,
    requiredPermission: "vessel_add_vessel",
  },
  {
    route: "/edit-vessel",
    component: EditVessel,
    requiredPermission: "vessel_edit_details",
  },
  {
    route: "/add-tofleet",
    component: AddVesselToFleet,
    requiredPermission: "vessel_add_vessel_to_fleet",
  },
  {
    route: "/vessel-list",
    component: VesselList,
    requiredPermission: "vessel_view_module",
  },
  {
    route: "/edit-compliance",
    component: EditCompliance.apply,
    requiredPermission: "vessel_edit_compliance",
  },
  {
    route: "/compliance/:vesselId",
    component: ComplianceEditPage,
    requiredPermission: "vessel_view_compliance",
  },
  {
    route: "/vessel/:vesselId",
    component: VesselDetailsPage,
    requiredPermission: "vessel_view_details",
  },
  {
    route: "/edit-vessel/:vesselId",
    component: VesselDetailsPage,
    requiredPermission: "vessel_edit_details",
  },
  {
    route: "/voyage-history",
    component: VoyageHistory,
    requiredPermission: "vessel_voyage_history",
  },
  {
    route: "/list-vessels",
    component: VesselList,
    requiredPermission: "vessel_view_module",
  },

  // party module
  {
    route: "/add-party",
    component: AddParty,
    requiredPermission: "parties_add_party",
  },
  {
    route: "/party-list",
    component: PartyList,
    requiredPermission: "parties_view_party_list",
  },
  {
    route: "/edit-party/:partId",
    component: EditParty,
    requiredPermission: "parties_edit_party",
  },
  {
    route: "/view-party/:partId",
    component: ViewParty,
    requiredPermission: "parties_view_party_details",
  },

  // equipment module
  {
    route: "/add-equipment",
    component: CreateEquipment,
    requiredPermission: "equipment_add_equipment",
  },
  {
    route: "/equipment-list",
    component: CargoCarryingEquipmentList,
    requiredPermission: "equipment_view_module",
  },
  {
    route: "/edit-equipment/:equipmentId",
    component: EditEquipment,
    requiredPermission: "equipment_edit_equipment_details",
  },

  // activities module
  {
    route: "/activity-list",
    component: ActivityList,
    requiredPermission: "activities_view_module",
  },
  {
    route: "/view-activity/:activityId",
    component: ViewActivity,
    requiredPermission: "activities_view_details",
  },
  {
    route: "/add-activity",
    component: AddActivity,
    requiredPermission: "activities_create_activity",
  },

  // sailing module
  {
    route: "/addsailingschedule",
    component: AddSailingSchedule,
    requiredPermission: "sailing-schedule_add_schedule_button",
  },
  {
    route: "/sailingSchedule",
    component: SailingScheduleCalendarView,
    requiredPermission: "sailing-schedule_view_sailing_calendar",
  },

  // locations module
  {
    route: "/locations",
    component: LocationsListPage,
    requiredPermission: "location_view_module",
  },
  {
    route: "/add-location",
    component: AddOrEditLocationPage,
    requiredPermission: "location_add_new_location",
  },
  {
    route: "/edit-location/:locationId",
    component: AddOrEditLocationPage,
    requiredPermission: "location_edit_location_details",
  },
  {
    route: "/location/:locationId",
    component: FullLocationViewPage,
    requiredPermission: "location_view_details",
  },
  {
    route: "/location/:locationId/:fleets_vessels",
    component: FullLocationViewPage,
    requiredPermission: "location_view_fleets_and_vessels_attached_to_a_zone",
  },

  // booking request module
  {
    route: "/add-booking",
    component: AddBookingRequest,
    // requiredPermission: "booking_add_booking"
  },
  {
    route: "/booking-requests",
    component: BookingRequestListPage,
    requiredPermission: "booking_view_bookings_list",
  },
  {
    route: "/booking-request/:bookingRequestId",
    component: BookingRequestDetailsPage,
    // requiredPermission: "booking_view_bookings_details"
  },
  {
    route: "/edit-booking/:bookingRequestId",
    component: EditBookingRequest,
    // requiredPermission: "booking_edit_bookings_details",
  },
  {
    route: "/deckCargoPlan-Upload/:voyagePlanningId",
    component: UploadDeckPlan,
  },
  { route: "/assign-tanks/:voyagePlanningId", component: AssignCargoTanks },
  { route: "/product-request", component: ProductRequestPage },
  { route: "/product-request/:product", component: ProductRequestPage },
  {
    route: "/product-request/:product/:vesselId",
    component: ProductRequestPage,
  },
  { route: "/fuel-usage-log/:voyageId", component: FuelUsageReportPage },
  { route: "/water-usage-log/:voyageId", component: WaterUsageReportPage },

  //  commercials module
  {
    route: "/commercial/:vesselCommercialId",
    component: CommercialDetailsPage,
    requiredPermission: "commercial_view_details",
  },
  {
    route: "/create-commercial",
    component: CreateCommercialPage,
    requiredPermission: "commercial_add_commercials",
  },
  {
    route: "/commercials",
    component: CommercialListPage,
    requiredPermission: "commercial_view_module",
  },
  {
    route: "/edit-commercial/:vesselCommercialId",
    component: EditCommercialPage,
    requiredPermission: "commercial_edit_commercials",
  },

  // voyage module
  {
    route: "/voyages",
    component: VoyageListPage,
    requiredPermission: "voyage-planning_view_module",
  },
  {
    route: "/create-voyage",
    component: CreateVoyagePage,
    requiredPermission: "voyage-planning_create_voyage",
  },
  {
    route: "/add-cargo/:voyagePlanningId",
    component: AddNewCargo,
    requiredPermission: "voyage-planning_add_cargo",
  },
  {
    route: "/remove-cargo/:voyagePlanningId",
    component: RemoveCargo,
    requiredPermission: "voyage-planning_remove_cargos",
  },
  {
    route: "/voyage/:voyagePlanningId",
    component: VoyageDetailsPage,
    requiredPermission: "voyage-planning_view_details",
  },
  {
    route: "/upload-cargo-evidence/:voyagePlanningId",
    component: UploadCargoEvidence,
    requiredPermission: "voyage-planning_add_activity", // clarify this permission
  },
  {
    route: "/voyage-activities/:voyagePlanningId",
    component: VoyageActivitiesPage,
    requiredPermission: "voyage-planning_view_details",
  },
  {
    route: "/start-voyage-activity/:voyageActivityId",
    component: StartVoyageActivityPage,
    requiredPermission: "voyage-planning_add_activity", // clarify this permission
  },
  {
    route: "/start-sailing-voyage-activity/:voyagePlanningId",
    component: StartSailingVoyageActivityPage,
    requiredPermission: "voyage-planning_add_activity", // clarify this permission
  },
  {
    route: "/end-voyage-activity/:voyageActivityId",
    component: EndVoyageActivityPage,
    requiredPermission: "voyage-planning_add_activity", // clarify this permission
  },
  {
    route: "/voyage-activity-details/:voyageActivityId",
    component: VoyageActivityDetailsPage,
    requiredPermission: "voyage-planning_view_details",
  },
  {
    route: "/add-activity/:voyageId",
    component: AddVoyageActivity,
    requiredPermission: "voyage-planning_add_activity",
  },
  {
    route: "/voyage-activity/:voyageId",
    component: VoyageActivityListPage,
    requiredPermission: "voyage-planning_view_details",
  },
  {
    route: "/update-voyage-activity/:voyageActivityId",
    component: UpdateVoyageActivityPage,
    requiredPermission: "voyage-planning_add_activity", // clarify this permission
  },

  {
    route: "/finalize-voyage/:voyagePlanningId",
    component: FinalizeVoyagePage,
    requiredPermission: "voyage-planning_finalize_voyage",
  },
  {
    route: "/review-voyage/:voyagePlanningId",
    component: ReviewVoyagePage,
    requiredPermission: "voyage-planning_review_voyage",
  },
  {
    route: "/add-extra-voyage-costs/:voyagePlanningId",
    component: AddLocationCostPage,
    requiredPermission: "voyage-planning_review_voyage",
  },

  // { route: "/product-usage-log/:voyageId", component: ProductUsageReportPage },

  {
    route: "/active-vessels",
    component: ActiveVesselMapView,
    requiredPermission: "voyage-planning_view_all_active_vessels",
  },
  { route: "/weather-report", component: WeatherReportingPage },
  {
    route: "/terminal-activities/:locationId",
    component: TerminalActivitiesPage,
  },
  {
    route: "/update-system-settings",
    component: UpdateSystemSettingsPage,
  },

  // Costs module
  { route: "/costs/:type", component: Costs },
  // invoice module
  {
    route: "/invoices",
    component: InvoiceList,
    requiredPermission: "invoice_view_module",
  },
  {
    route: "/invoices/:id",
    component: InvoiceDetail,
    requiredPermission: "invoice_view_details",
  },

  // payment history module
  {
    route: "/payments",
    component: PaymentsList,
    requiredPermission: "payments_view_module",
  },
  {
    route: "/payments/:PaymentReferenceNo",
    component: PaymentDetails,
    requiredPermission: "payments_view_details",
  },

  // user module
  { route: "/add-user", component: AddNewUserPage },
  { route: "/edit-user-details/:userId", component: EditUserDetailsPage },
  { route: "/users", component: UserListPage },

  // role module
  { route: "/add-role", component: AddNewRolePage },
  { route: "/roles", component: RolesListPage },
  { route: "/grant-permissions/:roleId", component: GrantRolePermissionsPage },

  // Pool module
  { route: "/pools-addVessel", component: AddVesselToPool },
  { route: "/vessel-pools", component: PoolListPage },
  { route: "/pool-mapview", component: PoolMapView },
  { route: "/view-pool/:fleetId", component: ViewPool },
  { route: "/switch-vessel-pool/:fleetId", component: SwitchVesselPoolPage },

  // profile
  { route: "/profile", component: Profile },
];

export default CommonRoutes;
