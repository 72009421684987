import moment from "moment";

// Round the minutes on date to nearest hour
const roundMinutes = (date) => {
  date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
  date.setMinutes(0, 0, 0);
  return date;
};

// Getting the date between days
const getDatesBetweenDates = (startDate, endDate) => {
  let dates = [];
  //to avoid modifying the original date
  const theDate = new Date(startDate);
  while (theDate < new Date(endDate)) {
    dates = [...dates, new Date(theDate)];
    theDate.setDate(theDate.getDate() + 1);
  }
  // console.log("dates0", dates);
  // // dates = [...dates, new Date(endDate)];
  // console.log("dates1", dates);
  // console.log(
  //   "dates2",
  //   dates?.map((date) => date.toLocaleDateString("sv"))
  // );
  return dates?.map((date) => date.toLocaleDateString("sv"));
};

// Getting the hours between days
const getHoursBetweenDates = (startDate, endDate) => {
  let fromTime = moment(startDate);
  let toTime = moment(endDate);
  let duration = moment.duration(toTime.diff(fromTime));
  let diff = duration.asHours();
  let array = [];

  for (let i = 0; diff > i; i++) {
    let result = moment(fromTime).add(i, "hours").format("HH:mm");
    let result1 = moment(fromTime).add(i, "hours").format("DD-MM-YYYY");
    array.push({
      result,
      result1,
    });
  }
  return array;
};

// Calculate the number of days between dates
const NoOfDays = (first, second) => {
  const resp = (second - first) / (1000 * 60 * 60 * 24);
  return resp.toFixed(4);
};

// Horizontal scaling label for day/hour range
const TimelineRange = ({ width, range, noOfIntervals }) => {
  return (
    <div className="row">
      <div className="col-2" style={{ width: "150px" }}>
        <h6 className="text-center">
          Vessels <small style={{ fontStyle: "italic" }}>(Voyage No.)</small>
        </h6>
      </div>
      <div className="col-10">
        <div style={{ width: `${width * noOfIntervals * 0.063}rem` }}>
          {range?.map((label, i) => (
            <div
              className="d-inline-block"
              style={{ width: `${width * 0.063}rem`, fontWeight: "bold" }}
            >
              <span>|</span>
              <span className="chart-label mx-3" key={i}>
                {label}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};

const toHoursAndMinutes = (totalMinutes) => {
  const minutes = (totalMinutes % 60).toFixed(0);
  const hours = Math.floor(totalMinutes / 60);

  return `${padTo2Digits(hours)}H ${padTo2Digits(minutes)}M`;
};

export {
  NoOfDays,
  getHoursBetweenDates,
  getDatesBetweenDates,
  roundMinutes,
  TimelineRange,
  toHoursAndMinutes,
};
