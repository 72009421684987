import React, { useState, useEffect } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import Select from "react-select";
import VoyageService from "../../services/voyageService";
import VesselService from "../../services/vesselService";
import SailingService from "../../services/sailingService";
import FleetService from "../../services/fleetService";
import {
	NoOfDays,
	getHoursBetweenDates,
	TimelineRange,
	toHoursAndMinutes,
} from "./VoyageTimelineFunctions";
import { dateToYYYY_MM_DD } from "../../utils/utilityFunctions";
import "../styles/gantt.scss";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import lockImg from "../../images/lock.png";
import unlockImg from "../../images/unlock.png";
import cautionImg from "../../images/caution.png";
import nozzleImg from "../../images/nozzle.png";
import waterDropImg from "../../images/water-drop.png";

export const VoyageTimelineChart = () => {
	const [tick, setTick] = useState(150);
	const [hourTick, setHourTick] = useState(1200);
	const [startMarkForHourMode, setStartMarkForHourMode] = useState(
		new Date(new Date().getTime() - 2 * 60 * 60 * 1000)
	);
	const [startMarkForDayMode, setStartMarkForDayMode] = useState(
		new Date(new Date().getTime() - 8 * 60 * 60 * 1000)
	);
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const [totalRecords, setTotalRecords] = useState(null);
	const [timeFrom, setTimeFrom] = useState("");
	const [timeTo, setTimeTo] = useState("");
	const [timeFromForHourMode, setTimeFromForHourMode] = useState(
		new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString()
	);
	const [timeToForHourMode, setTimeToForHourMode] = useState(
		new Date(new Date().getTime() + 18 * 60 * 60 * 1000).toISOString()
	);
	const [timeFromForDayMode, setTimeFromForDayMode] = useState(
		new Date(new Date().getTime() - 8 * 60 * 60 * 1000).toISOString()
	);
	const [timeToForDayMode, setTimeToForDayMode] = useState(
		new Date(new Date().getTime() + 72 * 60 * 60 * 1000).toISOString()
	);
	const [voyageId, setVoyageId] = useState("");
	const [vesselSpecificationId, setVesselSpecificationId] = useState("");
	const [voyageRef, setVoyageRef] = useState("");
	const [voyageDetailsForSelection, setVoyageDetailsForSelection] = useState(
		[]
	);
	const [vessels, setVessels] = useState([]);
	const [vesselNames, setVesselNames] = useState([]);
	const [zoom, setZoom] = useState("day");
	const [schedules, setSchedules] = useState([]);
	const [poolId, setPoolId] = useState("");
	const [pools, setPools] = useState([]);

	useEffect(async () => {
		let _vessels = await VesselService.listAllVessels();
		_vessels = _vessels?.map((c) => ({
			...c,
			label: c.VesselName,
			value: c.VesselSpecificationId,
		}));
		setVessels(_vessels || []);

		let _pools = await FleetService.listFleet();
		_pools = _pools?.map((c) => ({
			...c,
			label: c.FleetName,
			value: c.FleetId,
		}));
		setPools(_pools || []);
		await init();
	}, []);

	const init = async (
		_page,
		_pageSize,
		_vesselSpecificationId = "",
		_timeFrom,
		_timeTo,
		_voyageId = "",
		_voyageRef = "",
		_poolId = ""
	) => {
		setLoading(true);
		_page = _page || page;
		_pageSize = _pageSize || pageSize;

		if (zoom == "day") {
			_timeFrom = _timeFrom || timeFromForDayMode;
			_timeTo = _timeTo || timeToForDayMode;

			let _vesselDetails = await VoyageService.getVoyageDashboardProperties(
				_page,
				_pageSize,
				_vesselSpecificationId,
				_timeFrom,
				_timeTo,
				_voyageId,
				_voyageRef,
				_poolId
			);
			setTotalRecords(_vesselDetails?.TotalCount);

			const _vesselVoyageDetails = _vesselDetails?.Data?.map((vessel) => ({
				...vessel,
				CurrentVesselName: vessel?.Vessels.VesselName,
				VoyagesForTimeline: vessel?.VoyagesInRange?.map((vesselVoyage) => ({
					...vesselVoyage.VoyagePlan,
					DelayDurationInMinutes: vesselVoyage.delayedDurationInMinutes,
					DelayStatus: vesselVoyage.isDelayed,
					ProductRequests: vesselVoyage.productRequests,
				}))?.map((voyage) => ({
					VesselName: vessel?.Vessels.VesselName || "",
					VoyageCode: voyage.VoyageCode || "",
					VoyagePlanningId: voyage.VoyagePlanningId || "",
					VoyagePlanningStatus: voyage.VoyagePlanningStatus || "",
					ExpectedDateOfDeparture: voyage.ExpectedDateOfDeparture || "",
					ExpectedDateOfArrival: voyage.ExpectedDateOfArrival || "",
					SailingId: voyage.SailingId || "",
					DelayDurationInMinutes: voyage.DelayDurationInMinutes,
					DelayStatus: voyage.DelayStatus,
					ProductRequests: voyage.ProductRequests,
				})),
			}));

			const _vesselNames = _vesselVoyageDetails?.map(
				(vessel) => vessel.CurrentVesselName
			);
			setVesselNames(_vesselNames);

			const _voyageDetailsLoop = _vesselVoyageDetails
				?.map((vessel) => vessel.VoyagesForTimeline)
				.flat(2)
				?.map((voyage) => {
					if (voyage.VoyagePlanningStatus == "Active") {
						return {
							...voyage,
							colorStatus: "current" || "",
						};
					} else if (voyage.VoyagePlanningStatus == "Planned") {
						return {
							...voyage,
							colorStatus: "future" || "",
						};
					} else if (
						voyage.VoyagePlanningStatus == "Completed" ||
						voyage.VoyagePlanningStatus == "Reviewed" ||
						voyage.VoyagePlanningStatus == "Finalized" ||
						voyage.VoyagePlanningStatus == "CostAllocated" ||
						voyage.VoyagePlanningStatus == "Invoiced"
					) {
						return {
							...voyage,
							colorStatus: "old" || "",
						};
					} else return "";
				});

			const _voyageDetailsForSelection = _voyageDetailsLoop?.map((voyage) => ({
				...voyage,
				startTime:
					voyage.ExpectedDateOfDeparture !== "" &&
					voyage.ExpectedDateOfDeparture <= _timeFrom
						? _timeFrom
						: voyage.ExpectedDateOfDeparture,
				endTime:
					voyage.ExpectedDateOfArrival !== "" &&
					voyage.ExpectedDateOfArrival >= _timeTo
						? _timeTo
						: voyage.ExpectedDateOfArrival,
				statusIcon: voyageIcons(voyage),
				delayIcon: delayImg(voyage),
				waterRequestIcon: waterRequestImg(voyage),
				fuelRequestIcon: fuelRequestImg(voyage),
			}));
			setVoyageDetailsForSelection(_voyageDetailsForSelection);

			if (
				_voyageDetailsForSelection &&
				_voyageDetailsForSelection.length !== 0
			) {
				// Min date
				const _dayMinDate = new Date(
					Math.min(
						..._voyageDetailsForSelection?.map(
							(e) => new Date(e.ExpectedDateOfDeparture)
						)
					)
				);
				const dayMinDate = new Date(_dayMinDate.getTime());

				const firstDay = new Date(
					dayMinDate.getFullYear(),
					dayMinDate.getMonth(),
					1
				).toISOString();
				const lastDay = new Date(
					dayMinDate.getFullYear(),
					dayMinDate.getMonth() + 1,
					0
				).toISOString();

				await retrieveSchedules(firstDay, lastDay);
			}

			page != _page && setPage(_page);
			pageSize != _pageSize && setPageSize(_pageSize);
			timeFromForDayMode != _timeFrom && setTimeFromForDayMode(_timeFrom);
			timeToForDayMode != _timeTo && setTimeToForDayMode(_timeTo);
			setVoyageId(_voyageId || "");
			setVesselSpecificationId(_vesselSpecificationId || "");
			setVoyageRef(_voyageRef || "");
		} else if (zoom == "hour") {
			_timeFrom = _timeFrom || timeFromForHourMode;
			_timeTo = _timeTo || timeToForHourMode;

			let _vesselDetails = await VoyageService.getVoyageDashboardProperties(
				_page,
				_pageSize,
				_vesselSpecificationId,
				_timeFrom,
				_timeTo,
				_voyageId,
				_voyageRef,
				_poolId
			);
			setTotalRecords(_vesselDetails?.TotalCount);

			const _vesselVoyageDetails = _vesselDetails?.Data?.map((vessel) => ({
				...vessel,
				CurrentVesselName: vessel?.Vessels.VesselName,
				VoyagesForTimeline: vessel?.VoyagesInRange?.map((vesselVoyage) => ({
					...vesselVoyage.VoyagePlan,
					DelayDurationInMinutes: vesselVoyage.delayedDurationInMinutes,
					DelayStatus: vesselVoyage.isDelayed,
					ProductRequests: vesselVoyage.productRequests,
				}))?.map((voyage) => ({
					VesselName: vessel?.Vessels.VesselName || "",
					VoyageCode: voyage.VoyageCode || "",
					VoyagePlanningId: voyage.VoyagePlanningId || "",
					VoyagePlanningStatus: voyage.VoyagePlanningStatus || "",
					ExpectedDateOfDeparture: voyage.ExpectedDateOfDeparture || "",
					ExpectedDateOfArrival: voyage.ExpectedDateOfArrival || "",
					SailingId: voyage.SailingId || "",
					DelayDurationInMinutes: voyage.DelayDurationInMinutes,
					DelayStatus: voyage.DelayStatus,
					ProductRequests: voyage.ProductRequests,
				})),
			}));

			const _vesselNames = _vesselVoyageDetails?.map(
				(vessel) => vessel.CurrentVesselName
			);
			setVesselNames(_vesselNames);

			const _voyageDetailsLoop = _vesselVoyageDetails
				?.map((vessel) => vessel.VoyagesForTimeline)
				.flat(2)
				?.map((voyage) => {
					if (voyage.VoyagePlanningStatus == "Active") {
						return {
							...voyage,
							colorStatus: "current",
						};
					} else if (voyage.VoyagePlanningStatus == "Planned") {
						return {
							...voyage,
							colorStatus: "future",
						};
					} else if (
						voyage.VoyagePlanningStatus == "Completed" ||
						voyage.VoyagePlanningStatus == "Reviewed" ||
						voyage.VoyagePlanningStatus == "Finalized" ||
						voyage.VoyagePlanningStatus == "CostAllocated" ||
						voyage.VoyagePlanningStatus == "Invoiced"
					) {
						return {
							...voyage,
							colorStatus: "old",
						};
					} else return "";
				});

			const _voyageDetailsForSelection = _voyageDetailsLoop?.map((voyage) => ({
				...voyage,
				startTime:
					voyage.ExpectedDateOfDeparture !== "" &&
					voyage.ExpectedDateOfDeparture <= _timeFrom
						? _timeFrom
						: voyage.ExpectedDateOfDeparture,
				endTime:
					voyage.ExpectedDateOfArrival !== "" &&
					voyage.ExpectedDateOfArrival >= _timeTo
						? _timeTo
						: voyage.ExpectedDateOfArrival,
				statusIcon: voyageIcons(voyage),
				delayIcon: delayImg(voyage),
				waterRequestIcon: waterRequestImg(voyage),
				fuelRequestIcon: fuelRequestImg(voyage),
			}));
			setVoyageDetailsForSelection(_voyageDetailsForSelection);

			if (
				_voyageDetailsForSelection &&
				_voyageDetailsForSelection.length !== 0
			) {
				// Min date
				const _dayMinDate = new Date(
					Math.min(
						..._voyageDetailsForSelection?.map(
							(e) => new Date(e.ExpectedDateOfDeparture)
						)
					)
				);
				const dayMinDate = new Date(_dayMinDate.getTime());

				const firstDay = new Date(
					dayMinDate.getFullYear(),
					dayMinDate.getMonth(),
					1
				).toISOString();
				const lastDay = new Date(
					dayMinDate.getFullYear(),
					dayMinDate.getMonth() + 1,
					0
				).toISOString();

				await retrieveSchedules(firstDay, lastDay);
			}

			page != _page && setPage(_page);
			pageSize != _pageSize && setPageSize(_pageSize);
			timeFromForHourMode != _timeFrom && setTimeFromForHourMode(_timeFrom);
			timeToForHourMode != _timeTo && setTimeToForHourMode(_timeTo);
			setVoyageId(_voyageId || "");
			setVesselSpecificationId(_vesselSpecificationId || "");
			setVoyageRef(_voyageRef || "");
		}

		setLoading(false);
	};

	const voyageIcons = (voyage) => {
		if (
			voyage.VoyagePlanningStatus == "Completed" ||
			voyage.VoyagePlanningStatus == "Reviewed"
		) {
			return <img src={unlockImg} alt="unlock_img" />;
		} else if (voyage.VoyagePlanningStatus == "Finalized") {
			return <img src={lockImg} alt="lock_img" />;
		} else return null;
	};

	const delayImg = (voyage) => {
		if (voyage.DelayStatus == true) {
			return (
				<div>
					<ReactTooltip
						className="text-center"
						border
						id={`caution ${voyage.VoyageCode}`}>
						<small className="d-block my-1">
							ETD: {moment(voyage.ExpectedDateOfDeparture).format("llll")}
						</small>
						<small className="d-block my-1">
							ETA: {moment(voyage.ExpectedDateOfArrival).format("llll")}
						</small>
						<small className="d-block mt-2">
							Delay: {toHoursAndMinutes(voyage.DelayDurationInMinutes)}
						</small>
					</ReactTooltip>
					<a
						className=""
						data-toggle="tooltip"
						data-placement="top"
						data-original-title=""
						data-tip
						data-for={`caution ${voyage.VoyageCode}`}>
						<img src={cautionImg} alt="caution_img" />
					</a>
				</div>
			);
		} else return null;
	};

	// [{productId, requestType, dateOfRequest, productName}]{`lock ${i + v.VoyageCode}`}

	const waterRequestImg = (voyage) => {
		const waterBasedProducts = voyage.ProductRequests?.filter(
			(product) => product.requestTypeDescription == "water_request"
		);
		if (waterBasedProducts && waterBasedProducts.length !== 0) {
			return (
				<div>
					<ReactTooltip
						border
						className="text-center"
						id={`waterdrop ${voyage.VoyageCode}`}>
						{waterBasedProducts?.map((product, i) => (
							<small className="d-block mt-2" key={i}>{`${i + 1} - ${
								product.Quantity
							}${product.measurementUnitSymbol} of ${
								product.productName
							}`}</small>
						))}
					</ReactTooltip>
					<a
						className=""
						data-toggle="tooltip"
						data-placement="top"
						data-original-title=""
						data-tip
						data-for={`waterdrop ${voyage.VoyageCode}`}>
						<img src={waterDropImg} alt="waterdrop_img" />
					</a>
				</div>
			);
		} else if (waterBasedProducts && waterBasedProducts.length == 0)
			return null;
	};

	const fuelRequestImg = (voyage) => {
		const fuelBasedProducts = voyage.ProductRequests?.filter(
			(product) => product.requestTypeDescription == "fuel_request_fuel_oil"
		);
		if (fuelBasedProducts && fuelBasedProducts.length !== 0) {
			return (
				<div>
					<ReactTooltip
						border
						className="text-center"
						id={`nozzle ${voyage.VoyageCode}`}>
						{fuelBasedProducts?.map((product, i) => (
							<small className="d-block mt-2" key={i}>{`${i + 1} - ${
								product.Quantity
							}${product.measurementUnitSymbol} of ${
								product.productName
							}`}</small>
						))}
					</ReactTooltip>
					<a
						className=""
						data-toggle="tooltip"
						data-placement="top"
						data-original-title=""
						data-tip
						data-for={`nozzle ${voyage.VoyageCode}`}>
						<img src={nozzleImg} alt="nozzle_img" />
					</a>
				</div>
			);
		} else if (fuelBasedProducts && fuelBasedProducts.length == 0) return null;
	};

	const retrieveSchedules = async (_startDate, _endDate) => {
		if (!_startDate) {
			_startDate = dateToYYYY_MM_DD(new Date());
		}

		if (!_endDate) {
			const dateNow = new Date();
			const _currentMonth = dateNow.getMonth() + 1;
			const _currentYear = dateNow.getFullYear();
			_endDate = dateToYYYY_MM_DD(new Date(_currentYear, _currentMonth, 0));
		}

		const _schedules = await SailingService.getSchedule2(_startDate, _endDate);
		if (!_schedules || _schedules == null) {
			setSchedules([]);
		} else {
			setSchedules(_schedules);
		}
	};

	const _hourRangeForHourMode = getHoursBetweenDates(
		new Date(timeFromForHourMode),
		new Date(timeToForHourMode)
	);

	// Taking only the i+2 indexes for hours
	const newHourRangeForHourMode = [];
	for (let i = 0; i < _hourRangeForHourMode.length; i = i + 2) {
		newHourRangeForHourMode.push(_hourRangeForHourMode[i]);
	}

	const hourRangeForHourMode = newHourRangeForHourMode?.map(
		(hour) => `${hour.result1} ${hour.result}`
	);

	const _hourRangeForDayMode = getHoursBetweenDates(
		new Date(timeFromForDayMode),
		new Date(timeToForDayMode)
	);

	// Taking only the i+8 indexes for hours
	const newHourRangeForDayMode = [];
	for (let i = 0; i < _hourRangeForDayMode.length; i = i + 8) {
		newHourRangeForDayMode.push(_hourRangeForDayMode[i]);
	}

	const hourRangeForDayMode = newHourRangeForDayMode?.map(
		(hour) => `${hour.result1} ${hour.result}`
	);

	const pageLimiter = Math.ceil(totalRecords / pageSize);

	// Endpoint vessel data filter

	const prevLoaded = async () => {
		if (pageLimiter > 1 && page <= pageLimiter) {
			await init(
				page - 1,
				null
				// vesselSpecificationId,
				// timeFrom,
				// timeTo,
				// voyageId,
				// voyageRef,
				// poolId,
			);
		}
	};

	const nextLoaded = async () => {
		if (pageLimiter !== 1 && page < pageLimiter) {
			await init(
				page + 1,
				null
				// vesselSpecificationId,
				// timeFrom,
				// timeTo,
				// voyageId,
				// voyageRef,
				// poolId,
			);
		}
	};

	const goToPage = async () => {
		await init(
			page,
			null
			// vesselSpecificationId,
			// timeFrom,
			// timeTo,
			// voyageId,
			// voyageRef,
			// poolId,
		);
	};

	const filterByDashboardProperty = async () => {
		await init(
			1,
			null,
			vesselSpecificationId,
			timeFrom,
			timeTo,
			voyageId,
			voyageRef,
			poolId
		);
	};

	const clearFilter = async () => {
		const _timeFromForDay = new Date(
			new Date().getTime() - 8 * 60 * 60 * 1000
		).toISOString();
		const _timeToForDay = new Date(
			new Date().getTime() + 72 * 60 * 60 * 1000
		).toISOString();
		const _timeFromForHour = new Date(
			new Date().getTime() - 2 * 60 * 60 * 1000
		).toISOString();
		const _timeToForHour = new Date(
			new Date().getTime() + 18 * 60 * 60 * 1000
		).toISOString();

		setZoom("day");
		setTimeFromForDayMode(_timeFromForDay);
		setTimeToForDayMode(_timeToForDay);
		setTimeFromForHourMode(_timeFromForHour);
		setTimeToForHourMode(_timeToForHour);
		setVoyageId("");
		setVesselSpecificationId("");
		setVoyageRef("");
		setPoolId("");

		await init(1, null, "", _timeFromForDay, _timeToForDay);
	};

	// Set timeline day/hour zoomer

	const zoomOutToDays = async () => {
		const zoomMode = "day";
		setZoom(zoomMode);
		const _timeFromForDay = new Date(
			new Date().getTime() - 8 * 60 * 60 * 1000
		).toISOString();
		const _timeToForDay = new Date(
			new Date().getTime() + 72 * 60 * 60 * 1000
		).toISOString();
		setTimeFromForDayMode(_timeFromForDay);
		setTimeToForDayMode(_timeToForDay);

		await init(
			1,
			null,
			vesselSpecificationId,
			_timeFromForDay,
			_timeToForDay,
			voyageId,
			voyageRef,
			poolId
		);
	};

	const zoomInToHours = async () => {
		const zoomMode = "hour";
		setZoom(zoomMode);
		const _timeFromForHour = new Date(
			new Date().getTime() - 2 * 60 * 60 * 1000
		).toISOString();
		const _timeToForHour = new Date(
			new Date().getTime() + 18 * 60 * 60 * 1000
		).toISOString();
		setTimeFromForHourMode(_timeFromForHour);
		setTimeToForHourMode(_timeToForHour);

		await init(
			1,
			null,
			vesselSpecificationId,
			_timeFromForHour,
			_timeToForHour,
			voyageId,
			voyageRef,
			poolId
		);
	};

	const flipTimeForward = async () => {
		if (zoom == "day") {
			const timeFromForDay = new Date(
				new Date(timeFromForDayMode).getTime() + 80 * 60 * 60 * 1000
			).toISOString();
			const timeToForDay = new Date(
				new Date(timeToForDayMode).getTime() + 80 * 60 * 60 * 1000
			).toISOString();
			setTimeFromForDayMode(timeFromForDay);
			setTimeToForDayMode(timeToForDay);
			await init(
				page,
				pageSize,
				vesselSpecificationId,
				timeFromForDay,
				timeToForDay,
				voyageId,
				voyageRef,
				poolId
			);
		} else if (zoom == "hour") {
			const timeFromForHour = new Date(
				new Date(timeFromForHourMode).getTime() + 20 * 60 * 60 * 1000
			).toISOString();
			const timeToForHour = new Date(
				new Date(timeToForHourMode).getTime() + 20 * 60 * 60 * 1000
			).toISOString();
			setTimeFromForHourMode(timeFromForHour);
			setTimeToForHourMode(timeToForHour);
			await init(
				page,
				pageSize,
				vesselSpecificationId,
				timeFromForHour,
				timeToForHour,
				voyageId,
				voyageRef,
				poolId
			);
		}
	};

	const flipTimeBackward = async () => {
		if (zoom == "day") {
			const timeFromForDay = new Date(
				new Date(timeFromForDayMode).getTime() - 80 * 60 * 60 * 1000
			).toISOString();
			const timeToForDay = new Date(
				new Date(timeToForDayMode).getTime() - 80 * 60 * 60 * 1000
			).toISOString();
			setTimeFromForDayMode(timeFromForDay);
			setTimeToForDayMode(timeToForDay);
			await init(
				page,
				pageSize,
				vesselSpecificationId,
				timeFromForDay,
				timeToForDay,
				voyageId,
				voyageRef,
				poolId
			);
		} else if (zoom == "hour") {
			const timeFromForHour = new Date(
				new Date(timeFromForHourMode).getTime() - 20 * 60 * 60 * 1000
			).toISOString();
			const timeToForHour = new Date(
				new Date(timeToForHourMode).getTime() - 20 * 60 * 60 * 1000
			).toISOString();
			setTimeFromForHourMode(timeFromForHour);
			setTimeToForHourMode(timeToForHour);
			await init(
				page,
				pageSize,
				vesselSpecificationId,
				timeFromForHour,
				timeToForHour,
				voyageId,
				voyageRef,
				poolId
			);
		}
	};

	const retrieveActiveVoyage = (vessel) => {
		const voyage = voyageDetailsForSelection.find(
			(voyage) =>
				voyage.VesselName == vessel && voyage.VoyagePlanningStatus == "Active"
		);

		if (voyage) {
			return voyage.VoyageCode;
		} else return "None";
	};

	return (
		<>
			{loading ? (
				<h4 className="w-100 text-center center my-5 py-5">Loading...</h4>
			) : (
				<>
					<div style={{ zoom: "90%" }}>
						<div className="card-inner position-relative card-tools-toggle">
						<h5 className="text-center pt-3 pb-0 pl-1">
							Voyage Timeline 
						</h5>
							{
								<div className="card-title-group">
									<div className="card-tools ml-n1">
										<ul className="btn-toolbar gx-1">
											<li>
												<div className="dropdown">
													<a
														href="#"
														className="btn btn-trigger btn-icon dropdown-toggle "
														data-toggle="dropdown">
														<em className="icon ni ni-filter-alt" />
													</a>
													<div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
														<form style={{ zoom: "78%" }}>
															<div className="dropdown-head">
																<span className="sub-title dropdown-title">
																	Filter Voyage Chart
																</span>
																<div className="dropdown">
																	<a
																		className="btn btn-sm btn-icon"
																		data-toggle="dropdown">
																		<em
																			className="icon ni ni-cross"
																			title="close"
																		/>
																	</a>
																</div>
															</div>
															<div className="dropdown-body dropdown-body-rg">
																<div className="row gx-6 gy-3">
																	<div className="col-12">
																		<div
																			className="form-group"
																			onClick={(e) => e.stopPropagation()}>
																			<label className="overline-title overline-title-alt">
																				Vessel
																			</label>
																			<Select
																				className="border-transparent"
																				placeholder="Filter by Vessel"
																				value={vessels.find(
																					(vessel) =>
																						vessel.value ===
																						vesselSpecificationId
																				)}
																				options={vessels}
																				onChange={(option) =>
																					setVesselSpecificationId(option.value)
																				}
																			/>
																		</div>
																	</div>

																	<div className="col-12">
																		<div
																			className="form-group"
																			onClick={(e) => e.stopPropagation()}>
																			<label className="overline-title overline-title-alt">
																				Pool
																			</label>
																			<Select
																				className="border-transparent"
																				placeholder="Filter by Pool"
																				value={pools.find(
																					(pool) => pool.value === poolId
																				)}
																				options={pools}
																				onChange={(option) =>
																					setPoolId(option.value)
																				}
																			/>
																		</div>
																	</div>

																	<div className="col-12">
																		<div className="form-group">
																			<label className="overline-title overline-title-alt">
																				Voyage Code
																			</label>
																			<input
																				type="text"
																				className="form-control "
																				placeholder="Enter Voyage Code"
																				id="voyage-ref-filter"
																				value={voyageRef}
																				onChange={(e) =>
																					setVoyageRef(e.target.value)
																				}
																			/>
																		</div>
																	</div>

																	{/* <div className="col-6">
                                    <div className="form-group">
                                      <label
                                        className="overline-title overline-title-alt"
                                        htmlFor="from-date-filter"
                                      >
                                        Start Time
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control "
                                        placeholder=""
                                        id="from-date-filter"
                                        value={timeFrom}
                                        onChange={(e) =>
                                          setTimeFrom(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-6">
                                    <div className="form-group">
                                      <label
                                        className="overline-title overline-title-alt"
                                        htmlFor="to-date-filter"
                                      >
                                        End Time
                                      </label>
                                      <input
                                        type="date"
                                        className="form-control "
                                        placeholder=""
                                        id="to-date-filter"
                                        value={timeTo}
                                        onChange={(e) =>
                                          setTimeTo(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div> */}

																	<div className="gap gap-10px" />

																	<div className="col-12">
																		<div className="form-group">
																			<button
																				type="button"
																				onClick={filterByDashboardProperty}
																				className="btn btn-secondary"
																				style={{
																					backgroundColor: "midnightblue",
																				}}>
																				<span>Filter</span>
																			</button>
																			<a
																				className="clickable ml-2"
																				onClick={() => clearFilter()}>
																				Reset Filter
																			</a>
																		</div>
																	</div>
																	<div className="gap gap-20px" />
																</div>
															</div>
														</form>
													</div>
												</div>
											</li>
											<li className="btn-toolbar-sep" />
											<li>
												<span
													className={`badge badge-pill ml-3 badge-primary `}>
													{zoom == "day" ? "Day" : "Hour"}
												</span>
												<span
													onClick={
														zoom == "day" ? zoomInToHours : zoomOutToDays
													}>
													<em
														className={`icon ni ml-1 pointer align-middle fs-22px  ${
															zoom == "day"
																? "ni-toggle-off text-primary"
																: "ni-toggle-on text-primary"
														}`}
														title={
															zoom == "day"
																? "Switch to hour mode"
																: "Switch to day mode"
														}
													/>
												</span>
											</li>
											<li className="btn-toolbar-sep" />
											<li>
												<button
													className="btn text-success btn-sm"
													role="button"
													onClick={clearFilter}>
													<span>Refresh</span>
												</button>
											</li>
										</ul>
									</div>
									{pageLimiter > 1 && (
										<div className="card-tools mr-n1">
											<ul className="btn-toolbar gx-1">
												<li>
													<button
														className="btn btn-trigger btn-icon "
														onClick={page !== 1 ? prevLoaded : null}
														disabled={page == 1 ? true : false}
														title="Previous vessels">
														<em className="icon ni ni-arrow-left" />
													</button>
												</li>
												<li className="btn-toolbar-sep" />
												<li>
													<button
														className="btn btn-trigger btn-icon "
														onClick={page >= 1 ? nextLoaded : null}
														disabled={page >= pageLimiter ? true : false}
														title="Next vessels">
														<em className="icon ni ni-arrow-right" />
													</button>
												</li>
												<li className="btn-toolbar-sep" />
												<li>
													<div className="g" data-select2-id={6}>
														<div
															className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3"
															data-select2-id={5}>
															<div>
																<input
																	type="number"
																	className="form-control border search-input text-align-center"
																	placeholder=""
																	onChange={(e) => setPage(e.target.value)}
																	value={page}
																	style={{ width: "50px" }}
																/>
															</div>
															<div>of {` ${pageLimiter}`}</div>
															<button
																className="btn text-success btn-sm"
																role="button"
																onClick={goToPage}>
																<span>Go</span>
															</button>
														</div>
													</div>
												</li>
											</ul>
										</div>
									)}
								</div>
							}
						</div>
						{vesselNames.length == 0 ? (
							<h4 className="w-100 text-center center my-5 py-5">
								No data available for timeline chart
							</h4>
						) : (
							<>
								{zoom == "day" ? (
									<>
										<div
											className="voyage-gantt chart-container disappearing-scrollbar"
											style={{ overflowX: "scroll", overflowY: "hidden" }}>
											<TimelineRange
												width={tick}
												range={hourRangeForDayMode}
												noOfIntervals={10}
											/>
											<div className="position-relative">
												<div className="gap gap-5px" />
												{vesselNames?.map((vessel, i) => (
													<div className="row" key={i}>
														<div
															className="col-2 text-center"
															style={{ width: "150px", fontWeight: "bold" }}>
															{vessel}
															<small
																style={{
																	fontStyle: "italic",
																	fontWeight: "bold",
																}}>
																{" "}
																{`(${retrieveActiveVoyage(vessel)})`}
															</small>
														</div>
														<div className="col-10">
															<div
																style={{
																	width: `${tick * 10}px`,
																	position: "relative",
																}}>
																{timeFromForDayMode.localeCompare(
																	new Date().toISOString()
																) <= 0 &&
																timeToForDayMode.localeCompare(
																	new Date().toISOString()
																) >= 0 ? (
																	<div
																		className="vertical-line"
																		title="Present"
																		style={{
																			left: `${(tick + 2) * 0.063}rem`,
																			height: "20rem",
																		}}></div>
																) : null}
																{voyageDetailsForSelection
																	?.filter((l) => l.VesselName == vessel)
																	?.map((v, index) => (
																		<>
																			<ReactTooltip
																				className="text-center"
																				id={`lock ${i + v.VoyageCode}`}>
																				<small className="d-block mt-2">
																					Voyage number: {v.VoyageCode}
																				</small>
																				<small className="d-block my-1">
																					Day of sailing:{" "}
																					{moment(
																						v.ExpectedDateOfDeparture
																					).format("llll")}
																				</small>
																				<small className="d-block my-1">
																					Return in port :{" "}
																					{moment(
																						v.ExpectedDateOfArrival
																					).format("llll")}
																				</small>
																				<small className="d-block mt-2">
																					Sailing route:{" "}
																					{
																						schedules.find(
																							(sailing) =>
																								sailing.SailingId == v.SailingId
																						).SailingRoute
																					}
																				</small>
																				<small className="d-block my-1">
																					Chart range :{" "}
																					{moment(v.startTime).format(
																						"DD-MM-YYYY HH:mm:ss"
																					)}{" "}
																					-{" "}
																					{moment(v.endTime).format(
																						"DD-MM-YYYY HH:mm:ss"
																					)}
																				</small>
																			</ReactTooltip>
																			<a
																				href={`/voyage-activities/${v.VoyagePlanningId}`}
																				className="voyage-tooltip"
																				style={{
																					left: `${
																						NoOfDays(
																							new Date(timeFromForDayMode),
																							new Date(v.startTime)
																						) *
																						tick *
																						3
																					}px`,
																					position: "absolute",
																				}}>
																				<span
																					className={`gt-timeline ${v.colorStatus}-voyage`}
																					style={{
																						width: `${
																							NoOfDays(
																								new Date(v.startTime),
																								new Date(v.endTime)
																							) *
																							tick *
																							3
																						}px`,
																					}}
																					data-toggle="tooltip"
																					data-placement="top"
																					data-original-title=""
																					data-tip
																					data-for={`lock ${i + v.VoyageCode}`}>
																					<div
																						className="text-center"
																						style={{
																							color: "white",
																							fontFamily: "Candara",
																							fontSize: 16,
																							fontWeight: "bold",
																						}}>
																						{v.VoyageCode}
																					</div>
																				</span>
																			</a>
																		</>
																	))}
															</div>
														</div>
														<div className="gap gap-5px" />
													</div>
												))}
											</div>
											<TimelineRange
												width={tick}
												range={hourRangeForDayMode}
												noOfIntervals={10}
											/>
										</div>
									</>
								) : (
									<>
										<div
											className="voyage-gantt chart-container disappearing-scrollbar"
											style={{ overflowX: "scroll", overflowY: "hidden" }}>
											<TimelineRange
												width={tick}
												range={hourRangeForHourMode}
												noOfIntervals={10}
											/>
											<div className="position-relative">
												<div className="gap gap-5px" />
												{vesselNames?.map((vessel, i) => (
													<div className="row" key={i}>
														<div
															className="col-2 text-center"
															style={{ width: "150px", fontWeight: "bold" }}>
															{vessel}
															<small
																style={{
																	fontStyle: "italic",
																	fontWeight: "bold",
																}}>
																{" "}
																{`(${retrieveActiveVoyage(vessel)})`}
															</small>
														</div>
														<div className="col-10">
															<div
																style={{
																	width: `${tick * 10}px`,
																	position: "relative",
																}}>
																{timeFromForHourMode.localeCompare(
																	new Date().toISOString()
																) <= 0 &&
																timeToForHourMode.localeCompare(
																	new Date().toISOString()
																) >= 0 ? (
																	<div
																		className="vertical-line"
																		title="Present"
																		style={{
																			left: `${(tick + 2) * 0.063}rem`,
																			height: "20rem",
																		}}></div>
																) : null}
																{voyageDetailsForSelection
																	?.filter((l) => l.VesselName == vessel)
																	?.map((v, index) => (
																		<>
																			<ReactTooltip
																				className="text-center"
																				id={`lock ${i + v.VoyageCode}`}>
																				<small className="d-block mt-2">
																					Voyage number: {v.VoyageCode}
																				</small>
																				<small className="d-block my-1">
																					Day of sailing:{" "}
																					{moment(
																						v.ExpectedDateOfDeparture
																					).format("llll")}
																				</small>
																				<small className="d-block my-1">
																					Return in port :{" "}
																					{moment(
																						v.ExpectedDateOfArrival
																					).format("llll")}
																				</small>
																				<small className="d-block mt-2">
																					Sailing route:{" "}
																					{
																						schedules?.find(
																							(sailing) =>
																								sailing.SailingId == v.SailingId
																						).SailingRoute
																					}
																				</small>
																				<small className="d-block my-1">
																					Chart range :{" "}
																					{moment(v.startTime).format(
																						"DD-MM-YYYY HH:mm:ss"
																					)}{" "}
																					-{" "}
																					{moment(v.endTime).format(
																						"DD-MM-YYYY HH:mm:ss"
																					)}
																				</small>
																			</ReactTooltip>
																			<a
																				href={`/voyage-activities/${v.VoyagePlanningId}`}
																				style={{
																					left: `${
																						NoOfDays(
																							new Date(timeFromForHourMode),
																							new Date(v.startTime)
																						) *
																						tick *
																						12
																					}px`,
																					position: "absolute",
																				}}>
																				<span
																					className={`gt-timeline ${v.colorStatus}-voyage`}
																					style={{
																						width: `${
																							NoOfDays(
																								new Date(v.startTime),
																								new Date(v.endTime)
																							) *
																							tick *
																							12
																						}px`,
																					}}
																					data-toggle="tooltip"
																					data-placement="top"
																					data-original-title=""
																					data-tip
																					data-for={`lock ${i + v.VoyageCode}`}>
																					<div
																						className="text-center"
																						style={{
																							color: "white",
																							fontFamily: "Candara",
																							fontSize: 16,
																							fontWeight: "bold",
																						}}>
																						{v.VoyageCode}
																						<div className="col-2 float-right">
																							<div className="row">
																								<div className="col-3">
																									{v.statusIcon}
																								</div>
																								<div className="col-3">
																									{v.fuelRequestIcon}
																								</div>
																								<div className="col-3">
																									{v.waterRequestIcon}
																								</div>
																								<div className="col-3">
																									{v.delayIcon}
																								</div>
																							</div>
																						</div>
																					</div>
																				</span>
																			</a>
																		</>
																	))}
															</div>
														</div>
														<div className="gap gap-5px" />
													</div>
												))}
											</div>
											<TimelineRange
												width={tick}
												range={hourRangeForHourMode}
												noOfIntervals={10}
											/>
										</div>
									</>
								)}
							</>
						)}
						{zoom == "day" ? (
							<>
								<div className="gap gap-20px" />
								<div className="row">
									<div className="col-6 text-left">
										<button
											className="btn btn-lg text-success"
											onClick={flipTimeBackward}>
											<em className="icon ni ni-arrow-left" />
											<span> Past 80 hrs</span>
										</button>
									</div>
									<div className="col-6 text-right">
										<button
											className="btn btn-lg text-success"
											onClick={flipTimeForward}>
											<span> Next 80 hrs</span>
											<em className="icon ni ni-arrow-right" />
										</button>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="gap gap-20px" />
								<div className="row">
									<div className="col-6 text-left">
										<button
											className="btn btn-lg text-success"
											onClick={flipTimeBackward}>
											<em className="icon ni ni-arrow-left" />
											<span> Past 20 hrs</span>
										</button>
									</div>
									<div className="col-6 text-right">
										<button
											className="btn btn-lg text-success"
											onClick={flipTimeForward}>
											<span> Next 20 hrs</span>
											<em className="icon ni ni-arrow-right" />
										</button>
									</div>
								</div>
							</>
						)}
					</div>
				</>
			)}
		</>
	);
};
