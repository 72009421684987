import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import {
	camelizeKeys,
	toReadableDate,
	toReadableDateTime,
} from "../../utils/utilityFunctions";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import RoleService from "../../services/roleService";
import UserService from "../../services/userService";
import VesselService from "../../services/vesselService";
import LocationService from "../../services/locationService";

export default function UserListPage() {
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(true);
	const [pages, setPages] = useState([10, 20, 50, 100, 150]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [totalRecords, setTotalRecords] = useState(10);
	const [filter, setFilter] = useState(null);
	const [vessels, setVessels] = useState([]);
	const [users, setUsers] = useState([]);
	const [userId, setUserId] = useState("");
	const [userName, setUserName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

	useEffect(async () => {
		setLoading(true);

		await loadData();

		setLoading(false);
	}, []);

	const init = async () => {};

	const loadData = async (
		_page,
		_pageSize,
		_userId = "",
		_userName = "",
		_firstName = "",
		_lastName = "",
		// _startDate = "",
		// _endDate = "",
		_phoneNumber = "",
		_email = ""
	) => {
		setLoading(true);
		_page = _page || page;
		_pageSize = _pageSize || pageSize;
		_userId = _userId;
		_userName = _userName;
		_firstName = _firstName;
		_lastName = _lastName;
		// _startDate = _startDate;
		// _endDate = _endDate;
		_phoneNumber = _phoneNumber;
		_email = _email;

		const _users = await UserService.getSearchableUserList(
			_page,
			_pageSize,
			_userId,
			_userName,
			_firstName,
			_lastName,
			// _startDate,
			// _endDate,
			_phoneNumber,
			_email
		);
		let modifiedDataList = camelizeKeys(_users?.res || []);
		setTotalRecords(_users?.TotalCount || 0);
		modifiedDataList = modifiedDataList.map((user) => ({
			...user,
			editButton: editButton(user),
			userAccessButton: userAccessButton(user),
		}));

		setUsers(modifiedDataList);

		page != _page && setPage(_page);
		pageSize != _pageSize && setPageSize(_pageSize);
		// setStartDate(_startDate || "");
		// setEndDate(_endDate || "");

		setLoading(false);
	};

	const editButton = (user) => {
		return (
			<>
				<ReactTooltip id="edit">Edit User</ReactTooltip>
				<a
					href={`/edit-user-details/${user.id}`}
					className="btn btn-sm btn-primary active"
					data-toggle="tooltip"
					data-placement="top"
					data-original-title="Edit User"
					data-tip
					data-for="edit">
					Edit Details
				</a>
			</>
		);
	};

	const userAccessButton = (user) => {
		const _payload = {
			userId: user.id,
			lastModifiedBy: JSON.parse(localStorage.resu)?.user?.Id,
		};

		const blockUserAccess = async () => {
			setLoading(true);
			let response = await UserService.blockUser(_payload);
			if (response) {
				toastr("success", "User access blocked successfully");
				await loadData();
			} else {
				toastr("error", "User access could not be blocked");
			}
			setLoading(false);
		};

		const allowUserAccess = async () => {
			setLoading(true);
			let response = await UserService.unBlockUser(_payload);
			if (response) {
				toastr("success", "User granted access successfully");
				await loadData();
			} else {
				toastr("error", "User could not be granted access");
			}
			setLoading(false);
		};
		return (
			<>
				{user.lockoutEnabled == true ? (
					<>
						<button
							className="btn btn-sm btn-info"
							onClick={allowUserAccess}
							title="Allow user access">
							Allow
						</button>
					</>
				) : (
					<>
						<button
							className="btn btn-sm btn-warning"
							onClick={blockUserAccess}
							title="Block user access">
							Block
						</button>
					</>
				)}
			</>
		);
	};

	const headerList = [
		{
			Header: "First Name",
			accessor: "firstName",
		},
		{
			Header: "Last Name",
			accessor: "lastName",
		},
		{
			Header: "Phone",
			accessor: "phoneNumber",
		},
		{
			Header: "E-mail",
			accessor: "email",
		},
		{
			Header: "Edit User",
			accessor: "editButton",
		},
		{
			Header: "User Access",
			accessor: "userAccessButton",
		},
	];

	const exportPage = () => {
		if (!users.length) return;
		const exportData = users.map((user) => {
			const exportObject = {
				"First Name": user.firstName,
				"Last Name": user.lastName,
				"E-mail": user.email,
				"Phone Number": user.phoneNumber,
			};
			return exportObject;
		});
		exportToCSV(exportData, "list-of-users");
	};

	const filterByUserProperty = async () => {
		await loadData(
			1,
			null,
			null,
			null,
			firstName,
			null,
			startDate,
			endDate,
			phoneNumber,
			email
		);
	};

	const clearFilter = async () => {
		setFirstName("");
		// setStartDate("");
		// setEndDate("");
		setPhoneNumber("");
		setEmail("");
		// setLastName("");

		await loadData(1, null);
	};

	const pageActions = (
		<div className="toggle-wrap nk-block-tools-toggle">
			<a
				href="#"
				className="btn btn-icon btn-trigger toggle-expand mr-n1"
				data-target="pageMenu">
				<em className="icon ni ni-menu-alt-r" />
			</a>
			<div className="toggle-expand-content" data-content="pageMenu">
				<ul className="nk-block-tools g-3">
					{/* <li>
            <a
              // href={`${process.env.REACT_APP_API}/api/Commercials?page=${page}&pageSize=${pageSize}`}
              // target="_blank"
              className="btn btn-dim btn-secondary disabled"
            >
              <em className="icon ni ni-download" />
              <span>Download Users</span>
            </a>
          </li>
          {users && (
            <li>
              <button
                type="button"
                onClick={exportPage}
                className="btn btn-white btn-outline-light"
              >
                <em className="icon ni ni-download-cloud" />
                <span>Export page</span>
              </button>
            </li>
          )} */}

					<li className="nk-block-tools-opt">
						<div className="drodown">
							<a
								href="#"
								className="dropdown-toggle btn btn-icon btn-primary"
								data-toggle="dropdown">
								<em className="icon ni ni-plus" />
							</a>
							<div className="dropdown-menu dropdown-menu-right">
								<ul className="link-list-opt no-bdr">
									<li>
										<Link to="/add-user">
											<span>Add User</span>
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);

	return redirectUrl ? (
		<Navigate to={redirectUrl} replace={true} />
	) : (
		<MainLayout
			title="Users"
			pageActions={pageActions}
			loading={loading}
			showFullLoader={loading}>
			<div className="table-component">
				<div className="card-inner-group">
					{
						<div className="card-inner position-relative card-tools-toggle">
							<div className="card-title-group">
								<div className="card-tools">
									<div className="dropdown">
										<a
											href="#"
											className="btn btn-trigger btn-icon dropdown-toggle "
											data-toggle="dropdown">
											<em className="icon ni ni-search" />
										</a>
										<div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
											<form>
												<div className="dropdown-head">
													<span className="sub-title dropdown-title">
														Filter Users
													</span>
													<div className="dropdown">
														<a
															className="btn btn-sm btn-icon"
															data-toggle="dropdown">
															<em className="icon ni ni-cross" title="close" />
														</a>
													</div>
												</div>
												<div className="dropdown-body dropdown-body-rg">
													<div className="row gx-6 gy-3">
														<div className="col-12">
															<div className="form-group">
																<label
																	className="overline-title overline-title-alt"
																	htmlFor="from-date-filter">
																	First Name
																</label>
																<input
																	type="text"
																	className="form-control "
																	placeholder=""
																	value={firstName}
																	onChange={(e) => setFirstName(e.target.value)}
																/>
															</div>
														</div>

														{/* <div className="col-6">
                              <div className="form-group">
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="from-date-filter"
                                >
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  placeholder=""
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                />
                              </div>
                            </div> */}

														<div className="col-12">
															<div className="form-group">
																<label
																	className="overline-title overline-title-alt"
																	htmlFor="from-date-filter">
																	Email
																</label>
																<input
																	type="text"
																	className="form-control "
																	placeholder=""
																	value={email}
																	onChange={(e) => setEmail(e.target.value)}
																/>
															</div>
														</div>

														<div className="col-12">
															<div className="form-group">
																<label
																	className="overline-title overline-title-alt"
																	htmlFor="from-date-filter">
																	Phone Number
																</label>
																<input
																	type="text"
																	className="form-control "
																	placeholder=""
																	value={phoneNumber}
																	onChange={(e) =>
																		setPhoneNumber(e.target.value)
																	}
																/>
															</div>
														</div>
														{/* 
                            <div className="col-6">
                              <div className="form-group">
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="from-date-filter"
                                >
                                  Start Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control "
                                  placeholder=""
                                  id="from-date-filter"
                                  value={startDate}
                                  onChange={(e) => setStartDate(e.target.value)}
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="form-group">
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="to-date-filter"
                                >
                                  End Date
                                </label>
                                <input
                                  type="date"
                                  className="form-control "
                                  placeholder=""
                                  id="to-date-filter"
                                  value={endDate}
                                  onChange={(e) => setEndDate(e.target.value)}
                                />
                              </div>
                            </div> */}

														<div className="gap gap-10px" />

														<div className="col-12">
															<div className="form-group">
																<button
																	type="button"
																	onClick={filterByUserProperty}
																	className="btn btn-secondary">
																	<span>Filter</span>
																</button>
																<a
																	className="clickable ml-2"
																	onClick={() => clearFilter()}>
																	Reset Filter
																</a>
															</div>
														</div>
														<div className="gap gap-50px" />
													</div>
												</div>
											</form>
										</div>
									</div>
									{/* .form-inline */}
								</div>

								<div className="card-tools mr-n1">
									<ul className="btn-toolbar gx-1">
										<li className="btn-toolbar-sep" />
										<li>
											<div className="toggle-wrap">
												<a
													href="#"
													className="btn btn-icon btn-trigger toggle"
													data-target="cardTools">
													<em className="icon ni ni-menu-right" />
												</a>
												<div
													className="toggle-content"
													data-content="cardTools">
													<ul className="btn-toolbar gx-1">
														<li className="toggle-close">
															<a
																href="#"
																className="btn btn-icon btn-trigger toggle"
																data-target="cardTools">
																<em className="icon ni ni-arrow-left" />
															</a>
														</li>

														<li>
															<div className="dropdown">
																<a
																	href="#"
																	className="btn btn-trigger btn-icon dropdown-toggle"
																	data-toggle="dropdown">
																	<em className="icon ni ni-setting" />
																</a>
																<div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
																	<ul className="link-check">
																		<li>
																			<span>Show</span>
																		</li>
																		{pages.map((item, index) => (
																			<li
																				onClick={() => loadData(null, item)}
																				key={index}
																				className={`${
																					page == item && "active"
																				}`}>
																				<Link to={"#"}>{item}</Link>
																			</li>
																		))}
																	</ul>
																</div>
															</div>
														</li>
													</ul>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					}
				</div>
			</div>

			<>
				<BookingTable
					columns={headerList}
					data={users}
					cssArray={["font-weight-500", "", "", "text-left", "", ""]}
					enableSearch={false}
					emptyMessage="No user record to show on page"
					disablePageSize={true}
				/>
				<br />
				{users.length !== 0 ? (
					<div className="">
						<Paginate
							onChange={(p) => loadData(p)}
							currentPage={page}
							pageSize={pageSize}
							totalRecordsCount={totalRecords}
						/>
					</div>
				) : null}
			</>
		</MainLayout>
	);
}
