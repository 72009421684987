import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import DocumentService from "../../services/documentService";
import VoyageDocumentsListComponent from "./voyageDocumentsList";
import VoyageService from "../../services/voyageService";
import BulkListComponent from "./bulkList";
import DeckListComponent from "./deckList";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";

export default function VoyageDocumentsComponent({ voyagePlanningId }) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [voyageDocuments, setVoyageDocuments] = useState([]);

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setInit(true);
    const _voyageDocuments = await DocumentService.getListOfDocumentsById(
      voyagePlanningId
    );

    if (_voyageDocuments) {
      let _voyageDocument = _voyageDocuments?.map((voyageDocument) => ({
        ...voyageDocument,
        viewEvidence: documentEvidence(voyageDocument),
        dateCreated: toReadableDate(voyageDocument.DateCreated),
      }));
      setVoyageDocuments(_voyageDocument || []);
    }

    setInit(false);
  };

  const documentEvidence = (document) => {
    if (document) {
      return (
        <div>
          <a
            href={`${process.env.REACT_APP_API}/api/files/loadfile/${document.FIleReferenceNumber}`}
            target="_blank"
          >
            View Voyage Document
          </a>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card-inner p-5">
          <p>
            <span className="preview-title-lg overline-title">
              List of Voyage Documents
            </span>{" "}
          </p>
          <VoyageDocumentsListComponent dataList={voyageDocuments} />
        </div>
      )}
    </>
  );
}
