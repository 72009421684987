import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import VoyageActivityService from "../../services/voyageActivityService";
import BulkListComponent from "../voyage/bulkList";
import DeckListComponent from "../voyage/deckList";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import {
  toReadableDate,
  toReadableDateTime,
} from "../../utils/utilityFunctions";
import { camelizeKeys, dynamicSort } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import Carousel from "react-elastic-carousel";
import portImg from "../../images/port.png";
import offshoreImg from "../../images/oil-platform.png";
import oceanImg from "../../images/sea-wave.png";
import VoyageActivitiesListComponent from "./voyageActivitiesList";

export default function FinalVoyageActivitiesComponent({
  voyagePlanningId,
  voyage,
}) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalRecords, setTotalRecords] = useState(15);
  const [voyageActivities, setVoyageActivities] = useState([]);
  const [displayedVoyageActivities, setDisplayedVoyageActivities] = useState(
    []
  );
  const [locationOptions, setLocationOptions] = useState([]);

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    await loadData();
  };

  const loadData = async (_page, _pageSize) => {
    setLoading(true);
    _page = _page || page;
    _pageSize = _pageSize || pageSize;
    const _voyageActivities =
      await VoyageActivityService.getVoyageActivitiesAndSailingList(
        1,
        1000,
        voyagePlanningId
      );
    let modifiedDataList = camelizeKeys(_voyageActivities?.res || []);
    if (_voyageActivities?.TotalCount) {
      setTotalRecords(_voyageActivities?.TotalCount);
    }

    let startSailingFound = false;
    let activitiesLocation = [];

    modifiedDataList = modifiedDataList.map((voyageActivity) => {
      if (
        voyageActivity.activityName.toLowerCase() == "sailing" &&
        !startSailingFound
      ) {
        startSailingFound = true;
        activitiesLocation.push(voyageActivity);
      } else if (
        voyageActivity.activityName.toLowerCase() !== "sailing" &&
        startSailingFound
      ) {
        startSailingFound = false;
        activitiesLocation.push(voyageActivity);
      }
      return {
        ...voyageActivity,
        plannedStartDate: toReadableDateTime(voyageActivity.start),
        endDate: toReadableDateTime(voyageActivity.end),
      };
    });
    setVoyageActivities(modifiedDataList || []);
    setDisplayedVoyageActivities(modifiedDataList || []);

    let activitiesLocationReduced = activitiesLocation.map((location) => ({
      locationIcon: image(location),
      locationId: location?.locationId,
      locationName: location?.locationName,
      activityName: location?.activityName,
      sailingDistance: (location?.sailingDistance).toFixed(2),
      locationActivityStartDate: location?.eta,
      locationActivityEndDate: location?.etd,
    }));

    setLocationOptions(activitiesLocationReduced || []);

    setLoading(false);
  };

  const overflowMenu = (voyageActivity) => {
    const deleteVoyageActivity = async () => {
      if (voyageActivity) {
        let response = await VoyageActivityService.deleteVoyageActivity(
          voyageActivity.voyageActivityId
        );
        if (response) {
          toastr("success", "Activity removed successfully");
          await init();
        } else {
          toastr("error", "Activity not removed");
        }
      }
      setLoading(false);
    };

    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                <li>
                  <Link
                    to={`/voyage-activity-details/${voyageActivity.voyageActivityId}`}
                  >
                    <em className="icon ni ni-eye" />
                    <span>View Details</span>
                  </Link>
                </li>

                {voyageActivity.activityStatus == "not-started" ? (
                  <li>
                    <Link
                      to={`/edit-activity-timeline/${voyageActivity.voyageActivityId}`}
                    >
                      <em className="icon ni ni-files" />
                      <span>Edit Timeline</span>
                    </Link>
                  </li>
                ) : null}

                {voyageActivity.voyageActivityId !==
                "00000000-0000-0000-0000-000000000000" ? (
                  <li>
                    {
                      <a
                        style={{ color: "darkred" }}
                        role="button"
                        onClick={deleteVoyageActivity}
                      >
                        <em className="icon ni ni-cross" />
                        <span>Remove Activity</span>
                      </a>
                    }
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  const startEndButton = (voyageActivity) => {
    if (voyageActivity && voyageActivity.activityStatus == "not-started") {
      return (
        <div>
          <a
            href={`/start-voyage-activity/${voyageActivity.voyageActivityId}`}
            className="btn btn-sm btn-success active"
            onclick="return false"
          >
            Start
          </a>
        </div>
      );
    } else if (
      voyageActivity &&
      voyageActivity.activityStatus == "in-progress" &&
      voyageActivity.allowsParallel == true
    ) {
      return (
        <div>
          <a
            href={`/end-voyage-activity/${voyageActivity.voyageActivityId}`}
            className="btn btn-sm btn-danger active"
            onclick="return false"
          >
            End
          </a>
        </div>
      );
    } else if (
      voyageActivity &&
      voyageActivity.activityStatus == "in-progress" &&
      voyageActivity.allowsParallel == false
    ) {
      return (
        <div>
          <ReactTooltip className="text-center" id="lock">
            This activity will end automatically
            <br /> on start of next main activity
          </ReactTooltip>
          <a
            role="button"
            className="btn btn-sm btn-dim btn-danger disabled"
            onclick="return false"
            data-toggle="tooltip"
            data-placement="top"
            data-original-title="Changes locked"
            data-tip
            data-for="lock"
          >
            End
          </a>
        </div>
      );
    } else if (voyageActivity && voyageActivity.activityStatus == "completed") {
      return (
        <div>
          <a
            role="button"
            className="btn btn-sm btn-dim btn-primary disabled"
            onclick="return false"
          >
            Done
          </a>
        </div>
      );
    }
  };

  const image = (voyageActivity) => {
    if (voyageActivity.locationType == null) {
      return <img src={oceanImg} alt="terminal_img" />;
    } else if (voyageActivity.locationType == "Offshore Installation") {
      return <img src={offshoreImg} alt="terminal_img" />;
    } else {
      return <img src={portImg} alt="terminal_img" />;
    }
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1024, itemsToShow: 4 },
  ];

  const filterByLocation = (id) => {
    setLoading(true);
    let filteredActivities = voyageActivities.filter(
      (activity) => id == activity.locationId
    );
    setDisplayedVoyageActivities(filteredActivities);
    setLoading(false);
    // await loadData(1, null, _terminalId);
  };

  const clearFilter = () => {
    setLoading(true);
    setDisplayedVoyageActivities(voyageActivities);
    setLoading(false);
    // await loadData(1, null, _terminalId);
  };

  return (
    <>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card-inner p-5">
          <form>
            <span className="preview-title-lg overline-title text-center">
              Vessel Voyage View{" "}
            </span>
            <div className="code-block border-gray">
              <Carousel breakPoints={breakPoints}>
                {locationOptions.map((voyageActivity, index) => (
                  <a
                    className="code-block border-gray"
                    style={{ width: "200px", height: "240px" }}
                    role="button"
                    onClick={() => filterByLocation(voyageActivity.locationId)}
                    key={index}
                  >
                    {voyageActivity.activityName.toLowerCase() !== "sailing" ? (
                      <div
                        className="text-center"
                        key={voyageActivity.locationId}
                      >
                        {voyageActivity.locationIcon}
                        <p>{voyageActivity.locationName}</p>
                        <p>ETA: {voyageActivity.locationActivityStartDate}</p>
                        <p>ETD: {voyageActivity.locationActivityEndDate}</p>
                      </div>
                    ) : (
                      <div
                        className="text-center"
                        key={voyageActivity.locationId}
                      >
                        <div className="gap gap-60px" />
                        {voyageActivity.locationIcon}
                        <p>Distance: {voyageActivity.sailingDistance}</p>
                      </div>
                    )}
                  </a>
                ))}
              </Carousel>
            </div>
            <div className="gap" />
            <div className="table code-block border-info">
              <div className="text-center">
                <a
                  role="button"
                  className="btn btn-white btn-outline-light"
                  onClick={clearFilter}
                >
                  Remove Filter
                </a>
              </div>
              <div className="gap" />
              <VoyageActivitiesListComponent
                dataList={displayedVoyageActivities}
              />
            </div>
            {/* <div className="gap" />
            <div className="table code-block border-light">
              <div className="text-center p-2 m-1">
                <a
                  href={`/vessel/${voyage.VesselSpecificationId}`}
                  onclick="return false"
                  className="p-1 m-1"
                >
                  <span className="badge badge-pill badge-md badge-light">
                    View Vessel Details
                  </span>
                </a>
                <a
                  href="/weather-report"
                  onclick="return false"
                  className="p-1 m-1"
                >
                  <span className="badge badge-pill badge-md badge-light ">
                    Weather Information
                  </span>
                </a>
              </div>
            </div>
            <div className="gap" /> */}
          </form>
          <div className="gap" />
          <hr className="preview-hr" />
        </div>
      )}
    </>
  );
}
