import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";
import VesselService from "../../services/vesselService";
import PartyModal from "../../pages/Modals/partyModal";
import PartyService from "../../services/partyService";
import ContactPersonService from "../../services/contactPersonService";
import Select from "react-select";
import { checkUserPermission } from "../../utils/utilityFunctions";
import UserService from "../../services/userService";
import CustomModal from "../../utils/customModal";
import PayloadUpdater from "../../utils/payloadUpdater";
import toastr from "../../utils/toastr";
import AddVesselMasterComponent from "../users/addVesselMasterComponent";

export default function VesselPartiesComponent({ vesselSpecId }) {
  const definedPartyTypes = {
    "vessel owner": {},
    "technical manager": {},
    "international safety manager": {},
    "commercial manager": {},
  };
  const [initializing, setInit] = useState(true);
  const [partyTypes, setPartyTypes] = useState([]);
  const [contactPersonRoles, setContactPersonRoles] = useState([]);
  const [partyList, setParty] = useState([]);
  const [vesselParties, setVesselParties] = useState([]);
  const [updating, setUpdating] = useState({});
  const [saving, setSaving] = useState(false);
  const [vesselMaster, setVesselMaster] = useState({});
  const [vesselMasterUsers, setVesselMasterUsers] = useState([]);
  const [vessel, setVessel] = useState({});
  const [vesselPayload, setVesselPayload] = useState({
    userId: "",
    vesselEmail: "",
    vesselSatelitePhone: "",
  });
  const [vesselMasterUpdateModal, setVesselMasterUpdateModal] = useState(false);
  const [modalIsClosed, setModalIsClosed] = useState(true);

  const addVesselMasterPayload = new PayloadUpdater({
    payload: vesselPayload,
    setPayload: setVesselPayload,
  }).update;

  useEffect(async () => {
    setInit(true);

    const _partyTypes = await PartyService.listPartyTypes();
    setPartyTypes(_partyTypes || []);

    const _contactPersonRoles = await ContactPersonService.listAllRoles();
    setContactPersonRoles(_contactPersonRoles);

    const party_List = await PartyService.listParty();
    setParty(party_List || []);

    // await getVesselMasterProperties();

    await loadParties(_partyTypes);

    setInit(false);
  }, [vesselSpecId]);

  const loadParties = async (_partyTypes) => {
    _partyTypes = _partyTypes || (await PartyService.listPartyTypes());
    const _vesselParties = await PartyService.listByVesselId(vesselSpecId);
    const _partyList = [];
    Object.keys(definedPartyTypes).forEach((p) => {
      const party_type = _partyTypes.find(
        (a) => a.PartyType.toLowerCase() == p.toLowerCase()
      );
      const matchingRecord = (_vesselParties || []).find(
        (a) => a.PartyType?.toLowerCase() == p.toLowerCase()
      );

      if (_vesselParties && matchingRecord) {
        _partyList.push({
          ContactPersons: matchingRecord.ContactPersons || [{}],
          Party: matchingRecord.Party,
          MappingRecord: matchingRecord.MappingRecord,
        });
      } else {
        _partyList.push({
          ContactPersons: [{}],
          Party: {},
          MappingRecord: { PartyTypeId: party_type?.PartyTypeId },
        });
      }
    });
    setVesselParties(_partyList || []);
  };
    //extracting Vessel owners' list
    let vesselOwnerslist = partyList?.filter((item) => item?.PartyType?.toLowerCase() === "vessel owner")

  // const getVesselMasterProperties = async () => {
  //   setInit(true);
  //   const _vessel = await VesselService.fetchVessel(vesselSpecId);
  //   setVessel(_vessel || []);

  //   const _vesselMaster = await UserService.getUserById(_vessel.UserId);
  //   setVesselMaster(_vesselMaster || []);

  //   let _vesselMasterUsers = await UserService.getUsersByRole(
  //     "Vessel Master",
  //     ""
  //   );
  //   _vesselMasterUsers = _vesselMasterUsers?.map((user) => ({
  //     ...user,
  //     label: `${user.FirstName} ${user.LastName}`,
  //     value: user.Id,
  //   }));
  //   setVesselMasterUsers(_vesselMasterUsers || []);
  //   setInit(false);
  // };

  // const editCallback = async () => {
  //   onModalClose();
  //   await getVesselMasterProperties();
  // };

  // const onModalClose = () => {
  //   setModalIsClosed(true);
  // };

  const dataListItem = ({ label, value, label2, value2 }) => {
    return (
      <div className="data-item px-0">
        <div className="data-col">
          <span className="data-label">
            <span>{value}</span>
          </span>
          {value2 && (
            <span className="data-label">
              <span>{value2}</span>
            </span>
          )}
        </div>
      </div>
    );
  };

  const singleParty = ({ party }) => {
    return (
      <span>
        <h6 className="d-inline-block mb-2">
          {party.partyName || party.PartyName}
        </h6>{" "}
        <br />{" "}
        <span>
          <em className="icon ni ni-mail mr-1"></em>
          {party.emailAddress || party.EmailAddress}
        </span>{" "}
        <span>
          <em className="icon ni ni-call ml-3 mr-1"></em>{" "}
          {party.phoneNumber || party.PhoneNumber}
        </span>
        <br />
        <span>
          <em className="icon ni ni-map-pin-fill mr-1"></em>
          {`${party.AddressLine1}, ${party.AddressLine2}`}
        </span>{" "}
      </span>
    );
  };

  const singleContact = ({ contactPerson }) => {
    if (!contactPerson || !contactPerson.RoleId) return <span></span>;
    console.log({ contactPerson, contactPersonRoles });
    let c = contactPerson;
    const _contactRole = contactPersonRoles.find(
      (a) => a.ContactPersonRoleId?.toLowerCase() == c.RoleId.toLowerCase()
    );
    return (
      <span>
        <span className="overline-title d-inline-block mb-2">
          Contact Person
        </span>
        <br />
        <span className="d-inline-block">
          {`${c.FirstName} ${c.LastName}`}{" "}
          {_contactRole && (
            <>
              <span className="mx-2" style={{ opacity: "0.2" }}>
                |
              </span>{" "}
              {_contactRole?.ContactPersonRole}
            </>
          )}
        </span>
        <br />{" "}
        <span>
          <em className="icon ni ni-mail mr-1"></em>
          {c.emailAddress || c.EmailAddress}
        </span>{" "}
        <span>
          <em className="icon ni ni-call ml-3 mr-1"></em>{" "}
          {c.phoneNumber || c.PhoneNumber}
        </span>
      </span>
    );
  };

  // const currentVesselMaster = ({ vesselMaster }) => {
  //   if (!vesselMaster || !vesselMaster.Id) return <span></span>;
  //   let c = vesselMaster;
  //   return (
  //     <div className="row gy-3 pl-0 pt-4 pr-4">
  //       <span>
  //         <span className="preview-title-lg overline-title d-inline">
  //           Vessel Master
  //         </span>
  //         {checkUserPermission("vessel_edit_managers") && (
  //           <>
  //             <button
  //               type="button"
  //               title="Edit vessel master"
  //               className="d-inline btn btn-xs text-primary ml-3"
  //               onClick={() => setVesselMasterUpdateModal(true)}
  //             >
  //               <em className="icon ni ni-edit-alt"></em> Edit
  //             </button>
  //             <button
  //               type="button"
  //               title="Add a new vessel master"
  //               className="d-inline btn btn-xs text-primary ml-3"
  //               onClick={() => setModalIsClosed(false)}
  //             >
  //               <em className="icon ni ni-plus"></em> Add New
  //             </button>
  //           </>
  //         )}
  //         <div className="gap gap-30px" />
  //         <h6 className="d-inline-block mb-2">{`${c.FirstName} ${c.LastName}`}</h6>
  //         <br />{" "}
  //         <span>
  //           <em className="icon ni ni-mail mr-1"></em>
  //           {c.email || c.Email}
  //         </span>{" "}
  //         <span>
  //           <em className="icon ni ni-call ml-3 mr-1"></em>{" "}
  //           {c.phoneNumber || c.PhoneNumber}
  //         </span>
  //       </span>
  //     </div>
  //   );
  // };

  // const submitVesselMasterForm = async () => {
  //   addVesselMasterPayload(
  //     ["vesselEmail", "vesselSatelitePhone"],
  //     [vessel?.VesselEmail, vessel?.VesselSatelitePhone]
  //   );
  //   const _payload = { ...vesselPayload };

  //   setSaving(true);

  //   const response = await VesselService.updateVesselMasterDetails(
  //     vesselSpecId,
  //     _payload
  //   );
  //   if (response) {
  //     toastr("success", "Vessel master updated successfully");
  //     setVesselMasterUpdateModal(false);
  //     await getVesselMasterProperties();
  //   } else {
  //     toastr("error", "Vessel master not updated");
  //     setSaving(false);
  //   }
  //   setSaving(false);
  // };

  const SubmitPartyEdit = async (party, index, partyTypeId) => {
    console.log("new party selected", party);

    const _partyToEdit = [...vesselParties][index];
    const _updating = { ...updating };
    _updating[index.toString()] = true;
    setUpdating(_updating);
    if (_partyToEdit.MappingRecord.PartyVesselId) {
      await PartyService.updatePartyVessel(
        _partyToEdit.MappingRecord.PartyVesselId,
        _partyToEdit.MappingRecord,
        party.PartyId
      );
    } else {
      //partyvessel wasnt added previously
      await PartyService.postVesselParties(
        partyTypeId,
        party.PartyId,
        vesselSpecId
      );
      loadParties(partyTypes);
    }

    _updating[index.toString()] = false;
    setUpdating(_updating);

    await loadParties();
  };

  const startEdit = (index) => {
    const _vesselParties = [...vesselParties];
    _vesselParties[index].Party.showEdit = true;
    setVesselParties(_vesselParties);
  };

  const closeEdit = (index) => {
    const _vesselParties = [...vesselParties];
    _vesselParties[index].Party.showEdit = false;
    setVesselParties(_vesselParties);
  };

  const KeyVesselParties = ({ party, contactPerson, index, mappingRecord }) => {
    const selectedPartyType = partyTypes.find(
      (a) => a.PartyTypeId == mappingRecord.PartyTypeId
    );

    return (
      selectedPartyType && (
        <>
          <div className="row gy-3 pl-0 pt-4 pr-4">
            <div className="col-12">
              <p>
                <span className="preview-title-lg overline-title d-inline">
                  {selectedPartyType.PartyType}
                </span>
                {checkUserPermission("vessel_edit_managers") &&
                party.showEdit ? (
                  <button
                    type="button"
                    className="d-inline btn btn-xs text-danger ml-3"
                    onClick={() => closeEdit(index)}
                  >
                    <em className="icon ni ni-cross-sm"></em> Cancel
                  </button>
                ) : (
                  <button
                    type="button"
                    title={`Change ${selectedPartyType.PartyType}`}
                    className="d-inline btn btn-xs text-primary ml-3"
                    onClick={() => startEdit(index)}
                  >
                    <em className="icon ni ni-edit-alt"></em> Edit
                  </button>
                )}
              </p>
            </div>
            <div className="col-12">
              <div className="row align-items-center">
                {checkUserPermission("vessel_edit_managers") && party.showEdit && (
                  <>
                    <div className="col-md-4 mb-4">
                      <div className="form-group my-3">
                        <label className="form-label">
                          Select existing{" "}
                          <span>
                            {updating[index.toString()] && <Spinner size="1" />}
                          </span>{" "}
                        </label>{" "}
                        <small className="text-danger ml-1">*</small>
                        <div className="form-control-wrap">
                          <div className="">
                            <Select
                              options={vesselOwnerslist.map((e) => ({
                                ...e,
                                label: e.PartyName,
                                value: e.PartyId,
                              }))}
                              value={vesselOwnerslist
                                .map((e) => ({
                                  ...e,
                                  label: e.PartyName,
                                  value: e.PartyId,
                                }))
                                .find((a) => a.value == party.PartyId)}
                              onChange={(option) =>
                                SubmitPartyEdit(
                                  option,
                                  index,
                                  selectedPartyType.PartyTypeId
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-4">
                      <div className="form-group my-3">
                        <PartyModal
                          callback={async (_party_contact) =>
                            await SubmitPartyEdit(
                              _party_contact.party,
                              index,
                              selectedPartyType.PartyTypeId
                            )
                          }
                          partyTypeId={selectedPartyType.PartyTypeId}
                        />
                      </div>
                    </div>
                  </>
                )}

                {party?.PartyId && (
                  <div className="col-12">
                    <div className="mt-0">
                      <div className="nk-data data-list">
                        {dataListItem({
                          label: "",
                          value: singleParty({ party: party }),
                          label2: "Contact Person",
                          value2: singleContact({
                            contactPerson: contactPerson,
                          }),
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr className="mt-0" style={{ opacity: "0.25" }} />
        </>
      )
    );
  };

  return initializing ? (
    <Spinner />
  ) : (
    <>
      <div>
        {/* <div className="col-12">
          <div className="mt-0">
            <div className="nk-data data-list">
              {dataListItem({
                label: "Vessel Master",
                value: currentVesselMaster({ vesselMaster: vesselMaster }),
              })}
            </div>
          </div>
        </div>
        <hr className="mt-0" style={{ opacity: "0.25" }} /> */}
        {vesselParties.map((data, index) =>
          KeyVesselParties({
            party: data.Party,
            contactPerson: data.ContactPersons[0],
            index: index,
            mappingRecord: data.MappingRecord,
          })
        )}
      </div>

      {/* {vesselMasterUpdateModal == true && vessel && (
        <CustomModal
          title="Update Vessel Master"
          content={
            <>
              <form className="p-2">
                <div className="card-inner">
                  <>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            Vessel Master
                          </label>
                          <div className="form-control-wrap">
                            <Select
                              onChange={(e) => {
                                addVesselMasterPayload("userId", e.value);
                              }}
                              defaultValue={vesselMasterUsers?.find(
                                (user) => user.value == vessel?.UserId
                              )}
                              options={vesselMasterUsers}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gap gap-10px" />
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            Vessel Email
                          </label>
                          <div className="form-control-wrap">
                            <input
                              value={vessel?.VesselEmail}
                              type="email"
                              className="form-control required"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gap gap-10px" />
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label
                            className="form-label small"
                            htmlFor="fw-last-name"
                          >
                            Vessel Satellite Phone
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              value={vessel?.VesselSatelitePhone}
                              className="form-control required"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
                {saving ? (
                  <div className="form-group p-2 m-3">
                    <Spinner size="1.5" />
                  </div>
                ) : (
                  <div className="form-group p-2 m-3">
                    <button
                      type="button"
                      onClick={() => submitVesselMasterForm()}
                      className="btn btn-md btn-dark btn-wide px-5 mr-3"
                    >
                      Update
                    </button>
                  </div>
                )}
              </form>
            </>
          }
          onClose={() => setVesselMasterUpdateModal(false)}
          isClosed={false}
          noContentPadding={true}
        />
      )}
      {modalIsClosed == false && (
        <CustomModal
          title="Add Vessel Master"
          content={
            <>
              <AddVesselMasterComponent callback={editCallback} />
            </>
          }
          onClose={() => setModalIsClosed(true)}
          isClosed={modalIsClosed}
        />
      )} */}
    </>
  );
}
