import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageActivityService from "../../services/voyageActivityService";
import VoyageService from "../../services/voyageService";
import { toReadableDate } from "../../utils/utilityFunctions";
import {
  camelizeKeys,
  dynamicSort,
  dateToYYYY_MM_DD,
  dateToYYYY_MM_DD_hh_mm,
} from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import CargoHandlingService from "../../services/cargoHandlingService";
import VoyageActivityDeckCargoList from "./voyageActivityDeckCargoList";
import VoyageActivityBulkCargoList from "./voyageActivityBulkCargoList";
import UpdateCargoLogsComponent from "./updateCargoLogsComponent";
import CustomModal from "../../utils/customModal";

export default function UpdateVoyageActivityComponent({
  voyageActivityId,
}) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [voyageActivity, setVoyageActivity] = useState([]);
  const [bulkCargos, setBulkCargos] = useState([]);
  const [deckCargos, setDeckCargos] = useState([]);
  const [loadingBulkCargos, setLoadingBulkCargos] = useState([]);
  const [loadingDeckCargos, setLoadingDeckCargos] = useState([]);
  const [dischargingBulkCargos, setDischargingBulkCargos] = useState([]);
  const [dischargingDeckCargos, setDischargingDeckCargos] = useState([]);
  const [offshoreBulkCargos, setOffshoreBulkCargos] = useState([]);
  const [offshoreDeckCargos, setOffshoreDeckCargos] = useState([]);
  const [modalIsClosed, setModalIsClosed] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "editactivitytimeline";

  useEffect(async () => {
    await init();
  }, []);

  const init = async (_page, _pageSize) => {
    setInit(true);
    const _voyageActivity = await VoyageActivityService.getVoyageActivity(
      voyageActivityId
    );
    let voyageActivityData = camelizeKeys(_voyageActivity?.Data || []);
    setPayload(voyageActivityData || []);
    setVoyageActivity(voyageActivityData || []);

    if (
      voyageActivityData &&
      (voyageActivityData?.activityName == "Port Loading Deck cargo" ||
        voyageActivityData?.activityName == "Port Loading Bulk cargo")
    ) {
      const _loadingActivity = await CargoHandlingService.getLoadingCargos(
        voyageActivityData.voyagePlanningId,
        voyageActivityData.locationId
      );

      if (_loadingActivity) {
        let _loadingBulkCargo = _loadingActivity?.BulkCargos.map((cargo) => [
          {
            cargoName: cargo.BookingReference + " - " + cargo.ProductName,
            updateButtonComponent: endActivityUpdates(cargo),
            rob: cargo?.ActivityEndLog?.Rob || "N/A",
            quantityUnit: cargo?.ActivityEndLog?.RobUnitName || "N/A",
            bulkCargoDetailId: cargo.BulkCargoDetailId,
          },
        ]);
        setLoadingBulkCargos(_loadingBulkCargo.flat(2) || []);

        let _loadingDeckCargo = _loadingActivity?.DeckCargos.map((cargo) => [
          {
            cargoName:
              cargo.BookingReference + " - " + cargo.DescriptionOfGoods,
            updateButtonComponent: endActivityUpdates(cargo),
            cargoWeight: cargo?.ActivityEndLog?.WeightOfLoadedItem || "N/A",
            numberOfLifts: cargo?.ActivityEndLog?.NumberOfLifts || "N/A",
            quantityUnit: cargo?.ActivityEndLog?.MeasurementUnitName || "N/A",
            deckCargoDetailId: cargo.DeckCargoDetailId,
          },
        ]);
        setLoadingDeckCargos(_loadingDeckCargo.flat(2) || []);
        setBulkCargos(_loadingBulkCargo.flat(2) || []);
        setDeckCargos(_loadingDeckCargo.flat(2) || []);
      }
    }

    if (
      voyageActivityData &&
      (voyageActivityData?.activityName == "Port Discharging Deck cargo" ||
        voyageActivityData?.activityName == "Port Discharging Bulk cargo")
    ) {
      const _dischargeActivity =
        await CargoHandlingService.getDischargingCargos(
          voyageActivityData.voyagePlanningId,
          voyageActivityData.locationId
        );

      if (_dischargeActivity) {
        let _dischargingBulkCargo = _dischargeActivity?.BulkCargos.map(
          (cargo) => [
            {
              cargoName: cargo.BookingReference + " - " + cargo.ProductName,
              updateButtonComponent: endActivityUpdates(cargo),
              rob: cargo?.ActivityEndLog?.Rob || "N/A",
              quantityUnit: cargo?.ActivityEndLog?.RobUnitName || "N/A",
              bulkCargoDetailId: cargo.BulkCargoDetailId,
            },
          ]
        );
        setDischargingBulkCargos(_dischargingBulkCargo.flat(2) || []);

        let _dischargingDeckCargo = _dischargeActivity?.DeckCargos.map(
          (cargo) => [
            {
              cargoName:
                cargo.BookingReference + " - " + cargo.DescriptionOfGoods,
              updateButtonComponent: endActivityUpdates(cargo),
              cargoWeight: cargo?.ActivityEndLog?.WeightOfLoadedItem || "N/A",
              numberOfLifts: cargo?.ActivityEndLog?.NumberOfLifts || "N/A",
              quantityUnit: cargo?.ActivityEndLog?.MeasurementUnitName || "N/A",
              deckCargoDetailId: cargo.DeckCargoDetailId,
            },
          ]
        );
        setDischargingDeckCargos(_dischargingDeckCargo.flat(2) || []);
        setBulkCargos(_dischargingBulkCargo.flat(2) || []);
        setDeckCargos(_dischargingDeckCargo.flat(2) || []);
      }
    }

    if (
      voyageActivityData &&
      (voyageActivityData?.activityName == "Offshore Handling Deck cargo" ||
        voyageActivityData?.activityName == "Offshore Handling Bulk cargo")
    ) {
      const _offshoreActivity = await CargoHandlingService.getOffshoreCargos(
        voyageActivityData.voyagePlanningId,
        voyageActivityData.locationId
      );

      if (_offshoreActivity) {
        let _offshoreBulkCargo = _offshoreActivity?.BulkCargos.map((cargo) => [
          {
            cargoName: cargo.BookingReference + " - " + cargo.ProductName,
            updateButtonComponent: endActivityUpdates(cargo),
            rob: cargo?.ActivityEndLog?.Rob || "N/A",
            quantityUnit: cargo?.ActivityEndLog?.RobUnitName || "N/A",
            bulkCargoDetailId: cargo.BulkCargoDetailId,
          },
        ]);
        setOffshoreBulkCargos(_offshoreBulkCargo.flat(2) || []);

        let _offshoreDeckCargo = _offshoreActivity?.DeckCargos.map((cargo) => [
          {
            cargoName:
              cargo.BookingReference + " - " + cargo.DescriptionOfGoods,
            updateButtonComponent: endActivityUpdates(cargo),
            cargoWeight: cargo?.ActivityEndLog?.WeightOfLoadedItem || "N/A",
            numberOfLifts: cargo?.ActivityEndLog?.NumberOfLifts || "N/A",
            quantityUnit: cargo?.ActivityEndLog?.MeasurementUnitName || "N/A",
            deckCargoDetailId: cargo.DeckCargoDetailId,
          },
        ]);
        setOffshoreDeckCargos(_offshoreDeckCargo.flat(2) || []);
        setBulkCargos(_offshoreBulkCargo.flat(2) || []);
        setDeckCargos(_offshoreDeckCargo.flat(2) || []);
      }
    }

    setInit(false);
  };

  const editCallback = async () => {
    onModalClose();
    await init();
  };

  const onModalClose = () => {
    setModalIsClosed(true);
  };

  const endActivityUpdates = (cargo) => {
    return (
      <div>
        <a
          role="button"
          className="btn btn-sm btn-warning active"
          onClick={() => {
            setModalIsClosed(false);
            setSelectedRecord(cargo);
          }}
        >
          Update
        </a>
      </div>
    );
  };

  const updateActivityTimeline = async () => {
    const _payload = { ...payload };

    // if (!_payload.actualStartDate) {
    //   toastr("error", "Select start date");
    //   return;
    // }

    // if (!_payload.actualEndDate) {
    //   toastr("error", "Select end date");
    //   return;
    // }

    setSaving(true);

    const response = await VoyageActivityService.updateVoyageActivity2(
      _payload
    );
    if (response) {
      setPayload({});
      toastr("success", "Voyage activity updated");
      setRedirectUrl(`/voyage-activities/${payload.voyagePlanningId}`);
    } else {
      toastr("error", "Voyage activity not updated");
      setSaving(false);
    }

    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="">
          {/* <div className="gap gap-20px" /> */}
          <form>
            <div className="card-inner ml-2 mt-2">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      Actual start date / time
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="datetime-local"
                        data-msg="Required"
                        className="form-control required"
                        id="fw-last-name"
                        name="fw-last-name"
                        required=""
                        value={dateToYYYY_MM_DD_hh_mm(payload?.actualStartDate)}
                        onChange={(e) =>
                          addPayload("actualStartDate", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      Actual end date / time
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="datetime-local"
                        data-msg="Required"
                        className="form-control required"
                        id="fw-last-name"
                        name="fw-last-name"
                        required=""
                        value={dateToYYYY_MM_DD_hh_mm(payload?.actualEndDate)}
                        onChange={(e) => addPayload("actualEndDate", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="card-inner ml-2">

            {voyageActivity?.activityName !==
            "Port Loading Deck cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Deck Cargos to load at port{" "}
                  </span>
                  <VoyageActivityDeckCargoList dataList={loadingDeckCargos} />
                </div>
              </>
            )}
            {/* {voyageActivity?.activityName !==
            "Port Loading Bulk cargo" ? null : (
              <>
            <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                <span className="preview-title-lg overline-title">
                  Bulk Cargos to load at port{" "}
                </span>
                <VoyageActivityBulkCargoList dataList={loadingBulkCargos} />
              </div>
              </>
            )} */}

            {voyageActivity?.activityName !==
            "Offshore Handling Deck cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Deck Cargos to load at offshore installation{" "}
                  </span>
                  <VoyageActivityDeckCargoList dataList={offshoreDeckCargos} />
                </div>
              </>
            )}
            {voyageActivity?.activityName !==
            "Offshore Handling Bulk cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Bulk Cargos to load at offshore installation{" "}
                  </span>
                  <VoyageActivityBulkCargoList dataList={offshoreBulkCargos} />
                </div>
              </>
            )}
            {voyageActivity?.activityName !==
            "Port Discharging Deck cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Deck Cargos to discharge at port{" "}
                  </span>
                  <VoyageActivityDeckCargoList
                    dataList={dischargingDeckCargos}
                  />
                </div>
              </>
            )}
            {voyageActivity?.activityName !==
            "Port Discharging Bulk cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Bulk Cargos to discharge at port{" "}
                  </span>
                  <VoyageActivityBulkCargoList
                    dataList={dischargingBulkCargos}
                  />
                </div>
              </>
            )}
            {/* <hr className="preview-hr" /> */}

          </div>

{/* <hr className="preview-hr" /> */}

<div className="card-inner float-right">
  {saving ? (
    <div className="form-group">
      <Spinner size="1.5" />
    </div>
  ) : (
    <div className="form-group">
      <button
        type="button"
        onClick={() => updateActivityTimeline()}
        className="btn btn-md btn-dark btn-wide px-5 mr-3"
      >
        Save
      </button>
      <button
        type="button"
        className="btn btn-sm btn-link m-1"
        onClick={() => window.history.back()}
      >
        Cancel
      </button>
    </div>
  )}
</div>
        

      {!modalIsClosed && selectedRecord && voyageActivity && (
        <CustomModal
          title="Update Cargo Logs"
          content={
            <>
              <UpdateCargoLogsComponent
                cargo={selectedRecord}
                callback={editCallback}
                activityName={voyageActivity.activityName}
                voyageActivityId={params.voyageActivityId}
                selectedBulkCargoDetailId={
                  selectedRecord?.BulkCargoDetailId || null
                }
                selectedDeckCargoDetailId={
                  selectedRecord?.DeckCargoDetailId || null
                }
              />
            </>
          }
          onClose={() => setSelectedRecord(null)}
          isClosed={modalIsClosed}
        />
      )}
        </div>
      )}
    </>
  );
}
