import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class SystemSettingsService {
  static getSystemSettingsList = async () => {
    return await apiCall(`/api/SystemSettings/list`, "GET");
  };

  static updateSystemSettings = async (payload) => {
    return await apiCall(`/api/SystemSettings/update`, "POST", payload);
  };
}
