import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import ConfigurationService from "../../services/configurationService";
import CustomModal from "../../utils/customModal";
import { toReadableDate, camelizeKeys } from "../../utils/utilityFunctions";
import { forEach } from "lodash";

export default function AddVesselCostComponent({ voyagePlanningId }) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [voyage, setVoyage] = useState([]);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [currency, setCurrency] = useState([]);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "addVesselCost";

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);

    await retrieveVoyage();
    await configurations();

    setLoading(false);
  };

  const retrieveVoyage = async () => {
    const _voyage = await VoyageService.get(params.voyagePlanningId);
    setVoyage(_voyage || []);
    setPayload({
      amount: _voyage?.ExtraCostIncurredOnVoyage,
      currencyConfigurationId: _voyage?.ExtraCostCurrencyConfigurationId,
    });
  };

  const configurations = async () => {
    let _configList = await ConfigurationService.getConfigList();

    if (_configList) {
      let _currency = _configList?.filter(
        (l) =>
          l.CorrespondingData.toLowerCase() == "dollars" ||
          l.CorrespondingData.toLowerCase() == "naira" ||
          l.CorrespondingData.toLowerCase() == "pounds"
      );
      setCurrency(_currency);
    }
  };

  const submitForm = async (e) => {
    const _payload = { ...payload };

    if (!_payload.amount) {
      toastr("error", "Enter vessel cost amount");
      return;
    }

    if (!_payload.currencyConfigurationId) {
      toastr("error", "Select currency");
      return;
    }

    setSaving(true);
    const request = await VoyageService.allocateVesselCost(
      params.voyagePlanningId,
      _payload.currencyConfigurationId,
      _payload.amount
    );
    if (request) {
      toastr("success", "Cost allocated to vessel");
      await init();
    } else {
      toastr("error", "Cost not allocated to vessel");
      setSaving(false);
      return;
    }
    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <>
          <form>
            <div className="card-inner">
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label small">Vessel Cost</label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control"
                        defaultValue={voyage?.ExtraCostIncurredOnVoyage}
                        required
                        onChange={(e) => addPayload("amount", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label small">Currency</label>
                    <div className="form-control-wrap">
                      <Select
                        defaultValue={currency
                          .map((c) => ({
                            ...c,
                            label: c.CorrespondingData,
                            value: c.ConfigurationId,
                          }))
                          .find(
                            (a) =>
                              a.value ==
                              voyage?.ExtraCostCurrencyConfigurationId
                          )}
                        options={currency.map((c) => ({
                          ...c,
                          label: c.CorrespondingData,
                          value: c.ConfigurationId,
                        }))}
                        onChange={(e) => {
                          addPayload("currencyConfigurationId", e.value);
                        }}
                        placeholder="Select currency"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div className="card-inner">
            {saving ? (
              <div className="form-group">
                <Spinner size="1.5" />
              </div>
            ) : (
              <div className="form-group">
                <button
                  type="button"
                  onClick={() => submitForm()}
                  className="btn btn-md btn-dark btn-wide px-5 mr-3"
                >
                  Save
                </button>
              </div>
            )}
          </div>

          {/* <hr className="preview-hr" /> */}
        </>
      )}
    </>
  );
}
