import React, { useEffect, useState, useCallback } from "react";
import "../../styles/dataTable.scss";
import {
	useNavigate,
	useLocation,
	Link,
	Navigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import moment from "moment";
import ms from "ms";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import {
	generateQueryString,
	checkUserPermission,
	formatValue,
	toReadableDate,
} from "../../utils/utilityFunctions";
import VesselService from "../../services/vesselService";
import LocationService from "../../services/locationService";
import styled from "styled-components";
import CustomModal from "../../utils/customModal";
import {
	ComposedChart,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	Line,
	Scatter,
	ResponsiveContainer,
} from "recharts";

export default function OffshoreInstallationEfficiency() {
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [payload, setPayload] = useState({});
	const [pages, setPages] = useState([10, 20, 50, 100, 150]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [totalRecords, setTotalRecords] = useState(10);
	const [utilizationList, setUtilizationList] = useState([]);
	const [exportData, setExportData] = useState([]);
	const [maxMonthHeader, setMaxMonthHeader] = useState("");
	const [secondMaxMonthHeader, setSecondMaxMonthHeader] = useState("");
	const [thirdMaxMonthHeader, setThirdMaxMonthHeader] = useState("");
	const navigate = useNavigate();
	const [filterObject, setFilterObject] = useState({ alllocationType: true });
	const [vesselList, setVesselList] = useState([]);
	const [locationTypes, setLocationTypes] = useState([]);
	const [allLocationTypes, setAllLocationTypes] = useState([]);
	const [maxDate, setMaxDate] = useState(null);
	const [minDate, setMinDate] = useState(null);
	const [installationList, setOffshoreInstallationList] = useState([]);
	const [chartData, setChartData] = useState([]);
	const [unHideTable, setUnHideTable] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);

	const monthArray = [
		{ label: "January", value: 1 },
		{ label: "February", value: 2 },
		{ label: "March", value: 3 },
		{ label: "April", value: 4 },
		{ label: "May", value: 5 },
		{ label: "June", value: 6 },
		{ label: "July", value: 7 },
		{ label: "August", value: 8 },
		{ label: "September", value: 9 },
		{ label: "October", value: 10 },
		{ label: "November", value: 11 },
		{ label: "December", value: 12 },
	];

	useEffect(async () => {
		await init();
	}, []);

	//this it is to retrive date and avoid re-renders.
	useCallback(() => {
		const minToSeconds = ms("60d");
		const minimumDate = new Date(+new Date(filterObject.dateFrom));
		const maximumDate = new Date(
			+new Date(filterObject.dateFrom) + minToSeconds
		);
		setMinDate(moment(minimumDate).format("YYYY-MM-DD"));
		setMaxDate(moment(maximumDate).format("YYYY-MM-DD"));
	}, [filterObject.dateFrom]);

	const init = async () => {
		setLoading(true);
		await loadData();
		let _locationTypes = await LocationService.listLocationTypes();
		setAllLocationTypes(_locationTypes);

		const locationsTypes = await LocationService.list();
		const filteredLocation = locationsTypes?.filter(
			(list) =>
				list?.LocationTypeName?.toLowerCase() === "offshore installation"
		);
		setOffshoreInstallationList(filteredLocation);
		setLoading(false);
	};
	const checkBoxValue = (e) => {
		if (e.target.value === "") {
			setFilterObject((prev) => ({
				...prev,
				alllocationType: true,
			}));
		}
		setFilterObject((prev) => ({
			...prev,
			alllocationType: e.target.value ? false : true,
		}));
	};

	let queryStr = generateQueryString(filterObject);

	const clearFilters = async () => {
		setFilterObject({
			dateFrom: "",
			dateTo: "",
			locationId: "",
			locationType: "",
			alllocationType: true,
		});
		queryStr = "alllocationType=true&";

		await loadData(1, null, queryStr);
	};
	const filterData = async () => {
		await loadData(1, null, queryStr);
	};

	const loadData = async (_page, _pageSize, query = queryStr) => {
		setLoading(true);
		_page = _page || page;
		_pageSize = _pageSize || pageSize;
		const utilizationDetails =
			await LocationService.dashboardOffshoreInstallation(
				_page,
				_pageSize,
				query
			);

		if (utilizationDetails?.TotalCount) {
			setTotalRecords(utilizationDetails.TotalCount);
			setChartData(utilizationDetails?.VoyageUtilizationData);
		}
		if (!utilizationDetails)
			return toastr("error", "there are installations within the selected to");
		const allocationMonths = utilizationDetails?.Data?.map(
			(list) => list.Month
		);
		let maxMonth = Math.max.apply(null, allocationMonths);
		allocationMonths.splice(allocationMonths.indexOf(maxMonth), 1);
		let secondMax = maxMonth - 1;
		let thirdMax = secondMax - 1;
		let maxMonthHeader = monthArray?.find(
			(mon) => mon.value === maxMonth
		)?.label;
		setMaxMonthHeader(maxMonthHeader);
		let secondMonthHeader = monthArray?.find(
			(mon) => mon.value === secondMax
		)?.label;
		setSecondMaxMonthHeader(secondMonthHeader);
		let thirdMaxHeader = monthArray?.find(
			(mon) => mon.value === thirdMax
		)?.label;
		setThirdMaxMonthHeader(thirdMaxHeader);

		let modifiedDataList = utilizationDetails?.Data || [];

		modifiedDataList = modifiedDataList.map((list) => ({
			...list,

			ThirdMax: (
				<div>
					<p>
						{formatValue(
							modifiedDataList?.find(
								(item) =>
									list?.Month === thirdMax && item.Activity === list.Activity
							)?.PercentUtilization
						)?.toFixed(3) || "0.000"}
					</p>
				</div>
			),
			SecondMax: (
				<div>
					<p>
						{formatValue(
							modifiedDataList?.find(
								(item) =>
									list?.Month === secondMax && item.Activity === list.Activity
							)?.PercentUtilization
						)?.toFixed(3) || "0.000"}
					</p>
				</div>
			),
			MaxMonth: (
				<div>
					<p>
						{modifiedDataList
							?.find(
								(item) =>
									list?.Month === maxMonth && item.Activity === list.Activity
							)
							?.PercentUtilization?.toFixed(3) || "0.000"}
					</p>
				</div>
			),
		}));

		setUtilizationList(modifiedDataList);

		page != _page && setPage(_page);
		pageSize != _pageSize && setPageSize(_pageSize);
		setLoading(false);
	};

	let editedChartData = chartData?.results?.map((list) => ({
		...list,
		// Location: chartData?.results?.Utilizations.find((x) => ""),
	}));
	
	let sampleData = [
		{
			TotalVoyageCost: 5145.83,
			Utilizations: [
				// {
				// 	LocationId: "f869a744-db4a-11ec-b918-85abc6df0ea5",
				// 	LocationName: "BRASS",
				// 	LocationTypeName: "Onshore Terminal",
				// 	Month: 1,
				// 	CostSum: 200,
				// 	TotalVoyageCount: 35,
				// 	ActivitiesCount: 15,
				// 	TotalTimeInMinutes: 904.4166666666667,
				// 	PercentUtilization: 15.8134608516740776,
				// 	PercentUnproductive: 43.1428571429,
				// 	PercentProductive: 56.857142857142854,
				// },
				// {
				// 	LocationId: "3e0e2db8-da9e-11ec-b918-85abc6df0ea5",
				// 	LocationName: "ADOON",
				// 	LocationTypeName: "FSO",
				// 	Month: 2,
				// 	CostSum: 180,
				// 	TotalVoyageCount: 32,
				// 	ActivitiesCount: 22,
				// 	TotalTimeInMinutes: 1104.4166666666667,
				// 	PercentUtilization: 55.8134608516740776,
				// 	PercentUnproductive: 54.1428571429,
				// 	PercentProductive: 55.857142857142854,
				// },
				{
					LocationId: "742b5116-da7b-11ec-b918-85abc6df0ea5",
					LocationName: "AGBAMI",
					LocationTypeName: "Field",
					Month: 3,
					CostSum: 155,
					TotalVoyageCount: 42,
					ActivitiesCount: 35,
					TotalTimeInMinutes: 1904.4166666666667,
					PercentUtilization: 15.8134608516740776,
					PercentUnproductive: 23.1428571429,
					PercentProductive: 76.857142857142854,
				},
				{
					LocationId: "47e8c8e2-da9e-11ec-b918-85abc6df0ea5",
					LocationName: "AILSA CRAIG I",
					LocationTypeName: "FSO",
					Month: 4,
					CostSum: 170,
					TotalVoyageCount: 52,
					ActivitiesCount: 46,
					TotalTimeInMinutes: 1704.4166666666667,
					PercentUtilization: 19.8134608516740776,
					PercentUnproductive: 22.1428571429,
					PercentProductive: 77.857142857142854,
				},
				{
					LocationId: "5561c250-da7b-11ec-b918-85abc6df0ea5",
					LocationName: "AJAKPA",
					LocationTypeName: "Field",
					Month: 5,
					CostSum: 120,
					TotalVoyageCount: 55,
					ActivitiesCount: 88,
					TotalTimeInMinutes: 1264.4166666666667,
					PercentUtilization: 2.8134608516740776,
					PercentUnproductive: 57.1428571429,
					PercentProductive: 42.857142857142854,
				},
				{
					LocationId: "5e81ce61-da7b-11ec-b918-85abc6df0ea5",
					LocationName: "ANTAN",
					LocationTypeName: "Field",
					Month: 6,
					CostSum: 130,
					TotalVoyageCount: 78,
					ActivitiesCount: 14,
					TotalTimeInMinutes: 1004.4166666666667,
					PercentUtilization: 2.8134608516740776,
					PercentUnproductive: 52.1428571429,
					PercentProductive: 47.857142857142854,
				},
				{
					LocationId: "33e6bd2f-4c21-4b35-906f-3a7e2969c48a",
					LocationName: "Lagos",
					LocationTypeName: "Port",
					Month: 7,
					CostSum: 165,
					TotalVoyageCount: 68,
					ActivitiesCount: 20,
					TotalTimeInMinutes: 1404.4166666666667,
					PercentUtilization: 12.8134608516740776,
					PercentUnproductive: 32.1428571429,
					PercentProductive: 67.857142857142854,
				},
				{   "VoyagePlanningId": "e68b270a-9a43-41aa-90fc-430bcffd0706",
					"LocationId": "4c190afd-db4e-11ec-b918-85abc6df0ea5",
					"LocationName": "APAPA",
					"LocationTypeName": "Port",
					"Month": 8,
					"CostSum": 80,
					"TotalVoyageCount": 50,
					"ActivitiesCount": 24,
					"TotalTimeInMinutes": 1404.4166666666667,
					"PercentUtilization": 12.8134608516740776,
					"PercentUnproductive": 33.1428571429,
					"PercentProductive": 66.857142857142854,
				},
			],
		},
	];
	const activitiesCount = sampleData?.map((list) =>
		list?.Utilizations?.map((x) => x.ActivitiesCount)
	);
	let activitiesCountArray = [];
	activitiesCountArray?.splice(
		0,
		activitiesCountArray.length,
		...activitiesCount[0]
	);
	let maxActivitiesValue = Math.max.apply(null, activitiesCountArray);
	activitiesCountArray.splice(
		activitiesCountArray.indexOf(maxActivitiesValue),
		1
	);
	let secondMaxActivities = Math.max.apply(null, activitiesCountArray);
	activitiesCountArray.splice(
		activitiesCountArray.indexOf(secondMaxActivities),
		1
	);
	let thirdMaxActivities = Math.max.apply(null, activitiesCountArray);
	activitiesCountArray.splice(
		activitiesCountArray.indexOf(thirdMaxActivities),
		1
	);
	let fourthMaxActivities = Math.max.apply(null, activitiesCountArray);
	activitiesCountArray.splice(
		activitiesCountArray.indexOf(fourthMaxActivities),
		1
	);
	let fifthMaxActivities = Math.max.apply(null, activitiesCountArray);

	let productivityData = sampleData.map((list) =>
		list?.Utilizations?.map((x) => ({
			LocationName: x?.LocationName,
			"Percent Unproductive": x?.PercentUnproductive?.toFixed(1),
			"Percent productive": x?.PercentProductive?.toFixed(1),
		}))
	);

	let sampleData2 = sampleData.map((list) =>
		list?.Utilizations?.map((x) => ({
			Month: monthArray?.find((mon) => mon.value === x.Month)?.label,
			LocationName: x?.LocationName,
			CostSum: x?.CostSum,
			TotalVoyageCount: x?.TotalVoyageCount,
		}))
	);
	
	const productiveBarChart = () => {
		const result = productivityData[0]?.map((dataObject) =>
			Object.keys(dataObject)
				.filter((locationDetails) => {
					return locationDetails != "LocationName";
				})
				.map((activityNames, index) => {
					return (
						<Bar
							dataKey={activityNames}
							stackId="a"
							fill={productiveBarColors[index % productiveBarColors.length]}
						/>
					);
				})
		);
		return result[0];
	};

	const headerList = [
		{
			Header: "Activity Group",
			accessor: "Activity",
		},
		{
			Header: "Offshore Installation",
			accessor: "LocationName",
		},
		{
			Header: thirdMaxMonthHeader,
			accessor: "ThirdMax",
		},
		{
			Header: secondMaxMonthHeader,
			id: "secondMonthId",
			accessor: "SecondMax",
		},
		{
			Header: maxMonthHeader,
			accessor: "MaxMonth",
		},
	];
	const pageLimiter = Math.ceil(totalRecords / pageSize);

	const prevLoaded = async () => {
		if (pageLimiter > 1 && page < pageLimiter) {
			await loadData(page - 1, null, queryStr);
		} else if (pageLimiter == page) {
			await loadData(page - 1, null, queryStr);
		}
	};

	const nextLoaded = async () => {
		if (pageLimiter !== 1 && page < pageLimiter) {
			await loadData(page + 1, null, queryStr);
		}
	};

	const goToPage = async (page) => {
		await loadData(page, null, queryStr);
	};
	const colors = ["#003f5c","#2f4b7c","#665191","#a05195","#d45087","#f95d6a","#ff7c43","#ffa600"];
	const productiveBarColors = ["#003f5c", "#a05195"];

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			let tooltipContent = payload[0]?.payload;
			return (
				<div className="custom-tooltip bg-white text-center p-2 m-2 border border-secondary" style={{position: "absolute;"}}>
					<p className="label">{`Installation Name : ${tooltipContent?.LocationName}`}</p>
					<p className="intro">{`Month: ${tooltipContent?.Month}`}</p>
					<p className="desc">{`Sum of Cost: ${tooltipContent?.CostSum}`}</p>
					<p className="desc">{`Voyage Count: ${tooltipContent?.TotalVoyageCount}`}</p>
				</div>
			);
		}

		return null;
	};

	return (
		<>
			{loading ? (
				<div className="w-100 text-center center my-5 py-5">
					<Spinner />
				</div>
			) : (
				<>
					<div className="card-inner-group">
						<h5 className="text-center pt-3 pb-0 pl-1">
							Offshore Installation Efficiency(time spent in %)
						</h5>
						{utilizationList.length < 0 ? null : (
							// The filter and pagination component

							<div className="card-inner position-relative card-tools-toggle">
								{
									<div className="card-title-group">
										<div className="card-tools ml-n1">
											<ul className="btn-toolbar gx-1">
												<li>
													<div className="dropdown">
														<a
															href="#"
															className="btn btn-trigger btn-icon dropdown-toggle "
															data-toggle="dropdown">
															<em className="icon ni ni-filter-alt" />
														</a>
														<div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
															<form style={{ zoom: "85%" }}>
																<div className="dropdown-head">
																	<span className="sub-title dropdown-title">
																		Filter List
																	</span>
																	<div className="dropdown">
																		<a
																			className="btn btn-sm btn-icon"
																			data-toggle="dropdown">
																			<em
																				className="icon ni ni-cross"
																				title="close"
																			/>
																		</a>
																	</div>
																</div>
																<div className="dropdown-body dropdown-body-rg">
																	<div className="row gx-6 gy-3">
																		<div className="col-6">
																			<div
																				className="form-group"
																				onClick={(e) => e.stopPropagation()}>
																				<label className="overline-title overline-title-alt">
																					Location Name
																				</label>
																				<div className="form-control-wrap filterSpacing">
																					<Select
																						maxMenuHeight={140}
																						options={installationList?.map(
																							(e) => ({
																								...e,
																								label: e.LocationName,
																								value: e.LocationId,
																							})
																						)}
																						value={installationList
																							.map((e) => ({
																								...e,
																								label: e.LocationName,
																								value: e.LocationId,
																							}))
																							.find(
																								(e) =>
																									e.value ===
																									installationList.LocationId
																							)}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								locationId: option.value,
																							}))
																						}
																					/>
																				</div>
																			</div>
																		</div>
																		<div className="col-6">
																			<div
																				className="form-group"
																				onClick={(e) => e.stopPropagation()}>
																				<label className="overline-title overline-title-alt">
																					Location Type
																				</label>
																				<Select
																					className="border-transparent"
																					placeholder=""
																					maxMenuHeight={140}
																					options={allLocationTypes.map(
																						(e) => ({
																							...e,
																							label: e.LocationType,
																							value: e.LocationTypeId,
																						})
																					)}
																					value={allLocationTypes
																						.map((e) => ({
																							...e,
																							label: e.LocationType,
																							value: e.LocationTypeId,
																						}))
																						.find(
																							(e) =>
																								e.value ===
																								filterObject.locationType
																						)}
																					onChange={(option) =>
																						setFilterObject((prev) => ({
																							...prev,
																							locationType: option.label,
																						}))
																					}
																				/>
																			</div>
																		</div>
																		<div className="col-6">
																			<div className="form-group">
																				<label
																					className="form-label"
																					htmlFor="fw-last-name">
																					From
																				</label>
																				<div className="">
																					<input
																						type="date"
																						className="form-control "
																						placeholder=""
																						id="from-date-filter"
																						value={filterObject.dateFrom}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								dateFrom: option.target.value,
																							}))
																						}
																					/>
																				</div>
																			</div>
																		</div>

																		<div className="col-6">
																			<div className="form-group">
																				<label
																					className="form-label"
																					htmlFor="fw-last-name">
																					To
																				</label>
																				<div className="form-control-wrap">
																					<input
																						type="date"
																						className="form-control "
																						placeholder=""
																						id="from-date-filter"
																						value={filterObject.dateTo}
																						onChange={(option) =>
																							setFilterObject((prev) => ({
																								...prev,
																								dateTo: option.target.value,
																							}))
																						}
																						min={minDate}
																						max={maxDate}
																					/>
																				</div>
																			</div>
																		</div>
																		<div className="col-6">
																			<div className="form-group row align-items-center">
																				<label className="overline-title overline-title-alt ">
																					All locations?
																				</label>
																				<div className="form-control-wrap col">
																					<div className="custom-control custom-switch">
																						<input
																							type="checkbox"
																							className="custom-control-input"
																							id="customSwitchActive"
																							checked={
																								!filterObject.alllocationType
																							}
																							onChange={
																								(e) => checkBoxValue(e)
																								// setFilterObject((prev) => ({
																								// 	...prev,
																								// 	alllocationType: e.target
																								// 		.value
																								// 		? true
																								// 		: false,
																								// }))
																							}
																						/>
																						<label
																							className="custom-control-label"
																							htmlFor="customSwitchActive">
																							{filterObject.alllocationType
																								? "Yes"
																								: "No"}
																						</label>
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className="gap gap-10px" />

																		<div className="col-12">
																			<div className="form-group">
																				<button
																					type="button"
																					onClick={filterData}
																					className="btn btn-secondary">
																					<span>Filter</span>
																				</button>
																				<a
																					className="clickable ml-2"
																					onClick={() => clearFilters()}>
																					Reset Filter
																				</a>
																			</div>
																		</div>
																		<div className="gap gap-20px" />
																	</div>
																</div>
															</form>
														</div>
													</div>
												</li>
											</ul>
										</div>
										{pageLimiter > 1 && unHideTable && (
											<div className="card-tools mr-n1">
												<ul className="btn-toolbar gx-1">
													<li>
														<button
															className="btn btn-trigger btn-icon "
															onClick={page !== 1 ? prevLoaded : null}
															disabled={page == 1 ? true : false}
															title="Previous voyages">
															<em className="icon ni ni-arrow-left" />
														</button>
													</li>
													<li className="btn-toolbar-sep" />
													<li>
														<button
															className="btn btn-trigger btn-icon "
															onClick={page >= 1 ? nextLoaded : null}
															disabled={page >= pageLimiter ? true : false}
															title="Next voyages">
															<em className="icon ni ni-arrow-right" />
														</button>
													</li>
													<li className="btn-toolbar-sep" />
													<li>
														<div className="g" data-select2-id={6}>
															<div
																className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3"
																data-select2-id={5}>
																<div>
																	<input
																		type="number"
																		className="form-control border search-input text-align-center"
																		placeholder=""
																		onChange={(e) => goToPage(e.target.value)}
																		value={page}
																		style={{ width: "50px" }}
																	/>
																</div>
																<div>of {`${pageLimiter}`}</div>
															</div>
														</div>
													</li>
												</ul>
											</div>
										)}
									</div>
								}
							</div>
						)}
						<>
							{unHideTable && (
								<div className="" style={{ width: "100%" }}>
									<div className="text-center">
										<button
											type="button"
											className="btn btn-sm btn-link m-1"
											onClick={() => setUnHideTable(false)}>
											View as a chart
										</button>
									</div>
									<BookingTable
										columns={headerList}
										data={utilizationList}
										cssArray={["font-weight-500", "", "", "text-left", "", ""]}
										enableSearch={false}
										emptyMessage="No installation record to show"
										disablePageSize={true}
									/>
								</div>
							)}
						</>
					</div>
				</>
			)}
			{!unHideTable && (
				<div className="text-center">
					<div className="text-center">
						<button
							type="button"
							className="btn btn-sm btn-link m-1"
							onClick={() => setUnHideTable(true)}>
							View as a table
						</button>
					</div>
					<div className="row gx-6 gy-3">
						<div className="col-sm-6">
							<ResponsiveContainer width="100%" height="100%">
								<ComposedChart
									width={520}
									height={180}
									data={sampleData2[0]}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 5,
									}}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="Month" />
									<YAxis type="number" />
									<Tooltip content={<CustomTooltip />} />
									{/* <Legend /> */}
									<Bar
										dataKey="TotalVoyageCount"
										stackId="a"
										fill="#4551bf"
										// onMouseEnter={(e) => tooltipContent(e)}
										// onMouseLeave={() => setShowTooltip(false)}
									/>
									<Bar
										dataKey="CostSum"
										stackId="b"
										fill="#9fa3d4"
										// onMouseEnter={(e) => tooltipContent(e)}
										// onMouseLeave={() => setShowTooltip(false)}
									/>

									<Line
										type="monotone"
										dataKey="TotalVoyageCount"
										stroke="#8B8000"
									/>
									<Line type="monotone" dataKey="CostSum" stroke="#ffa600" />
								</ComposedChart>
							</ResponsiveContainer>
						</div>
						<div className="col-sm-6">
							<BarChart
								width={580}
								height={180}
								data={productivityData[0]}
								margin={{
									top: 20,
									right: 30,
									left: 20,
									bottom: 5,
								}}>
								<CartesianGrid strokeDasharray="3 3" />

								<XAxis dataKey="LocationName" type="category" />
								<YAxis type="number" />
								<Tooltip />
								{productiveBarChart()}
								<Legend />
							</BarChart>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
