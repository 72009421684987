import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";

export default function BulkListComponent({ dataList }) {
  const [tableData, setTableData] = useState(null);
  const [initializing, setInit] = useState(true);

  const headerList = [
    {
      Header: "Booking Reference",
      accessor: "BookingReference",
    },
    {
      Header: "Cargo Evidence",
      accessor: "BulkEvidence",
      cell: "BulkEvidence",
    },
    {
      Header: "Bulk Cargo",
      accessor: "ProductName",
    },
    {
      Header: "Quantity",
      accessor: "Quantity",
    },
    {
      Header: "Quantity Unit",
      accessor: "QuantityUnit",
    },
    {
      Header: "Specific Gravity",
      accessor: "SpecificGravity",
    },
    {
      Header: "Custom Status",
      accessor: "CustomStatus",
    },
  ];

  useEffect(async () => {
    setInit(true);

    setTableData(dataList);
    setInit(false);
  }, [dataList, tableData]);

  return (
    <div
      style={{
        overflow: "auto",
        boxShadow: "rgb(221, 221, 221) 2px 2px 5px 0.1px",
        maxHeight: 400,
        width: "100%",
      }}
    >
      {initializing && <Spinner />}
      {!initializing && (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", "", "", "text-center"]}
          data={dataList || []}
          disablePageSize={true}
          enableSearch={false}
          emptyMessage="No bulk cargo selected"
        />
      )}
    </div>
  );
}
