import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import StartEndVoyageActivityListComponent from "./startEndVoyageActivityList";
import ParallelVoyageActivityListComponent from "./parallelVoyageActivitiesList";
import VoyageActivityService from "../../services/voyageActivityService";
import VoyageService from "../../services/voyageService";
import BulkListComponent from "../voyage/bulkList";
import DeckListComponent from "../voyage/deckList";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import {
  toReadableDate,
  toReadableDateTime,
} from "../../utils/utilityFunctions";
import { camelizeKeys, dynamicSort } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import ActivityService from "../../services/activityService";
import LocationService from "../../services/locationService";

export default function StartSailingVoyageActivityComponent({
  voyagePlanningId,
}) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({
    voyagePlanningId: "",
    etaToNextLocation: "",
    nextLocationId: "",
    actualEndDateOfRunningActivity: "",
    actualSailingStartDate: "",
  });
  const [saving, setSaving] = useState(false);
  const [locations, setLocations] = useState([]);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "startactivity";

  useEffect(async () => {
    await init();
  }, []);

  const init = async (_page, _pageSize) => {
    setInit(true);
    // const _voyageActivity = await VoyageActivityService.getVoyageActivity(
    //   voyageActivityId
    // );
    // let modifiedDataList = camelizeKeys(_voyageActivity?.Data || []);
    // let dataList = [];
    // dataList.push({ ...modifiedDataList });
    // let voyageActivityData = dataList.map((voyageActivity) => ({
    //   ...voyageActivity,
    //   proposedStartDate: toReadableDateTime(voyageActivity.plannedStartDate),
    //   proposedEndDate: toReadableDateTime(voyageActivity.endDate),
    // }));
    // setVoyageActivity(voyageActivityData || []);

    // // await retrieveParallelActivities();
    // _page = _page || 1;
    // _pageSize = _pageSize || 50;
    // const _voyageActivities =
    //   await VoyageActivityService.getVoyageActivitiesList(
    //     _page,
    //     _pageSize,
    //     payload.voyagePlanningId
    //   );

    // const voyageActivitiesDataList = camelizeKeys(
    //   _voyageActivities?.Data.voyageActivities || []
    // );
    // setVoyageActivities(voyageActivitiesDataList);

    // if (_voyageActivities?.Data.TotalCount) {
    //   setTotalRecords(_voyageActivities.Data.TotalCount);
    // }

    // if (_voyageActivities) {
    //   let parallelVoyageActivitiesDataList = voyageActivitiesDataList
    //     .map((voyageActivity) => ({
    //       ...voyageActivity,
    //       plannedStartDate: toReadableDate(voyageActivity.plannedStartDate),
    //       endDate: toReadableDate(voyageActivity.endDate),
    //     }))
    //     .filter(
    //       (activity) =>
    //         activity.activityName !== voyageActivityData[0].activityName &&
    //         activity.locationId == voyageActivityData[0].locationId &&
    //         activity.allowsParallel == true &&
    //         activity.activityStatus == "in-progress"
    //     );
    //   setParallelVoyageActivities(parallelVoyageActivitiesDataList || []);
    // }

    // if (_voyageActivities) {
    //   let _previousVoyageActivity = voyageActivitiesDataList
    //     .map((voyageActivity) => ({
    //       ...voyageActivity,
    //       plannedStartDate: toReadableDate(voyageActivity.plannedStartDate),
    //       endDate: toReadableDate(voyageActivity.endDate),
    //     }))
    //     .find(
    //       (activity) =>
    //         activity.allowsParallel == false &&
    //         activity.activityStatus == "in-progress" &&
    //         activity.plannedStartDate !== null
    //     );
    //   setPreviousVoyageActivity(_previousVoyageActivity || []);
    // }

    let _locationTypes = await LocationService.listLocationTypes(1, 1000);
    let excludedLocationTypes = (_locationTypes || []).filter(
      (a) =>
        a.LocationType?.toLowerCase() == "zone" ||
        a.LocationType?.toLowerCase() == "port terminal-berth" ||
        a.LocationType?.toLowerCase() == "port terminal-warehouse location" ||
        a.LocationType?.toLowerCase() == "logistics base-warehouse location" ||
        a.LocationType?.toLowerCase() == "logistics base-berth"
    );

    const excludedLocationTypeIds = excludedLocationTypes?.map(
      (locationType) => locationType.LocationTypeId
    );

    let _locations = await LocationService.list(1, 1000);
    _locations = (_locations || []).map((e) => ({
      ...e,
      label: e.LocationName,
      value: e.LocationId,
    }));
    if (excludedLocationTypeIds) {
      //activities cannot be performed at an excluded location
      _locations = _locations.filter(
        (a) => !excludedLocationTypeIds.includes(a.LocationTypeId)
      );
    }
    setLocations(_locations || []);

    setInit(false);
  };

  payload.voyagePlanningId = voyagePlanningId || "";
  payload.actualEndDateOfRunningActivity =
    payload?.actualSailingStartDate || "";

  console.log(payload);

  const startSailingActivity = async () => {
    const _payload = { ...payload };

    if (!_payload.actualSailingStartDate) {
      toastr("error", "Pick actual start date");
      return;
    }

    if (!_payload.nextLocationId) {
      toastr("error", "Select next location");
      return;
    }

    if (!_payload.etaToNextLocation) {
      toastr("error", "Pick ETA at next location");
      return;
    }

    setSaving(true);

    const request = await VoyageActivityService.startSailing(_payload);
    if (request) {
      toastr("success", "Sailing activity started");
      setRedirectUrl(`/voyage-activities/${voyagePlanningId}`);
    } else {
      toastr("error", "Sailing activity not started");
    }

    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card card-bordered shadow-lg">
          <div className="card-inner p-5">
            <div className="gap gap-20px" />
            {/* <hr className="preview-hr" /> */}
            <span className="preview-title-lg overline-title">
              Activity to Start{" "}
            </span>
            <div className="gap gap-20px" />
            <form>
              <div className="row align-items-center">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      Actual start date / time
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="datetime-local"
                        data-msg="Required"
                        className="form-control required"
                        id="fw-last-name"
                        name="fw-last-name"
                        required=""
                        onChange={(e) =>
                          addPayload("actualSailingStartDate", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <>
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Next Location
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          onChange={(item) =>
                            addPayload(["nextLocationId"], [item.value])
                          }
                          options={locations}
                          placeholder="select location"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        ETA
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="datetime-local"
                          data-msg="Required"
                          className="form-control required"
                          id="fw-last-name"
                          name="fw-last-name"
                          required=""
                          onChange={(e) =>
                            addPayload("etaToNextLocation", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </form>

            <hr className="preview-hr" />

            {
              <div className="float-right">
                {saving ? (
                  <div className="form-group p-2 m-3">
                    <Spinner size="1.5" />
                  </div>
                ) : (
                  <div className="form-group p-2 m-3">
                    <ReactTooltip className="text-center" id="main">
                      Starting this activity will end the
                      <br /> currently running main activity
                    </ReactTooltip>
                    <button
                      className="btn btn-md btn-dark btn-wide px-5 mr-3"
                      onClick={() => startSailingActivity()}
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Start main activity"
                      data-tip
                      data-for="main"
                    >
                      Start Activity
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-link m-1"
                      onClick={() => window.history.back()}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      )}
    </>
  );
}
