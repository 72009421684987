import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class PoolService {
    // /api/VesselFleet/Get`
    static fetchPool = async () => {
        let response =  await apiCall(`/api/VesselFleet/Get`);
        if (response?.Data){
          response = response?.Data
        }
        return response;
      };
      static postPool = async (payload) => {
        return await apiCall(`/api/VesselFleet/Post`, "POST", payload);
      };
    
}