import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import MyTable from "../../components/dataTable";
import {
  checkUserPermission,
  generateQueryString,
  toReadableDate,
} from "../../utils/utilityFunctions";
import PartyService from "../../services/partyService";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import PaymentService from "../../services/paymentService";

export default function PaymentsList() {
  const navigate = useNavigate();
  const { search: queryParams } = useLocation();
  const [searchParams] = useSearchParams();
  const [tableData, setTableData] = useState([]);
  const [oparators, setOparators] = useState([{}]);
  const [totalCount, setTotalCount] = useState(0);
  const [initializing, setInit] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [filterObject, setFilterObject] = useState(
    Object.fromEntries([...searchParams])
  );

  const clearFilters = () => {
    setFilterObject({
      PaymentReferenceNo: "",
      PartyId: "",
      StartDate: "",
      EndDate: "",
      Status: "",
    });

    navigate("/payments", { replace: true });
  };

  const statusList = [
    { value: "Full Payment", label: "Full Payment" },
    { value: "Partial Payment", label: "Partial Payment" },
  ];

  const filterData = () => {
    const queryStr = generateQueryString(filterObject);
    navigate(`/payments?${queryStr}`, { replace: true });
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li className="nk-block-tools-opt"></li>
        </ul>
      </div>
    </div>
  );

  const rowActions = [
    {
      action: "View Details",
      link: "/payments/",
      property1: "PaymentReferenceNo",
      icon: "icon ni ni-eye",
      show: checkUserPermission("payments_view_details"),
    },
    {
      action: "View Invoice",
      link: "/invoices/",
      property1: "InvoiceId",
      icon: "icon ni ni-eye",
      show: checkUserPermission("invoice_view_details"),
    },
    // {
    //   action: "Download Reciept",
    //   link: "",
    //   externalLink: true,
    //   property1: "fileUrl",
    //   target: "_blank",
    //   icon: "icon ni ni-file-download",
    // },
  ];
  const headerList = [
    {
      Header: "Payment Reference",
      accessor: "PaymentReferenceNo",
    },
    {
      Header: "Payment Date",
      accessor: "PaymentDate",
    },

    {
      Header: "Amount paid",
      accessor: "AmountPaid",
    },
    {
      Header: "Balance",
      accessor: "Balance",
    },

    {
      Header: "Operator",
      accessor: "PartyName",
      link: "/view-party/",
      linkId: "Id",
    },

    {
      Header: "Status",
      accessor: "PaymentStatus",
    },
    {
      Header: "",
      accessor: "menu",
    },
  ];

  useEffect(async () => {
    setInit(true);
    await retrieveOparators();
    await getPaymentHistory();
  }, [queryParams]);

  const getPaymentHistory = async (
    currentPage,
    PageSize,
    query = queryParams
  ) => {
    setInit(true);
    setCurrentPage(currentPage);
    setPageSize(PageSize);

    let res = await PaymentService.search(
      currentPage,
      PageSize,
      query && query.substring(1)
    );

    if (res) {
      const parsedData = res.res.map((payment) => ({
        ...payment,
        PaymentDate: toReadableDate(payment.PaymentDate),
      }));
      setTableData(parsedData);
      setTotalCount(res.TotalCount);
    } else {
      setTableData([]);
      setTotalCount(0);
    }

    setInit(false);
  };

  const retrieveOparators = async () => {
    let res = await PartyService.listParty();
    if (res) {
      res = res.map((e) => ({
        ...e,
        label: e.PartyName,
        value: e.PartyId,
      }));
    }
    setOparators(res);
  };

  const tableTopActions = (
    <div className="card-tools mr-n1" data-select2-id={28}>
      <ul className="btn-toolbar gx-1" data-select2-id={27}>
        <li data-select2-id={26}>
          <div className="toggle-wrap" data-select2-id={25}>
            <div
              className="toggle-content"
              data-content="cardTools"
              data-select2-id={24}
            >
              <ul className="btn-toolbar gx-1" data-select2-id={23}>
                <li className="toggle-close">
                  <a
                    className="btn btn-icon btn-trigger toggle"
                    data-target="cardTools"
                  >
                    <em className="icon ni ni-arrow-left" />
                  </a>
                </li>
                {/* li */}

                <li>
                  <div className="dropdown">
                    {checkUserPermission("payments_filter_payments") && (
                      <a
                        className="btn btn-trigger btn-icon dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <div className="dot dot-primary" />
                        <em className="icon ni ni-filter-alt" />
                      </a>
                    )}
                    <div className="filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-right">
                      <form id="invoice-filter">
                        <div className="dropdown-head">
                          <span className="sub-title dropdown-title">
                            Filter Invoices
                          </span>
                          <div className="dropdown">
                            <a
                              className="btn btn-sm btn-icon"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-cross" title="close" />
                            </a>
                          </div>
                        </div>
                        <div className="dropdown-body dropdown-body-rg">
                          <div className="row gx-6 gy-3">
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="payment-reference-filter"
                                >
                                  Payment Reference
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  placeholder=""
                                  id="payment-reference-filter"
                                  value={filterObject.paymentReferenceNo}
                                  onChange={(e) =>
                                    setFilterObject((prev) => ({
                                      ...prev,
                                      paymentReferenceNo: e.target.value,
                                    }))
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="form-group">
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="from-date-filter"
                                >
                                  From
                                </label>
                                <input
                                  type="date"
                                  className="form-control "
                                  placeholder=""
                                  id="from-date-filter"
                                  value={filterObject.StartDate}
                                  onChange={(e) =>
                                    setFilterObject((prev) => ({
                                      ...prev,
                                      StartDate: e.target.value,
                                    }))
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="form-group">
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="to-date-filter"
                                >
                                  To
                                </label>
                                <input
                                  type="date"
                                  className="form-control "
                                  placeholder=""
                                  id="to-date-filter"
                                  value={filterObject.EndDate}
                                  onChange={(e) =>
                                    setFilterObject((prev) => ({
                                      ...prev,
                                      EndDate: e.target.value,
                                    }))
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              <div
                                className="form-group"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="status-select-filter"
                                >
                                  Status
                                </label>
                                <Select
                                  className="select-dropdown-sm"
                                  id="status-select-filter"
                                  value={statusList.find(
                                    (status) =>
                                      status.value === filterObject.Status
                                  )}
                                  options={statusList}
                                  onChange={(option) =>
                                    setFilterObject((prev) => ({
                                      ...prev,
                                      Status: option.value,
                                    }))
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              <div
                                className="form-group"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <label className="overline-title overline-title-alt">
                                  Operator
                                </label>
                                <Select
                                  className="select-dropdown-sm"
                                  options={oparators}
                                  value={oparators.find(
                                    (operator) =>
                                      operator.PartyId === filterObject.PartyId
                                  )}
                                  onChange={(option) =>
                                    setFilterObject((prev) => ({
                                      ...prev,
                                      PartyId: option.value,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <button
                                  onClick={() => filterData()}
                                  type="button"
                                  className="btn btn-secondary"
                                >
                                  Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="dropdown-foot between">
                          <a className="clickable" onClick={clearFilters}>
                            Reset Filter
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );

  return (
    <MainLayout
      title="Payments"
      loading={initializing}
      showFullLoader={initializing}
      pageActions={pageActions}
    >
      <div>
        {!initializing && (
          <MyTable
            columns={headerList}
            cssArray={["font-weight-500", "", "", ""]}
            data={tableData}
            reloadData={(page, pageSize, query) =>
              getPaymentHistory(page, pageSize, query)
            }
            emptyMessage="No Payments Yet"
            pageSize={pageSize}
            page={currentPage}
            totalCount={totalCount}
            rowActions={rowActions}
            paginationStyle={2}
            tableTopActions={tableTopActions}
          />
        )}
      </div>
    </MainLayout>
  );
}
