import React, { useEffect, useState } from "react";
import { generateQueryString } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";
import { Cell, Legend, Pie, PieChart } from "recharts";
import DateFilter from "../general/DateFilter";
import VesselService from "../../services/vesselService";
import EmptyRecords from "../general/EmptyRecords";

export default function VesselUtilization({ dataList, vesselSpecId = "" }) {
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);

  const colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF0000"];

  useEffect(async () => {
    setLoading(true);
    await getUtilizationData();

    setLoading(false);
  }, [dataList]);

  const getUtilizationData = async (dates) => {
    setLoading(true);
    let payload = { vesselSpecId: vesselSpecId };
    if (dates) payload = { ...payload, ...dates };
    payload = generateQueryString(payload);
    const _res = await VesselService.getVesselUtilizationData(payload);
    const data = _res?.Data[0];
    if (!data) {
      setChartData([]);
      setLoading(false);
      return;
    }

    const chartData = [
      {
        name: "Time in port",
        value: Math.round(data.TimeinPort),
      },
      {
        name: "Time underway",
        value: Math.round(data.TimeUnderway),
      },
      {
        name: "Time waiting",
        value: Math.round(data.TimeWaiting),
      },
      {
        name: "Time Idle",
        value: Math.round(data.TimeIdle),
      },
    ];
    setChartData(chartData || []);
    setLoading(false);
  };

  return (
    <div style={{ zoom: "0.85" }}>
      <div className="d-flex justify-between mb-2">
        <h4>Vessel Utilization</h4>

        <DateFilter callback={getUtilizationData} />
      </div>
      {loading ? (
        <div className="center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        <div>
          {/* pie-chart */}
          {!!chartData.length ? (
            <div className="text-center">
              <PieChart width={600} height={600} className="mx-auto">
                <Legend />
                <Pie data={chartData} cx="50%" cy="50%" label>
                  {chartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index]} />
                  ))}
                </Pie>
              </PieChart>
            </div>
          ) : (
            <EmptyRecords message="No data for time frame" />
          )}
        </div>
      )}
    </div>
  );
}
