import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import VoyageService from "../../services/voyageService";
import VoyageActivitiesComponent from "../../components/voyageReporting/voyageActivitiesComponent";
import VoyageDetailsActivityComponent from "../../components/voyageReporting/voyageDetailsActivityComponent";
import AddLocationCostComponent from "../../components/voyage/addLocationCostComponent";
import AddVesselCostComponent from "../../components/voyage/addVesselCostComponent";
import Tabs from "../../utils/tabs";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";

export default function ReviewVoyagePage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [voyage, setVoyage] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);

  useEffect(async () => {
    setLoading(true);
    await init();
    await retrieveVoyage();
    setLoading(false);
  }, [params.voyagePlanningId]);

  const init = async () => {};

  const retrieveVoyage = async () => {
    const _voyage = await VoyageService.get(params.voyagePlanningId);
    setVoyage(_voyage || []);
  };

  const reviewVoyage = async () => {
    let _reviewVoyage = await VoyageService.updateVoyageStatus(
      params.voyagePlanningId,
      "Reviewed"
    );
    if (_reviewVoyage) {
      toastr("success", "Voyage reviewed");
      setSelectedRecord(null);
      await retrieveVoyage();
    }

    setLoading(false);
  };

  const tabHeaders = [
    { icon: "icon ni ni-files", text: "Voyage Details" },
    { icon: "icon ni ni-view-grid-wd", text: "Voyage Activities" },
    { icon: "icon ni ni-money", text: "Location Cost" },
    { icon: "icon ni ni-money", text: "Vessel Cost" },
  ];

  const tabContent = [
    <VoyageDetailsActivityComponent voyage={voyage} />,
    <VoyageActivitiesComponent
      voyagePlanningId={params.voyagePlanningId}
      voyage={voyage}
    />,
    <AddLocationCostComponent voyagePlanningId={params.voyagePlanningId} />,
    <AddVesselCostComponent voyagePlanningId={params.voyagePlanningId} />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {voyage.VoyagePlanningStatus !== "Completed" ? null : (
            <>
              <li>
                <Link
                  to={`/add-activity/${params.voyagePlanningId}`}
                  className="btn btn-white btn-outline-light"
                >
                  <em className="icon ni ni-plus" />
                  <span>Add Voyage Activity</span>
                </Link>
              </li>

              <li>
                <a
                  role="button"
                  className="btn btn-white btn-outline-light"
                  onClick={() => setSelectedRecord(voyage)}
                >
                  <em className="icon ni ni-check" />
                  <span>Confirm Voyage Review</span>
                </a>
              </li>
            </>
          )}

          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to Voyages</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Review Voyage"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <div className="">{_tabView}</div>

      {selectedRecord && voyage && (
        <CustomModal
          title={voyage.VoyageCode}
          content={
            <>
              <div className="">
                <div className="text-center">
                  <h5>Has voyage review been completed?</h5>
                  <h6>Changes can still be made before voyage is finalized</h6>
                  <br />
                  <p>1. Ensure all voyage activities are completed.</p>
                  <p>2. Ensure all required costs have been allocated.</p>
                </div>
                <br />
                <div className="row">
                  <div className="col-6 text-center">
                    <a
                      role="button"
                      className="btn btn-lg btn-success active"
                      onClick={reviewVoyage}
                    >
                      Yes
                    </a>
                  </div>

                  <div className="col-6 text-center">
                    <a
                      role="button"
                      className="btn btn-lg btn-danger active"
                      onClick={() => setSelectedRecord(null)}
                    >
                      Hold on
                    </a>
                  </div>
                </div>
              </div>
            </>
          }
          onClose={() => setSelectedRecord(null)}
          isClosed={false}
        />
      )}
    </MainLayout>
  );
}
