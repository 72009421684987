import React, { useEffect, useRef, useState } from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";
import VesselService from "../../services/vesselService";
import CustomModal from "../../utils/customModal";
import EditVesselDetailsComponent from "./editVesselDetailsComponent";
import CountryService from "../../services/countriesService";
import DPService from "../../services/dynamicPositioning";
import FileInput from "../fileInput";
import toastr from "../../utils/toastr";
import { Link } from "react-router-dom";
import SetVesselHireComponent from "./SetVesselHireComponent";
import { checkUserPermission } from "../../utils/utilityFunctions";
import EquipmentService from "../../services/equipmentService";

export default function VesselDetailsComponent({ vesselSpecId, isEdit }) {
  const [vessel, setVessel] = useState({});
  const [initializing, setInit] = useState(true);
  const [modalIsClosed, setModalIsClosed] = useState(true);
  const [hireModalIsClosed, setHireModalIsClosed] = useState(true);
  const [countries, setCountries] = useState([]);
  const [vesselCountry, setVesselCountry] = useState({});
  const [dynamicPositioningList, setDynamicPositioningList] = useState([]);
  const [vesselDp, setVesselDp] = useState({});
  const [vesselType, setVesselType] = useState({});
  const [vesselTypes, setVesselTypes] = useState([{}]);
  const [imageUpdating, setImageUpdating] = useState(false);
  const [offHireStatus, setOffHireStatus] = useState(false);
  const [activeContract, setActiveContract] = useState({});
  const [vesselStorageTanks, setVesselStorageTanks] = useState([]);
  const [vesselPumps, setVesselPumps] = useState([]);

  const fileInputRef = useRef();

  const getDetails = async () => {
    const _vessel = await VesselService.fetchVesselExtended(vesselSpecId);
    setVessel(_vessel || {});
    return _vessel;
  };

  //   this is to display the readable name of ceertain vessel details.
  const parseDetailNmaes = (
    _vessel = vessel,
    _countries = countries,
    _dynamicPositioningList = dynamicPositioningList,
    _vesselTypes = vesselTypes
  ) => {
    if (_countries && _vessel && _vessel.CountryId) {
      const _vesselCountry = _countries.find(
        (c) => c.CountryId == _vessel.CountryId
      );
      setVesselCountry(_vesselCountry || {});
    }

    if (_dynamicPositioningList && _vessel && _vessel.DynamicPositioningId) {
      const _vesselDp = _dynamicPositioningList.find(
        (c) => c.DynamicPositioningId == _vessel.DynamicPositioningId
      );
      setVesselDp(_vesselDp || {});
    }

    if (_vesselTypes && _vessel && _vessel.VesselTypeId) {
      const _vesselType = _vesselTypes.find(
        (c) => c.VesselTypeId == _vessel.VesselTypeId
      );
      setVesselType(_vesselType || {});
    }
  };

  const editCallback = async () => {
    onModalClose();
    setInit(true);
    const _vessel = await getDetails();
    parseDetailNmaes(_vessel);
    setInit(false);
  };

  const triggerHireModal = async () => {
    setHireModalIsClosed(false);
  };

  const retrieveEquipmentList = async () => {
    const res = await EquipmentService.listVesselEquipmentV2(vesselSpecId);
    const tanks = [];
    const pumps = [];
    // separate the storage tanks and pumps
    res &&
      res.map((item) => {
        item.EquipmentName == "Storage Tank" && tanks.push(item);
        item.EquipmentName == "Pump" && pumps.push(item);
      });
    setVesselStorageTanks(tanks);
    setVesselPumps(pumps);
  };

  useEffect(async () => {
    setInit(true);
    const _vessel = await getDetails();

    const _countries = await CountryService.listCountry();
    setCountries(_countries || []);

    const _dpList = await DPService.dpList();
    setDynamicPositioningList(_dpList || []);

    const _vesselTypes = await VesselService.fetchVesselTypes();
    setVesselTypes(_vesselTypes);

    parseDetailNmaes(_vessel, _countries, _dpList, _vesselTypes);

    await retrieveEquipmentList();

    await getActiveContract();

    if (isEdit) {
      setModalIsClosed(false);
    }

    // setInit(false);
  }, [vesselSpecId]);

  const getActiveContract = async () => {
    setInit(true);
    onModalClose();

    const activeContract = await VesselService.getActiveVesselContract(
      vesselSpecId
    );

    setOffHireStatus(activeContract?.IsCurrentlyOffHire);
    setActiveContract(activeContract);
    setInit(false);

    return activeContract;
  };

  const dataItem = (label, value) => {
    if (value == "null") value = "";
    return (
      <li className="col-6 col-lg-3">
        <p>
          <span className="text-soft small">{label}</span> {value || "-"}
        </p>
      </li>
    );
  };

  const onModalClose = () => {
    setModalIsClosed(true);
    setHireModalIsClosed(true);
  };

  const updateVesselImage = async (base64) => {
    if (!base64) return;
    setImageUpdating(true);
    const _updatedRecord = await VesselService.updateVesselImage(
      vesselSpecId,
      base64
    );
    if (!_updatedRecord) {
      toastr("error", "Image update failed");
    } else {
      let __vessel = { ...vessel };
      __vessel.PhotoUrl = _updatedRecord.PhotoUrl;
      setVessel(__vessel);
    }

    setImageUpdating(false);
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card sp-plan">
          <div className="row no-gutters">
            <div className="col-md-8">
              <div className="sp-plan-info card-inner">
                <div className="row gx-0 gy-3">
                  <div className="col-xl-9 col-sm-8 ">
                    <div className="sp-plan-name">
                      <h6 className="title">
                        <Link to="">
                          {vessel.VesselName}
                          {vessel.status && (
                            <span className="badge badge-light badge-pill ml-3">
                              {vessel.status}
                            </span>
                          )}
                        </Link>
                        {checkUserPermission("vessel_toggle_offhire") &&
                          activeContract?.VesselCommercialId && (
                            <>
                              <span
                                className={`badge  badge-pill ml-3 ${
                                  offHireStatus
                                    ? "badge-danger"
                                    : "badge-success"
                                } `}
                              >
                                {offHireStatus ? "off" : "on"}-hire
                              </span>
                              <span onClick={triggerHireModal}>
                                <em
                                  className={`icon ni ml-1 pointer align-middle fs-22px  ${
                                    offHireStatus
                                      ? "ni-toggle-off text-danger"
                                      : "ni-toggle-on text-success"
                                  }`}
                                  title="toggle hire status"
                                />
                              </span>
                            </>
                          )}
                      </h6>
                      <p style={{ visibility: "hidden" }}>
                        IMO: <span className="text-base">{vessel.Imo}</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-4"></div>
                </div>
              </div>
              <div className="sp-plan-desc card-inner">
                <ul className="row gx-1">
                  {dataItem("IMO", vessel.Imo)}
                  {dataItem("MMSI", vessel.Mmsi)}
                  {dataItem("Fleet", vessel.fleet)}
                  {dataItem("Year of build", vessel.YearOfBuild)}
                  {dataItem(
                    "Vessel type",
                    vessel.vesselType || vesselType.VesselType || "-"
                  )}
                  {dataItem("Liquid oil", vessel.LiquidOil || "-")}

                  {dataItem("Draught", vessel.Draught)}
                  {dataItem("Max draught", vessel.DraughtMax)}
                </ul>
                <hr style={{ opacity: "0.25" }} />
                <ul className="row gx-1">
                  {dataItem(
                    "Net registered tonnage",
                    vessel.NetRegisteredTonnage || "-"
                  )}
                  {dataItem(
                    "Gross registered tonnage",
                    vessel.GrossRegisteredTonnage || vessel.Grt || "-"
                  )}
                  {dataItem("Dead weight", vessel.SummerDwt)}
                  {dataItem("Service speed", vessel.SpeedService)}
                  {dataItem("Max speed", vessel.SpeedMax || "-")}
                  {dataItem("BHP", vessel.Bhp || "-")}
                  {dataItem("Beam", vessel.Beam || "-")}
                  {dataItem("Rate of turn", vessel.RateOfTurn || "-")}
                </ul>
                <hr style={{ opacity: "0.25" }} />
                <ul className="row gx-1">
                  {dataItem("Breadth extreme", vessel.BreadthExtreme || "-")}
                  {dataItem("Deck area", vessel.DeckArea || "-")}
                  {dataItem(
                    "Deck Cargo Capacity",
                    vessel.DeckCargoCapacity || "-"
                  )}
                  {dataItem("Call sign", vessel.CallSign || "-")}
                  {dataItem("Country", vesselCountry.CountryName || "-")}
                  {dataItem(
                    "Displacement summer",
                    vessel.DisplacementSummer || "-"
                  )}
                  {dataItem(
                    "Dynamic Positioning",
                    vesselDp.DynamicPositioning || "-"
                  )}
                  {dataItem("Fuel consumption", vessel.FuelConsumption || "-")}
                </ul>
                <hr style={{ opacity: "0.25" }} />
                <ul className="row gx-1">
                  {dataItem("Length overall", vessel.LengthOverall)}
                  {dataItem(
                    "Length between perpendicular",
                    vessel.LengthBetweenPerpendicular || "-"
                  )}
                  {dataItem("Crew cabins", vessel.NoOfCrewCabins || "-")}
                  {dataItem(
                    "Passenger cabins",
                    vessel.NoOfPassengerCabins || "-"
                  )}
                  {dataItem("Place of build", vessel.PlaceOfBuild || "-")}
                  {dataItem("Width", vessel.Width || "-")}
                  {dataItem("Vessel Email", vessel.VesselEmail || "-")}
                  {dataItem(
                    "Vessel Satelite Phone",
                    vessel.VesselSatelitePhone || "-"
                  )}
                </ul>
                {/* storage tanks */}
                {vesselStorageTanks?.length && (
                  <>
                    {" "}
                    <hr style={{ opacity: "0.25" }} />
                    <h6 className="title">Storage Tanks</h6>
                    {vesselStorageTanks.map((tank) => (
                      <ul className="row gx-1">
                        {dataItem("Tank", tank.Product || "N/A")}
                        {dataItem("Count", tank.EquipmentCount)}
                        {dataItem("Capacity", tank.CapacityMeasure)}
                        {dataItem(
                          "Measurement Unit",
                          tank.MeasurementUnit || "-"
                        )}
                      </ul>
                    ))}
                  </>
                )}

                {/* pumps */}
                {vesselPumps?.length && (
                  <>
                    <hr style={{ opacity: "0.25" }} />
                    <h6 className="title">Pumps</h6>
                    {vesselPumps.map((pump) => (
                      <ul className="row gx-1">
                        {dataItem("Pump", pump.Product || "N/A")}
                        {dataItem("Count", pump.EquipmentCount)}
                        {dataItem("Capacity", pump.CapacityMeasure)}
                        {dataItem(
                          "Measurement Unit",
                          pump.MeasurementUnit || "-"
                        )}
                      </ul>
                    ))}{" "}
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="vessel-image-container sp-plan-action card-inner"
                style={{
                  maxHeight: "400px",
                  backgroundImage: `url(${vessel.PhotoUrl})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              >
                {checkUserPermission("vessel_change_image") && (
                  <div className="sp-plan-btn">
                    <button
                      onClick={() => openFileDialog()}
                      className="btn btn-primary"
                      type="button"
                    >
                      Change vessel image
                    </button>
                  </div>
                )}
                <div className="sp-plan-note text-md-center">
                  {imageUpdating && <Spinner />}
                </div>
              </div>
              <div
                style={{
                  width: "1px",
                  height: "1px",
                  overflow: "hidden",
                  visibility: "hidden",
                }}
              >
                <FileInput
                  htmlRef={fileInputRef}
                  onChange={(file) => updateVesselImage(file?.base64)}
                />
              </div>
            </div>
            <div className="col-12">
              {checkUserPermission("vessel_edit_details") && (
                <button
                  onClick={() => setModalIsClosed(false)}
                  type="button"
                  className="mt-4 btn btn-dark btn-sm "
                >
                  <span>Edit vessel details</span>
                </button>
              )}

              {vessel.activeVoyagePlanningId && (
                <Link to={`/view-activity/${vessel.activeVoyagePlanningId}`}>
                  <button
                    type="button"
                    className="mt-4 btn btn-dark btn-dim btn-sm ml-3 "
                  >
                    <span>View vessel activities</span>
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
      {!modalIsClosed && (
        <CustomModal
          title="Edit vessel"
          isClosed={modalIsClosed}
          content={
            <EditVesselDetailsComponent
              vesselSpecId={vesselSpecId}
              callback={editCallback}
              dynamicPositions={dynamicPositioningList}
              vesselTypes={vesselTypes}
              countryList={countries}
              vessel={vessel}
            />
          }
          onClose={() => onModalClose()}
        />
      )}

      {!hireModalIsClosed && (
        <CustomModal
          title={`Set ${offHireStatus ? "On" : "Off"}-Hire`}
          isClosed={hireModalIsClosed}
          content={
            <SetVesselHireComponent
              vesselSpecId={vesselSpecId}
              callback={getActiveContract}
              vesselName={vessel.VesselName}
              isOffHire={offHireStatus}
              activeContract={activeContract}
            />
          }
          onClose={() => onModalClose()}
        />
      )}
    </>
  );
}
