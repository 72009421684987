import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class UserService {
  static addNewUser = async (payload) => {
    return await apiCall(`/api/User/AddNewUser`, "POST", payload);
  };

  static updateUserProfile = async (payload) => {
    return await apiCall(`/api/User/UpdateProfile`, "POST", payload);
  };

  static assignRolesToUser = async (payload) => {
    return await apiCall(`/api/User/AssignRole`, "POST", payload);
  };

  static updateUserPassword = async (payload) => {
    return await apiCall(`/api/User/UserPasswordUpdate`, "POST", payload);
  };

  static passwordResetByAdmin = async (payload) => {
    return await apiCall(`/api/User/AdminPasswordReset`, "POST", payload);
  };

  static getSearchableUserList = async (
    page,
    pageSize,
    userId,
    userName,
    firstName,
    lastName,
    startDate,
    endDate,
    phoneNumber,
    email
  ) => {
    page = page || 1;
    pageSize = pageSize || 50;
    userId = userId || "";
    userName = userName || "";
    firstName = firstName || "";
    lastName = lastName || "";
    startDate = startDate || "";
    endDate = endDate || "";
    phoneNumber = phoneNumber || "";
    email = email || "";

    let response = await apiCall(
      `/api/User/SearchList?pageCount=${page}&noPerPage=${pageSize}&userId=${userId}&userName=${userName}&firstName=${firstName}&lastName=${lastName}&startDate=${startDate}&endDate=${endDate}&phoneNumber=${phoneNumber}&email=${email}`,
      "GET"
    );
    return response?.Data;
  };

  static getUserById = async (userId) => {
    let response = await apiCall(
      `/api/User/GetUserById?userId=${userId}`,
      "GET"
    );
    return response?.Data;
  };
  static getUserByRoleName = async (roleName) => {
    let response = await apiCall(
      `/api/User/ListUsersByRole?roleName=${roleName}`,
      "GET"
    );
    return response;
  };

  static blockUser = async (payload) => {
    return await apiCall(`/api/User/LockoutUser`, "POST", payload);
  };

  static unBlockUser = async (payload) => {
    return await apiCall(`/api/User/UnLockoutUser`, "POST", payload);
  };

  static getUsersByRole = async (roleName, roleId) => {
    roleName = roleName || "";
    roleId = roleId || "";

    let response = await apiCall(
      `/api/User/ListUsersByRole?roleName=${roleName}&roleId=${roleId}`,
      "GET"
    );
    return response;
  };
}
