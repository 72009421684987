import React, { Component } from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; FOR APP.js
// import "slick-carousel/slick/slick-theme.css";

export default class SliderComponent extends Component {
  render() {
    const settings = {
      dots: true,
    //   infinite: true,
      className: "center",
      centerMode: true,
      centerPadding: "70px",
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
    };
    return (
      <div>
        {/* <h2> Multiple items </h2> */}
        <Slider {...settings}>
            <div>
              <h3>1</h3>
            </div>
            <div>
              <h3>2</h3>
            </div>
            <div>
              <h3>3</h3>
            </div>
            <div>
              <h3>4</h3>
            </div>
            <div>
              <h3>5</h3>
            </div>
            <div>
              <h3>6</h3>
            </div>
            <div>
              <h3>7</h3>
            </div>
            <div>
              <h3>8</h3>
            </div>
            <div>
              <h3>9</h3>
            </div>
        </Slider>
      </div>
    );
  }
}
