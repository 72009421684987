import React, { useEffect, useRef, useState } from "react";
import Spinner from "../../utils/spinner";
import CustomModal from "../../utils/customModal";

import toastr from "../../utils/toastr";
import Select from "react-select";
import LocationService from "../../services/locationService";

export default function CostsLocationComponent({ locationTypeId, currency }) {
  const [loading, setLoading] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [modalIsClosed, setModalIsClosed] = useState(true);

  const [locations, setLocations] = useState([]);

  const getLocations = async () => {
    const _locations = await LocationService.search(
      1,
      30,
      null,
      locationTypeId
    );
    setLocations(_locations?.Data || []);
  };

  const discardChanges = () => {
    setSelectedLocation({});
  };

  const saveChanges = () => {
    setSelectedLocation({});
  };

  const editCallback = async () => {
    onModalClose();
    setLoading(true);
    setLoading(false);
  };

  useEffect(async () => {
    setLoading(true);

    await getLocations();

    setLoading(false);
  }, []);

  const dataItem = (data) => {
    return (
      <div className="col col-md-3  mb-4" key={data?.LocationId}>
        <label className="form-label ">{data.LocationName}</label>
        {selectedLocation.LocationId !== data.LocationId ? (
          <div className="d-flex  align-center">
            <p className="mb-0 ">{data.cost || "N/A"}</p>

            <em
              className="icon ni ni-edit text-primary fs-20px pointer mx-3"
              title="Edit"
              onClick={() => {
                setSelectedLocation(data);
              }}
            ></em>
          </div>
        ) : (
          <div className="form-control-wrap d-flex  align-center">
            <div className="form-group col mb-0 px-0">
              <Select
                value={currency
                  .map((c) => ({
                    ...c,
                    label: c.CorrespondingData,
                    value: c.ConfigurationId,
                  }))
                  .find((a) => a.value == selectedLocation?.ConfigurationId)}
                options={currency.map((c) => ({
                  ...c,
                  label: c.CorrespondingData,
                  value: c.ConfigurationId,
                }))}
                onChange={(e) => {
                  setSelectedLocation((prev) => ({
                    ...prev,
                    ConfigurationId: e.value,
                  }));
                }}
              />
            </div>

            <div className="form-group col">
              <div className="form-control-wrap d-flex align-center">
                <input
                  type="text"
                  className="form-control required"
                  required
                  onChange={(e) =>
                    setSelectedLocation((prev) => ({
                      ...prev,
                      Cost: e.target.value,
                    }))
                  }
                />
                <em
                  class="icon ni ni-trash text-danger fs-22px pointer mx-3"
                  title="Discard"
                  onClick={discardChanges}
                ></em>
                <em
                  class="icon ni ni-check text-primary fs-22px pointer"
                  title="Save"
                  onClick={saveChanges}
                ></em>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const onModalClose = () => {
    setModalIsClosed(true);
  };

  return (
    <>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card sp-plan">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="sp-plan-desc card-inner">
                <ul className="row gx-1 mt-5">
                  {/* locations start */}
                  {locations.map((location) => dataItem(location))}
                </ul>
              </div>
            </div>

            {/* <div className="col-12">
              <button
                onClick={() => setModalIsClosed(false)}
                type="button"
                className="mt-4 btn btn-dark btn-sm "
              >
                <span>Edit vessel details</span>
              </button>
            </div> */}
          </div>
        </div>
      )}
      {!modalIsClosed && (
        <CustomModal
          title="Edit vessel"
          isClosed={modalIsClosed}
          // content={
          //   // <EditVesselDetailsComponent
          //   //   vesselSpecId={vesselSpecId}
          //   //   callback={editCallback}
          //   //   dynamicPositions={dynamicPositioningList}
          //   //   vesselTypes={vesselTypes}
          //   //   countryList={countries}
          //   //   vessel={vessel}
          //   // />
          // }
          onClose={() => onModalClose()}
        />
      )}
    </>
  );
}
