import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import ConfigurationService from "../../services/configurationService";
import CustomModal from "../../utils/customModal";
import { toReadableDate, camelizeKeys } from "../../utils/utilityFunctions";
import { forEach } from "lodash";

export default function FinalVesselCostComponent({
  voyagePlanningId,
  voyage,
  currency,
}) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "addVesselCost";

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);

    setLoading(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <>
          <form>
            <div className="card-inner">
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label small">Vessel Cost</label>
                    <div className="form-control-wrap">
                      <input
                        type="number"
                        className="form-control"
                        value={voyage?.ExtraCostIncurredOnVoyage}
                        required
                        disabled
                        // onChange={(e) => addPayload("amount", e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label small">Currency</label>
                    <div className="form-control-wrap">
                      <Select
                        value={currency
                          ?.map((c) => ({
                            ...c,
                            label: c.CorrespondingData,
                            value: c.ConfigurationId,
                          }))
                          ?.find(
                            (a) =>
                              a.value ==
                              voyage?.ExtraCostCurrencyConfigurationId
                          )}
                        isDisabled={true}
                        // options={currency.map((c) => ({
                        //   ...c,
                        //   label: c.CorrespondingData,
                        //   value: c.ConfigurationId,
                        // }))}
                        // onChange={(e) => {
                        //   addPayload("currencyConfigurationId", e.value);
                        // }}
                        // placeholder="Select currency"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* <hr className="preview-hr" /> */}
        </>
      )}
    </>
  );
}
