import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import StartEndVoyageActivityListComponent from "./startEndVoyageActivityList";
import ParallelVoyageActivityListComponent from "./parallelVoyageActivitiesList";
import VoyageActivityService from "../../services/voyageActivityService";
import VoyageService from "../../services/voyageService";
import BulkListComponent from "../voyage/bulkList";
import DeckListComponent from "../voyage/deckList";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import {
  toReadableDate,
  toReadableDateTime,
} from "../../utils/utilityFunctions";
import { camelizeKeys, dynamicSort } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import CargoHandlingService from "../../services/cargoHandlingService";
import VoyageActivityDeckCargoList from "./voyageActivityDeckCargoList";
import VoyageActivityBulkCargoList from "./voyageActivityBulkCargoList";
import UpdateCargoLogsComponent from "./updateCargoLogsComponent";

export default function EndVoyageActivityComponent({ voyageActivityId }) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [voyageActivity, setVoyageActivity] = useState([]);
  const [bookingRequests, setBookingRequests] = useState([]);
  const [selectedBookingId, setSelectedBookingId] = useState("");
  const [totalRecords, setTotalRecords] = useState(15);
  const [parallelVoyageActivities, setParallelVoyageActivities] = useState([]);
  const [confirmPopup, setConfirmPopup] = useState(null);
  const [bulkCargos, setBulkCargos] = useState([]);
  const [deckCargos, setDeckCargos] = useState([]);
  const [loadingBulkCargos, setLoadingBulkCargos] = useState([]);
  const [loadingDeckCargos, setLoadingDeckCargos] = useState([]);
  const [dischargingBulkCargos, setDischargingBulkCargos] = useState([]);
  const [dischargingDeckCargos, setDischargingDeckCargos] = useState([]);
  const [offshoreBulkCargos, setOffshoreBulkCargos] = useState([]);
  const [offshoreDeckCargos, setOffshoreDeckCargos] = useState([]);
  const [modalIsClosed, setModalIsClosed] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "endactivity";

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setInit(true);
    const _voyageActivity = await VoyageActivityService.getVoyageActivity(
      voyageActivityId
    );
    let modifiedDataList = camelizeKeys(_voyageActivity?.Data || []);
    let dataList = [];
    dataList.push({ ...modifiedDataList });
    let voyageActivityData = dataList.map((voyageActivity) => ({
      ...voyageActivity,
      proposedStartDate: toReadableDateTime(voyageActivity.plannedStartDate),
      proposedEndDate: toReadableDateTime(voyageActivity.endDate),
    }));
    setVoyageActivity(voyageActivityData || []);

    if (
      voyageActivityData &&
      (voyageActivityData[0].activityName == "Port Loading Deck cargo" ||
        voyageActivityData[0].activityName == "Port Loading Bulk cargo")
    ) {
      const _loadingActivity = await CargoHandlingService.getLoadingCargos(
        voyageActivityData[0].voyagePlanningId,
        voyageActivityData[0].locationId
      );

      if (_loadingActivity) {
        let _loadingBulkCargo = _loadingActivity?.BulkCargos.map((cargo) => [
          {
            cargoName: cargo.BookingReference + " - " + cargo.ProductName,
            updateButtonComponent: endActivityUpdates(cargo),
            rob: cargo?.ActivityEndLog?.Rob || "N/A",
            quantityUnit: cargo?.ActivityEndLog?.RobUnitName || "N/A",
            bulkCargoDetailId: cargo.BulkCargoDetailId,
          },
        ]);
        setLoadingBulkCargos(_loadingBulkCargo.flat(2) || []);

        let _loadingDeckCargo = _loadingActivity?.DeckCargos.map((cargo) => [
          {
            cargoName:
              cargo.BookingReference + " - " + cargo.DescriptionOfGoods,
            updateButtonComponent: endActivityUpdates(cargo),
            cargoWeight: cargo?.ActivityEndLog?.WeightOfLoadedItem || "N/A",
            numberOfLifts: cargo?.ActivityEndLog?.NumberOfLifts || "N/A",
            quantityUnit: cargo?.ActivityEndLog?.MeasurementUnitName || "N/A",
            deckCargoDetailId: cargo.DeckCargoDetailId,
          },
        ]);
        setLoadingDeckCargos(_loadingDeckCargo.flat(2) || []);
        setBulkCargos(_loadingBulkCargo.flat(2) || []);
        setDeckCargos(_loadingDeckCargo.flat(2) || []);
      }
    }

    if (
      voyageActivityData &&
      (voyageActivityData[0].activityName == "Port Discharging Deck cargo" ||
        voyageActivityData[0].activityName == "Port Discharging Bulk cargo")
    ) {
      const _dischargeActivity =
        await CargoHandlingService.getDischargingCargos(
          voyageActivityData[0].voyagePlanningId,
          voyageActivityData[0].locationId
        );

      if (_dischargeActivity) {
        let _dischargingBulkCargo = _dischargeActivity?.BulkCargos.map(
          (cargo) => [
            {
              cargoName: cargo.BookingReference + " - " + cargo.ProductName,
              updateButtonComponent: endActivityUpdates(cargo),
              rob: cargo?.ActivityEndLog?.Rob || "N/A",
              quantityUnit: cargo?.ActivityEndLog?.RobUnitName || "N/A",
              bulkCargoDetailId: cargo.BulkCargoDetailId,
            },
          ]
        );
        setDischargingBulkCargos(_dischargingBulkCargo.flat(2) || []);

        let _dischargingDeckCargo = _dischargeActivity?.DeckCargos.map(
          (cargo) => [
            {
              cargoName:
                cargo.BookingReference + " - " + cargo.DescriptionOfGoods,
              updateButtonComponent: endActivityUpdates(cargo),
              cargoWeight: cargo?.ActivityEndLog?.WeightOfLoadedItem || "N/A",
              numberOfLifts: cargo?.ActivityEndLog?.NumberOfLifts || "N/A",
              quantityUnit: cargo?.ActivityEndLog?.MeasurementUnitName || "N/A",
              deckCargoDetailId: cargo.DeckCargoDetailId,
            },
          ]
        );
        setDischargingDeckCargos(_dischargingDeckCargo.flat(2) || []);
        setBulkCargos(_dischargingBulkCargo.flat(2) || []);
        setDeckCargos(_dischargingDeckCargo.flat(2) || []);
      }
    }

    if (
      voyageActivityData &&
      (voyageActivityData[0].activityName == "Offshore Handling Deck cargo" ||
        voyageActivityData[0].activityName == "Offshore Handling Bulk cargo")
    ) {
      const _offshoreActivity = await CargoHandlingService.getOffshoreCargos(
        voyageActivityData[0].voyagePlanningId,
        voyageActivityData[0].locationId
      );

      if (_offshoreActivity) {
        let _offshoreBulkCargo = _offshoreActivity?.BulkCargos.map((cargo) => [
          {
            cargoName: cargo.BookingReference + " - " + cargo.ProductName,
            updateButtonComponent: endActivityUpdates(cargo),
            rob: cargo?.ActivityEndLog?.Rob || "N/A",
            quantityUnit: cargo?.ActivityEndLog?.RobUnitName || "N/A",
            bulkCargoDetailId: cargo.BulkCargoDetailId,
          },
        ]);
        setOffshoreBulkCargos(_offshoreBulkCargo.flat(2) || []);

        let _offshoreDeckCargo = _offshoreActivity?.DeckCargos.map((cargo) => [
          {
            cargoName:
              cargo.BookingReference + " - " + cargo.DescriptionOfGoods,
            updateButtonComponent: endActivityUpdates(cargo),
            cargoWeight: cargo?.ActivityEndLog?.WeightOfLoadedItem || "N/A",
            numberOfLifts: cargo?.ActivityEndLog?.NumberOfLifts || "N/A",
            quantityUnit: cargo?.ActivityEndLog?.MeasurementUnitName || "N/A",
            deckCargoDetailId: cargo.DeckCargoDetailId,
          },
        ]);
        setOffshoreDeckCargos(_offshoreDeckCargo.flat(2) || []);
        setBulkCargos(_offshoreBulkCargo.flat(2) || []);
        setDeckCargos(_offshoreDeckCargo.flat(2) || []);
      }
    }

    const _voyageActivities =
      await VoyageActivityService.getVoyageActivitiesList(
        1,
        1000,
        payload.voyagePlanningId
      );
    let voyageActivitiesDataList = camelizeKeys(
      _voyageActivities?.Data.voyageActivities || []
    );
    if (_voyageActivities?.Data.TotalCount) {
      setTotalRecords(_voyageActivities.Data.TotalCount);
    }
    voyageActivitiesDataList = voyageActivitiesDataList
      .map((voyageActivity) => ({
        ...voyageActivity,
        plannedStartDate: toReadableDate(voyageActivity.plannedStartDate),
        endDate: toReadableDate(voyageActivity.endDate),
      }))
      .filter(
        (activity) =>
          activity.activityName !== voyageActivityData[0].activityName &&
          activity.locationId == voyageActivityData[0].locationId &&
          activity.allowsParallel == true &&
          activity.activityStatus == "in-progress"
      );
    setParallelVoyageActivities(voyageActivitiesDataList || []);

    setInit(false);
  };

  const editCallback = async () => {
    onModalClose();
    await init();
  };

  const onModalClose = () => {
    setModalIsClosed(true);
  };

  const endActivityUpdates = (cargo) => {
    return (
      <div>
        <a
          role="button"
          className="btn btn-sm btn-warning active"
          onClick={() => {
            setModalIsClosed(false);
            setSelectedRecord(cargo);
          }}
        >
          Update
        </a>
      </div>
    );
  };

  payload.voyagePlanningId = voyageActivity[0]?.voyagePlanningId || "";
  payload.voyageActivityId = voyageActivity[0]?.voyageActivityId || "";
  payload.activityCategory = voyageActivity[0]?.activityCategory || "";
  payload.productivityOfActivity =
    voyageActivity[0]?.productivityOfActivity || "";
  payload.locationId = voyageActivity[0]?.locationId || "";
  payload.endDate = voyageActivity[0]?.endDate || "";
  payload.plannedStartDate = voyageActivity[0]?.plannedStartDate || "";
  payload.actualStartDate = voyageActivity[0]?.actualStartDate || "";
  payload.activityId = voyageActivity[0]?.activityId || "";

  const endActivity = async () => {
    const _payload = { ...payload };

    if (!_payload.actualEndDate) {
      toastr("error", "Pick actual end date");
      return;
    }

    setSaving(true);
    const response = await VoyageActivityService.updateVoyageActivity2(
      _payload
    );
    if (response) {
      setPayload({});
      // setRedirectUrl(
      //   `/voyage-activities/${voyageActivity[0].voyagePlanningId}`
      // );
      // toastr("success", "Actual end date updated");
    }

    const endActivityResponse = await VoyageActivityService.endVoyageActivity(
      _payload.voyageActivityId
    );
    if (endActivityResponse) {
      setPayload({});
      setRedirectUrl(
        `/voyage-activities/${voyageActivity[0].voyagePlanningId}`
      );
      toastr("success", "Voyage activity ended");
    } else {
      toastr("error", "Voyage activity not ended");
    }

    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card card-bordered shadow-lg">
          <div className="card-inner p-5">
            <div className="gap gap-20px" />
            <div className="table code-block border-lighter">
              <span className="preview-title-lg overline-title">
                Selected Activity{" "}
              </span>
              <StartEndVoyageActivityListComponent dataList={voyageActivity} />
            </div>

            {voyageActivity[0]?.activityName !==
            "Port Loading Deck cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Deck Cargos to load at port{" "}
                  </span>
                  <VoyageActivityDeckCargoList dataList={loadingDeckCargos} />
                </div>
              </>
            )}
            {/* {voyageActivity[0]?.activityName !==
            "Port Loading Bulk cargo" ? null : (
              <>
            <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                <span className="preview-title-lg overline-title">
                  Bulk Cargos to load at port{" "}
                </span>
                <VoyageActivityBulkCargoList dataList={loadingBulkCargos} />
              </div>
              </>
            )} */}

            {voyageActivity[0]?.activityName !==
            "Offshore Handling Deck cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Deck Cargos to load at offshore installation{" "}
                  </span>
                  <VoyageActivityDeckCargoList dataList={offshoreDeckCargos} />
                </div>
              </>
            )}
            {voyageActivity[0]?.activityName !==
            "Offshore Handling Bulk cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Bulk Cargos to load at offshore installation{" "}
                  </span>
                  <VoyageActivityBulkCargoList dataList={offshoreBulkCargos} />
                </div>
              </>
            )}
            {voyageActivity[0]?.activityName !==
            "Port Discharging Deck cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Deck Cargos to discharge at port{" "}
                  </span>
                  <VoyageActivityDeckCargoList
                    dataList={dischargingDeckCargos}
                  />
                </div>
              </>
            )}
            {voyageActivity[0]?.activityName !==
            "Port Discharging Bulk cargo" ? null : (
              <>
                <div className="gap gap-20px" />
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Bulk Cargos to discharge at port{" "}
                  </span>
                  <VoyageActivityBulkCargoList
                    dataList={dischargingBulkCargos}
                  />
                </div>
              </>
            )}

            {parallelVoyageActivities.length == 0 ? null : (
              <>
                <div className="gap gap-30px" />
                {/* <hr className="preview-hr" /> */}
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Other current activities{" "}
                  </span>
                  <ParallelVoyageActivityListComponent
                    dataList={parallelVoyageActivities}
                  />
                </div>
              </>
            )}
            <hr className="preview-hr" />
            <form>
              <div className="row align-items-center">
                {/* <div className="row gy-4 p-3"> */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      Actual end date / time
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="datetime-local"
                        data-msg="Required"
                        className="form-control required"
                        id="fw-last-name"
                        name="fw-last-name"
                        required=""
                        onChange={(e) =>
                          addPayload("actualEndDate", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <hr className="preview-hr" />

            <div className="float-right">
              {saving ? (
                <div className="form-group p-2 m-3">
                  <Spinner size="1.5" />
                </div>
              ) : (
                <div className="form-group p-2 m-3">
                  <button
                    type="button"
                    onClick={() => endActivity()}
                    className="btn btn-md btn-dark btn-wide px-5 mr-3"
                  >
                    End Activity
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-link m-1"
                    onClick={() => window.history.back()}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!modalIsClosed && selectedRecord && voyageActivity && (
        <CustomModal
          title="Update Cargo Logs"
          content={
            <>
              <UpdateCargoLogsComponent
                cargo={selectedRecord}
                callback={editCallback}
                activityName={voyageActivity[0].activityName}
                voyageActivityId={params.voyageActivityId}
                selectedBulkCargoDetailId={
                  selectedRecord?.BulkCargoDetailId || null
                }
                selectedDeckCargoDetailId={
                  selectedRecord?.DeckCargoDetailId || null
                }
              />
            </>
          }
          onClose={() => setSelectedRecord(null)}
          isClosed={modalIsClosed}
        />
      )}
    </>
  );
}
