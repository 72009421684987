import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import Select from "react-select";
import ActivityService from "../../services/activityService";
import LocationService from "../../services/locationService";
import payloadUpdater from "../../utils/payloadUpdater";
import { Validator, ValidateGroup } from "../../components/validator";
import toastr from "../../utils/toastr";
import { Navigate } from "react-router-dom";

export default function AddActivity() {
  const [predictiveText, setPredicitiveText] = useState({});
  const [formattedSuggestion, setFormattedSuggestion] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [activityList, setActivityList] = useState("");
  const [fullLocationList, setFullLocationList] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [postActivityPayload, setPostActivityPayload] = useState({
    activityName: "",
    activityShortName: "",
    activityDescription: "",
    // locationTypeId: "",
    locationGroup: "",
    allowsParallel: "",
  });
  const [secondPayload, setSecondPayload] = useState({});
    
  const activityPayload = new payloadUpdater({
    payload: postActivityPayload,
    setPayload: setPostActivityPayload,
  }).update;
  const validationGroup = "addActivity";

  useEffect(() => {
    init();
  }, []);

 
  const init = async () => {
    setLoading(true)
    const locationList = await LocationService.list();
    setFullLocationList(locationList);
    await retrieveListOfActivities();
    setLoading(false)
  }

  const retrieveListOfActivities = async () => {
    const retrievedList = await ActivityService.activitiesList();
    setActivityList(retrievedList);
    const textOption = retrievedList.map((e) => e.ActivityName);
    setPredicitiveText(textOption);
  };

  const onTextChanged = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      const suggestions = predictiveText?.sort().filter((v) => regex.test(v));
      setFormattedSuggestion(suggestions);
      setSelectedText(value);
    }
    //Do SOmething
  };

  let suggestionSelected = (value) => {
    setSelectedText(value);
    setFormattedSuggestion("");
  };
  let renderSuggestions = () => {
    if (formattedSuggestion.length === 0) {
      return null;
    }
    return (
      <ul>
        {formattedSuggestion?.map((item) => (
          <li onClick={() => suggestionSelected(item)}> {item} </li>
        ))}
      </ul>
    );
  };
  let manualShortName = document.getElementById("ActivityShortnameBox")?.value;
  if (!activityList) {
    return null;
  }
  let correspondingShortName = activityList
    ? activityList?.find((n) => n?.ActivityName === selectedText)
    : [];
  let shortName = correspondingShortName?.ActivityShortName;
  postActivityPayload.activityShortName = shortName || manualShortName;
  postActivityPayload.activityName = selectedText;

 

  const getSecondActivityDetails = (option) => {
    let parallelActivityDetails = option?.map((activityOption) => (
      {
        activityName: activityOption?.ActivityName,
        activityShortName: activityOption?.ActivityShortName,
        activityDescription: activityOption?.ActivityDescription,
        locationTypeId: activityOption?.LocationTypeId || "00000000-0000-0000-0000-000000000000",
        allowsParallel: activityOption?.AllowsParallel || "False",
        locationGroup: activityOption?.LocationGroup
      }
    ))
    setSecondPayload(parallelActivityDetails);
  };

  const saveActivityForm = async () => {
    let parallelActivity = [];
    parallelActivity.push(secondPayload);

    const validatorStatus = ValidateGroup(validationGroup);
    if (
      !validatorStatus ||
      !postActivityPayload.activityName ||
      !postActivityPayload.activityShortName ||
      !postActivityPayload.allowsParallel || !postActivityPayload.locationGroup
    ) {
      toastr("error", "Please fill the empty fields");
      return;
    }
    setLoading(true);
    const request = await ActivityService.postActivity(postActivityPayload);
    if (request && postActivityPayload?.allowsParallel === "True") {
      toastr("success", "New activity record added");
      let firstActivityId = request?.ActivityId;
      const parallelActivitySaved =
        await ActivityService.postParallelActivities(
          firstActivityId,
          secondPayload
        );
      setPostActivityPayload({});
      setSecondPayload({});
      toastr("success", "Parallel activity added");
      secondPayload.length = 0;
      setTimeout(() => setRedirectUrl("/activity-list"), 2500);
    } else if (!request)
      toastr(
        "error",
        "Activity could not be added, seems activity already exists"
      );
    setLoading(false); //
    return;
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout title="Add Activity">
      <div className="card-inner p-5">
        <form>
          <div className="row align-items-center">
            <div className="row gy-4">
              <div className="col-sm-6">
                <div className="form-group ">
                  <label className="form-label" htmlFor="fw-last-name">
                    Activity Name
                  </label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      data-msg="Required"
                      className="form-control required"
                      id="AN"
                      value={selectedText}
                      onChange={(e) => onTextChanged(e)}
                    />
                    <Validator
                      data={postActivityPayload.activityName}
                      errorMessage="Enter Activity Name"
                      groupName={validationGroup}
                      dataKey={"activityName"}
                    />
                  </div>
                  {renderSuggestions()}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Activity Shortname</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      data-msg="Required"
                      className="form-control required"
                      value={shortName}
                      id="ActivityShortnameBox"
                      onChange={async (e) =>
                        activityPayload("activityShortName", e.target.value)
                      }
                    />
                    <Validator
                      data={postActivityPayload.activityShortName}
                      errorMessage="Enter Activity Shortname"
                      groupName={validationGroup}
                      dataKey={"activityShortName"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group ">
                  <label className="form-label" htmlFor="fw-last-name">
                    Location group
                  </label>
                  <Select
                    options={[
                      { label: "Anywhere", value: "Anywhere" },
                      { label: "At sea", value: "At sea" },
                      { label: "Offshore installation", value: "Offshore installation" },
                      { label: "Port", value: "Port" },
                    ]}
                    onChange={(option) => {
                      activityPayload("locationGroup", option.value);
                    }}
                  />
                  {/* <Validator data={postActivityPayload.locationTypeId} errorMessage="Select Location group" groupName={validationGroup} dataKey={"locationTypeId"} /> */}
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Allow Parallel Activity
                  </label>
                  <Select
                    options={[
                      { label: "Yes", value: "True" },
                      { label: "No", value: "False" },
                    ]}
                    onChange={(option) => {
                      activityPayload("allowsParallel", option.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-first-name">
                    Description
                  </label>
                  <div className="form-control-wrap">
                    <textarea
                      placeholder="Fill in description"
                      className="form-control no-resize"
                      id="default-textarea"
                      defaultValue={""}
                      onChange={(e) =>
                        activityPayload("activityDescription", e.target.value)
                      }
                    />
                    <Validator
                      data={postActivityPayload.activityDescription}
                      errorMessage="Enter Activity description"
                      groupName={validationGroup}
                      dataKey={"activityDescription"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">

              {(postActivityPayload?.allowsParallel === "True") && (
                  <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Select parallel activity
                  </label>
                      <Select
                      onChange={(option) => getSecondActivityDetails(option)}
                      isMulti={true}
                      options={activityList?.map((a => ({
                        ...a,
                        label: a.ActivityName,
                        value: a.ActivityId
                      })))}
                        
                      />
                </div>
                )
                  }
              </div>
            </div>
          </div>
          <div className="nk-tb-col">
            <span>&nbsp;</span>
          </div>
          {/* {renderAdditionalPage()} */}
          {
            <div className="form-group py-2 my-3">
              <button
                type="button"
                onClick={() => saveActivityForm()}
                className="btn btn-md btn-dark btn-wide px-5 mr-3"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-sm btn-link m-1"
                onClick={() => window.history.back()}
              >
                Cancel
              </button>
            </div>
          }
        </form>
        <hr className="preview-hr" />
        {/* </div> */}
      </div>
    </MainLayout>
  );
  // </>
}
