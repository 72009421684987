import apiCall from "../utils/apiCall";

export default class ProvinceService {
    static listProvincesByCountry = async (countrId) => {
        return await apiCall(`/api/Province/GetByCountryId?countryId=${countrId}`, "GET", null, null, null, true);
    }
    
    static listOfProvinces = async () => {
        return await apiCall(`/api/Region/Get`);
    }
  
}
