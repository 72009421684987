import React from "react";
import { Link } from "react-router-dom";
import {
  checkUserPermission,
  formatAmount,
  toReadableDate,
} from "../../utils/utilityFunctions";
import logo from "../../images/internautical_darklogo.png";

function PaymentDetailsComponent({ details, currencies }) {
  return (
    <div>
      <div class="invoice">
        <div class="invoice-action">
          {checkUserPermission("payments_download_reciept") &&
            details?.fileUrl && (
              <a
                class="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary"
                href={details.fileUrl}
                target="_blank"
              >
                <em class="icon ni ni-printer-fill"></em>
              </a>
            )}
        </div>
        <div class="invoice-wrap">
          <div class="invoice-brand text-center">
            <img src={logo} srcset="./images/logo-dark2x.png 2x" alt="" />
          </div>
          <div class="invoice-head row">
            <div class="invoice-contact col-lg-4 mb-2">
              <span class="overline-title">Sender</span>
              <div class="invoice-contact-info">
                <h4 class="title">{details.PartyName}</h4>
                <ul class="list-plain">
                  <li>
                    <em class="icon ni ni-map-pin-fill"></em>
                    <span>{details.PartyAddress}</span>
                  </li>
                  <li>
                    <Link to={`mailto:${details.PartyEmail}`}>
                      <em class="icon ni ni-emails-fill"></em>
                      {details.PartyEmail}
                    </Link>
                  </li>
                  <li>
                    <Link to={`tel:${details.PartyPhone}`}>
                      <em class="icon ni ni-call-fill"></em>
                      {details.PartyPhone}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="invoice-contact col-lg-4 mb-2">
              <span class="overline-title">Recipient</span>
              <div class="invoice-contact-info">
                <h4 class="title">
                  {details.OrganizationDetails?.AccountName}
                </h4>
                <ul class="list-plain">
                  <li class="invoice-id">
                    <span>Bank </span>:
                    <span>
                      {details.OrganizationDetails?.BankDetails?.BankName}
                    </span>
                  </li>
                  <li class="invoice-date">
                    <span>Account </span>:
                    <span>{details.OrganizationDetails?.AccountNumber}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="invoice-desc col-lg-4 mb-2">
              <h3 class="title">Reciept</h3>
              <ul class="list-plain">
                <li class="invoice-id">
                  <span>Amount Paid</span>:
                  <b className="text-primary d-inline fs-17px">
                    {" "}
                    {formatAmount(details.AmountPaid, details.Currency)}
                  </b>
                </li>
                <li class="invoice-id">
                  <span>Payment Ref</span>:
                  <span>{details.PaymentReferenceNo}</span>
                </li>
                <li class="invoice-date">
                  <span>Payment Date</span>:
                  <span>{toReadableDate(details?.PaymentDate)}</span>
                </li>
                <li class="invoice-status">
                  <span>Payment Method</span>:
                  <span>{details?.PaymentMethod}</span>
                </li>
                <li class="invoice-status">
                  <span>Invoice Number</span>:
                  <span>{details?.InvoiceRefNo || "-"}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="invoice-bills">
            {details?.Description && (
              <div className="mb-3">
                <h6 className="text-primary">Remark</h6>
                <p>{details?.Description}</p>
              </div>
            )}

            <div class="nk-notes ff-italic fs-12px text-soft">
              {" "}
              Reciept was created on a computer and is valid without the seal.{" "}
            </div>

            <div className="col-12 pl-0 mb-2">
              {checkUserPermission("invoice_view_details") && (
                <Link to={`/invoices/${details?.InvoiceId}`}>
                  <button type="button" className="mt-4 btn btn-dark btn-sm ">
                    <span>View Invoice</span>
                  </button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetailsComponent;
