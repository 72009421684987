import React, { useEffect, useMemo, useState } from "react";
import { Navigate, Link } from "react-router-dom";
import PartyModal from "../Modals/partyModal";
import MainLayout from "../../layouts/mainLayout";
import CountryService from "../../services/countriesService";
import DPService from "../../services/dynamicPositioning";
import FleetService from "../../services/fleetService";
import PartyService from "../../services/partyService";
import VesselService from "../../services/vesselService";
import Spinner from "../../utils/spinner";
import ProvinceService from "../../services/provinceService";
import RolesService from "../../services/rolesService";
import payloadUpdater from "../../utils/payloadUpdater";
import {
  Validator,
  ValidateGroup,
  numberOnly,
} from "../../components/validator";
import Select from "react-select";
import ContactRolesService from "../../services/contactPersonService";
import CertificateService from "../../services/certificateService";
import toastr from "../../utils/toastr";
import ContactPersonService from "../../services/contactPersonService";
import FileInput from "../../components/fileInput";
import ComplianceModal from "../Modals/complianceModal";
import CertificateListComponent from "../../components/vessel/certificateListComponent";
import { removeElementFromArray } from "../../utils/utilityFunctions";
import CreateShipEquipment from "../Modals/createShipEquipment";
import EquipmentListComponent from "../../components/vessel/equipmentListComponent";
import EquipmentService from "../../services/equipmentService";
import VesselStorageTankInput from "../../components/vessel/VesselStorageTankInput";
import ProductService from "../../services/productService";
import UserService from "../../services/userService";
import AddVesselMasterComponent from "../../components/users/addVesselMasterComponent";
import CustomModal from "../../utils/customModal";
import RoleService from "../../services/roleService";
import { camelizeKeys } from "../../utils/utilityFunctions";

export default function AddVessel() {
  const definedPartyTypes = {
    "vessel owner": {},
    "technical manager": {},
    "international safety manager": {},
    "commercial manager": {},
  };
  const [certificates, setCertificates] = useState([]);
  const [vesselDetail, setVesselDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(false);
  const [imoSearching, setImoSearching] = useState(false);
  const [finalSaveloading, setFinalSaveLoading] = useState(false);
  const [tab, setTab] = useState(1);
  const [storageTanks, setStorageTanks] = useState([{}]);
  const [fleetList, setFleetList] = useState([{}]);
  const [dypValue, setDp] = useState([{}]);
  const [countryList, setCountry] = useState([{}]);
  const [partyList, setParty] = useState([{}]);
  const [partyTypes, setPartyTypes] = useState([]);
  const [provinces, setProvinces] = useState({});
  const [postVesselData, setPostVesselData] = useState();
  const [vesselTypes, setVesselTypes] = useState([{}]);
  const [vesselOwner, setVesselOwner] = useState({});
  const [contactPersonRoles, setContactPersonRoles] = useState([]);
  const [selectedContactPersonRole, setSelectedContactPersonRole] = useState(
    {}
  );
  const [contactPersonPayload, setContactPersonPayload] = useState({});
  const [roles, setRoles] = useState([]);
  const [compulsoryParties, setCompulsoryParties] = useState(definedPartyTypes);
  const [certificatePayload, setCertificatePayload] = useState({});
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [complianceDocuments, setComplianceDocuments] = useState([]);
  const [showComplianceModal, setShowComplianceModal] = useState(false);
  const [reloadingCertificate, setReloadingCertificate] = useState(false);
  const [addEquipmentForm, setAddEquipmentForm] = useState([1]);
  const [listOfNewVesselEquipments, setListOfNewVesselEquipments] = useState(
    []
  );
  const [selectedFleetId, setSelectedFleetId] = useState(null);
  // vessel storage tanks under attributes
  const [vesselStorageTanks, setVesselStorageTanks] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [tankProducts, setTankProducts] = useState([]);
  const [selectedTank, setSelectedTank] = useState(null);
  const [selectedTankIndex, setSelectedTankIndex] = useState(null);
  const [equipments, setEquipments] = useState([]);
  const [storageTankObject, setStorageTankObject] = useState({});
  const [pumpObject, setPumpObject] = useState({});
  const [vesselMasterUsers, setVesselMasterUsers] = useState([]);
  const [modalIsClosed, setModalIsClosed] = useState(true);
  const [payload, setPayload] = useState({});
  const [rolesPayload, setRolesPayload] = useState({});

  const addVesselPayload = new payloadUpdater({
    payload: postVesselData,
    setPayload: setPostVesselData,
  }).update;

  const retrieveVesselMasterRole = async () => {
    let _roles = await RoleService.getAllRolesPaginated(1, 1000);
    _roles = camelizeKeys(_roles?.Data || [])?.filter(
      (role) => role.name == "Vessel Master"
    );
    setRoles(_roles?.map((role) => role.roleId) || []);
  };

  const makeId = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  rolesPayload.roles = roles;
  rolesPayload.lastModifiedBy = JSON.parse(localStorage.resu)?.user?.Id;
  payload.firstName = postVesselData?.vesselName;
  payload.lastName = postVesselData?.imo;
  payload.email = postVesselData?.vesselEmail;
  payload.phoneNumber = postVesselData?.vesselSatelitePhone;
  payload.password = makeId(8);

  const submitForm = async () => {
    const _payload = { ...payload };

    if (!_payload.email) {
      toastr("error", "Enter vessel's email address");
      return;
    }

    if (!_payload.phoneNumber) {
      toastr("error", "Enter vessel's satellite phone number");
      return;
    }

    setFinalSaveLoading(true);

    const response = await UserService.addNewUser(_payload);
    if (!response) {
      toastr("error", "User could not be added");
      setFinalSaveLoading(false);
    }

    const newUserId = response?.Id;
    await assignUserRole(newUserId);
    setFinalSaveLoading(false);
  };

  const assignUserRole = async (newUserId) => {
    rolesPayload.userId = newUserId;
    const _payload = { ...rolesPayload };

    setFinalSaveLoading(true);
    const response = await UserService.assignRolesToUser(_payload);
    if (response) {
      const userId = response?.Data?.UserId;
      toastr("success", "New vessel master created");
      await saveEntireForm(userId);
    } else {
      toastr("error", "User created but vessel master role not assigned");
      setFinalSaveLoading(false);
    }
    setFinalSaveLoading(false);
  };

  const updateCompulsoryParties = (prop, value, _partyType) => {
    let _compulsoryParties = { ...compulsoryParties };
    _compulsoryParties[_partyType.PartyType.toLowerCase()][prop] = value;
    _compulsoryParties[_partyType.PartyType.toLowerCase()].partyTypeId =
      _partyType.PartyTypeId;
    if (prop === "firstname" || prop === "lastname") {
      let _temp = { ..._compulsoryParties[_partyType.PartyType.toLowerCase()] };
      _compulsoryParties[_partyType.PartyType.toLowerCase()].partyName =
        `${_temp.firstname} ${_temp.lastname}`.trim();
    }

    setCompulsoryParties(_compulsoryParties);
  };

  const addPartyModalCallback = async (partyAndContactPerson, _partyType) => {
    if (_partyType) {
      let _compulsoryParties = { ...compulsoryParties };
      _compulsoryParties[_partyType.toLowerCase()].party =
        partyAndContactPerson?.party;

      if (partyAndContactPerson?.party) {
        const _partyWithContactList =
          await PartyService.getPartyWithContactPerson(
            partyAndContactPerson.party.partyId ||
              partyAndContactPerson.party.PartyId
          );
        const _selectedContactPerson = _partyWithContactList &&
          _partyWithContactList.ContactPersons && {
            ..._partyWithContactList.ContactPersons[0],
          };
        _compulsoryParties[_partyType.toLowerCase()].contactPerson =
          _selectedContactPerson;
        setContactLoading(!contactLoading);
      }
      setCompulsoryParties(_compulsoryParties);
    }
    //await retrieveParty();
  };

  const addEquipment = () => {
    var forms = addEquipmentForm;
    forms.concat(addEquipmentForm.length);
    setAddEquipmentForm(forms);
  };

  const retrieveMeasurementUnits = async () => {
    const units = await EquipmentService.listMeasurementUnits();
    if (units == null) setMeasurementUnits({});
    else
      setMeasurementUnits(
        units.map((e) => ({
          ...e,
          label: e.MeasurementUnitSymbol,
          value: e.MeasurementUnitId,
          id: "MeasurementUnitId",
        }))
      );
  };

  const selectMeasurementUnit = (e, type) => {
    setSelectedTank((prev) => ({
      ...prev,
      [type]: { ...prev[type], [e.id]: e.value },
    }));
  };

  const validateTankEntry = () => {
    if (
      !selectedTank.tank.ProductId ||
      !selectedTank.tank.EquipmentCount ||
      !selectedTank.tank.CapacityMeasure ||
      !selectedTank.tank.MeasurementUnitId ||
      !selectedTank.pump.ProductId ||
      !selectedTank.pump.EquipmentCount ||
      !selectedTank.pump.CapacityMeasure ||
      !selectedTank.pump.MeasurementUnitId
    ) {
      toastr("error", "Please complete the tank and pump details");
      return false;
    }
    return true;
  };

  const retrieveEquipments = async () => {
    const equips = await EquipmentService.listEquipment();
    if (equips == null) setEquipments({});
    else {
      setEquipments(
        equips.map((e) => {
          if (e.EquipmentName.toLowerCase() === "storage tank") {
            setStorageTankObject(e);
          }

          if (e.EquipmentName.toLowerCase() === "pump") {
            setPumpObject(e);
          }
          return {
            ...e,
            label: e.EquipmentName,
            value: e.EquipmentId,
            id: "EquipmentId",
          };
        })
      );
    }
  };

  const fetchTankProducts = async () => {
    let _products = await ProductService.listAll();
    _products = (_products || []).map((a) => ({
      ...a,
      value: a.ProductId,
      label: a.ProductName,
    }));

    setTankProducts(_products);
  };

  const saveTankEntry = (e) => {
    if (!validateTankEntry()) return;
    setVesselStorageTanks((prev) => {
      prev[selectedTankIndex] = selectedTank;
      return prev;
    });
    clearSelectedTank();
  };

  const discardChanges = (e) => {
    clearSelectedTank();
  };

  const clearSelectedTank = () => {
    setSelectedTank(null);
    setSelectedTankIndex(null);
  };

  const addTank = () => {
    if (selectedTank) return toastr("error", "Save or discard tank changes");
    setSelectedTank({
      tank: {
        EquipmentId: storageTankObject.EquipmentId,
        EquipmentName: storageTankObject.EquipmentName,
      },
      pump: {
        EquipmentId: pumpObject.EquipmentId,
        EquipmentName: pumpObject.EquipmentName,
      },
    });
    setSelectedTankIndex(vesselStorageTanks.length);
  };

  const editTank = (tank, index) => {
    if (selectedTank) return toastr("error", "Save or discard tank changes");

    setSelectedTank(tank);
    setSelectedTankIndex(index);
  };

  const deleteTank = (deleteIndex) => {
    if (selectedTank) return toastr("error", "Save or discard tank changes");
    setVesselStorageTanks((prev) =>
      prev.filter((tank, index) => index !== deleteIndex)
    );
    setSelectedTank(null);
  };

  useEffect(async () => {
    setLoading(true);
    await retrieveDp();
    await retrieveFleet();
    await retrieveParty();
    await retrieveCountry();
    const _vesselTypes = await VesselService.fetchVesselTypes();
    setVesselTypes(_vesselTypes);

    const _contactPersonRoles = await ContactRolesService.listAllRoles();
    setContactPersonRoles(_contactPersonRoles);

    const _roles = await RolesService.listRoles();
    setRoles(_roles);

    const _partyTypes = await PartyService.listPartyTypes();
    setPartyTypes(_partyTypes);

    const _certificates = await CertificateService.listAll();
    setCertificates(_certificates);

    await retrieveMeasurementUnits();
    await retrieveEquipments();
    await fetchTankProducts();
    await retrieveVesselMasterRole();
    setLoading(false);
  }, []);

  //extracting Vessel owners' list
  let vesselOwnerslist = partyList?.filter(
    (item) => item?.PartyType?.toLowerCase() === "vessel owner"
  );

  const updateCountry = (countryId) => {
    addVesselPayload("countryId", countryId, async () => {
      await retrieveProvince(countryId);
    });
  };

  const saveEntireForm = async (userId) => {
    if (!validateTab()) return;
    const _reloadedCertificates = await CertificateService.listAll(false); //we are reloading certificates because user may have added a new certificate during compliance setup
    setCertificates(_reloadedCertificates);

    //STEP 1: Save Vessel
    setFinalSaveLoading(true);
    postVesselData.userId = userId;
    const vesselSaved = await VesselService.postVessel(postVesselData);
    if (!vesselSaved) {
      toastr(
        "error",
        "Vessel Details could not be saved. Review steps 1 and 2"
      );
      setTab(1);
      setFinalSaveLoading(false);
      return;
    }

    // add equipments to vessel
    const newVesselId =
      vesselSaved.vesselSpecificationId || vesselSaved.VesselSpecificationId;
    await postNewEquipmentsData(newVesselId);

    if (selectedFleetId) {
      const addToFleetResult = await FleetService.addVesselToFleet({
        vesselSpecificationId: newVesselId,
        fleetId: selectedFleetId,
        isActive: true,
      });
      if (!addToFleetResult) {
        //this is a soft failure
        toastr(
          "warning",
          "Vessel could not be added to fleet, use the fleet module to add vessel to fleet"
        );
      }
    }

    //STEP 2: Map parties to vessel
    try {
      let partySaveOk = true;
      Object.keys(compulsoryParties).forEach(async (e) => {
        let _currentParty = { ...compulsoryParties[e] };
        const selectedPartyType = partyTypes.find(
          (a) => a.PartyType?.toLowerCase() == e.toLowerCase()
        );

        if (_currentParty.party) {
          //weird code below (property casing gets confusing at times. It hasnt been consistent)
          _currentParty.party.partyTypeId =
            selectedPartyType.partyTypeId || selectedPartyType.PartyTypeId;
          _currentParty.party.partyId =
            _currentParty.party.partyId || _currentParty.party.PartyId;
          //end of wierd stuff
          const partySaved = await PartyService.postVesselParties(
            _currentParty.party.partyTypeId,
            _currentParty.party.partyId,
            newVesselId
          );
          if (!partySaved) partySaveOk = false;
        }
      });

      if (!partySaveOk) {
        toastr("error", "There was a problem saving vessel managers");
        setTab(3);
        setFinalSaveLoading(false);
        return;
      }
    } catch (err) {
      toastr("error", err.message);
      setTab(3);
      setFinalSaveLoading(false);
      return;
    }

    //STEP 3: Certificates
    for (let _certPayload of complianceDocuments) {
      _certPayload.certificateGroupId = _reloadedCertificates.find(
        (a) => a.CertificateNameId == _certPayload.certificateNameId
      ).CertificateGroupId;
      _certPayload.vesselSpecificationId = newVesselId;
      const _complianceDoc = { ..._certPayload };

      //these are additional props added when rendering within the table component
      delete _complianceDoc.statusComponent;
      delete _complianceDoc.dateComponent;
      //end of delete

      const savedCertificate = await CertificateService.postForVessel(
        _complianceDoc
      );
      if (!savedCertificate) {
        toastr("error", "Could not save compliance documents");
        setTab(4);
        setFinalSaveLoading(false);
        return;
      }
    }

    setFinalSaveLoading(false);
    toastr("success", "Vessel added successfully");
    setRedirectUrl("/vessel-list");
  };

  const toNextTab = () => {
    if (!validateTab()) return;

    let nextTab = tab + 1;
    if (nextTab > 5) nextTab = 5;
    if (nextTab === 2) {
      //saveVesselDetails();
      //add validations for tab 1.
    }
    if (nextTab === 3) {
      //add validations for tab 2.
    }

    setTab(nextTab);
    window.scrollTo(0, 0);
  };

  const toPreviousTab = () => {
    let prevTab = tab - 1;
    if (prevTab < 1) prevTab = 1;
    setTab(prevTab);
    window.scrollTo(0, 0);
  };

  const validateTab = (_tab) => {
    _tab = _tab || tab;

    if (_tab == 1) {
      if (!vesselDetail?.Vessel?.IMO) {
        toastr(
          "error",
          "You need to fill all compulsory fields under vessel description "
        );
        return false;
      }
    } else if (_tab == 2) {
      /*if (!postVesselData?.lengthBetweenPerpendicular ||
                !postVesselData?.beam ||
                !postVesselData?.noOfCrewCabins ||
                !postVesselData?.noOfPassengerCabins ||
                !postVesselData?.deckArea ||
                !postVesselData?.deckCargoCapacity ||
                !postVesselData?.dynamicPositioningId) {
                toastr("error", "You need to fill all compulsory fields for vessel attributes");
                return false;
            }*/
    } else if (_tab == 3) {
      let tab3IsOk = true;

      Object.keys(compulsoryParties).forEach(async (e) => {
        let _cptPayload = { ...compulsoryParties[e] };
        if (e === "vessel owner" && !_cptPayload.party?.PartyId) {
          //only vessel owner is compulsory
          tab3IsOk = false;
        }
      });

      if (!tab3IsOk) {
        toastr("error", "You need to add a vessel owner");
        return false;
      }
    } else if (_tab == 4) {
      /* if(complianceDocuments.length <=0){
                toastr("error", "You have not added any compliance documents");
                return false;
            }*/
      for (let _cert of complianceDocuments) {
        if (
          !_cert.certificateNameId ||
          !_cert.placeOfIssue ||
          !_cert.fileContent
        ) {
          toastr(
            "error",
            "You need to fill all compulsory fields for compliance"
          );
          return false;
        }
      }
    }

    return true;
  };

  const receiveEquipmentDetails = (equipment) => {
    setReloadingCertificate(true);

    const equipments = [...listOfNewVesselEquipments];
    equipments.push(equipment);
    setListOfNewVesselEquipments(equipments);
    setTimeout(() => {
      setReloadingCertificate(false);
    }, 1000);
  };

  const removeEquipmentDetails = (element) => {
    setReloadingCertificate(true);
    let equipments = [...listOfNewVesselEquipments];
    removeElementFromArray(element, equipments);
    setListOfNewVesselEquipments(equipments);

    setTimeout(() => {
      setReloadingCertificate(false);
    }, 1000);
  };

  const postNewEquipmentsData = async (vesselId) => {
    // initial equipments payload from the equipments tab
    let _payload = listOfNewVesselEquipments.map((e) => ({
      ...e,
      VesselSpecificationId: vesselId,
    }));
    setListOfNewVesselEquipments(_payload);

    // updating the equipment payload with the pumps and tanks. This is done this way to avoid duplicates by not adding it to the equipments state
    const tanks = [];
    const pumps = [];
    vesselStorageTanks.map((tank) => {
      tanks.push({ ...tank.tank, VesselSpecificationId: vesselId });
      pumps.push({ ...tank.pump, VesselSpecificationId: vesselId });
    });
    _payload = [..._payload, ...tanks, ...pumps];

    setFinalSaveLoading(true);
    const equipAdded = await EquipmentService.createEquipments(_payload);
    if (!equipAdded) {
      toastr("error", "Vessel Equipment could not be added");
      setFinalSaveLoading(false);
    } else {
      //toastr("success", "Equipments successfully added");
      setFinalSaveLoading(false);
    }

    return equipAdded;
  };

  const receiveComplianceDocuments = (doc) => {
    setReloadingCertificate(true);
    if (doc) {
      let _complianceDocuments = [...complianceDocuments];
      _complianceDocuments.push(doc);
      setComplianceDocuments(_complianceDocuments);
    }
    setShowComplianceModal(false);
    setTimeout(() => {
      setReloadingCertificate(false);
    }, 1000);
  };

  const searchImo_Mmsi = async (e) => {
    if (
      e.code == "Enter" ||
      ((e.key === 13 || e.key == "Enter") && e.location === 3)
    ) {
      setImoSearching(true);
      const existingVessel = await VesselService.searchVessels(
        1,
        1,
        "",
        e.target.value
      );
      if (existingVessel && existingVessel.TotalCount > 0) {
        toastr("error", "The searched vessel exists already in the system");
        setImoSearching(false);
        return;
      } else {
        await searchVessel(e.target.value);
      }
      setImoSearching(false);
    }
  };

  const searchVessel = async (imo_or_mmsi, type) => {
    if ((!imo_or_mmsi || imo_or_mmsi.length < 7) && type != "name") {
      setVesselDetail(null);
      setLoading(false);
      toastr("warning", "IMO and MMSI are longer than 7 characters");
      return;
    }

    setLoading(true);
    var vessel_detail = null;
    if (type == "name") {
      if (imo_or_mmsi.length < 5) return;
      vessel_detail = await VesselService.searchByName(imo_or_mmsi);
    } else {
      if (imo_or_mmsi.length < 7) return;
      vessel_detail = await VesselService.searchByImoOrMmsi(imo_or_mmsi);
    }

    if (
      vessel_detail &&
      vessel_detail.Vessel &&
      (vessel_detail.Vessel.IMO || vessel_detail.VesselParticularsItems.IMO)
    ) {
      let selectedCountry = countryList.find(
        (country) => country.CountryName === vessel_detail.Vessel?.COUNTRY
      );
      let selectedVesselType = vesselTypes.find(
        (vessel) => vessel.VesselType === vessel_detail.Vessel?.TYPE_NAME
      );
      if (!selectedVesselType && vessel_detail.Vessel?.TYPE_NAME) {
        //vessel type is not on our db yet.
        selectedVesselType = await VesselService.postVesselType(
          vessel_detail.Vessel.TYPE_NAME
        );
      }
      setVesselDetail(vessel_detail);
      setPostVesselData({
        mmsi:
          vessel_detail.Vessel.MMSI ||
          vessel_detail.VesselParticularsItems.MMSI,
        imo:
          vessel_detail.Vessel.IMO || vessel_detail.VesselParticularsItems.IMO,
        shipId: !vessel_detail.Vessel.SHIP_ID
          ? 0
          : parseInt(vessel_detail.Vessel.SHIP_ID),
        vesselName: vessel_detail.Vessel.SHIPNAME,
        VesselTypeId:
          selectedVesselType?.VesselTypeId || selectedVesselType?.vesselTypeId,
        YearOfBuild: vessel_detail.Vessel.YEAR_BUILT,
        PlaceOfBuild: vessel_detail.VesselParticularsItems.PLACE_OF_BUILD,
        BreadthExtreme: null,
        SummerDwt: vessel_detail.VesselParticularsItems.SUMMER_DWT,
        DisplacementSummer:
          vessel_detail.VesselParticularsItems.DISPLACEMENT_SUMMER,
        CallSign: vessel_detail.VesselParticularsItems.CALLSIGN,
        Draught: vessel_detail.VesselParticularsItems.DRAUGHT,
        DraughtMax: vessel_detail.VoyageInformation.DRAUGHT_MAX,
        LengthOverall: vessel_detail.VesselParticularsItems.LENGTH_OVERALL,
        FuelConsumption: " ", //vessel_detail.VesselParticularsItems.FUEL_CONSUMPTION
        SpeedMax: vessel_detail.VesselParticularsItems.SPEED_MAX,
        SpeedService: vessel_detail.VesselParticularsItems.SPEED_SERVICE,
        LiquidOil: vessel_detail.VesselParticularsItems.LIQUID_OIL,
        GrossRegisteredTonnage:
          vessel_detail.VesselActivity.GrossRegisteredTonnage ||
          vessel_detail.VesselActivity.GRT,
        RateOfTurn:
          vessel_detail.VesselActivity.RateOfTurn ||
          vessel_detail.VesselActivity.ROT,
        Width: vessel_detail.VesselActivity.WIDTH,
        CountryId: selectedCountry?.CountryId,
        PhotoUrl: vessel_detail.VesselPhoto.URL,
        VesselUrl: vessel_detail.Vessel.MT_URL,
      });
    } else {
      setVesselDetail(null);
      toastr("warning", `Vessel not found`);
    }
    setLoading(false);
  };

  const retrieveProvince = async (countryId) => {
    if (!countryId) return;
    if (provinces[countryId]) return; // we have fetched provinces for this country before

    const _provinces = await ProvinceService.listProvincesByCountry(countryId);
    const __provinces = { ...provinces };
    __provinces[countryId] = _provinces;

    setProvinces(__provinces);
  };

  const retrieveFleet = async () => {
    const fleet_list = await FleetService.listFleet();
    setFleetList(fleet_list || []);
  };
  const retrieveDp = async () => {
    const dyp_List = await DPService.dpList();
    setDp(dyp_List || []);
  };
  const retrieveCountry = async () => {
    const country_List = await CountryService.listCountry();
    setCountry(country_List || []);
  };

  const retrieveParty = async () => {
    const party_List = await PartyService.listParty();
    setParty(party_List || []);
  };

  const renderStorageTank = (index) => {
    return (
      <div className="row align-items-center" id="TP1">
        <div className="col-md-4">
          <div className="form-group ">
            <label className="form-label">
              Storage Tank Name (Tank {index + 1})
            </label>
            <div className="form-control-wrap ">
              <div className="form-control-select">
                <select className="form-control ">
                  <option value>-- Select --</option>
                  <option value="us">Brine</option>
                  <option value="uk">Potable Water ( Cargo)</option>
                  <option value> Potable Water (Tug)</option>
                  <option value="us">Dilling and Ballast Water</option>
                  <option value="uk">Liquid Mud</option>
                  <option value="uk">Fuel</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="form-label" htmlFor="fw-nationality">
              Bulk Product Type
            </label>
            <div className="form-control-wrap ">
              <div className="form-control-select">
                <select className="form-control ">
                  <option value>-- Select --</option>
                  <option value="us">Wet</option>
                  <option value="uk">Dry</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="form-group ">
            <label className="form-label">Tank Capacity</label>
            <div className="form-control-wrap">
              <input type="text" className="form-control required" required />
            </div>
          </div>
        </div>
        <div className="gap" />
        <div className="col-md-4">
          <div className="form-group ">
            <label className="form-label">Discharge Rate</label>
            <div className="form-control-wrap">
              <input type="text" className="form-control required" required />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group ">
            <label className="form-label">Cargo Volume</label>
            <div className="form-control-wrap">
              <input type="text" className="form-control required" required />
            </div>
          </div>
        </div>
        <div className="col-12 mt-5"></div>
      </div>
    );
  };

  const updateStorageTankCount = (e) => {
    let count = parseInt(e.target.value);
    let _storageTanks = [...storageTanks];

    if (count > storageTanks.length) {
      for (let i = storageTanks.length; i < count; i++) {
        _storageTanks.push({});
      }
    } else if (count < storageTanks.length) {
      _storageTanks = [];
      for (let i = 0; i < count; i++) {
        _storageTanks.push(storageTanks[i]);
      }
    }
    setStorageTanks(_storageTanks);
  };

  const dataListItem = ({ label, value, label2, value2 }) => {
    return (
      <div className="data-item px-0">
        <div className="data-col">
          <span className="data-label">
            <span>{value}</span>
          </span>
          {value2 && (
            <span className="data-label">
              <span>{value2}</span>
            </span>
          )}
        </div>
      </div>
    );
  };

  const singleParty = ({ party }) => {
    return (
      <span>
        <h6 className="d-inline-block mb-2">
          {party.partyName || party.PartyName}
        </h6>{" "}
        <br />{" "}
        <span>
          <em className="icon ni ni-mail mr-1"></em>
          {party.emailAddress || party.EmailAddress}
        </span>{" "}
        <span>
          <em className="icon ni ni-call ml-3 mr-1"></em>{" "}
          {party.phoneNumber || party.PhoneNumber}
        </span>
        <br />
        <span>
          <em className="icon ni ni-map-pin-fill mr-1"></em>
          {`${party.AddressLine1}, ${party.AddressLine2}`}
        </span>{" "}
      </span>
    );
  };

  const singleContact = ({ contactPerson }) => {
    if (!contactPerson || !contactPerson.RoleId) return <span></span>;
    let c = contactPerson;
    const _contactRole = contactPersonRoles.find(
      (a) => a.ContactPersonRoleId?.toLowerCase() == c.RoleId.toLowerCase()
    );
    return (
      <span>
        <span className="overline-title d-inline-block mb-2">
          Contact Person
        </span>
        <br />
        <span className="d-inline-block">
          {`${c.FirstName} ${c.LastName}`}{" "}
          {_contactRole && (
            <>
              <span className="mx-2" style={{ opacity: "0.2" }}>
                |
              </span>{" "}
              {_contactRole?.ContactPersonRole}
            </>
          )}
        </span>
        <br />{" "}
        <span>
          <em className="icon ni ni-mail mr-1"></em>
          {c.emailAddress || c.EmailAddress}
        </span>{" "}
        <span>
          <em className="icon ni ni-call ml-3 mr-1"></em>{" "}
          {c.phoneNumber || c.PhoneNumber}
        </span>
      </span>
    );
  };

  const KeyVesselParties = ({ role }) => {
    let vesselOwnerFromAPI;
    if (
      role.toLowerCase() === "vessel owner" &&
      vesselDetail &&
      !compulsoryParties[role]?.party
    ) {
      vesselOwnerFromAPI =
        vesselDetail.VesselParticularsItems?.MANAGER ||
        vesselDetail.VesselParticularsItems?.MANAGER_OWNER ||
        vesselDetail.VesselParticularsItems?.OWNER;

      if (vesselOwnerFromAPI) {
        const _vesselOwner = partyList?.find(
          (a) => a?.PartyName.toLowerCase() === vesselOwnerFromAPI.toLowerCase()
        );
        if (_vesselOwner && !compulsoryParties[role].party) {
          addPartyModalCallback({ party: _vesselOwner }, role);
        }
      }
    }
    const selectedPartyType = partyTypes.find(
      (a) => a.PartyType?.toLowerCase() == role.toLowerCase()
    );

    return (
      selectedPartyType && (
        <>
          <div className="row gy-3 pl-0 pt-4 pr-4">
            <div className="col-12">
              <p>
                <span className="preview-title-lg overline-title d-inline">
                  {selectedPartyType.PartyType}
                </span>
                {compulsoryParties[role].party && (
                  <>
                    {" "}
                    {!vesselOwnerFromAPI && (
                      <button
                        type="button"
                        title={`Change ${selectedPartyType.PartyType}`}
                        className="d-inline btn btn-xs text-primary ml-3"
                        onClick={() => addPartyModalCallback(null, role)}
                      >
                        <em className="icon ni ni-edit-alt"></em> Edit
                      </button>
                    )}
                  </>
                )}
              </p>
            </div>
            <div className="col-12">
              <div className="row align-items-end">
                {!compulsoryParties[role].party && (
                  <>
                    <div className="col-12" style={{ marginBottom: "-12px" }}>
                      <label className="form-label mb-0">
                        Select existing{" "}
                      </label>{" "}
                      {role === "vessel owner" && (
                        <small className="text-danger ml-1">*</small>
                      )}
                      {!compulsoryParties[role].party && vesselOwnerFromAPI && (
                        <span className="text-muted small">
                          <br />
                          Owner name is available. Click configure prefilled to
                          add extra owner information
                        </span>
                      )}
                    </div>
                    <div className="col-md-4 mb-4">
                      <div className="form-group mb-3 mt-0">
                        <div className="form-control-wrap">
                          <div className="">
                            <Select
                              options={vesselOwnerslist.map((e) => ({
                                ...e,
                                label: e.PartyName,
                                value: e.PartyId,
                              }))}
                              value={compulsoryParties[role].party}
                              onChange={(option) =>
                                addPartyModalCallback({ party: option }, role)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-4 ">
                      <div className="form-group mb-3 mt-0">
                        <PartyModal
                          callback={async (_party_contact) => {
                            await addPartyModalCallback(_party_contact, role);
                            await retrieveParty();
                          }}
                          partyTypeId={selectedPartyType.PartyTypeId}
                          prefilledPartyName={vesselOwnerFromAPI}
                          addNewLabel={
                            vesselOwnerFromAPI && "Configure prefilled"
                          }
                        />
                      </div>
                    </div>
                  </>
                )}

                {compulsoryParties[role] &&
                  compulsoryParties[role].party?.PartyId && (
                    <div className="col-12">
                      <div className="mt-0">
                        <div className="nk-data data-list">
                          {dataListItem({
                            label: "",
                            value: singleParty({
                              party: compulsoryParties[role].party,
                            }),
                            label2: "Contact Person",
                            value2: singleContact({
                              contactPerson:
                                compulsoryParties[role].contactPerson,
                            }),
                          })}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <hr className="mt-0" style={{ opacity: "0.25" }} />
        </>
      )
    );
  };

  const _compulsory_parties = Object.keys(definedPartyTypes);
  let CompulsoryPartiesJSX = useMemo(
    () =>
      _compulsory_parties.map((item, index) =>
        KeyVesselParties({ role: item })
      ),
    [vesselDetail, partyTypes, contactLoading, compulsoryParties]
  );

  const removeComplianceDocument = (element) => {
    setReloadingCertificate(true);
    let _complianceDocuments = [...complianceDocuments];
    removeElementFromArray(element, _complianceDocuments);
    setComplianceDocuments(_complianceDocuments);

    setTimeout(() => {
      setReloadingCertificate(false);
    }, 1000);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout title="Add a Vessel">
      <ul className="nav nav-tabs mt-n3 ml-3">
        <li className="nav-item">
          <a
            onClick={() => setTab(1)}
            className={`nav-link ${tab == 1 && "active"}`}
          >
            <em className="icon ni ni-view-grid-wd" />
            <span> Description</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={() => validateTab(1) && setTab(2)}
            className={`nav-link ${tab == 2 && "active"}`}
          >
            <em className="icon ni ni-list-check" />
            <span> Attributes</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={() => validateTab(2) && setTab(3)}
            className={`nav-link ${tab == 3 && "active"}`}
          >
            <em className="icon ni ni-user-list" />
            <span>Managers</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={() => validateTab(3) && setTab(4)}
            className={`nav-link ${tab == 4 && "active"}`}
          >
            <em className="icon ni ni-files" />
            <span>Compliance</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={() => validateTab(4) && setTab(5)}
            className={`nav-link ${tab == 5 && "active"}`}
          >
            <em className="icon ni ni-cc-secure" />
            <span>Equipment</span>
          </a>
        </li>
      </ul>
      <form className=" nk-wizard-simple is-alter p-3">
        {tab === 1 && (
          <>
            <div className="pl-2">
              <p>
                <span className="preview-title-lg overline-title pt-4">
                  Vessel Description
                </span>{" "}
              </p>
              <br />
              <div className="nk-wizard-content">
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="form-group ">
                      <label className="form-label">
                        IMO {imoSearching && <Spinner size={1} />}
                      </label>
                      <small className="text-danger ml-1">*</small>{" "}
                      <small className="text-muted">
                        {" "}
                        press enter key to search
                      </small>
                      <div className="form-control-wrap">
                        <input
                          onChange={(e) =>
                            addVesselPayload("Imo", e.target.value)
                          }
                          type="search"
                          className="form-control "
                          onKeyDown={async (e) => await searchImo_Mmsi(e)}
                          placeholder="Search Vessel.."
                          defaultValue={vesselDetail?.Imo}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">Vessel Name</label>
                      <div className="form-control-wrap">
                        <input
                          type="search"
                          Value={vesselDetail?.Vessel?.SHIPNAME}
                          placeholder="....Find vessel by Name"
                          onChange={async (e) =>
                            addVesselPayload(
                              "vesselName",
                              e.target.value,
                              async () =>
                                await searchVessel(e.target.value, "name")
                            )
                          }
                          className="form-control required"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {vesselDetail && vesselDetail.VesselPhoto && (
                    <div className="col-md-4">
                      <div className="form-group">
                        {vesselDetail.VesselPhoto.URL ? (
                          <div className="p-3">
                            <img
                              className="rounded"
                              src={vesselDetail.VesselPhoto.URL}
                            />
                          </div>
                        ) : (
                          <>
                            <label className="form-label">
                              Upload Vessel Photo
                            </label>
                            <FileInput
                              onChange={(file) => {
                                addVesselPayload(["PhotoUrl"], [file.base64]);
                              }}
                              fileValue={{
                                name:
                                  postVesselData.PhotoUrl &&
                                  "Vessel image selected",
                                size: postVesselData.PhotoUrl?.length || 0,
                                fileContent: postVesselData.PhotoUrl,
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <span>&nbsp;</span>

                {vesselDetail && vesselDetail?.Vessel?.SHIPNAME && (
                  <div className="row gy-3">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">MMSI</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            defaultValue={vesselDetail?.Vessel?.MMSI}
                            onChange={(e) =>
                              addVesselPayload("Mmsi", e.target.value)
                            }
                            className="form-control required"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">Add to Pool</label>
                        <div className="form-control-wrap ">
                          <Select
                            onChange={(item) => setSelectedFleetId(item.value)}
                            value={fleetList
                              .map((f) => ({
                                ...f,
                                label: f.FleetName,
                                value: f.FleetId,
                              }))
                              .find((a) => a.value == selectedFleetId)}
                            options={fleetList.map((f) => ({
                              ...f,
                              label: f.FleetName,
                              value: f.FleetId,
                            }))}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">Year of Build</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            defaultValue={vesselDetail?.Vessel?.YEAR_BUILT}
                            onChange={(e) =>
                              addVesselPayload("YearOfBuild", e.target)
                            }
                            className="form-control required"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">Vessel Type</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            defaultValue={vesselDetail?.Vessel?.TYPE_NAME}
                            className="form-control required"
                            disabled
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">Length Overall</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            defaultValue={
                              vesselDetail?.VesselParticularsItems
                                ?.LENGTH_OVERALL
                            }
                            className="form-control required"
                            disabled
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">Maximum draught</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            value={
                              vesselDetail?.VesselParticularsItems?.DRAUGHT
                            }
                            className="form-control required"
                            disabled
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">
                          Net Registered Tonnage
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control required"
                            id="fw-first-name"
                            name="fw-first-name"
                            disabled
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">
                          Gross Registered Tonnage -
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control required"
                            disabled
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">Dead weight</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            defaultValue={
                              vesselDetail?.VesselParticularsItems?.SUMMER_DWT
                            }
                            className="form-control required"
                            disabled
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-sm-6">
                                    <div className="form-group">
                                        <label className="form-label" >Depth</label>
                                        <div className="form-control-wrap">
                                            <input type="text" className="form-control required" disabled required />
                                        </div>
                                    </div>
                                </div> */}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Speed</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            defaultValue={
                              vesselDetail?.VesselParticularsItems?.SPEED_MAX
                            }
                            className="form-control required"
                            disabled
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">BHP</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control required"
                            disabled
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Flag</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            defaultValue={
                              vesselDetail?.VesselParticularsItems?.FLAG
                            }
                            className="form-control required"
                            disabled
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {tab === 2 && (
          <>
            <div className="nk-wizard-head">
              <h5>Vessel Attributes</h5>
            </div>
            <div className="nk-wizard-content" style={{ maxWidth: "800px" }}>
              <div className="row gy-4 p-4">
                <div>
                  <p>
                    <span className="preview-title-lg overline-title">
                      Vessel Attributes
                    </span>{" "}
                  </p>
                </div>
                <br />
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <div className="form-group ">
                      <label className="form-label">Vessel Email</label>
                      <div className="form-control-wrap">
                        <input
                          type="email"
                          defaultValue={postVesselData?.vesselEmail}
                          className="form-control required"
                          onChange={(e) =>
                            addVesselPayload("vesselEmail", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Vessel Satelite Phone
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="number"
                          className="form-control required"
                          defaultValue={postVesselData?.vesselSatelitePhone}
                          onChange={(e) =>
                            addVesselPayload(
                              "vesselSatelitePhone",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gap" />
                  <div className="col-sm-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Length between perpendicular
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          defaultValue={
                            postVesselData?.lengthBetweenPerpendicular
                          }
                          className="form-control required"
                          onChange={(e) =>
                            addVesselPayload(
                              "lengthBetweenPerpendicular",
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group ">
                      <label className="form-label">Beam</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control required"
                          defaultValue={postVesselData?.beam}
                          onChange={(e) =>
                            addVesselPayload("beam", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gap" />
                  <div className="col-sm-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Number of Crew Cabins
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control required"
                          defaultValue={postVesselData?.noOfCrewCabins}
                          onChange={(e) =>
                            addVesselPayload("noOfCrewCabins", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group ">
                      <label className="form-label">
                        Number of Passenger Cabins
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control required"
                          defaultValue={postVesselData?.noOfPassengerCabins}
                          onChange={(e) =>
                            addVesselPayload(
                              "noOfPassengerCabins",
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gap" />
                  <div className="col-sm-6">
                    <div className="form-group ">
                      <label className="form-label">Deck Area</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control required"
                          defaultValue={postVesselData?.deckArea}
                          onChange={(e) =>
                            addVesselPayload("deckArea", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group ">
                      <label className="form-label">Deck Cargo Capacity</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control required"
                          defaultValue={postVesselData?.deckCargoCapacity}
                          onChange={(e) =>
                            addVesselPayload(
                              "deckCargoCapacity",
                              e.target.value
                            )
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gap" />
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="customMultipleFilesLabel"
                      >
                        Upload vessel documents
                      </label>
                      <FileInput
                        onChange={(file) => {
                          addVesselPayload(
                            ["fileContent", "fileName", "mime"],
                            [
                              file.base64,
                              `Vessel-document-${file.name}`,
                              file.type,
                            ]
                          );
                        }}
                        fileValue={{
                          name: postVesselData.fileName,
                          size: postVesselData.fileContent?.length || 0,
                          fileContent: postVesselData.fileContent,
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group ">
                      <label className="form-label">Dynamic Positioning</label>
                      <div className="form-control-wrap ">
                        <div className="form-control-select">
                          <Select
                            options={dypValue.map((displayDp) => ({
                              ...displayDp,
                              label: displayDp.DynamicPositioning,
                              value: displayDp.DynamicPositioningId,
                            }))}
                            value={dypValue
                              .map((displayDp) => ({
                                ...displayDp,
                                label: displayDp.DynamicPositioning,
                                value: displayDp.DynamicPositioningId,
                              }))
                              .find(
                                (dp) =>
                                  dp.value ==
                                  postVesselData?.dynamicPositioningId
                              )}
                            onChange={(e) =>
                              addVesselPayload("dynamicPositioningId", e.value)
                            }
                          />
                          {/* <select
                            onChange={(e) =>
                              addVesselPayload(
                                "dynamicPositioningId",
                                e.target.value
                              )
                            }
                            className="form-control"
                            defaultValue={dypValue.find(
                              (dp) =>
                                dp.DynamicPositioningId ==
                                postVesselData?.dynamicPositioningId
                            )}
                          >
                            <option></option>
                            {dypValue.map((displayDp) => (
                              <option value={displayDp.DynamicPositioningId}>
                                {" "}
                                {displayDp.DynamicPositioning}{" "}
                              </option>
                            ))}
                          </select> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {null && (
                <>
                  <hr className="preview-hr" />
                  <div>
                    <p>
                      <span className="preview-title-lg overline-title">
                        Storage Tanks
                      </span>{" "}
                    </p>
                  </div>
                  <br />
                  <div>
                    <label className="nk-block-des text-soft bg-warning-dim">
                      <i>Select Number of tanks</i>
                    </label>
                    <select
                      id="TP0"
                      onChange={(e) => updateStorageTankCount(e)}
                    >
                      <option selected>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                    </select>
                  </div>
                  {storageTanks.map((item, index) => renderStorageTank(index))}
                </>
              )}
            </div>
            {/* storage tanks */}
            <div className="nk-wizard-content">
              {/* <div className="row gy-4 p-4"> */}
              <hr className="preview-hr" />
              <div className="row gy-4 p-4">
                <p>
                  <span className="preview-title-lg overline-title">
                    Storage Tanks
                  </span>{" "}
                </p>
              </div>
              {/* storage tanks */}

              {!!vesselStorageTanks.length &&
                vesselStorageTanks.map((tank, index) =>
                  selectedTankIndex !== index ? (
                    <div className="row px-4  align-center mb-4">
                      {/* tanks */}
                      <div>
                        <h6>Tank: {tank.tank.ProductName || "N/A"} </h6>

                        <div className="d-flex">
                          <p className="mb-0 mr-2">
                            <span className="font-weight-bold mr-1">
                              Count:
                            </span>
                            {tank.tank.EquipmentCount || "N/A"}{" "}
                          </p>
                          <p className="mb-0 mr-2">
                            <span className="font-weight-bold mr-1">
                              Capacity:
                            </span>
                            {tank.tank.CapacityMeasure || "N/A"}{" "}
                          </p>
                          <p className="mb-0 mr-2">
                            <span className="font-weight-bold mr-1">
                              Measurement Unit:
                            </span>
                            {measurementUnits.find(
                              (unit) =>
                                unit.MeasurementUnitId ===
                                tank.tank.MeasurementUnitId
                            )?.label || "N/A"}{" "}
                          </p>
                        </div>
                      </div>

                      {/* pumps */}
                      <div className="ml-4">
                        <h6>{tank.pump.ProductName || " "} Pump </h6>

                        <div className="d-flex">
                          <p className="mb-0 mr-2">
                            <span className="font-weight-bold mr-1">
                              Count:
                            </span>
                            {tank.pump.EquipmentCount || "N/A"}{" "}
                          </p>
                          <p className="mb-0 mr-2">
                            <span className="font-weight-bold mr-1">
                              Capacity:
                            </span>
                            {tank.pump.CapacityMeasure || "N/A"}{" "}
                          </p>
                          <p className="mb-0 mr-2">
                            <span className="font-weight-bold mr-1">
                              Measurement Unit:
                            </span>
                            {measurementUnits.find(
                              (unit) =>
                                unit.MeasurementUnitId ===
                                tank.pump.MeasurementUnitId
                            )?.label || "N/A"}{" "}
                          </p>
                        </div>
                      </div>

                      {/* action */}
                      <div className="col-12 pl-0 form-group">
                        <span
                          className="text-primary fs-12px pointer"
                          onClick={() => editTank(tank, index)}
                        >
                          <em
                            class="icon ni ni-edit text-primary pointer mr-1"
                            title="Edit"
                          ></em>
                          Edit
                        </span>

                        <span
                          className="text-danger fs-12px ml-3 pointer"
                          onClick={(e) => deleteTank(index)}
                        >
                          <em
                            class="icon ni ni-cross  mr-1"
                            title="Delete"
                          ></em>
                          Delete
                        </span>
                      </div>
                    </div>
                  ) : (
                    <VesselStorageTankInput
                      selectedTank={selectedTank}
                      setSelectedTank={setSelectedTank}
                      measurementUnits={measurementUnits}
                      selectMeasurementUnit={selectMeasurementUnit}
                      saveTankEntry={saveTankEntry}
                      discardChanges={discardChanges}
                      tankProducts={tankProducts}
                    />
                  )
                )}
              {!vesselStorageTanks.length && !selectedTank && (
                <div className="row px-4 ">
                  <p>No tank added</p>
                </div>
              )}
              {selectedTankIndex === vesselStorageTanks.length && (
                <div className="row px-4 mt-2">
                  <VesselStorageTankInput
                    selectedTank={selectedTank}
                    setSelectedTank={setSelectedTank}
                    measurementUnits={measurementUnits}
                    selectMeasurementUnit={selectMeasurementUnit}
                    saveTankEntry={saveTankEntry}
                    discardChanges={discardChanges}
                    tankProducts={tankProducts}
                  />
                </div>
              )}

              {!selectedTank && (
                <div
                  className="row px-4 align-center mt-3"
                  onClick={() => addTank()}
                >
                  <em
                    class="icon ni ni-plus-sm text-primary fs-20px pointer mr-1"
                    title="Add a storage tank"
                  ></em>
                  <a href={"javascript:void(0)"} className="mb-0 text-primary">
                    Add a storage tank
                  </a>
                </div>
              )}
            </div>
          </>
        )}
        {tab === 3 && (
          <>
            {" "}
            <div className="nk-wizard-head">
              <h5>Vessel Managers</h5>
            </div>
            {/* <>
              <div className="row gy-3 pl-0 pt-4 pr-4">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title d-inline">
                      Vessel Master
                    </span>
                  </p>
                </div>
                <div className="col-12">
                  <label className="form-label mb-0">Select existing </label>{" "}
                </div>
                <div className="col-md-4 mb-4">
                  <div className="form-group mb-3 mt-0">
                    <div className="form-control-wrap">
                      <div className="">
                        <Select
                          onChange={(e) => {
                            addVesselPayload("userId", e.value);
                          }}
                          defaultValue={vesselMasterUsers?.find(
                            (user) => user.value == postVesselData?.userId
                          )}
                          options={vesselMasterUsers}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="form-group mb-3 mt-0">
                    <button
                      onClick={() => setModalIsClosed(false)}
                      type="button"
                      className={`openModalBtn btn btn-md btn-dark`}
                    >
                      + Add vessel master?
                    </button>
                  </div>
                </div>
              </div>
              <hr className="mt-0" style={{ opacity: "0.25" }} />
            </> */}
            <div className="nk-wizard-content col">{CompulsoryPartiesJSX}</div>
          </>
        )}
        {tab === 4 && (
          <>
            <div className="nk-wizard-head">
              <h5>Compliance</h5>
            </div>
            {showComplianceModal && (
              <ComplianceModal
                callback={(complianceDoc) =>
                  receiveComplianceDocuments(complianceDoc)
                }
              />
            )}
            <div className="nk-wizard-content">
              <button
                type="button"
                className="btn btn-sm text-primary my-3"
                onClick={() => {
                  setShowComplianceModal(true);
                }}
              >
                + Add Compliance Document
              </button>
              <br style={{ clear: "both" }} />
              {reloadingCertificate ? (
                <div className="center">
                  <Spinner size="1.5" />
                </div>
              ) : (
                <CertificateListComponent
                  dataList={complianceDocuments}
                  deleteAction={(record) => removeComplianceDocument(record)}
                />
              )}{" "}
            </div>
          </>
        )}

        <div></div>

        {tab === 5 && (
          <>
            <div className="nk-wizard-head">
              <h5>Equipment</h5>
            </div>
            <CreateShipEquipment
              callback={(equipment) => receiveEquipmentDetails(equipment)}
            >
              {" "}
            </CreateShipEquipment>

            <br style={{ clear: "both" }} />
            {reloadingCertificate ? (
              <div className="center">
                <Spinner size="1.5" />
              </div>
            ) : (
              <EquipmentListComponent
                dataList={listOfNewVesselEquipments}
                deleteAction={(record) => removeEquipmentDetails(record)}
              />
            )}
          </>
        )}
      </form>
      <div className="row">
        <div className="col mb-4 pl-5 mt-5">
          {tab > 1 && (
            <button
              type="button"
              onClick={() => toPreviousTab()}
              className="btn btn-md btn-primary"
            >
              {"<"} Previous
            </button>
          )}
        </div>
        <div className="col text-right mb-4 pr-5 mt-5">
          {tab < 5 && (
            <button
              type="button"
              id="saveVessel"
              onClick={() => toNextTab()}
              className="btn btn-md btn-primary"
            >
              Next {">"}
            </button>
          )}
          {tab == 5 && (
            <>
              {finalSaveloading ? (
                <button type="button" className="btn btn-lg btn-light">
                  <Spinner size="1" />{" "}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => submitForm()}
                  className="btn btn-lg btn-dark"
                >
                  <em className="icon ni ni-save mr-2" />
                  Save Vessel{" "}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <hr className="preview-hr" style={{ visibility: "hidden" }} />
      {/* {modalIsClosed == false && (
        <CustomModal
          title="Add Vessel Master"
          content={
            <>
              <AddVesselMasterComponent callback={editCallback} />
            </>
          }
          onClose={() => setModalIsClosed(true)}
          isClosed={modalIsClosed}
        />
      )} */}
    </MainLayout>
  );
}
