import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import { checkUserPermission } from "../../utils/utilityFunctions";
import EquipmentService from "../../services/equipmentService";
import VesselEquipmentListComponent from "./VesselEquipmentListComponent";
import CreateOrEditShipEquipment from "../../pages/Modals/CreateOrEditShipEquipment";

export default function VesselDetailsEquipmentComponent({
  vesselSpecId,
  forceRefresh,
}) {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [showEquipmentModal, setShowEquipmentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const retrieveEquipmentList = async () => {
    setLoading(true);
    const types = await EquipmentService.listVesselEquipmentV2(vesselSpecId);
    setTableData(types || []);
    setLoading(false);
  };

  const receiveEquipmentDetails = async (equipment) => {
    setLoading(true);
    equipment.VesselSpecificationId = vesselSpecId;

    const res = await EquipmentService.createEquipments([equipment]);
    if (res) {
      equipment?.EquipmentName == "Storage Tank" ||
      equipment?.EquipmentName == "Pump"
        ? forceRefresh()
        : await retrieveEquipmentList();
      toastr("success", "Equipments successfully added");
    }

    setLoading(false);
  };

  const removeEquipment = async (equipment) => {
    setLoading(true);
    const res = await EquipmentService.removeVesselEquipment(
      equipment.ShipSideEquipmentId
    );
    if (res) {
      toastr("success", "Equipment successfully removed");
      equipment?.EquipmentName == "Storage Tank" ||
      equipment?.EquipmentName == "Pump"
        ? forceRefresh()
        : await retrieveEquipmentList();
    }
    setLoading(false);
  };

  const editEquipment = async (equipment) => {
    setLoading(true);
    const res = await EquipmentService.editShipEquipment(equipment);
    if (res) {
      toastr("success", "Equipment details successfully updated");
      equipment?.EquipmentName == "Storage Tank" ||
      equipment?.EquipmentName == "Pump"
        ? forceRefresh()
        : await retrieveEquipmentList();
    } else {
      toastr("error", "Equipment detail could not be updated");
    }

    setLoading(false);
  };

  useEffect(async () => {
    setLoading(true);
    await retrieveEquipmentList();

    setLoading(false);
  }, []);

  const selectRecord = (record) => {
    setSelectedRecord(record);
    setShowEquipmentModal(true);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      {loading ? (
        <div className="center py-5 my-5">
          <Spinner />
        </div>
      ) : (
        <form className=" nk-wizard-simple is-alter p-3">
          {vesselSpecId && (
            <>
              <div className="nk-wizard-head">
                <h5>Vessel Equipments</h5>
              </div>
              {/* Edit equipments */}
              {showEquipmentModal && (
                <CreateOrEditShipEquipment
                  callback={(equipment) => {
                    editEquipment(equipment);
                    setShowEquipmentModal(false);
                  }}
                  editDetails={selectedRecord}
                  show={showEquipmentModal}
                  setShowEquipmentModal={setShowEquipmentModal}
                />
              )}
              <div className="nk-wizard-content">
                {checkUserPermission("vessel_edit_compliance") && (
                  <CreateOrEditShipEquipment
                    callback={(equipment) => receiveEquipmentDetails(equipment)}
                    show={showEquipmentModal}
                    setShowEquipmentModal={setShowEquipmentModal}
                  />
                )}
                <br style={{ clear: "both" }} />
                {loading ? (
                  <div className="center">
                    <Spinner size="1.5" />
                  </div>
                ) : (
                  <VesselEquipmentListComponent
                    dataList={tableData}
                    deleteAction={(record) => removeEquipment(record)}
                    viewAction={(record) => selectRecord(record)}
                  />
                )}{" "}
              </div>
            </>
          )}
          <div></div>
        </form>
      )}

      <hr className="preview-hr" style={{ visibility: "hidden" }} />
    </>
  );
}
