import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import CountryService from "../../services/countriesService";
import Spinner from "../../utils/spinner";
import CertificateService from "../../services/certificateService";
import toastr from "../../utils/toastr";
import ComplianceModal from "../../pages/Modals/complianceModal";
import CertificateListComponent from "../../components/vessel/certificateListComponent";
import {
  removeElementFromArray,
  toReadableDate,
} from "../../utils/utilityFunctions";
import VesselService from "../../services/vesselService";

export default function ComplianceEditPage() {
  const params = useParams();

  const [certificates, setCertificates] = useState([]);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [complianceDocuments, setComplianceDocuments] = useState([]);
  const [showComplianceModal, setShowComplianceModal] = useState(false);
  const [reloadingCertificate, setReloadingCertificate] = useState(false);
  const [vessel, setVessel] = useState({});
  const [statusList, setStatusList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [issuerList, setIssuerList] = useState([]);

  useEffect(() => {
    setLoading(true);
    async function getData() {
      await retrieveCountry();

      const _vessel = await VesselService.fetchVessel(params.vesselId);
      setVessel(_vessel);

      const _certificates = await CertificateService.listAll();
      setCertificates(_certificates || []);

      const _statusList = await CertificateService.statusList();
      setStatusList(_statusList || []);

      const _issuerList = await CertificateService.listIssuers();
      setIssuerList(_issuerList || []);

      if (_vessel && _vessel.VesselSpecificationId) {
        await loadCertificates();
      } else {
        toastr("error", "Page initialization failed");
      }

      setLoading(false);
    }

    getData();
  }, [
    setCountryList,
    setLoading,
    setComplianceDocuments,
    setCertificates,
    setVessel,
    setStatusList,
    setIssuerList,
  ]);

  const retrieveCountry = async () => {
    const country_List = await CountryService.listCountry();
    setCountryList(country_List);
    return country_List;
  };

  const loadCertificates = async () => {
    let _vesselCertificates = await CertificateService.listForVessel(
      params.vesselId
    );
    console.log({ _vesselCertificates });
    if (_vesselCertificates && _vesselCertificates.length > 0) {
      const _vessel = await VesselService.fetchVessel(params.vesselId);
      setVessel(_vessel);

      const _certificates = await CertificateService.listAll();
      setCertificates(_certificates || []);

      const _statusList = await CertificateService.statusList();
      setStatusList(_statusList || []);

      const _issuerList = await CertificateService.listIssuers();
      setIssuerList(_issuerList || []);

      const country_List = await CountryService.listCountry();
      setCountryList(country_List);

      _vesselCertificates.forEach((element) => {
        element.certificateName = _certificates.find(
          (c) =>
            c.CertificateNameId.toLowerCase() ==
            element.CertificateNameId.toLowerCase()
        )?.CertificateName;
        element.placeOfIssueName = country_List.find(
          (c) => c.CountryId.toLowerCase() == element.PlaceOfIssue.toLowerCase()
        )?.CountryName;
        element.status = _statusList.find(
          (c) => c.StatusId.toLowerCase() == element.StatusId.toLowerCase()
        )?.Status;
        element.dateComponent =
          !element.expiryDate && !element.ExpiryDate
            ? "Indefinite"
            : `${toReadableDate(
                element.issueDate || element.IssueDate
              )} - ${toReadableDate(element.expiryDate || element.ExpiryDate)}`;

        element.statusComponent =
          element.IsDeleted == false ? (
            <span className="badge badge-pill badge-dim badge-outline-secondary">
              {
                _statusList.find(
                  (c) =>
                    c.StatusId.toLowerCase() == element.StatusId.toLowerCase()
                )?.Status
              }
            </span>
          ) : (
            <span className="badge badge-pill badge-dim badge-outline-secondary">
              Inactive
            </span>
          );
        if (!element.issuerName) {
          const _issuerForThis = _issuerList.find(
            (a) => a.IssuerId == element.IssuerId
          );
          element.issuerName =
            _issuerForThis &&
            `${_issuerForThis?.IssuerName} (${_issuerForThis?.IssuerAbbreviation})`;
        }
        element.deleteButton = removalButton(element);
        element.editButton = editComplianceButton(element);
      });

      setComplianceDocuments(_vesselCertificates);
    }
  };

  const editComplianceButton = (certificate) => {
    const selectRecord = () => {
      setSelectedRecord(certificate);
      setShowComplianceModal(true);
    };

    if (certificate.IsDeleted == false) {
      return (
        <em
          className="icon ni ni-edit text-primary fs-22px pointer mr-2"
          disabled
          onClick={selectRecord}
        />
      );
    }
  };

  const removalButton = (certificate) => {
    const removeComplianceDocument = async () => {
      setReloadingCertificate(true);

      const _entryId = certificate.EntryId || certificate.entryId;
      const _deleteResponse = await CertificateService.deleteScheduleEntry(
        _entryId
      );

      if (_deleteResponse) {
        setTimeout(() => {
          setReloadingCertificate(false);
        }, 1000);
        setShowComplianceModal(false);
        await loadCertificates();
      }
    };
    if (certificate.IsDeleted == false) {
      return (
        <em
          className="icon ni ni-trash text-danger fs-22px pointer mr-2"
          disabled
          onClick={removeComplianceDocument}
        />
      );
    } else return null;
  };

  const receiveComplianceDocuments = async (doc) => {
    setReloadingCertificate(true);
    if (doc) {
      console.log({ complianceDocument: doc });

      //save document to database
      if (!doc.entryId && !doc.EntryId) {
        //new certificate
        const _complianceDoc = { ...doc };
        _complianceDoc.certificateGroupId = certificates.find(
          (a) => a.CertificateNameId == _complianceDoc.certificateNameId
        ).CertificateGroupId;
        _complianceDoc.vesselSpecificationId = params.vesselId;

        const savedCertificate = await CertificateService.postForVessel(
          _complianceDoc
        );
        if (!savedCertificate) {
          toastr("error", "Could not save compliance documents");
        } else {
          await loadCertificates();
        }
      } else {
        //update certificate
        const _payload = { ...doc };
        delete _payload.status;
        delete _payload.statusComponent;
        delete _payload.certificateName;
        delete _payload.certificateNameComponent;
        delete _payload.dateComponent;
        delete _payload.validityPeriodName;
        delete _payload.deleteButton;
        delete _payload.editButton;

        const savedCertificate = await CertificateService.editForVessel(
          doc.entryId || doc.EntryId,
          _payload
        );
        if (!savedCertificate) {
          toastr("error", "Could not update compliance documents");
        } else {
          await loadCertificates();
        }
      }
    }
    setShowComplianceModal(false);
    setTimeout(() => {
      setReloadingCertificate(false);
    }, 1000);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      loading={loading || reloadingCertificate}
      showFullLoader={loading || reloadingCertificate}
      title="Compliance Documents"
    >
      <form className=" nk-wizard-simple is-alter p-3">
        {vessel && vessel.VesselSpecificationId && (
          <>
            <div className="nk-wizard-head">
              <h5>Compliance</h5>
            </div>
            {showComplianceModal && (
              <ComplianceModal
                callback={(complianceDoc) =>
                  receiveComplianceDocuments(complianceDoc)
                }
                recordToEdit={selectedRecord}
              />
            )}
            <div className="nk-wizard-content">
              <button
                type="button"
                className="btn btn-sm text-primary my-3"
                onClick={() => {
                  setSelectedRecord(null);
                  setShowComplianceModal(true);
                }}
              >
                + Add Compliance Document
              </button>
              <br style={{ clear: "both" }} />
              <CertificateListComponent dataList={complianceDocuments} />{" "}
            </div>
          </>
        )}
        <div></div>
      </form>

      <hr className="preview-hr" style={{ visibility: "hidden" }} />
    </MainLayout>
  );
}
