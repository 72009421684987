import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import VesselService from "../../services/vesselService";
import FleetService from "../../services/fleetService";
import LocationService from "../../services/locationService";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import { camelizeKeys, toReadableDate } from "../../utils/utilityFunctions";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import CustomModal from "../../utils/customModal";
import AddPoolComponent from "../../components/vesselPools/addPoolComponent";
import EditPoolComponent from "../../components/vesselPools/editPoolComponent";
import * as vesselPoolsMockEndpoint from "../../utils/getpoolvessels.json";
import AddVesselToPool from "./addVesselToFleet";
import PoolService from "../../services/vesselPoolsService";
import MapView3 from "../../components/vesselPools/mapView3";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { checkUserPermission } from "../../utils/utilityFunctions";

let vesselsInPool = vesselPoolsMockEndpoint?.Data?.Data;

export default function PoolListPage() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([10, 20, 50, 100, 150]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [totalRecords, setTotalRecords] = useState(10);
  const [vessels, setVessels] = useState([]);
  const [pools, setPools] = useState([]);
  const [poolsList, setPoolsList] = useState([]);
  const [zones, setZones] = useState([]);
  const [fleetId, setFleetId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [fleetName, setFleetName] = useState("");
  const [vesselSpecificationId, setVesselSpecificationId] = useState("");
  const [modalIsClosed, setModalIsClosed] = useState(true);
  const [oldPool, setOldPool] = useState(true);
  const [mapObject, setMapObject] = useState();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [poolName, setPoolName] = useState();
  const [PoolWithVesselInfo, setPoolWithVesselInfo] = useState([]);
  const [vesselDetailsList, setVesselDetailsList] = useState([]);
  const [entirePoolList, setEntirePool] = useState([]);
  const [showPoolModal, setShowPoolModal] = useState(false);


  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    await retrieveVessels();
    await loadData();
    await poolList();
    await getZones();
    await getVessels();
    await getPools();
    // setEntirePoolDetails(vesselsInPool);
    setLoading(false);
  };
  const retrieveVessels = async () => {
    const vesselsList = await VesselService.listAllVessels();
    const vesselsListAbb = vesselsList?.map((vessel) => ({
      VesselName: vessel?.VesselName,
      VesselSpecificationId: vessel?.VesselSpecificationId,
    }));
    setVesselDetailsList(vesselsListAbb);
  };
  const loadData = async (
    _page,
    _pageSize,
    _locationId = "",
    _fleetName = "",
    _fleetId = "",
    _vesselSpecificationId = ""
  ) => {
    setLoading(true);
    _page = _page || page;
    _pageSize = _pageSize || pageSize;
    _locationId = _locationId;
    _fleetId = _fleetId;
    _fleetName = _fleetName;
    _vesselSpecificationId = _vesselSpecificationId;

    const _pools = await FleetService.listFleetDetailed(
      _page,
      _pageSize,
      _locationId,
      _fleetName,
      _fleetId,
      _vesselSpecificationId
    );
    let modifiedDataList = camelizeKeys(_pools?.Data || []);
    setTotalRecords(_pools?.TotalCount || 0);
    modifiedDataList = modifiedDataList.map((l) => ({
      ...l,
      poolCreationDate: toReadableDate(l.dateCreated),
      actionsComponent: overflowMenu(l),
    }));

    setPools(modifiedDataList);

    page != _page && setPage(_page);
    pageSize != _pageSize && setPageSize(_pageSize);
    fleetId != _fleetId && setFleetId(_fleetId);
    fleetName != _fleetName && setFleetName(_fleetName);
    locationId != _locationId && setLocationId(_locationId);
    vesselSpecificationId != _vesselSpecificationId &&
      setVesselSpecificationId(_vesselSpecificationId);

    setLoading(false);
  };

  const poolList = async () => {
    const poolOfVessels = await PoolService.fetchPool();
    setPoolWithVesselInfo(poolOfVessels);
  };

  const getVessels = async () => {
    let _vessels = await VesselService.listAllVessels();
    _vessels = camelizeKeys(_vessels || []);

    let _vesselsList = _vessels.map((c) => ({
      ...c,
      label: c.vesselName,
      value: c.vesselSpecificationId,
    }));

    setVessels(_vesselsList || []);
  };

  const getPools = async () => {
    let _poolsList = await FleetService.listFleet();
    setPoolsList(camelizeKeys(_poolsList) || []);
  };

  const getZones = async () => {
    let _locations = await LocationService.allLocations();
    if (_locations) {
      let _zones = camelizeKeys(
        _locations?.filter(
          (location) => location.LocationTypeName.toLowerCase() == "zone"
        )
      );
      setZones(_zones || []);
    }
  };

  const overflowMenu = (pool) => {
    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                {/* {checkUserPermission("pool_access_pool_details") && ( */}
                <li>
                  <Link to={`/view-pool/${pool.fleetId}`}>
                    <em className="icon ni ni-eye" />
                    <span>View Details</span>
                  </Link>
                </li>
                {/* )} */}
                <li>
                  <a role="button" onClick={() => setSelectedRecord(pool)}>
                    <em className="icon ni ni-files" />
                    <span>Edit Pool</span>
                  </a>
                </li>

                {/* {checkUserPermission("pool_view_pool_map") && ( */}
                <li className="clickable" onClick={() => displayMap(pool)}>
                  <a>
                    <em className="icon ni ni-location" />
                    <span>View on Map</span>
                  </a>
                </li>
                {/* )} */}
                {checkUserPermission("pool_remove_vessel_pool") &&
                  pool.vesselCount == 0 ? null : (
                  <li>
                    <Link to={`/switch-vessel-pool/${pool.fleetId}`}>
                      <em className="icon ni ni-exchange" />
                      <span>Switch Vessel</span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  const displayMap = (pool) => {
    setPoolName(pool?.fleetName);

    let selectedPoolForMapList = vesselsInPool?.find(
      (chosenPool) => chosenPool?.FleetId === pool.fleetId
    )?.FleetVesselDetails;

    // Extracting all the needed details
    selectedPoolForMapList = selectedPoolForMapList?.map((detailsNeeded) => ({
      VesselSpecificationId:
        detailsNeeded?.VesselSpecification?.VesselSpecificationId,
      VesselName: detailsNeeded?.VesselSpecification?.VesselName,
      Longitude: detailsNeeded?.location?.Lon,
      Latitude: detailsNeeded?.location?.Lat,
      Destination: detailsNeeded?.location?.Destination,
      Eta: detailsNeeded?.location?.Eta,
      LastPortId: detailsNeeded?.location?.LastPortId,
      LastPortTime: detailsNeeded?.location?.LastPortTime,
      Speed: detailsNeeded?.location?.Speed,
      FleetName: pool.fleetName,
    }));

    if (
      selectedPoolForMapList === undefined ||
      selectedPoolForMapList.length < 1
    ) {
      toastr("error", "There are no Map coordinates for vessels under this Pool");
      return;
    }
    let coords = selectedPoolForMapList.map((loc) => [
      loc.Latitude,
      loc.Longitude,
      loc,
    ]);
    setMapObject({
      markerOnClick: (loc) => setSelectedMarker(loc[2]),
      useChildren: true,
      markerPositions: coords,
      readOnly: true,
    });
  };
  const headerList = [
    {
      Header: "Pool Name",
      accessor: "fleetName",
    },
    {
      Header: "Zone",
      accessor: "locationName",
    },
    {
      Header: "Number of Vessels",
      accessor: "vesselCount",
    },
    {
      Header: "Creation Date",
      accessor: "poolCreationDate",
    },

    {
      Header: "",
      accessor: "actionsComponent",
    },
  ];

  const exportPage = () => {
    if (!pools.length) return;
    const exportData = pools.map((pool) => {
      const exportObject = {
        "Fleet Name": pool.fleetName,
        "Zone": pool.locationName,
        "Number of Vessels": pool.vesselCount,
        "Date Created": pool.poolCreationDate,
      };
      return exportObject;
    });
    exportToCSV(exportData, "list-of-pools");
  };

  const filterByFleetProperty = async () => {
    await loadData(
      1,
      null,
      locationId,
      fleetName,
      fleetId,
      vesselSpecificationId
    );
  };

  const clearFilter = async () => {
    setFleetId("");
    setFleetName("");
    setLocationId("");
    setVesselSpecificationId("");

    await loadData(1, null);
  };

  const addCallback = async () => {
    onModalClose();
    await loadData();
    setOldPool(true);
  };

  const editCallback = async () => {
    onModalClose();
    await loadData();
    setSelectedRecord(null);
  };

  const onModalClose = () => {
    setModalIsClosed(true);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <a
              href={`${process.env.REACT_APP_API}/api/Fleets/DownloadPoolCSV?page=1&pageSize=50&authorization=${localStorage.token}`}
              // target="_blank"
              className="btn btn-dim btn-secondary"
            >
              <em className="icon ni ni-download" />
              <span>Download Pools</span>
            </a>
          </li>
          {/* {pools && (
            <li>
              <button
                type="button"
                onClick={exportPage}
                className="btn btn-white btn-outline-light"
              >
                <em className="icon ni ni-download-cloud" />
                <span>Export page</span>
              </button>
            </li>
          )} */}

          <li className="nk-block-tools-opt">
            <div className="drodown">
              <a
                href="#"
                className="dropdown-toggle btn btn-icon btn-primary"
                data-toggle="dropdown"
              >
                <em className="icon ni ni-plus" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <ul className="link-list-opt no-bdr">
                  {checkUserPermission("pool_create_pool") && (
                    <li>
                      <a role="button" onClick={() => setOldPool(false)}>
                        Add Pool
                      </a>
                    </li>
                  )}
                  {checkUserPermission("pool_add_vessel_pool") && (
                    <li onClick={() => setShowPoolModal(true)}>
                      <a>
                        <span>Add Vessel to Pool</span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Pools"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <div className="table-component">
        <div className="card-inner-group">
          {
            <div className="card-inner position-relative card-tools-toggle">
              {checkUserPermission("pool_filter_data") && (
                <div className="card-title-group">
                  <div className="card-tools">
                    <div className="dropdown">
                      <a
                        href="#"
                        className="btn btn-trigger btn-icon dropdown-toggle "
                        data-toggle="dropdown"
                      >
                        <em className="icon ni ni-search" />
                      </a>

                      <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
                        <form>
                          <div className="dropdown-head">
                            <span className="sub-title dropdown-title">
                              Filter Pools
                            </span>
                            <div className="dropdown">
                              <a
                                className="btn btn-sm btn-icon"
                                data-toggle="dropdown"
                              >
                                <em
                                  className="icon ni ni-cross"
                                  title="close"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="dropdown-body dropdown-body-rg">
                            <div className="row gx-6 gy-3">
                              <div className="col-12">
                                <label className="overline-title overline-title-alt">
                                  Pool
                                </label>
                                <div
                                  className="form-group"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  
                                  <Select
                                    className="select-dropdown-sm border-transparent"
                                    // placeholder="Filter by Pool"
                                    value={poolsList
                                      .map((c) => ({
                                        ...c,
                                        label: c.fleetName,
                                        value: c.fleetId,
                                      }))
                                      .find((pool) => pool.value === fleetId)}
                                    options={poolsList.map((c) => ({
                                      ...c,
                                      label: c.fleetName,
                                      value: c.fleetId,
                                    }))}
                                    onChange={(option) =>
                                      setFleetId(option.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <label className="overline-title overline-title-alt">
                                  Zone
                                </label>
                                <div
                                  className="form-group"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {/* <div className="mb-1">
                                  <span>Pool:</span>
                                </div> */}
                                  <Select
                                    className="select-dropdown-sm border-transparent"
                                    // placeholder="Filter by Zone"
                                    value={zones
                                      .map((c) => ({
                                        ...c,
                                        label: c.locationName,
                                        value: c.locationId,
                                      }))
                                      .find(
                                        (zone) => zone.value === locationId
                                      )}
                                    options={zones.map((c) => ({
                                      ...c,
                                      label: c.locationName,
                                      value: c.locationId,
                                    }))}
                                    onChange={(option) =>
                                      setLocationId(option.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <label className="overline-title overline-title-alt">
                                  Vessel
                                </label>
                                <div
                                  className="form-group"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <Select
                                    className="select-dropdown-sm border-transparent"
                                    // placeholder="Filter by Vessel"
                                    value={vessels.find(
                                      (vessel) =>
                                        vessel.value === vesselSpecificationId
                                    )}
                                    options={vessels}
                                    onChange={(option) =>
                                      setVesselSpecificationId(option.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="gap gap-10px" />
                              <div className="col-12">
                                <div className="form-group">
                                  <button
                                    type="button"
                                    onClick={filterByFleetProperty}
                                    className="btn btn-secondary"
                                  >
                                    <span>Filter</span>
                                  </button>
                                  <a
                                    className="clickable ml-2"
                                    onClick={() => clearFilter()}
                                  >
                                    Reset Filter
                                  </a>
                                </div>
                              </div>
                              <div className="gap gap-50px" />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* .form-inline */}
                  </div>

                  <div className="card-tools mr-n1">
                    <ul className="btn-toolbar gx-1">
                      <li className="btn-toolbar-sep" />
                      {/* li */}
                      <li>
                        <div className="toggle-wrap">
                          <a
                            href="#"
                            className="btn btn-icon btn-trigger toggle"
                            data-target="cardTools"
                          >
                            <em className="icon ni ni-menu-right" />
                          </a>
                          <div
                            className="toggle-content"
                            data-content="cardTools"
                          >
                            <ul className="btn-toolbar gx-1">
                              <li className="toggle-close">
                                <Link
                                  to="#"
                                  className="btn btn-icon btn-trigger toggle"
                                  data-target="cardTools"
                                >
                                  <em className="icon ni ni-arrow-left" />
                                </Link>
                              </li>
                              {/* li */}

                              <li>
                                <div className="dropdown">
                                  <Link
                                    to={"#"}
                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-setting" />
                                  </Link>
                                  <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                    <ul className="link-check">
                                      <li>
                                        <span>Show</span>
                                      </li>
                                      {pages.map((item, index) => (
                                        <li
                                          onClick={() => loadData(null, item)}
                                          key={index}
                                          className={`${page == item && "active"
                                            }`}
                                        >
                                          <Link to={"#"}>{item}</Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          }
        </div>
        {/* .card-inner-group */}
      </div>
      <>
        <BookingTable
          columns={headerList}
          data={pools}
          cssArray={["font-weight-500", "", "", "text-left", "", ""]}
          enableSearch={false}
          emptyMessage="No pools to show on page"
          disablePageSize={true}
        />
        <br />
        <div className="">
          <Paginate
            onChange={(p) => loadData(p)}
            currentPage={page}
            pageSize={pageSize}
            totalRecordsCount={totalRecords}
          />
        </div>
      </>

      {!oldPool && (
        <CustomModal
          title="Create Pool"
          content={<AddPoolComponent callback={addCallback} />}
          onClose={() => setOldPool(true)}
          isClosed={false}
          noContentPadding={true}
        />
      )}
      {selectedRecord && (
        <CustomModal
          title={`Edit ${selectedRecord.fleetName}`}
          content={
            <EditPoolComponent pool={selectedRecord} callback={editCallback} />
          }
          onClose={() => setSelectedRecord(null)}
          isClosed={false}
          noContentPadding={true}
        />
      )}
      {mapObject && (
        <CustomModal
          title={poolName}
          content={
            <MapView3 {...mapObject}>
              {selectedMarker && (
                <Popup
                  position={[selectedMarker.Latitude, selectedMarker.Longitude]}
                  onClose={() => {
                    setSelectedMarker(null);
                  }}
                >
                  <div>
                    <h6>Vessel Name: {selectedMarker.VesselName}</h6>
                    <p>Destination: {selectedMarker.Destination}</p>
                    {/* <p>Type : {selectedMarker?.LocationTypeName}</p>
                      <p>Party : {selectedMarker?.PartyName || "N/A"}</p> */}
                  </div>
                </Popup>
              )}
            </MapView3>
          }
          onClose={() => setMapObject(null)}
          isClosed={false}
          noContentPadding={true}
        />
      )}

      {showPoolModal && checkUserPermission("pool_add_vessel_pool") && (
        <CustomModal
          title={
            <p>
              <em class="icon ni ni-property-add"></em> Add Vessel to Pool
            </p>
          }
          content={
            <div>
              <AddVesselToPool
                // entirePool={entirePoolList}
                entirePool={poolsList}
                vesselDetails={vesselDetailsList}
                poolWithVesselDetails={PoolWithVesselInfo}
              />
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-sm btn-link m-1"
                  onClick={() => setShowPoolModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          }
          onClose={() => setShowPoolModal(false)}
        />
      )}
    </MainLayout>
  );
}
