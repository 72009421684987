import React, {useEffect, useState} from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";

export default  function RemoveDeckListComponent ({dataList}) {

    const [tableData, setTableData] = useState(null);
    const [initializing, setInit] = useState(true);

    const headerList = [
      {
        Header: "...",
        accessor: "deleteIcon",
      },
      {
        Header: "Booking Reference",
        accessor: "BookingReference",
      },
      {
        Header: "Booking Equipment",
        accessor: "BookingEquipment",
      },
      {
        Header: "Serial Number",
        accessor: "SerialNumber",
      },
      {
        Header: "Gross Weight",
        accessor: "GrossWeightValue",
      },
      {
        Header: "Gross Weight Unit",
        accessor: "GrossWeightUnit",
      },
      {
        Header: "Net Weight",
        accessor: "NetWeightValue",
      },
      {
        Header: "Net Weight Unit",
        accessor: "NetWeightUnit",
      },
      {
        Header: "Measurement",
        accessor: "MeasurementValue",
      },
      {
        Header: "Measurement Unit",
        accessor: "MeasurementUnit",
      },
      {
        Header: "UN Number",
        accessor: "UNNumber",
      },
      {
        Header: "DG Classification",
        accessor: "DGClassification",
      },
      {
        Header: "Subsidiary Risks",
        accessor: "SusidiaryRisk",
      },
      {
        Header: "MARPOL Pollution",
        accessor: "MARPOLPollutionCode",
      },
      {
        Header: "Customs Status",
        accessor: "CustomsStatus",
      },
      {
        Header: "Goods Description",
        accessor: "DescriptionOfGoods",
      },
      {
        Header: "HS Code",
        accessor: "HSCode",
      },{
        Header: "Number of Packages",
        accessor: "NumberOfPackages",
      },
      {
        Header: "IMO Hazard Class",
        accessor: "IMOHazardClass",
      },
      {
        Header: "Packing Group",
        accessor: "PackingGroup",
      },
      {
        Header: "Flashpoint",
        accessor: "Flashpoint",
      },
      {
        Header: "EMS",
        accessor: "EmS",
      },
      
    ];

    

    
    useEffect(async ()=>{
      setInit(true);

      setTableData(dataList);
      console.log({dataList, tableData});
      setInit(false);
    }, [dataList, tableData])

    return (
      <div style={{
        overflow: "auto",
        boxShadow: "rgb(221, 221, 221) 2px 2px 5px 0.1px",
        maxHeight: 400,
        width: 1024
      }}>
        {initializing && <Spinner />}
        {!initializing && <MyTable
          columns={headerList}
          cssArray={[
            "font-weight-500",
            "",
            "",
            "text-center", 
          ]}
          data={dataList || []}
          disablePageSize={true}
          enableSearch={false} emptyMessage="No deck cargos selected"
        />}
      </div>
    );
  }