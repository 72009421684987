import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from "react-router-dom";
import MainLayout from '../../layouts/mainLayout';
import LocationService from '../../services/locationService';
import PayloadUpdater from '../../utils/payloadUpdater';
import Select from "react-select";
import Spinner from '../../utils/spinner';
import { Validator, ValidateGroup, ClearValidator } from "../../components/validator";
import toastr from '../../utils/toastr';
import ProductService from "../../services/productService";
import EquipmentService from '../../services/equipmentService';
import UsageReportComponent from '../../components/product/usageReport';
import VoyageService from '../../services/voyageService';

export default function WaterUsageReportPage() {
    const params = useParams();
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [units, setUnits] = useState([]);
    const [payload, setPayload] = useState({});
    const [saving, setSaving] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [voyage, setVoyage] = useState({});


    const addPayload = new PayloadUpdater({ payload: payload, setPayload: setPayload }).update;
    const validationGroup = "fuel_consumption_log";

    useEffect(async () => {
        await init();


        return () => {
            ClearValidator(validationGroup);
        }
    }, []);

    const init = async () => {
        setLoading(true);

        let _units = await EquipmentService.listMeasurementUnits();
        _units = (_units || []).map(a => ({ ...a, value: a.MeasurementUnitId, label: `${a.MeasurementUnit} (${a.MeasurementUnitSymbol})` }))
            .filter(a => a.UnitType == "volume");
        setUnits(_units);

        let _voyage = await VoyageService.get(params.voyageId);
        setVoyage(_voyage);


        setLoading(false);
    }


    const submitForm = async () => {
        const _payload = { ...payload, voyagePlanningId: params.voyageId };

        setSaving(true);
        const response = await ProductService.waterConsumptionLog(_payload);
        if (response) {
            setPayload({});
            toastr("success", "Consumption log saved successfully");
        }
        setSaving(false);
    }


    return (
        redirectUrl ? <Navigate to={redirectUrl} replace={true} /> :
            <MainLayout title={`Water consumption log`} loading={loading}>
                {!loading && <form>
                    <div className="card-inner">
                        <div className="row">
                            <div className="col-sm-4 col-lg-4">
                                <div className="form-group row" >
                                    <div className="form-control-wrap col-4">
                                    <label className="control-label">Quantity</label>
                                        <input type="number" className="form-control required" placeholder="quantity" defaultValue={voyage?.QuantityofWaterOnboard}
                                            onChange={(e) => addPayload("QuantityofWaterRequested", e.target.value)} />
                                    </div>
                                    <div className="form-control-wrap col-8">
                                    <label className="control-label">Unit of measurement</label>
                                        <Select onChange={(item) => addPayload("WaterMeasurementUnitId", item.value)} placeholder="unit of measurement"
                                            value={units.find(a => a.value == (payload?.WaterMeasurementUnitId || voyage?.WaterMeasurementUnitId))}
                                            options={units} />
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div>
                            {
                                // products.map((product, index)=> <div style={{zIndex: (products.length - index)}} key={index} className="my-2"><UsageReportComponent productId={product.ProductId}  /></div>)
                            }

                        </div>

                    </div>
                    {saving && <div className="form-group p-2 m-3">
                        <button type="button" className="btn btn-md btn-link m-1" ><Spinner size="1.5" /></button>
                    </div>}
                    {!saving && <div className="form-group p-2 m-3">
                        <button type="button" onClick={() => submitForm()} className="btn btn-md btn-dark btn-wide  mr-3">Save Water Consumption</button>
                        <button type="button" className="btn btn-sm btn-link m-1" onClick={() => window.history.back()}>Cancel</button>
                    </div>}
                </form>}

            </MainLayout >
    );
}

