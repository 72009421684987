import React, { useEffect, useState } from "react";
import MyTable from "../dataTable";
import Spinner from "../../utils/spinner";

export default function LocationHistoryListComponent({ dataList }) {
  const [tableData, setTableData] = useState(null);
  const [initializing, setInit] = useState(true);

  const headerList = [
    {
      Header: "Name",
      accessor: "LocationName",
    },
    {
      Header: "Relocation Date",
      accessor: "RelocationDate",
    },
    {
      Header: "Parent Location",
      accessor: "ParentLocationName",
    },
    {
      Header: "Country",
      accessor: "CountryName",
    },
    {
      Header: "Coordinates",
      accessor: "Coordinates",
    },
  ];

  useEffect(async () => {
    setInit(true);

    setTableData(dataList);
    setInit(false);
  }, [dataList, tableData]);

  return (
    <div
      style={{
        overflow: "auto",
        boxShadow: "rgb(221, 221, 221) 2px 2px 5px 0.1px",
        width: "100%",
      }}
    >
      {initializing && <Spinner />}
      {!initializing && (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", "", "", "text-center"]}
          data={dataList || []}
          disablePageSize={true}
          enableSearch={false}
          emptyMessage="No history of relocation for location"
        />
      )}
    </div>
  );
}
