import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import MyTable from "../../components/dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import EquipmentService from "../../services/equipmentService";
import Spinner from "../../utils/spinner";
import { Link } from "react-router-dom";
import Select from "react-select";
import PartyService from "../../services/partyService";
import ViewEquipmentModal from "../Modals/viewEquipmentModal";
import CustomModal from "../../utils/customModal";
import { checkUserPermission, dynamicSort } from "../../utils/utilityFunctions";
import BookingTable from "../../components/bookingTable";
import PersonWithInitial from "../../components/personWithInitial";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";

export default function CargoCarryingEquipmentList() {
  const [tableData, setTableData] = useState(null);
  const [equipment, setEquipment] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState(null);
  const [equipmentCount, setEquipmentCount] = useState(0);
  const [initializing, setInit] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [partyTypeList, setPartyType] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const partyTypeListPromiseState = async (state) =>
    new Promise((resolve) => setPartyType(state, resolve));
  const [measurementTypes, setMeasurementTypes] = useState([]);
  const [equipmentMeasurements, setEquipmentMeasurements] = useState(null);
  const [pages, setPages] = useState([10, 20, 50, 100, 150]);
  const [cargoEquipmentTypes, setCargoEquipmentTypes] = useState([]);
  const [tubularCategories, setTubularCategories] = useState([]);
  const [parties, setParties] = useState([]);
  const [cargoEquipmentTypeId, setCargoEquipmentTypeId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [partyId, setPartyId] = useState("");
  const [totalRecords, setTotalRecords] = useState(10);

  useEffect(async () => {
    setInit(true);

    let _parties = await PartyService.listParty();
    _parties = _parties
      ?.map((c) => ({
        ...c,
        label: c.PartyName,
        value: c.PartyId,
      }))
      .filter((type) => type.PartyType.toLowerCase() == "ccu owner");
    setParties(_parties || []);

    await retrieveMeasurementTypes();
    await getTotalEquipmentCount();
    await retrieveEquipmentTypes();
    await retrieveEquipment();
    await retrieveTubularCategories();

    setInit(false);
  }, []);

  const exportPage = () => {
    if (!equipment.length) return;
    const exportData = equipment.map((e) => {
      const exportObject = {
        "Type ": e.Type,
        "Owner ": e.PartyName,
        "Description ": e.EquipmentDescription,
        "CCU Number ": e.Ccunumber,
      };
      return exportObject;
    });
    exportToCSV(exportData, "list-of-cargo-carrying-equipment");
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {checkUserPermission("equipment_view_details") &&
            checkUserPermission("equipment_edit_equipment_details") && (
              <li>
                <a
                  href={
                    initializing
                      ? "javascript:void(0)"
                      : `${process.env.REACT_APP_API}/api/BookingRequest/DownloadBookingEquipmentsCSV?authorization=${localStorage.token}`
                  }
                  target={initializing ? "" : "_blank"}
                  className={`btn btn-dim btn-secondary ${
                    initializing ? "disabled" : ""
                  }`}
                >
                  <em className="icon ni ni-download" />
                  <span>Download Equipment</span>
                </a>
              </li>
            )}
          {/* {checkUserPermission("equipment_view_details") &&
            checkUserPermission("equipment_edit_equipment_details") &&
            equipment && (
              <li>
                <button
                  type="button"
                  onClick={exportPage}
                  className="btn btn-white btn-outline-light"
                >
                  <em className="icon ni ni-download-cloud" />
                  <span>Export page</span>
                </button>
              </li>
            )} */}
          <li className="nk-block-tools-opt">
            <div className="drodown">
              <a
                href="#"
                className="dropdown-toggle btn btn-icon btn-primary"
                data-toggle="dropdown"
              >
                <em className="icon ni ni-plus" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <ul className="link-list-opt no-bdr">
                  {checkUserPermission("equipment_add_equipment") && (
                    <li>
                      <Link to="/add-equipment">
                        <span>Add Cargo Carrying EquipmentList</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );

  const rowActions = [];
  const rowActions2 = [
    {
      action: "View Details",
      link: "/view-equipment/",
      property1: "Id",
      property2: null,
      icon: "icon ni ni-eye",
    },
    {
      action: "Edit Equipment",
      link: "/edit-equipment/",
      property1: "Id",
      property2: null,
      icon: "icon ni ni-repeat",
    },
  ];
  const headerList = [
    {
      Header: "Equipment Type",
      accessor: "Type",
      link: "/view-party/",
      linkId: "Id",
    },
    {
      Header: "CCU Number",
      accessor: "Ccunumber",
    },
    {
      Header: "Owner",
      accessor: "Owner",
    },
    {
      Header: "Equipment Description",
      accessor: "EquipmentDescription",
    },
    // {
    //   Header: "Volume",
    //   accessor: "Volume",
    // },
    // {
    //   Header: "Capacity",
    //   accessor: "Capacity",
    // },
    // {
    //   Header: "Space capacity",
    //   accessor: "SCapacity",
    // },
    {
      Header: "",
      accessor: "menu",
    },
  ];

  const overflowMenu = (equipment) => {
    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                {checkUserPermission("equipment_view_details") && (
                  <li
                    className="clickable"
                    onClick={() => {
                      setEquipmentMeasurements(equipment.Measurements);
                      setSelectedRecord(equipment);
                    }}
                  >
                    <a>
                      <em className="icon ni ni-eye" />
                      <span>View Details</span>
                    </a>
                  </li>
                )}
                {checkUserPermission("equipment_edit_equipment_details") && (
                  <li>
                    <Link
                      to={`/edit-equipment/${equipment.CargoCarryingEquipment.CargoCarryingEquipmentId}`}
                    >
                      <em className="icon ni ni-file-docs" />
                      <span>Edit equipment details</span>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  const retrieveMeasurementTypes = async () => {
    const types = await EquipmentService.listMeasurementTypes();
    if (types == null) setMeasurementTypes({});
    else setMeasurementTypes(types);
  };

  const retrieveEquipmentTypes = async () => {
    const EquipmentTypes = (await EquipmentService.listEquipmentTypes()) || [];
    setEquipmentTypes(EquipmentTypes);
    setCargoEquipmentTypes(
      EquipmentTypes.map((type) => ({
        ...type,
        value: type.EquipmentTypeId,
        label: type.EquipmentType,
      })).filter(
        (e) =>
          e.EquipmentType.toLowerCase() == "tubular" ||
          e.EquipmentType.toLowerCase() == "bigbag" ||
          e.EquipmentType.toLowerCase() == "offshore container"
      )
    );
  };

  const retrieveTubularCategories = async () => {
    const categories = await EquipmentService.getEquipmentCategories();
    if (categories == null) setTubularCategories({});

    let _parentCategories = categories
      ?.map((e) => ({
        ...e,
        label: e.TubularCategory,
        value: e.TubularCategoryId,
        id: "TubularCategoryId",
      }))
      .filter(
        (category) =>
          category.ParentTubularCategoryId ==
          "00000000-0000-0000-0000-000000000000"
      );

    let _filteredChildrenCategories = categories?.filter(
      (category) =>
        category.ParentTubularCategoryId !==
        "00000000-0000-0000-0000-000000000000"
    );

    let _parentTubularForChildCategories = _filteredChildrenCategories?.map(
      (e) => ({
        ...e,
        parentTubularCategory: _parentCategories
          .map((p) => ({
            TubularCategory: p.TubularCategory,
            TubularCategoryId: p.TubularCategoryId,
          }))
          .find((x) => x.TubularCategoryId == e.ParentTubularCategoryId),
      })
    );
    let _childCategories = _parentTubularForChildCategories?.map((e) => ({
      ...e,
      label:
        e.parentTubularCategory.TubularCategory + " - " + e.TubularCategory,
      value: e.TubularCategoryId,
      id: "TubularSubCategoryId",
    }));
    const _categories = [..._parentCategories, ..._childCategories];
    setTubularCategories(_categories.sort(dynamicSort("label")));
  };

  const retrieveEquipment = async (
    _currentPage,
    _pageSize,
    _query = "",
    _cargoEquipmentTypeId = "",
    _categoryId = "",
    _partyId = ""
  ) => {
    var EquipmentTypes = [];
    var EmeasurementTypes = [];
    if (!equipmentTypes || !measurementTypes) {
      EquipmentTypes = await EquipmentService.listEquipmentTypes();
      EmeasurementTypes = await EquipmentService.listMeasurementTypes();
    } else {
      EquipmentTypes = equipmentTypes;
      EmeasurementTypes = measurementTypes;
    }
    setInit(true);
    _currentPage = _currentPage || currentPage;
    _pageSize = _pageSize || pageSize;
    setSearchParam(_query);
    setCargoEquipmentTypeId(_cargoEquipmentTypeId);
    setCategoryId(_categoryId);
    setPartyId(_partyId);
    const volumeId =
      (EmeasurementTypes?.find((a) => a.MeasurementType == "Volume") || "")
        .MeasurementTypeId || "";
    const capacityId =
      (
        EmeasurementTypes?.find(
          (a) => a.MeasurementType == "LoadCarryingCapacity"
        ) || ""
      ).MeasurementTypeId || "";
    const SpaceCapacityId =
      (
        EmeasurementTypes?.find(
          (a) => a.MeasurementType == "SpaceAreaCapacity"
        ) || ""
      ).MeasurementTypeId || "";
    const equipmentList = await EquipmentService.getCargoCarryingEquipment(
      _currentPage,
      _pageSize,
      _query,
      _cargoEquipmentTypeId,
      _categoryId,
      _partyId
    );
      setTotalRecords(equipmentList?.TotalCount || 0);
    const v = equipmentList.response
      ? equipmentList.response?.map((e) => ({
          ...e.CargoCarryingEquipment,
          Type:
            EquipmentTypes?.find(
              (et) =>
                et.EquipmentTypeId ==
                e.CargoCarryingEquipment.CargoCarryingEquipmentTypeId
            ).EquipmentType || "",
          Owner: <PersonWithInitial name={e.Party.PartyName} />,
          PartyName: e.Party.PartyName,
          Volume:
            e.Measurements.find((m) => m.MeasurementTypeId == volumeId)
              ?.MeasurementValue || "",
          Capacity:
            e.Measurements.find((m) => m.MeasurementTypeId == capacityId)
              ?.MeasurementValue || "",
          SCapacity:
            e.Measurements.find((m) => m.MeasurementTypeId == SpaceCapacityId)
              ?.MeasurementValue || "",
          Id: e.CargoCarryingEquipment.CargoCarryingEquipmentId,
          menu: overflowMenu(e),
        }))
      : [];

    currentPage != _currentPage && setCurrentPage(_currentPage);
    pageSize != _pageSize && setPageSize(_pageSize);
    setEquipment(v);
    setTableData(v);
    setInit(false);
  };

  const getTotalEquipmentCount = async () => {
    const count = await EquipmentService.countCargoEquipments();
    if (count != null) {
      setEquipmentCount(count);
    }
  };

  const deleteAction = () => {
    console.log("click worked");
  };

  const tableTopActions = <div></div>;

  const filterByEquipmentProperty = async () => {
    await retrieveEquipment(
      1,
      null,
      searchParam,
      cargoEquipmentTypeId,
      categoryId,
      partyId
    );
  };

  const clearFilter = async () => {
    setSearchParam("");
    setCargoEquipmentTypeId("");
    setCategoryId("");
    setPartyId("");

    await retrieveEquipment(1, null);
  };

  return (
    <MainLayout
      title="Cargo Carrying Equipment"
      loading={initializing}
      showFullLoader={initializing}
      pageActions={pageActions}
    >
      <div className="table-component">
        <div className="card-inner-group">
          {
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  <div className="dropdown">
                    <a
                      href="#"
                      className="btn btn-trigger btn-icon dropdown-toggle "
                      data-toggle="dropdown"
                    >
                      <em className="icon ni ni-search" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
                      <form>
                        <div className="dropdown-head">
                          <span className="sub-title dropdown-title">
                            Filter Equipment
                          </span>
                          <div className="dropdown">
                            <a
                              className="btn btn-sm btn-icon"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-cross" title="close" />
                            </a>
                          </div>
                        </div>
                        <div className="dropdown-body dropdown-body-rg">
                          <div className="row gx-6 gy-3">
                            <div className="col-12">
                              <div className="form-group">
                                <label
                                  className="overline-title overline-title-alt"
                                  htmlFor="from-date-filter"
                                >
                                  Search
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  placeholder="Enter  CCU no. or equipment desc."
                                  value={searchParam}
                                  onChange={(e) =>
                                    setSearchParam(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div
                                className="form-group"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <label className="overline-title overline-title-alt">
                                  Equipment Type
                                </label>
                                <Select
                                  className="select-dropdown-sm"
                                  placeholder="Filter by equipment type"
                                  value={cargoEquipmentTypes.find(
                                    (type) =>
                                      type.value === cargoEquipmentTypeId
                                  )}
                                  options={cargoEquipmentTypes}
                                  onChange={(e) =>
                                    setCargoEquipmentTypeId(e.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div
                                className="form-group"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <label className="overline-title overline-title-alt">
                                  Party
                                </label>
                                <Select
                                  className="select-dropdown-sm"
                                  placeholder="Filter by party"
                                  value={parties.find(
                                    (party) => party.value === partyId
                                  )}
                                  options={parties}
                                  onChange={(e) => setPartyId(e.value)}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div
                                className="form-group"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <label className="overline-title overline-title-alt">
                                  Tubular Category
                                </label>
                                <Select
                                  className="select-dropdown-sm"
                                  placeholder="Filter by tubular category"
                                  value={tubularCategories.find(
                                    (category) => category.value === categoryId
                                  )}
                                  options={tubularCategories}
                                  onChange={(e) => setCategoryId(e.value)}
                                />
                              </div>
                            </div>
                            {/* <div className="col-12">
                              <div
                                className="form-group"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <label className="overline-title overline-title-alt">
                                  Sort Alphabetically
                                </label>
                                <Select
                                  className="select-dropdown-sm"
                                  placeholder=""
                                  value={orderList.find(
                                    (order) => order.value === sortingOrder
                                  )}
                                  options={orderList}
                                  onChange={(e) => setSortingOrder(e.value)}
                                />
                              </div>
                            </div> */}

                            <div className="gap gap-10px" />

                            <div className="col-12">
                              <div className="form-group">
                                <button
                                  type="button"
                                  onClick={filterByEquipmentProperty}
                                  className="btn btn-secondary"
                                >
                                  <span>Filter</span>
                                </button>
                                <a
                                  className="clickable ml-2"
                                  onClick={() => clearFilter()}
                                >
                                  Reset Filter
                                </a>
                              </div>
                            </div>
                            <div className="gap gap-50px" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* .form-inline */}
                </div>

                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li className="btn-toolbar-sep" />
                    <li>
                      <div className="toggle-wrap">
                        <a
                          href="#"
                          className="btn btn-icon btn-trigger toggle"
                          data-target="cardTools"
                        >
                          <em className="icon ni ni-menu-right" />
                        </a>
                        <div
                          className="toggle-content"
                          data-content="cardTools"
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <a
                                href="#"
                                className="btn btn-icon btn-trigger toggle"
                                data-target="cardTools"
                              >
                                <em className="icon ni ni-arrow-left" />
                              </a>
                            </li>

                            <li>
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  <em className="icon ni ni-setting" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    {pages.map((item, index) => (
                                      <li
                                        onClick={() =>
                                          retrieveEquipment(
                                            null,
                                            item,
                                            searchParam,
                                            cargoEquipmentTypeId,
                                            categoryId,
                                            partyId
                                          )
                                        }
                                        key={index}
                                        className={`${
                                          currentPage == item && "active"
                                        }`}
                                      >
                                        <Link to={"#"}>{item}</Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <>
        <BookingTable
          columns={headerList}
          cssArray={["font-weight-500", "", "", "text-left", "", ""]}
          data={tableData}
          enableSearch={false}
          emptyMessage="Equipment List is Empty"
          disablePageSize={true}
        />
      </>
      <br />
      {/* {equipment.length !== 0 ? ( */}
        <div className="">
          <Paginate
            onChange={(p) =>
              retrieveEquipment(
                p,
                null,
                searchParam,
                cargoEquipmentTypeId,
                categoryId,
                partyId
              )
            }
            currentPage={currentPage}
            pageSize={pageSize}
            totalRecordsCount={totalRecords}
          />
        </div>
      {/* ) : null} */}
      {selectedRecord && equipmentMeasurements && (
        <CustomModal
          // title={selectedRecord.CargoCarryingEquipment.EquipmentDescription}
          content={
            <>
              <ViewEquipmentModal
                equipment={selectedRecord}
                measurementTypes={measurementTypes}
                equipmentMeasurements={equipmentMeasurements}
              />{" "}
              <hr style={{ opacity: "0.5", marginBottom: "30px" }} />
            </>
          }
          onClose={() => {
            setEquipmentMeasurements(null);
            setSelectedRecord(null);
          }}
          isClosed={false}
        />
      )}
    </MainLayout>
  );
}
