import apiCall from "../utils/apiCall";

export default class DPService {
  static dpList = async () => {
    let response = await apiCall(
      `/api/DynamicPositioning`,
      "GET",
      null,
      null,
      false,
      true
    );

    if (response?.Data) {
      response = response.Data;
    }

    return response;
  };
}
