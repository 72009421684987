import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import Tabs from "../../utils/tabs";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { camelizeKeys, toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import UserService from "../../services/userService";
import EditUserDetailsComponent from "../../components/users/editUserDetailsComponent";
import AssignUserRoleComponent from "../../components/users/assignUserRoleComponent";

export default function EditUserDetailsPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [commercial, setCommercial] = useState({});
  const [user, setUser] = useState([]);
  const [userRoleIds, setUserRoleIds] = useState([]);

  useEffect(async () => {
    await init();
  }, [params.userId]);

  const init = async () => {
    setLoading(true);
    let _user = await UserService.getUserById(params.userId);
    _user = camelizeKeys(_user || []);
    setUser(_user);
    setUserRoleIds(_user?.roles?.map((role) => role.roleId));
    setLoading(false);
  };

  const tabHeaders = [
    { icon: "icon ni ni-view-grid-wd", text: "Details" },
    { icon: "icon ni ni-user", text: "Assign Role" },
  ];

  const tabContent = [
    <EditUserDetailsComponent user={user} userId={params.userId} />,
    <AssignUserRoleComponent
      userRoleIds={userRoleIds}
      userId={params.userId}
    />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <a
              role="button"
              href="/users"
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to Users</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title={
        user.firstName == null || user.lastName == null
          ? "Edit User Details"
          : `Edit User Details - ${user.firstName} ${user.lastName}`
      }
      loading={loading}
      showFullLoader={loading}
      pageActions={pageActions}
    >
      <div className="">{_tabView}</div>
    </MainLayout>
  );
}
