import React from "react";
import Select from "react-select";

function VesselStorageTankInput({
  selectedTank,
  setSelectedTank,
  measurementUnits,
  selectMeasurementUnit,
  saveTankEntry,
  discardChanges,
  tankProducts,
}) {
  return (
    <>
      <div className="row px-4">
        <div className="col-12 row mb-1">
          <h6>Tank</h6>
        </div>
        <div className=" form-group row align-items-center">
          <div className="form-group col-4">
            <label className="form-label">Product</label>
            <div className="form-control-wrap">
              <Select
                id="ProductId"
                name="ProductId"
                value={tankProducts.find(
                  (product) =>
                    product.ProductId === selectedTank?.tank?.ProductId
                )}
                onChange={(item) => {
                  setSelectedTank((prev) => ({
                    ...prev,
                    tank: {
                      ...prev.tank,
                      ProductName: item.label,
                      ProductId: item.value,
                    },
                    pump: {
                      ...prev.pump,
                      ProductName: item.label,
                      ProductId: item.value,
                    },
                  }));
                }}
                options={tankProducts}
              />
            </div>
          </div>

          <div className="form-group col-2">
            <label className="form-label"> Count</label>
            <input
              type="number"
              className="form-control required"
              required
              value={selectedTank?.tank?.EquipmentCount}
              onChange={(e) =>
                setSelectedTank((prev) => ({
                  ...prev,
                  tank: {
                    ...prev.tank,
                    EquipmentCount: e.target.value,
                  },
                }))
              }
            />
          </div>

          <div className="form-group col-2">
            <label className="form-label"> Capacity</label>
            <input
              type="number"
              className="form-control required"
              required
              value={selectedTank?.tank?.CapacityMeasure}
              onChange={(e) =>
                setSelectedTank((prev) => ({
                  ...prev,
                  tank: {
                    ...prev.tank,
                    CapacityMeasure: e.target.value,
                  },
                }))
              }
            />
          </div>

          <div className="form-group col-3">
            <label className="form-label"> Measurement Unit</label>
            <div className="form-control-wrap">
              <Select
                id="MeasurementUnitId"
                name="MeasurementUnitId"
                value={measurementUnits.find(
                  (unit) =>
                    unit.MeasurementUnitId ===
                    selectedTank?.tank?.MeasurementUnitId
                )}
                onChange={(item) => {
                  selectMeasurementUnit(item, "tank");
                }}
                options={measurementUnits.filter(
                  (unit) => unit.UnitType == "volume"
                )}
              />
            </div>
          </div>

          <div></div>
        </div>
      </div>

      <div className="row pt-2 px-4">
        <div className="col-12 row mb-1">
          <h6> {selectedTank?.tank?.ProductName} Pump</h6>
        </div>
        <div className=" form-group row align-items-center">
          {/* <div className="form-group col-3">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control required"
              required
              value={selectedTank?.pump?.Description}
              onChange={(e) =>
                setSelectedTank((prev) => ({
                  ...prev,
                  pump: {
                    ...prev.pump,
                    Description: e.target.value,
                  },
                }))
              }
            />
          </div> */}

          <div className="form-group col-2">
            <label className="form-label"> Count</label>
            <input
              type="number"
              className="form-control required"
              required
              value={selectedTank?.pump?.EquipmentCount}
              onChange={(e) =>
                setSelectedTank((prev) => ({
                  ...prev,
                  pump: {
                    ...prev.pump,
                    EquipmentCount: e.target.value,
                  },
                }))
              }
            />
          </div>

          <div className="form-group col-2">
            <label className="form-label"> Capacity</label>
            <input
              type="number"
              className="form-control required"
              required
              value={selectedTank?.pump?.CapacityMeasure}
              onChange={(e) =>
                setSelectedTank((prev) => ({
                  ...prev,
                  pump: {
                    ...prev.pump,
                    CapacityMeasure: e.target.value,
                  },
                }))
              }
            />
          </div>

          <div className="form-group col-4">
            <label className="form-label"> Measurement Unit</label>
            <div className="form-control-wrap">
              <Select
                id="MeasurementUnitId-pump"
                name="MeasurementUnitId-pump"
                value={measurementUnits.find(
                  (unit) =>
                    unit.MeasurementUnitId ===
                    selectedTank?.pump?.MeasurementUnitId
                )}
                onChange={(item) => {
                  selectMeasurementUnit(item, "pump");
                }}
                options={measurementUnits.filter(
                  (unit) => unit.UnitType == "transfer_rate"
                )}
              />
            </div>
          </div>

          <div></div>
        </div>
      </div>
      <div className="col-12 form-group">
        <span
          className="text-primary fs-14px pointer"
          onClick={() => saveTankEntry()}
        >
          <em
            class="icon ni ni-check text-primary pointer mr-1"
            title="Save"
          ></em>
          Save
        </span>

        <span
          className="text-danger fs-14px ml-3 pointer"
          onClick={(e) => discardChanges()}
        >
          <em class="icon ni ni-trash  mr-1" title="Discard"></em>
          Discard
        </span>
      </div>
    </>
  );
}

export default VesselStorageTankInput;
