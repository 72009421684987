import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import StartEndVoyageActivityListComponent from "./startEndVoyageActivityList";
import ParallelVoyageActivityListComponent from "./parallelVoyageActivitiesList";
import VoyageActivityService from "../../services/voyageActivityService";
import VoyageService from "../../services/voyageService";
import BulkListComponent from "../voyage/bulkList";
import DeckListComponent from "../voyage/deckList";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import {
  toReadableDate,
  toReadableDateTime,
} from "../../utils/utilityFunctions";
import { camelizeKeys, dynamicSort } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import ActivityService from "../../services/activityService";
import LocationService from "../../services/locationService";

export default function StartEndVoyageActivityComponent({ voyageActivityId }) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [voyageActivity, setVoyageActivity] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalRecords, setTotalRecords] = useState(15);
  const [voyageActivities, setVoyageActivities] = useState([]);
  const [parallelVoyageActivities, setParallelVoyageActivities] = useState([]);
  const [previousVoyageActivity, setPreviousVoyageActivity] = useState([]);
  const [startMainActivity, setStartMainActivity] = useState({
    endingVoyageActivityId: "",
    startingVoyageActivityId: "",
    actualEndDate: "",
  });
  const [sailingPayload, setSailingPayload] = useState({
    voyagePlanningId: "",
    etaToNextLocation: "",
    nextLocationId: "",
    actualEndDateOfRunningActivity: "",
    actualSailingStartDate: "",
  });
  const [locations, setLocations] = useState([]);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;

  const addSailingPayload = new PayloadUpdater({
    payload: sailingPayload,
    setPayload: setSailingPayload,
  }).update;
  const validationGroup = "startactivity";

  useEffect(async () => {
    await init();
  }, []);

  const init = async (_page, _pageSize) => {
    setInit(true);
    const _voyageActivity = await VoyageActivityService.getVoyageActivity(
      voyageActivityId
    );
    let modifiedDataList = camelizeKeys(_voyageActivity?.Data || []);
    let dataList = [];
    dataList.push({ ...modifiedDataList });
    let voyageActivityData = dataList.map((voyageActivity) => ({
      ...voyageActivity,
      proposedStartDate: toReadableDateTime(voyageActivity.plannedStartDate),
      proposedEndDate: toReadableDateTime(voyageActivity.endDate),
    }));
    setVoyageActivity(voyageActivityData || []);

    // await retrieveParallelActivities();
    _page = _page || 1;
    _pageSize = _pageSize || 50;
    const _voyageActivities =
      await VoyageActivityService.getVoyageActivitiesList(
        _page,
        _pageSize,
        payload.voyagePlanningId
      );

    const voyageActivitiesDataList = camelizeKeys(
      _voyageActivities?.Data.voyageActivities || []
    );
    setVoyageActivities(voyageActivitiesDataList);

    if (_voyageActivities?.Data.TotalCount) {
      setTotalRecords(_voyageActivities.Data.TotalCount);
    }

    if (_voyageActivities) {
      let parallelVoyageActivitiesDataList = voyageActivitiesDataList
        .map((voyageActivity) => ({
          ...voyageActivity,
          plannedStartDate: toReadableDate(voyageActivity.plannedStartDate),
          endDate: toReadableDate(voyageActivity.endDate),
        }))
        .filter(
          (activity) =>
            activity.activityName !== voyageActivityData[0].activityName &&
            activity.locationId == voyageActivityData[0].locationId &&
            activity.allowsParallel == true &&
            activity.activityStatus == "in-progress"
        );
      setParallelVoyageActivities(parallelVoyageActivitiesDataList || []);
    }

    if (_voyageActivities) {
      let _previousVoyageActivity = voyageActivitiesDataList
        .map((voyageActivity) => ({
          ...voyageActivity,
          plannedStartDate: toReadableDate(voyageActivity.plannedStartDate),
          endDate: toReadableDate(voyageActivity.endDate),
        }))
        .find(
          (activity) =>
            activity.allowsParallel == false &&
            activity.activityStatus == "in-progress" &&
            activity.plannedStartDate !== null
        );
      setPreviousVoyageActivity(_previousVoyageActivity || []);
    }

    let _locationTypes = await LocationService.listLocationTypes(1, 1000);
    let excludedLocationTypes = (_locationTypes || []).filter(
      (a) =>
        a.LocationType?.toLowerCase() == "zone" ||
        a.LocationType?.toLowerCase() == "port terminal-berth" ||
        a.LocationType?.toLowerCase() == "port terminal-warehouse location" ||
        a.LocationType?.toLowerCase() == "logistics base-warehouse location" ||
        a.LocationType?.toLowerCase() == "logistics base-berth"
    );

    const excludedLocationTypeIds = excludedLocationTypes?.map(
      (locationType) => locationType.LocationTypeId
    );

    let _locations = await LocationService.list(1, 1000);
    _locations = (_locations || []).map((e) => ({
      ...e,
      label: e.LocationName,
      value: e.LocationId,
    }));
    if (excludedLocationTypeIds) {
      //activities cannot be performed at an excluded location
      _locations = _locations.filter(
        (a) => !excludedLocationTypeIds.includes(a.LocationTypeId)
      );
    }
    setLocations(_locations || []);

    setInit(false);
  };

  payload.voyagePlanningId = voyageActivity[0]?.voyagePlanningId || "";
  payload.voyageActivityId = voyageActivity[0]?.voyageActivityId || "";
  payload.activityCategory = voyageActivity[0]?.activityCategory || "";
  payload.productivityOfActivity =
    voyageActivity[0]?.productivityOfActivity || "";
  payload.locationId = voyageActivity[0]?.locationId || "";
  payload.endDate = voyageActivity[0]?.endDate || "";
  payload.plannedStartDate = voyageActivity[0]?.plannedStartDate || "";
  payload.actualEndDate = voyageActivity[0]?.actualEndDate || "";
  payload.activityId = voyageActivity[0]?.activityId || "";
  payload.actualEndDate = voyageActivity[0]?.actualEndDate || "";

  startMainActivity.startingVoyageActivityId =
    voyageActivity[0]?.voyageActivityId || "";
  startMainActivity.endingVoyageActivityId =
    previousVoyageActivity.voyageActivityId || "";
  startMainActivity.actualEndDate = payload.actualStartDate || "";

  sailingPayload.voyagePlanningId = voyageActivity[0]?.voyagePlanningId || "";
  sailingPayload.actualEndDateOfRunningActivity =
    payload?.actualStartDate || "";
  sailingPayload.actualSailingStartDate = payload?.actualStartDate || "";

  console.log(sailingPayload);
  console.log(voyageActivity);
  console.log(previousVoyageActivity);

  const startAndUpdateActivity = async () => {
    const _payload = { ...payload };

    if (!_payload.actualStartDate) {
      toastr("error", "Pick actual start date");
      return;
    }

    setSaving(true);

    const response = await VoyageActivityService.updateVoyageActivity2(
      _payload
    );
    if (response) {
      setPayload({});
      // toastr("success", "Voyage activity started");
    }

    const request = await VoyageActivityService.updateVoyageActivityStatus(
      voyageActivityId
    );
    if (request) {
      toastr("success", "Voyage activity started");
      // toastr("success", "Voyage activity status updated");
      setRedirectUrl(
        `/voyage-activities/${voyageActivity[0].voyagePlanningId}`
      );
      // await init();
    } else {
      toastr("error", "Voyage activity not started");
    }

    setSaving(false);
  };

  const startEndMainActivity = async () => {
    const _payload = { ...payload };
    const _requestPayload = { ...startMainActivity };
    const _sailingPayload = { ...sailingPayload };

    if (!_payload.actualStartDate) {
      toastr("error", "Pick actual start date");
      return;
    }

    if (voyageActivity[0].activityName !== "Sailing") {
      setSaving(true);

      const request = await ActivityService.autoStartAndEndMainActivity(
        _requestPayload
      );
      if (request) {
        toastr("success", "Voyage activity started");
        setRedirectUrl(
          `/voyage-activities/${voyageActivity[0].voyagePlanningId}`
        );
      } else {
        toastr("error", "Voyage activity not started");
      }

      setSaving(false);
    } else if (voyageActivity[0].activityName == "Sailing") {
      setSaving(true);

      const request = await VoyageActivityService.startSailing(_sailingPayload);
      if (request) {
        toastr("success", "Sailing activity started");
        setRedirectUrl(
          `/voyage-activities/${voyageActivity[0].voyagePlanningId}`
        );
      } else {
        toastr("error", "Sailing activity not started");
      }

      setSaving(false);
    }
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card card-bordered shadow-lg">
          <div className="card-inner p-5">
            <div className="gap gap-20px" />
            {/* <hr className="preview-hr" /> */}
            <span className="preview-title-lg overline-title">
              Activity to Start{" "}
            </span>
            <div className="table code-block border-lighter">
              <StartEndVoyageActivityListComponent dataList={voyageActivity} />
            </div>
            <div className="gap gap-20px" />
            <form>
              <div className="row align-items-center">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="fw-last-name">
                      Actual start date / time
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="datetime-local"
                        data-msg="Required"
                        className="form-control required"
                        id="fw-last-name"
                        name="fw-last-name"
                        required=""
                        onChange={(e) =>
                          addPayload("actualStartDate", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  {voyageActivity[0].activityName != "Sailing" ? null : (
                    <>
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-last-name">
                          Next Location
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(item) =>
                              addSailingPayload(
                                ["nextLocationId"],
                                [item.value]
                              )
                            }
                            options={locations}
                            placeholder="select location"
                          />
                          {/* <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            name="fw-last-name"
                            required=""
                            value={voyageActivity[0].locationName}
                          /> */}
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-last-name">
                          ETA
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="datetime-local"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            name="fw-last-name"
                            required=""
                            onChange={(e) =>
                              addSailingPayload(
                                "etaToNextLocation",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="form-group">
                        <label className="form-label" htmlFor="fw-last-name">
                          Activity
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            name="fw-last-name"
                            required=""
                            // onChange={(e) => addPayload("actualStartDate", e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="fw-last-name">
                          Reason to start waiting
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            data-msg="Required"
                            className="form-control required"
                            id="fw-last-name"
                            name="fw-last-name"
                            required=""
                            // onChange={(e) => addPayload("actualStartDate", e.target.value)}
                          />
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </form>

            {voyageActivity[0].activityName == "Sailing" ||
            parallelVoyageActivities.length == 0 ? null : (
              <>
                <div className="gap gap-30px" />
                {/* <hr className="preview-hr" /> */}
                <div className="table code-block border-lighter">
                  <span className="preview-title-lg overline-title">
                    Other current activities{" "}
                  </span>
                  <ParallelVoyageActivityListComponent
                    dataList={parallelVoyageActivities}
                  />
                </div>
              </>
            )}

            <hr className="preview-hr" />

            {voyageActivity[0].allowsParallel == false &&
            previousVoyageActivity.length !== 0 ? (
              <>
                <div className="float-right">
                  {saving ? (
                    <div className="form-group p-2 m-3">
                      <Spinner size="1.5" />
                    </div>
                  ) : (
                    <div className="form-group p-2 m-3">
                      <ReactTooltip className="text-center" id="main">
                        Starting this activity will end the
                        <br /> currently running main activity
                      </ReactTooltip>
                      <button
                        className="btn btn-md btn-dark btn-wide px-5 mr-3"
                        onClick={() => startEndMainActivity()}
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Start main activity"
                        data-tip
                        data-for="main"
                      >
                        Start Activity
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-link m-1"
                        onClick={() => window.history.back()}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="float-right">
                  {saving ? (
                    <div className="form-group p-2 m-3">
                      <Spinner size="1.5" />
                    </div>
                  ) : (
                    <div className="form-group p-2 m-3">
                      <button
                        type="button"
                        onClick={() => startAndUpdateActivity()}
                        className="btn btn-md btn-dark btn-wide px-5 mr-3"
                      >
                        Start Activity
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-link m-1"
                        onClick={() => window.history.back()}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}

            {/* {voyageActivity[0].allowsParallel == true ? (
              <div className="float-right">
                {saving ? (
                  <div className="form-group p-2 m-3">
                    <Spinner size="1.5" />
                  </div>
                ) : (
                  <div className="form-group p-2 m-3">
                    <button
                      type="button"
                      onClick={() => startAndUpdateActivity()}
                      className="btn btn-md btn-dark btn-wide px-5 mr-3"
                    >
                      Start Activity
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-link m-1"
                      onClick={() => window.history.back()}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="float-right">
                {saving ? (
                  <div className="form-group p-2 m-3">
                    <Spinner size="1.5" />
                  </div>
                ) : (
                  <div className="form-group p-2 m-3">
                    <ReactTooltip className="text-center" id="main">
                      Starting this activity will end the
                      <br /> currently running main activity
                    </ReactTooltip>
                    <button
                      className="btn btn-md btn-dark btn-wide px-5 mr-3"
                      onClick={() => startEndMainActivity()}
                      data-toggle="tooltip"
                      data-placement="top"
                      data-original-title="Start main activity"
                      data-tip
                      data-for="main"
                    >
                      Start Activity
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-link m-1"
                      onClick={() => window.history.back()}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            )} */}
          </div>
        </div>
      )}
    </>
  );
}
