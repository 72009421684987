import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";

export default function VoyageDetailsActivityComponent({ voyage }) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {};

  const dataItem = (label, value) => {
    if (value == "null") value = "";
    return (
      <li className="col-6 col-lg-3">
        <p>
          <span className="text-soft small">{label}</span> {value || "-"}
        </p>
      </li>
    );
  };

  return (
    <>
      <div className="card sp-plan">
        <div className="row no-gutters">
          <div className="col-md-8">
            <div className="sp-plan-info card-inner">
              <div className="row gx-0 gy-3">
                <div className="col-xl-9 col-sm-8">
                  <div className="sp-plan-name">
                    <h6 className="title">
                      <Link to={"#"}>
                        {voyage.VoyageCode}
                        {voyage.SailingStatus && (
                          <span className="badge badge-light badge-pill ml-3">
                            {voyage.SailingStatus}
                          </span>
                        )}
                      </Link>
                    </h6>
                    <p style={{ visibility: "hidden" }}>
                      '': <span className="text-base">{"-"}</span>
                    </p>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-4"></div>
              </div>
            </div>
            <div className="sp-plan-desc card-inner">
              <p>
                <span className="preview-title-lg overline-title">Voyage</span>{" "}
              </p>
              <ul className="row gx-1">
                {dataItem("Voyage Code", voyage.VoyageCode)}
                {dataItem("Vessel Name", voyage.VesselName)}
                {dataItem("Sailing Status", voyage.SailingStatus)}
                {dataItem("Sailing Duration", voyage.SailingDuration)}
                {dataItem("Arrival Port", voyage.ArrivalPortName || "-")}
                {dataItem(
                  "Expected Date of Arrival",
                  toReadableDate(voyage.ExpectedDateOfArrival) || "-"
                )}
                {dataItem("Departure Port", voyage.DeparturePortName)}
                {dataItem(
                  "Expected Date of Departure",
                  toReadableDate(voyage.ExpectedDateOfDeparture) || "-"
                )}
                {dataItem("Zone Name", voyage.ZoneName)}
                {dataItem("Voyage Status", voyage.VoyagePlanningStatus)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
