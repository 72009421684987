import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import FleetService from "../../services/fleetService";
import Tabs from "../../utils/tabs";
import { camelizeKeys, toReadableDate } from "../../utils/utilityFunctions";
import ReactTooltip from "react-tooltip";
import VesselsInPoolsViewComponent from "../../components/vesselPools/poolViewList";
import SwitchVesselPoolComponent from "../../components/vesselPools/switchVesselPoolComponent";
import CustomModal from "../../utils/customModal";
import VesselService from "../../services/vesselService";
import DeletedVesselListComponent from "../../components/vesselPools/deletedVesselList";
import * as vesselPoolsMockEndpoint from "../../utils/getpoolvessels.json";
import MapViewTabComponent from "../../components/vesselPools/mapViewComponent"
let vesselsInPool = vesselPoolsMockEndpoint?.Data?.Data;

export default function ViewPool() {
  const params = useParams();
  const [vesselsList, setVesselsList] = useState([]);
  const [fleetId, setVFleetId] = useState(params.fleetId);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [currentPoolName, setCurrentPoolName] = useState("");
  const [pools, setPools] = useState([]);
  const [modalIsClosed, setModalIsClosed] = useState(true);
  const [deletedList, setDeletedList] = useState([]);
  const [selectedPoolForMapList, setSelectedPoolForMapList] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    // await getAllVessels();
    await deletedVessels();
    await retrieveVessels();
    await retrievePool();
    await mapDetailsForPool();
    setLoading(false);
  };

  const mapDetailsForPool = async () => {
    let mapDetails = await vesselsInPool?.find(
      (chosenPool) => chosenPool?.FleetId === fleetId.toString()
    )?.FleetVesselDetails;
    setSelectedPoolForMapList(mapDetails);
  };
  const retrievePool = async () => {
    let listOfPool = await FleetService.listFleet();
    listOfPool = camelizeKeys(listOfPool || []);

    if (listOfPool) {
      let filteredPoolList = listOfPool
        ?.map((list) => ({
          ...list,
          label: list.fleetName,
          value: list.fleetId,
        }))
        .filter((pool) => pool.fleetId !== params.fleetId);
      setPools(filteredPoolList || []);
    }
  };
  const retrieveVessels = async () => {
    let poolsInVessel = await FleetService.getAllVesselsByFleetId(
      1,
      15,
      params.fleetId
    );
    poolsInVessel = camelizeKeys(...(poolsInVessel || []));
    setCurrentPoolName(poolsInVessel?.fleetName || "");

    poolsInVessel = poolsInVessel?.fleetVesselDetails?.map((vessel) => ({
      ...vessel,
      vesselName: vessel?.vesselSpecification.vesselName,
      removeIcon: switchIcon(vessel),
    }));
    setVesselsList(poolsInVessel);
  };
  const deletedVessels = async () => {
    let deleted = await FleetService.getDeletedVesselsFromPool(1, 25);
    let mainDeleted = deleted.find(
      (specificFleet) => specificFleet?.FleetId === params?.fleetId
    )?.DeletedVessels;
    let allVessels = await VesselService.listAllVessels();
    // allVessels = camelizeKeys(allVessels || []);
    let allVesselFiltered = allVessels.map((list) => ({
      vesselName: list.VesselName,
      vesselSpecificationId: list.VesselSpecificationId,
    }));

    if (allVesselFiltered) {
      let editedList = mainDeleted?.map((vessel) => ({
        vesselName: allVesselFiltered?.find(
          (condition) =>
            condition?.vesselSpecificationId === vessel?.VesselSpecificationId
        )?.vesselName,
        isActive: vessel?.IsActive ? "Yes" : "No",
        dateRemoved: toReadableDate(vessel?.DateRemoved),
      }));
      setDeletedList(editedList);
    }
  };
  const switchIcon = (vessel) => {
    return (
      <>
        <ReactTooltip id="trash">Switch Vessel</ReactTooltip>
        <a
          role="button"
          className="btn btn-sm btn-danger active"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Remove Vessel from fleet"
          data-tip
          data-for="trash"
          onClick={() => setSelectedRecord(vessel)}
        >
          Delete
        </a>
      </>
    );
  };
  const editCallback = async () => {
    onModalClose();
    await retrieveVessels();
    setSelectedRecord(null);
  };

  const onModalClose = () => {
    setModalIsClosed(true);
  };
  const tabHeaders = [
    { icon: "icon ni ni-view-grid-wd", text: "Vessels In Pool" },
    { icon: "icon ni ni-trash-alt", text: "Deleted Vessels' History" },
    { icon: "icon ni ni-location", text: "Map View" },
  ];
  const tabContent = [
    <VesselsInPoolsViewComponent
      currentPoolName={currentPoolName}
      dataList={vesselsList}
      callback={editCallback}
    />,
    <DeletedVesselListComponent
      currentPoolName={currentPoolName}
      dataList={deletedList}
    />,
    <MapViewTabComponent fleetId={fleetId} selectedPoolForMapList={selectedPoolForMapList} />,
    
  ];

  const tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <span>{"<< "}Back to PooL List</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <MainLayout
      title="Pool View"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <div className="">{tabView}</div>

      {selectedRecord && (
        <CustomModal
          title="Switch Vessel Pool"
          content={
            <>
              <SwitchVesselPoolComponent
                vesselPools={vesselsList}
                callback={editCallback}
                pools={pools}
                currentPoolName={currentPoolName}
                currentVessel={selectedRecord}
              />
            </>
          }
          onClose={() => setSelectedRecord(null)}
          isClosed={false}
        />
      )}
    </MainLayout>
  );
}
