import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import LocationService from "../../services/locationService";
import VoyageService from "../../services/voyageService";
import ReactTooltip from "react-tooltip";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import SailingService from "../../services/sailingService";
import VesselService from "../../services/vesselService";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import TankListComponent from "../../components/voyage/tankList";
import BulkListComponent from "../../components/voyage/bulkList";
import DeckListComponent from "../../components/voyage/deckList";
import { forEach } from "lodash";

export default function CreateVoyagePage() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [availableBookings, setAvailableBookings] = useState([]);
  const [saving, setSaving] = useState(false);
  const [myEventsList, setMyEventsList] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const localizer = momentLocalizer(moment);
  const [schedules, setSchedules] = useState([]);
  const [startDate, setStartDate] = useState(new Date().toLocaleDateString());
  const [endDate, setEndDate] = useState(new Date().toLocaleDateString());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsClosed, setModalIsClosed] = useState(false);
  const [selectedSailingId, setSelectedSailingId] = useState("");
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedSailingDetails, setSelectedSailingDetails] = useState("");
  const [vessels, setVessels] = useState([]);
  const [message, setMessage] = useState(null);
  const [bulkCargoMessage, setBulkCargoMessage] = useState(null);
  const [deckCargoMessage, setDeckCargoMessage] = useState(null);
  const [selectedVesselSpecificationId, setSelectedVesselSpecificationId] =
    useState("");
  const [bookingList, setBookingList] = useState(null);
  const [availableTanks, setAvailableTanks] = useState([]);
  const [measurementUnit, setMeasurementUnit] = useState([]);
  const [locations, setLocations] = useState([]);
  const [portLocations, setPortLocations] = useState([]);
  const [portTerminalLocations, setPortTerminalLocations] = useState([]);
  const [bookingIds, setBookingIds] = useState([]);
  const [bulkCargo, setBulkCargo] = useState([]);
  const [deckCargo, setDeckCargo] = useState([]);
  const [bulkCargoId, setBulkCargoId] = useState([]);
  const [deckCargoId, setDeckCargoId] = useState([]);
  const [tab, setTab] = useState(1);
  const [selectedBulkCargo, setSelectedBulkCargo] = useState([]);
  const [selectedDeckCargo, setSelectedDeckCargo] = useState([]);
  const [createdVoyage, setCreatedVoyage] = useState([]);
  const [confirmPopup, setConfirmPopup] = useState(null);

  const [postVoyageDetails, setPostVoyageDetails] = useState({
    vesselSpecificationId: "",
    sailingId: "",
    departurePort: "",
    arrivalPort: "",
    expectedDateOfDeparture: "",
    expectedDateOfArrival: "",
    BulkCargos: [],
    DeckCargos: [],
  });

  const addPayload = new PayloadUpdater({
    payload: postVoyageDetails,
    setPayload: setPostVoyageDetails,
  }).update;
  const validationGroup = "createVoyage";

  useEffect(async () => {
    setLoading(true);
    await init();
    await retrieveSchedules();
    // await retrieveBookings();
    await retrieveLocations();
    await retrieveMeasurementUnits();
    // await retrieveTanks();
    setLoading(false);
  }, []);

  const init = async () => {
    let _activeVessels = await VesselService.getActiveVessels();
    if (_activeVessels) {
      _activeVessels = _activeVessels.map((c) => ({
        ...c,
        label: c.VesselName,
        value: c.VesselSpecificationId,
      }));
      setVessels(_activeVessels || []);
    }
  };

  const retrieveBookings = async (sailingId) => {
    let _availableBookings = await BookingRequestService.getAvailableBookings();
    if (_availableBookings) {
      _availableBookings = _availableBookings.map((c) => ({
        ...c,
        label: c.BookingReferenceNumber,
        value: c.BookingRequestId,
      }));
    }
    setAvailableBookings(
      _availableBookings.filter((booking) => booking.SailingId == sailingId)
    );
  };

  const retrieveMeasurementUnits = async () => {
    let _units = await EquipmentService.listMeasurementUnits();
    if (_units) {
      _units = _units.find((c) => c.MeasurementUnit == "liters");
    }
    setMeasurementUnit(_units);
  };

  const retrieveTanks = async (vesselSpecificationId) => {
    let _tanks = await VesselService.listTanks(vesselSpecificationId);
    let tankList = _tanks.map((l) => ({
      ...l,
      literMeasure: `${l.CapacityMeasure}${l.unit}`,
    }));
    setAvailableTanks(tankList || []);
  };

  const retrieveLocations = async () => {
    let _locations = await LocationService.list(1, 1000);
    let _portTerminalLocations = _locations?.filter(
      (l) =>
        l.LocationTypeName.toLowerCase() == "port" ||
        l.LocationTypeName.toLowerCase() == "terminal"
    );
    let _portLocations = _locations?.filter(
      (l) => l.LocationTypeName.toLowerCase() == "port"
    );
    setLocations(_locations || []);
    setPortLocations(_portLocations || []);
    setPortTerminalLocations(_portTerminalLocations || []);
  };

  const retrieveSchedules = async () => {
    // const schedulesInfo = await SailingService.getSailingSchedule();
    const schedulesInfo = await SailingService.getUnlockedSailings(1, 500);
    const _schedules = schedulesInfo?.Data;
    // const _schedules = await SailingService.getSchedule(startDate, endDate);
    if (!_schedules || _schedules == null) setSchedules([]);
    else {
      setSchedules(_schedules);
      setMyEventsList(
        _schedules.map((schedule) => ({
          ...schedule,
          title: "Sailing " + schedule.SailingNo,
          start: schedule.DayOfSailing,
          end: schedule.DayOfReturnInPort,
        }))
      );
    }
  };

  const handleSelect = (e) => {
    setSelectedSailingId(e.SailingId);
    setSelectedSchedule(e);
    setModalIsOpen(true);
    sailing(e.SailingId);
    setButtonClicked(false);
    retrieveBookings(e.SailingId);

    // if (selectedSailingId != null){
    //     sailing(selectedSailingId);
    // }
  };

  const MyCalendar = (props) => (
    <>
      {myEventsList && (
        <div>
          <Calendar
            localizer={localizer}
            events={myEventsList}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 480 }}
            onSelectEvent={(event) => handleSelect(event)}
            onSelectSlot={(e) => handleSelect()}
          />
        </div>
      )}
    </>
  );

  const displayCalendar = () => {
    // myEventsList && <MyCalendar></MyCalendar>
    return <div>{myEventsList && <MyCalendar></MyCalendar>}</div>;
  };

  const displayToggle = (prop) => {
    // e.preventDefault();
    return (
      <>
        {myEventsList && (
          <div>
            <CustomModal
              title={"Select preferred Sailing"}
              content={displayCalendar()}
              onClose={() => setButtonClicked(false)}
              //   isClosed={() => setModalIsClosed(false)}
            />
          </div>
        )}
      </>
    );
  };

  const handleClick = () => {
    if (!buttonClicked) {
    }
    setButtonClicked(true);
  };

  const closeModal = () => {
    setModalIsClosed(true);
  };

  const sailing = async (sailingId) => {
    const specificSailing = await SailingService.getSailingLocationBySailing(
      sailingId
    );
    setSelectedSailingDetails(specificSailing); //SailingId
  };

  let selectedSailing = myEventsList?.find(
    (sortedSailing) => sortedSailing.SailingId === selectedSailingId
  );

  const vesselValidation = async (option) => {
    let _bookingIds = option?.map(function (booking) {
      return booking.BookingRequestId;
    });

    let _body = {
      vesselId: postVoyageDetails?.vesselSpecificationId,
      bookingRequestIds: _bookingIds || null,
    };

    const response = await VesselService.validateVessel(_body);

    if (
      _body.vesselId !== null &&
      _body.bookingRequestIds !== null &&
      response?.deckCargoCheck == true &&
      response?.bulkCargoCheck == true
    ) {
      setMessage(
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Booking request added
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      );
    } else {
      setMessage(
        <div
          className="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          Booking request not added
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      );
    }
  };

  const handleBookingChange = (option) => {
    let _bookingId = option?.map(function (booking) {
      return booking.BookingRequestId;
    });
    setBookingIds(_bookingId || []);

    let _bulkCargo = option
      ?.map(function (bulkcargo) {
        return bulkcargo?.cargos;
      })
      ?.flat();
    setBulkCargo(_bulkCargo || []);

    let _deckCargo = option
      ?.map(function (deckcargo) {
        return deckcargo?.deckCargos;
      })
      ?.flat();
    setDeckCargo(_deckCargo || []);
  };

  const handleBulkTypeChange = (option) => {
    let _bulkCargoId = option?.map(function (a) {
      return a.BulkCargoDetailId;
    });
    setBulkCargoId(_bulkCargoId || []);

    let _selectedBulkCargo = option?.map(function (bulkselected) {
      return bulkselected;
    });
    setSelectedBulkCargo(_selectedBulkCargo || []);
  };

  const handleDeckTypeChange = (option) => {
    let _deckCargoId = option?.map(function (a) {
      return a.DeckCargoDetailId;
    });
    setDeckCargoId(_deckCargoId || []);

    let _selectedDeckCargo = option?.map(function (deckselected) {
      return deckselected;
    });
    setSelectedDeckCargo(_selectedDeckCargo || []);
  };

  const vesselBulkCargoValidation = async () => {
    let _body = {
      vesselId: postVoyageDetails?.vesselSpecificationId,
    };

    const response = await VesselService.validateCargoHoldingCapabilities(
      _body
    );

    if (
      _body.vesselId !== null &&
      response?.deckCargoCheck == true &&
      response?.bulkCargoCheck == true
    ) {
      setBulkCargoMessage(
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Cargo will fit on vessel
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      );
    } else {
      setBulkCargoMessage(
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          Cargo will not on vessel
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      );
    }
  };
  const vesselDeckCargoValidation = async () => {
    let _body = {
      vesselId: postVoyageDetails?.vesselSpecificationId,
    };

    const response = await VesselService.validateCargoHoldingCapabilities(
      _body
    );

    if (
      _body.vesselId !== null &&
      response?.deckCargoCheck == true &&
      response?.bulkCargoCheck == true
    ) {
      setDeckCargoMessage(
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Cargo will fit on vessel
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      );
    } else {
      setDeckCargoMessage(
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          Cargo will not on vessel
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      );
    }
  };

  const statusOptions = [
    { label: "Planned", value: "Planned" },
    { label: "Active", value: "Active" },
    { label: "Completed", value: "Completed" },
    { label: "Finalised", value: "Finalised" },
    { label: "Invoiced", value: "Invoiced" },
  ];

  postVoyageDetails.sailingId = selectedSailing?.SailingId || "";
  postVoyageDetails.expectedDateOfDeparture = selectedSailing?.start || "";
  postVoyageDetails.expectedDateOfArrival = selectedSailing?.end || "";
  postVoyageDetails.DeckCargos = deckCargoId;
  postVoyageDetails.BulkCargos = bulkCargoId;

  const toNextTab = () => {
    if (!validateTab()) return;

    let nextTab = tab + 1;
    if (nextTab > 2) nextTab = 2;
    if (nextTab === 2) {
    }

    setTab(nextTab);
    window.scrollTo(0, 0);
  };

  const toPreviousTab = () => {
    let prevTab = tab - 1;
    if (prevTab < 1) prevTab = 1;
    setTab(prevTab);
    window.scrollTo(0, 0);
  };

  const validateTab = (_tab) => {
    _tab = _tab || tab;

    if (_tab == 1) {
      if (!postVoyageDetails.vesselSpecificationId) {
        toastr("error", "Select vessel");
        return;
      }

      if (!postVoyageDetails.sailingId) {
        toastr("error", "Select sailing");
        return;
      }

      if (!postVoyageDetails.arrivalPort) {
        toastr("error", "Select port ending");
        return;
      }

      if (!postVoyageDetails.departurePort) {
        toastr("error", "Select calling port or terminal");
        return;
      }

      if (bookingIds.length == 0) {
        toastr("error", "Select at least one booking reference");
        return;
      }
    } else if (_tab == 2) {
      if (
        postVoyageDetails.BulkCargos.length == 0 &&
        postVoyageDetails.DeckCargos.length == 0
      ) {
        toastr("error", "Select at least one cargo item");
        return;
      }
    }

    return true;
  };

  const saveEntireForm = async () => {
    setSaving(true);
    if (!validateTab()) return;
    // if (loading) return;
    const response = await VoyageService.postVoyage(postVoyageDetails);
    if (response && response.Success == true) {
      setCreatedVoyage(response?.Data?.voyage);
      setPostVoyageDetails({});
      toastr("success", "Voyage created");
    } else if (response && response.data.Success == false) {
      toastr("error", `${response.data.Message[0]}`);
      setSaving(false);
      return;
    }
    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Create Voyage"
      loading={loading}
      showFullLoader={loading}
    >
      <ul className="nav nav-tabs mt-n3 ml-3">
        <li className="nav-item">
          <a
            onClick={() => setTab(1)}
            className={`nav-link ${tab == 1 && "active"}`}
          >
            <em className="icon ni ni-view-grid-wd" />
            <span> Create Voyage</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            onClick={() => validateTab(1) && setTab(2)}
            className={`nav-link ${tab == 2 && "active"}`}
          >
            <em className="icon ni ni-list-check" />
            <span> Manage Cargo</span>
          </a>
        </li>
      </ul>
      <form>
        {tab === 1 && (
          <div className="card-inner">
            <div>
              <div className="row">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Sailing Details
                    </span>{" "}
                  </p>
                </div>
                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "40" }}>
                    <label className="form-label small">
                      Vessel{" "}
                      <span
                        style={{
                          fontStyle: "italic",
                        }}
                      >
                        (Only active vessels are viewable)
                      </span>
                    </label>
                    <div className="form-control-wrap">
                      {/* <input type="text" className="form-control required" required /> */}
                      <Select
                        value={vessels.find(
                          (a) =>
                            a.value == postVoyageDetails?.vesselSpecificationId
                        )}
                        options={vessels}
                        onChange={(e) => {
                          addPayload("vesselSpecificationId", e.value);
                          retrieveTanks(e.value);
                          //   vesselValidation();
                          //   handleVesselChange(e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    className="form-group"
                    // style={{ zIndex: "1000" }}
                  >
                    <label className="form-label small">Sailing</label>
                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-right">
                        <em
                          className="icon ni ni-calendar-alt"
                          onClick={handleClick}
                        />
                      </div>
                      <input
                        type="button"
                        onClick={handleClick}
                        value={selectedSailing?.title}
                        className="form-control"
                      />
                    </div>
                    {buttonClicked ? displayToggle() : ""}
                  </div>
                </div>
              </div>
              <br />

              {!loading && (
                <div>
                  {!postVoyageDetails.vesselSpecificationId ? null : (
                    <div className="row">
                      <div className="col-12">
                        <p>
                          <span className="preview-title-lg overline-title">
                            Tank Details
                          </span>{" "}
                        </p>
                      </div>
                      <TankListComponent dataList={availableTanks} />
                      <br />
                    </div>
                  )}
                </div>
              )}
              <br />

              <div className="row">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Booking Information
                    </span>{" "}
                  </p>
                </div>
              </div>

              {postVoyageDetails.sailingId !== "" &&
              availableBookings.length == 0 ? (
                <div
                  className="alert alert-warning alert-dismissible fade show"
                  role="alert"
                >
                  No booking requests match the selected sailing
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              ) : null}

              <div className="row">
                <div className="col-md-8">
                  <div className="form-group" style={{ zIndex: "30" }}>
                    <label className="form-label small">
                      Booking Reference Number(s)
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        onChange={(option) => {
                          handleBookingChange(option);
                          vesselValidation(option);
                          // handleBulkDeckTypeId();
                          // addPayload("bookingRequestIds", (option));
                        }}
                        isMulti={true}
                        value={availableBookings
                          ?.map((c) => ({
                            ...c,
                            label: c.BookingReferenceNumber,
                            value: c.BookingRequestId,
                          }))
                          ?.filter((obj) => bookingIds.includes(obj.value))}
                        options={availableBookings?.map((c) => ({
                          ...c,
                          label: c.BookingReferenceNumber,
                          value: c.BookingRequestId,
                        }))}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              {!bookingIds ? null : <div>{message}</div>}
              <br />

              <div className="row">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Loading/Discharge Locations
                    </span>{" "}
                  </p>
                </div>
                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "10" }}>
                    <label className="form-label small">
                      Calling Port/ Terminal
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        value={portTerminalLocations
                          .map((c) => ({
                            ...c,
                            label: c.LocationName,
                            value: c.LocationId,
                          }))
                          .find(
                            (a) => a.value == postVoyageDetails?.departurePort
                          )}
                        options={portTerminalLocations.map((c) => ({
                          ...c,
                          label: c.LocationName,
                          value: c.LocationId,
                        }))}
                        onChange={(e) => {
                          addPayload("departurePort", e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "10" }}>
                    <label className="form-label small">Port Ending</label>
                    <div className="form-control-wrap">
                      <Select
                        defaultValue={postVoyageDetails.arrivalPort}
                        value={portLocations
                          .map((c) => ({
                            ...c,
                            label: c.LocationName,
                            value: c.LocationId,
                          }))
                          .find(
                            (a) => a.value == postVoyageDetails?.arrivalPort
                          )}
                        options={portLocations.map((c) => ({
                          ...c,
                          label: c.LocationName,
                          value: c.LocationId,
                        }))}
                        onChange={(e) => {
                          addPayload("arrivalPort", e.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-12">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Departure/Arrival Time
                    </span>{" "}
                  </p>
                </div>
                <div className="col-md-4">
                  <div className="form-group ">
                    <label className="form-label small">
                      Expected Time of Departure
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control required"
                        required
                        value={toReadableDate(selectedSailing?.DayOfSailing)}
                        onChange={(e) =>
                          addPayload("expectedDateOfDeparture", e.value)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group" style={{ zIndex: "9" }}>
                    <label className="form-label small">
                      Expected Time of Arrival
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control required"
                        required
                        value={toReadableDate(
                          selectedSailing?.DayOfReturnInPort
                        )}
                        onChange={(e) =>
                          addPayload("expectedDateOfArrival", e.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {tab === 2 && (
          <div className="card-inner">
            <div className="row">
              <div className="col-12">
                <p>
                  <span className="preview-title-lg overline-title">
                    Bulk Cargo Selection
                  </span>{" "}
                </p>
              </div>

              <div className="col-md-8">
                <div className="form-group" style={{ zIndex: "30" }}>
                  <label className="form-label small">Bulk Cargo(s)</label>
                  <div className="form-control-wrap">
                    <Select
                      onChange={(option) => {
                        handleBulkTypeChange(option);
                        vesselBulkCargoValidation();
                      }}
                      isMulti={true}
                      value={bulkCargo
                        ?.map((c) => ({
                          ...c,
                          label: c.BookingReference + " - " + c.ProductName,
                          value: c.BulkCargoDetailId,
                        }))
                        ?.filter((obj) => bulkCargoId.includes(obj.value))}
                      options={bulkCargo?.map((c) => ({
                        ...c,
                        label: c.BookingReference + " - " + c.ProductName,
                        value: c.BulkCargoDetailId,
                      }))}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />

            {postVoyageDetails.BulkCargos.length == 0 ? null : (
              <div>{bulkCargoMessage}</div>
            )}
            <br />

            {!loading && (
              <div>
                {postVoyageDetails.BulkCargos.length == 0 ? null : (
                  <div className="row">
                    <div className="col-12">
                      <p>
                        <span className="preview-title-lg overline-title">
                          Bulk Cargo Details
                        </span>{" "}
                      </p>
                    </div>
                    <BulkListComponent dataList={selectedBulkCargo} />
                  </div>
                )}
              </div>
            )}
            <br />

            <div className="row">
              <div className="col-12">
                <p>
                  <span className="preview-title-lg overline-title">
                    Deck Cargo Selection
                  </span>{" "}
                </p>
              </div>

              <div className="col-md-8">
                <div className="form-group" style={{ zIndex: "20" }}>
                  <label className="form-label small">Deck Cargo(s)</label>
                  <div className="form-control-wrap">
                    <Select
                      onChange={(option) => {
                        handleDeckTypeChange(option);
                        vesselDeckCargoValidation();
                      }}
                      isMulti={true}
                      value={deckCargo
                        ?.map((c) => ({
                          ...c,
                          label:
                            c.BookingReference + " - " + c.DescriptionOfGoods,
                          value: c.DeckCargoDetailId,
                        }))
                        ?.filter((obj) => deckCargoId.includes(obj.value))}
                      options={deckCargo?.map((c) => ({
                        ...c,
                        label:
                          c.BookingReference + " - " + c.DescriptionOfGoods,
                        value: c.DeckCargoDetailId,
                      }))}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />

            {postVoyageDetails.DeckCargos.length == 0 ? null : (
              <div>{deckCargoMessage}</div>
            )}
            <br />

            {!loading && (
              <div>
                {postVoyageDetails.DeckCargos.length == 0 ? null : (
                  <div className="row">
                    <div className="col-12">
                      <p>
                        <span className="preview-title-lg overline-title">
                          Deck Cargo Details
                        </span>{" "}
                      </p>
                    </div>
                    <DeckListComponent dataList={selectedDeckCargo} />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </form>
      <div className="row">
        <div className="col mb-4 pl-5 mt-5">
          {tab > 1 && (
            <button
              type="button"
              onClick={() => toPreviousTab()}
              className="btn btn-md btn-primary"
            >
              {"<"} Previous
            </button>
          )}
        </div>
        <div className="col text-right mb-4 pr-5 mt-5">
          {tab < 2 && (
            <button
              type="button"
              id="saveVessel"
              onClick={() => toNextTab()}
              className="btn btn-md btn-primary"
            >
              Next {">"}
            </button>
          )}
          {tab == 2 && (
            <>
              {saving ? (
                <button type="button" className="btn btn-lg btn-light btn-link">
                  <Spinner size="1.5" />{" "}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={async () => {
                    await saveEntireForm();
                    setConfirmPopup(createdVoyage);
                  }}
                  className="btn btn-lg btn-dark"
                >
                  <em className="icon ni ni-save mr-2" />
                  Save Voyage{" "}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <hr className="preview-hr" style={{ visibility: "hidden" }} />
      {confirmPopup && createdVoyage && (
        <CustomModal
          title={
            <span>
              <em class="icon ni ni-info-i"></em> Create New Activity
            </span>
          }
          content={
            <>
              <div className="">
                <div className="text-center">
                  <h5>Voyage created successfully!!</h5>
                  <div className="gap gap-10px" />
                  <h5>
                    Click "Continue" to create a voyage activity
                    <br />
                    or close to return to voyages
                  </h5>
                </div>
                <br />
                <div className="row my-1 p-1">
                  <div className="col-12 text-center">
                    <a
                      href={`/add-activity/${createdVoyage?.VoyagePlanningId}`}
                      className="btn btn-lg btn-dark active"
                      onclick="return false"
                    >
                      Continue
                    </a>
                  </div>
                </div>
              </div>
            </>
          }
          onClose={() => setRedirectUrl("/voyages")}
          isClosed={false}
        />
      )}
    </MainLayout>
  );
}
