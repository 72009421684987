import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import LocationService from "../../services/locationService";
import FleetService from "../../services/fleetService";
import UserService from "../../services/userService";
import ReactTooltip from "react-tooltip";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import VesselService from "../../services/vesselService";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import { dateToYYYY_MM_DD } from "../../utils/utilityFunctions";

export default function AddPoolComponent({ callback }) {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [vessels, setVessels] = useState([]);
  const [zones, setZones] = useState([]);
  const [tab, setTab] = useState(1);
  const [userList, setUserList] = useState([]);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "addpool";

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);

    let _locations = await LocationService.allLocations();
    if (_locations) {
      let _zones = _locations?.filter(
        (location) => location.LocationTypeName.toLowerCase() == "zone"
      );
      setZones(_zones || []);
    }
    let listOfUsers = await UserService.getUserByRoleName("Pool Manager");
    listOfUsers = listOfUsers?.map((list) => ({
      id: list.Id,
      name: `${list.FirstName} ${list.LastName}`,
    }));
    setUserList(listOfUsers);
    setLoading(false);
  };
  const submitForm = async () => {
    const _payload = { ...payload };

    if (!_payload.fleetName) {
      toastr("error", "Enter pool name");
      return;
    }

    if (!_payload.locationId) {
      toastr("error", "Select pool zone");
      return;
    }

    setSaving(true);

    const response = await FleetService.addPool(_payload);
    if (response) {
      setPayload({});
      toastr("success", "Pool added successfully");
      callback && callback();
    }
    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <form className="p-2">
      {tab === 1 && (
        <div className="card-inner">
          <div>
            <div className="row">
              <div className="col">
                <div className="form-group ">
                  <label className="form-label small">Pool Name</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control required"
                      required
                      onChange={(e) => addPayload("fleetName", e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <div className="form-group" style={{ zIndex: "10" }}>
                  <label className="form-label small">Zone Name</label>
                  <div className="form-control-wrap">
                    <Select
                      value={zones
                        .map((c) => ({
                          ...c,
                          label: c.LocationName,
                          value: c.LocationId,
                        }))
                        .find((a) => a.value == payload?.locationId)}
                      options={zones.map((c) => ({
                        ...c,
                        label: c.LocationName,
                        value: c.LocationId,
                      }))}
                      onChange={(e) => {
                        addPayload("locationId", e.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label className="form-label small">Pool Manager</label>
                  <div className="form-control-wrap">
                    <Select
                      value={userList
                        .map((c) => ({
                          ...c,
                          label: c.name,
                          value: c.id,
                        }))
                        .find((a) => a.value === payload?.poolManagerUserId)}
                      options={userList.map((c) => ({
                        ...c,
                        label: c.name,
                        value: c.id,
                      }))}
                      onChange={(e) => {
                        addPayload("poolManagerUserId", e.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {saving && (
        <div className="form-group p-2 m-3">
          <button type="button" className="btn btn-md btn-link m-1">
            <Spinner size="1.5" />
          </button>
        </div>
      )}
      {!saving && (
        <div className="form-group p-2 m-3">
          <button
            type="button"
            className="btn btn-md btn-dark btn-wide px-5 mr-3"
            onClick={() => submitForm()}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-sm btn-link m-1"
            onClick={() => window.history.back()}
          >
            Cancel
          </button>
        </div>
      )}
    </form>
  );
}
