import React, { Component, useEffect, useState } from "react";
import ReactModal from "react-modal";
import apiCall from "../../utils/apiCall";
import Spinner from "../../utils/spinner";
import { BrowserRouter } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import CountryService from "../../services/countriesService";
import ProvinceService from "../../services/provinceService";
import PartyService from "../../services/partyService";
import payloadUpdater from "../../utils/payloadUpdater";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import { defaultModalStyle } from "../../utils/customStyles";
import Select from "react-select";
import ContactPersonService from "../../services/contactPersonService";
import { numberOnly } from "../../components/validator";

export default function PartyModal({
  callback,
  partyTypeId,
  prefilledPartyName,
  addNewLabel,
}) {
  const [showModal, setShowModal] = useState(false);
  const [countryList, setCountry] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partyTypeList, setPartyType] = useState([{}]);
  const [countryParty, setCountryParty] = useState();
  const [provinces, setProvinces] = useState({});
  const [postPartyData, setPostPartyData] = useState({
    partyName: prefilledPartyName || "",
    addressLine1: "",
    addressLine2: "address",
    regionId: "",
    countryId: "",
  });

  const [contactPersonPayload, setContactPersonPayload] = useState({});
  const [contactPersonRoles, setContactPersonRoles] = useState([]);
  const [selectedContactPersonRole, setSelectedContactPersonRole] = useState(
    {}
  );

  const updatePayload = new payloadUpdater({
    payload: postPartyData,
    setPayload: setPostPartyData,
  }).update;
  const addContactPersonPayload = new payloadUpdater({
    payload: contactPersonPayload,
    setPayload: setContactPersonPayload,
  }).update;

  const validationGroup = "partyModal";

  useEffect(async () => {
    const party_Types = await PartyService.listPartyTypes();
    if (party_Types) {
      setPartyType(party_Types);
    }

    const _contactPersonRoles = await ContactPersonService.listAllRoles();
    setContactPersonRoles(_contactPersonRoles);

    const _countryList = await CountryService.listCountry();
    setCountry(_countryList);

    if (partyTypeId) {
      updatePayload("partyTypeId", partyTypeId);
    }

    if (prefilledPartyName) {
      updatePayload("partyName", prefilledPartyName);
    }

    return () => {
      ClearValidator(validationGroup);
    };
  }, []);

  const savePartyForm = async () => {
    if (loading) return;
    // const validatorStatus = ValidateGroup(validationGroup);
    // if (
    //   !validatorStatus ||
    //   !postPartyData.countryId ||
    //   !postPartyData.regionId
    // ) {
    //   toastr("error", "Please fill the form correctly");
    //   return;
    // }

    if (!postPartyData.partyName) {
      toastr("error", "Enter party name");
      return;
    }

    if (!postPartyData.partyTypeId) {
      toastr("error", "Select party type");
      return;
    }

    if (
      // !validatorStatus ||
      !postPartyData.countryId ||
      !postPartyData.regionId
    ) {
      toastr("error", "Select Country");
      return;
    }

    if (!postPartyData.city) {
      toastr("error", "Enter city");
      return;
    }

    if (!contactPersonPayload.firstName) {
      toastr("error", "Enter contact's firstname");
      return;
    }

    if (!contactPersonPayload.lastName) {
      toastr("error", "Enter contact's lastname");
      return;
    }

    if (!contactPersonPayload.roleId) {
      toastr("error", "Select contact's role");
      return;
    }

    if (!contactPersonPayload.emailAddress) {
      toastr("error", "Enter contact's email");
      return;
    }

    if (!contactPersonPayload.phoneNumber) {
      toastr("error", "Enter contact's phone number");
      return;
    }

    if (prefilledPartyName) {
      postPartyData.partyName = prefilledPartyName;
    }

    const selectedCountry = countryList.find(
      (a) => a.CountryId == postPartyData.countryId
    );
    const selectedState = provinces[postPartyData.countryId].find(
      (a) => a.RegionId == postPartyData.regionId
    );

    postPartyData.addressLine2 = `${selectedState?.RegionName} ${selectedCountry?.CountryName}`;
    if (partyTypeId) {
      postPartyData.partyTypeId = partyTypeId;
    }

    setLoading(true);
    const response = await PartyService.postParty(postPartyData);
    if (response) {
      let _cpPayload = {
        ...contactPersonPayload,
        partyId: response.PartyId || response.partyId,
      };
      const contactPersonSaved = await ContactPersonService.postContactPerson(
        _cpPayload
      );

      const _partyData = {
        party: { ...response },
        contactPerson: {
          ...contactPersonSaved,
        },
      };
      ClearValidator(validationGroup);
      setPostPartyData({});
      setContactPersonPayload({});

      toastr("success", "New party record added");
      if (callback) callback(_partyData);
      handleCloseModal();
    }
    setLoading(false);
  };

  const retrieveProvince = async (countryId) => {
    if (!countryId) return;
    if (provinces[countryId]) return; // we have fetched provinces for this country before

    const _provinces = await ProvinceService.listProvincesByCountry(countryId);
    const __provinces = { ...provinces };
    __provinces[countryId] = _provinces;

    console.log({ __provinces });
    setProvinces(__provinces);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setPostPartyData({});
    setShowModal(false);
  };

  return (
    <>
      <button
        onClick={() => handleOpenModal()}
        type="button"
        className={`openModalBtn btn btn-md ${
          addNewLabel ? "btn-dim btn-secondary" : "btn-dark"
        } mt-4`}
      >
        {addNewLabel || "+ Add new Party?"}
      </button>
      <ReactModal
        isOpen={showModal}
        contentLabel="Minimal Modal Example"
        style={(defaultModalStyle, { overlay: { zIndex: "1200" } })}
      >
        <MainLayout
          preTitle={
            <span className="ml-2">
              <em
                className="icon ni ni-arrow-left pointer"
                onClick={() => handleCloseModal()}
              />
              <span className="pointer" onClick={() => handleCloseModal()}>
                Return
              </span>
              <h4 className="ml-2">Add a party</h4>
            </span>
          }
        >
          <div className="card  col">
            <form className=" nk-wizard-simple is-alter p-3">
              <div className="nk-wizard-head">
                {/* There should be something here */}
              </div>
              <div className="nk-wizard-content">
                <div className="row">
                  <div className="col-sm-6  mb-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="partyName">
                        Party name
                      </label>
                      <small className="text-danger ml-1">*</small>
                      <div className="form-control-wrap">
                        <input
                          tabIndex={0}
                          defaultValue={prefilledPartyName}
                          type="text"
                          onChange={(e) =>
                            updatePayload("partyName", e.target.value)
                          }
                          className="form-control required"
                          id="partyName"
                          disabled={prefilledPartyName ? true : false}
                        />
                        {!prefilledPartyName && (
                          <Validator
                            data={postPartyData.partyName}
                            errorMessage="Enter party name"
                            groupName={validationGroup}
                            dataKey={"partyName"}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {!partyTypeId && (
                    <div className="col-sm-6">
                      <div className="form-group  mb-3" style={{ zIndex: 10 }}>
                        <label className="form-label" htmlFor="partyType">
                          Party type
                        </label>
                        <small className="text-danger ml-1">*</small>
                        <div className="form-control-wrap">
                          <Select
                            options={partyTypeList.map((e) => ({
                              ...e,
                              label: e.PartyType,
                              value: e.PartyTypeId,
                            }))}
                            value={partyTypeList
                              .map((e) => ({
                                ...e,
                                label: e.PartyType,
                                value: e.PartyTypeId,
                              }))
                              .find(
                                (a) => a.value == postPartyData.partyTypeId
                              )}
                            onChange={(option) =>
                              updatePayload("partyTypeId", option.value)
                            }
                          />
                          {/* <Validator data={postPartyData.partyType} errorMessage="select party type" groupName={validationGroup} dataKey={"partyType"} /> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group  mb-3">
                      <label className="form-label" htmlFor="phoneNumber">
                        Address Line 1
                      </label>
                      <div className="form-control-wrap">
                        <textarea
                          type="text"
                          onChange={(e) =>
                            updatePayload("addressLine1", e.target.value)
                          }
                          className="form-control"
                          id="addressLine1"
                          name="fw-last-name"
                        />
                        {/* <Validator
                          data={postPartyData.phoneNumber}
                          dataType="number"
                          errorMessage="Please fill phone number"
                          groupName={validationGroup}
                          dataKey={"phoneNumber"}
                        /> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group  mb-3">
                      <label className="form-label">Address Line 2</label>
                      <div className="form-control-wrap">
                        <textarea
                          type="text"
                          onChange={(e) =>
                            updatePayload("addressLine2", e.target.value)
                          }
                          className="form-control"
                          id="addressLine2"
                          name="fw-first-name"
                        />
                        {/* <Validator
                              data={postPartyData.emailAddress}
                              dataType="email"
                              groupName={validationGroup}
                              dataKey={"emailAddress"}
                            /> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group  mb-3" style={{ zIndex: 9 }}>
                      <label className="form-label" htmlFor="country">
                        Country
                      </label>
                      <small className="text-danger ml-1">*</small>
                      <div className="form-control-wrap">
                        <Select
                          options={countryList.map((e) => ({
                            ...e,
                            label: e.CountryName,
                            value: e.CountryId,
                          }))}
                          value={countryList
                            .map((e) => ({
                              ...e,
                              label: e.CountryName,
                              value: e.CountryId,
                            }))
                            .find((a) => a.value == postPartyData.countryId)}
                          onChange={(option) => {
                            updatePayload("countryId", option.value);
                            retrieveProvince(option.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group  mb-3" style={{ zIndex: 9 }}>
                      <label className="form-label">State/Province</label>
                      <small className="text-danger ml-1">*</small>
                      <div className="form-control-wrap">
                        <Select
                          isDisabled={!postPartyData.countryId}
                          options={
                            postPartyData.countryId &&
                            provinces[postPartyData.countryId] &&
                            provinces[postPartyData.countryId].map((e) => ({
                              ...e,
                              label: e.RegionName,
                              value: e.RegionId,
                            }))
                          }
                          value={(
                            (postPartyData.countryId &&
                              provinces[postPartyData.countryId] &&
                              provinces[postPartyData.countryId].map((e) => ({
                                ...e,
                                label: e.RegionName,
                                value: e.RegionId,
                              }))) ||
                            []
                          ).find((a) => a.value == postPartyData.regionId)}
                          onChange={(option) => {
                            updatePayload("regionId", option.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">City</label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          onChange={(e) =>
                            updatePayload("city", e.target.value)
                          }
                          className="form-control required"
                          id="city"
                          name="city"
                        />
                        {/* <Validator
                            data={postPartyData.addressLine1}
                            errorMessage="Supply address"
                            groupName={validationGroup}
                            dataKey={"addressLine1"}
                          /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gap"></div>
              <div className="gap gap-20px" />
              <div className="row">
                <span className="col preview-title-lg overline-title">
                  Contact Person Information{" "}
                </span>
                <div className="gap gap-20px" />
                <div className="col-sm-6">
                  <div className="form-group mb-3">
                    <label className="form-label">First name</label>{" "}
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control required"
                        defaultValue={contactPersonPayload.firstName}
                        onChange={(e) =>
                          addContactPersonPayload("firstName", e.target.value)
                        }
                        required
                      />
                      <Validator
                        data={contactPersonPayload.firstName}
                        errorMessage="Enter contact first name"
                        groupName={validationGroup}
                        dataKey={"firstName"}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group  mb-3">
                    <label className="form-label">Last name</label>{" "}
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control required"
                        defaultValue={contactPersonPayload.lastName}
                        onChange={(e) =>
                          addContactPersonPayload("lastName", e.target.value)
                        }
                        required
                      />
                      <Validator
                        data={contactPersonPayload.lastName}
                        errorMessage="Enter contact last name"
                        groupName={validationGroup}
                        dataKey={"lastName"}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group  mb-3" style={{ zIndex: 8 }}>
                    <label className="form-label">Contact Person Role</label>{" "}
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <Select
                        options={contactPersonRoles?.map((e) => ({
                          ...e,
                          label: e.ContactPersonRole,
                          value: e.ContactPersonRoleId,
                        }))}
                        value={selectedContactPersonRole}
                        onChange={(option) => {
                          setSelectedContactPersonRole(option);
                          addContactPersonPayload(
                            "roleId",
                            option.ContactPersonRoleId
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group  mb-3">
                    <label className="form-label">Email address</label>{" "}
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <input
                        type="email"
                        className="form-control required"
                        defaultValue={contactPersonPayload.emailAddress}
                        onChange={(e) =>
                          addContactPersonPayload(
                            "emailAddress",
                            e.target.value?.toLowerCase()
                          )
                        }
                        required
                      />
                      <Validator
                        data={contactPersonPayload.emailAddress}
                        dataType="email"
                        errorMessage="Enter contact email"
                        groupName={validationGroup}
                        dataKey={"emailAddress"}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group  mb-3">
                    <label className="form-label">Phone number</label>{" "}
                    <small className="text-danger ml-1">*</small>
                    <div className="form-control-wrap">
                      <input
                        onKeyUp={(e) => numberOnly(e)}
                        type="tel"
                        maxLength={13}
                        className="form-control required"
                        onChange={(e) =>
                          addContactPersonPayload("phoneNumber", e.target.value)
                        }
                        required
                        defaultValue={contactPersonPayload.phoneNumber}
                      />
                      <Validator
                        dataType="number"
                        data={contactPersonPayload.phoneNumber}
                        errorMessage="Enter contact phone"
                        groupName={validationGroup}
                        dataKey={"phoneNumber"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <button
                  className="btn btn-md btn-light btn-dim"
                  onClick={() => handleCloseModal()}
                >
                  {" "}
                  <em className="icon ni ni-arrow-left pointer mr-2" /> Go back
                </button>
                {loading ? (
                  <span className="float-right">
                    <Spinner size="2" />
                  </span>
                ) : (
                  <button
                    type="button"
                    onClick={() => savePartyForm()}
                    className="btn btn-md btn-dark float-right"
                  >
                    Add new party
                  </button>
                )}
              </div>
            </form>
          </div>
        </MainLayout>
      </ReactModal>
    </>
  );
}
