import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import {
  camelizeKeys,
  toReadableDate,
  toReadableDateTime,
} from "../../utils/utilityFunctions";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import RoleService from "../../services/roleService";
import UserService from "../../services/userService";
import VesselService from "../../services/vesselService";
import LocationService from "../../services/locationService";

export default function RolesListPage() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([10, 20, 50, 100, 150]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [totalRecords, setTotalRecords] = useState(10);
  const [filter, setFilter] = useState(null);
  const [vessels, setVessels] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(async () => {
    setLoading(true);

    await loadData();

    setLoading(false);
  }, []);

  const loadData = async (_page, _pageSize) => {
    setLoading(true);
    _page = _page || page;
    _pageSize = _pageSize || pageSize;

    const _roles = await RoleService.getAllRolesPaginated(_page, _pageSize);
    let modifiedDataList = camelizeKeys(_roles?.Data || []);
    setTotalRecords(_roles?.TotalCount || 0);
    modifiedDataList = modifiedDataList.map((role) => ({
      ...role,
      systemRole: role.isSystemRole == true ? "System" : "Non-system",
      creationDate: toReadableDate(role.dateCreated),
      mostRecentModifiedDate: toReadableDate(role.lastModifiedDate),
      addPermissionsButton: addPermissionsButton(role),
      removeRoleButton: removeRoleButton(role),
    }));

    setRoles(modifiedDataList);

    page != _page && setPage(_page);
    pageSize != _pageSize && setPageSize(_pageSize);

    setLoading(false);
  };

  const addPermissionsButton = (role) => {
    return (
      <>
        {role.isSystemRole == false ? (
          <a
            href={`/grant-permissions/${role.roleId}`}
            className="btn btn-sm btn-primary active"
          >
            Grant Permission(s)
          </a>
        ) : (
          <>
            <button
              className="btn btn-sm btn-primary"
              title="System generated"
              disabled
            >
              Grant Permission(s)
            </button>
          </>
        )}
      </>
    );
  };

  const removeRoleButton = (role) => {
    const roleRemoval = async () => {
      let response = await RoleService.removeRoleById(role.roleId);
      if (response) {
        toastr("success", "Role removed successfully");
        await loadData();
      } else {
        toastr("warning", "Role might have existing permissons");
        toastr(
          "error",
          "Role could not be removed. Check for existing permissions"
        );
      }
    };
    return (
      <>
        <button
          className="btn btn-sm btn-danger"
          onClick={roleRemoval}
          title={role.isSystemRole ? "System generated" : "Remove role"}
          disabled={role.isSystemRole}
        >
          Remove
        </button>
      </>
    );
  };

  const headerList = [
    {
      Header: "Role Name",
      accessor: "name",
    },
    {
      Header: "Role Type",
      accessor: "systemRole",
    },
    {
      Header: "Date Created",
      accessor: "creationDate",
    },
    {
      Header: "Last Modified",
      accessor: "mostRecentModifiedDate",
    },
    {
      Header: "Permissions",
      accessor: "addPermissionsButton",
    },
    {
      Header: "Role Removal",
      accessor: "removeRoleButton",
    },
  ];

  const exportPage = () => {
    if (!roles.length) return;
    const exportData = roles.map((role) => {
      const exportObject = {
        "First Name": role.name,
        "Last Name": role.systemRole,
        "E-mail": role.creationDate,
        "Phone Number": role.mostRecentModifiedDate,
      };
      return exportObject;
    });
    exportToCSV(exportData, "list-of-roles");
  };

  // const filterByVessel = async (vessel) => {
  //   setFilter(vessel.VesselSpecificationId);
  //   await loadData(1, null, null, null, vessel.VesselSpecificationId, null);
  // };

  // const clearFilter = async () => {
  //   setFilter(null);
  //   await loadData(1, null, null, null, null, null);
  // };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {/* <li>
            <a
              // href={`${process.env.REACT_APP_API}/api/Commercials?page=${page}&pageSize=${pageSize}`}
              // target="_blank"
              className="btn btn-dim btn-secondary disabled"
            >
              <em className="icon ni ni-download" />
              <span>Download Users</span>
            </a>
          </li>
          {roles && (
            <li>
              <button
                type="button"
                onClick={exportPage}
                className="btn btn-white btn-outline-light"
              >
                <em className="icon ni ni-download-cloud" />
                <span>Export page</span>
              </button>
            </li>
          )} */}

          <li className="nk-block-tools-opt">
            <div className="drodown">
              <a
                href="#"
                className="dropdown-toggle btn btn-icon btn-primary"
                data-toggle="dropdown"
              >
                <em className="icon ni ni-plus" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <ul className="link-list-opt no-bdr">
                  <li>
                    <Link to="/add-role">
                      <span>Add New Role</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Roles"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <div className="table-component">
        <div className="card-inner-group">
          {
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
                <div className="card-tools">
                  {/* <div className="dropdown">
                    <a
                      href="#"
                      className="btn btn-trigger btn-icon dropdown-toggle "
                      data-toggle="dropdown"
                    >
                      <em className="icon ni ni-search" />
                    </a>
                    <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
                      <form></form>
                    </div>
                  </div> */}
                </div>

                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li className="btn-toolbar-sep" />
                    <li>
                      <div className="toggle-wrap">
                        <a
                          href="#"
                          className="btn btn-icon btn-trigger toggle"
                          data-target="cardTools"
                        >
                          <em className="icon ni ni-menu-right" />
                        </a>
                        <div
                          className="toggle-content"
                          data-content="cardTools"
                        >
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <a
                                href="#"
                                className="btn btn-icon btn-trigger toggle"
                                data-target="cardTools"
                              >
                                <em className="icon ni ni-arrow-left" />
                              </a>
                            </li>

                            <li>
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="btn btn-trigger btn-icon dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  <em className="icon ni ni-setting" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    {pages.map((item, index) => (
                                      <li
                                        onClick={() => loadData(null, item)}
                                        key={index}
                                        className={`${
                                          page == item && "active"
                                        }`}
                                      >
                                        <Link to={"#"}>{item}</Link>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      {/* {loading ? (
        <div className="mt-5 pt-5 mx-auto">
          <Spinner />
        </div>
      ) : ( */}
      <>
        <BookingTable
          columns={headerList}
          data={roles}
          cssArray={["font-weight-500", "", "", "text-left", "", ""]}
          enableSearch={false}
          emptyMessage="No user record to show on page"
          disablePageSize={true}
        />
        <br />
        {roles.length !== 0 && pageSize < totalRecords ? (
          <div className="">
            <Paginate
              onChange={(p) => loadData(p)}
              currentPage={page}
              pageSize={pageSize}
              totalRecordsCount={totalRecords}
            />
          </div>
        ) : null}
      </>
      {/* )} */}
    </MainLayout>
  );
}
