import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import MyTable from "../../components/dataTable";
import Spinner from "../../utils/spinner";

import {
  currencySymbol,
  generateQueryString,
  toReadableDate,
} from "../../utils/utilityFunctions";
import PartyService from "../../services/partyService";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import toastr from "../../utils/toastr";
import ActivityService from "../../services/activityService";
import VoyageService from "../../services/voyageService";

export default function ActivityTimeSpent() {
  const navigate = useNavigate();
  const { search: queryParams } = useLocation();
  const [searchParams] = useSearchParams();
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [initializing, setInit] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [voyageList, setVoyageList] = useState([{}]);
  const [voyageLocation, setVoyageLocation] = useState([{}]);
  const [filterObject, setFilterObject] = useState(
    Object.fromEntries([...searchParams])
  );
  // const statusList = [
  //   { value: "Planned", label: "Planned" },
  //   // { value: "Active", label: "Active" },
  //   { value: "completed", label: "completed" },
  //   // { value: "Reviewed", label: "Reviewed" },
  //   { value: "finalized", label: "finalized" },
  //   { value: "unplanned", label: "unplanned" },
  //   { value: "Invoiced", label: "Invoiced" },
  // ];

  const clearFilters = () => {
    setFilterObject({
      locationId: "",
      voyagePlanningId: "",
    });
    navigate("/activity-list", { replace: true });
  };
  const filterData = () => {
    const queryStr = generateQueryString(filterObject);
    navigate(`/activity-list?${queryStr}`, { replace: true });
  };

  const headerList = [
    {
      Header: "Activity Name",
      accessor: "ActivityName",
    },
    {
      Header: "Time Spent",
      accessor: "DurationOfActivity",
    },
  ];

  useEffect(async () => {
    // loadNeededEndPoints();
    setInit(true);
    getActivities();
    retrieveVoyages();
    setInit(true);
  }, [queryParams]);

  const getActivities = async (currentPage, PageSize, query = queryParams) => {
    setInit(true);
    setCurrentPage(currentPage);
    setPageSize(PageSize);
    let res = await ActivityService.getTimeSpent(
      currentPage,
      PageSize,
      query && query.substring(1)
    );
    if (res) {
      const parsedData = res.Data.response.map((list) => ({
        ...list,
        startDate: toReadableDate(list.Start),
        endDate: toReadableDate(list.End),
        etaDate: toReadableDate(list.ETA),
      }));
      setTableData(parsedData);
      setTotalCount(res.Data.TotalCount);
    }
    setInit(false);
  };
  const retrieveVoyages = async () => {
    let voyages = await VoyageService.getVoyageList();
    setInit(true);
    if (voyages?.Data) {
      let voyageLocationList = voyages?.Data?.map((loc) => ({
        label: loc?.ZoneDetails?.LocationName,
        value: loc?.ZoneDetails?.LocationId,
      }));
      let voyageLocationList2 = [
        ...new Map(
          voyageLocationList.map((item) => [item["value"], item])
        ).values(),
      ];
      //This removes undefined from the array of objects.
      Object.keys(voyageLocationList2).forEach((key) => {
        if (voyageLocationList2[key].value === undefined) {
          delete voyageLocationList2[key];
        }
      });
      setVoyageLocation(voyageLocationList2);

      voyages = voyages?.Data.map((list) => ({
        ...list,
        value: list?.VoyagePlanningId,
        label: list?.VoyageCode,
      }));
    }
    setVoyageList(voyages);
    setInit(false);
  };

  const tableTopActions = (
    <div className="card-tools mr-n1" data-select2-id={28}>
      <ul className="btn-toolbar gx-1" data-select2-id={27}>
        <li data-select2-id={26}>
          <div className="toggle-wrap" data-select2-id={25}>
            <div
              className="toggle-content"
              data-content="cardTools"
              data-select2-id={24}
            >
              <ul className="btn-toolbar gx-1" data-select2-id={23}>
                <li className="toggle-close">
                  <a
                    className="btn btn-icon btn-trigger toggle"
                    data-target="cardTools"
                  >
                    <em className="icon ni ni-arrow-left" />
                  </a>
                </li>
                {/* li */}

                <li>
                  <div className="dropdown">
                    <a
                      className="btn btn-trigger btn-icon dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <div className="dot dot-primary" />
                      <em className="icon ni ni-filter-alt" />
                    </a>
                    <div className="filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-right">
                      <form id="invoice-filter">
                        <div className="dropdown-head">
                          <span className="sub-title dropdown-title">
                            Filter Activities
                          </span>
                          <div className="dropdown">
                            <a
                              className="btn btn-sm btn-icon"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-cross" title="close" />
                            </a>
                          </div>
                        </div>

                        <div className="dropdown-body dropdown-body-rg">
                          {voyageList?.length <= 1 ||
                          voyageList?.length === undefined ? (
                            <div className="text-center p-3">
                              <Spinner />
                            </div>
                          ) : (
                            <div className="row gx-6 gy-3">
                              <div className="col-12">
                                <div
                                  className="form-group"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <label
                                    className="overline-title overline-title-alt"
                                    htmlFor="location-name-filter"
                                  >
                                    Location Name
                                  </label>
                                  <Select
                                    className="select-dropdown-sm"
                                    options={voyageLocation}
                                    value={voyageLocation?.find(
                                      (loc) =>
                                        loc?.value === filterObject?.locationId
                                    )}
                                    onChange={(option) =>
                                      setFilterObject((prev) => ({
                                        ...prev,
                                        locationId: option.value,
                                      }))
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div
                                  className="form-group"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <label className="overline-title overline-title-alt">
                                    Voyaye Code
                                  </label>
                                  <Select
                                    className="select-dropdown-sm"
                                    options={voyageList}
                                    value={voyageList?.find(
                                      (voyage) =>
                                        voyage?.value ===
                                        filterObject?.voyagePlanningId
                                    )}
                                    onChange={(option) =>
                                      setFilterObject((prev) => ({
                                        ...prev,
                                        voyagePlanningId: option.value,
                                      }))
                                    }
                                  />
                                  {/* )} */}
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="form-group">
                                  <button
                                    onClick={() => filterData()}
                                    type="button"
                                    className="btn btn-secondary"
                                  >
                                    Filter
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="dropdown-foot between">
                          <a className="clickable" onClick={clearFilters}>
                            Reset Filter
                          </a>
                        </div>
                      </form>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );

  return (
    <div>
      {!initializing && (
        <MyTable
          columns={headerList}
          cssArray={["font-weight-500", ""]}
          data={tableData}
          reloadData={(page, pageSize, query) =>
            getActivities(page, pageSize, query)
          }
          emptyMessage="No Activity information"
          pageSize={pageSize}
          page={currentPage}
          totalCount={totalCount}
          paginationStyle={2}
          tableTopActions={tableTopActions}
        />
      )}
    </div>
  );
}
