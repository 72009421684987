import React from "react";

function EmptyRecords({ message }) {
  return (
    <div>
      <p className="text-muted text-center mt-5">
        <em
          className=" icon ni ni-folders mb-3  d-inline-block mt-5"
          style={{ fontSize: "40px" }}
        ></em>
        <br /> {message || "No results to show"}
      </p>
    </div>
  );
}

export default EmptyRecords;
