import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import MainLayout from "../../layouts/mainLayout";
import Select from "react-select";
import "../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import BookingEquipmentSheet from "../../utils/spreadTableEquipment";
import DeckCargoSheet from "../../utils/spreadSheetDeckCargo";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import Spinner from "../../utils/spinner";
import moment from "moment";
import SailingService from "../../services/sailingService";
import CustomModal from "../../utils/customModal";
import LocationService from "../../services/locationService";
import PartyService from "../../services/partyService";
import { Validator, ValidateGroup } from "../../components/validator";
import toastr from "../../utils/toastr";
import { Navigate } from "react-router-dom";
import { defaultModalStyle } from "../../utils/customStyles";
import payloadUpdater from "../../utils/payloadUpdater";
import BookingService from "../../services/bookingRequestService";
import EquipmentService from "../../services/equipmentService";
import CloseButton from 'react-bootstrap/CloseButton';
import OffshoreInstallationDashboard from "../../components/dashboard/OffShoreAllocationCost";
import { Overlay, Tooltip, Button, Row, Popover, OverlayTrigger } from "react-bootstrap";
import { cycleColor, dateToYYYY_MM_DD, randomColor, randomNumber, toReadableDate } from "../../utils/utilityFunctions";

export default function AddBookingRequest() {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [myEventsList, setMyEventsList] = useState(null);
  const localizer = momentLocalizer(moment);
  const [loading, setLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);
  const [startDate, setStartDate] = useState(new Date().toLocaleDateString());
  const [endDate, setEndDate] = useState(new Date().toLocaleDateString());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [deckButtonClicked, setDeckButtonClicked] = useState(false);
  const [bookingButtonClicked, setBookingButtonClicked] = useState(true);
  const [allTubularTypeId, setAllTubularTypeId] = useState("");

  const [hoveredScheduleVisits, sethoveredScheduleVisits] = useState([]);
  const [cachedScheduleVisits, setCachedScheduleVisits] = useState([]);
  const [loadinghoveredScheduleVisits, setLoadinghoveredScheduleVisits] =
    useState(false);
  const [showOverlay, setShowOverlay] = useState(true);

  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedSailingId, setSelectedSailingId] = useState("");
  const [bookingRefNumber, setBookingRefNumber] = useState("");
  const [portOptions, setPortOptions] = useState([]);
  const [installationOptionList, setInstallationOptionList] = useState([]);
  const [sailingsAndDetails, setSailingAndDetails] = useState([]);
  const [productList, setProductList] = useState([]);
  const [operatorsList, setOperatorsList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [measurements, setmeasurements] = useState([]);
  const [chosenOption, setChosenOption] = useState("");
  const [bookingData, setBookingData] = useState([]);
  const [clickedVal, setClickedVal] = useState(false);
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  const [bookingEquipmentContent, setBookingEquipmentContent] = useState([
    ["", "", "", "", "", "", "", "", ""],
  ]); //this initialization is to ensure the bookingEquipment table is not blank.
  const [measurementsPrime, setMeasurementsPrime] = useState([]);
  const [measurementUnitsBulkItem, setMeasurementUnitsBulkItem] = useState([]);
  const [cargoEquipment, setCargoEquipment] = useState([]);
  const [allCargoCarryingEquipments, setAllCargoCarryingEquipments] = useState(
    []
  );
  const [ownersList, setOwnersList] = useState([]);
  const [dgList, setDgList] = useState([]);
  const [unCodeList, setUnCodeList] = useState([]);
  const [pgList, setPgList] = useState([]);
  const [hazardClassList, setHazardClassList] = useState([]);
  const [preferredCargoEquipment, setPreferredCargoEquipment] = useState([]);
  const [equipmentOwnersList, setEquipmentOwnersList] = useState();
  const [cargoEquipmentType, setCargoEquipmentType] = useState();
  const [deckCargoData, setDeckCargoData] = useState([
    ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
  ]); //this initialization is to ensure the DeckCargo table is not blank.
  const [measurementUnitData, setMeasurementUnitData] = useState();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [bookingRequest, setBookingRequest] = useState([]);
  const [cargoDataDetails, setCargoDataDetails] = useState([]);
  const [bulkItemsList, setBulkItemsList] = useState([]);
  const [selectedSailingInfo, setSelectedSailingInfo] = useState([]);


  const bookingDetailsPayload = new payloadUpdater({
    payload: bookingRequest,
    setPayload: setBookingRequest,
  }).update;
  const bulkCargoPayload = new payloadUpdater({
    payload: cargoDataDetails,
    setPayload: setCargoDataDetails,
  }).update;
  cargoDataDetails.shipSideEquipmentId = "00000000-0000-0000-0000-000000000000";
  const validationGroup = "addBookingRequest";

  const retrieveDgClasses = async () => {
    const dgFullList = await BookingService.dgClassificationList();
    // Retrieve ID for DG class category
    const retrievedDgId = dgFullList?.find(
      (a) => a?.CorrespondingData.toLowerCase() === "ibc "
    )?.ConfigCategoryId;
    const completeDgItems =
      dgFullList
        ?.filter((item) => item?.ConfigCategoryId === retrievedDgId)
        ?.map((obj) => ({
          id: obj.ConfigurationId,
          name: obj?.CorrespondingData,
        })) || "";
    setDgList(completeDgItems);
    const retrieveUnId = dgFullList?.find(
      (a) => a?.CorrespondingData.toLowerCase() === "2011"
    )?.ConfigCategoryId;
    const completeUnCodes =
      dgFullList
        ?.filter((item) => item?.ConfigCategoryId === retrieveUnId)
        ?.map((obj) => ({
          id: obj.ConfigurationId,
          name: obj?.CorrespondingData,
        })) || "";
    setUnCodeList(completeUnCodes);
    const retrievePgGrpId = dgFullList?.find(
      (a) => a?.CorrespondingData.toLowerCase() === "packing group (pg) i"
    )?.ConfigCategoryId;
    const completePGList =
      dgFullList
        ?.filter((item) => item?.ConfigCategoryId === retrievePgGrpId)
        ?.map((obj) => ({
          id: obj.ConfigurationId,
          name: obj?.CorrespondingData,
        })) || "";
    setPgList(completePGList);
    const retrievedhazardClassId = dgFullList?.find(
      (a) => a.CorrespondingData.toLowerCase() === "class 1 "
    )?.ConfigCategoryId;
    const completeHazardClassList =
      dgFullList
        ?.filter((item) => item?.ConfigCategoryId === retrievedhazardClassId)
        ?.map((obj) => ({
          id: obj.ConfigurationId,
          name: obj.CorrespondingData,
        })) || "";
    setHazardClassList(completeHazardClassList);
  };
  let direction = [
    { label: "Outbound", value: "Outbound" },
    { label: "Interfield", value: "Interfield" },
    { label: "Inbound", value: "Inbound" },
  ]
  let customsStatus = [
    { label: "Cleared", value: "Cleared" },
    { label: "Awaiting clearance", value: "Awaiting clearance", }]
  //non-weight items to be removed from list of measurement units CCU Owner
  const measuementItemsToFilter = ["hour", "kelvin", "second", "knots", "inch",
    "minute", "watt", "horsepower", "meter per second", "kilometer per ho", "kilowatt",
    "meter", "square meter", "centimeter", "cubic meter per hour", "square centimeter"]

    const tableMeasurementFilter = ["hour", "kelvin", "second", "knots", "inch",
    "cubic centimeter", "cubic meter per ", "cubic meter", "liters", "milliliter", 
    "minute", "watt", "horsepower", "meter per second", "kilometer per ho", "kilowatt",
    "meter", "square meter", "centimeter", "cubic meter per hour", "square centimeter"]

  const retrieveMeasurementUnit = async () => {
    const unitsList = await EquipmentService.listMeasurementUnits();
    setMeasurementUnitData(unitsList);
    setmeasurements(unitsList);
    let editedUnits = unitsList?.map((obj) => ({
      name: obj.MeasurementUnit,
      id: obj.MeasurementUnitId,
    }));
    //removing the non-weight measurement units from the list.
    editedUnits = editedUnits?.filter((units) => !measuementItemsToFilter.find(list => (list === units.name)))
    setMeasurementUnitsBulkItem(editedUnits);
    let editedUnitedForSheets = editedUnits?.filter((units) => !tableMeasurementFilter.find(list => (list === units.name)))
    setMeasurementsPrime(editedUnitedForSheets);
     
  };
  const partyOption = async () => {
    let listOfParties = await PartyService.listParty();

    // list of Operators
    let listOfOperatorsOnly = listOfParties.filter((party) => party.PartyType?.toLowerCase() === "operator")
    setOperatorsList(listOfOperatorsOnly);

    setEquipmentOwnersList(listOfParties);
    let editedList = listOfParties.filter((party) => party.PartyType?.toLowerCase() === "ccu owner")
    editedList = editedList?.map((obj) => ({
      id: obj.PartyId,
      name: obj.PartyName,
    }));
    setOwnersList(editedList);
  };

  const userPreferredCargoEquipment = async () => {
    const preferredEquipment = await BookingService.cargoEquipment();
    const tubularId = preferredEquipment?.find((item) => item?.equipmentType.toLowerCase() === "tubular/casing (drill collar)")?.equipmentTypeId
    setAllTubularTypeId(tubularId)
    setPreferredCargoEquipment(preferredEquipment);
    setCargoEquipmentType(preferredEquipment);
    const equipDescription = preferredEquipment?.map((obj) => ({
      id: obj.tubularCategoryId || obj.equipmentTypeId,
      name: obj.equipmentType,
    }));
    setCargoEquipment(equipDescription);
    setAllCargoCarryingEquipments(equipDescription);
  };

  //We filtered and concatenated booking equipment data for the first column on the Deck Cargo Spreadsheet 
  let entireBookingData = bookingEquipmentContent.map((obj) => ({
    id: obj[0],
    name: preferredCargoEquipment?.find(equip => equip?.equipmentTypeId === obj[0])?.equipmentType ||
      preferredCargoEquipment?.find(equip => equip?.tubularCategoryId === obj[0])?.equipmentType,
    ccuNO: obj[6],
    dimension: obj[7]
  }))

  //Index was used as ID because 
  let newEntireBookingData = entireBookingData.map((newlist, index) => ({
    id: index + 1,
    dbId: newlist.id,
    name: (newlist.name !== "Offshore Container") ? `${newlist.name} - ${newlist.dimension}` :
      `${newlist.name} - ${newlist.ccuNO}`
  }))

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);
    await userPreferredCargoEquipment();
    await listOfProducts(1, 50);
    await locationTypes();
    await retrieveSchedules();
    await partyOption();
    await retrieveDgClasses();
    await retrieveMeasurementUnit();
    setLoading(false);
  };


  const handleSelect = (e) => {
    setSelectedSailingId(e.SailingId);
    retrieveSelectedSailingInfo(e.SailingId);
    setSelectedSchedule(e);
    setModalIsOpen(true);
    sailing(e.SailingId);
    setButtonClicked(false);
  };

  const retrieveSelectedSailingInfo = async (retrievedId) => {
    const sailingDetailedInfo = await SailingService.getSailingLocationBySailing(retrievedId)
    setSelectedSailingInfo(sailingDetailedInfo);
  }
  const gethoveredScheduleVisits = async (hoveredSchedule) => {
    setLoadinghoveredScheduleVisits(true);
    let visitList = [];
    // check if the user has already hovered on this visit before
    const cachedVisit = cachedScheduleVisits.find(
      (visit) => visit.SailingId === hoveredSchedule?.SailingId
    );
    if (cachedVisit) {
      visitList = cachedVisit.visitList;
    } else {
      visitList = await SailingService.getSailingLocationBySailing(
        hoveredSchedule?.SailingId
      );
      visitList = (visitList || []).map((e) => ({
        ...e,
        IsBulkRequirement: e.IsBulkRequirement ? "Yes" : "No",
        DayOnLocationFormatted: toReadableDate(e.DayOnLocation),
      }));

      setCachedScheduleVisits((prev) => [
        ...prev,
        { ...hoveredSchedule, visitList },
      ]);
    }
    sethoveredScheduleVisits([...visitList] || []);
    setLoadinghoveredScheduleVisits(false);
  };


  const calenderPopOver = useCallback((schedule) => {
    const popover =

      (
        <Popover id="calender-popover" show={false}>
          <Popover.Header as="h3">{schedule.event.title}
            <CloseButton className="icon ni ni-cross btn-danger float-sm-right"
              onClick={() => setShowOverlay(false)} />
          </Popover.Header>
          <Popover.Body>
            {loadinghoveredScheduleVisits ? (
              <div className="text-center p-3">
                <Spinner />
              </div>
            ) : (
              <div
                style={{ maxHeight: "140px", overflowY: "auto" }}
                className="small-scrollbar pr-2"
              >
                <small className="d-block mt-2">
                  Start: {moment(schedule.event.start).format("llll")}
                </small>
                <small className="d-block my-1">
                  End: {moment(schedule.event.end).format("llll")}
                </small>

                <div>
                  <h6 className="mt-2">Locations</h6>
                  {hoveredScheduleVisits.map((visit, index) => (
                    <small className="d-block my-1" key={index}>
                      {visit.LocationName}: {visit.DayOnLocationFormatted}
                    </small>
                  ))}
                </div>
              </div>
            )}
          </Popover.Body>
        </Popover>
      );

    const overlay =
      <OverlayTrigger
        placement="auto"
        overlay={popover}
        id="calender-overlay"
        show={false}
      >
        <div className="d-flex align-center" >
          <b>{schedule.event.title}</b>
          <span className="small ml-1">
            {schedule.event.SailingRoute && `(${schedule.event.SailingRoute})`}
          </span>

        </div>
      </OverlayTrigger>

    return overlay;
  }, [showOverlay]);

  const randomiseCalenderEntryColor = useCallback((event) => {
    return { style: event.style };
  }, []);
  const retrieveSchedules = async () => {
    // if (!_startDate) {
    //   _startDate = dateToYYYY_MM_DD(new Date());
    // }

    // if (!_endDate) {
    //   const dateNow = new Date();
    //   const _currentMonth = dateNow.getMonth() + 1;
    //   const _currentYear = dateNow.getFullYear();
    //   _endDate = dateToYYYY_MM_DD(new Date(_currentYear, _currentMonth, 0));
    // }
    const schedulesInfo = await SailingService.getUnlockedSailingDetails();
    const _schedules = schedulesInfo?.Data;
    if (!_schedules || _schedules == null) setSchedules([]);
    else {
      setSchedules(_schedules);
      setMyEventsList(
        _schedules.map((schedule, index) => ({
          ...schedule,
          title: "Sailing " + schedule.SailingNo,
          start: schedule.DayOfSailing,
          end: schedule.DayOfReturnInPort,
          style: {
            backgroundColor: `${cycleColor(index)}`,
          },
        }))
      );
    }
  };

  const firstDayOfMonth = (date) => {
    let _currentMonth = date.getMonth() + 1;
    const _currentYear = date.getFullYear();

    if (_currentMonth.toString().length < 2)
      _currentMonth = "0" + _currentMonth.toString();
    return `${_currentYear}-${_currentMonth}-01`;
  };

  const MyCalendar = useCallback((props) => (
    <>
      {myEventsList && (
        <div>
          <Calendar
            localizer={localizer}
            events={myEventsList || []}
            showAllEvents={true}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 420 }}
            onSelectEvent={(event) => handleSelect(event)}
            onSelectSlot={(e) => handleSelect()}
            // onNavigate={(date) => calendarNavigated(date)}
            eventPropGetter={randomiseCalenderEntryColor}
            components={{
              event: calenderPopOver,
            }}
            views={[Views.MONTH]}
          />
        </div>
      )}
    </>
  ), [myEventsList])

  const displayCalendar = () => {
    return <div>{myEventsList && <MyCalendar></MyCalendar>}</div>;
  };

  const displayToggle = (prop) => {
    return (
      <>
        {myEventsList && (
          <div>
            <CustomModal
              title={"Select preferred Sailing"}
              content={<MyCalendar></MyCalendar>}
              // content={displayCalendar()}
              onClose={() => setButtonClicked(false)}
            />
          </div>
        )}
      </>
    );
  };
  const handleClick = () => {
    if (!buttonClicked) {
    }
    setButtonClicked(true);
  };
  const locationTypes = async () => {
    const locationsTypes = await LocationService.list();
    const retrievePortFilter = locationsTypes?.find(
      (p) => p.LocationTypeName?.toLowerCase() === "port"
    );
    let retrievedPort = retrievePortFilter?.LocationTypeId;
    const ports = locationsTypes?.filter(
      (p) => p?.LocationTypeId === retrievedPort
    );
    const offshoreFilters = locationsTypes?.find(
      (p) => p.LocationTypeName?.toLowerCase() === "offshore installation"
    );
    let retrievedInstallation = offshoreFilters?.LocationTypeId;
    const offshoreInstallationsList = locationsTypes?.filter(
      (p) => p?.LocationTypeId === retrievedInstallation
    );
    setInstallationOptionList(offshoreInstallationsList)

    setLocationsList(locationsTypes);
    setPortOptions(ports);
  };

  const sailing = async () => {
    const specificSailing = await SailingService.getUnlockedSailingDetails();
    setSailingAndDetails(specificSailing); //SailingId
  };
  const listOfProducts = async () => {
    const listProducts = await BookingService.products();
    setProductList(listProducts?.Data);
  };
  let selectedSailingDetails = sailingsAndDetails?.Data?.find((list) => list?.SailingId === selectedSailingId)

  const selectSailingLocation = selectedSailingInfo?.find((item) => item?.LocationId === bookingRequest?.offshoreInstallation)
  let formattedDayOnLocation = toReadableDate(selectSailingLocation?.DayOnLocation);
  let timeOnLocation = selectSailingLocation?.ReqTimeOnLocationInHrs;

  // let chosenSailing = selectedSailingDetails?.filter((list) => list?.IsBulkRequirement === true);

  // setDayOnLocation(b);
  let selectedSailing = myEventsList?.find(
    (sortedSailing) => sortedSailing.SailingId === selectedSailingId
  );


  let locationForSailing = [];
  let operatorForSailing = [];
  let offshoreInstallationOptions = [];
  if (selectedSailingDetails) {
    let operatorForSailingList = selectedSailingDetails?.SailingRoutes?.map((list) => ({
      IsBulkRequirement: list?.IsBulkRequirement,
      LocationId: list?.LocationId,
      LocationName: list?.LocationName,
      PartyName: list?.PartyName,
      PartyId: list?.PartyId
    }))

    operatorForSailingList = operatorForSailingList?.map((obj) =>
      Object.fromEntries(Object.entries(obj).filter(([key, value]) => !!value)));//removes objects with null values
    operatorForSailingList?.forEach((item, index, object) => {
      if (!item.PartyId || !item.PartyName) {
        object.splice(index, 1);
      }
    })
    locationForSailing?.splice(0, locationForSailing?.length, ...operatorForSailingList)

    const uniqueOperatorsList = [...new Map(locationForSailing.map(item => [item.PartyName, item])).values()]
    operatorForSailing?.splice(0, operatorForSailing?.length, ...uniqueOperatorsList)

    let offshoreInstallationList = operatorForSailingList.filter((item) => item?.PartyId === bookingRequest.partyId)
    const uniqueList = [...new Map(offshoreInstallationList.map(item => [item.LocationName, item])).values()]

    offshoreInstallationOptions?.splice(0, offshoreInstallationOptions?.length, ...uniqueList)
  }

  let sailingWithBulkItems = [];
  if (selectedSailingDetails) {

    let bulkItemCheck = selectedSailingDetails?.SailingRoutes?.filter((list) => list?.IsBulkRequirement === true)
    sailingWithBulkItems.push(bulkItemCheck)
    sailingWithBulkItems = sailingWithBulkItems.flat();
  }
  let operatorHasBulk = sailingWithBulkItems?.find((party) => party?.PartyId === bookingRequest?.partyId)?.IsBulkRequirement;

  let offshoreLocation = locationsList?.find(
    (p) => p.LocationId === selectedSailingDetails?.[0]?.LocationId
  );
  let offshoreDetails = {
    offshoreLocationName: offshoreLocation?.LocationName,
    offshoreLocationId: offshoreLocation?.LocationId,
  };
  bookingRequest.sailingId = selectedSailing?.SailingId;

  bookingRequest.expectedHandlingTimeOnLocation = timeOnLocation;
  bookingRequest.dayOnLocation = formattedDayOnLocation;

  const receiveBookingEquipmentDetails = (equipment) => {
    setBookingEquipmentContent(equipment); // This would be set to session storage (or state object if it won't result in re-render)
  };
  const receiveDeckCargoDetails = (list) => {
    setDeckCargoData(list);
    // setBookingEquipmentContent(list); // This would be set to session storage (or state object if it won't result in re-render)
  };
  const closeAndClearModal = () => {
    setToggleCheckBox(false);
    setChosenOption("");
  };
  const modalCheckBox = () => {
    return (
      <div>
        <CustomModal
          title={
            <span>
              <em class="icon ni ni-info-i"></em> Confirm Save
            </span>
          }
          content={
            <div className="text-center">
              <span>
                <p>
                  <h6>Kindly click 'confirm' to save booking request.</h6>
                </p>
              </span>
              <br />
              <div>
                <input
                  type="button"
                  className="btn btn-md btn-dark btn-wide"
                  value="Confirm"
                  name="chosenOption"
                  onClick={() => toggleChosenOptionYes()}
                />
                <input
                  type="button"
                  className="btn btn-sm btn-link m-1"
                  value="Cancel"
                  name="chosenOption"
                  onClick={() => toggleChosenOptionNo()}
                />
              </div>
            </div>
          }
          onClose={() => closeAndClearModal()}
        />
      </div>
    );
  };
  const toggleChosenOptionYes = () => {
    saveBookingRequest();
    closeAndClearModal();
  };
  const toggleChosenOptionNo = () => {
    closeAndClearModal();
  };

  const selectBulkCargo = (value, index) => {
    let items = bulkItemsList;
    items[index]["productId"] = value.value;
    setBulkItemsList(items);
  };
  const bulkItemQuantity = (value, index) => {
    let items = bulkItemsList;
    items[index]["quantity"] = value.value;
    setBulkItemsList(items);
  };
  const quantityUnit = (value, index) => {
    let items = bulkItemsList;
    items[index]["measurementUnitId"] = value.value;
    setBulkItemsList(items);
  };
  const customsStatusOfItem = (value, index) => {
    let items = bulkItemsList;
    items[index]["customStatus"] = value.value;
    setBulkItemsList(items);
  };
  const specificGravityOfItem = (value, index) => {
    let items = bulkItemsList;
    items[index]["specificGravity"] = value.value;
    setBulkItemsList(items);
  };
  const removeBulkItem = (index) => {
    let items = [...bulkItemsList];
    items.splice(index, 1);
    setBulkItemsList(items);
  };
  //removing payload items that are not relevent to specified direction.
  if (bookingRequest?.direction === "Outbound") {
    delete bookingRequest?.secondOffshoreInstallation
    delete bookingRequest?.portOfDischarge
  }
  else if (bookingRequest?.direction === "Inbound") {
    delete bookingRequest?.secondOffshoreInstallation
    delete bookingRequest?.portOfLoading
  }
  else if (bookingRequest?.direction === "Interfield") {
    delete bookingRequest?.portOfLoading
    delete bookingRequest?.portOfDischarge
  }

  //#region logic for array conversion and keys addition
  let bookingDataToBeSaved = bookingEquipmentContent;
  //The loop below allows saving of CargoCarryingEquipmentTypeId as bookingEquipmentDetailId for each deckCargo item
  //it returns the exact index to the backend.

  let bookingEquipmentKeys = [];
  bookingDataToBeSaved.forEach((element) => {
    bookingEquipmentKeys.push(element);
  });
  const bookingEquipmentHash = {};

  let x = bookingEquipmentKeys.map((obj, index) => {
    let uniqueObj = `${obj[0]} - ${obj[7]} - ${obj[6]}`
    if (!bookingEquipmentHash[uniqueObj]) {
      bookingEquipmentHash[uniqueObj] = index;
    }

    return {
      cargoCarryingEquipmentTypeId:
        cargoEquipmentType?.find(
          (equipmentId) => equipmentId?.tubularCategoryId === obj[0]
        )?.equipmentTypeId || obj[0], //EquipmentTypeId.
      partyId: obj[1] || "00000000-0000-0000-0000-000000000000",
      quantityOfEquipment: obj[2] || 0,
      sealNo: obj[3] || "",
      estimatedGrossWeight: obj[4] || 0.0,
      measurementUnitId: obj[5],
      ccuNumber: obj[6] || "",
      description: obj[7] || "",
      fullEmptyIndication: obj[8] || "",
      tubularCategoryId:
        cargoEquipmentType?.find(
          (equipmentId) => equipmentId?.tubularCategoryId === obj[0]
        )?.tubularCategoryId || "00000000-0000-0000-0000-000000000000", //tubularCategoryId,
    };
  });
  let bookingEquipment = x;
  let deckCargoToSave = deckCargoData;
  let deckCargoKeys = [];
  deckCargoToSave.forEach((element) => {
    deckCargoKeys.push(element);
  });
  let c = deckCargoKeys.map((obj) => ({
    equipmentIndex: parseInt(obj[0]) - 1, //Add the index object of the booking equipment
    bookingEquipmentDetailId: newEntireBookingData?.find((item) => (item?.id).toString() === obj[0])?.dbId || "00000000-0000-0000-0000-000000000000",
    serialNumber: obj[1] || "",
    grossWeightValue: obj[2] || "",
    grossWeightUnitId: obj[3] || "00000000-0000-0000-0000-000000000000",
    netWeightValue: obj[4] || 0.0,
    netWeightUnitId: obj[5] || "00000000-0000-0000-0000-000000000000",
    measurementValue: obj[6] || 0.0,
    measurementValueUnitId: obj[7] || "00000000-0000-0000-0000-000000000000",
    customsStatus: obj[8] || "",
    descriptionOfGoods: obj[9] || "",
    hsCode: obj[10] || "",
    numberOfPackages: obj[11] || 0,
    typeOfPackage: obj[12] || "",
    // isDG: obj[13] || false,
    isDG: (obj[13] === "Yes" ? true : "" || obj[13] === "No" ? false : ""),
    dgClassificationConfigId: obj[14] || "00000000-0000-0000-0000-000000000000",
    unNumberConfigId: obj[15] || "00000000-0000-0000-0000-000000000000",
    subsidiaryRisk: obj[16] || "",
    marpolPollutionCode: obj[17] || "",
    imoHazardClassConfigId: obj[18] || "00000000-0000-0000-0000-000000000000",
    packingGroupConfigId: obj[19] || "00000000-0000-0000-0000-000000000000",
    flashpoint: obj[20] || "",
    emS: obj[21] || "",
  }));
  let deckCargos = c;
  let bigBagId = allCargoCarryingEquipments?.find((item) => item.name.toLowerCase() === "bigbag")?.id
  let containerId = allCargoCarryingEquipments?.find((item) => item.name.toLowerCase() === "offshore container")?.id

  let cargos = [];
  cargos?.splice(0, cargos?.length, ...bulkItemsList)//this ensures the objects in cargos are not nested.

  //#endregion logic for array conversion and keys addition

  //#region spreadsheet validation logic
  let requiredDeckCargoKeys = ["equipmentIndex", "numberOfPackages", "descriptionOfGoods", "measurementValue",
    "measurementValueUnitId", "grossWeightValue", "grossWeightUnitId", "netWeightValue",
    "netWeightUnitId", "numberOfPackages", "isDG"];
  let nonDgKeys = ["dgClassificationConfigId", "imoHazardClassConfigId", "unNumberConfigId", "packingGroupConfigId"]
  // const validateDeckCargo = () => {

  const validatePayload = () => {


    const requiredBookingRequestKeys = [
      "cargoCarryingEquipmentTypeId",
      // "PartyId",
      "QuantityOfEquipment",
      "EstimatedGrossWeight",
      "MeasurementUnitId",
    ];

    const validateBookingRequestFailed = bookingEquipment.find((request) => {
      return requiredBookingRequestKeys.find((item) => {

        setLoading(false);
        return (
          request[item] === "" ||
          request[item] === "00000000-0000-0000-0000-000000000000"
        );
      });
    });
    if (!validateBookingRequestFailed) {
      return !deckCargos.find((request) => {
        return (
          requiredDeckCargoKeys.find((item) => {
            return (
              request[item] === "" ||
              request[item] === "00000000-0000-0000-0000-000000000000"
            );
          })
          // || request.equipmentIndex === undefined
        );

      });

    } else {
      setLoading(false);
      return false;
    }
  };

  let conditionalBookingItems = ["cargoCarryingEquipmentTypeId", "partyId", "ccuNumber",
    "quantityOfEquipment", "description"];

  let conditionalDeckCargoItems = ["dgClassificationConfigId", "imoHazardClassConfigId", "unNumberConfigId", "packingGroupConfigId"]

  const newValidation = () => {
    const validateBookingItemsFailed = bookingEquipment.find((request) => {
      return conditionalBookingItems.find((item) => {
        return (
          ((request.cargoCarryingEquipmentTypeId === containerId) && (request.partyId === "00000000-0000-0000-0000-000000000000")) ||
          ((request.cargoCarryingEquipmentTypeId === containerId) && (request.ccuNumber === "")) ||
          ((request.cargoCarryingEquipmentTypeId === containerId) && (request.quantityOfEquipment > 1)) ||
          ((request.cargoCarryingEquipmentTypeId === containerId) && (request.quantityOfEquipment === "")) ||
          ((request.cargoCarryingEquipmentTypeId === allTubularTypeId || (request.cargoCarryingEquipmentTypeId === bigBagId)) && (request.partyId != "00000000-0000-0000-0000-000000000000")) ||
          ((request.cargoCarryingEquipmentTypeId === allTubularTypeId || (request.cargoCarryingEquipmentTypeId === bigBagId)) && (request.description === "")) ||
          ((request.cargoCarryingEquipmentTypeId === allTubularTypeId || (request.cargoCarryingEquipmentTypeId === bigBagId)) && (request.ccuNumber != ""))
        );
      });
    });
    if (!validateBookingItemsFailed) {
      setLoading(false);
      return !deckCargos.find((request) => {
        return (
          conditionalDeckCargoItems.find((item) => {
            return (
              ((request.isDG === true) && (request[item] === "00000000-0000-0000-0000-000000000000"))
            )
          })
        )
      })
    }
    else {
      return false;
    }
  }


  //#region For tracking the spreadtable contents
  //bookingEquipmentContentReplica(a copy of bookingEquipmentContent) array tracks input and changes to the BookingEquipment table.
  //it has been flattened and converted to a Set object which returns the length to 1.
  //If any value different from ''(empty string) is added, the length increases accordingly.
  let bookingEquipmentContentReplica = bookingEquipmentContent.flat();//it was copied to enable us convert it to a Set
  bookingEquipmentContentReplica = [...new Set(bookingEquipmentContentReplica)]
  let deckCargoReplica = deckCargoData.flat();
  deckCargoReplica = [...new Set(deckCargoReplica)]

  //#endregion end of spreadsheet validation logic.

  const toggleDeckCargo = () => {
    setBookingButtonClicked(false);
    setDeckButtonClicked(true);
    //ToDO: add a function to validate CCu and dimension/description fields accordingly. 
  };
  const toggleBookingEquipmentTable = () => {
    setDeckButtonClicked(false);
    setBookingButtonClicked(true);
  };
  const saveBookingRequest = async () => {

    let bookingInfo = {
      bookingRequest,
      cargos,
      deckCargos,
      bookingEquipment,
    };


    const validatorStatus = ValidateGroup(validationGroup);
    if (
      !validatorStatus
    ) {
      toastr("error", "Incomplete booking request information, please fill the empty fields in the first/second section.");
      return;
    }
    if ((!validatePayload()) && (bookingEquipmentContentReplica?.length > 1)) return toastr("error", "Some required fields are empty, check booking equipment and deck cargo information!");

    if (!newValidation() && (bookingEquipmentContentReplica?.length > 1))
      return toastr("error", "Please check booking equipment and deck cargo information sheet, some records are missing!");

    if ((deckCargoReplica.length > 1) && (bookingEquipmentContentReplica?.length <= 1)) return toastr("error", "Deck cargo records cannot be saved without Booking equipment!");
    if ((bookingInfo.cargos.length === 0) && (bookingEquipmentContentReplica?.length <= 1)) return toastr("error", "You must saved a Bulk cargo or Booking equipment!");
    if (bookingEquipmentContentReplica?.length <= 1) {
      bookingInfo = {
        bookingRequest,
        cargos,
      };
    }

    setLoading(true);
    const request = await BookingService.saveBookingDetails(bookingInfo);
    if (request) {
      toastr("success", "Booking request added");
      setRedirectUrl("/booking-requests");
      setLoading(false);

    } else {
      toastr("error", "Booking request could not be added");
      setLoading(false);
      return;
    }
  };


  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Booking Request - Create"
      loading={loading}
      showFullLoader={loading}
    >
      <div className="card-inner p-5">
        <form>
          <span className="preview-title-lg overline-title">
            Booking Details{" "}
          </span>
          <div className="row gy-4 p-3">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label" htmlFor="fw-last-name">
                  Sailing
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-right">
                    <em
                      className="icon ni ni-calendar-alt"
                      onClick={handleClick}
                    />
                  </div>
                  <input
                    type="button"
                    onClick={handleClick}
                    value={selectedSailing?.title}
                    className="form-control"
                  />
                  <Validator
                    data={bookingRequest.sailingId}
                    errorMessage="Select a Sailing"
                    groupName={validationGroup}
                    dataKey={"sailingId"}
                  />
                </div>
                {buttonClicked ? displayToggle() : ""}
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="fw-last-name">
                  Manifest Number
                </label>
                <div className="form-control-wrap">
                  <input
                    value={bookingRequest?.manifestNumber}
                    type="number"
                    data-msg="Required"
                    className="form-control required"
                    id="fw-last-name"
                    name="fw-last-name"
                    onChange={(e) =>
                      bookingDetailsPayload("manifestNumber", e.target.value)
                    }
                  />
                  <Validator
                    data={bookingRequest.manifestNumber}
                    errorMessage="Enter Manifest number"
                    groupName={validationGroup}
                    dataKey={"manifestNumber"}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group"
              //  style={{ zIndex: "8" }}..
              >
                <label className="form-label" htmlFor="fw-last-name">
                  Direction
                </label>
                <div className="form-control-wrap">
                  <Select
                    options={direction.map((e) => ({
                      ...e,
                      label: e.label,
                      value: e.value,
                    }))}
                    value={direction.map((e) => ({
                      ...e,
                      label: e.label,
                      value: e.value,
                    })).find((a) => a.value === bookingRequest.direction)}
                    // .find((a) => a.value == bookingRequest.direction)
                    onChange={(option) => {
                      bookingDetailsPayload("direction", option.value);
                    }}
                  />
                  <Validator
                    data={bookingRequest.direction}
                    errorMessage="Select a direction"
                    groupName={validationGroup}
                    dataKey={"direction"}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="fw-last-name">
                  Day on location
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    value={formattedDayOnLocation}
                    data-msg="Required"
                    className="form-control required"
                    id="fw-last-name"
                    name="fw-last-name"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-6" >


              <div className="form-group">
                <label className="form-label" htmlFor="fw-last-name">
                  Expected handling time on location
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    data-msg="Required"
                    value={timeOnLocation}
                    className="form-control required"
                    disabled
                  />
                </div>
              </div>

              {operatorForSailing.length > 0 && (
                <div className="form-group">
                  <label className="form-label" htmlFor="fw-last-name">
                    Operator Name
                  </label>{" "}
                  <div className="form-control-wrap">
                    <Select
                      maxMenuHeight={160}
                      options={operatorForSailing?.map((a) => ({
                        ...a,
                        label: a.PartyName,
                        value: a.PartyId,
                      }))}
                      value={operatorForSailing?.map((a) => ({
                        ...a,
                        label: a.PartyName,
                        value: a.PartyId,
                      })).find((a) => a.value === bookingRequest.partyId)}
                      onChange={(option) => {
                        bookingDetailsPayload("partyId", option.value);
                      }}
                    />
                    <Validator
                      data={bookingRequest.partyId}
                      errorMessage="Please select Operator"
                      groupName={validationGroup}
                      dataKey={"partyId"}
                    />
                  </div>
                </div>)}
            </div>

            <div className="col-sm-6">
              {(bookingRequest.direction === "Outbound") && (
                <div className="form-group"
                //  style={{ zIndex: "8" }}
                >
                  <label className="form-label" htmlFor="fw-last-name">
                    Port Of Loading
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      maxMenuHeight={160}
                      options={portOptions?.map((a) => ({
                        ...a,
                        label: a.LocationName,
                        value: a.LocationId,
                      }))}
                      value={portOptions?.map((a) => ({
                        ...a,
                        label: a.LocationName,
                        value: a.LocationId,
                      })).find((a) => a.value === bookingRequest.portOfLoading)}

                      onChange={(option) => {
                        bookingDetailsPayload("portOfLoading", option.value);
                      }}
                    />
                    <Validator
                      data={bookingRequest.portOfLoading}
                      errorMessage="Select a Port Of Loading"
                      groupName={validationGroup}
                      dataKey={"portOfLoading"}
                    />
                  </div>
                </div>
              )}
              {bookingRequest.direction === "Inbound" && (
                <div className="form-group"
                // style={{ zIndex: "8" }}
                >
                  <label className="form-label" htmlFor="fw-last-name">
                    Port Of Discharge
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      maxMenuHeight={160}
                      options={portOptions?.map((a) => ({
                        ...a,
                        label: a?.LocationName,
                        value: a?.LocationId,
                      }))}
                      value={portOptions?.map((a) => ({
                        ...a,
                        label: a?.LocationName,
                        value: a?.LocationId,
                      })).find((a) => a.value === bookingRequest.portOfDischarge)}
                      onChange={(option) => {
                        bookingDetailsPayload("portOfDischarge", option.value);
                      }}
                    />
                    <Validator
                      data={bookingRequest.portOfDischarge}
                      errorMessage="Select a Port"
                      groupName={validationGroup}
                      dataKey={"portOfDischarge"}
                    />
                  </div>
                </div>
              )}
              {bookingRequest.direction === "Interfield" && (
                <div className="form-group"
                //  style={{ zIndex: "8" }}
                >
                  <label className="form-label" htmlFor="fw-last-name">
                    Offshore installation (Departure)
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      maxMenuHeight={200}
                      options={installationOptionList?.map((a) => ({
                        ...a,
                        label: a.LocationName,
                        value: a.LocationId,
                      }))}
                      value={installationOptionList?.map((a) => ({
                        ...a,
                        label: a.LocationName,
                        value: a.LocationId,
                      })).find((a) => a.value === bookingRequest.secondOffshoreInstallation)}
                      onChange={(option) => {
                        bookingDetailsPayload(
                          "secondOffshoreInstallation",
                          option.value
                        );
                      }}
                    />
                    <Validator
                      data={bookingRequest.secondOffshoreInstallation}
                      errorMessage="Select an Offshore installation"
                      groupName={validationGroup}
                      dataKey={"offshoreInstallation"}
                    />
                  </div>
                </div>)}


              {bookingRequest?.partyId && (
                <div className="form-group"
                //  style={{ zIndex: "8" }}
                >
                  <label className="form-label" htmlFor="fw-last-name">
                    Offshore installation
                  </label>
                  <div className="form-control-wrap">
                    <Select
                      maxMenuHeight={200}
                      options={offshoreInstallationOptions?.map((a) => ({
                        ...a,
                        label: a.LocationName,
                        value: a.LocationId,
                      }))}
                      value={offshoreInstallationOptions?.map((a) => ({
                        ...a,
                        label: a.LocationName,
                        value: a.LocationId,
                      })).find((a) => a.value === bookingRequest.offshoreInstallation)}
                      onChange={(option) => {
                        bookingDetailsPayload(
                          "offshoreInstallation",
                          option.value
                        );
                      }}
                    />
                    <Validator
                      data={bookingRequest.offshoreInstallation}
                      errorMessage="Select an Offshore installation"
                      groupName={validationGroup}
                      dataKey={"offshoreInstallation"}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {operatorHasBulk ? (
            <div>
              <hr className="preview-hr" />
              <span className="preview-title-lg overline-title">
                Bulk Cargo Details{" "}
              </span>

              {bulkItemsList.map((list, index) =>
              (
                <div className="row gy-4 p-3" key={index} >
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Bulk Cargo
                      </label>{" "}
                      <div className="form-control-wrap">
                        <div className="form-control-wrap">
                          <Select
                            //productList
                            options={productList?.map((a) => ({
                              ...a,
                              label: a.ProductName,
                              value: a.ProductId,
                            }))}
                            value={productList?.map((a, index) => ({
                              ...a,
                              label: a.ProductName,
                              value: a.ProductId,
                            })).find(
                              (a) => a.value === list?.productId
                            )}
                            onChange={(item) =>
                              selectBulkCargo(item, index)
                            }
                            placeholder="select product"
                            maxMenuHeight={160}
                          />
                          <Validator
                            data={list?.productId}
                            errorMessage="Please select bulk product"
                            groupName={validationGroup}
                            dataKey={"productId"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group" >
                      <label className="form-label" htmlFor="fw-last-name">
                        Quantity Unit{" "}
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          options={measurementsPrime?.map((a) => ({
                            ...a,
                            label: a.name,
                            value: a.id,
                          }))}
                          value={measurementsPrime.map((a) => ({
                            ...a,
                            label: a.name,
                            value: a.id,
                          })).find(
                            (a) => a.value ===
                              list?.measurementUnitId
                          )}
                          onChange={(item) =>
                            quantityUnit(item, index)
                          }
                          maxMenuHeight={160}
                        />
                        <Validator
                          data={list?.measurementUnitId}
                          errorMessage="Please select a unit"
                          groupName={validationGroup}
                          dataKey={"measurementUnitId"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Quantity
                      </label>{" "}
                      <div className="form-control-wrap">
                        <input
                          defaultValue={list?.quantity}
                          type="text"
                          data-msg="Required"
                          className="form-control required"
                          id="fw-last-name"
                          name="fw-last-name"
                          onChange={async (e) =>
                            bulkItemQuantity(e.target, index)
                          }
                        />
                        <Validator
                          data={list?.quantity}
                          errorMessage="Please fill quantity"
                          groupName={validationGroup}
                          dataKey={"quantity"}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Specific Gravity
                      </label>
                      <input
                        defaultValue={list?.specificGravity}
                        type="text"
                        data-msg="Required"
                        className="form-control required"
                        id="fw-last-name"
                        name="fw-last-name"
                        onChange={async (e) =>
                          specificGravityOfItem(e.target, index)
                        }
                      />
                      <Validator
                        data={list?.specificGravity}
                        errorMessage="Please fill Specific Gravity"
                        groupName={validationGroup}
                        dataKey={"specificGravity"}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fw-last-name">
                        Customs Status
                      </label>
                      <div className="form-control-wrap">
                        <div className="form-control-wrap">
                          <Select
                            options={customsStatus.map((a) => ({
                              ...a,
                              label: a.label,
                              value: a.value,
                            }))}
                            value={customsStatus.map((a) => ({
                              ...a,
                              label: a.label,
                              value: a.value,
                            })).find(
                              (a) => a.value ===
                                list?.customStatus
                            )}
                            onChange={(item) => {
                              customsStatusOfItem(item, index);
                            }}
                          />
                          <Validator
                            data={list?.customStatus}
                            errorMessage="Please select custom status"
                            groupName={validationGroup}
                            dataKey={"customStatus"}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="gap" />
                      <em
                        class="icon ni ni-trash-alt text-danger fs-16px pointer mr-2 p-1"
                        title="Discard"
                        onClick={() => removeBulkItem(index)}
                      > delete entry</em>
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                title="click to add bulk cargo"
                className="btn btn-xs text-primary m-2 p-2"
                onClick={() =>
                  setBulkItemsList([...bulkItemsList, {}])
                }
              >
                <em className="icon ni ni-plus">  </em> Add bulk cargo
              </button>
            </div>

          ) : ""}

          <div>
            <hr className="preview-hr" />
            <span className="preview-title-lg overline-title">
              {" "}
              Booking Equipment Details{" "}
            </span>
            <div className="card" id="VS1">
              {bookingButtonClicked ? (
                <BookingEquipmentSheet
                  setClickedVal={setClickedVal}
                  setBookingData={setBookingData}
                  callback={(equipment) =>
                    receiveBookingEquipmentDetails(equipment)
                  }
                  bookingDataToEdit={bookingEquipmentContent}
                  allCargoCarryingEquipments={allCargoCarryingEquipments}
                  measurementUnitArray={measurementsPrime}
                  ownersList={ownersList}
                  cargoEquipmentForBooking={cargoEquipment}
                />
              ) : (
                <div>
                  <button
                    id="deckButton"
                    type="button"
                    onClick={() => toggleBookingEquipmentTable()}
                    className="btn btn-md btn-dark btn-wide px-5 mr-3"
                  >
                    Click to edit Booking Equipment
                  </button>
                </div>
              )}
            </div>
          </div>
          <hr className="preview-hr" />
          <span className="preview-title-lg overline-title">
            Deck Cargo Details{" "}
          </span>
          <div className="card" id="VS1">
            {deckButtonClicked ? (
              <DeckCargoSheet
                bookingData={bookingData}
                clickedVal={clickedVal}
                cargoEquipment={cargoEquipment}
                preferredEquipment={preferredCargoEquipment}
                deckCallBack={(list) => receiveDeckCargoDetails(list)}
                hazardClassList={hazardClassList}
                dataToEdit={deckCargoData}
                dgList={dgList}
                unCodeList={unCodeList}
                pgList={pgList}
                measurementUnitArray={measurementsPrime}
                entireBookingData={bookingEquipmentContent}
                newNewEntry={newEntireBookingData}
              />
            ) : (
              <div>
                <button
                  id="deckButton"
                  type="button"
                  onClick={() => toggleDeckCargo()}
                  className="btn btn-md btn-dark btn-wide px-5 mr-3"
                >
                  Click to fill Deck Cargo
                </button>
              </div>
            )}
            <hr className="preview-hr" id="HR1" />
          </div>
          {toggleCheckBox ? modalCheckBox() : !toggleCheckBox}

          {
            <div className="form-group py-2 my-3">
              <button
                id="toggleBtn"
                type="button"
                onClick={() => setToggleCheckBox(true)}
                className="btn btn-md btn-dark btn-wide px-5 mr-3"
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-sm btn-link m-1"
                onClick={() => window.history.back()}
              >
                Cancel
              </button>
            </div>
          }
        </form>
        {/* <hr className="preview-hr" /> */}
      </div>
    </MainLayout>
  );
}
