import React, { useEffect, useState } from "react";
import { Navigate, useParams, Link } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import CommercialService from "../../services/commercialService";
import ReactTooltip from "react-tooltip";
import CustomModal from "../../utils/customModal";
import { toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import VesselService from "../../services/vesselService";
import SetVesselHireComponent from "../vessel/SetVesselHireComponent";

export default function CommercialDetailsComponent({ commercial, callback }) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [initializing, setInit] = useState(true);
  const [loading, setLoading] = useState(true);
  // const [commercial, setCommercial] = useState({});
  const [offHireStatus, setOffHireStatus] = useState(
    commercial?.IsCurrentlyOffHire
  );
  const [hireModalIsClosed, setHireModalIsClosed] = useState(true);

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    // setInit(true);
    setInit(false);
  };

  const dataItem = (label, value) => {
    if (value == "null") value = "";
    return (
      <li className="col-6 col-lg-3">
        <p>
          <span className="text-soft small">{label}</span> {value || "-"}
        </p>
      </li>
    );
  };

  const triggerHireModal = async () => {
    setHireModalIsClosed(!hireModalIsClosed);
  };

  const renewalLabels = {
    one_off: "One Off",
    recurring: "Recurring",
    subject_to_approval: "Subject to Approval",
  };

  return (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <div className="card sp-plan">
          <div className="row no-gutters">
            <div className="col-md-8">
              <div className="sp-plan-info card-inner">
                <div className="row gx-0 gy-3">
                  <div className="col-xl-9 col-sm-8">
                    <div className="sp-plan-name">
                      <h6 className="title">
                        <Link
                          to={`/vessel/${commercial.VesselSpecificationId} `}
                        >
                          {commercial.VesselName}
                          {commercial.CommercialStatus && (
                            <span className="badge badge-light badge-pill ml-3">
                              {commercial.CommercialStatus}
                            </span>
                          )}
                        </Link>

                        {commercial?.CommercialStatus === "active" && (
                          <>
                            <span
                              className={`badge  badge-pill ml-3 ${
                                offHireStatus ? "badge-danger" : "badge-success"
                              } `}
                            >
                              {offHireStatus ? "off" : "on"}-hire
                            </span>
                            <span onClick={triggerHireModal}>
                              <em
                                className={`icon ni ml-1 pointer align-middle fs-22px  ${
                                  offHireStatus
                                    ? "ni-toggle-off text-danger"
                                    : "ni-toggle-on text-success"
                                }`}
                                title="toggle hire status"
                              />
                            </span>
                          </>
                        )}
                      </h6>
                      <p style={{ visibility: "hidden" }}>
                        '': <span className="text-base">{"-"}</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-4"></div>
                </div>
              </div>
              <div className="sp-plan-desc card-inner">
                <p>
                  <span className="preview-title-lg overline-title">
                    Commercial
                  </span>{" "}
                </p>
                <ul className="row gx-1">
                  {dataItem("Vessel Name", commercial.VesselName)}
                  {dataItem("Vessel Owner", commercial.OwnerPartyName)}
                  {dataItem("Charterer's Name", commercial.ChartererPartyName)}
                  {dataItem("Commercial Status", commercial.CommercialStatus)}
                  {dataItem("Contract Type", commercial.ContractType)}
                  {dataItem(
                    "Renewal Option",
                    renewalLabels[commercial.ContractOptions] || "-"
                  )}
                  {dataItem("Daily Rate", commercial.DailyRate)}
                  {dataItem("Currency", commercial.Currency)}
                  {dataItem(
                    "Contract Start Date",
                    toReadableDate(commercial.ContractStartDate) || "-"
                  )}
                  {dataItem(
                    "Contract Start Comments",
                    commercial.ContractStartComments
                  )}
                  {dataItem(
                    "Contract End Date",
                    toReadableDate(commercial.ContractEndDate) || "-"
                  )}
                  {dataItem(
                    "Contract End Comments",
                    commercial.ContractEndComments
                  )}
                </ul>
              </div>
            </div>
            <br />
          </div>
        </div>
      )}

      {!hireModalIsClosed && (
        <CustomModal
          title={`Set ${offHireStatus ? "On" : "Off"}-Hire`}
          isClosed={hireModalIsClosed}
          content={
            <SetVesselHireComponent
              vesselSpecId={commercial.VesselSpecificationId}
              callback={callback}
              vesselName={commercial.VesselName}
              isOffHire={offHireStatus}
              activeContract={commercial}
            />
          }
          onClose={() => triggerHireModal()}
        />
      )}

      {/* {!modalIsClosed && <CustomModal title="Edit Booking Request" isClosed={modalIsClosed}
                content={<EditVesselDetailsComponent bookingRequestId={bookingRequestId} callback={() => onModalClose()} />} onClose={() => onModalClose()} />} */}
    </>
  );
}
