import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class LocationService {
  //Complete unpaginated list
  static extensiveList = async () => {
    return await apiCall(`/api/Location/Get/v2`);
  };
  static dashboardAllocation = async (
    page,
    pageSize,
    dateFrom,
    dateTo,
    locationId
  ) => {
    page = page || 1;
    pageSize = pageSize || 10;
    dateFrom = dateFrom || "";
    dateTo = dateTo || "";
    locationId = locationId || "";
    return await apiCall(
      `/api/Location/Get/dashboard/installation-cost-allocated?pageCount=${page}&noPerPage=${pageSize}&locationId=${locationId}&dateFrom=${dateFrom}&dateTo=${dateTo}`
    );
  };
  static dashboardOffshoreInstallation = async (page, pageSize, query = "") => {
    page = page || 1;
    pageSize = pageSize || 10;
    return await apiCall(
      `/api/Location/GetMetrics/dashboard/offshore-installation-efficiency?pageCount=${page}&noPerPage=${pageSize}&${query}`
    );
  };

  static extensiveList2 = async () => {
    let response = await apiCall(`/api/Location/Get/v2`);
    if (response?.Data?.Data) {
      response = response?.Data?.Data;
    }
    return response;
  };
  //Complete unpaginated list
  static fullList = async () => {
    return await apiCall(`/api/Location/Get/v2`);
  }; //add new loca

  static list = async (page, pageSize) => {
    page = page || 1;
    pageSize = pageSize || 50;
    let response = await apiCall(
      `/api/Location/Get/v2?page=${page}&pageSize=${pageSize}`
    );
    if (response?.Data?.Data) {
      response = response?.Data.Data.sort(dynamicSort("LocationName"));
    }
    return response;
  };
  static search = async (
    page,
    pageSize,
    searchQuery,
    locationTypeId,
    isActive,
    useCache
  ) => {
    page = page || 1;
    pageSize = pageSize || 50;
    searchQuery = searchQuery || "";
    if (!useCache) useCache = false;
    return await apiCall(
      `/api/Location/search?pageCount=${page}&noPerPage=${pageSize}&query=${searchQuery}&locationTypeId=${
        locationTypeId || ""
      }&isActive=${isActive == null ? "" : isActive}`,
      "GET",
      null,
      null,
      false,
      useCache
    );
  };

  static allLocations = async (params) => {
    let response = await apiCall(
      `/api/Location/Get/v2` + (params ? params : "")
    );
    if (response?.Data?.Data) {
      response = response?.Data.Data.sort(dynamicSort("LocationName"));
    }
    return response;
  };

  static get = async (locationId) => {
    if (!locationId) return null;
    return await apiCall(`/api/Location/GetExtended/${locationId}`);
  };

  static update = async (locationId, payload) => {
    return await apiCall(
      `/api/Location/update/${locationId}`,
      "PUT",
      payload,
      null,
      true,
      false,
      true
    );
  };

  static updateZoneCoordinates = async (locationId, payload) => {
    return await apiCall(
      `/api/Location/UpdateCoordinates/${locationId}`,
      "POST",
      payload
    );
  };

  static post = async (payload) => {
    return await apiCall(
      `/api/Location/post`,
      "POST",
      payload,
      null,
      true,
      false,
      true
    );
  };
  static postZone = async (payload) => {
    return await apiCall(
      `/api/Location/AddLocation`,
      "POST",
      payload,
      null,
      false,
      false
      // true
      // false
    );
  };

  static geTerminalActivitiesList = async (
    page,
    pageSize,
    voyagePlanningId,
    locationId
  ) => {
    page = page || 1;
    pageSize = pageSize || 1000;
    voyagePlanningId = voyagePlanningId || "";
    const response = await apiCall(
      `/api/Location/GetTerminalById/${locationId}/${page}/${pageSize}?voyagePlanningId=${voyagePlanningId}`
    );
    return response?.Data;
  };

  //location types

  static listLocationTypes = async (page, pageSize, useCache) => {
    page = page || 1;
    pageSize = pageSize || 50;
    if (!useCache) useCache = false;
    const response = await apiCall(
      `/api/LocationTypes/Get?pageCount=${page}&noPerPage=${pageSize}`,
      "GET",
      null,
      null,
      false,
      useCache
    );
    return response?.Data;
  };

  static getLocationType = async (id) => {
    if (!id) return null;
    return await apiCall(`/api/LocationTypes/Get/${id}`)?.Data;
  };

  static allActiveVesselsPosition = async (id) => {
    return await apiCall(`api/VesselPosition/GetActiveVesselPositions/${id}`);
  };

  static updateLocationType = async (id, payload) => {
    return await apiCall(`/api/LocationTypes/Put/${id}`, "PUT", payload);
  };

  static postLocationType = async (payload) => {
    return await apiCall(`/api/LocationTypes/post`, "PUT", payload);
  };

  static searchPartyLocations = async (
    page,
    pageSize,
    searchQuery,
    locationTypeId,
    partyId,
    activeOnly,
    useCache
  ) => {
    page = page || 1;
    pageSize = pageSize || 50;
    searchQuery = searchQuery || "";
    if (!activeOnly) activeOnly = false;
    if (!useCache) useCache = false;
    return await apiCall(
      `/api/Location/search?pageCount=${page}&noPerPage=${pageSize}&query=${searchQuery}&locationTypeId=${
        locationTypeId || ""
      }&partyId=${partyId}&activeOnly=${activeOnly}`,
      "GET",
      null,
      null,
      false,
      useCache
    );
  };

  static changeLocationPoint = async (payload) => {
    return await apiCall(`/api/Location/Relocate`, "POST", payload);
  };

  static getLocationHistory = async (locationId) => {
    if (!locationId) return null;
    return await apiCall(
      `/api/Location/RelocationHistory?locationId=${locationId}`
    );
  };
}
