import React, { useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import LocationService from "../../services/locationService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ActivityService from "../../services/activityService";
import VoyageService from "../../services/voyageService";
import VesselService from "../../services/vesselService";
import ConfigurationService from "../../services/configurationService";
import { add } from "lodash";

export default function AddVoyageActivity() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [payload, setPayload] = useState({
    tankCleaning: [],
  });
  const [saving, setSaving] = useState(false);
  const [activities, setActivities] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);
  const [reloading, setReloading] = useState(false);
  const [voyage, setVoyage] = useState([]);
  const [availableTanks, setAvailableTanks] = useState([]);
  const [tanksToBeCleaned, setTanksToBeCleaned] = useState([]);
  const [cleaningRequirements, setCleaningRequirements] = useState([]);

console.log("tanksToBeCleaned", tanksToBeCleaned)
console.log("payload", payload)

  const activityCategoryOptions = [
    {
      label: "Direct Attributable",
      value: "Direct Attributable",
    },
    {
      label: "Indirect Attributable",
      value: "Indirect Attributable",
    },
    {
      label: "Non-Attributable",
      value: "Non-Attributable",
    },
  ];

  const productivityOfActivityOptions = [
    {
      label: "Productive",
      value: "Productive",
    },
    {
      label: "Avoidable Non-Productive",
      value: "Avoidable Non-Productive",
    },
    {
      label: "Unavoidable Non-Productive",
      value: "Unavoidable Non-Productive",
    },
  ];

  const activityTypeOptions = [
    {
      label: "Planned",
      value: "planned",
    },
    {
      label: "Unplanned",
      value: "unplanned",
    },
  ];

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "add_voyage_activity";

  useEffect(async () => {
    await init();

    return () => {
      ClearValidator(validationGroup);
    };
  }, []);

  const init = async () => {
    setLoading(true);

    let _locationTypes = await LocationService.listLocationTypes(1, 1000);
    let excludedLocationTypes = (_locationTypes || []).filter(
      (a) =>
        a.LocationType?.toLowerCase() == "zone" ||
        a.LocationType?.toLowerCase() == "port terminal-berth" ||
        a.LocationType?.toLowerCase() == "port terminal-warehouse location" ||
        a.LocationType?.toLowerCase() == "logistics base-warehouse location" ||
        a.LocationType?.toLowerCase() == "logistics base-berth"
    );
    setLocationTypes(_locationTypes || []);
    const excludedLocationTypeIds = excludedLocationTypes?.map(
      (locationType) => locationType.LocationTypeId
    );

    let _locations = await LocationService.list(1, 1000);
    _locations = (_locations || []).map((e) => ({
      ...e,
      label: e.LocationName,
      value: e.LocationId,
    }));
    if (excludedLocationTypeIds) {
      //activities cannot be performed at an excluded location
      _locations = _locations.filter(
        (a) => !excludedLocationTypeIds.includes(a.LocationTypeId)
      );
    }
    setLocations(_locations || []);

    let _activities = await ActivityService.activitiesList(1, 200);
    _activities = (_activities || [])
      .map((e) => ({
        ...e,
        label: e.ActivityName,
        value: e.ActivityId,
      }))
      .filter((activity) => activity?.ActivityName.toLowerCase() !== "sailing");
    setActivities(_activities);

    let _voyage = await VoyageService.get(params.voyageId);
    setVoyage(_voyage || []);

    let _cleaningRequirements = await ConfigurationService.getConfigCategory(
      "cleaningRequirements"
    );
    setCleaningRequirements(_cleaningRequirements || []);

    // let _tanks = await VesselService.listTanks(_voyage?.VesselSpecificationId);
    // let tankList = _tanks.map((l) => ({
    //   ...l,
    //   literMeasure: `${l.CapacityMeasure}${l.unit}`,
    // }));
    // setAvailableTanks(tankList || []);

    setLoading(false);
  };

  const submitForm = async () => {
    addPayload("tankCleaning", tanksToBeCleaned);
    const _payload = { ...payload };

    if (!_payload.activityId) {
      toastr("error", "Select activity");
      return;
    }
    if (!_payload.activityCategory) {
      toastr("error", "Select activity category");
      return;
    }
    if (!_payload.plannedStartDate) {
      toastr("error", "Set a planned start date");
      return;
    }
    if (!_payload.endDate) {
      toastr("error", "Set a planned end date");
      return;
    }

    if (!_payload.productivityOfActivity) {
      _payload.productivityOfActivity = "Productive";
    }

    if (!_payload.activityType) {
      toastr("error", "Select activity type");
      return;
    }

    if (
      _payload.activityName == "Port Tank Cleaning" &&
      tanksToBeCleaned.length == 0
    ) {
      toastr("error", "Select tank(s) to be cleaned");
      return;
    }

    if (
      _payload.activityName !== "Port Tank Cleaning" &&
      tanksToBeCleaned.length !== 0
    ) {
      toastr("error", "Select tank cleaning activity");
      return;
    }

    _payload.voyagePlanningId = params.voyageId;

    const validationFailed = tanksToBeCleaned.find(
      (tank) =>
        tank.shipSideEquipmentId == null || tank.cleaningConfigurationId == null
    );
    if (validationFailed) {
      toastr("error", " Selected tanks missing some information");
      return;
    }

    delete _payload.activityName;

    setSaving(true);
    const response = await ActivityService.postVoyageActivity(_payload);
    if (response) {
      setPayload({});
      toastr("success", "Activity added successfully");
      reloadPage();
    }
    setSaving(false);
  };

  const reloadPage = () => {
    setReloading(true);
    setTimeout(() => {
      setReloading(false);
    }, 200);
  };

  const selectTank = (value, index) => {
    let _tanks = tanksToBeCleaned;
    _tanks[index]["shipSideEquipmentId"] = value.value;
    setTanksToBeCleaned(_tanks);
  };

  const selectCleaningStandard = (value, index) => {
    let _tanks = tanksToBeCleaned;
    _tanks[index]["cleaningConfigurationId"] = value.value;
    setTanksToBeCleaned(_tanks);
  };

  const removeTank = (index) => {
    let _tanks = [...tanksToBeCleaned];
    _tanks.splice(index, 1);
    setTanksToBeCleaned(_tanks);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <a
              href={`/voyage-activities/${params.voyageId}`}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Go to Activities List</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Add Voyage Activity"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      {!loading && (
        <>
          {reloading ? (
            <Spinner />
          ) : (
            <form>
              <div className="card-inner">
                <div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-5">
                      <div className="form-group " style={{ zIndex: "100" }}>
                        <label className="form-label small">Activity </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(item) =>
                              addPayload(
                                ["activityName", "activityId"],
                                [item.label, item.value]
                              )
                            }
                            value={activities.find(
                              (a) => a.value == payload?.activityId
                            )}
                            options={activities}
                            placeholder="select activity"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-5">
                      <div className="form-group mt-4" style={{ zIndex: "70" }}>
                        <label className="form-label small">
                          Activity Type{" "}
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(item) =>
                              addPayload(["activityType"], [item.value])
                            }
                            value={activityTypeOptions.find(
                              (a) => a.value == payload?.activityType
                            )}
                            options={activityTypeOptions}
                            placeholder="select type"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-5">
                      <div className="form-group mt-4" style={{ zIndex: "20" }}>
                        <label className="form-label small">Category </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(item) =>
                              addPayload(["activityCategory"], [item.value])
                            }
                            value={activityCategoryOptions.find(
                              (a) => a.value == payload?.activityCategory
                            )}
                            options={activityCategoryOptions}
                            placeholder="select category"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-5">
                      <div className="form-group mt-4" style={{ zIndex: "10" }}>
                        <label className="form-label small">
                          Productivity Of Activity{" "}
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(item) =>
                              addPayload(
                                ["productivityOfActivity"],
                                [item.value]
                              )
                            }
                            value={productivityOfActivityOptions.find(
                              (a) => a.value == payload?.productivityOfActivity
                            )}
                            options={productivityOfActivityOptions}
                            placeholder="select productivity level"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 mt-4 col-lg-5">
                      <div className="form-group" style={{ zIndex: "9" }}>
                        <label className="form-label small">
                          Activity location{" "}
                        </label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(item) =>
                              addPayload(["locationId"], [item.value])
                            }
                            value={locations.find(
                              (a) => a.value == payload?.locationId
                            )}
                            options={locations}
                            placeholder="select location"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-6 mt-4 col-lg-5">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group " style={{ zIndex: "8" }}>
                            <label className="form-label small">
                              Planned start at{" "}
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="datetime-local"
                                className="form-control"
                                onChange={(e) =>
                                  addPayload(
                                    ["plannedStartDate"],
                                    [e.target.value]
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group " style={{ zIndex: "8" }}>
                            <label className="form-label small">
                              Planned to end at{" "}
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="datetime-local"
                                className="form-control"
                                onChange={(e) =>
                                  addPayload(["endDate"], [e.target.value])
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gap" />

                  {payload.activityName == "Port Tank Cleaning" ? (
                    <div className="row">
                      <div className="col-sm-12 col-md-6 mt-4 col-lg-5">
                        <span>
                          <h6>
                            Attach Tanks (only applicable to Port Tank Cleaning)
                          </h6>
                        </span>
                        <div className="gap" />
                        {tanksToBeCleaned.map((tank, index) => (
                          <>
                            <div
                              className="form-control-wrap d-flex align-center mb-2"
                              key={index}
                            >
                              <div
                                className="form-group col mb-0 px-2"
                                style={{ zIndex: 100 - index }}
                              >
                                <label className="form-label small">Tank</label>
                                <Select
                                  onChange={(item) => selectTank(item, index)}
                                  value={availableTanks
                                    .map((c) => ({
                                      ...c,
                                      label:
                                        c.tankType + " - " + c.literMeasure,
                                      value: c.ShipSideEquipmentId,
                                    }))
                                    .find(
                                      (a) =>
                                        a.value == payload?.shipSideEquipmentId
                                    )}
                                  options={availableTanks.map((c) => ({
                                    ...c,
                                    label: c.tankType + " - " + c.literMeasure,
                                    value: c.ShipSideEquipmentId,
                                  }))}
                                  placeholder="select tank"
                                />
                              </div>

                              <div
                                className="form-group col mb-0 px-2"
                                style={{ zIndex: 100 - index }}
                              >
                                <label className="form-label small">
                                  Cleaning Requirement
                                </label>
                                <Select
                                  onChange={(item) =>
                                    selectCleaningStandard(item, index)
                                  }
                                  value={cleaningRequirements
                                    .map((c) => ({
                                      ...c,
                                      label: c.CorrespondingData,
                                      value: c.ConfigurationId,
                                    }))
                                    .find(
                                      (a) =>
                                        a.value ==
                                        payload?.cleaningConfigurationId
                                    )}
                                  options={cleaningRequirements.map((c) => ({
                                    ...c,
                                    label: c.CorrespondingData,
                                    value: c.ConfigurationId,
                                  }))}
                                  placeholder="select standard"
                                />
                              </div>
                              <div>
                                <div className="gap" />
                                <em
                                  class="icon ni ni-cross text-danger fs-22px pointer mx-3"
                                  title="Discard"
                                  onClick={() => removeTank(index)}
                                ></em>
                              </div>
                            </div>

                            {/* <div className="row" key={index}>
                              <div className="col">
                                <div
                                  className="form-group "
                                  style={{ zIndex: 100 - index }}
                                >
                                  <label className="form-label small">
                                    Tank
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select
                                      onChange={(item) =>
                                        selectTank(item, index)
                                      }
                                      value={availableTanks
                                        .map((c) => ({
                                          ...c,
                                          label:
                                            c.tankType + " - " + c.literMeasure,
                                          value: c.ShipSideEquipmentId,
                                        }))
                                        .find(
                                          (a) =>
                                            a.value ==
                                            payload?.shipSideEquipmentId
                                        )}
                                      options={availableTanks.map((c) => ({
                                        ...c,
                                        label:
                                          c.tankType + " - " + c.literMeasure,
                                        value: c.ShipSideEquipmentId,
                                      }))}
                                      placeholder="select tank"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col">
                                <div
                                  className="form-group "
                                  style={{ zIndex: 100 - index }}
                                >
                                  <label className="form-label small">
                                    Cleaning Requirement
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select
                                      onChange={(item) =>
                                        selectCleaningStandard(item, index)
                                      }
                                      value={cleaningRequirements
                                        .map((c) => ({
                                          ...c,
                                          label: c.CorrespondingData,
                                          value: c.ConfigurationId,
                                        }))
                                        .find(
                                          (a) =>
                                            a.value ==
                                            payload?.cleaningConfigurationId
                                        )}
                                      options={cleaningRequirements.map(
                                        (c) => ({
                                          ...c,
                                          label: c.CorrespondingData,
                                          value: c.ConfigurationId,
                                        })
                                      )}
                                      placeholder="select standard"
                                    />
                                    <em
                                      class="icon ni ni-cross text-danger fs-22px pointer mx-3"
                                      title="Discard"
                                      // onClick={discardChanges}
                                    ></em>
                                  </div>
                                </div>
                              </div>
                              <div className="col-2">
                                <div
                                  className="form-group "
                                  style={{ zIndex: 99 - index }}
                                >
                                  <button
                                    type="button"
                                    onClick={() => removeTank(index)}
                                    className="btn btn-md text-danger py-2 my-4"
                                  >
                                    <em className="icon ni ni-cross"></em>
                                  </button>
                                </div>
                              </div>
                            </div> */}
                          </>
                        ))}
                        <button
                          type="button"
                          title="Add Tank"
                          className="btn btn-xs text-primary mt-2"
                          onClick={() =>
                            setTanksToBeCleaned([...tanksToBeCleaned, {}])
                          }
                        >
                          <em className="icon ni ni-plus"></em> Add Tank
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {saving && (
                <div className="form-group p-2 m-3">
                  <button type="button" className="btn btn-md btn-link m-1">
                    <Spinner size="1.5" />
                  </button>
                </div>
              )}
              {!saving && (
                <div className="form-group p-2 m-3">
                  <button
                    type="button"
                    onClick={() => submitForm()}
                    className="btn btn-md btn-dark btn-wide px-5 mr-3"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-link m-1"
                    onClick={() => window.history.back()}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </form>
          )}
        </>
      )}
    </MainLayout>
  );
}
