import React, {useEffect, useState} from "react";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import EquipmentService from "../../services/equipmentService";
import Spinner from "../../utils/spinner";

export default function EquipmentListComponent({dataList, reload, deleteAction, vesselSpecId ="" }) {
 

    const [tableData, setTableData] = useState((dataList || []));
    const [loading, setLoading] = useState(true);

    const headerList = [
      {
        Header: "Equipment Name",
        accessor: "EquipmentName",
      },
      {
        Header: "Capacity Measure",
        accessor: "CapacityMeasure",
      },
      {
        Header: "Description",
        accessor: "Description",
      }
      
    ];

    
    useEffect(async ()=>{
      setLoading(true);
      if(vesselSpecId) await retrieveEquipmentList();
      else setTableData((dataList || []));

      setLoading(false);
    }, [dataList])

    
  const retrieveEquipmentList = async () => {
    setLoading(true);
    const types = await EquipmentService.listVesselEquipment(vesselSpecId);
    setTableData(types || []);
    setLoading(false);
  };
    return (
      <div style={{ zoom: "0.85" }}>
        {loading? <Spinner/>: <MyTable
          columns={headerList}
          cssArray={[
            "font-weight-500",
            "",
            "",
            "text-right", 
          ]}
          data={tableData}
          reloadData={(page, pageSize, query) => reload && reload(page, pageSize, query)
          }
          enableSearch={false} deleteAction={deleteAction} emptyMessage="You have not added any equipment"
        />}
      </div>
    );
  }