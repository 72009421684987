import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import LocationService from "../../services/locationService";
import Select from "react-select";
import SelectPartyComponent from "../../components/party/selectParty";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import CustomModal from "../../utils/customModal";
import LocationDetailsComponent from "../../components/location/locationDetails";
import Paginate from "../../components/paginate";
import PersonWithInitial from "../../components/personWithInitial";
import MapView from "../../components/mapView";
import { width } from "dom-helpers";
import { exportToCSV } from "../../utils/exportCSV";
import MapView2 from "../../components/mapView2";
import { Popup } from "react-leaflet";
import { checkUserPermission } from "../../utils/utilityFunctions";
import CountryService from "../../services/countriesService";

export default function LocationsListPage() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [locationTypes, setLocationTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const allLocations = useRef();
  const [pages, setPages] = useState([10, 20, 50, 100, 150]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [totalRecords, setTotalRecords] = useState(10);
  const [mapObject, setMapObject] = useState(null);
  const [isActiveFilter, setIsActiveFilter] = useState(null);
  const [countries, setCountries] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [locationTypeId, setLocationTypeId] = useState("");

  const isActiveDropdownOptions = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];

  // filter out these location types from the dropdown because the user should not be able to create these from the frontend
  const [locationTypesToBeFilteredOut] = useState([
    "field",
    "port terminal-berth",
    "port terminal-warehouse location",
    "logistics base-berth",
    "logistics base-warehouse location",
  ]);

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);
    let _locationTypes = await LocationService.listLocationTypes();

    // filter out these location types from the dropdown because the user should not be able to create these from the frontend
    _locationTypes = _locationTypes
      ?.map((f) => ({
        ...f,
        label: f.LocationType,
        value: f.LocationTypeId,
      }))
      .filter(
        (_type) =>
          !locationTypesToBeFilteredOut.includes(
            _type.LocationType?.toLowerCase()
          )
      );

    setLocationTypes(_locationTypes || []);

    const _allLocations = await LocationService.allLocations();
    allLocations.current = _allLocations || [];

    let _countries = await CountryService.listCountry();
    if (_countries) {
      _countries = _countries.map((c) => ({
        ...c,
        label: c.CountryName,
        value: c.CountryId,
      }));
      setCountries(_countries);
    }

    await loadData();

    setLoading(false);
  };

  const loadData = async (
    _page,
    _pageSize,
    _search = "",
    _locationTypeId = "",
    _isActive = ""
  ) => {
    _page = _page || page;
    _pageSize = _pageSize || pageSize;
    // _search = _search || search;

    if (_search) {
      _page = 1;
    }

    setLoading(true);

    const _locations = await LocationService.search(
      _page,
      _pageSize,
      _search,
      _locationTypeId,
      _isActive
    );
    let modifiedDataList = _locations?.Data || [];
    setTotalRecords(_locations?.TotalCount || 0);
    modifiedDataList = modifiedDataList.map((l) => ({
      ...l,
      coordComponent:
        !l.Longitude || !l.Latitude
          ? null
          : `Lat: ${l.Latitude}°, Long: ${l.Longitude}°`,
      country: countries.find((country) => country.CountryId === l.CountryId),
      actionsComponent: overflowMenu(l),
      partyComponent: <PersonWithInitial name={l.PartyName} />,
    }));

    let dataToExport = modifiedDataList.map((l) => ({
      Location: l.LocationName,
      "Location Type": l.LocationTypeName,
      Party: l.PartyName || "N/A",
      "Parent Location": l.ParentLocationName || "N/A",
      Longitude: l.Longitude || "N/A",
      Latitude: l.Latitude || "N/A",
      Country: l.country || "N/A",
      City: l.City || "N/A",
      "Is Open 24 Hours": l.IsOpen24Hours ? "Yes" : "No",
      "Opening Time": l.OpeningTime || "N/A",
      "Closing Time": l.ClosingTime || "N/A",
      "Is Active": l.IsActive ? "Yes" : "No",
    }));

    setExportData(dataToExport);
    setLocations(modifiedDataList);

    page != _page && setPage(_page);
    pageSize != _pageSize && setPageSize(_pageSize);

    setLoading(false);
  };

  const overflowMenu = (location) => {
    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                {checkUserPermission("location_view_details") && (
                  <li
                    className="clickable"
                    onClick={() => setSelectedRecord(location)}
                  >
                    <a>
                      <em className="icon ni ni-eye" />
                      <span>View Details</span>
                    </a>
                  </li>
                )}
                {checkUserPermission("location_edit_location_details") &&
                  location.DateMoved == null && (
                    <li>
                      <Link to={`/edit-location/${location.LocationId}`}>
                        <em className="icon ni ni-file-docs" />
                        <span>Edit location details</span>
                      </Link>
                    </li>
                  )}
                {/* <li><Link to={"#"}><em class="icon ni ni-edit" onclick="return false"></em>Edit Vessel Ownership</span></a></li> */}
                <li className="divider" />
                {checkUserPermission("location_view_on_map") && (
                  <li
                    className="clickable"
                    onClick={() => displayMap(location)}
                  >
                    <a>
                      <em className="icon ni ni-eye" />
                      <span>View on map</span>
                    </a>
                  </li>
                )}
                {checkUserPermission(
                  "location_view_fleets_and_vessels_attached_to_a_zone"
                ) && (
                  <li>
                    <Link
                      to={`/location/${location.LocationId}/fleets_vessels`}
                    >
                      <em className="icon ni ni-file-docs" />
                      <span>Fleets and vessels</span>
                    </Link>
                  </li>
                )}
                {checkUserPermission("activities_view_module") &&
                  (location.LocationTypeName == "Port Terminal" ? (
                    <li>
                      <Link to={`/terminal-activities/${location.LocationId}`}>
                        <em className="icon ni ni-list" />
                        <span>View Activities</span>
                      </Link>
                    </li>
                  ) : null)}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  const displayMap = (location) => {
    if (
      location.LocationTypeName != "Zone" &&
      (!location.Longitude || !location.Latitude)
    ) {
      toastr("error", "Map coordinates were not defined for this location");
      return;
    }

    // setMapObject({long: location.Longitude, lat: location.Latitude, location: location.LocationName,
    //width: 520, height: 250})

    let coords = allLocations.current.filter(
      // let coords = locations.filter(
      (loc) =>
        loc.Latitude != null &&
        loc.Longitude != null &&
        loc.LocationId !== location.LocationId
    );

    coords = coords.map((loc) => [loc.Latitude, loc.Longitude, loc]);
    let zoneCoords;

    if (location.LocationTypeName != "Zone") {
      coords.unshift([location.Latitude, location.Longitude, location]);
    } else {
      zoneCoords = location.coordinates.map((loc) => [loc.Lat, loc.Lng, loc]);
    }

    setMapObject({
      // markerPositions: [[location.Latitude, location.Longitude]],

      mapArea: location.LocationTypeName == "Zone" ? zoneCoords : null,
      markerOnClick: (loc) => setSelectedMarker(loc[2]),
      useChildren: true,

      markerPositions: coords,
      readOnly: true,
    });
  };
  const headerList = [
    {
      Header: "Location Name",
      accessor: "LocationName",
    },
    {
      Header: "Location Type",
      accessor: "LocationTypeName",
    },
    {
      Header: "Parent Location",
      accessor: "ParentLocationName",
    },
    {
      Header: "Coordinates",
      accessor: "coordComponent",
    },
    {
      Header: "Party",
      accessor: "partyComponent",
    },
    {
      Header: "",
      accessor: "actionsComponent",
    },
  ];

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {checkUserPermission("location_download_locations") && (
            <li>
              <a
                // href={`${process.env.REACT_APP_API}/api/Location/Get/v2?pageCount=1&authorization=${localStorage.token}`}
                href={`${process.env.REACT_APP_API}/api/Location/DownloadLocationCSV?authorization=${localStorage.token}`}
                className="btn btn-dim btn-secondary"
              >
                <em className="icon ni ni-download" />
                <span>Download Locations</span>
              </a>
            </li>
          )}
          {/* {checkUserPermission("location_export_locations") && locations && (
            <li>
              <button
                type="button"
                onClick={() => exportToCSV(exportData, "list_of_locations")}
                className="btn btn-white btn-outline-light"
              >
                <em className="icon ni ni-download-cloud" />
                <span>Export page</span>
              </button>
            </li>
          )} */}

          <li className="nk-block-tools-opt">
            <div className="drodown">
              <a
                href="#"
                className="dropdown-toggle btn btn-icon btn-primary"
                data-toggle="dropdown"
              >
                <em className="icon ni ni-plus" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <ul className="link-list-opt no-bdr">
                  {checkUserPermission("location_add_new_location") && (
                    <li>
                      <Link to="/add-location">
                        <span>Add new location</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );

  const filterByLocationProperty = async () => {
    await loadData(1, null, search, locationTypeId, isActiveFilter);
  };

  const clearFilter = async () => {
    setSearch("");
    setLocationTypeId("");
    setIsActiveFilter(null);

    await loadData(1, null);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Locations"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <div className="p-3">
        <div className="card-inner position-relative card-tools-toggle">
          {checkUserPermission("location_filter_locations") && (
            <div className="card-title-group">
              <div className="card-tools">
                <div className="dropdown">
                  <a
                    href="#"
                    className="btn btn-trigger btn-icon dropdown-toggle "
                    data-toggle="dropdown"
                  >
                    <em className="icon ni ni-search" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
                    <form>
                      <div className="dropdown-head">
                        <span className="sub-title dropdown-title">
                          Filter Locations
                        </span>
                        <div className="dropdown">
                          <a
                            className="btn btn-sm btn-icon"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-cross" title="close" />
                          </a>
                        </div>
                      </div>
                      <div className="dropdown-body dropdown-body-rg">
                        <div className="row gx-6 gy-3">
                          <div className="col-12">
                            <div
                              className="form-group"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <label className="overline-title overline-title-alt">
                                Location Type
                              </label>
                              <Select
                                className="border-transparent"
                                placeholder="Filter by Location Type"
                                value={locationTypes.find(
                                  (type) => type.value === locationTypeId
                                )}
                                options={locationTypes}
                                onChange={(option) =>
                                  setLocationTypeId(option.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-group">
                              <label
                                className="overline-title overline-title-alt"
                                htmlFor="from-date-filter"
                              >
                                Location Name
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                placeholder="Enter location name"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <div
                              className="form-group"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <label className="overline-title overline-title-alt">
                                Location Status
                              </label>
                              <Select
                                className="border-transparent"
                                placeholder="Filter by Location Status"
                                value={isActiveDropdownOptions.find(
                                  (status) => status.value === isActiveFilter
                                )}
                                options={isActiveDropdownOptions}
                                onChange={(option) =>
                                  setIsActiveFilter(option.value)
                                }
                              />
                            </div>
                          </div>

                          <div className="gap gap-10px" />

                          <div className="col-12">
                            <div className="form-group">
                              <button
                                type="button"
                                onClick={filterByLocationProperty}
                                className="btn btn-secondary"
                              >
                                <span>Filter</span>
                              </button>
                              <a
                                className="clickable ml-2"
                                onClick={() => clearFilter()}
                              >
                                Reset Filter
                              </a>
                            </div>
                          </div>
                          <div className="gap gap-50px" />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* .card-tools */}

              <div className="card-tools mr-n1">
                <ul className="btn-toolbar gx-1">
                  <li className="btn-toolbar-sep" />
                  {/* li */}

                  <li>
                    <div className="toggle-wrap">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle"
                        data-target="cardTools"
                      >
                        <em className="icon ni ni-menu-right" />
                      </a>
                      <div className="toggle-content" data-content="cardTools">
                        <ul className="btn-toolbar gx-1">
                          <li className="toggle-close">
                            <a
                              href="#"
                              className="btn btn-icon btn-trigger toggle"
                              data-target="cardTools"
                            >
                              <em className="icon ni ni-arrow-left" />
                            </a>
                          </li>
                          {/* li */}

                          <li>
                            <div className="dropdown">
                              <a
                                href="#"
                                className="btn btn-trigger btn-icon dropdown-toggle"
                                data-toggle="dropdown"
                              >
                                <em className="icon ni ni-setting" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                <ul className="link-check">
                                  <li>
                                    <span>Show</span>
                                  </li>
                                  {pages.map((item, index) => (
                                    <li
                                      onClick={() => loadData(null, item)}
                                      key={index}
                                      className={`${page == item && "active"}`}
                                    >
                                      <Link to={"#"}>{item}</Link>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                            {/* .dropdown */}
                          </li>
                        </ul>
                        {/* .btn-toolbar */}
                      </div>
                      {/* .toggle-content */}
                    </div>
                    {/* .toggle-wrap */}
                  </li>
                  {/* li */}
                </ul>
                {/* .btn-toolbar */}
              </div>
              {/* .card-tools */}
            </div>
          )}
        </div>
        {/* .card-inner */}
        {loading || (
          <>
            <DataTable
              columns={headerList}
              data={locations}
              cssArray={["font-weight-500", "", "", "text-left", "", ""]}
              enableSearch={false}
              emptyMessage="No location record to show on page"
              disablePageSize={true}
            />
            <div className="gap gap-15px" />
            {checkUserPermission("location_pagination") && (
              <div className="">
                <Paginate
                  onChange={(p) =>
                    loadData(p, null, search, locationTypeId, isActiveFilter)
                  }
                  currentPage={page}
                  pageSize={pageSize}
                  totalRecordsCount={totalRecords}
                />
              </div>
            )}
          </>
        )}
      </div>
      {selectedRecord && (
        <CustomModal
          title={selectedRecord.LocationName}
          content={
            <>
              <LocationDetailsComponent location={selectedRecord} />{" "}
              {selectedRecord.DateMoved == null && (
                <hr style={{ opacity: "0.5", marginBottom: "px" }} />
              )}
              <div className="row">
                {checkUserPermission("location_edit_location_details") &&
                  selectedRecord.DateMoved == null && (
                    <div className="col col-12 text-center">
                      <Link
                        to={`/edit-location/${selectedRecord.LocationId}`}
                        className="mt-2 btn btn-dark btn-dim btn-sm "
                      >
                        <span>Edit Location Details</span>
                      </Link>
                    </div>
                  )}

                {selectedRecord.LocationTypeName == "Port Terminal" ? (
                  <div className="col col-12 text-center">
                    <Link
                      to={`/terminal-activities/${selectedRecord.LocationId}`}
                      className="mt-2 btn btn-dark btn-dim btn-sm "
                    >
                      <span>View Terminal Activities</span>
                    </Link>
                  </div>
                ) : null}

                {/* {checkUserPermission(
                  "location_view_fleets_and_vessels_attached_to_a_zone"
                ) && (
                  <div className="col col-12 text-center">
                    <button
                      type="button"
                      className="mt-4 btn btn-dark btn-dim btn-sm "
                    >
                      <Link to={`/location/${selectedRecord.LocationId}`}>
                        <span>See Associated Vessels and Fleets</span>
                      </Link>
                    </button>
                  </div>
                )} */}
              </div>
            </>
          }
          onClose={() => setSelectedRecord(null)}
          isClosed={false}
        />
      )}
      {mapObject && (
        <CustomModal
          title={mapObject.location}
          content={
            <MapView2 {...mapObject}>
              {selectedMarker && (
                <Popup
                  position={[selectedMarker.Latitude, selectedMarker.Longitude]}
                  onClose={() => {
                    setSelectedMarker(null);
                  }}
                >
                  <div>
                    <h5>Location Name: {selectedMarker.LocationName}</h5>
                    <p>Type : {selectedMarker?.LocationTypeName}</p>
                    <p>Party : {selectedMarker?.PartyName || "N/A"}</p>
                  </div>
                </Popup>
              )}
            </MapView2>
          }
          onClose={() => setMapObject(null)}
          isClosed={false}
          noContentPadding={true}
        />
      )}
    </MainLayout>
  );
}
