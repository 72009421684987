import React, { useEffect, useState } from "react";
import Spinner from "../../utils/spinner";
import MapView2 from "../mapView2";
import { Popup } from "react-leaflet";
import DateFilter from "../general/DateFilter";
import VesselService from "../../services/vesselService";
import {
  generateQueryString,
  toReadableDate,
} from "../../utils/utilityFunctions";
import EmptyRecords from "../general/EmptyRecords";

export default function VesselSailingHistory({ vesselSpecId = "" }) {
  const [loading, setLoading] = useState(true);
  const [mapObject, setMapObject] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [coords, setCoords] = useState([]);

  // methods
  const getSailingData = async (dates) => {
    setLoading(true);
    let payload = { vesselSpecificationId: vesselSpecId };
    if (dates) payload = { ...payload, ...dates };
    payload = generateQueryString(payload);
    const _res = await VesselService.getSailingHistoryData(payload);
    const data = _res?.response?.VesselPositions;
    if (!data) {
      setCoords([]);
      setLoading(false);
      return;
    }
    const mapData = data.map((location) => {
      return [location.Lat, location.Lon, { ...location, Lng: location.Lon }];
    });

    setCoords(mapData || []);
    setMapObject({
      mapArea: mapData,
      markerOnClick: (loc) => setSelectedMarker(loc[2]),
      markerPositions: mapData,
      readOnly: true,
      height: "100%",
    });

    setLoading(false);
  };

  useEffect(async () => {
    setLoading(true);
    await getSailingData();
    setLoading(false);
  }, []);

  return (
    <div style={{ zoom: "0.85" }}>
      <div className="d-flex justify-between mb-2">
        <h4>Vessel Utilization</h4>

        <DateFilter callback={getSailingData} />
      </div>
      {loading ? (
        <div className="center mt-5 pt-5">
          <Spinner />
        </div>
      ) : (
        mapObject && (
          <div>
            {!!coords.length ? (
              <div style={{ height: "calc(100vh - 150px)" }}>
                <MapView2 {...mapObject}>
                  {selectedMarker && (
                    <Popup
                      position={[
                        selectedMarker?.Lat || 0,
                        selectedMarker.Lng || 0,
                      ]}
                      onClose={() => {
                        setSelectedMarker(null);
                      }}
                    >
                      <div>
                        <h5>
                          Location: {selectedMarker.LocationName || "At Sea"}
                        </h5>
                        {selectedMarker?.LastPortName && (
                          <p>
                            Last Port:{" "}
                            {selectedMarker?.LocationTypeName || "N/A"}
                          </p>
                        )}

                        {selectedMarker?.LastPortName && (
                          <p>
                            Next Port :{selectedMarker?.NextPortName || "N/A"}
                          </p>
                        )}

                        <p>Activity : {selectedMarker?.StatusName || "N/A"}</p>
                        <p>Heading : {selectedMarker?.Heading || "N/A"}</p>
                        <p>Course : {selectedMarker?.Course || "N/A"}</p>
                        <p>
                          Date :{" "}
                          {toReadableDate(selectedMarker?.Timestamp) || "N/A"}
                        </p>
                      </div>
                    </Popup>
                  )}
                </MapView2>{" "}
              </div>
            ) : (
              <EmptyRecords message="No data for time frame" />
            )}
          </div>
        )
      )}
    </div>
  );
}
