import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class PartyService {
  static listParty = async () => {
    // let response = await apiCall(`/api/Parties/Get`);
    let response = await apiCall(`/api/Parties/get/v2`);

    if (response?.Data) {
      response?.Data.sort(dynamicSort("PartyName"));
    }

    return response?.Data;
  };

  static listPartyPaginated = async (
    currentPage = 1,
    PageSize = 10,
    query = "",
    contactQuery = "",
    country = "",
    contactRole = "",
    orderByAsc
  ) => {
    return await apiCall(
      `/api/Parties/List?pageCount=${currentPage}&noPerPage=${PageSize}&searchParam=${query}&orderByAsc=${orderByAsc}&cSearchParam=${contactQuery}&contactRole=${contactRole}&country=${country}`
    );
  };

  static CountParties = async () => {
    return await apiCall(`/api/Parties/CountParties`);
  };

  static getPartyWithContactPerson = async (partyId) => {
    return await apiCall(`/api/Parties/GetExtended/${partyId}`);
  };

  static postParty = async (payload) => {
    return await apiCall(`/api/Parties/Post`, "POST", payload);
  };

  static listPartyTypes = async () => {
    const response = await apiCall(`/api/PartyTypes`);
    return response?.Data;
  };

  static postVesselParties = async (
    partyTypeId,
    partyId,
    vesselSpecificationId
  ) => {
    const payload = { partyTypeId, partyId, vesselSpecificationId };
    return await apiCall(`/api/PartyVessel/Post`, "POST", payload);
  };

  static getVesselParties = async () => {
    return await apiCall(`/api/PartyVessel/Get`);
  };

  static listByVesselId = async (vesselSpecificationId) => {
    return await apiCall(`/api/parties/listforvessel/${vesselSpecificationId}`);
  };

  static contactPersonList = async () => {
    return await apiCall(`/api/ContactPerson/Get`);
  };

  static contactPersonRole = async () => {
    return await apiCall(`/api/ContactPersonRole/Get`);
  };

  static partyRoles = async () => {
    return await apiCall(`api/Roles`);
  };
  static postEditedParty = async (id, payload) => {
    return await apiCall(`api/parties/Put/${id}`, "PUT", payload);
    //http://parties/Put/
  };

  static updatePartyVessel = async (id, payload, newPartyId) => {
    payload.partyId = newPartyId;
    return await apiCall(`api/partyVessel/Put/${id}`, "PUT", payload);
  };

  static getPartyVessels = async (partyId) => {
    let response = await apiCall(
      `/api/parties/GetPartyVessels?partyId=${partyId}`
    );
    if (response) {
      response = response.map((e) => ({ ...e.specification, ...e }));
    }
    console.log(response);
    return response;
  };
}
