import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class ComplianceService {
  static issuerTypesList = async () => {
    let response = await apiCall(
      `/api/issuertype/get`,
      "GET",
      null,
      null,
      false,
      true
    );

    if (response?.Data) {
      response = response.Data.sort(dynamicSort("IssuerName"));
    }

    return response;
  };

  static fetchCertificate = async () => {
    return await apiCall(`/api/Certificates/Get`);
  };
  static certificateIssuer = async () => {
    let issuers = await apiCall(`/api/Issuer/Get`);
    console.log("test ", issuers);

    if (issuers?.Data) {
      issuers?.Data.sort(dynamicSort("IssuerName"));
    }

    return issuers;
  };
  static fetchValidityPeriod = async () => {
    return await apiCall(`/api/ValidityPeriod/Get`);
  };
  static postCertificate = async (payload) => {
    return await apiCall(
      `/api/ScheduleOfVesselCertificate/Post`,
      "POST",
      payload
    );
  };
  static certificateValidityList = async () => {
    return await apiCall(`/api/ScheduleOfVesselCertificate/Get`);
  };

  static certificateStatus = async () => {
    return await apiCall(`/api/Status/Get`);
  };
  static postEditVessel = async (id, payload) => {
    return await apiCall(
      `/api/ScheduleOfVesselCertificate/Put/${id}`,
      "PUT",
      payload
    );
  };
}
