import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from "react-router-dom";
import MainLayout from '../../layouts/mainLayout';
import Tabs from '../../utils/tabs';
import VesselDetailsComponent from '../../components/vessel/vesselDetailsComponent';
import VesselPartiesComponent from '../../components/vessel/vesselPartiesComponent';
import ComplianceComponent from '../../components/vessel/complianceComponent';
import CustomModal from '../../utils/customModal';
import LocationDetailsComponent from '../../components/location/locationDetails';
import VesselService from '../../services/vesselService';
import VesselListComponent from '../../components/vessel/vesselListComponent';
import LocationService from '../../services/locationService';
import MapView from '../../components/mapView';
import FleetService from '../../services/fleetService';

export default function FullLocationViewPage() {
    const params = useParams(); //we are expecting params.locationId, fleets_vessels

    const [redirectUrl, setRedirectUrl] = useState(null);
    const [locationVessels, setLocationVessels] = useState({});
    const [_tabView, setTabView] = useState(null);
    const [location, setLocation] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedFleet, setSelectedFleet] = useState(null);



    useEffect(async () => {
        await init();

    }, [selectedFleet]);

    const displayMap = (location) => {

        return {
            long: location.Longitude, lat: location.Latitude, location: location.LocationName,
            width: 520, height: 250
        };
    }

    const init = async () => {
        setLoading(true);
        const _locationVessels = await VesselService.listByLocation(params.locationId);
        setLocationVessels(_locationVessels || {});

        const _location = await LocationService.get(params.locationId);
        setLocation(_location || {});

        const _fleets = await FleetService.listByLocation(params.locationId);

        const mapObject = displayMap(_location || {});



        const tabContent = [
            <>
                <div className="row no-gutters">
                    <div className="col p-0 my-4">
                        <LocationDetailsComponent locationId={params.locationId} hideImage={true} />

                    </div>
                    <div className="col">
                        {
                            mapObject && <MapView {...mapObject} />
                        }
                    </div>

                </div>
            </>,
            <FleetsAndVessels _apiResponse={(_locationVessels || {})} fleets={_fleets?.Data || []} />
        ];

        const __tabView = (
            <Tabs
                headers={tabHeaders}
                contents={tabContent} contentPadding="4" _firstIndex={params.fleets_vessels? 1: 0}
            />
        );

        setTabView(__tabView);

        setLoading(false);
    }

    const tabHeaders = [
        { icon: "icon ni ni-view-grid-wd", text: "Details" },
        { icon: "icon ni ni-files", text: "Fleets and Vessels" },
    ];




    const FleetsAndVessels = ({ _apiResponse, fleets }) => {
        const vesselsToDisplay = (_apiResponse?.Data || []).filter(v=> v.fleet == (selectedFleet? selectedFleet: v.fleet));
        const vesselCount = (_apiResponse?.Data || [])

        return <>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-6 mb-4">
                    <div className="analytic-ov"><div className="analytic-data-group analytic-ov-group g-3">
                        <div className="analytic-data analytic-ov-data">
                            <div className="title">Fleets</div><div className="amount">{fleets.length}</div>
                        </div>
                        <div className="analytic-data analytic-ov-data"><div className="title">Vessels </div>
                            <div className="amount">{vesselCount.length || 0}</div>
                           
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">

                {<div className="col-auto">
                    <span className="preview-title overline-title">Fleets {selectedFleet && <button type="button" onClick={()=>setSelectedFleet(null)} className="btn btn-xs btn-dim btn-light btn-round ml-3"> x Reset filter</button>}</span>
                    {fleets && fleets.length > 0 && <div className="dropdown-preview"><div className="dropdown-menu border-0" style={{ boxShadow: "none" }} >
                        <ul className="link-list-opt no-bdr">
                            {
                                fleets.map((f, index) => <li key={index} className={selectedFleet == f.FleetName? "active":""}><a className="clickable" onClick={()=>setSelectedFleet(f.FleetName)}><span>{f.FleetName}</span></a></li>)
                            }

                        </ul>
                    </div>
                    </div>}
                    {
                        (!fleets || fleets.length <= 0) && <p className="text-muted" style={{ maxWidth: "175px" }}> No fleets configured for this location yet</p>
                    }
                </div>}
                <div className="col border-left-1 border border-right-0 border-top-0 border-bottom-0">
                    <span className="preview-title overline-title">Vessels <span className="text-primary"> {selectedFleet && `for ${selectedFleet}`}</span></span>
                    {vesselsToDisplay && vesselsToDisplay.length > 0 && <VesselListComponent _apiResponse={{Data: vesselsToDisplay, TotalCount: vesselsToDisplay?.length}} />}

                    {
                        (!vesselsToDisplay || vesselsToDisplay.length <= 0) && <p className="text-muted" > No vessels to show</p>
                    }
                </div>
            </div></>
    }

    const pageActions =
        <div className="toggle-wrap nk-block-tools-toggle">
            <Link to={"#"} className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r" /></Link>
            <div className="toggle-expand-content" data-content="pageMenu">
                <ul className="nk-block-tools g-3">
                    <li><Link to="/locations" className="btn btn-dim btn-secondary"> <span>{"<"} Back to locations</span></Link>
                    </li>
                    <li><Link to={`/edit-location/${params.locationId}`} className="btn btn-white btn-outline-light"><em className="icon ni ni-edit" /><span>Edit location</span></Link>
                    </li>

                </ul>
            </div>
        </div>

    return (
        redirectUrl ? <Navigate to={redirectUrl} replace={true} /> :
            <MainLayout title={location?.LocationName || "Location Details "} loading={loading} pageActions={pageActions}>
                <div className="">{_tabView}</div>
            </MainLayout >
    );
}
