import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import { Validator, ValidateGroup, ClearValidator } from "../validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CustomModal from "../../utils/customModal";
import {
  toReadableDate,
  dateToYYYY_MM_DD,
  camelizeKeys,
} from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import RoleService from "../../services/roleService";
import UserService from "../../services/userService";
import VesselService from "../../services/vesselService";
import LocationService from "../../services/locationService";

export default function AssignUserRoleComponent({ userRoleIds, userId }) {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    userId: userId,
    // roles: userRoleIds,
  });
  const [roles, setRoles] = useState([]);
  const [addedUserRoles, setAddedUserRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [saving, setSaving] = useState(false);
  const localizer = momentLocalizer(moment);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const [reloading, setReloading] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState({});

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "adduserrole";
  // console.log("payload", payload);
  // console.log("userRoles", userRoleIds);

  useEffect(async () => {
    await retrieveAllRoles();

    return () => {
      ClearValidator(validationGroup);
    };
  }, []);

  const retrieveAllRoles = async () => {
    setLoading(true);

    let _user = await UserService.getUserById(userId);
    let _userRoles = camelizeKeys(_user?.Roles || []);
    setAddedUserRoles(_userRoles || []);

    let _userRoleIds = _userRoles?.map((userRole) => userRole.roleId);

    let _roles = await RoleService.getAllRolesPaginated(1, 1000);
    _roles = camelizeKeys(_roles?.Data || [])
      ?.map((role) => ({
        ...role,
        label: role.isSystemRole ? `${role.name}` : `${role.name} (NS)`,
        value: role.roleId,
      }))
      .filter((role) => !_userRoleIds.includes(role.roleId));
    setRoles(_roles || []);

    setLoading(false);
  };

  const discardChanges = async (selectedId) => {
    const _payload = { roleId: selectedId, userIds: [userId] };
    console.log(_payload);

    setLoading(true);
    const response = await RoleService.removeRolesFromUser(_payload);
    if (response) {
      toastr("success", "Role removed for user successfully");
      await retrieveAllRoles();
    } else {
      toastr("error", "Role could not be removed for user");
    }
    setLoading(false);
  };

  const dataItem = (data) => {
    return (
      <div className="col col-md-3  mb-4" key={data?.roleId}>
        <div className="form-control-wrap d-flex  align-center">
          <div className="form-group col pl-0">
            <div className="form-control-wrap d-flex align-center">
              {addedUserRoles.length > 1 && (
                <em
                  className="icon ni ni-trash text-danger fs-22px pointer mr-2"
                  title="Discard"
                  onClick={() => discardChanges(data.roleId)}
                ></em>
              )}
              <label className="form-label mb-0">{data.name}</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  payload.roles = [];
  payload.roles.push(...selectedRoles);
  payload.lastModifiedBy = JSON.parse(localStorage.resu)?.user?.Id;

  const submitForm = async () => {
    const _payload = { ...payload };

    if (_payload.roles.length == 0) {
      toastr("error", "Select user role(s)");
      return;
    }

    setSaving(true);

    const response = await UserService.assignRolesToUser(_payload);
    if (response) {
      _payload.roles = [];
      setSelectedRoles([]);
      toastr("success", "User roles updated successfully");
      await retrieveAllRoles();
    } else {
      toastr("error", "Role could not be added to user");
    }
    setSaving(false);
  };

  const reloadPage = () => {
    setReloading(true);
    setTimeout(() => {
      setReloading(false);
    }, 200);
  };

  const handleRoleSelect = (option) => {
    // console.log("role", option);
    // let selectedRoleId = [];
    let _roles = option?.map(function (role) {
      return role.value;
    });
    // console.log("roleIds", selectedRoleId);
    // console.log("rolesSelected", JSON.parse(localStorage.resu)?.user?.Id);
    setSelectedRoles(_roles || []);
  };

  return (
    <>
      {loading ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <>
          <form>
            {
              <div className="card-inner">
                <div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group" style={{ zIndex: "10" }}>
                        <label className="form-label small">Role(s)</label>
                        <div className="form-control-wrap">
                          <Select
                            onChange={(option) => {
                              handleRoleSelect(option);
                            }}
                            isMulti={true}
                            // defaultValue={roles?.filter((obj) =>
                            //   userRoleIds.includes(obj.value)
                            // )}
                            options={roles}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {saving && (
              <div className="form-group p-2 m-3">
                <button type="button" className="btn btn-md btn-link m-1">
                  <Spinner size="1.5" />
                </button>
              </div>
            )}
            {!saving && selectedRoles.length !== 0 && (
              <div className="form-group p-2 m-3">
                <button
                  type="button"
                  className="btn btn-md btn-dark btn-wide px-5 mr-3"
                  onClick={() => submitForm()}
                >
                  Assign Role(s)
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-link m-1"
                  onClick={() => window.history.back()}
                >
                  Cancel
                </button>
              </div>
            )}
            {!saving && selectedRoles.length == 0 && (
              <div className="form-group p-2 m-3">
                <button
                  type="button"
                  className="btn btn-md btn-dark btn-wide px-5 mr-3 disabled"
                >
                  Assign Role(s)
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-link m-1"
                  onClick={() => window.history.back()}
                >
                  Cancel
                </button>
              </div>
            )}

            <div className="gap" />

            <div className="card sp-plan">
              <div className="row no-gutters">
                <div className="col-12 ml-4">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Added User Roles
                    </span>{" "}
                  </p>
                </div>
                {!loading && (
                  <div className="col-12">
                    <div className="sp-plan-desc card-inner">
                      <ul className="row gx-1 mt-5">
                        {addedUserRoles?.map((role) => dataItem(role))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
}
