import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import MyTable from "../dataTable";
import { toReadableDate } from "../../utils/utilityFunctions";
import Spinner from "../../utils/spinner";
import BookingRequestService from "../../services/bookingRequestService";
import BookingService from "../../services/bookingRequestService";
import PartyService from "../../services/partyService";
import EquipmentService from "../../services/equipmentService";
import CustomModal from "../../utils/customModal";
import FileInput from "../fileInput";
import toastr from "../../utils/toastr";
import BulkCargoListComponent from "./bulkCargoList";
import EquipmentListComponent from "./equipmentList";
import DeckCargoListComponent from "./deckCargoList";

export default function BookingRequestDetailsComponent({ bookingRequestId }) {
  const [bookingRequest, setBookingRequest] = useState({});
  const [initializing, setInit] = useState(true);
  const [modalIsClosed, setModalIsClosed] = useState(true);
  const [cargoEquipmentType, setCargoEquipmentType] = useState([]);
  const [bookingEquipments, setBookingEquipments] = useState([]);
  const [bookingDeckCargos, setBookingDeckCargos] = useState([]);

  useEffect(async () => {
    setInit(true);

    const _bookingRequest = await BookingRequestService.getBookingRequest(
      bookingRequestId
    );

    userPreferredCargoEquipment();
    setBookingRequest(_bookingRequest || {});

    let _equipmentTypes = await BookingRequestService.cargoEquipment();
    const EquipmentTypes = _equipmentTypes.map((e) => ({
      ...e,
      label: e.equipmentType,
      value: e.tubularCategoryId || e.equipmentTypeId,
    }));

    const _bookingEquipments = _bookingRequest?.bookingEquipment?.map(
      (equipment) => ({
        ...equipment,
        EquipmentType:
          equipment.TubularCategoryId !== "00000000-0000-0000-0000-000000000000"
            ? EquipmentTypes.find(
                (type) => type.value == equipment.TubularCategoryId
              )?.label
            : EquipmentTypes.find(
                (type) => type.value == equipment.CargoCarryingEquipmentTypeId
              )?.label,
      })
    );

    const _deckCargoEqupmentsArray = (
      _bookingRequest?.bookingEquipment || []
    )?.map((equipment) => ({
      ...equipment,
      EquipmentType:
        equipment.TubularCategoryId !== "00000000-0000-0000-0000-000000000000"
          ? EquipmentTypes.find(
              (type) => type.value == equipment.TubularCategoryId
            )?.label
          : EquipmentTypes.find(
              (type) => type.value == equipment.CargoCarryingEquipmentTypeId
            )?.label,
    }));

    const equipmentsForDeckCargo = (_deckCargoEqupmentsArray || [])?.map(
      (equipment) => ({
        ...equipment,
        label:
          equipment.EquipmentType !== "" && equipment.Description !== ""
            ? `${equipment.EquipmentType} - ${equipment.Description}`
            : equipment.EquipmentType,
      })
    );

    const _bookingDeckCargos = _bookingRequest?.deckCargos?.map((cargo) => ({
      ...cargo,
      BookingEquipmentDescription: equipmentsForDeckCargo.find(
        (equipment) =>
          equipment.BookingEquipmentDetailId == cargo.BookingEquipmentDetailId
      )?.label,
    }));

    setBookingEquipments(_bookingEquipments || []);
    setBookingDeckCargos(_bookingDeckCargos || []);

    setInit(false);
  }, [bookingRequestId]);

  const userPreferredCargoEquipment = async () => {
    const preferredEquipment = await BookingService.cargoEquipment();
    const equipDescription = preferredEquipment?.map((obj) => ({
      id: obj.tubularCategoryId || obj.equipmentTypeId,
      name: obj.equipmentType,
    }));
    setCargoEquipmentType(equipDescription);
  };
  const dataItem = (label, value) => {
    if (value == "null") value = "";
    return (
      <li className="col-6 col-lg-3">
        <p>
          <span className="text-soft small">{label}</span> {value || "-"}
        </p>
      </li>
    );
  };

  const onModalClose = () => {
    setModalIsClosed(true);
  };

  let bulkCargoTableInfo = bookingRequest?.deckCargos;
  if (bulkCargoTableInfo) {
    bulkCargoTableInfo = bulkCargoTableInfo?.map((bulkCargoInfo) => ({
      ...bulkCargoInfo,
      nameOfEquipment:
        cargoEquipmentType?.find(
          (equipment) =>
            equipment?.id === bulkCargoInfo?.CargoCarryingEquipmentTypeId
        )?.name ||
        cargoEquipmentType?.find(
          (equipment) => equipment?.id === bulkCargoInfo?.TubularCategoryId
        )?.name,
    }));
  }

  return (
    <>
      {initializing ? (
        <div className="w-100 text-center center my-5 py-5">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="card sp-plan">
            <div className="row no-gutters">
              <div className="col-md-8">
                <div className="sp-plan-info card-inner">
                  <div className="row gx-0 gy-3">
                    <div className="col-xl-9 col-sm-8">
                      <div className="sp-plan-name">
                        <h6 className="title">
                          <Link to={"#"}>
                            {bookingRequest.BookingReferenceNumber}
                            {bookingRequest.BookingStatus && (
                              <span className="badge badge-light badge-pill ml-3">
                                {bookingRequest.BookingStatus}
                              </span>
                            )}
                          </Link>
                        </h6>
                        <p style={{ visibility: "hidden" }}>
                          Manifest Number:{" "}
                          <span className="text-base">
                            {bookingRequest.ManifestNumber}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-4"></div>
                  </div>
                </div>
                <div className="sp-plan-desc card-inner">
                  <p>
                    <span className="preview-title-lg overline-title">
                      Booking Request
                    </span>{" "}
                  </p>
                  <ul className="row gx-1">
                    {dataItem(
                      "Booking Reference Number",
                      bookingRequest.BookingReferenceNumber
                    )}
                    {dataItem(
                      "Sailing Date",
                      toReadableDate(bookingRequest?.sailing.DayOfSailing)
                    )}
                    {dataItem("Booking Status", bookingRequest.BookingStatus)}
                    {dataItem(
                      "Port of Loading",
                      bookingRequest.portOfLoadingName
                    )}
                    {dataItem(
                      "Port of Discharge",
                      bookingRequest.portOfDischargeName || "-"
                    )}
                    {dataItem(
                      "Offshore Installation",
                      bookingRequest.offshoreInstallationName
                    )}
                    {dataItem("Direction", bookingRequest.Direction)}
                    {dataItem(
                      "Expected Handling Time",
                      bookingRequest.ExpectedHandlingTimeOnLocation || "-"
                    )}

                    {dataItem("Operator Name", bookingRequest.partyName)}
                    {dataItem("Manifest Number", bookingRequest.ManifestNumber)}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card-inner p-5">
            <div className="gap" />
            <p>
              <span className="preview-title-lg overline-title">
                Bulk Cargo
              </span>{" "}
            </p>
            <BulkCargoListComponent dataList={bookingRequest.cargos} />

            <div className="gap" />
            <p>
              <span className="preview-title-lg overline-title">Equipment</span>{" "}
            </p>
            <EquipmentListComponent dataList={bookingEquipments} />

            <div className="gap" />
            <p>
              <span className="preview-title-lg overline-title">
                Deck Cargo
              </span>{" "}
            </p>
            <div>
              <DeckCargoListComponent dataList={bookingDeckCargos} />
            </div>
          </div>
          <div className="col-12">
            {bookingRequest.LockedStatus == "full" ||
            bookingRequest.BookingStatus == "cancelled" ? null : (
              <a
                className="mt-4 btn btn-dark btn-dim btn-sm "
                href={`/edit-booking/${bookingRequest.BookingRequestId}`}
              >
                <span>Edit Booking Request Details</span>
              </a>
            )}
          </div>
        </>
      )}
      {/* {!modalIsClosed && <CustomModal title="Edit Booking Request" isClosed={modalIsClosed}
                content={<EditVesselDetailsComponent bookingRequestId={bookingRequestId} callback={() => onModalClose()} />} onClose={() => onModalClose()} />} */}
    </>
  );
}
