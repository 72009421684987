import apiCall from "../utils/apiCall";

export default class PaymentService {
  //Complete unpaginated list
  static fullList = async () => {
    return await apiCall(`/api/Invoice/Get`);
  };

  static list = async (page, pageSize, query = "") => {
    page = page || 1;
    pageSize = pageSize || 50;
    let response = await apiCall(
      `/api/Invoice/Get?pageCount=${page}&noPerPage=${pageSize}&${query}`
    );
    return response;
  };
  static search = async (page, pageSize, query = "") => {
    page = page || 1;
    pageSize = pageSize || 50;
    let response = await apiCall(
      `/api/Payment/SearchList?pageCount=${page}&noPerPage=${pageSize}&${query}`
    );
    return response?.Data;
  };

  static get = async (id) => {
    if (!id) return null;
    return await apiCall(`/api/Payment/Get/${id}`);
  };

  static post = async (payload) => {
    return await apiCall(
      `/api/Payment/AddPayment`,
      "POST",
      payload,
      null,
      true
    );
  };
}
