import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ReactTooltip from "react-tooltip";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CustomModal from "../../utils/customModal";
import {
  toReadableDate,
  dateToYYYY_MM_DD,
  camelizeKeys,
} from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import RoleService from "../../services/roleService";
import UserService from "../../services/userService";
import VesselService from "../../services/vesselService";
import LocationService from "../../services/locationService";

export default function GrantRolePermissionsPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    roleId: params.roleId,
  });
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [saving, setSaving] = useState(false);
  const localizer = momentLocalizer(moment);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tab, setTab] = useState(1);
  const [reloading, setReloading] = useState(false);
  const [rolePermissions, setRolePermissions] = useState([]);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "addrolepermission";
  // console.log(payload);

  useEffect(async () => {
    setLoading(true);

    let _role = await RoleService.getRoleById(params.roleId);
    _role = camelizeKeys(_role || []);
    if (_role.isSystemRole == true) {
      setRedirectUrl("/roles");
    } else {
      await retrieveAllPermissions();
    }

    setLoading(false);

    return () => {
      ClearValidator(validationGroup);
    };
  }, [params.roleId]);

  const retrieveAllPermissions = async () => {
    setLoading(true);

    let _rolePermissions = await RoleService.getRolePermissions(params.roleId);
    _rolePermissions = camelizeKeys(_rolePermissions.Permissions || []);
    setRolePermissions(_rolePermissions);

    let _rolePermissionIds = _rolePermissions?.map(
      (rolePermissionId) => rolePermissionId.permissionId
    );

    let _permissions = await RoleService.getAllPermissions();
    _permissions = camelizeKeys(_permissions || [])
      ?.map((permission) => ({
        ...permission,
        label: permission.permission,
        value: permission.permissionId,
      }))
      .filter(
        (permission) => !_rolePermissionIds.includes(permission.permissionId)
      );
    setPermissions(_permissions);

    setLoading(false);
  };
  // console.log(permissions);
  // console.log(rolePermissions);

  const discardChanges = async (selectedId) => {
    const _payload = { roleId: params.roleId, permissionIds: [selectedId] };
    console.log(_payload);

    setLoading(true);
    const response = await RoleService.removePermissionsFromRole(_payload);
    if (response) {
      toastr("success", "Permission removed role successfully");
      await retrieveAllPermissions();
    } else {
      toastr("error", "Permission could not be removed from role");
    }
    setLoading(false);
  };

  const dataItem = (data) => {
    return (
      <div className="col col-md-3  mb-4" key={data?.permissonId}>
        <div className="form-control-wrap d-flex  align-center">
          <div className="form-group col">
            <div className="form-control-wrap d-flex align-center">
              <em
                class="icon ni ni-trash text-danger fs-22px pointer mr-2"
                title="Discard"
                onClick={() => discardChanges(data.permissionId)}
              ></em>
              <label className="form-label mb-0">{data.permission}</label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const submitForm = async () => {
    const _payload = [...selectedPermissions];

    // if (!_payload[0].permissionId) {
    //   toastr("error", "Select permission");
    //   return;
    // }

    setSaving(true);

    const response = await RoleService.addPermissionsToRoles(_payload);
    if (response) {
      _payload[0].permissionId = "";
      toastr("success", "Permission granted to role successfully");
      await retrieveAllPermissions();
    } else {
      toastr("error", "Permission could not be granted to role");
    }
    setSaving(false);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <a
              role="button"
              href="/roles"
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to Roles</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  // const reloadPage = () => {
  //   setReloading(true);
  //   setTimeout(() => {
  //     setReloading(false);
  //   }, 200);
  // };

  const handlePermissionSelect = (option) => {
    let _permissions = option?.map(function (permission) {
      return { roleId: params.roleId, permissionId: permission.value };
    });
    console.log(_permissions);
    setSelectedPermissions(_permissions || []);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Grant Role Permissions"
      loading={loading}
      showFullLoader={loading}
      pageActions={pageActions}
    >
      <form>
        {
          <div className="card-inner">
            <div>
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group ">
                    <label className="form-label small required">
                      Permissions
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        isMulti={true}
                        onChange={(option) => {
                          handlePermissionSelect(option);
                        }}
                        // value={permissions.find(
                        //   (a) => a.value === payload?.permissionId
                        // )}
                        options={permissions}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {saving && (
          <div className="form-group p-2 m-3">
            <button type="button" className="btn btn-md btn-link m-1">
              <Spinner size="1.5" />
            </button>
          </div>
        )}
        {!saving && selectedPermissions.length !== 0 && (
          <div className="form-group p-2 m-3">
            <button
              type="button"
              className="btn btn-md btn-dark btn-wide px-5 mr-3"
              onClick={() => submitForm()}
            >
              Add Permission(s)
            </button>
            <button
              type="button"
              className="btn btn-sm btn-link m-1"
              onClick={() => window.history.back()}
            >
              Cancel
            </button>
          </div>
        )}
        {!saving && selectedPermissions.length == 0 && (
          <div className="form-group p-2 m-3">
            <button
              type="button"
              className="btn btn-md btn-dark btn-wide px-5 mr-3 disabled"
            >
              Add Permission(s)
            </button>
            <button
              type="button"
              className="btn btn-sm btn-link m-1"
              onClick={() => window.history.back()}
            >
              Cancel
            </button>
          </div>
        )}

        <div className="gap" />

        <div className="card sp-plan">
          <div className="row no-gutters">
            <div className="col-12 ml-4">
              <p>
                <span className="preview-title-lg overline-title">
                  Added Permissions
                </span>{" "}
              </p>
            </div>
            {!loading && (
              <div className="col-12">
                <div className="sp-plan-desc card-inner">
                  <ul className="row gx-1 mt-5">
                    {rolePermissions?.map((permission) => dataItem(permission))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
      <hr className="preview-hr" style={{ visibility: "hidden" }} />
    </MainLayout>
  );
}
