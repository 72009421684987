import React, { useEffect, useState, useCallback } from "react";
import "../../styles/dataTable.scss";
import {
	useNavigate,
	useLocation,
	Link,
	Navigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import moment from "moment";
import ms from "ms";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import {
	generateQueryString,
	checkUserPermission,
	formatValue,
	toReadableDate,
} from "../../utils/utilityFunctions";
import VesselService from "../../services/vesselService";
import LocationService from "../../services/locationService";
import BookingService from "../../services/bookingRequestService";
import CustomModal from "../../utils/customModal";

export default function BookingRequestDashboard() {
	const [redirectUrl, setRedirectUrl] = useState(null);
	const [loading, setLoading] = useState(false);
	const [payload, setPayload] = useState({});
	const [pages, setPages] = useState([10, 20, 50, 100, 150]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [totalRecords, setTotalRecords] = useState(10);
	const [utilizationList, setUtilizationList] = useState([]);
	const [exportData, setExportData] = useState([]);
	const [maxMonthHeader, setMaxMonthHeader] = useState("");
	const [secondMaxMonthHeader, setSecondMaxMonthHeader] = useState("");
	const [thirdMaxMonthHeader, setThirdMaxMonthHeader] = useState("");
	const navigate = useNavigate();
	const [filterObject, setFilterObject] = useState({
		dateFrom: "2022-02-02",
		dateTo: "2022-10-20,",
	});
	const [vesselList, setVesselList] = useState([]);
	const [locationTypes, setLocationTypes] = useState([]);
	const [allLocationTypes, setAllLocationTypes] = useState([]);
	const [maxDate, setMaxDate] = useState(null);
	const [minDate, setMinDate] = useState(null);
	const [installationList, setOffshoreInstallationList] = useState([]);

	const monthArray = [
		{ label: "January", value: 1 },
		{ label: "February", value: 2 },
		{ label: "March", value: 3 },
		{ label: "April", value: 4 },
		{ label: "May", value: 5 },
		{ label: "June", value: 6 },
		{ label: "July", value: 7 },
		{ label: "August", value: 8 },
		{ label: "September", value: 9 },
		{ label: "October", value: 10 },
		{ label: "November", value: 11 },
		{ label: "December", value: 12 },
	];

	useEffect(async () => {
		await init();
	}, []);

	//this it is to retrive date and avoid re-renders.
	useCallback(() => {
		const minToSeconds = ms("60d");
		const minimumDate = new Date(+new Date(filterObject.dateFrom));
		const maximumDate = new Date(
			+new Date(filterObject.dateFrom) + minToSeconds
		);
		setMinDate(moment(minimumDate).format("YYYY-MM-DD"));
		setMaxDate(moment(maximumDate).format("YYYY-MM-DD"));
	}, [filterObject.dateFrom]);

	const init = async () => {
		setLoading(true);
		await loadData();
		let _locationTypes = await LocationService.listLocationTypes();
		setAllLocationTypes(_locationTypes);

		const locationsTypes = await LocationService.list();
		const filteredLocation = locationsTypes?.filter(
			(list) =>
				list?.LocationTypeName?.toLowerCase() === "offshore installation"
		);
		setOffshoreInstallationList(filteredLocation);
		setLoading(false);
	};

	let queryStr = generateQueryString(filterObject);

	const clearFilters = async () => {
		setFilterObject({
			Installation: "",
			Direction: "",
			locationType: "",
			IsLinkedToVoayge: "",
		});
		queryStr = "alllocationType=true&";

		await loadData(1, null, queryStr);
	};
	const filterData = async () => {
		await loadData(1, null, queryStr);
	};

	const loadData = async (_page, _pageSize, query = queryStr) => {
		setLoading(true);
		_page = _page || page;
		_pageSize = _pageSize || pageSize;
		const utilizationDetails = await BookingService.bookingDashboard(
			_page,
			_pageSize,
			query
		);

		if (utilizationDetails?.TotalCount) {
			setTotalRecords(utilizationDetails.TotalCount);
		}

		let modifiedDataList = utilizationDetails?.Data || [];
		modifiedDataList = modifiedDataList.map((list) => ({
			...list,
			fomattedSailingDate: toReadableDate(list?.SailingDate),
			VesselName: list?.LinkedVoyageDetails?.map(
				(x) => x?.VesselName
			).toString(),
			viewDetailsButton: viewBookingDetails(list),
		}));
		setUtilizationList(modifiedDataList.flat(1));

		page != _page && setPage(_page);
		pageSize != _pageSize && setPageSize(_pageSize);
		setLoading(false);
	};
	let direction = [
		{ label: "Outbound", value: "Outbound" },
		{ label: "Interfield", value: "Interfield" },
		{ label: "Inbound", value: "Inbound" },
	];
	const headerList = [
		{
			Header: "Offshore Installation",
			accessor: "Installation",
		},
		{
			Header: "Direction",
			accessor: "Direction",
		},
		{
			Header: "Status",
			accessor: "BookingStatus",
		},
		{
			Header: "Date of Sailing",
			accessor: "fomattedSailingDate",
		},
		{
			Header: "Vessel name",
			accessor: "VesselName",
		},
		{
			Header: "",
			accessor: "viewDetailsButton",
		},
	];
	const viewBookingDetails = (view) => {
		return (
			<div>
				<Link
					to={`/booking-request/${view.BookingRequestId}`}
					className="btn btn-xs text-primary"
					title="booking details">
					<span>Details</span>
				</Link>
			</div>
		);
	};
	const pageLimiter = Math.ceil(totalRecords / pageSize);

	const prevLoaded = async () => {
		if (pageLimiter > 1 && page < pageLimiter) {
			await loadData(page - 1, null, queryStr);
		} else if (pageLimiter == page) {
			await loadData(page - 1, null, queryStr);
		}
	};

	const nextLoaded = async () => {
		if (pageLimiter !== 1 && page < pageLimiter) {
			await loadData(page + 1, null, queryStr);
		}
	};

	const goToPage = async (page) => {
		await loadData(page, null, queryStr);
	};

	return (
	<>
		{loading ? (
			<div className="w-100 text-center center my-5 py-5">
				<Spinner />
			</div>
		) : (
			<>
				<div className="card-inner-group">
					<h5 className="text-center pt-3 pb-0 pl-1">
							Booking Request (Draft)
					</h5>
					{utilizationList.length < 0 ? null : (
						// The filter and pagination component

					<div className="card-inner position-relative card-tools-toggle">
						{
						<div className="card-title-group">
							<div className="card-tools ml-n1">
								<ul className="btn-toolbar gx-1">
									<li>
										<div className="dropdown">
											<a
												href="#"
												className="btn btn-trigger btn-icon dropdown-toggle "
												data-toggle="dropdown">
												<em className="icon ni ni-filter-alt" />
											</a>
											<div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
												<form style={{ zoom: "85%" }}>
													<div className="dropdown-head">
														<span className="sub-title dropdown-title">
															Filter List
														</span>
														<div className="dropdown">
															<a
																className="btn btn-sm btn-icon"
																data-toggle="dropdown">
																<em
																	className="icon ni ni-cross"
																	title="close"
																/>
															</a>
														</div>
													</div>
													<div className="dropdown-body dropdown-body-rg">
														<div className="row gx-6 gy-3">
															<div className="col-6">
																<div
																	className="form-group"
																	onClick={(e) => e.stopPropagation()}>
																	<label className="overline-title overline-title-alt">
																		Offshore Installation
																	</label>
																	<div className="form-control-wrap filterSpacing">
																		<Select
																			maxMenuHeight={140}
																			options={installationList?.map(
																				(e) => ({
																					...e,
																					label: e.LocationName,
																					value: e.LocationId,
																				})
																			)}
																			value={installationList
																				.map((e) => ({
																					...e,
																					label: e.LocationName,
																					value: e.LocationId,
																				}))
																				.find(
																					(e) =>
																						e.value ===
																						installationList.LocationId
																				)}
																			onChange={(option) =>
																				setFilterObject((prev) => ({
																					...prev,
																					Installation: option.value,
																				}))
																			}
																		/>
																	</div>
																</div>
															</div>
															<div className="col-6">
																<div
																	className="form-group"
																	onClick={(e) => e.stopPropagation()}>
																	<label className="overline-title overline-title-alt">
																		Direction
																	</label>
																	<Select
																		className="border-transparent"
																		placeholder=""
																		maxMenuHeight={140}
																		options={direction.map((e) => ({
																			...e,
																			label: e.label,
																			value: e.value,
																		}))}
																		value={direction
																			.map((e) => ({
																				...e,
																				label: e.label,
																				value: e.value,
																			}))
																			.find(
																				(e) =>
																					e.value ===
																					filterObject.Direction
																			)}
																		onChange={(option) =>
																			setFilterObject((prev) => ({
																				...prev,
																				Direction: option.label,
																			}))
																		}
																	/>
																</div>
															</div>
															<div className="col-6">
																<div className="form-group row align-items-center">
																	<label className="overline-title overline-title-alt ">
																		Linked To Voayge?
																	</label>
																	<div className="form-control-wrap col">
																		<div className="custom-control custom-switch">
																			<input
																				type="checkbox"
																				className="custom-control-input"
																				id="customSwitchActive"
																				checked={
																					!!filterObject.groupActivities
																				}
																				onChange={(e) =>
																					setFilterObject((prev) => ({
																						...prev,
																						IsLinkedToVoayge: e.target
																							.value
																							? true
																							: false,
																					}))
																				}
																			/>
																			<label
																				className="custom-control-label"
																				htmlFor="customSwitchActive">
																				{filterObject.groupActivities
																					? "Yes"
																					: "No"}
																			</label>
																		</div>
																	</div>
																</div>
															</div>

															<div className="gap gap-10px" />

															<div className="col-12">
																<div className="form-group">
																	<button
																		type="button"
																		onClick={filterData}
																		className="btn btn-secondary">
																		<span>Filter</span>
																	</button>
																	<a
																		className="clickable ml-2"
																		onClick={() => clearFilters()}>
																		Reset Filter
																	</a>
																</div>
															</div>
															<div className="gap gap-20px" />
														</div>
													</div>
												</form>
											</div>
										</div>
									</li>
								</ul>
							</div>
							{pageLimiter > 1 &&(
							<div className="card-tools mr-n1">
								<ul className="btn-toolbar gx-1">
									<li>
										<button
											className="btn btn-trigger btn-icon "
											onClick={page !== 1 ? prevLoaded : null}
											disabled={page == 1 ? true : false}
											title="Previous voyages">
											<em className="icon ni ni-arrow-left" />
										</button>
									</li>
									<li className="btn-toolbar-sep" />
									<li>
										<button
											className="btn btn-trigger btn-icon "
											onClick={page >= 1 ? nextLoaded : null}
											disabled={page >= pageLimiter ? true : false}
											title="Next voyages">
											<em className="icon ni ni-arrow-right" />
										</button>
									</li>
									<li className="btn-toolbar-sep" />
									<li>
										<div className="g" data-select2-id={6}>
											<div
												className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3"
												data-select2-id={5}>
												<div>
													<input
														type="number"
														className="form-control border search-input text-align-center"
														placeholder=""
														onChange={(e) => goToPage(e.target.value)}
														value={page}
														style={{ width: "50px" }}
													/>
												</div>
												<div>of {`${pageLimiter}`}</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							)}
						</div>
						}
					</div>
					)}
					<>
					<div className="" style={{ width: "100%" }}>
						<BookingTable
							columns={headerList}
							data={utilizationList}
							cssArray={["font-weight-500", "", "", "text-left", "", ""]}
							enableSearch={false}
							emptyMessage="No installation record to show"
							disablePageSize={true}
						/>
					</div>
					</>
				</div>
			</>
		)}
	</>
	);
}
