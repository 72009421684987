import React, { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import BookingRequestService from "../../services/bookingRequestService";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import toastr from "../../utils/toastr";
import DataTable from "../../components/dataTable";
import Paginate from "../../components/paginate";
import { exportToCSV } from "../../utils/exportCSV";
import { toReadableDate } from "../../utils/utilityFunctions";
import ReactTooltip from "react-tooltip";
import BookingTable from "../../components/bookingTable";
import { checkUserPermission } from "../../utils/utilityFunctions";
import CustomModal from "../../utils/customModal";

export default function BookingRequestListPage() {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [payload, setPayload] = useState({});
  const [pages, setPages] = useState([10, 20, 50, 100, 150]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [totalRecords, setTotalRecords] = useState(10);
  const [bookingRequests, setBookingRequests] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [bookingRef, setBookingRef] = useState("");
  const [bookingStatus, setBookingStatus] = useState("");
  const [sailingId, setSailingId] = useState("");
  const [manifestNumber, setManifestNumber] = useState("");
  const [unconfirmedBookings, setUnconfirmedBooking] = useState([]);
  const [filterUnconfirmedBookings, setFilterUnconfirmedBookings] =
    useState("all");
  const [downloadPopUp, setDownloadPopUp] = useState(false);
  const [downloadStartDate, setDownloadStartDate] = useState("");
  const [downloadEndDate, setDownloadEndDate] = useState("");

  const exportItems = bookingRequests?.map((shortlist) => ({
    BookingReferenceNumber: shortlist?.BookingReferenceNumber,
    BookingStatus: shortlist?.BookingStatus,
    DateCreated: toReadableDate(shortlist?.DateCreated),
    DayOfSailing: shortlist?.DayOfSailing,
    Direction: shortlist?.Direction,
    ExpectedHandlingTimeOnLocation: shortlist?.ExpectedHandlingTimeOnLocation,
    LastModifiedDate: toReadableDate(shortlist?.LastModifiedDate),
    ManifestNumber: shortlist?.ManifestNumber,
    OffshoreInstallationName: shortlist?.offshoreInstallationName,
    PartyName: shortlist?.partyName,
    PortOfDischargeName: shortlist?.portOfDischargeName,
    PortOfLoadingName: shortlist?.portOfLoadingName,
    Sailing: `Sailing ${shortlist?.sailing?.SailingNo}`,
    SailingRoute: shortlist?.sailing?.SailingRoute,
    SailingStatus: shortlist?.sailing?.SailingStatus,
  }));

  useEffect(async () => {
    await init();
  }, []);

  const init = async () => {
    setLoading(true);

    await loadData();

    setLoading(false);
  };

  const loadData = async (
    _page,
    _pageSize,
    _manifestNumber = "",
    _bookingStatus = "",
    _bookingRef = "",
    _sailingId = ""
  ) => {
    setLoading(true);
    _page = _page || page;
    _pageSize = _pageSize || pageSize;
    setManifestNumber(_manifestNumber);
    setBookingStatus(_bookingStatus);
    setBookingRef(_bookingRef);
    setSailingId(_sailingId);

    const _bookingRequests = await BookingRequestService.searchBookingRequest(
      _page,
      _pageSize,
      _manifestNumber,
      _bookingStatus,
      _bookingRef,
      _sailingId
    );
    let modifiedDataList = _bookingRequests?.Data || [];
      setTotalRecords(_bookingRequests?.TotalCount || 0);
    modifiedDataList = modifiedDataList?.map((l) => ({
      ...l,
      DayOfSailing: toReadableDate(l?.sailing?.DayOfSailing),
      actionsComponent: overflowMenu(l),
      changeLockComponent: lockIcon(l),
    }));

    setBookingRequests(modifiedDataList);

    page != _page && setPage(_page);
    pageSize != _pageSize && setPageSize(_pageSize);
    setLoading(false);
  };

  const loadUnconfirmedBookings = async (_page, _pageSize) => {
    setLoading(true);
    _page = _page || page;
    _pageSize = _pageSize || pageSize;

    const _unconfirmedBookings =
      await BookingRequestService.getUnconfirmedBookings(_page, _pageSize);
    let modifiedDataList = _unconfirmedBookings?.Data?.uconfirmedBookings || [];
    if (_unconfirmedBookings?.Data?.TotalCount) {
      setTotalRecords(_unconfirmedBookings?.Data?.TotalCount);
    }
    modifiedDataList = modifiedDataList.map((l) => ({
      ...l,
      DayOfSailing: toReadableDate(l.sailing.DayOfSailing),
      actionsComponent: overflowMenu(l),
      changeLockComponent: lockIcon(l),
    }));
    setUnconfirmedBooking(modifiedDataList);

    page != _page && setPage(_page);
    pageSize != _pageSize && setPageSize(_pageSize);
    setLoading(false);
  };

  const overflowMenu = (bookingRequest) => {
    const cancelRequest = async () => {
      if (bookingRequest) {
        let toCancel = await BookingRequestService.cancelBooking(
          bookingRequest.BookingRequestId
        );
        if (toCancel) {
          setPayload({});
          await init();
          toastr("success", "Booking cancelled successfully");
        }
      }
      setLoading(false);
    };

    const confirmRequest = async () => {
      if (bookingRequest) {
        const toConfirm = await BookingRequestService.confirmBooking(
          bookingRequest.BookingRequestId
        );
        if (toConfirm) {
          await init();
          toastr("success", "Booking confirmed successfully");
        } else {
          toastr("error", "Booking not confirmed. No voyage linked to booking");
        }
      }
      setLoading(false);
    };

    return (
      <ul className="nk-tb-actions gx-2">
        {null && (
          <li className="nk-tb-action-hidden">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger"
              data-toggle="tooltip"
              data-placement="top"
              title="Suspend"
            >
              <em className="icon ni ni-user-cross-fill" />
            </a>
          </li>
        )}
        <li>
          <div className="drodown">
            <a
              href="#"
              className="btn btn-sm btn-icon btn-trigger dropdown-toggle"
              data-toggle="dropdown"
            >
              <em className="icon ni ni-more-h" />
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <ul className="link-list-opt no-bdr">
                {/* {checkUserPermission("booking_view_bookings_details") && ( */}
                <li>
                  <Link
                    to={`/booking-request/${bookingRequest.BookingRequestId}`}
                  >
                    <em className="icon ni ni-eye" />
                    <span>View Details</span>
                  </Link>
                </li>
                {/* )} */}

                {checkUserPermission("booking_edit_bookings_details") &&
                  (bookingRequest.LockedStatus == "full" ||
                  bookingRequest.BookingStatus == "cancelled" ? null : (
                    <li>
                      <Link
                        to={`/edit-booking/${bookingRequest.BookingRequestId}`}
                      >
                        <em className="icon ni ni-file-docs" />
                        <span>Edit Booking Details</span>
                      </Link>
                    </li>
                  ))}

                {checkUserPermission("booking_cancel_bookings") &&
                  (bookingRequest.LockedStatus !== "unlocked" ||
                  bookingRequest.BookingStatus == "confirmed" ||
                  bookingRequest.BookingStatus == "cancelled" ? null : (
                    <li>
                      {
                        <a role="button" onClick={cancelRequest}>
                          <em className="icon ni ni-cross" />
                          <span>Cancel Booking</span>
                        </a>
                      }
                    </li>
                  ))}

                {checkUserPermission("booking_confirm_bookings") &&
                  (bookingRequest.BookingStatus == "confirmed" ||
                  bookingRequest.BookingStatus == "cancelled" ? null : (
                    <li>
                      {
                        <a role="button" onClick={confirmRequest}>
                          <em className="icon ni ni-check" />
                          <span>Confirm Booking</span>
                        </a>
                      }
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  const lockIcon = (bookingRequest) => {
    if (bookingRequest.LockedStatus == "unlocked") {
      return (
        <>
          <a
            style={{ color: "lightgreen" }}
            href="#"
            className="btn btn-sm btn-icon btn-trigger"
            title={`${bookingRequest.LockedStatus}, changes permitted`}
          >
            <em className="icon ni ni-unlock" />
          </a>
        </>
      );
    } else if (bookingRequest.LockedStatus == "partial") {
      return (
        <>
          <a
            style={{ color: "blue" }}
            href="#"
            className="btn btn-sm btn-icon btn-trigger"
            title={`${bookingRequest.LockedStatus}, changes permitted`}
          >
            <em className="icon ni ni-unlock" />
          </a>
        </>
      );
    } else {
      return (
        <>
          <a
            style={{ color: "red" }}
            href="#"
            className="btn btn-sm btn-icon btn-trigger"
            title={`${bookingRequest.LockedStatus}, changes locked`}
          >
            <em className="icon ni ni-lock-alt" />
          </a>
        </>
      );
    }
  };

  const headerList = [
    {
      Header: "Day of Sailing",
      accessor: "DayOfSailing",
    },
    {
      Header: "Installation",
      accessor: "offshoreInstallationName",
      id: "offshoreInstallationName",
    },
    {
      Header: "Direction",
      accessor: "Direction",
    },
    {
      Header: "Terminal",
      accessor: "portOfLoadingName",
    },
    {
      Header: "Status",
      accessor: "BookingStatus",
    },
    {
      Header: "",
      accessor: "changeLockComponent",
    },
    {
      Header: "",
      accessor: "actionsComponent",
    },
  ];

  const filterByBookingProperty = async () => {
    await loadData(
      1,
      null,
      manifestNumber,
      bookingStatus,
      bookingRef,
      sailingId
    );
  };

  const clearFilter = async () => {
    setManifestNumber("");
    setBookingStatus("");
    setBookingRef("");
    setSailingId("");
    setFilterUnconfirmedBookings("all");
    setUnconfirmedBooking([]);

    await loadData(1, null);
  };

  const statusList = [
    { label: "Cancelled", value: "cancelled" },
    { label: "Confirmed", value: "confirmed" },
    { label: "Draft", value: "draft" },
    { label: "Submitted", value: "submitted" },
    { label: "Updated", value: "updated" },
  ];

  const confirmlist = [
    { label: "Unconfirmed", value: "unconfirmed" },
    { label: "All", value: "all" },
  ];

  const filterByConfirmation = async (option) => {
    setLoading(true);
    if (option == "all") {
      setFilterUnconfirmedBookings(option);
      await loadData(1, null, null, null, null, null);
    } else {
      setFilterUnconfirmedBookings(option);
      await loadUnconfirmedBookings(1, null);
    }
    setLoading(false);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          {checkUserPermission("booking_export_bookings_list") && (
            <li>
              <a
                role="button"
                onClick={() => setDownloadPopUp(true)}
                // href={
                //   loading
                //     ? "javascript:void(0)"
                //     : `${process.env.REACT_APP_API}/api/BookingRequest/DownloadBookingRequestsCSV?startDate=2022-02-01&endDate=2022-09-30&authorization=${localStorage.token}`
                // }
                target={loading ? "" : "_blank"}
                className={`btn btn-dim btn-secondary ${
                  loading ? "disabled" : ""
                }`}
              >
                <em className="icon ni ni-download" />
                <span>Download Booking Requests</span>
              </a>
            </li>
          )}
          {/* {bookingRequests &&
            checkUserPermission("booking_export_bookings_list") && (
              <li>
                <button
                  type="button"
                  onClick={() => exportToCSV(exportItems, "list_of_bookings")}
                  className="btn btn-white btn-outline-light"
                >
                  <em className="icon ni ni-download-cloud" />
                  <span>Export page</span>
                </button>
              </li>
            )} */}
          {/* {checkUserPermission("booking_add_booking") && ( */}
          <li className="nk-block-tools-opt">
            <div className="drodown">
              <a
                href="#"
                className="dropdown-toggle btn btn-icon btn-primary"
                data-toggle="dropdown"
              >
                <em className="icon ni ni-plus" />
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <ul className="link-list-opt no-bdr">
                  <li>
                    <Link to="/add-booking">
                      <span>Add Booking Request</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          {/* )} */}
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Bookings"
      pageActions={pageActions}
      loading={loading}
      showFullLoader={loading}
    >
      <div className="table-component">
        <div className="card-inner-group">
          {
            <div className="card-inner position-relative card-tools-toggle">
              {checkUserPermission("booking_filter_bookings_list") && (
                <div className="card-title-group">
                  <div className="card-tools">
                    <div className="dropdown">
                      <a
                        href="#"
                        className="btn btn-trigger btn-icon dropdown-toggle "
                        data-toggle="dropdown"
                      >
                        <em className="icon ni ni-search" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-xl dropdown-menu-left p-1">
                        <form>
                          <div className="dropdown-head">
                            <span className="sub-title dropdown-title">
                              Filter Booking Requests
                            </span>
                            <div className="dropdown">
                              <a
                                className="btn btn-sm btn-icon"
                                data-toggle="dropdown"
                              >
                                <em
                                  className="icon ni ni-cross"
                                  title="close"
                                />
                              </a>
                            </div>
                          </div>
                          <div className="dropdown-body dropdown-body-rg">
                            <div className="row gx-6 gy-3">
                              {filterUnconfirmedBookings !== "all" ? null : (
                                <>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label
                                        className="overline-title overline-title-alt"
                                        htmlFor="from-date-filter"
                                      >
                                        Booking Reference
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control "
                                        placeholder=""
                                        value={bookingRef}
                                        onChange={(e) =>
                                          setBookingRef(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label
                                        className="overline-title overline-title-alt"
                                        htmlFor="from-date-filter"
                                      >
                                        Manifest Number
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control "
                                        placeholder=""
                                        value={manifestNumber}
                                        onChange={(e) =>
                                          setManifestNumber(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div
                                      className="form-group"
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <label className="overline-title overline-title-alt">
                                        Booking Status
                                      </label>
                                      <Select
                                        className="border-transparent"
                                        placeholder=""
                                        value={statusList.find(
                                          (status) =>
                                            status.value === bookingStatus
                                        )}
                                        options={statusList}
                                        onChange={(option) =>
                                          setBookingStatus(option.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}

                              <div className="col-12">
                                <div className="form-group">
                                  <label
                                    className="overline-title overline-title-alt"
                                    htmlFor="from-date-filter"
                                  >
                                    Sort Unconfirmed Bookings
                                  </label>
                                  <Select
                                    className="border-transparent"
                                    placeholder=""
                                    value={confirmlist.find(
                                      (status) =>
                                        status.value ===
                                        filterUnconfirmedBookings
                                    )}
                                    options={confirmlist}
                                    onChange={(e) =>
                                      filterByConfirmation(e.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="gap gap-10px" />

                              <div className="col-12">
                                <div className="form-group">
                                  <button
                                    type="button"
                                    onClick={filterByBookingProperty}
                                    className="btn btn-secondary"
                                  >
                                    <span>Filter</span>
                                  </button>
                                  <a
                                    className="clickable ml-2"
                                    onClick={() => clearFilter()}
                                  >
                                    Reset Filter
                                  </a>
                                </div>
                              </div>
                              <div className="gap gap-50px" />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* .form-inline */}
                  </div>

                  <div className="card-tools mr-n1">
                    <ul className="btn-toolbar gx-1">
                      <li className="btn-toolbar-sep" />
                      {/* li */}
                      <li>
                        <div className="toggle-wrap">
                          <a
                            href="#"
                            className="btn btn-icon btn-trigger toggle"
                            data-target="cardTools"
                          >
                            <em className="icon ni ni-menu-right" />
                          </a>
                          <div
                            className="toggle-content"
                            data-content="cardTools"
                          >
                            <ul className="btn-toolbar gx-1">
                              <li className="toggle-close">
                                <a
                                  href="#"
                                  className="btn btn-icon btn-trigger toggle"
                                  data-target="cardTools"
                                >
                                  <em className="icon ni ni-arrow-left" />
                                </a>
                              </li>
                              {/* li */}

                              <li>
                                <div className="dropdown">
                                  <a
                                    href="#"
                                    className="btn btn-trigger btn-icon dropdown-toggle"
                                    data-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-setting" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                    {filterUnconfirmedBookings == "all" ? (
                                      <ul className="link-check">
                                        <li>
                                          <span>Show</span>
                                        </li>
                                        {pages.map((item, index) => (
                                          <li
                                            onClick={() =>
                                              loadData(
                                                null,
                                                item,
                                                manifestNumber,
                                                bookingStatus,
                                                bookingRef,
                                                sailingId
                                              )
                                            }
                                            key={index}
                                            className={`${
                                              page == item && "active"
                                            }`}
                                          >
                                            <Link to={"#"}>{item}</Link>
                                          </li>
                                        ))}
                                      </ul>
                                    ) : (
                                      <ul className="link-check">
                                        <li>
                                          <span>Show</span>
                                        </li>
                                        {pages.map((item, index) => (
                                          <li
                                            onClick={() =>
                                              loadUnconfirmedBookings(
                                                null,
                                                item
                                              )
                                            }
                                            key={index}
                                            className={`${
                                              page == item && "active"
                                            }`}
                                          >
                                            <Link to={"#"}>{item}</Link>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </div>
                                </div>
                                {/* .dropdown */}
                              </li>
                              {/* li */}
                            </ul>
                            {/* .btn-toolbar */}
                          </div>
                          {/* .toggle-content */}
                        </div>
                        {/* .toggle-wrap */}
                      </li>
                      {/* li */}
                    </ul>
                    {/* .btn-toolbar */}
                  </div>
                  {/* .card-tools */}
                </div>
              )}
            </div>
          }
          {/* .card-inner */}
        </div>
        {/* .card-inner-group */}
      </div>

      {filterUnconfirmedBookings == "unconfirmed"
        ? loading || (
            <>
              <BookingTable
                columns={headerList}
                data={unconfirmedBookings}
                cssArray={["font-weight-500", "", "", "text-left", "", ""]}
                enableSearch={false}
                emptyMessage="No booking record to show on page"
                disablePageSize={true}
                defaultSorting={[
                  {
                    id: "offshoreInstallationName",
                    desc: false,
                  },
                ]}
              />
              <br />
              {unconfirmedBookings.length == 0 ? null : (
                <div className="">
                  <Paginate
                    onChange={(p) => loadUnconfirmedBookings(p, null)}
                    currentPage={page}
                    pageSize={pageSize}
                    totalRecordsCount={totalRecords}
                  />
                </div>
              )}
            </>
          )
        : loading || (
            <>
              <BookingTable
                columns={headerList}
                data={bookingRequests}
                cssArray={["font-weight-500", "", "", "text-left", "", ""]}
                enableSearch={false}
                emptyMessage="No booking record to show on page"
                disablePageSize={true}
                defaultSorting={[
                  {
                    id: "offshoreInstallationName",
                    desc: false,
                  },
                ]}
              />
              <br />
                <div className="">
                  <Paginate
                    onChange={(p) =>
                      loadData(
                        p,
                        null,
                        manifestNumber,
                        bookingStatus,
                        bookingRef,
                        sailingId
                      )
                    }
                    currentPage={page}
                    pageSize={pageSize}
                    totalRecordsCount={totalRecords}
                  />
                </div>
            
            </>
          )}
      {downloadPopUp == true && (
        <CustomModal
          title={
            <span>
              <em class="icon ni ni-info-i"></em> Enter Download Time Range
            </span>
          }
          content={
            <>
              <div className="card-inner p-2">
                <form>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group" style={{ zIndex: "10" }}>
                        <label className="form-label small">Start Date</label>
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control required"
                            onChange={(e) =>
                              setDownloadStartDate(e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group" style={{ zIndex: "10" }}>
                        <label className="form-label small">End Date</label>
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control required"
                            onChange={(e) => setDownloadEndDate(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="gap gap-30px" />

                <div className="form-group">
                  <a
                    className="btn btn-sm btn-dark active"
                    href={`${process.env.REACT_APP_API}/api/BookingRequest/DownloadBookingRequestsCSV?startDate=${downloadStartDate}&endDate=${downloadEndDate}&authorization=${localStorage.token}`}
                    onClick={() => setDownloadPopUp(false)}
                  >
                    Download
                  </a>
                </div>
              </div>
            </>
          }
          onClose={() => setDownloadPopUp(false)}
          isClosed={false}
        />
      )}
    </MainLayout>
  );
}
