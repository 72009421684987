import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import VesselService from "../../services/vesselService";
import VoyageService from "../../services/voyageService";
import * as vesselPositionData from "../../utils/vesselsPositions.json";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from "react-leaflet";
import L from "leaflet";
import osm from "../../utils/osm.providers";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import marker from "../../images/ferry.svg";

let vesselPositionDetails = vesselPositionData?.Data;

export default function ActiveVesselMapView() {
  const [vesselInfo, setVesselInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const defaultMarker = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: "./images/ferry.svg",
    shadowSize: [25, 25],
    shadowAnchor: null,
    iconSize: new L.Point(60, 75),
    className: "leaflet-div-icon-custom",
  });
  const center = {
    lat: 3.967525,
    lng: 7.249595,
  };
  const allVesselsInfo = async () => {
    let vesselSpecificInfo = await VesselService.listAllVessels();
    setVesselInfo(vesselSpecificInfo);
  };
  //The function below would be used at a later time when the endpoint returns varying locations for active vessels.
  //A JSON file was made to mock the exisitng endpoint for voyages
  const voyageList = async () => {
    const voyagesDetails = await VoyageService.getVoyageList();
  };

  useEffect(() => {
    allVesselsInfo();
    // voyageList();
  }, []);

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <button
              onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <span>{"<< "}Back to Voyages</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
// console.log("vesselPositionDetails", vesselPositionDetails)
  return (
    <MainLayout title={"Active Vessel Postions  🚢"} pageActions={pageActions}>
      {/* loading ? <Spinner /> : */}
      <MapContainer
        center={center}
        zoom={8}
        style={{ height: "100%" }}
        // doubleClickZoom={false}
      >
        <TileLayer
          attribution={osm.openstreetmap.attribution}
          url={osm.openstreetmap.url}
        />
        {vesselPositionDetails?.map((vessel) => (
          <Marker
            icon={defaultMarker}
            key={vessel?.VesselSpecificationId}
            position={[vessel.Lat, vessel.Lon]}
            eventHandlers={{
              click: () => {
                setSelectedPosition(vessel);
              },
            }}
          />
        ))}
        {selectedPosition && (
          <Popup
            position={[selectedPosition.Lat, selectedPosition.Lon]}
            onClose={() => {
              setSelectedPosition(null);
            }}
          >
            <div>
              <h5>
                Vessel Name:{" "}
                {vesselInfo?.find(
                  (vessel) =>
                    vessel?.VesselSpecificationId ===
                    selectedPosition?.VesselSpecificationId
                )?.VesselName || "N/A"}
              </h5>
              <p>Destination : {selectedPosition?.Destination}</p>
              <p>ETA : {selectedPosition?.Eta}</p>
              <p>LastPortId : {selectedPosition?.LastPortId}</p>
              <p>LastPortTime : {selectedPosition?.LastPortTime}</p>
              <p>Speed : {selectedPosition?.Speed}</p>
            </div>
          </Popup>
        )}
      </MapContainer>
    </MainLayout>
  );
}
