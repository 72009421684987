import React, { useEffect, useState } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import LocationService from "../../services/locationService";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import ProductService from "../../services/productService";
import EquipmentService from "../../services/equipmentService";
import VesselService from "../../services/vesselService";

export default function ProductRequestPage() {
  const params = useParams();
  const [product, setProduct] = useState(
    params?.product && params?.product != "all" ? params.product : null
  );
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [units, setUnits] = useState([]);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [tanks, setTanks] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [locations, setLocations] = useState([]);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "productRequest";

  useEffect(async () => {
    await init();

    return () => {
      ClearValidator(validationGroup);
    };
  }, []);

  const init = async () => {
    setLoading(true);
    let _products = await ProductService.listAll();
    _products = (_products || []).map((a) => ({
      ...a,
      value: a.ProductId,
      label: a.ProductName,
    }));
    _products = _products.filter((singleProduct) =>
      ["fuel", "potable water"].includes(
        singleProduct.ProductName?.toLowerCase()
      )
    );
    if (params?.product && params.product != "all") {
      const selected_product = _products.find(
        (a) =>
          a.ProductName.toLowerCase().replace(/ /g, "_").trim() ==
          params.product.toLowerCase().trim()
      );
      setSelectedProduct(selected_product);
      addPayload(["productId"], [selected_product?.ProductId]);
    }
    setProducts(_products);

    let _units = await EquipmentService.listMeasurementUnits();
    _units = (_units || [])
      .map((a) => ({
        ...a,
        value: a.MeasurementUnitId,
        label: `${a.MeasurementUnit} (${a.MeasurementUnitSymbol})`,
      }))
      .filter((a) => a.UnitType == "volume");
    setUnits(_units);

    if (params.vesselId) {
      addPayload(["vesselSpecificationId"], [params.vesselId]);
      await loadTanks(params.vesselId);
    } else {
    }
    await loadVessels();

    let _locations = await LocationService.list(1, 5000);
    _locations = (_locations || []).map((a) => ({
      ...a,
      value: a.LocationId,
      label: a.LocationName,
    }));
    setLocations(_locations);

    setLoading(false);
  };

  const loadTanks = async (vesselId) => {
    const _tanks = await VesselService.listTanks(vesselId);
    setTanks(_tanks || []);
  };

  const loadVessels = async () => {
    let _vessels = await VesselService.searchVessels(1, 5000, "", 0);
    _vessels = (_vessels?.Data || []).map((e) => ({
      ...e,
      value: e.VesselSpecificationId,
      label: e.VesselName,
    }));
    setVessels(_vessels);
    if (params.vesselId) {
      const selectedVessel = _vessels.find(
        (a) => a.VesselSpecificationId == params.vesselId
      );
      addPayload(
        "vesselSpecificationId",
        selectedVessel?.VesselSpecificationId
      );
    }
  };

  const submitForm = async () => {
    //water_request, fuel_request_MGO, fuel_request_fuel_oil
    const _payload = { ...payload };
    // const _product = products.find((a) => a.ProductId == _payload.productId);
    // if (`${_product?.ProductName}`.toLocaleLowerCase().includes("water")) {
    //   _payload.RequestType = "water_request";
    // } else if (`${_product?.ProductName}`.toLocaleLowerCase().includes("mgo")) {
    //   _payload.RequestType = "fuel_request_MGO";
    // } else {
    //   _payload.RequestType = "fuel_request_fuel_oil";
    // }

    const _tank = tanks.find((a) => a.ProductId == _payload.productId);
    _payload.shipSideEquipmentId = _tank?.ShipSideEquipmentId;

    setSaving(true);

    // delete _payload.locationType;
    const response = await ProductService.newRequest(_payload);
    if (response) {
      setPayload({});
      //setRedirectUrl("/locations");
      toastr("success", "Product request submitted");
      await init();
    }
    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title={`Vessel Request`}
      loading={loading}
      showFullLoader={loading}
    >
      {!loading && (
        <form>
          <div className="card-inner">
            <div>
              <div className="row">
                <div className="col-sm-6 col-lg-4 mb-3">
                  <div className="form-group" style={{ zIndex: "10" }}>
                    <label className="form-label small">Vessel</label>
                    <div className="form-control-wrap">
                      <Select
                        onChange={async (item) => {
                          addPayload(["vesselSpecificationId"], [item.value]);
                          await loadTanks(item.value);
                        }}
                        value={vessels.find(
                          (a) => a.value == payload?.vesselSpecificationId
                        )}
                        options={vessels}
                        isDisabled={params.vesselId}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3 mb-3">
                  <div className="form-group" style={{ zIndex: "9" }}>
                    <label className="form-label small">Product</label>
                    <div className="form-control-wrap">
                      <Select
                        onChange={(item) => {
                          addPayload(["productId"], [item.value]);
                          setSelectedProduct(item);
                        }}
                        value={products
                          .filter((a) =>
                            tanks.map((t) => t.ProductId).includes(a.ProductId)
                          )
                          ?.find(
                            (a) =>
                              a.value ==
                              (selectedProduct?.ProductId || payload?.productId)
                          )}
                        options={products.filter((a) =>
                          tanks.map((t) => t.ProductId).includes(a.ProductId)
                        )}
                        isDisabled={params.product && selectedProduct}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 col-lg-4 mb-3">
                  <div className="form-group row" style={{ zIndex: "8" }}>
                    <div className="form-control-wrap col-4">
                      <label className="form-label small ">Quantity</label>
                      <input
                        type="number"
                        className="form-control required"
                        placeholder="quantity"
                        onChange={(e) => addPayload("quantity", e.target.value)}
                      />
                    </div>
                    <div className="form-control-wrap col-8">
                      <label className="form-label small ">Unit</label>
                      <Select
                        onChange={(item) =>
                          addPayload("MeasurementUnitId", item.value)
                        }
                        placeholder="unit of measurement"
                        value={units.find(
                          (a) => a.value == payload?.MeasurementUnitId
                        )}
                        options={units}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-lg-4 mb-3">
                  <div className="form-group" style={{ zIndex: "7" }}>
                    <label className="form-label small">
                      Place of Delivery
                    </label>
                    <div className="form-control-wrap">
                      {payload?.isAtSea ? (
                        <>
                          <input
                            type="text"
                            className="form-control required"
                            placeholder=""
                            onChange={(e) =>
                              addPayload("description", e.target.value)
                            }
                          />
                        </>
                      ) : (
                        <Select
                          onChange={async (item) => {
                            addPayload(["placeOfDelivery"], [item.value]);
                          }}
                          value={locations.find(
                            (a) => a.value == payload?.placeOfDelivery
                          )}
                          options={locations}
                        />
                      )}
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group row align-items-center">
                      <label className="form-label col-auto">At Sea ? </label>
                      <div className="form-control-wrap col">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch24Hours"
                            checked={!!payload.isAtSea}
                            onChange={() =>
                              addPayload(
                                ["isAtSea", "placeOfDelivery", "description"],
                                [payload.isAtSea ? false : true, null, ""]
                              )
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch24Hours"
                          >
                            {payload.isAtSea ? "Yes" : "No"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-3 mb-3">
                  <div className="form-group">
                    <label className="form-label small">Delivery Date</label>
                    <div className="form-control-wrap">
                      <input
                        type="date"
                        className="form-control required"
                        placeholder="date of delivery"
                        onChange={(e) =>
                          addPayload("expectedDateOfDelivery", e.target.value)
                        }
                        value={payload.expectedDateOfDelivery}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-lg-7 mb-3">
                  <div className="form-group">
                    <label className="form-label small">
                      Purpose of request
                    </label>
                    <div className="form-control-wrap">
                      <textarea
                        className="form-control required"
                        placeholder=""
                        value={payload.productRequestUse}
                        onChange={(e) =>
                          addPayload("productRequestUse", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {saving && (
            <div className="form-group p-2 m-3">
              <button type="button" className="btn btn-md btn-link m-1">
                <Spinner size="1.5" />
              </button>
            </div>
          )}
          {!saving && (
            <div className="form-group p-2 m-3">
              <button
                type="button"
                onClick={() => submitForm()}
                className="btn btn-md btn-dark btn-wide px-5 mr-3"
              >
                Submit Request
              </button>
              <button
                type="button"
                className="btn btn-sm btn-link m-1"
                onClick={() => window.history.back()}
              >
                Cancel
              </button>
            </div>
          )}
        </form>
      )}
    </MainLayout>
  );
}
