import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../../components/fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../../components/validator";
import toastr from "../../utils/toastr";
import LocationService from "../../services/locationService";
import FleetService from "../../services/fleetService";
import ReactTooltip from "react-tooltip";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import VesselService from "../../services/vesselService";
import CustomModal from "../../utils/customModal";
import { camelizeKeys, toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import { dateToYYYY_MM_DD } from "../../utils/utilityFunctions";
import VesselsInPoolsListComponent from "../../components/vesselPools/vesselsInPoolsList";
import SwitchVesselPoolComponent from "../../components/vesselPools/switchVesselPoolComponent";

export default function SwitchVesselPoolPage() {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [saving, setSaving] = useState(false);
  const [vessels, setVessels] = useState([]);
  const [vesselPools, setVesselPools] = useState([]);
  const [currentPoolName, setCurrentPoolName] = useState("");
  const [pools, setPools] = useState([]);
  const [tab, setTab] = useState(1);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [modalIsClosed, setModalIsClosed] = useState(true);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "switchpool";

  useEffect(() => {
    initialLoading();
    // initialLoading();
  }, [params.vesselId]);

  const initialLoading = async () => {
    setLoading(true);

    await init();
    await deriveVesselPool();

    setLoading(false);
  };

  const init = async () => {
    let _pools = await FleetService.listFleet();
    _pools = camelizeKeys(_pools || []);

    if (_pools) {
      let _poolsList = _pools
        ?.map((c) => ({
          ...c,
          label: c.fleetName,
          value: c.fleetId,
        }))
        .filter((pool) => pool.fleetId !== params.fleetId);
      setPools(_poolsList || []);
    }
  };

  const deriveVesselPool = async () => {
    let _vesselPool = await FleetService.getAllVesselsByFleetId(
      1,
      10,
      params.fleetId
    );
    _vesselPool = camelizeKeys(...(_vesselPool || []));

    let _vesselPoolList = _vesselPool?.fleetVesselDetails?.map((vessel) => ({
      ...vessel,
      label: vessel?.vesselSpecification.vesselName,
      value: vessel?.vesselFleetId,
      vesselName: vessel?.vesselSpecification.vesselName,
      dateCreated: toReadableDate(vessel?.dateCreated),
      isActive: vessel.isActive === true ? "Yes" : "No",
      switchIcon: switchIcon(vessel),
    }));
    setVessels(_vesselPoolList || []);
    setVesselPools(_vesselPoolList || []);
    setCurrentPoolName(_vesselPool?.fleetName || "");
  };

  const switchIcon = (vessel) => {
    return (
      <>
        <ReactTooltip id="trash">Switch Vessel</ReactTooltip>
        <a
          role="button"
          className="btn btn-sm btn-primary active"
          // className="btn btn-sm btn-icon btn-trigger"
          data-toggle="tooltip"
          data-placement="top"
          data-original-title="Remove Vessel"
          data-tip
          data-for="trash"
          onClick={() => setSelectedRecord(vessel)}
        >
          Switch
          {/* <em 
            className="icon ni ni-trash text-danger fs-22px pointer"
            onClick={() => setSelectedRecord(vessel)} 
            /> */}
        </a>
      </>
    );
  };

  const editCallback = async () => {
    onModalClose();
    await deriveVesselPool();
    setSelectedRecord(null);
  };

  const onModalClose = () => {
    setModalIsClosed(true);
  };

  const pageActions = (
    <div className="toggle-wrap nk-block-tools-toggle">
      <a
        href="#"
        className="btn btn-icon btn-trigger toggle-expand mr-n1"
        data-target="pageMenu"
      >
        <em className="icon ni ni-menu-alt-r" />
      </a>
      <div className="toggle-expand-content" data-content="pageMenu">
        <ul className="nk-block-tools g-3">
          <li>
            <a
              href="/vessel-pools"
              // onClick={() => window.history.back()}
              className="btn btn-white btn-outline-light"
            >
              <em className="icon ni ni-arrow-left" />
              <span>Back to Pools</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Switch Vessel Pool"
      loading={loading}
      showFullLoader={loading}
    >
      <form>
        <div className="card-inner">
          <span className="preview-title-lg overline-title">
            List of Vessels in Pool{" "}
          </span>
          <>
            <VesselsInPoolsListComponent dataList={vesselPools} />
            <div className="gap gap-20px" />
          </>
        </div>
      </form>
      <hr className="preview-hr" style={{ visibility: "hidden" }} />
      {selectedRecord && (
        <CustomModal
          title="Switch Vessel Pool"
          content={
            <>
              <SwitchVesselPoolComponent
                callback={editCallback}
                vessels={vessels}
                currentVessel={selectedRecord}
                vesselPools={vesselPools}
                currentPoolName={currentPoolName}
                pools={pools}
              />
            </>
          }
          onClose={() => setSelectedRecord(null)}
          isClosed={false}
        />
      )}
    </MainLayout>
  );
}
