import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import PayloadUpdater from "../../utils/payloadUpdater";
import Select from "react-select";
import FileInput from "../fileInput";
import Spinner from "../../utils/spinner";
import {
  Validator,
  ValidateGroup,
  ClearValidator,
} from "../validator";
import toastr from "../../utils/toastr";
import LocationService from "../../services/locationService";
import FleetService from "../../services/fleetService";
import ReactTooltip from "react-tooltip";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import VesselService from "../../services/vesselService";
import CustomModal from "../../utils/customModal";
import { camelizeKeys, toReadableDate } from "../../utils/utilityFunctions";
import { forEach } from "lodash";
import { dateToYYYY_MM_DD } from "../../utils/utilityFunctions";
import VesselsInPoolsListComponent from "./vesselsInPoolsList";

export default function SwitchVesselPoolComponent({callback, vessels, vesselPools, currentVessel, currentPoolName, pools}) {
  const params = useParams();
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const [currentPayload, setCurrentPayload] = useState({});
  const [saving, setSaving] = useState(false);

  const addPayload = new PayloadUpdater({
    payload: payload,
    setPayload: setPayload,
  }).update;
  const validationGroup = "switchpool";

  // useEffect(async () => {
  //   // setLoading(true);

  //   // await init();
  //   // await deriveVesselPool();

  //   // setLoading(false);
  // }, []);

  // const init = async () => {
  //   let _pools = await FleetService.listFleet();
  //   _pools = camelizeKeys(_pools || []);
  //   console.log(_pools);

  //   if (_pools) {
  //     let _poolsList = _pools?.map((c) => ({
  //       ...c,
  //       label: c.fleetName,
  //       value: c.fleetId,
  //     })).filter(
  //       (pool) => pool.fleetId !== params.fleetId
  //     );
  //     console.log(_poolsList);
  //     setPools(_poolsList || []);
  //   }
  // };

  // const deriveVesselPool = async () => {
  //   let _vesselPool = await FleetService.getAllVesselsByFleetId(1, 10, params.fleetId);
  //   _vesselPool = camelizeKeys(..._vesselPool || []);
  //   console.log(_vesselPool?.fleetVesselDetails);

  //   let _vesselPoolList = _vesselPool?.fleetVesselDetails?.map((vessel) => ({
  //     ...vessel,
  //     label: vessel?.vesselSpecification.vesselName,
  //     value: vessel?.vesselFleetId,
  //     vesselName: vessel?.vesselSpecification.vesselName,
  //     dateCreated: toReadableDate(vessel?.dateCreated),
  //     isActive: vessel.isActive === true ? "Yes" : "No",
  //     trashIcon: deleteIcon(vessel),
  //   }))
  //   setVessels(_vesselPoolList || []);
  //   setVesselPool(_vesselPoolList || []);
  //   setCurrentPoolName(_vesselPool?.fleetName || "");
  // };


  let today = new Date();
  let dateToDisplay = toReadableDate(today);
  let dateForDbPost = today.toISOString();

  payload.dateCreated = dateForDbPost;
  payload.vesselSpecificationId = currentVessel.vesselSpecificationId;
  payload.isActive = currentVessel.isActive;

  currentPayload.dateCreated = currentVessel.dateCreated;
  currentPayload.fleetId = currentVessel.fleetId;
  currentPayload.vesselSpecificationId = currentVessel.vesselSpecificationId;
  currentPayload.isActive = currentVessel.isActive;

  const switchVesselPoolForm = async () => {

    const _payload = { ...payload };
    const _currentPayload = { ...currentPayload };

    if (!_payload.fleetId) {
      toastr("error", "Select new pool");
      return;
    }

    setSaving(true);

    const removeRequest = await FleetService.RemoveVesselFromPool(currentVessel.vesselFleetId);
    // console.log(removeRequest);
    if (removeRequest) {
      toastr("success", "Vessel removed successfully");

      const switchRequest = await FleetService.postPool(_payload);
      // console.log(switchRequest);
      if (switchRequest) {
        toastr("success", "Vessel pool switch successfully");
        callback && callback();
        // setRedirectUrl("/vessel-pools");
      } else {
        try {
          const switchBackToCurrentRequest = await FleetService.postPool(_currentPayload);
          // console.log(switchBackToCurrentRequest);
          if (switchBackToCurrentRequest) {
            toastr("success", "Vessel returned to original pool");
            callback && callback();
          } 
        } catch (error) {
          toastr("error", "Vessel pool not switched");
        }
        };
    } else {
      toastr("error", "Vessel not removed");
      setSaving(false);
    };

    setSaving(false);
  };

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <>
      <form>
          <div className="card-inner">
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="form-label small">Vessel Name</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control required"
                        value={currentVessel?.vesselSpecification?.vesselName}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="form-label small">Current Pool Name</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control required"
                        value={currentPoolName}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group" style={{ zIndex: "9" }}>
                    <label className="form-label small">New Pool Name</label>
                    <div className="form-control-wrap">
                      <Select
                        value={pools.find((pool) => pool.value == payload?.fleetId)}
                        options={pools}
                        onChange={(e) => {
                          addPayload("fleetId", e.value);
                        }}
                        placeholder="Select Pool"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        {saving && (
          <div className="form-group p-2 m-3">
            <button type="button" className="btn btn-md btn-link m-1">
              <Spinner size="1.5" />
            </button>
          </div>
        )}
        {!saving && (
          <div className="form-group p-2 m-3">
            <button
              type="button"
              className="btn btn-md btn-dark btn-wide px-5 mr-3"
              onClick={() => switchVesselPoolForm()}
            >
              Remove
            </button>
            <button
              type="button"
              className="btn btn-sm btn-link m-1"
              onClick={() => window.history.back()}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
      <hr className="preview-hr" style={{ visibility: "hidden" }} />
    </>
  );
}
