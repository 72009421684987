import React, { useEffect, useState } from "react";
import { Navigate, useParams, useLocation } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout";
import Tabs from "../../utils/tabs";
import VesselDetailsComponent from "../../components/vessel/vesselDetailsComponent";
import VesselPartiesComponent from "../../components/vessel/vesselPartiesComponent";
import ComplianceComponent from "../../components/vessel/complianceComponent";
import EquipmentListComponent from "../../components/vessel/equipmentListComponent";
import VesselContracts from "../../components/vessel/VesselContracts";
import VesselUtilization from "../../components/vessel/VesselUtilization";
import VesselSailingHistory from "../../components/vessel/VesselSailingHistory";
import VesselHireHistory from "../../components/vessel/VesselHireHistory";
import { checkUserPermission } from "../../utils/utilityFunctions";
import VesselDetailsEquipmentComponent from "../../components/vessel/VesselDetailsEquipmentComponent";

export default function VesselDetailsPage() {
  const params = useParams(); //we are expecting params.vesselId as vesselSpecificationId
  const isEdit = useLocation().pathname.startsWith("/edit-vessel");

  const [redirectUrl, setRedirectUrl] = useState(null);

  const [loading, setloading] = useState(false);

  // pass this function to any of the sub components to trigger a component wide refresh across the tabs
  const forceRefresh = () => {
    setloading(true);
    setloading(false);
  };

  useEffect(async () => {}, []);

  const tabHeaders = [
    {
      icon: "icon ni ni-view-grid-wd",
      text: "Details",
      show: checkUserPermission("vessel_view_details"),
    },
    {
      icon: "icon ni ni-user-list",
      text: "Managers",
      show: checkUserPermission("parties_view_module"),
    },
    {
      icon: "icon ni ni-files",
      text: "Compliance",
      show: checkUserPermission("vessel_view_compliance"),
    },
    {
      icon: "icon ni ni-files",
      text: "Vessel Equipment",
      show: checkUserPermission("equipment_view_module"),
    },
    {
      icon: "icon ni ni-files",
      text: "Contracts",
      show: checkUserPermission("commercial_view_module"),
    },
    {
      icon: "icon ni ni-activity-alt",
      text: "Utilization",
      show: checkUserPermission("vessel_voyage_history"),
    },
    {
      icon: "icon ni ni-map",
      text: "Sailing History",
      show: checkUserPermission("vessel_voyage_history"),
    },
    {
      icon: "icon ni ni-map",
      text: "Hire History",
      show: checkUserPermission("vessel_voyage_history"),
    },
  ];
  const tabContent = [
    <VesselDetailsComponent vesselSpecId={params.vesselId} isEdit={isEdit} />,
    <VesselPartiesComponent vesselSpecId={params.vesselId} />,
    <ComplianceComponent vesselSpecId={params.vesselId} />,
    <VesselDetailsEquipmentComponent
      vesselSpecId={params.vesselId}
      forceRefresh={forceRefresh}
    />,
    // <EquipmentListComponent vesselSpecId={params.vesselId} />,
    <VesselContracts vesselSpecId={params.vesselId} />,
    <VesselUtilization vesselSpecId={params.vesselId} />,
    <VesselSailingHistory vesselSpecId={params.vesselId} />,
    <VesselHireHistory vesselSpecId={params.vesselId} />,
  ];

  const _tabView = (
    <Tabs headers={tabHeaders} contents={tabContent} contentPadding="3" />
  );

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <MainLayout
      title="Vessel Details"
      // loading={loading}
      showFullLoader={loading}
    >
      <div className="">{_tabView}</div>
    </MainLayout>
  );
}
