import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CountryService from "../../services/countriesService";
import LocationService from "../../services/locationService";
import ProvinceService from "../../services/provinceService";
import Spinner from "../../utils/spinner";

export default function LocationDetailsComponent({ locationId, location, hideImage }) {
    const [loading, setLoading] = useState(false);
    const [locationData, setLocation] = useState(location || {});
    const [country, setCountry] = useState(null);
    const [region, setRegion] = useState(null);


    const init = async () => {
        if (!location) {
            setLoading(true);
            const _location = await LocationService.get(locationId);
            setLocation(_location || {});
            
        }

        const actualLocation = location || locationData;

        if(actualLocation.CountryId){
            const countries = await CountryService.listCountry();
            const _country = (countries || []).find(c=> c.CountryId == actualLocation.CountryId);
            setCountry(_country);

            if(_country && actualLocation.RegionId){
                const regions = await ProvinceService.listProvincesByCountry(actualLocation.CountryId);
                const _region = (regions || []).find(r=> r.RegionId == actualLocation.RegionId);
                setRegion(_region);
            }
        }

        


        setLoading(false);
    }

    useEffect(async () => {
        await init();

        return () => {
        }
    }, [])

    const dataItem = (label, value) => {
        if (value == "null") value = "";
        return <li className="col-6 "><p><span className="text-soft small">{label}</span> {value || "-"}</p></li>
    }


    return (
        <>
            {loading && <Spinner />}
            {loading || <div className="card ">
                {locationData.PhotoUrl && !hideImage && <img src={locationData.PhotoUrl} className="card-img-top p-2" alt="" />}
                {!locationData.PhotoUrl && <p className="text-muted">No photo available</p>}
                    <div className="sp-plan-desc card-inner">
                        <ul className="row gx-1">
                            {dataItem("Location type", locationData.LocationTypeName)}
                            {dataItem("Parent location", locationData.ParentLocationName)}
                            {dataItem("Country", country?.CountryName)}
                            {dataItem("Region", region?.RegionName)}
                            {dataItem("City", locationData.City)}
                            {dataItem("Co-ordinates", (!locationData.Longitude || !locationData.Latitude) ? null : `Long: ${locationData.Longitude} x Lat: ${locationData.Latitude}`)}
                            {dataItem("Location manager", locationData.PartyName)}
                            {dataItem("Operating hours", locationData.IsOpen24Hours? "Open 24 hours": `${locationData?.OpeningTime?.substring(0, 5) } - ${locationData?.ClosingTime?.substring(0, 5)}` )}
                        </ul>
                    </div>
                    
            </div>}
        </>
    );
}

