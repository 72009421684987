import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout";
import MyTable from "../../components/dataTable";
import {
	checkUserPermission,
	currencySymbol,
	generateQueryString,
	toReadableDate,
} from "../../utils/utilityFunctions";
import PartyService from "../../services/partyService";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Select from "react-select";
import InvoiceService from "../../services/invoiceService";
import toastr from "../../utils/toastr";
import ConfirmationModal from "../../components/general/ConfirmationModal";
import Paginate from "../../components/paginate";

export default function InvoiceList() {
	const navigate = useNavigate();
	const { search: queryParams } = useLocation();
	const [searchParams] = useSearchParams();
	const [tableData, setTableData] = useState([]);
	const [oparators, setOparators] = useState([{}]);
	const [totalCount, setTotalCount] = useState(0);
	const [initializing, setInit] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
	const [filterObject, setFilterObject] = useState(
		Object.fromEntries([...searchParams])
	);
	const statusList = [
		{ value: "Awaiting Approval", label: "Awaiting Approval" },
		{ value: "Completed", label: "Completed" },
		{ value: "Partial Payment", label: "Partial Payment" },
		{ value: "Pending", label: "Pending" },
		{ value: "Unpaid", label: "Unpaid" },
	];

	const clearFilters = () => {
		setFilterObject({
			invoiceNumber: "",
			operator: "",
			status: "",
			from: "",
			to: "",
		});

		navigate("/invoices", { replace: true });
	};

	const filterData = () => {
		const queryStr = generateQueryString(filterObject);
		navigate(`/invoices?${queryStr}`, { replace: true });
	};
	const generateInvoices = async () => {
		setInit(true);
		setConfirmationModalIsOpen(false);
		let res = await InvoiceService.generateInvoice({
			invoices: [],
		});
		if (res) {
			toastr("success", "Pending invoices sent to Operators");
			await getInvoices();
		}
		setInit(false);
	};

	const pageActions = (
		<div className="toggle-wrap nk-block-tools-toggle">
			<a
				href="#"
				className="btn btn-icon btn-trigger toggle-expand mr-n1"
				data-target="pageMenu">
				<em className="icon ni ni-menu-alt-r" />
			</a>
			<div className="toggle-expand-content" data-content="pageMenu">
				<ul className="nk-block-tools g-3">
					{checkUserPermission("invoice_notify_operators") && (
						<li className="nk-block-tools-opt">
							<button
								onClick={() => setConfirmationModalIsOpen(true)}
								className="px-2 btn btn-icon btn-primary"
								disabled={initializing}>
								Notify Operators
							</button>
						</li>
					)}
				</ul>
			</div>
		</div>
	);

	const rowActions = [
		{
			action: "View Details",
			link: "/invoices/",
			property1: "InvoiceId",
			icon: "icon ni ni-eye",
			show: checkUserPermission("invoice_view_details"),
		},
		{
			action: "Download Invoice",
			link: "",
			externalLink: true,
			property1: "fileUrl",
			target: "_blank",
			icon: "icon ni ni-file-download",
			show: checkUserPermission("invoice_download_invoice"),
		},
		{
			action: "Payment History",
			link: "/payments/",
			queryLink: true,
			property1: "InvoiceId",
			icon: "icon ni ni-list-index",
			show: checkUserPermission("payments_view_module"),
		},
	];
	const headerList = [
		{
			Header: "Invoice Number",
			accessor: "InvoiceRefNo",
		},
		{
			Header: "Operator",
			accessor: "PartyName",
			link: "/view-party/",
			linkId: "Id",
		},
		{
			Header: "Due Date",
			accessor: "DueDate",
		},

		{
			Header: "Amount",
			accessor: "GrossAmount",
		},
		{
			Header: "Status",
			accessor: "InvoiceStatus",
		},
		{
			Header: "",
			accessor: "menu",
		},
	];

	useEffect(async () => {
		setInit(true);
		await retrieveOparators();
		await getInvoices();
	}, [queryParams]);

	const getInvoices = async (
		currentPage = 1,
		PageSize = 10,
		query = queryParams
	) => {
		setInit(true);
		setCurrentPage(currentPage);
		setPageSize(PageSize);
		let res = await InvoiceService.search(
			currentPage,
			PageSize,
			query && (query[0] == "?" ? query.substring(1) : query)
		);
		if (res) {
			const parsedData = res.res.map((invoice) => ({
				...invoice,
				DueDate: toReadableDate(invoice.DueDate),
				fileUrl:
					invoice.fileUrl &&
					`${invoice.fileUrl}?authorization=${localStorage.token}`,
				GrossAmount: `${currencySymbol(invoice?.Currency)}${
					invoice.GrossAmount
				}`,
			}));
			setTableData(parsedData);
			setTotalCount(res?.TotalCount || 0);
		}

		setInit(false);
	};

	const retrieveOparators = async () => {
		let res = await PartyService.listParty();
		if (res) {
			res = res.map((e) => ({
				...e,
				label: e.PartyName,
				value: e.PartyId,
			}));
		}
		setOparators(res);
	};

	const gotoPage = (page) => {
		if (page) {
			const queryStr = generateQueryString(filterObject);
			setCurrentPage(page);
			getInvoices(page, 10, queryStr);
		}
	};

	const tableTopActions = (
		<div className="card-tools mr-n1" data-select2-id={28}>
			<ul className="btn-toolbar gx-1" data-select2-id={27}>
				<li data-select2-id={26}>
					<div className="toggle-wrap" data-select2-id={25}>
						<div
							className="toggle-content"
							data-content="cardTools"
							data-select2-id={24}>
							<ul className="btn-toolbar gx-1" data-select2-id={23}>
								<li className="toggle-close">
									<a
										className="btn btn-icon btn-trigger toggle"
										data-target="cardTools">
										<em className="icon ni ni-arrow-left" />
									</a>
								</li>
								{/* li */}

								<li>
									<div className="dropdown">
										{checkUserPermission("invoice_filter_invoices") && (
											<a
												className="btn btn-trigger btn-icon dropdown-toggle"
												data-toggle="dropdown">
												<div className="dot dot-primary" />
												<em className="icon ni ni-filter-alt" />
											</a>
										)}
										<div className="filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-right">
											<form id="invoice-filter">
												<div className="dropdown-head">
													<span className="sub-title dropdown-title">
														Filter Invoices
													</span>
													<div className="dropdown">
														<a
															className="btn btn-sm btn-icon"
															data-toggle="dropdown">
															<em className="icon ni ni-cross" title="close" />
														</a>
													</div>
												</div>
												<div className="dropdown-body dropdown-body-rg">
													<div className="row gx-6 gy-3">
														<div className="col-12">
															<div className="form-group">
																<label
																	className="overline-title overline-title-alt"
																	htmlFor="invoice-number-filter">
																	Invoice Number
																</label>
																<input
																	type="number"
																	className="form-control "
																	placeholder=""
																	id="invoice-number-filter"
																	value={filterObject.invoiceNumber}
																	onChange={(e) =>
																		setFilterObject((prev) => ({
																			...prev,
																			invoiceNumber: e.target.value,
																		}))
																	}
																/>
															</div>
														</div>
														<div className="col-12">
															<div
																className="form-group"
																onClick={(e) => e.stopPropagation()}>
																<label
																	className="overline-title overline-title-alt"
																	htmlFor="status-select-filter">
																	Status
																</label>
																<Select
																	className="select-dropdown-sm"
																	id="status-select-filter"
																	value={statusList.find(
																		(status) =>
																			status.value === filterObject.status
																	)}
																	options={statusList}
																	onChange={(option) =>
																		setFilterObject((prev) => ({
																			...prev,
																			status: option.value,
																		}))
																	}
																/>
															</div>
														</div>
														<div className="col-6">
															<div className="form-group">
																<label
																	className="overline-title overline-title-alt"
																	htmlFor="from-date-filter">
																	From
																</label>
																<input
																	type="date"
																	className="form-control "
																	placeholder=""
																	id="from-date-filter"
																	value={filterObject.from}
																	onChange={(e) =>
																		setFilterObject((prev) => ({
																			...prev,
																			from: e.target.value,
																		}))
																	}
																/>
															</div>
														</div>

														<div className="col-6">
															<div className="form-group">
																<label
																	className="overline-title overline-title-alt"
																	htmlFor="to-date-filter">
																	To
																</label>
																<input
																	type="date"
																	className="form-control "
																	placeholder=""
																	id="to-date-filter"
																	value={filterObject.to}
																	onChange={(e) =>
																		setFilterObject((prev) => ({
																			...prev,
																			to: e.target.value,
																		}))
																	}
																/>
															</div>
														</div>
														<div className="col-12">
															<div
																className="form-group"
																onClick={(e) => e.stopPropagation()}>
																<label className="overline-title overline-title-alt">
																	Operator
																</label>
																<Select
																	className="select-dropdown-sm"
																	options={oparators}
																	value={oparators.find(
																		(operator) =>
																			operator.PartyId === filterObject.operator
																	)}
																	onChange={(option) =>
																		setFilterObject((prev) => ({
																			...prev,
																			operator: option.value,
																		}))
																	}
																/>
															</div>
														</div>
														<div className="col-12">
															<div className="form-group">
																<button
																	onClick={() => filterData()}
																	type="button"
																	className="btn btn-secondary">
																	Filter
																</button>
															</div>
														</div>
													</div>
												</div>
												<div className="dropdown-foot between">
													<a className="clickable" onClick={clearFilters}>
														Reset Filter
													</a>
												</div>
											</form>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</li>
			</ul>
		</div>
	);

	return (
		<MainLayout
			title="Invoices"
			loading={initializing}
			showFullLoader={initializing}
			pageActions={pageActions}>
			<div>
				{!initializing && (
					<MyTable
						columns={headerList}
						cssArray={["font-weight-500", "", "", ""]}
						data={tableData}
						reloadData={(page, pageSize, query) =>
							getInvoices(page, pageSize, query)
						}
						emptyMessage="No Invoices Yet"
						pageSize={pageSize}
						page={currentPage}
						totalCount={totalCount}
						rowActions={rowActions}
						hidePagination
						tableTopActions={tableTopActions}
					/>
				)}

				<div className="card-inner">
					<Paginate
						onChange={(p) => gotoPage(p)}
						currentPage={currentPage || 1}
						pageSize={pageSize}
						totalRecordsCount={totalCount}
					/>
				</div>
			</div>

			{confirmationModalIsOpen && (
				<ConfirmationModal
					text={
						"This action is final, are you sure you would like to send out pending invoices to operators ?"
					}
					title="Notify Operators"
					loading={initializing}
					onSubmit={generateInvoices}
					onClose={() => setConfirmationModalIsOpen(false)}
				/>
			)}
		</MainLayout>
	);
}
