import React, { useState } from "react";
import Spinner from "../../utils/spinner";
import PayloadUpdater from "../../utils/payloadUpdater";
import toastr from "../../utils/toastr";
import moment from "moment";

import PaymentService from "../../services/paymentService";
import Select from "react-select";

export default function PostPaymentComponent({
  invoice,
  callback,
  currencies,
  organizationAccounts,
}) {
  // Data
  const [payload, setUpdatePayload] = useState({
    partyId: invoice.PartyId,
    InvoiceId: invoice.InvoiceId,
    invoiceRef: invoice.InvoiceRefNo,
  });
  const [initializing, setInit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [usePaymentReciept, setUsePaymentReciept] = useState(false);
  const [contractEndDate, setContractEndDate] = useState(null);

  const paymentMethods = [
    { label: "cheque", value: "cheque" },
    { label: "direct debit", value: "direct debit" },
    { label: "bank transfer", value: "bank transfer" },
  ];

  // methods
  const updatePayload = new PayloadUpdater({
    payload: payload,
    setPayload: setUpdatePayload,
  }).update;

  const postPayment = async (payload) => {
    const res = await PaymentService.post(payload);
    return res;
  };

  const validateForm = () => {
    if (!moment(payload.paymentDate).isBefore(moment())) {
      toastr("error", "Payment date can't be in the future");
      return false;
    }

    if (!payload.currencyConfigurationId) {
      toastr("error", "Please choose a currency");
      return false;
    }

    if (!payload.organizationAccountDetailId) {
      toastr("error", "Please choose the account paid into");
      return false;
    }

    return true;
  };

  const submitUpdate = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setSubmitting(true);
    let response = false;

    response = await postPayment(payload);

    if (response) {
      toastr("success", `Payment Posted successfully`);
      callback && callback();
    } else {
      toastr("error", "Update failed, Please try again");
    }

    setSubmitting(false);
  };

  const formLabel = (label, toolTip) => {
    return (
      <label className="form-label" title={toolTip || label}>
        <small>{label}</small>
      </label>
    );
  };

  return initializing ? (
    <div className="w-100 text-center my-5 py-5">
      <Spinner />
    </div>
  ) : (
    <form onSubmit={(e) => submitUpdate(e)} className="form-validate is-alter">
      <div className="form-group row">
        <div className="col">
          {formLabel("Payment Reference")}
          <div className="form-control-wrap">
            <input
              type="text"
              className="form-control "
              required
              onChange={(e) =>
                updatePayload("paymentReferenceNo", e.target.value)
              }
            />
          </div>
        </div>
      </div>

      <div className="form-group row">
        <div className="col">
          {formLabel("Account Paid to")}
          <div className="form-control-wrap">
            <Select
              options={organizationAccounts.map((c) => ({
                ...c,
                label: c.AccountName,
                value: c.OrganizationAccountDetailID,
              }))}
              onChange={(e) => {
                updatePayload("organizationAccountDetailId", e.value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="form-group row">
        <div className="col">
          {formLabel("Currency")}
          <div className="form-control-wrap">
            <Select
              options={currencies.map((c) => ({
                ...c,
                label: c.CorrespondingData,
                value: c.ConfigurationId,
              }))}
              onChange={(e) => {
                updatePayload("currencyConfigurationId", e.value);
              }}
            />
          </div>
        </div>

        <div className="col">
          {formLabel("Amount Paid")}
          <div className="form-control-wrap">
            <input
              type="number"
              className="form-control "
              required
              onChange={(e) => updatePayload("amountPaid", e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="form-group row">
        <div className="col">
          {formLabel("Payment Method")}
          <div className="form-control-wrap">
            <Select
              options={paymentMethods}
              onChange={(e) => {
                updatePayload("paymentMethod", e.value);
              }}
            />
          </div>
        </div>

        <div className="col">
          {formLabel("Payment Date")}
          <div className="form-control-wrap">
            <input
              type="date"
              className="form-control "
              required
              onChange={(e) => updatePayload("paymentDate", e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="form-group row">
        <div className="col">
          {formLabel("Remarks (Optional)")}
          <div className="form-control-wrap">
            <textarea
              className="form-control "
              onChange={(e) => updatePayload("description", e.target.value)}
            >
              {" "}
            </textarea>
          </div>
        </div>
      </div>

      {/* for if we are to add reciept upload */}
      {/* {usePaymentReciept && (
        <div className="form-group row">
          <div className="col">
            {formLabel("Contract End Date")}
            <div className="form-control-wrap">
              <input
                type="datetime-local"
                className="form-control "
                required
                value={contractEndDate}
                onChange={(e) => setContractEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      )} */}

      {/* <div className="form-control-wrap mb-3">
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customSwitchActive"
            checked={usePaymentReciept}
            onChange={() => setUsePaymentReciept(!usePaymentReciept)}
          />
          <label className="custom-control-label" htmlFor="customSwitchActive">
            Contract Extension
          </label>
        </div>
      </div> */}

      <div className="form-group">
        <button
          type="submit"
          disabled={submitting}
          className="btn  btn-primary"
        >
          {submitting ? <Spinner size="1" color="white" /> : "Submit"}
        </button>
      </div>
    </form>
  );
}
