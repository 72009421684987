import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class InvoiceService {
  //Complete unpaginated list
  static fullList = async () => {
    return await apiCall(`/api/Invoice/Get`);
  };

  static list = async (page, pageSize, query = "") => {
    page = page || 1;
    pageSize = pageSize || 50;
    let response = await apiCall(
      `/api/Invoice/Get?pageCount=${page}&noPerPage=${pageSize}&${query}`
    );
    return response;
  };

  static search = async (page, pageSize, query = "") => {
    page = page || 1;
    pageSize = pageSize || 50;
    let response = await apiCall(
      `/api/Invoice/SearchList?pageCount=${page}&noPerPage=${pageSize}&${query}`
    );
    return response?.Data;
  };
  static oustandingInvoicesDashboard = async (page, pageSize, query = "") => {
    page = page || 1;
    pageSize = pageSize || 50;
    let response = await apiCall(
      `/api/Invoice/Get/dashboard/outstanding-invoices?pageCount=${page}&noPerPage=${pageSize}&draftInvoice=true&${query}`
    );
    return response?.Data;
  };

  static get = async (id) => {
    if (!id) return null;
    return await apiCall(`/api/Invoice/Get/${id}`);
  };

  static saveDraft = async (payload) => {
    return await apiCall(`/api/Invoice/ApplyDiscount`, "POST", payload);
  };

  static updateInvoice = async (payload) => {
    return await apiCall(`/api/Invoice/UpdateInvoice`, "PUT", payload);
  };
  static generateInvoice = async (payload) => {
    return await apiCall(`/api/Invoice/GenerateInvoice`, "POST", payload);
  };
}
