import React from "react";

function BerthOrWarehouseItem({
  type,
  index,
  entry,
  //   setSelectedEntry,
  editEntry,
  deleteEntry,
}) {
  return (
    <div className="d-flex  align-center mb-2">
      <p className="mb-0 ">
        {entry.locationName || "N/A"}{" "}
        {entry.shortName ? "(" + entry.shortName + ")" : ""}
      </p>

      <em
        class="icon ni ni-edit text-primary fs-20px pointer mx-2"
        title="Edit"
        onClick={() => {
          editEntry({ type, index, data: entry });
        }}
      ></em>
      <em
        class="icon ni ni-cross text-danger fs-22px pointer"
        title="Delete"
        onClick={(e) => deleteEntry(type, index)}
      ></em>
    </div>
  );
}

export default BerthOrWarehouseItem;
