import apiCall from "../utils/apiCall";
import { dynamicSort } from "../utils/utilityFunctions";

export default class CertificateService {
  static listAll = async (useCache = true) => {
    let response = await apiCall(
      `/api/certificates/get`,
      "GET",
      null,
      null,
      false,
      useCache
    );

    if (response?.Data) {
      response = response.Data.sort(dynamicSort("CertificateName"));
    }

    return response;
  };

  static listIssuers = async (useCache = true) => {
    let response = await apiCall(
      `/api/Issuer/get`,
      "GET",
      null,
      null,
      false,
      useCache
    );

    if (response?.Data) {
      response = response.Data.sort(dynamicSort("IssuerName"));
    }

    return response;
  };

  static postIssuer = async ({
    issuerName,
    issuerAbbreviation,
    countryId,
    issuerTypeId,
  }) => {
    const payload = { issuerName, issuerAbbreviation, countryId, issuerTypeId };
    const response = await apiCall(`/api/issuer/Post`, "POST", payload);
    return response;
  };

  static listValidityPeriod = async () => {
    let response = await apiCall(
      `/api/ValidityPeriod/Get`,
      "GET",
      null,
      null,
      false,
      true
    );

    if (response) {
      let monthItems = response.filter((a) =>
        a.ValidityPeriod?.toLowerCase().includes("month")
      );
      monthItems.sort(dynamicSort("ValidityPeriod"));

      let yearItems = response.filter((a) =>
        a.ValidityPeriod?.toLowerCase().includes("year")
      );
      yearItems.sort(dynamicSort("ValidityPeriod"));

      let otherItems = response.filter(
        (a) =>
          !a.ValidityPeriod?.toLowerCase().includes("year") &&
          !a.ValidityPeriod?.toLowerCase().includes("month")
      );

      response = [...monthItems, ...yearItems, ...otherItems];
    }

    return response;
  };

  static statusList = async (useCache = true) => {
    let response = await apiCall(
      `/api/certificates/statuslist`,
      "GET",
      null,
      null,
      false,
      useCache
    );

    if (response) {
      response.sort(dynamicSort("Status"));
    }

    return response;
  };

  static postForVessel = async (payload) => {
    return await apiCall(
      `/api/ScheduleOfVesselCertificate/AddCertificateWithDocs`,
      "POST",
      payload
    );
  };

  static postMasterList = async ({ certificateName, certificateGroupId }) => {
    const payload = { certificateName, certificateGroupId };
    const response = await apiCall(`/api/certificates/post`, "POST", payload);
    return response;
  };

  static editForVessel = async (id, payload) => {
    return await apiCall(
      `/api/ScheduleOfVesselCertificate/Put/${id}`,
      "PUT",
      payload
    );
  };

  static listForVessel = async (vesselSpecificationId) => {
    return await apiCall(
      `/api/ScheduleOfVesselCertificate/ListByVesselId?vesselSpecificationId=${vesselSpecificationId}`,
      "GET"
    );
  };

  static deleteScheduleEntry = async (scheduleId) => {
    return await apiCall(
      `/api/ScheduleOfVesselCertificate/Delete/${scheduleId}`,
      "DELETE"
    );
  };

  static listCertificateGroup = async (useCache = true) => {
    let response = await apiCall(
      `/api/certificategroup/get`,
      "GET",
      null,
      null,
      false,
      useCache
    );

    if (response?.Data) {
      response = response.Data.sort(dynamicSort("CertificateGroup"));
    }

    return response;
  };
}
